import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import LinkDestForm from "./LinkDestForm";

import { formGetStartValue } from "../../../../../lib/editFunctions";
import { isValidURL } from "../../../../../lib/generalFunctions";

import { translate } from "../../../../../translations/translations";

const LinkDestTarget = ({ eb: { startStyles } }) => {
  return (
    <>
      <LinkDestForm
        label={translate("cEditForms.linkDest", false, null)}
        id={"linkDest"}
        startValue={formGetStartValue(startStyles, "linkDest")}
        tooltipText={translate("cEditForms.tooltipLinkDest", false, null)}
        onTypeCheck={isValidURL}
        errMsgText={translate("cEditForms.notValidUrl", false, null)}
      />
    </>
  );
};

LinkDestTarget.propTypes = {
  eb: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  eb: state.eb,
});

export default connect(mapStateToProps, null)(LinkDestTarget);
