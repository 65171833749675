export const mImgGalleryUpload = {
  uploadingFiles: {
    en: "Uploading files..",
    nl: "Bestanden uploaden..",
  },
  addFiles: {
    en: "Please add files/folders to be uploaded",
    nl: "Voeg aub bestanden/folders die geupload moeten worden toe",
  },
  uploadSuccess: {
    en: "File(s) successfully uploaded!",
    nl: "Bestand(en) geupload!",
  },
  serverErrorUploading: {
    en: "Server error while uploading your files. Please try again.",
    nl: "Server fout tijdens het uploading van uw bestanden. Probeer het aub opnieuw.",
  },
  uploadMoreImgs: {
    en: "Upload more images",
    nl: "Upload meer afbeeldingen",
  },
  browseFiles: {
    en: "Browse or drag & drop images to upload to your gallery",
    nl: "Selecteer or drag & drop afbeeldingen die u wilt uploaden naar uw gallerij",
  },
  clearAll: {
    en: "Clear all images",
    nl: "Verwijder alle afbeeldingen",
  },
  removeFromSelection: {
    en: "Remove file from selection",
    nl: "Verwijder bestand van selectie",
  },
  uploadImgs: {
    en: "Upload images",
    nl: "Afbeeldingen uploaden",
  },
};
