import { translate } from "../translations/translations";

export const MARGIN_VALUES = [-8, -6, -4.5, -3, -2, -1.5, -1, -0.5, -0.25, -0.125, 0, 0.125, 0.25, 0.5, 1, 1.5, 2, 3, 4.5, 6, 8];
// export const PADDING_VALUES = [0, 0.125, 0.25, 0.5, 1, 1.5, 2, 3, 4.5, 6, 8];
export const PADDING_VALUES = [0, 0.125, 0.25, 0.5, 0.75, 1, 1.5, 2, 3, 5, 7];
export const BORDER_RADIUS_VALUES = [0, 0.2, 0.4, 0.75, 1, 50, 1000];
export const COL_LAYOUT_VALUES = ["6-6", "4-8", "8-4"];
export const SOCIAL_NAMES = ["facebook", "twitter", "instagram", "linkedin", "youtube", "snapchat", "pinterest", "google-plus", "wechat"];
export const SOCIAL_COLORS = [
  { val: "white", label: translate("lGeneralVars.white", false, null) },
  { val: "black", label: translate("lGeneralVars.black", false, null) },
  { val: "brand", label: translate("lGeneralVars.brand", false, null) },
];
