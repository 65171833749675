import React, { Fragment, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { clickApplyStyleChanges } from "../../../lib/editFunctions";

import EditComponentAccordionItem from "./editForms/formComponents/EditComponentAccordionItem";
import ElementSettings from "./editForms/ElementSettings";
import BgColor from "./editForms/BgColor";
import Border from "./editForms/Border";
import BorderRadius from "./editForms/BorderRadius";
import BtnEdit from "./editForms/BtnEdit";
import ImgSource from "./editForms/ImgSource";
import ImgAdjust from "./editForms/ImgAdjust";
import ImgSize from "./editForms/ImgSize";
import LinkEditor from "./editForms/LinkEditor";
import ListMarker2 from "./editForms/ListMarker2";
import Margin from "./editForms/Margin";
import Padding from "./editForms/Padding";
import TextEditor from "./editForms/TextEditor";

// Special components
import Component from "./editForms/Component";
import ComponentDivider from "./editForms/ComponentDivider";
import ColLayout from "./editForms/ColLayout";
import SocialIconSource from "./editForms/SocialIconSource";
import DownloadAppSource from "./editForms/DownloadAppSource";
import LinkElement from "./editForms/LinkElement";
import NumRatingStars from "./editForms/NumRatingStars";

import { translate } from "../../../translations/translations";

const EditComponent = ({ eb: { selectedElement, editFormsToShow } }) => {
  const textEditorRef = useRef();

  return editFormsToShow.length > 0 ? (
    <>
      <p className="mb-3">
        Selected element: <span className="text-italic">{selectedElement}</span>
      </p>
      <div className="accordion accordion-flush trans-3 border rounded-4 overflow-hidden" id="accordion-EditComponent">
        <EditComponentAccordionItem title={translate("cEditComponent.elementSettings", false, null)} key="ElementSettings">
          <ElementSettings />
        </EditComponentAccordionItem>
        {editFormsToShow.map((editForm) => {
          return editForm === "textEditor" ? (
            <EditComponentAccordionItem title={translate("cEditComponent.textEditor", false, null)} key="TextEditor">
              <TextEditor textEditorRef={textEditorRef} />
            </EditComponentAccordionItem>
          ) : editForm === "linkElement" ? (
            <EditComponentAccordionItem title={translate("cEditComponent.linkDest", false, null)} key="LinkElement">
              <LinkElement />
            </EditComponentAccordionItem>
          ) : editForm === "bgColor" ? (
            <EditComponentAccordionItem title={translate("cEditComponent.bgColor", false, null)} key="BgColor">
              <BgColor />
            </EditComponentAccordionItem>
          ) : editForm === "border" ? (
            <EditComponentAccordionItem title={translate("cEditComponent.border", false, null)} key="Border">
              <Border />
            </EditComponentAccordionItem>
          ) : editForm === "borderRadius" ? (
            <EditComponentAccordionItem title={translate("cEditComponent.borderRadius", false, null)} key="BorderRadius">
              <BorderRadius />
            </EditComponentAccordionItem>
          ) : editForm === "buttonEditor" ? (
            <EditComponentAccordionItem title={translate("cEditComponent.btnStyles", false, null)} key="ButtonEditor">
              <BtnEdit />
            </EditComponentAccordionItem>
          ) : editForm === "socialIconSource" ? (
            <EditComponentAccordionItem title={translate("cEditComponent.socialIcon", false, null)} key="SocialIconSource">
              <SocialIconSource />
            </EditComponentAccordionItem>
          ) : editForm === "downloadAppSource" ? (
            <EditComponentAccordionItem title={translate("cEditComponent.appStoreLinks", false, null)} key="DownloadAppSource">
              <DownloadAppSource />
            </EditComponentAccordionItem>
          ) : editForm === "imgSource" ? (
            <EditComponentAccordionItem title={translate("cEditComponent.imgSrc", false, null)} key="ImgSource">
              <ImgSource />
            </EditComponentAccordionItem>
          ) : editForm === "imgAdjust" ? (
            <EditComponentAccordionItem title={translate("cEditComponent.imgFilters", false, null)} key="ImgAdjust">
              <ImgAdjust />
            </EditComponentAccordionItem>
          ) : editForm === "imgResize" ? (
            <EditComponentAccordionItem title={translate("cEditComponent.imgSize", false, null)} key="ImgSize">
              <ImgSize />
            </EditComponentAccordionItem>
          ) : editForm === "linkEditor" ? (
            <EditComponentAccordionItem title={translate("cEditComponent.linkSettingsStyles", false, null)} key="LinkEditor">
              <LinkEditor />
            </EditComponentAccordionItem>
          ) : editForm === "listMarker2" ? (
            <EditComponentAccordionItem title={translate("cEditComponent.listMarker", false, null)} key="ListMarker2">
              <ListMarker2 />
            </EditComponentAccordionItem>
          ) : editForm === "margin" ? (
            <EditComponentAccordionItem title={translate("cEditComponent.margin", false, null)} key="Margin">
              <Margin />
            </EditComponentAccordionItem>
          ) : editForm === "padding" ? (
            <EditComponentAccordionItem title={translate("cEditComponent.padding", false, null)} key="Padding">
              <Padding />
            </EditComponentAccordionItem>
          ) : editForm === "component" ? (
            <EditComponentAccordionItem title={translate("cEditComponent.component", false, null)} key="Component">
              <Component />
            </EditComponentAccordionItem>
          ) : editForm === "colLayout" ? (
            <EditComponentAccordionItem title={translate("cEditComponent.colLayout", false, null)} key="ColLayout">
              <ColLayout />
            </EditComponentAccordionItem>
          ) : editForm === "divider" ? (
            <EditComponentAccordionItem title={translate("cEditComponent.componentDividerHeadingLine", false, null)} key="divider">
              <ComponentDivider />
            </EditComponentAccordionItem>
          ) : editForm === "numRatingStars" ? (
            <EditComponentAccordionItem title={translate("cEditComponent.rating", false, null)} key="NumRatingStars">
              <NumRatingStars />
            </EditComponentAccordionItem>
          ) : null;
        })}
      </div>
      <button
        className="btn btn-success mt-4 w-100"
        onClick={() => clickApplyStyleChanges(editFormsToShow.includes("textEditor") ? textEditorRef : null)}
      >
        {translate("cEditComponent.applyChanges", false, null)}
      </button>
    </>
  ) : (
    <p className="m-0 text-italic">{translate("cEditComponent.noEditableElementSelected", false, null)}</p>
  );
};

EditComponent.propTypes = {
  eb: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  eb: state.eb,
});

export default connect(mapStateToProps, null)(EditComponent);
