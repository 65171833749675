import React, { useEffect } from "react";
import { useParams, Navigate } from "react-router-dom";

import setAuthToken from "../utils/setAuthToken";
import setWorkspace from "../utils/setWorkspace";

const Open = () => {
  let { token } = useParams();
  let { wsId } = useParams();
  let { projectId } = useParams();

  useEffect(() => {
    localStorage.setItem("token", token);
    localStorage.setItem("workspace", wsId);
    setAuthToken(token);
    setWorkspace(wsId);
    // eslint-disable-next-line
  }, []);

  return <Navigate to={`/${projectId}`} replace={true} />;
};

export default Open;
