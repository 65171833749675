import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { selectColor } from "../../actions/eb";
import { MAIL_COLOR_VAR_NAMES } from "../../lib/generalVars";

import { convertRgbToHex, convertHexToRgb, RGBtoHSL, hslToRgbString, hslToRGB, ensureRgba, ensureRgba_values } from "../../lib/colorFunctions";
import { translate } from "../../translations/translations";

const ModalColorPicker = ({ eb: { colorPicker, ebCssVars }, selectColor }) => {
  const closeBtn = useRef();
  const sliderSat = useRef();
  const sliderLum = useRef();
  // const sliderAlpha = useRef();
  const preview = useRef();

  const STANDARD_COLORS = [
    "rgba(0, 0, 0, 1)",
    "rgba(192,192,192, 1)",
    "rgba(128,128,128, 1)",
    "rgba(255, 255, 255, 1)",
    "rgba(128, 0, 0, 1)",
    "rgba(255, 0, 0, 1)",
    "rgba(128, 0, 128, 1)",
    "rgba(255, 0, 255, 1)",
    "rgba(0, 128, 0, 1)",
    "rgba(0, 255, 0, 1)",
    "rgba(128, 128, 0, 1)",
    "rgba(255, 255, 0, 1)",
    "rgba(0, 0, 128, 1)",
    "rgba(0, 0, 255, 1)",
    "rgba(0, 128, 128, 1)",
    "rgba(0, 255, 255, 1)",
  ];

  const [state, setState] = useState({
    hue: 200,
    sat: 81,
    lum: 18,
    alpha: 1,
    red: 9,
    green: 60,
    blue: 87,
    hex: "#093c57",
  });
  const { hue, sat, lum, alpha, red, green, blue, hex } = state;

  useEffect(() => {
    setInitialState();
    // eslint-disable-next-line
  }, [colorPicker.color]);

  useEffect(() => {
    updatePreview();
    // eslint-disable-next-line
  }, [state]);

  const setInitialState = () => {
    let rgba = ensureRgba_values(colorPicker.color);
    let hsla = RGBtoHSL(rgba[0], rgba[1], rgba[2], 1);
    updateSat(hsla[0]);
    updateLum(hsla[0], hsla[1]);
    setState({
      ...state,
      hue: hsla[0],
      sat: hsla[1],
      lum: hsla[2],
      alpha: 1,
      red: rgba[0],
      green: rgba[1],
      blue: rgba[2],
      hex: convertRgbToHex(rgba[0], rgba[1], rgba[2]),
    });
  };

  const onChange = (e) => {
    // Recalculate the different colors depending on which input was changed
    if (e.target.name === "hue" || e.target.name === "sat" || e.target.name === "lum") {
      let rgb = [];
      if (e.target.name === "hue") {
        updateSat(e.target.value);
        updateLum(e.target.value, sat);
        // Update red/green/blue/hex
        rgb = hslToRGB(e.target.value, sat, lum);
      }
      if (e.target.name === "sat") {
        updateLum(hue, e.target.value);
        rgb = hslToRGB(hue, e.target.value, lum);
      }
      if (e.target.name === "lum") {
        rgb = hslToRGB(hue, sat, e.target.value);
      }
      setState({
        ...state,
        [e.target.name]: e.target.value,
        red: rgb[0],
        green: rgb[1],
        blue: rgb[2],
        hex: convertRgbToHex(rgb[0], rgb[1], rgb[2]),
        initialState: false,
      });
    }
    if (e.target.name === "red" || e.target.name === "green" || e.target.name === "blue") {
      let rgb = [];
      e.target.name === "red" && (rgb = [e.target.value, green, blue]);
      e.target.name === "green" && (rgb = [red, e.target.value, blue]);
      e.target.name === "blue" && (rgb = [red, green, e.target.value]);
      let hsl = RGBtoHSL(rgb[0], rgb[1], rgb[2]);
      updateSat(hsl[0]);
      updateLum(hsl[0], hsl[1]);
      setState({
        ...state,
        [e.target.name]: e.target.value,
        hue: hsl[0],
        sat: hsl[1],
        lum: hsl[2],
        hex: convertRgbToHex(rgb[0], rgb[1], rgb[2]),
        initialState: false,
      });
    }
    if (e.target.name === "hex") {
      let isValidHex = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(e.target.value);
      if (isValidHex !== null) {
        let rgb = convertHexToRgb(e.target.value);
        let hsl = RGBtoHSL(rgb.r, rgb.g, rgb.b);
        updateSat(hsl[0]);
        updateLum(hsl[0], hsl[1]);
        setState({
          ...state,
          [e.target.name]: e.target.value,
          hue: hsl[0],
          sat: hsl[1],
          lum: hsl[2],
          red: rgb.r,
          green: rgb.g,
          blue: rgb.b,
          initialState: false,
        });
      } else {
        // If hex is not valid, the user is probably still typing. So do allow updating of the controlled input
        setState({
          ...state,
          [e.target.name]: e.target.value,
          initialState: false,
        });
      }
    }
    if (e.target.name === "alpha") {
      setState({
        ...state,
        [e.target.name]: e.target.value,
        initialState: false,
      });
    }
  };

  const selectColorFromMailTheme = (selectedColor) => {
    let foundRgba = ensureRgba(selectedColor).match(/rgb\((\d+), (\d+), (\d+)\)/);
    if (foundRgba !== null) {
      // Calculate and set all the state vars
      let hsl = RGBtoHSL(foundRgba[1], foundRgba[2], foundRgba[3]);
      updateSat(hsl[0], sliderSat);
      updateLum(hsl[0], hsl[1], sliderLum);
      setState({
        ...state,
        red: foundRgba[1],
        green: foundRgba[2],
        blue: foundRgba[3],
        hue: hsl[0],
        sat: hsl[1],
        lum: hsl[2],
        hex: convertRgbToHex(foundRgba[1], foundRgba[2], foundRgba[3]),
        alpha: 1,
        initialState: false,
      });
    }
  };

  const updateSat = (targetHue, slider = null) => {
    // slider === null && (slider = sliderSat);
    let css = `background: linear-gradient(left, hsl(${targetHue}, 0%, 50%) 0%, hsl(${targetHue}, 100%, 50%) 100%);
      background: -webkit-linear-gradient(left, hsl(${targetHue}, 0%, 50%) 0%, hsl(${targetHue}, 100%, 50%) 100%);
      background: -moz-linear-gradient(left, hsl(${targetHue}, 0%, 50%) 0%, hsl(${targetHue}, 100%, 50%) 100%);`;
    sliderSat.current.style.cssText = css;
  };

  const updateLum = (targetHue, targetSat, slider = null) => {
    // slider === null && (slider = sliderLum);
    let css = `background: linear-gradient(left, hsl(${targetHue}, ${targetSat}%, 0%) 0%, hsl(${targetHue}, ${targetSat}%, 50%) 50%, hsl(${targetHue}, ${targetSat}%, 100%) 100%);
    background: -webkit-linear-gradient(left, hsl(${targetHue}, ${targetSat}%, 0%) 0%, hsl(${targetHue}, ${targetSat}%, 50%) 50%, hsl(${targetHue}, ${targetSat}%, 100%) 100%);
    background: -moz-linear-gradient(left, hsl(${targetHue}, ${targetSat}%, 0%) 0%, hsl(${targetHue}, ${targetSat}%, 50%) 50%, hsl(${targetHue}, ${targetSat}%, 100%) 100%);`;
    sliderLum.current.style.cssText = css;
  };

  const updatePreview = () => {
    let { hsla } = getCurrentColor();
    // sliderAlpha.current.style.backgroundColor = hsl;
    preview.current.style.backgroundColor = hsla;
  };

  const getCurrentColor = () => {
    return { hsl: `hsl(${hue}, ${sat}%, ${lum}%)`, hsla: `hsla(${hue}, ${sat}%, ${lum}%, ${alpha})` };
  };

  const clickSelectColor = () => {
    selectColor(hslToRgbString(hue, sat, lum, alpha), colorPicker.varName);
  };

  return (
    <div
      className="modal fade"
      id="ModalColorPicker"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="ModalColorPickerLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg">
        <div
          className="modal-content"
          // style={{ height: "75vh" }}
        >
          <div className="modal-header">
            <h3 className="modal-title" id="ModalColorPickerLabel">
              {colorPicker.title}
            </h3>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ref={closeBtn}></button>
          </div>
          <div className="modal-body">
            <div className="row justify-content-center">
              <div className="col-6 align-items-center d-flex flex-column">
                <input
                  type="range"
                  min="0"
                  max="360"
                  step="1"
                  id={`sliderHue`}
                  name="hue"
                  value={hue}
                  onChange={onChange}
                  className="cpSlider cpHue mb-2"
                />
                <input
                  type="range"
                  min="0"
                  max="100"
                  step="1"
                  id={`sliderSat`}
                  name="sat"
                  value={sat}
                  onChange={onChange}
                  className="cpSlider cpSat mb-2"
                  ref={sliderSat}
                />
                <input
                  type="range"
                  min="0"
                  max="100"
                  step="1"
                  id={`sliderLum`}
                  name="lum"
                  value={lum}
                  onChange={onChange}
                  className="cpSlider cpLum mb-2"
                  ref={sliderLum}
                />
                {/* <input
                  type="range"
                  min="0"
                  max="1"
                  step="0.01"
                  id={`sliderAlpha`}
                  name="alpha"
                  value={alpha}
                  onChange={onChange}
                  className="cpSlider cpAlpha mb-2"
                  ref={sliderAlpha}
                  style={{ background: `url("/assets/img/transparant.png`, backgroundSize: "cover" }}
                /> */}
              </div>
              <div className="col-6">
                <p className="mb-2 text-bold">{translate("mModalColorPicker.yourEmailColorScheme", false, null)}</p>
                <div className="row">
                  {MAIL_COLOR_VAR_NAMES.map((colorVarName, i) => (
                    <div className="col-6 px-3 py-2 d-flex" key={colorVarName}>
                      <p className="m-0" style={{ width: "50%" }}>
                        {translate("mModalColorPicker.color", false, null)} {i + 1}:
                      </p>
                      <div
                        className="cursorPointer"
                        style={{ width: "50%", height: "100%", backgroundColor: ebCssVars[colorVarName] }}
                        onClick={() => selectColorFromMailTheme(ebCssVars[colorVarName])}
                      ></div>
                    </div>
                  ))}
                </div>
                <p className="mt-3 mb-2 text-bold">{translate("mModalColorPicker.standardColors", false, null)}</p>
                <div className="d-flex justify-content-start">
                  {STANDARD_COLORS.slice(0, 8).map((rgba, i) => (
                    <div
                      key={`standardcolor_1_${i}`}
                      className="cursorPointer mx-1 my-1"
                      style={{ height: "24px", width: "24px", background: rgba }}
                      onClick={() => selectColorFromMailTheme(rgba)}
                    ></div>
                  ))}
                </div>
                <div className="d-flex justify-content-start">
                  {STANDARD_COLORS.slice(8).map((rgba, i) => (
                    <div
                      key={`standardcolor_2_${i}`}
                      className="cursorPointer mx-1 my-1"
                      style={{ height: "24px", width: "24px", background: rgba }}
                      onClick={() => selectColorFromMailTheme(rgba)}
                    ></div>
                  ))}
                </div>
              </div>
              {/* <div className="col-12 d-flex flex-column align-items-center">
                <p className="text-bold mt-3 mb-2">Selected color</p>
                <div className="d-flex flex-row">
                  <div
                    className="cpPreviewTransparant"
                    // style={{ background: `url("/assets/img/transparant-square.png` }}
                  >
                    <div id="cpPreview" className="cpPreview" ref={preview}></div>
                  </div>
                  <div className="ml-3">
                    <div className="">
                      <div className="cpNumberInputRow">
                        <p className="cpNumberLabels">H</p>
                        <input type="text" className="cpNumberInputs" name="hue" value={hue} onChange={onChange} />
                      </div>
                      <div className="cpNumberInputRow">
                        <p className="cpNumberLabels">S</p>
                        <input type="text" className="cpNumberInputs" name="sat" value={sat} onChange={onChange} />
                      </div>
                      <div className="cpNumberInputRow">
                        <p className="cpNumberLabels">L</p>
                        <input type="text" className="cpNumberInputs" name="lum" value={lum} onChange={onChange} />
                      </div>
                    </div>
                    <div className="">
                      <div className="cpNumberInputRow">
                        <p className="cpNumberLabels">R</p>
                        <input type="text" className="cpNumberInputs" name="red" value={red} onChange={onChange} />
                      </div>
                      <div className="cpNumberInputRow">
                        <p className="cpNumberLabels">G</p>
                        <input type="text" className="cpNumberInputs" name="green" value={green} onChange={onChange} />
                      </div>
                      <div className="cpNumberInputRow">
                        <p className="cpNumberLabels">B</p>
                        <input type="text" className="cpNumberInputs" name="blue" value={blue} onChange={onChange} />
                      </div>
                    </div>
                    <div className="">
                      <div className="cpNumberInputRow">
                        <input type="text" className="cpNumberInputsHex" maxLength="7" name="hex" value={hex} onChange={onChange} />
                      </div>
                      <div className="cpNumberInputRow"></div>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="col-6">
                <p className="text-center text-bold mt-3 mb-2">{translate("mModalColorPicker.selectedColor", false, null)}</p>
                <div className="d-flex flex-row">
                  <div className="cpPreviewTransparant" style={{ background: `url("/assets/img/transparant-square.png` }}>
                    <div id="cpPreview" className="cpPreview" ref={preview}></div>
                  </div>
                  <div className="d-flex flex-column ms-3 fontSize09">
                    <div className="d-flex align-items-center">
                      <div className="text-center" style={{ width: "18.33%" }}>
                        <p className="m-0">H</p>
                      </div>
                      <div className="text-center" style={{ width: "18.33%" }}>
                        <p className="m-0">{hue}</p>
                        {/* <input type="text" className="form-control form-control-sm text-center" name="hue" value={hue} onChange={onChange} /> */}
                      </div>
                      <div className="text-center" style={{ width: "18.33%" }}>
                        <p className="m-0">S</p>
                      </div>
                      <div className="text-center" style={{ width: "18.33%" }}>
                        <p className="m-0">{sat}</p>
                        {/* <input type="text" className="form-control form-control-sm text-center" name="sat" value={sat} onChange={onChange} /> */}
                      </div>
                      <div className="text-center" style={{ width: "18.33%" }}>
                        <p className="m-0">L</p>
                      </div>
                      <div className="text-center" style={{ width: "18.33%" }}>
                        <p className="m-0">{lum}</p>
                        {/* <input type="text" className="form-control form-control-sm text-center" name="lum" value={lum} onChange={onChange} /> */}
                      </div>
                    </div>
                    <div className="d-flex align-items-center mt-2">
                      <div className="text-center" style={{ width: "18.33%" }}>
                        <p className="m-0">R</p>
                      </div>
                      <div className="text-center" style={{ width: "18.33%" }}>
                        <input type="text" className="form-control form-control-sm text-center" name="red" value={red} onChange={onChange} />
                      </div>
                      <div className="text-center" style={{ width: "18.33%" }}>
                        <p className="m-0">G</p>
                      </div>
                      <div className="text-center" style={{ width: "18.33%" }}>
                        <input type="text" className="form-control form-control-sm text-center" name="green" value={green} onChange={onChange} />
                      </div>
                      <div className="text-center" style={{ width: "18.33%" }}>
                        <p className="m-0">B</p>
                      </div>
                      <div className="text-center" style={{ width: "18.33%" }}>
                        <input type="text" className="form-control form-control-sm text-center" name="blue" value={blue} onChange={onChange} />
                      </div>
                    </div>
                    <div className="d-flex align-items-center mt-2">
                      <div className="text-center" style={{ width: "18.33%" }}>
                        <p className="m-0">Hex</p>
                      </div>
                      <div className="text-center" style={{ width: "36.66%" }}>
                        <p className="m-0">{hex}</p>
                        {/* <input
                          type="text"
                          className="form-control form-control-sm text-center"
                          maxLength="7"
                          name="hex"
                          value={hex}
                          onChange={onChange}
                        /> */}
                      </div>
                      {/* <div className="text-center" style={{ width: "18.33%" }}></div>
                      <div className="text-center" style={{ width: "18.33%" }}>
                        <p className="m-0">A</p>
                      </div>
                      <div className="text-center" style={{ width: "18.33%" }}>
                        <input type="text" className="form-control form-control-sm text-center" name="alpha" value={alpha} onChange={onChange} />
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer justify-content-between">
            <button type="button" className="btn btn-gray px-4" data-bs-dismiss="modal">
              {translate("mModalColorPicker.close", false, null)}
            </button>
            <button type="button" className="btn btn-success px-4" data-bs-dismiss="modal" onClick={clickSelectColor}>
              {translate("mModalColorPicker.selectColor", false, null)}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

ModalColorPicker.propTypes = {
  eb: PropTypes.object.isRequired,
  selectColor: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  eb: state.eb,
});

export default connect(mapStateToProps, { selectColor })(ModalColorPicker);
