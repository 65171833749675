export const FixedCss = `body {
  margin: 0;
  font-family: var(--font-body);
  font-size: 1rem;
  font-weight: var(--font-body-weight);
  background-color: var(--body-bg);
  mso-line-height-rule: exactly;
}

p, a, h1, h2, h3, h4, h5, h6 {
  line-height: var(--body-line-height) !important;
}

.fontBody, body {
  font-family: var(--font-body);
  font-weight: var(--font-body-weight);
}

.fontHeaders, h1, h2, h3, h4, h5, h6 {
    font-family: var(--font-headers);
    font-weight: var(--font-headers-weight);
}

.moz-text-html .column-container {
  max-width: 600px !important;
  width: 600px !important;
  overflow: hidden;
}
.moz-text-html .column {
  display: table-cell;
  border-spacing: 0 !important; 
  border-collapse: collapse; 
  mso-table-lspace: 0pt; 
  mso-table-rspace: 0pt;
}
.column-table {
  border-spacing: 0 !important; 
  border-collapse: collapse; 
  mso-table-lspace: 0pt; 
  mso-table-rspace: 0pt;
  line-height: 0;
}
.moz-text-html .vertical-align-top {
  vertical-align: top;
}
.moz-text-html .vertical-align-middle {
  vertical-align: middle;
}
.moz-text-html .vertical-align-bottom {
  vertical-align: bottom;
}
.moz-text-html .eb-component .col-3 {
  max-width: 150px !important;
  width: 150px !important;
}
.moz-text-html .eb-component .col-4 {
  max-width: 200px !important;
  width: 200px !important;
}
.moz-text-html .eb-component .col-5 {
  max-width: 250px !important;
  width: 250px !important;
}
.moz-text-html .eb-component .col-6 {
  max-width: 300px !important;
  width: 300px !important;
}
.moz-text-html .eb-component .col-7 {
  max-width: 350px !important;
  width: 350px !important;
}
.moz-text-html .eb-component .col-8 {
  max-width: 400px !important;
  width: 400px !important;
}
.moz-text-html .eb-component .col-9 {
  max-width: 450px !important;
  width: 450px !important;
}
.moz-text-html .eb-component .col-12 {
  max-width: 600px !important;
  width: 600px !important;
}
[owa] .column-container {
  max-width: 600px !important;
  width: 600px !important;
  overflow: hidden;
}
[owa] .column {
  display: table-cell;
  border-spacing: 0 !important; 
  border-collapse: collapse; 
  mso-table-lspace: 0pt; 
  mso-table-rspace: 0pt;
}
.column-table {
  border-spacing: 0 !important; 
  border-collapse: collapse; 
  mso-table-lspace: 0pt; 
  mso-table-rspace: 0pt;
  line-height: 0;
}
[owa] .vertical-align-top {
  vertical-align: top;
}
[owa] .vertical-align-middle {
  vertical-align: middle;
}
[owa] .vertical-align-bottom {
  vertical-align: bottom;
}
[owa] .eb-component .col-3 {
  max-width: 150px !important;
  width: 150px !important;
}
[owa] .eb-component .col-4 {
  max-width: 200px !important;
  width: 200px !important;
}
[owa] .eb-component .col-5 {
  max-width: 250px !important;
  width: 250px !important;
}
[owa] .eb-component .col-6 {
  max-width: 300px !important;
  width: 300px !important;
}
[owa] .eb-component .col-7 {
  max-width: 350px !important;
  width: 350px !important;
}
[owa] .eb-component .col-8 {
  max-width: 400px !important;
  width: 400px !important;
}
[owa] .eb-component .col-9 {
  max-width: 450px !important;
  width: 450px !important;
}
[owa] .eb-component .col-12 {
  max-width: 600px !important;
  width: 600px !important;
}
@media only screen and (min-width: 640px) {
  .column-container {
    max-width: 600px !important;
    width: 600px !important;
    overflow: hidden;
  }
  .column {
    display: table-cell;
    border-spacing: 0 !important; 
    border-collapse: collapse; 
    mso-table-lspace: 0pt; 
    mso-table-rspace: 0pt;
  }
  .column-table {
    border-spacing: 0 !important; 
    border-collapse: collapse; 
    mso-table-lspace: 0pt; 
    mso-table-rspace: 0pt;
    line-height: 0;
  }
  .vertical-align-top {
    vertical-align: top;
  }
  .vertical-align-middle {
    vertical-align: middle;
  }
  .vertical-align-bottom {
    vertical-align: bottom;
  }
  .eb-component .col-3 {
    max-width: 150px !important;
    width: 150px !important;
  }
  .eb-component .col-4 {
    max-width: 200px !important;
    width: 200px !important;
  }
  .eb-component .col-5 {
    max-width: 250px !important;
    width: 250px !important;
  }
  .eb-component .col-6 {
    max-width: 300px !important;
    width: 300px !important;
  }
  .eb-component .col-7 {
    max-width: 350px !important;
    width: 350px !important;
  }
  .eb-component .col-8 {
    max-width: 400px !important;
    width: 400px !important;
  }
  .eb-component .col-9 {
    max-width: 450px !important;
    width: 450px !important;
  }
  .eb-component .col-12 {
    max-width: 600px !important;
    width: 600px !important;
  }
}
@media only screen and (max-width: 639px) {
  img.stretch-on-mobile {
    height: auto !important;
    width: 100% !important;
  }
  .display_block_on_small_screens {
    display: block;
  }
  ul,
  blockquote {
    margin: 0;
    padding: 1em 40px;
  }
}`;
