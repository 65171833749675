export const template14 = {
  id: "660353b21c66b648f4c0da9a",
  name: { en: "Reminder for an event", nl: "Herinnering voor een evenement" },
  desc: { en: "Remind your visitors of an upcoming event", nl: "Verstuur een herinnering aan uw bezoekers voor een komend evenement" },
  categories: ["text"],
  ebContent: [
    {
      pageId: "b6452bc4-7f58-4aa8-add4-f2a32ca50deb",
      version: "A",
      components: [
        {
          componentId: "EgrYt9",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "basics-7-component-EgrYt9"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "LuVrnm",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "basics-7-col-container-EgrYt9"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "R80hKx",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-12"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "x55tJd",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "G24mfz",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "RLlnuE",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "ld5Qxp",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["basics-7-col-td-wrapper-EgrYt9"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-name", value: "divider" },
                                    { property: "data-editable", value: "true" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "wbgrBC",
                                      type: "",
                                      htmlTagName: "div",
                                      classes: ["basics-7-divider-EgrYt9"],
                                      styles: [],
                                      attributes: [{ property: "data-elementgetter1", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "pA0hH8",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "basics-1-component-pA0hH8"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "ifnzSM",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "basics-1-col-container-pA0hH8"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "U80MXf",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-12"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "Fjzgqe",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "y0bksc",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "qnN4xw",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "TLJaKz",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["basics-1-col-td-wrapper-pA0hH8"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "cz7AYn",
                                      type: "",
                                      htmlTagName: "h1",
                                      classes: ["basics-1-title-pA0hH8"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "h" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Reminder: Tomorrow is the day!",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "CDCkka",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "basics-2-component-CDCkka"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "I4Z55B",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "basics-2-col-container-CDCkka"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "MyhxRr",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-12"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "YlupOm",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "MgzHSl",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "wiN4LW",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "RyrZk2",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["basics-2-col-td-wrapper-CDCkka"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "BcbnXC",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["basics-2-text-CDCkka"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content:
                                            "We'd like to remind you that tomorrow is our conference on the latest developments in technology.",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "rSUdpR",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["gLjHzHbfGSHQ2T6b1HuZo2bomxrpPs"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-name", value: "p" },
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "strong",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "",
                                          children: [
                                            {
                                              childId: "",
                                              type: "",
                                              htmlTagName: "ins",
                                              classes: [],
                                              styles: [],
                                              attributes: [],
                                              content: "",
                                              children: [
                                                {
                                                  childId: "",
                                                  type: "",
                                                  htmlTagName: "textNode",
                                                  classes: [],
                                                  styles: [],
                                                  attributes: [],
                                                  content: "Time",
                                                  children: [],
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: ": The conference starts at 11.30 with a walking lunch.",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "bgo0ZP",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["zQdKSazAwCrY9NR3F24B7HVSfQJBeX"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-name", value: "p" },
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "strong",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "",
                                          children: [
                                            {
                                              childId: "",
                                              type: "",
                                              htmlTagName: "ins",
                                              classes: [],
                                              styles: [],
                                              attributes: [],
                                              content: "",
                                              children: [
                                                {
                                                  childId: "",
                                                  type: "",
                                                  htmlTagName: "textNode",
                                                  classes: [],
                                                  styles: [],
                                                  attributes: [],
                                                  content: "Location",
                                                  children: [],
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: ": Street 17, 1000 XX, Amsterdam",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "Njo0j0",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "basics-12-component-Njo0j0"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "CPg91w",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "basics-12-col-container-Njo0j0"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "BP5e4B",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-12"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "K2bNZv",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "hiu9dR",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "TzuvGM",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "xvjODA",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["basics-12-col-td-wrapper-Njo0j0"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "QUTUc7",
                                      type: "",
                                      htmlTagName: "div",
                                      classes: ["basics-12-bgdiv-Njo0j0"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-name", value: "bgdiv" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "p25m4f",
                                          type: "",
                                          htmlTagName: "table",
                                          classes: ["column-table", "basics-12-table-table-Njo0j0"],
                                          styles: [],
                                          attributes: [
                                            { property: "role", value: "presentation" },
                                            { property: "width", value: "100%" },
                                            { property: "cellspacing", value: "0" },
                                            { property: "cellpadding", value: "0" },
                                          ],
                                          content: "",
                                          children: [
                                            {
                                              childId: "c9rog4",
                                              type: "",
                                              htmlTagName: "tbody",
                                              classes: [],
                                              styles: [],
                                              attributes: [],
                                              content: "",
                                              children: [
                                                {
                                                  childId: "ldxOhz",
                                                  type: "",
                                                  htmlTagName: "tr",
                                                  classes: ["basics-12-table-row-Njo0j0"],
                                                  styles: [],
                                                  attributes: [],
                                                  content: "",
                                                  children: [
                                                    {
                                                      childId: "nomNXO",
                                                      type: "",
                                                      htmlTagName: "td",
                                                      classes: ["basics-12-table-cell-all-Njo0j0", "basics-12-table-cell-img-Njo0j0"],
                                                      styles: [],
                                                      attributes: [{ property: "data-tablecell", value: "true" }],
                                                      content: "",
                                                      children: [
                                                        {
                                                          childId: "LiKlHj",
                                                          type: "",
                                                          htmlTagName: "img",
                                                          classes: ["basics-12-img-Njo0j0"],
                                                          styles: [],
                                                          attributes: [
                                                            { property: "data-editable", value: "true" },
                                                            { property: "data-name", value: "img" },
                                                            { property: "src", value: "https://cdn.satonda.com/eb/assets/alert.png" },
                                                            { property: "alt", value: "Icon" },
                                                          ],
                                                          content: "",
                                                          children: [],
                                                        },
                                                      ],
                                                    },
                                                    {
                                                      childId: "v7k2PY",
                                                      type: "",
                                                      htmlTagName: "td",
                                                      classes: ["basics-12-table-cell-all-Njo0j0"],
                                                      styles: [],
                                                      attributes: [{ property: "data-tablecell", value: "true" }],
                                                      content: "",
                                                      children: [
                                                        {
                                                          childId: "ST519e",
                                                          type: "",
                                                          htmlTagName: "p",
                                                          classes: ["basics-12-table-text-Njo0j0"],
                                                          styles: [],
                                                          attributes: [
                                                            { property: "data-editable", value: "true" },
                                                            { property: "data-texteditable", value: "true" },
                                                            { property: "data-name", value: "p" },
                                                          ],
                                                          content: "",
                                                          children: [
                                                            {
                                                              childId: "",
                                                              type: "",
                                                              htmlTagName: "textNode",
                                                              classes: [],
                                                              styles: [],
                                                              attributes: [],
                                                              content: "Don't forget to bring your badge!",
                                                              children: [],
                                                            },
                                                          ],
                                                        },
                                                      ],
                                                    },
                                                  ],
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "RPjrKa",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "basics-2-component-RPjrKa"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "cMN6f2",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "basics-2-col-container-RPjrKa"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "rzZRAH",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-12"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "uz4jPK",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "oIw2Ph",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "Gny9Uj",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "PpZchh",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["basics-2-col-td-wrapper-RPjrKa"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "ckdZeW",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["basics-2-text-RPjrKa"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "We're looking forward to seeing you tomorrow!",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "kkEyiN",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "footer-9-component-kkEyiN"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "PBr6vu",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "footer-9-col-container-kkEyiN"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "DVr8os",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-12"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "jBKqoK",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "lqykd3",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "RoYXno",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "OgfIV6",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["footer-9-col-td-wrapper-kkEyiN"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "pkGRUo",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["footer-9-text1-kkEyiN"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                        { property: "data-uniqueclassname", value: "true" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "© Company name",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "VfejaO",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["footer-9-text2-kkEyiN"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                        { property: "data-uniqueclassname", value: "true" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Streetname 1, 1000 XX Amsterdam, The Netherlands",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "UmCJ94",
                                      type: "element",
                                      htmlTagName: "a",
                                      classes: ["footer-9-link-kkEyiN"],
                                      styles: [],
                                      attributes: [
                                        { property: "href", value: "#!" },
                                        { property: "data-href", value: "%UNSUBSCRIBE_LINK%" },
                                        { property: "data-target", value: "_blank" },
                                        { property: "data-editable", value: "true" },
                                        { property: "data-name", value: "a" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Unsubscribe",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  ebCustomCss: [
    {
      componentId: "EgrYt9",
      classes: [
        {
          className: "basics-7-divider-EgrYt9",
          pseudo: "",
          rules: [
            { property: "display", value: "inline-block" },
            { property: "height", value: "25px" },
            { property: "width", value: "100%" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
            { property: "border-radius", value: "0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(230, 230, 230)" },
          ],
        },
        {
          className: "basics-7-component-EgrYt9",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
          ],
        },
        {
          className: "basics-7-col-container-EgrYt9",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "background-color", value: "rgb(255, 255, 255)" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "basics-7-col-td-wrapper-EgrYt9",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0rem 0rem 0rem 0rem" },
            { property: "text-align", value: "start" },
          ],
        },
      ],
    },
    {
      componentId: "pA0hH8",
      classes: [
        {
          className: "basics-1-title-pA0hH8",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgb(108, 117, 125)" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "basics-1-component-pA0hH8",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
          ],
        },
        {
          className: "basics-1-col-container-pA0hH8",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "background-color", value: "rgb(255, 255, 255)" },
            { property: "padding-top", value: "0.5rem" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "basics-1-col-td-wrapper-pA0hH8",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0.5rem 0.5rem 0.5rem 0.5rem" },
            { property: "text-align", value: "start" },
          ],
        },
      ],
    },
    {
      componentId: "CDCkka",
      classes: [
        {
          className: "basics-2-text-CDCkka",
          pseudo: "",
          rules: [
            { property: "text-align", value: "start" },
            { property: "color", value: "rgb(108, 117, 125)" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "gLjHzHbfGSHQ2T6b1HuZo2bomxrpPs",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(108, 117, 125)" },
            { property: "margin", value: "0.5rem 0rem 0.5rem 0rem" },
          ],
        },
        { className: "gLjHzHbfGSHQ2T6b1HuZo2bomxrpPs", pseudo: "hover", rules: [] },
        {
          className: "zQdKSazAwCrY9NR3F24B7HVSfQJBeX",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(108, 117, 125)" },
            { property: "margin", value: "0.5rem 0rem 0.5rem 0rem" },
          ],
        },
        { className: "zQdKSazAwCrY9NR3F24B7HVSfQJBeX", pseudo: "hover", rules: [] },
        {
          className: "basics-2-component-CDCkka",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
          ],
        },
        {
          className: "basics-2-col-container-CDCkka",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "background-color", value: "rgb(255, 255, 255)" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "basics-2-col-td-wrapper-CDCkka",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0.5rem 0.5rem 0.5rem 0.5rem" },
            { property: "text-align", value: "start" },
          ],
        },
      ],
    },
    {
      componentId: "Njo0j0",
      classes: [
        { className: "basics-12-table-table-Njo0j0", pseudo: "", rules: [] },
        { className: "basics-12-table-row-Njo0j0", pseudo: "", rules: [] },
        {
          className: "basics-12-table-cell-img-Njo0j0",
          pseudo: "",
          rules: [
            { property: "width", value: "60px" },
            { property: "text-align", value: "center" },
          ],
        },
        {
          className: "basics-12-table-cell-all-Njo0j0",
          pseudo: "",
          rules: [
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "padding", value: "0px 10px 0px 10px" },
          ],
        },
        {
          className: "basics-12-component-Njo0j0",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
          ],
        },
        {
          className: "basics-12-col-container-Njo0j0",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "background-color", value: "rgb(255, 255, 255)" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "basics-12-col-td-wrapper-Njo0j0",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0.5rem 0.5rem 0.5rem 0.5rem" },
            { property: "text-align", value: "start" },
          ],
        },
        {
          className: "basics-12-bgdiv-Njo0j0",
          pseudo: "",
          rules: [
            { property: "padding", value: "10px 0px 10px 0px" },
            { property: "background-color", value: "rgb(255, 220, 184)" },
            { property: "background", value: "rgb(255, 220, 184)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(245, 126, 0)" },
            { property: "border-radius", value: "0.75rem" },
          ],
        },
        {
          className: "basics-12-img-Njo0j0",
          pseudo: "",
          rules: [
            { property: "outline", value: "none" },
            { property: "-ms-interpolation-mode", value: "bicubic" },
            { property: "width", value: "auto" },
            { property: "height", value: "30px" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "0px" },
            { property: "border-color", value: "rgb(0, 0, 0)" },
            { property: "border-radius", value: "0rem" },
          ],
        },
        {
          className: "basics-12-table-text-Njo0j0",
          pseudo: "",
          rules: [
            { property: "text-align", value: "start" },
            { property: "color", value: "rgb(246, 129, 0)" },
            { property: "font-size", value: "12px" },
            { property: "font-weight", value: "bold" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
          ],
        },
      ],
    },
    {
      componentId: "RPjrKa",
      classes: [
        {
          className: "basics-2-text-RPjrKa",
          pseudo: "",
          rules: [
            { property: "text-align", value: "start" },
            { property: "color", value: "rgb(108, 117, 125)" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "basics-2-component-RPjrKa",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
          ],
        },
        {
          className: "basics-2-col-container-RPjrKa",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-top", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "background-color", value: "rgb(255, 255, 255)" },
            { property: "padding-bottom", value: "0.75rem" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "basics-2-col-td-wrapper-RPjrKa",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0.5rem 0.5rem 0.5rem 0.5rem" },
            { property: "text-align", value: "start" },
          ],
        },
      ],
    },
    {
      componentId: "kkEyiN",
      classes: [
        {
          className: "footer-9-text1-kkEyiN",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(108, 117, 125)" },
            { property: "margin", value: "0rem 0rem 0.25rem 0rem" },
            { property: "text-align", value: "center" },
            { property: "font-size", value: "12px" },
          ],
        },
        {
          className: "footer-9-text2-kkEyiN",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(108, 117, 125)" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-align", value: "center" },
            { property: "font-size", value: "12px" },
          ],
        },
        {
          className: "footer-9-link-kkEyiN",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(108, 117, 125)" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "display", value: "block" },
            { property: "text-align", value: "center" },
            { property: "text-decoration", value: "underline" },
            { property: "font-size", value: "12px" },
          ],
        },
        {
          className: "footer-9-component-kkEyiN",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
          ],
        },
        {
          className: "footer-9-col-container-kkEyiN",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(234, 237, 240)" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "padding-top", value: "1.5rem" },
            { property: "padding-bottom", value: "3rem" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "footer-9-col-td-wrapper-kkEyiN",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0.5rem 0rem 0.5rem 0rem" },
            { property: "text-align", value: "start" },
          ],
        },
      ],
    },
  ],
  ebCssVars: {
    fontHeadersName: "Arial",
    fontHeadersWeight: "400",
    fontBodyName: "Arial",
    fontBodyWeight: "400",
    color1: "rgba(229, 42, 5, 1)",
    color2: "rgba(246, 129, 0, 1)",
    color3: "rgba(40, 167, 69, 1)",
    color4: "rgba(13, 202, 240, 1)",
    color5: "rgba(255, 193, 7, 1)",
    color6: "rgba(220, 53, 69, 1)",
    color7: "rgba(233, 236, 239, 1)",
    color8: "rgba(33, 37, 41, 1)",
    color9: "rgba(206, 212, 218, 1)",
    color10: "rgba(108, 117, 125, 1)",
  },
};
