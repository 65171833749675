import React from "react";
import { Outlet } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { MYSATONDA_RETURN_URL } from "../../lib/generalVars";

const AuthRequired = ({ auth: { isAuthenticated, loading } }) => {
  return !isAuthenticated && !loading ? window.location.replace(MYSATONDA_RETURN_URL) : <Outlet />;
};

AuthRequired.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, null)(AuthRequired);
