export const basics12 = {
  id: "basics-12",
  name: "Basic building blocks - Call out box",
  html: {
    componentId: "",
    type: "component",
    htmlTagName: "div",
    classes: ["eb-component", "basics-12-component"],
    styles: [],
    attributes: [{ property: "data-name", value: "component" }],
    content: "",
    children: [
      {
        childId: "",
        type: "",
        htmlTagName: "div",
        classes: ["column-container", "basics-12-col-container"],
        styles: [],
        attributes: [],
        content: "",
        children: [
          {
            childId: "",
            type: "",
            htmlTagName: "div",
            classes: ["column", "col-12"],
            styles: [],
            attributes: [],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "table",
                classes: ["column-table"],
                styles: [],
                attributes: [
                  { property: "role", value: "presentation" },
                  { property: "width", value: "100%" },
                  { property: "cellspacing", value: "0" },
                  { property: "cellpadding", value: "0" },
                ],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "tbody",
                    classes: [],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "tr",
                        classes: [],
                        styles: [],
                        attributes: [],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "td",
                            classes: ["basics-12-col-td-wrapper"],
                            styles: [],
                            attributes: [],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "div",
                                classes: ["basics-12-bgdiv"],
                                styles: [],
                                attributes: [
                                  { property: "data-editable", value: "true" },
                                  { property: "data-name", value: "bgdiv" },
                                ],
                                content: "",
                                children: [
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "table",
                                    classes: ["column-table", "basics-12-table-table"],
                                    styles: [],
                                    attributes: [
                                      { property: "role", value: "presentation" },
                                      { property: "width", value: "100%" },
                                      { property: "cellspacing", value: "0" },
                                      { property: "cellpadding", value: "0" },
                                    ],
                                    content: "",
                                    children: [
                                      {
                                        childId: "",
                                        type: "",
                                        htmlTagName: "tbody",
                                        classes: [],
                                        styles: [],
                                        attributes: [],
                                        content: "",
                                        children: [
                                          {
                                            childId: "",
                                            type: "",
                                            htmlTagName: "tr",
                                            classes: ["basics-12-table-row"],
                                            styles: [],
                                            attributes: [],
                                            content: "",
                                            children: [
                                              {
                                                childId: "",
                                                type: "",
                                                htmlTagName: "td",
                                                classes: ["basics-12-table-cell-all", "basics-12-table-cell-img"],
                                                styles: [],
                                                attributes: [{ property: "data-tablecell", value: "true" }],
                                                content: "",
                                                children: [
                                                  {
                                                    childId: "",
                                                    type: "",
                                                    htmlTagName: "img",
                                                    classes: ["basics-12-img"],
                                                    styles: [],
                                                    attributes: [
                                                      {
                                                        property: "src",
                                                        value: "https://cdn.satonda.com/eb/assets/icon_star.png",
                                                      },
                                                      { property: "alt", value: "Icon" },
                                                      { property: "data-editable", value: "true" },
                                                      { property: "data-name", value: "img" },
                                                    ],
                                                    content: "",
                                                    children: [],
                                                  },
                                                ],
                                              },
                                              {
                                                childId: "",
                                                type: "",
                                                htmlTagName: "td",
                                                classes: ["basics-12-table-cell-all"],
                                                styles: [],
                                                attributes: [{ property: "data-tablecell", value: "true" }],
                                                content: "",
                                                children: [
                                                  {
                                                    childId: "",
                                                    type: "",
                                                    htmlTagName: "p",
                                                    classes: ["basics-12-table-text"],
                                                    styles: [],
                                                    attributes: [
                                                      { property: "data-editable", value: "true" },
                                                      { property: "data-texteditable", value: "true" },
                                                      { property: "data-name", value: "p" },
                                                    ],
                                                    content: "",
                                                    children: [
                                                      {
                                                        childId: "",
                                                        type: "",
                                                        htmlTagName: "textNode",
                                                        classes: [],
                                                        styles: [],
                                                        attributes: [],
                                                        content:
                                                          "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Beatae ratione omnis temporibus vero fugit quam necessitatibus dolore voluptatibus libero nesciunt!",
                                                        children: [],
                                                      },
                                                    ],
                                                  },
                                                ],
                                              },
                                            ],
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  css: {
    componentId: "",
    classes: [
      {
        className: "basics-12-component",
        pseudo: "",
        rules: [{ property: "padding", value: "0 10px 0 10px" }],
      },
      {
        className: "basics-12-col-container",
        pseudo: "",
        rules: [
          { property: "min-width", value: "280px" },
          { property: "max-width", value: "600px" },
          { property: "width", value: "100%" },
          { property: "margin", value: "0 auto 0 auto" },
          { property: "border-collapse", value: "collapse" },
          { property: "border-spacing", value: "0" },
          { property: "background", value: "rgb(255, 255, 255)" },
          { property: "background-color", value: "rgb(255, 255, 255)" },
          { property: "border-radius", value: "0 0 0 0" },
          { property: "border-style", value: "none none none none" },
          { property: "border-width", value: "1px" },
          { property: "border-color", value: "rgb(210, 210, 210)" },
          { property: "padding-top", value: "0" },
          { property: "padding-bottom", value: "0" },
          { property: "padding-left", value: "0" },
          { property: "padding-right", value: "0" },
        ],
      },
      {
        className: "basics-12-col-td-wrapper",
        pseudo: "",
        rules: [
          { property: "border-collapse", value: "collapse" },
          { property: "word-break", value: "break-word" },
          { property: "text-align", value: "start" },
          { property: "padding", value: "0.5rem 0rem 0.5rem 0rem" },
        ],
      },
      {
        className: "basics-12-bgdiv",
        pseudo: "",
        rules: [
          { property: "background", value: "rgb(253, 221, 215)" },
          { property: "background-color", value: "rgb(253, 221, 215)" },
          { property: "border-radius", value: "0.75rem" },
          { property: "border-style", value: "solid solid solid solid" },
          { property: "border-width", value: "1px" },
          { property: "border-color", value: "rgb(229, 42, 5)" },
          { property: "padding", value: "10px 0px 10px 0px" },
        ],
      },
      {
        className: "basics-12-table-table",
        pseudo: "",
        rules: [],
      },
      {
        className: "basics-12-table-row",
        pseudo: "",
        rules: [],
      },
      {
        className: "basics-12-table-cell-img",
        pseudo: "",
        rules: [
          { property: "width", value: "60px" },
          { property: "text-align", value: "center" },
        ],
      },
      {
        className: "basics-12-table-cell-all",
        pseudo: "",
        rules: [
          { property: "border-style", value: "none none none none" },
          { property: "border-width", value: "1px" },
          { property: "border-color", value: "rgb(210, 210, 210)" },
          { property: "padding", value: "0px 10px 0px 10px" },
        ],
      },
      {
        className: "basics-12-table-text",
        pseudo: "",
        rules: [
          { property: "color", value: "rgb(33, 37, 41)" },
          { property: "margin", value: "0rem 0rem 0rem 0rem" },
          { property: "text-align", value: "start" },
        ],
      },
      {
        className: "basics-12-img",
        pseudo: "",
        rules: [
          { property: "outline", value: "none" },
          { property: "-ms-interpolation-mode", value: "bicubic" },
          { property: "width", value: "auto" },
          { property: "height", value: "30px" },
          { property: "margin", value: "0rem 0rem 0rem 0rem" },
        ],
      },
    ],
  },
};
