import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Slider from "./formComponents/Slider";
import Divider from "./formComponents/Divider";
import AlignHori from "./AlignHori";
import Border from "./Border";
import BorderRadius from "./BorderRadius";
import Custom from "./formComponents/Custom";
import GradientColor from "./formComponents/GradientColor";

import { formGetStartValue } from "../../../../lib/editFunctions";
import { EDIT_FORM_ID_PREFIX } from "../../../../lib/editFunctions";
import { translate } from "../../../../translations/translations";

const ComponentDivider = ({ eb: { startStyles } }) => {
  const DividerWidth = () => {
    const [valueSelect, setValueSelect] = useState("");
    const [valuePx, setValuePx] = useState(0);
    useEffect(() => {
      setValueSelect(formGetStartValue(startStyles, "dividerWidth"));
      setValuePx(formGetStartValue(startStyles, "dividerWidthPx"));
      // [formGetStartValue(startStyles, "dividerWidth"), formGetStartValue(startStyles, "dividerWidthPx")]
      // eslint-disable-next-line
    }, []);

    return (
      <>
        {/* Dropdown */}
        <select
          className="form-select form-select-sm"
          id={`${EDIT_FORM_ID_PREFIX}dividerWidth`}
          value={valueSelect}
          onChange={(e) => setValueSelect(e.target.value)}
        >
          <option value="100%">{translate("cEditForms.fullWidth", false, null)}</option>
          <option value="">{translate("cEditForms.setWidthManually", false, null)}</option>
        </select>
        {/* Specific width in px */}
        {valueSelect === "" && (
          <div className="d-flex justify-content-center mt-3">
            <input
              type="range"
              className="form-range"
              min="0"
              max="250"
              step="1"
              id={`${EDIT_FORM_ID_PREFIX}dividerWidthPx`}
              value={valuePx}
              onChange={(e) => setValuePx(e.target.value)}
            />
            <span className="slider-value">{valuePx}</span>
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <AlignHori />
      <Divider />
      <Slider
        label={translate("cEditForms.dividerHeight", false, null)}
        id={"height"}
        min={1}
        max={250}
        step={1}
        startValue={formGetStartValue(startStyles, "height")}
        tooltipText={translate("cEditForms.ttDividerHeight", false, null)}
      />
      <Custom
        label={translate("cEditForms.dividerWidth", false, null)}
        column={true}
        customComponent={DividerWidth}
        tooltipText={translate("cEditForms.ttDividerWidth", false, null)}
      />
      <Divider />
      <GradientColor
        label={translate("cEditForms.bgColor", false, null)}
        arrBgVarNames={[
          "bgColor",
          "boolBgIsGradient",
          "bgGradientDirection",
          "bgGradientColor1",
          "bgGradientColor2",
          "bgGradientStop1",
          "bgGradientStop2",
        ]}
        tooltipText={translate("cEditForms.bgColorComponentDivider", false, null)}
      />
      <Divider />
      <Border />
      <Divider />
      <BorderRadius />
    </>
  );
};

ComponentDivider.propTypes = {
  eb: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  eb: state.eb,
});

export default connect(mapStateToProps, null)(ComponentDivider);
