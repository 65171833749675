export const template1 = {
  id: "66032f864ac82ad5e37cdcad",
  name: { en: "Text only", nl: "Puur tekst" },
  desc: { en: "A simple email with only text", nl: "Een simpele email met alleen tekst" },
  categories: ["text"],
  ebContent: [
    {
      pageId: "30e8516a-d551-4ca7-8f6a-f8713a3640bf",
      version: "A",
      components: [
        {
          componentId: "hIsnJg",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "basics-7-component-hIsnJg"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "GOTvwM",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "basics-7-col-container-hIsnJg"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "m24tlV",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-12"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "U4XJTo",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "J7m45i",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "MM2J1L",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "Sf8imo",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["basics-7-col-td-wrapper-hIsnJg"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-name", value: "divider" },
                                    { property: "data-editable", value: "true" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "Y09zFp",
                                      type: "",
                                      htmlTagName: "div",
                                      classes: ["basics-7-divider-hIsnJg"],
                                      styles: [],
                                      attributes: [{ property: "data-elementgetter1", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "pe6IS1",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "basics-2-component-pe6IS1"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "zfu9gE",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "basics-2-col-container-pe6IS1"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "CPIx8Y",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-12"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "Nvj5TX",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "Gf9ffh",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "Rbleom",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "OqjAXd",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["basics-2-col-td-wrapper-pe6IS1"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "hEgP8X",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["basics-2-text-pe6IS1"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Dear %RECIPIENT_NAME%,",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "Wk4jWW",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "basics-2-component-Wk4jWW"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "NEkqpg",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "basics-2-col-container-Wk4jWW"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "oyxnfr",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-12"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "gdUWlY",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "vJmL7Y",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "FQ91pE",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "KkFSe3",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["basics-2-col-td-wrapper-Wk4jWW"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "hxcC4C",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["basics-2-text-Wk4jWW"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content:
                                            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sit amet porttitor eget dolor morbi non arcu. Diam in arcu cursus euismod quis viverra nibh. Ultrices mi tempus imperdiet nulla malesuada ellentesque. ",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "LD0DVL",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "basics-2-component-LD0DVL"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "NwOoSr",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "basics-2-col-container-LD0DVL"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "fmmajT",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-12"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "niWEqd",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "R8P1Mx",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "b7XnWN",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "UeKPjN",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["basics-2-col-td-wrapper-LD0DVL"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "VSK1mW",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["basics-2-text-LD0DVL"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content:
                                            "Pharetra diam sit amet nisl suscipit adipiscing bibendum est. In fermentum posuere urna nec tincidunt praesent semper feugiat. Cras semper auctor neque vitae tempus quam pellentesque. Elementum sagittis vitae et leo duis ut diam.",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "JXvPyg",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "basics-2-component-JXvPyg"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "a2HH3T",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "basics-2-col-container-JXvPyg"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "R8OCJb",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-12"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "VsAori",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "flfKE2",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "x4Y2Os",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "uLPdbj",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["basics-2-col-td-wrapper-JXvPyg"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "hLqisL",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["basics-2-text-JXvPyg"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Kind regards,",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "bPSWrl",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["yWd1PwqYa4pVjO7zy3n8XgPHRQk5oX"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-name", value: "p" },
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Company name",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "aVeUfr",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "basics-9-component-aVeUfr"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "ufAp7R",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "basics-9-col-container-aVeUfr"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "VVabj4",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-12"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "w0Lccj",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "yuRcQI",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "CGnJOI",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "PQCL0B",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["basics-9-col-td-wrapper-aVeUfr"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "DZDFjB",
                                      type: "block",
                                      htmlTagName: "div",
                                      classes: ["basics-9-wrapper-div-aVeUfr"],
                                      styles: [],
                                      attributes: [{ property: "data-name", value: "block" }],
                                      content: "",
                                      children: [
                                        {
                                          childId: "ZgBK09",
                                          type: "element",
                                          htmlTagName: "div",
                                          classes: ["basics-9-wrapper-links-aVeUfr"],
                                          styles: [],
                                          attributes: [],
                                          content: "",
                                          children: [
                                            {
                                              childId: "Bq7osM",
                                              type: "",
                                              htmlTagName: "a",
                                              classes: ["basics-9-link-aVeUfr"],
                                              styles: [],
                                              attributes: [
                                                { property: "href", value: "#!" },
                                                { property: "data-href", value: "#!" },
                                                { property: "data-target", value: "_blank" },
                                                { property: "data-editable", value: "true" },
                                                { property: "data-name", value: "a" },
                                              ],
                                              content: "",
                                              children: [
                                                {
                                                  childId: "",
                                                  type: "",
                                                  htmlTagName: "textNode",
                                                  classes: [],
                                                  styles: [],
                                                  attributes: [],
                                                  content: "Customer service",
                                                  children: [],
                                                },
                                              ],
                                            },
                                            {
                                              childId: "LcrPgi",
                                              type: "",
                                              htmlTagName: "p",
                                              classes: ["basics-9-divider-aVeUfr"],
                                              styles: [],
                                              attributes: [
                                                { property: "data-editable", value: "true" },
                                                { property: "data-texteditable", value: "true" },
                                                { property: "data-name", value: "p" },
                                              ],
                                              content: "",
                                              children: [
                                                {
                                                  childId: "",
                                                  type: "",
                                                  htmlTagName: "textNode",
                                                  classes: [],
                                                  styles: [],
                                                  attributes: [],
                                                  content: " | ",
                                                  children: [],
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                        {
                                          childId: "vpYuuu",
                                          type: "element",
                                          htmlTagName: "div",
                                          classes: ["basics-9-wrapper-links-aVeUfr"],
                                          styles: [],
                                          attributes: [],
                                          content: "",
                                          children: [
                                            {
                                              childId: "Cnfr0O",
                                              type: "",
                                              htmlTagName: "a",
                                              classes: ["basics-9-link-aVeUfr"],
                                              styles: [],
                                              attributes: [
                                                { property: "href", value: "#!" },
                                                { property: "data-href", value: "#!" },
                                                { property: "data-target", value: "_blank" },
                                                { property: "data-editable", value: "true" },
                                                { property: "data-name", value: "a" },
                                              ],
                                              content: "",
                                              children: [
                                                {
                                                  childId: "",
                                                  type: "",
                                                  htmlTagName: "textNode",
                                                  classes: [],
                                                  styles: [],
                                                  attributes: [],
                                                  content: "Privacy",
                                                  children: [],
                                                },
                                              ],
                                            },
                                            {
                                              childId: "Bpz8FU",
                                              type: "",
                                              htmlTagName: "p",
                                              classes: ["basics-9-divider-aVeUfr"],
                                              styles: [],
                                              attributes: [
                                                { property: "data-editable", value: "true" },
                                                { property: "data-texteditable", value: "true" },
                                                { property: "data-name", value: "p" },
                                              ],
                                              content: "",
                                              children: [
                                                {
                                                  childId: "",
                                                  type: "",
                                                  htmlTagName: "textNode",
                                                  classes: [],
                                                  styles: [],
                                                  attributes: [],
                                                  content: " | ",
                                                  children: [],
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                        {
                                          childId: "UNfQTv",
                                          type: "element",
                                          htmlTagName: "div",
                                          classes: ["basics-9-wrapper-links-aVeUfr"],
                                          styles: [],
                                          attributes: [],
                                          content: "",
                                          children: [
                                            {
                                              childId: "xFCyup",
                                              type: "",
                                              htmlTagName: "a",
                                              classes: ["basics-9-link-aVeUfr"],
                                              styles: [],
                                              attributes: [
                                                { property: "href", value: "#!" },
                                                { property: "data-href", value: "%UNSUBSCRIBE_LINK%" },
                                                { property: "data-target", value: "_blank" },
                                                { property: "data-editable", value: "true" },
                                                { property: "data-name", value: "a" },
                                              ],
                                              content: "",
                                              children: [
                                                {
                                                  childId: "",
                                                  type: "",
                                                  htmlTagName: "textNode",
                                                  classes: [],
                                                  styles: [],
                                                  attributes: [],
                                                  content: "Unsubscribe",
                                                  children: [],
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  ebCustomCss: [
    {
      componentId: "pe6IS1",
      classes: [
        { className: "basics-2-component-pe6IS1", pseudo: "", rules: [{ property: "padding", value: "0 10px 0 10px" }] },
        {
          className: "basics-2-col-container-pe6IS1",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "background-color", value: "rgb(255, 255, 255)" },
            { property: "border-radius", value: "0 0 0 0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
          ],
        },
        {
          className: "basics-2-col-td-wrapper-pe6IS1",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "text-align", value: "start" },
            { property: "padding", value: "0.5rem 0.5rem 0.5rem 0.5rem" },
          ],
        },
        {
          className: "basics-2-text-pe6IS1",
          pseudo: "",
          rules: [
            { property: "text-align", value: "start" },
            { property: "color", value: "rgb(33, 37, 41)" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
          ],
        },
      ],
    },
    {
      componentId: "Wk4jWW",
      classes: [
        { className: "basics-2-component-Wk4jWW", pseudo: "", rules: [{ property: "padding", value: "0 10px 0 10px" }] },
        {
          className: "basics-2-col-container-Wk4jWW",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "background-color", value: "rgb(255, 255, 255)" },
            { property: "border-radius", value: "0 0 0 0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
          ],
        },
        {
          className: "basics-2-col-td-wrapper-Wk4jWW",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "text-align", value: "start" },
            { property: "padding", value: "0.5rem 0.5rem 0.5rem 0.5rem" },
          ],
        },
        { className: "basics-2-text-Wk4jWW", pseudo: "", rules: [{ property: "margin", value: "0rem 0rem 0rem 0rem" }] },
      ],
    },
    {
      componentId: "LD0DVL",
      classes: [
        { className: "basics-2-component-LD0DVL", pseudo: "", rules: [{ property: "padding", value: "0 10px 0 10px" }] },
        {
          className: "basics-2-col-container-LD0DVL",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "background-color", value: "rgb(255, 255, 255)" },
            { property: "border-radius", value: "0 0 0 0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
          ],
        },
        {
          className: "basics-2-col-td-wrapper-LD0DVL",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "text-align", value: "start" },
            { property: "padding", value: "0.5rem 0.5rem 0.5rem 0.5rem" },
          ],
        },
        {
          className: "basics-2-text-LD0DVL",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(33, 37, 41)" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
          ],
        },
      ],
    },
    {
      componentId: "JXvPyg",
      classes: [
        { className: "basics-2-component-JXvPyg", pseudo: "", rules: [{ property: "padding", value: "0 10px 0 10px" }] },
        {
          className: "basics-2-col-container-JXvPyg",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "background-color", value: "rgb(255, 255, 255)" },
            { property: "border-radius", value: "0 0 0 0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
          ],
        },
        {
          className: "basics-2-col-td-wrapper-JXvPyg",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "text-align", value: "start" },
            { property: "padding", value: "0.5rem 0.5rem 0.5rem 0.5rem" },
          ],
        },
        {
          className: "basics-2-text-JXvPyg",
          pseudo: "",
          rules: [
            { property: "text-align", value: "start" },
            { property: "color", value: "rgb(33, 37, 41)" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "yWd1PwqYa4pVjO7zy3n8XgPHRQk5oX",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(33, 37, 41)" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        { className: "yWd1PwqYa4pVjO7zy3n8XgPHRQk5oX", pseudo: "hover", rules: [] },
      ],
    },
    {
      componentId: "sU2PId",
      classes: [
        { className: "basics-2-component-sU2PId", pseudo: "", rules: [{ property: "padding", value: "0 10px 0 10px" }] },
        {
          className: "basics-2-col-container-sU2PId",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "background-color", value: "rgb(255, 255, 255)" },
            { property: "border-radius", value: "0 0 0 0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
          ],
        },
        {
          className: "basics-2-col-td-wrapper-sU2PId",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "text-align", value: "start" },
            { property: "padding", value: "0.5rem 0.5rem 0.5rem 0.5rem" },
          ],
        },
        {
          className: "basics-2-text-sU2PId",
          pseudo: "",
          rules: [
            { property: "text-align", value: "start" },
            { property: "color", value: "rgb(33, 37, 41)" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
          ],
        },
      ],
    },
    {
      componentId: "aVeUfr",
      classes: [
        { className: "basics-9-wrapper-div-aVeUfr", pseudo: "", rules: [{ property: "display", value: "block" }] },
        { className: "basics-9-wrapper-links-aVeUfr", pseudo: "", rules: [{ property: "display", value: "inline-block" }] },
        { className: "basics-9-wrapper-links-aVeUfr", pseudo: "last-child > a", rules: [{ property: "margin-right", value: "0" }] },
        {
          className: "basics-9-link-aVeUfr",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(33, 37, 41)" },
            { property: "margin", value: "0rem 0.5rem 0rem 0rem" },
            { property: "display", value: "inline-block" },
            { property: "text-decoration", value: "underline" },
            { property: "text-align", value: "center" },
          ],
        },
        {
          className: "basics-9-divider-aVeUfr",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(33, 37, 41)" },
            { property: "margin", value: "0rem 0.5rem 0rem 0rem" },
            { property: "text-align", value: "center" },
            { property: "display", value: "inline-block" },
          ],
        },
        {
          className: "basics-9-component-aVeUfr",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(255, 255, 255)" },
            { property: "background", value: "rgb(255, 255, 255)" },
          ],
        },
        {
          className: "basics-9-col-container-aVeUfr",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "background-color", value: "rgb(255, 255, 255)" },
            { property: "padding-top", value: "3rem" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "basics-9-col-td-wrapper-aVeUfr",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0.5rem 0rem 0.5rem 0rem" },
            { property: "text-align", value: "center" },
          ],
        },
      ],
    },
    {
      componentId: "hIsnJg",
      classes: [
        { className: "basics-7-component-hIsnJg", pseudo: "", rules: [{ property: "padding", value: "0 10px 0 10px" }] },
        {
          className: "basics-7-col-container-hIsnJg",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "background-color", value: "rgb(255, 255, 255)" },
            { property: "border-radius", value: "0 0 0 0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
          ],
        },
        {
          className: "basics-7-col-td-wrapper-hIsnJg",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0rem 0rem 0rem 0rem" },
            { property: "text-align", value: "start" },
          ],
        },
        {
          className: "basics-7-divider-hIsnJg",
          pseudo: "",
          rules: [
            { property: "display", value: "inline-block" },
            { property: "height", value: "25px" },
            { property: "width", value: "100%" },
            { property: "background-color", value: "rgb(255, 255, 255)" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "border-radius", value: "0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(230, 230, 230)" },
          ],
        },
      ],
    },
  ],
  ebCssVars: {
    fontHeadersName: "Arial",
    fontHeadersWeight: "400",
    fontBodyName: "Arial",
    fontBodyWeight: "400",
    color1: "rgba(229, 42, 5, 1)",
    color2: "rgba(246, 129, 0, 1)",
    color3: "rgba(40, 167, 69, 1)",
    color4: "rgba(13, 202, 240, 1)",
    color5: "rgba(255, 193, 7, 1)",
    color6: "rgba(220, 53, 69, 1)",
    color7: "rgba(233, 236, 239, 1)",
    color8: "rgba(33, 37, 41, 1)",
    color9: "rgba(206, 212, 218, 1)",
    color10: "rgba(108, 117, 125, 1)",
  },
};
