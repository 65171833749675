import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Select from "./formComponents/Select";

import { formGetStartValue } from "../../../../lib/editFunctions";
import { translate } from "../../../../translations/translations";

const NumRatingStars = ({ eb: { startStyles } }) => {
  return (
    <>
      <Select
        label={translate("cEditForms.rating", false, null)}
        id={"numStars"}
        options={[
          { val: "0", label: `0 ${translate("cEditForms.stars", false, null)}` },
          { val: "0.5", label: `0.5 ${translate("cEditForms.star", false, null)}` },
          { val: "1", label: `1 ${translate("cEditForms.star", false, null)}` },
          { val: "1.5", label: `1.5 ${translate("cEditForms.stars", false, null)}` },
          { val: "2", label: `2 ${translate("cEditForms.stars", false, null)}` },
          { val: "2.5", label: `2.5 ${translate("cEditForms.stars", false, null)}` },
          { val: "3", label: `3 ${translate("cEditForms.stars", false, null)}` },
          { val: "3.5", label: `3.5 ${translate("cEditForms.stars", false, null)}` },
          { val: "4", label: `4 ${translate("cEditForms.stars", false, null)}` },
          { val: "4.5", label: `4.5 ${translate("cEditForms.stars", false, null)}` },
          { val: "5", label: `5 ${translate("cEditForms.stars", false, null)}` },
        ]}
        startValue={formGetStartValue(startStyles, "numStars")}
        tooltipText={translate("cEditForms.selectRatingToShow", false, null)}
      />
    </>
  );
};

NumRatingStars.propTypes = {
  eb: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  eb: state.eb,
});

export default connect(mapStateToProps, null)(NumRatingStars);
