import { translate } from "../translations/translations";

export const ATTR_EB_TYPE = "data-ebtype";
export const TAGNAME_TEXT_NODE = "textNode";
export const TAGNAME_COMMENT = "comment";
// Component buttons
export const EDIT_COMPONENT_ACTIONS_TYPE = "component-actions";
export const EDIT_COMPONENT_ACTIONS_CLASS = "componentActions";
export const COMPONENT_MOVEUP_TYPE = "component-moveup";
export const COMPONENT_MOVEUP_CLASS = "moveup";
export const COMPONENT_MOVEDOWN_TYPE = "component-movedown";
export const COMPONENT_MOVEDOWN_CLASS = "movedown";
export const COMPONENT_EDIT_TYPE = "component-edit";
export const COMPONENT_EDIT_CLASS = "edit";
export const COMPONENT_DELETE_TYPE = "component-delete";
export const COMPONENT_DELETE_CLASS = "delete";
// Element buttons
export const EDIT_ELEMENT_ACTIONS_TYPE = "element-actions";
export const EDIT_ELEMENT_ACTIONS_CLASS = "elementActions";
export const ELEMENT_DUPLICATE_TYPE = "element-duplicate";
export const ELEMENT_DUPLICATE_CLASS = "duplicate";
export const ELEMENT_EDIT_TYPE = "element-edit";
export const ELEMENT_EDIT_CLASS = "edit";
export const ELEMENT_DELETE_TYPE = "element-delete";
export const ELEMENT_DELETE_CLASS = "delete";
// Col reorder buttons
export const COL_REORDER_TYPE = "col-reorder";
export const COL_REORDER_CLASS = "reorder";
// Both component & element buttons:
export const EDIT_COMPONENT_ACTION_ICON_TYPE = "edit-action";

// export const XXX = "AAA"
// export const XXX = "AAA"
// export const XXX = "AAA"

// Mail color var names
export const MAIL_COLOR_VAR_NAMES = ["color1", "color2", "color3", "color4", "color5", "color6", "color7", "color8", "color9", "color10"];

// Font weights
export const FONT_WEIGHT_VALUES = ["regular", "700"];
export const FONT_WEIGHT_CSS = ["400", "700"];
export const FONT_WEIGHT_NAMES = [translate("lGeneralVars.normal", false, null), translate("lGeneralVars.bold", false, null)];

// mySatonda return URL
// export const MYSATONDA_RETURN_URL = "http://localhost:3000/email-marketing/manage/builder";
export const MYSATONDA_RETURN_URL = "https://my.satonda.com/email-marketing/manage/builder";

// axios
export const POST_CONFIG = {
  headers: {
    "Content-Type": "application/json",
  },
};

// export const API_URL = "http://localhost:5000/api"; //  // No trailing "/"
export const API_URL = "https://api.satonda.com/api"; // No trailing "/"

// Dynamic content
export const DYNAMIC_CONTENT_VARS = ["%UNSUBSCRIBE_LINK%", "%RECIPIENT_EMAIL%", "%RECIPIENT_NAME%"];
