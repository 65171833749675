import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { changeScreenSize, clickUndoRedo, saveProject } from "../../actions/eb";
import { MYSATONDA_RETURN_URL } from "../../lib/generalVars";
import { exportMail } from "../../lib/exportMail";
import { translate } from "../../translations/translations";

const BuilderAreaTop = ({
  eb: { project, ebContent, ebCustomCss, ebCssVars, activePageId, trackChanges, posTrackChanges, screen },
  changeScreenSize,
  clickUndoRedo,
  saveProject,
}) => {
  const clickSaveProject = () => {
    // Remove unused componentIds from ebCustomCss
    // console.log(JSON.stringify(ebContent));
    // console.log(JSON.stringify(ebCustomCss));
    // console.log(JSON.stringify(ebCssVars));
    let cleanedCustomCss = cleanupCustomCss(ebContent, ebCustomCss);
    let exportedMail = exportMail(project, ebContent, cleanedCustomCss, ebCssVars);
    saveProject(project._id, project.gallery, project.mailMeta, ebContent, cleanedCustomCss, ebCssVars, exportedMail);
  };

  const cleanupCustomCss = (content, customCss) => {
    try {
      let activeComponentIds = content.map((page) => page.components.map((component) => component.componentId)).flat();
      return customCss.filter((component) => activeComponentIds.includes(component.componentId));
    } catch (error) {
      console.error(error);
      return customCss;
    }
  };

  const clickSaveAndClose = async () => {
    if (window.confirm(translate("cLayout.confirmExitEb", false, null))) {
      // await saveProject(project._id, ebContent, ebCustomCss, ebCssVars);
      window.location.replace(MYSATONDA_RETURN_URL);
    }
  };

  const getCurrPageVersion = () => {
    try {
      return ebContent.filter((page) => page.pageId === activePageId)[0].version;
    } catch (error) {
      return "A";
    }
  };

  const Left = () => {
    return (
      <div className="d-flex align-items-center">
        {/* Exit EB button */}
        <i
          className="fa-solid fa-arrow-left-long trans-3 cursorPointer fontSize15 text-danger"
          title={translate("cLayout.saveExitEb", false, null)}
          onClick={clickSaveAndClose}
          data-tour="save-and-exit"
        />
        {/* Project / page name */}
        <span data-tour="active-page-version">
          <span className="text-gray text-bold ms-4">{project === null ? "projectName" : project.projectName}</span>
          <span className="ms-2 fontSize09 text-gray">
            ({translate("cLayout.version", false, null)} {getCurrPageVersion()})
          </span>
        </span>
        {/* Page options */}
        <i
          className="fa-solid fa-bars trans-3 cursorPointer text-gray textHover-primary ms-3"
          title={translate("cLayout.mailOptions", false, null)}
          data-bs-toggle="modal"
          data-bs-target="#ModalPageOptions"
          data-tour="site-page-options"
        ></i>
      </div>
    );
  };

  const Mid = () => {
    return (
      <div className="d-flex justify-content-center align-items-center" data-tour="change-screen-size">
        <div>
          <i
            className={`fa-solid fa-display me-3 changeScreen trans-3 cursorPointer ${
              screen === "desktop" ? "text-primary" : "text-gray"
            } textHover-primary`}
            data-toggle="tooltip"
            data-placement="bottom"
            title={translate("cLayout.desktopScreen", false, null)}
            onClick={() => changeScreenSize("desktop")}
          />
          <i
            className={`fa-solid fa-tablet-screen-button me-3 changeScreen trans-3 cursorPointer ${
              screen === "tablet" ? "text-primary" : "text-gray"
            } textHover-primary`}
            data-toggle="tooltip"
            data-placement="bottom"
            title={translate("cLayout.tabletScreen", false, null)}
            onClick={() => changeScreenSize("tablet")}
          />
          <i
            className={`fa-solid fa-mobile-screen-button changeScreen trans-3 cursorPointer ${
              screen === "mobile" ? "text-primary" : "text-gray"
            } textHover-primary`}
            data-toggle="tooltip"
            data-placement="bottom"
            title={translate("cLayout.mobileScreen", false, null)}
            onClick={() => changeScreenSize("mobile")}
          />
        </div>
      </div>
    );
  };

  const Right = () => {
    return (
      <div className="d-flex justify-content-end align-items-center">
        <div data-tour="undo-redo">
          {/* Undo button */}
          {trackChanges.length > 0 && posTrackChanges < trackChanges.length ? (
            <i
              className={`fa-solid fa-rotate-left trans-3 cursorPointer text-gray textHover-primary me-3`}
              title={translate("cLayout.undo", false, null)}
              onClick={() => clickUndoRedo(1)}
            ></i>
          ) : (
            <i className={`fa-solid fa-rotate-left trans-3 text-midgray me-3`} title={translate("cLayout.noUndoChange", false, null)}></i>
          )}
          {/* Redo button */}
          {posTrackChanges > 0 ? (
            <i
              className={`fa-solid fa-rotate-right trans-3 cursorPointer text-gray textHover-primary me-5`}
              title={translate("cLayout.redo", false, null)}
              onClick={() => clickUndoRedo(-1)}
            ></i>
          ) : (
            <i className={`fa-solid fa-rotate-right trans-3 text-midgray me-5`} title={translate("cLayout.noRedoChange", false, null)}></i>
          )}
          {/* Save and save as buttons */}
          <i
            className={`fa-solid fa-floppy-disk trans-3 cursorPointer text-gray textHover-primary me-1`}
            title={translate("cLayout.saveProject", false, null)}
            onClick={clickSaveProject}
            data-tour="save-project"
          ></i>
          {/* <i className={`fa-solid fa-caret-down trans-3 cursorPointer text-gray textHover-primary me-2 fontSize08`} title="Save as..."></i> */}
        </div>
      </div>
    );
  };

  return (
    <div className="row mb-3 w-100" data-tour="top-menu">
      <div className="col-4">
        <Left ebContent={ebContent} />
      </div>
      <div className="col-4">
        <Mid screen={screen} changeScreenSize={changeScreenSize} />
      </div>
      <div className="col-4">
        <Right />
      </div>
    </div>
  );
};

BuilderAreaTop.propTypes = {
  eb: PropTypes.object.isRequired,
  changeScreenSize: PropTypes.func.isRequired,
  clickUndoRedo: PropTypes.func.isRequired,
  saveProject: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  eb: state.eb,
});

export default connect(mapStateToProps, { changeScreenSize, clickUndoRedo, saveProject })(BuilderAreaTop);
