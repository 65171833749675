import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Select from "./formComponents/Select";

import { formGetStartValue } from "../../../../lib/editFunctions";
import { translate } from "../../../../translations/translations";

const BorderRadius = ({ eb: { startStyles } }) => {
  // Values: [0, 0.2, 0.4, 0.75, 1, 50, 1000]
  // If 0-1  => unit = rem
  // If 50   => unit = %
  // If 1000 => unit = px
  return (
    <>
      <Select
        label={translate("cEditForms.borderRadius", false, null)}
        id={"borderRadius"}
        options={[
          { val: 0, label: translate("cEditForms.square", false, null) },
          { val: 0.2, label: translate("cEditForms.roundedSmall", false, null) },
          { val: 0.4, label: translate("cEditForms.roundedMedium", false, null) },
          { val: 0.75, label: translate("cEditForms.roundedLarge", false, null) },
          { val: 1, label: translate("cEditForms.roundedExtraLarge", false, null) },
          { val: 1000, label: translate("cEditForms.pill", false, null) },
          { val: 50, label: translate("cEditForms.circle", false, null) },
        ]}
        startValue={formGetStartValue(startStyles, "borderRadius")}
        tooltipText={translate("cEditForms.tooltipBorderRadius", false, null)}
      />
    </>
  );
};

BorderRadius.propTypes = {
  eb: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  eb: state.eb,
});

export default connect(mapStateToProps, null)(BorderRadius);
