export const cards1 = {
  id: "cards-1",
  name: "Card - Title + paragraph",
  html: {
    componentId: "",
    type: "component",
    htmlTagName: "div",
    classes: ["eb-component", "cards-1-component"],
    styles: [],
    attributes: [{ property: "data-name", value: "col_component" }],
    content: "",
    children: [
      {
        childId: "",
        type: "",
        htmlTagName: "div",
        classes: ["column-container", "cards-1-col-container"],
        styles: [],
        attributes: [],
        content: "",
        children: [
          {
            childId: "",
            type: "",
            htmlTagName: "div",
            classes: ["column", "col-6", "vertical-align-top"],
            styles: [],
            attributes: [{ property: "data-colLayout", value: "true" }],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "table",
                classes: ["column-table"],
                styles: [],
                attributes: [
                  { property: "role", value: "presentation" },
                  { property: "width", value: "100%" },
                  { property: "cellspacing", value: "0" },
                  { property: "cellpadding", value: "0" },
                ],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "tbody",
                    classes: [],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "tr",
                        classes: [],
                        styles: [],
                        attributes: [],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "td",
                            classes: ["cards-1-col-td-wrapper"],
                            styles: [],
                            attributes: [],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "h1",
                                classes: ["cards-1-title"],
                                styles: [],
                                attributes: [
                                  { property: "data-editable", value: "true" },
                                  { property: "data-texteditable", value: "true" },
                                  { property: "data-name", value: "h" },
                                ],
                                content: "",
                                children: [
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "textNode",
                                    classes: [],
                                    styles: [],
                                    attributes: [],
                                    content: "This is a title",
                                    children: [],
                                  },
                                ],
                              },
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "p",
                                classes: ["cards-1-text"],
                                styles: [],
                                attributes: [
                                  { property: "data-editable", value: "true" },
                                  { property: "data-texteditable", value: "true" },
                                  { property: "data-name", value: "p" },
                                ],
                                content: "",
                                children: [
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "textNode",
                                    classes: [],
                                    styles: [],
                                    attributes: [],
                                    content: "Lorem ipsum dolor sit amet consectetur.",
                                    children: [],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            childId: "",
            type: "",
            htmlTagName: "div",
            classes: ["column", "col-6", "vertical-align-top"],
            styles: [],
            attributes: [{ property: "data-colLayout", value: "true" }],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "table",
                classes: ["column-table"],
                styles: [],
                attributes: [
                  { property: "role", value: "presentation" },
                  { property: "width", value: "100%" },
                  { property: "cellspacing", value: "0" },
                  { property: "cellpadding", value: "0" },
                ],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "tbody",
                    classes: [],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "tr",
                        classes: [],
                        styles: [],
                        attributes: [],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "td",
                            classes: ["cards-1-col-td-wrapper"],
                            styles: [],
                            attributes: [],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "h1",
                                classes: ["cards-1-title"],
                                styles: [],
                                attributes: [
                                  { property: "data-editable", value: "true" },
                                  { property: "data-texteditable", value: "true" },
                                  { property: "data-name", value: "h" },
                                ],
                                content: "",
                                children: [
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "textNode",
                                    classes: [],
                                    styles: [],
                                    attributes: [],
                                    content: "This is a title",
                                    children: [],
                                  },
                                ],
                              },
                              {
                                childId: "",
                                type: "",
                                htmlTagName: "p",
                                classes: ["cards-1-text"],
                                styles: [],
                                attributes: [
                                  { property: "data-editable", value: "true" },
                                  { property: "data-texteditable", value: "true" },
                                  { property: "data-name", value: "p" },
                                ],
                                content: "",
                                children: [
                                  {
                                    childId: "",
                                    type: "",
                                    htmlTagName: "textNode",
                                    classes: [],
                                    styles: [],
                                    attributes: [],
                                    content: "Lorem ipsum dolor sit amet consectetur.",
                                    children: [],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  css: {
    componentId: "",
    classes: [
      {
        className: "cards-1-component",
        pseudo: "",
        rules: [{ property: "padding", value: "0 10px 0 10px" }],
      },
      {
        className: "cards-1-col-container",
        pseudo: "",
        rules: [
          { property: "min-width", value: "280px" },
          { property: "max-width", value: "600px" },
          { property: "width", value: "100%" },
          { property: "margin", value: "0 auto 0 auto" },
          { property: "border-collapse", value: "collapse" },
          { property: "border-spacing", value: "0" },
          { property: "background", value: "rgb(255, 255, 255)" },
          { property: "background-color", value: "rgb(255, 255, 255)" },
          { property: "border-radius", value: "0 0 0 0" },
          { property: "border-style", value: "none none none none" },
          { property: "border-width", value: "1px" },
          { property: "border-color", value: "rgb(210, 210, 210)" },
          { property: "padding-top", value: "0" },
          { property: "padding-bottom", value: "0" },
          { property: "padding-left", value: "0" },
          { property: "padding-right", value: "0" },
        ],
      },
      {
        className: "cards-1-col-td-wrapper",
        pseudo: "",
        rules: [
          { property: "border-collapse", value: "collapse" },
          { property: "word-break", value: "break-word" },
          { property: "text-align", value: "start" },
          { property: "padding", value: "0.5rem 0.5rem 0.5rem 0.5rem" },
        ],
      },
      {
        className: "cards-1-title",
        pseudo: "",
        rules: [
          { property: "color", value: "rgb(229, 42, 5)" },
          { property: "margin", value: "0rem 0rem 0.5rem 0rem" },
          { property: "text-align", value: "center" },
        ],
      },
      {
        className: "cards-1-text",
        pseudo: "",
        rules: [
          { property: "color", value: "rgb(33, 37, 41)" },
          { property: "margin", value: "0rem 0rem 0rem 0rem" },
          { property: "text-align", value: "center" },
        ],
      },
    ],
  },
};
