import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Select from "./formComponents/Select";
import ColorPicker from "./formComponents/ColorPicker";
import Slider from "./formComponents/Slider";

import { formGetStartValue } from "../../../../lib/editFunctions";

import { PADDING_VALUES } from "../../../../lib/editStyleVars";
import { translate } from "../../../../translations/translations";

const ListMarker2 = ({ eb: { startStyles } }) => {
  return (
    <>
      <Select
        label={translate("cEditForms.markerStyle", false, null)}
        id={"markerForm"}
        options={[
          { val: "none", label: translate("cEditForms.noMarker", false, null) },
          { val: "square", label: translate("cEditForms.square", false, null) },
          { val: "circle", label: translate("cEditForms.circle", false, null) },
          { val: "line", label: translate("cEditForms.line", false, null) },
        ]}
        startValue={formGetStartValue(startStyles, "markerForm")}
        tooltipText={translate("cEditForms.ttMarkerStyle", false, null)}
      />
      <ColorPicker
        label={translate("cEditForms.markerBorderColor", false, null)}
        id={"markerBorderColor"}
        startValue={formGetStartValue(startStyles, "markerBorderColor")}
        tooltipText={translate("cEditForms.ttMarkerBorderColor", false, null)}
      />
      <ColorPicker
        label={translate("cEditForms.markerFillColor", false, null)}
        id={"markerBgColor"}
        startValue={formGetStartValue(startStyles, "markerBgColor")}
        tooltipText={translate("cEditForms.ttMarkerFillColor", false, null)}
      />
      <Slider
        label={translate("cEditForms.markerSpaceRight", false, null)}
        id={"markerMarginRight"}
        min={0}
        max={10}
        step={1}
        startValue={PADDING_VALUES.indexOf(formGetStartValue(startStyles, "markerMarginRight"))}
        tooltipText={translate("cEditForms.ttMarkerSpaceRight", false, null)}
      />
    </>
  );
};

ListMarker2.propTypes = {
  eb: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  eb: state.eb,
});

export default connect(mapStateToProps, null)(ListMarker2);
