export const footer1 = {
  id: "footer-1",
  name: "Footer 1",
  html: {
    componentId: "",
    type: "component",
    htmlTagName: "div",
    classes: ["eb-component", "footer-1-component"],
    styles: [],
    attributes: [{ property: "data-name", value: "col_component" }],
    content: "",
    children: [
      {
        childId: "",
        type: "",
        htmlTagName: "div",
        classes: ["column-container", "footer-1-col-container"],
        styles: [],
        attributes: [],
        content: "",
        children: [
          {
            childId: "",
            type: "",
            htmlTagName: "div",
            classes: ["column", "col-6", "vertical-align-middle"],
            styles: [],
            attributes: [{ property: "data-colLayout", value: "true" }],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "table",
                classes: ["column-table"],
                styles: [],
                attributes: [
                  { property: "role", value: "presentation" },
                  { property: "width", value: "100%" },
                  { property: "cellspacing", value: "0" },
                  { property: "cellpadding", value: "0" },
                ],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "tbody",
                    classes: [],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "tr",
                        classes: [],
                        styles: [],
                        attributes: [],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "td",
                            classes: ["footer-1-col-td-wrapper"],
                            styles: [],
                            attributes: [],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "block",
                                htmlTagName: "div",
                                classes: ["footer-1-wrapper-icons"],
                                styles: [],
                                attributes: [{ property: "data-name", value: "block" }],
                                content: "",
                                children: [
                                  {
                                    childId: "",
                                    type: "element",
                                    htmlTagName: "a",
                                    classes: ["footer-1-social-link"],
                                    styles: [],
                                    attributes: [
                                      { property: "href", value: "#!" },
                                      { property: "data-href", value: "#!" },
                                      { property: "data-target", value: "_blank" },
                                      { property: "data-editable", value: "true" },
                                      { property: "data-name", value: "socialIcon" },
                                    ],
                                    content: "",
                                    children: [
                                      {
                                        childId: "",
                                        type: "",
                                        htmlTagName: "img",
                                        classes: ["footer-1-social-icon"],
                                        styles: [],
                                        attributes: [
                                          {
                                            property: "src",
                                            value: "https://cdn.satonda.com/eb/assets/facebook-brand-white-square.png",
                                          },
                                          { property: "alt", value: "Facebook" },
                                          { property: "data-editable", value: "false" },
                                        ],
                                        content: "",
                                        children: [],
                                      },
                                    ],
                                  },
                                  {
                                    childId: "",
                                    type: "element",
                                    htmlTagName: "a",
                                    classes: ["footer-1-social-link"],
                                    styles: [],
                                    attributes: [
                                      { property: "href", value: "#!" },
                                      { property: "data-href", value: "#!" },
                                      { property: "data-target", value: "_blank" },
                                      { property: "data-editable", value: "true" },
                                      { property: "data-name", value: "socialIcon" },
                                    ],
                                    content: "",
                                    children: [
                                      {
                                        childId: "",
                                        type: "",
                                        htmlTagName: "img",
                                        classes: ["footer-1-social-icon"],
                                        styles: [],
                                        attributes: [
                                          {
                                            property: "src",
                                            value: "https://cdn.satonda.com/eb/assets/twitter-brand-white-square.png",
                                          },
                                          { property: "alt", value: "Twitter" },
                                          { property: "data-editable", value: "false" },
                                        ],
                                        content: "",
                                        children: [],
                                      },
                                    ],
                                  },
                                  {
                                    childId: "",
                                    type: "element",
                                    htmlTagName: "a",
                                    classes: ["footer-1-social-link"],
                                    styles: [],
                                    attributes: [
                                      { property: "href", value: "#!" },
                                      { property: "data-href", value: "#!" },
                                      { property: "data-target", value: "_blank" },
                                      { property: "data-editable", value: "true" },
                                      { property: "data-name", value: "socialIcon" },
                                    ],
                                    content: "",
                                    children: [
                                      {
                                        childId: "",
                                        type: "",
                                        htmlTagName: "img",
                                        classes: ["footer-1-social-icon"],
                                        styles: [],
                                        attributes: [
                                          {
                                            property: "src",
                                            value: "https://cdn.satonda.com/eb/assets/youtube-brand-white-square.png",
                                          },
                                          { property: "alt", value: "Youtube" },
                                          { property: "data-editable", value: "false" },
                                        ],
                                        content: "",
                                        children: [],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            childId: "",
            type: "",
            htmlTagName: "div",
            classes: ["column", "col-6", "vertical-align-middle"],
            styles: [],
            attributes: [{ property: "data-colLayout", value: "true" }],
            content: "",
            children: [
              {
                childId: "",
                type: "",
                htmlTagName: "table",
                classes: ["column-table"],
                styles: [],
                attributes: [
                  { property: "role", value: "presentation" },
                  { property: "width", value: "100%" },
                  { property: "cellspacing", value: "0" },
                  { property: "cellpadding", value: "0" },
                ],
                content: "",
                children: [
                  {
                    childId: "",
                    type: "",
                    htmlTagName: "tbody",
                    classes: [],
                    styles: [],
                    attributes: [],
                    content: "",
                    children: [
                      {
                        childId: "",
                        type: "",
                        htmlTagName: "tr",
                        classes: [],
                        styles: [],
                        attributes: [],
                        content: "",
                        children: [
                          {
                            childId: "",
                            type: "",
                            htmlTagName: "td",
                            classes: ["footer-1-col-td-wrapper"],
                            styles: [],
                            attributes: [],
                            content: "",
                            children: [
                              {
                                childId: "",
                                type: "block",
                                htmlTagName: "div",
                                classes: ["footer-1-wrapper-links"],
                                styles: [],
                                attributes: [{ property: "data-name", value: "block" }],
                                content: "",
                                children: [
                                  {
                                    childId: "",
                                    type: "element",
                                    htmlTagName: "a",
                                    classes: ["footer-1-footer-link"],
                                    styles: [],
                                    attributes: [
                                      { property: "href", value: "#!" },
                                      { property: "data-href", value: "#!" },
                                      { property: "data-target", value: "_blank" },
                                      { property: "data-editable", value: "true" },
                                      { property: "data-name", value: "a" },
                                    ],
                                    content: "",
                                    children: [
                                      {
                                        childId: "",
                                        type: "",
                                        htmlTagName: "textNode",
                                        classes: [],
                                        styles: [],
                                        attributes: [],
                                        content: "Link 1",
                                        children: [],
                                      },
                                    ],
                                  },
                                  {
                                    childId: "",
                                    type: "element",
                                    htmlTagName: "a",
                                    classes: ["footer-1-footer-link"],
                                    styles: [],
                                    attributes: [
                                      { property: "href", value: "#!" },
                                      { property: "data-href", value: "#!" },
                                      { property: "data-target", value: "_blank" },
                                      { property: "data-editable", value: "true" },
                                      { property: "data-name", value: "a" },
                                    ],
                                    content: "",
                                    children: [
                                      {
                                        childId: "",
                                        type: "",
                                        htmlTagName: "textNode",
                                        classes: [],
                                        styles: [],
                                        attributes: [],
                                        content: "Link 2",
                                        children: [],
                                      },
                                    ],
                                  },
                                  {
                                    childId: "",
                                    type: "element",
                                    htmlTagName: "a",
                                    classes: ["footer-1-footer-link"],
                                    styles: [],
                                    attributes: [
                                      { property: "href", value: "#!" },
                                      { property: "data-href", value: "#!" },
                                      { property: "data-target", value: "_blank" },
                                      { property: "data-editable", value: "true" },
                                      { property: "data-name", value: "a" },
                                    ],
                                    content: "",
                                    children: [
                                      {
                                        childId: "",
                                        type: "",
                                        htmlTagName: "textNode",
                                        classes: [],
                                        styles: [],
                                        attributes: [],
                                        content: "Link 3",
                                        children: [],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  css: {
    componentId: "",
    classes: [
      {
        className: "footer-1-component",
        pseudo: "",
        rules: [{ property: "padding", value: "0 10px 0 10px" }],
      },
      {
        className: "footer-1-col-container",
        pseudo: "",
        rules: [
          { property: "min-width", value: "280px" },
          { property: "max-width", value: "600px" },
          { property: "width", value: "100%" },
          { property: "margin", value: "0 auto 0 auto" },
          { property: "border-collapse", value: "collapse" },
          { property: "border-spacing", value: "0" },
          { property: "background", value: "rgb(21, 84, 146)" },
          { property: "background-color", value: "rgb(21, 84, 146)" },
          { property: "border-radius", value: "0 0 0 0" },
          { property: "border-style", value: "none none none none" },
          { property: "border-width", value: "1px" },
          { property: "border-color", value: "rgb(210, 210, 210)" },
          { property: "padding-top", value: "1rem" },
          { property: "padding-bottom", value: "1rem" },
          { property: "padding-left", value: "0" },
          { property: "padding-right", value: "0" },
        ],
      },
      {
        className: "footer-1-col-td-wrapper",
        pseudo: "",
        rules: [
          { property: "border-collapse", value: "collapse" },
          { property: "word-break", value: "break-word" },
          { property: "text-align", value: "start" },
          { property: "padding", value: "0.5rem 0rem 0.5rem 0rem" },
        ],
      },
      {
        className: "footer-1-wrapper-icons",
        pseudo: "",
        rules: [
          { property: "text-align", value: "center" },
          { property: "display", value: "block" },
        ],
      },
      {
        className: "footer-1-social-link",
        pseudo: "",
        rules: [
          { property: "display", value: "inline-block" },
          { property: "text-decoration", value: "none" },
          { property: "margin", value: "0rem 1rem 0rem 0rem" },
        ],
      },
      { className: "footer-1-social-link", pseudo: "last-child", rules: [{ property: "margin-right", value: "0" }] },
      {
        className: "footer-1-social-icon",
        pseudo: "",
        rules: [
          { property: "outline", value: "none" },
          { property: "-ms-interpolation-mode", value: "bicubic" },
          { property: "width", value: "32px" },
          { property: "height", value: "32px" },
          { property: "border-radius", value: "50%" },
          { property: "border-style", value: "none none none none" },
          { property: "border-width", value: "1px" },
          { property: "border-color", value: "rgb(255, 255, 255)" },
        ],
      },
      {
        className: "footer-1-wrapper-links",
        pseudo: "",
        rules: [{ property: "text-align", value: "center" }],
      },
      {
        className: "footer-1-footer-link",
        pseudo: "",
        rules: [
          { property: "color", value: "rgb(245, 245, 245)" },
          { property: "margin", value: "0rem 1rem 0rem 0rem" },
          { property: "display", value: "inline-block" },
          { property: "text-decoration", value: "none" },
        ],
      },
      { className: "footer-1-footer-link", pseudo: "last-child", rules: [{ property: "margin-right", value: "0" }] },
    ],
  },
};
