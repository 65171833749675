export const mModalColorPicker = {
  yourEmailColorScheme: {
    en: "Your mail color scheme",
    nl: "Uw email kleurenschema",
  },
  standardColors: {
    en: "Standard colors",
    nl: "Standaardkleuren",
  },
  color: {
    en: "Color",
    nl: "Kleur",
  },
  selectedColor: {
    en: "Selected color",
    nl: "Geselecteerde kleur",
  },
  close: {
    en: "Close",
    nl: "Sluiten",
  },
  selectColor: {
    en: "Select color",
    nl: "Selecteer kleur",
  },
};
