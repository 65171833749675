import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import ColorPicker from "./formComponents/ColorPicker";
import LinkDestTarget from "./formComponents/LinkDestTarget";
import Custom from "./formComponents/Custom";
import TextInput from "./formComponents/TextInput";

import Border from "./Border";
import BorderRadius from "./BorderRadius";
import Padding from "./Padding";
import AlignHori from "./AlignHori";
import FontSize from "./FontSize";

import { formGetStartValue } from "../../../../lib/editFunctions";
import { EDIT_FORM_ID_PREFIX } from "../../../../lib/editFunctions";
import { translate } from "../../../../translations/translations";

const BtnEdit = ({ eb: { startStyles } }) => {
  const BtnTextStyles = () => {
    const [isBold, setIsBold] = useState(false);
    const [isItalic, setIsItalic] = useState(false);
    const [isUnderlined, setIsUnderlined] = useState(false);

    useEffect(() => {
      setIsBold(formGetStartValue(startStyles, "btnIsBold"));
      setIsItalic(formGetStartValue(startStyles, "btnIsItalic"));
      setIsUnderlined(formGetStartValue(startStyles, "btnIsUnderlined"));
    }, []);

    return (
      <>
        <button
          className={`btn btn-sm ${isBold ? "btn-primary" : "btn-gray"} me-2`}
          id={`${EDIT_FORM_ID_PREFIX}btnIsBold`}
          onClick={() => setIsBold((prev) => !prev)}
          data-active={isBold ? "true" : "false"}
          data-editform="boolToggle"
        >
          <i className="fa-solid fa-bold" />
        </button>
        <button
          className={`btn btn-sm ${isItalic ? "btn-primary" : "btn-gray"} me-2`}
          id={`${EDIT_FORM_ID_PREFIX}btnIsItalic`}
          onClick={() => setIsItalic((prev) => !prev)}
          data-active={isItalic ? "true" : "false"}
          data-editform="boolToggle"
        >
          <i className="fa-solid fa-italic" />
        </button>
        <button
          className={`btn btn-sm ${isUnderlined ? "btn-primary" : "btn-gray"}`}
          id={`${EDIT_FORM_ID_PREFIX}btnIsUnderlined`}
          onClick={() => setIsUnderlined((prev) => !prev)}
          data-active={isUnderlined ? "true" : "false"}
          data-editform="boolToggle"
        >
          <i className="fa-solid fa-underline" />
        </button>
      </>
    );
  };

  const BtnFullWidth = () => {
    const [isFullWidth, setIsFullWidth] = useState(false);

    useEffect(() => {
      setIsFullWidth(formGetStartValue(startStyles, "btnFullWidth"));
      // eslint-disable-next-line
    }, [formGetStartValue(startStyles, "btnFullWidth")]);

    const onChange = (newBool) => {
      setIsFullWidth(newBool);
      if (newBool) {
        // If btn is full width => set left/right padding to 0
        document.getElementById(`${EDIT_FORM_ID_PREFIX}paddingLeft`).value = 0;
        document.getElementById(`${EDIT_FORM_ID_PREFIX}paddingLeft`).nextElementSibling.textContent = 0;
        document.getElementById(`${EDIT_FORM_ID_PREFIX}paddingRight`).value = 0;
        document.getElementById(`${EDIT_FORM_ID_PREFIX}paddingRight`).nextElementSibling.textContent = 0;
      }
    };

    return (
      <>
        <div className={`form-check form-switch`}>
          <input
            className="form-check-input"
            type="checkbox"
            role="switch"
            id={`${EDIT_FORM_ID_PREFIX}btnFullWidth`}
            value={isFullWidth}
            checked={isFullWidth}
            onChange={(e) => onChange(e.target.checked)}
            data-active={isFullWidth ? "true" : "false"}
            data-editform="boolToggle"
          />
          <label className="form-check-label" htmlFor={`${EDIT_FORM_ID_PREFIX}btnFullWidth`}></label>
        </div>
      </>
    );
  };

  return (
    <>
      {formGetStartValue(startStyles, "btnText") !== null && (
        <TextInput label={translate("cEditForms.btnText", false, null)} id={"btnText"} startValue={formGetStartValue(startStyles, "btnText")} />
      )}
      {/* If a link: link destination & target, else: button text styles */}
      {formGetStartValue(startStyles, "btnIsLink") && <LinkDestTarget />}
      <Custom label={translate("cEditForms.btnTextStyle", false, null)} column={false} customComponent={BtnTextStyles} />
      <FontSize />
      <ColorPicker
        label={translate("cEditForms.btnTextColor", false, null)}
        id={"textColor"}
        startValue={formGetStartValue(startStyles, "textColor")}
        tooltipText={translate("cEditForms.tooltipBtnTextColor", false, null)}
      />
      <ColorPicker
        label={translate("cEditForms.bgColor", false, null)}
        id={"btnBgColor"}
        startValue={formGetStartValue(startStyles, "btnBgColor")}
        tooltipText={translate("cEditForms.tooltipBtnBgColor", false, null)}
      />
      <Border />
      <BorderRadius />
      <Padding />
      <Custom
        label={translate("cEditForms.btnFullWidth", false, null)}
        tooltipText={translate("cEditForms.tooltipBtnFullWidth", false, null)}
        column={true}
        customComponent={BtnFullWidth}
      />
      <AlignHori />
    </>
  );
};

BtnEdit.propTypes = {
  eb: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  eb: state.eb,
});

export default connect(mapStateToProps, null)(BtnEdit);
