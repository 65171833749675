import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Select from "./formComponents/Select";

import { formGetStartValue } from "../../../../lib/editFunctions";
import { translate } from "../../../../translations/translations";

const ColVertAlign = ({ eb: { startStyles } }) => {
  return (
    <>
      <Select
        label={translate("cEditForms.verticalAlignment", false, null)}
        id={"colVerticalAlign"}
        options={[
          { val: "vertical-align-top", label: translate("cEditForms.top", false, null) },
          { val: "vertical-align-middle", label: translate("cEditForms.middle", false, null) },
          { val: "vertical-align-bottom", label: translate("cEditForms.bottom", false, null) },
        ]}
        startValue={formGetStartValue(startStyles, "colVerticalAlign")}
        tooltipText={translate("cEditForms.ttVerticalAlignment", false, null)}
      />
    </>
  );
};

ColVertAlign.propTypes = {
  eb: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  eb: state.eb,
});

export default connect(mapStateToProps, null)(ColVertAlign);
