import store from "../store";

// == TODO: ============================================================================================
// There's a circular dependency issue causing some content not to be translated
// Issue is that store is created based on rootReducer
// In the sb.js reducer, it imports parse.js
// parse.js already requires translations.js, at which point, store hasn't been initialized yet
//   (Similarly, generalVars.js and templates.js are imported through parse.js)
// Issue is that this is translated content within variables. For example, FONT_WEIGHT_NAMES = [translate("lGeneralVars.thin", false, null), translate("lGeneralVars.light", false, null), translate("lGeneralVars.normal", false, null), translate("lGeneralVars.bold", false, null)]
// Potential way to solve would be to not hard-code these translated contents into the variables but keep them as functions, so they are re-rendered each time when they
//   are needed, with the live value of langCode from redux.
//   Example: {translate(`lGeneralVars.${weight}`, false, null)}
// =====================================================================================================

// Actions
import { aEb } from "./translations/actions/aEb";
// Components - editPane - addNewComponent
import { cAddNewComponent } from "./translations/components/cAddNewComponent";
// Components - editPane - editForms
import { cEditForms } from "./translations/components/cEditForms";
// Components - editPane - editComponent
import { cEditComponent } from "./translations/components/cEditComponent";
// Components - editPane - mailStyle
import { cMailStyle } from "./translations/components/cMailStyle";
// Components - layout
import { cLayout } from "./translations/components/cLayout";
// Components - modals
import { mEmailFonts } from "./translations/components/modals/mEmailFonts";
import { mImgGalleryCrop } from "./translations/components/modals/mImgGalleryCrop";
import { mImgGalleryListFiles } from "./translations/components/modals/mImgGalleryListFiles";
import { mImgGalleryUpload } from "./translations/components/modals/mImgGalleryUpload";
import { mModalBrowseTemplates } from "./translations/components/modals/mModalBrowseTemplates";
import { mModalColorPicker } from "./translations/components/modals/mModalColorPicker";
import { mModalColorSchemeGenerator } from "./translations/components/modals/mModalColorSchemeGenerator";
import { mModalImageGallery } from "./translations/components/modals/mModalImageGallery";
import { mModalPageOptions } from "./translations/components/modals/mModalPageOptions";
import { mModalSelectIcon } from "./translations/components/modals/mModalSelectIcon";
import { mModalSelectImgFromGallery } from "./translations/components/modals/mModalSelectImgFromGallery";
// Lib
import { lGeneralVars } from "./translations/lib/lGeneralVars";
import { lParse } from "./translations/lib/lParse";
import { lProductTour } from "./translations/lib/lProductTour";
// Pages
import { pMain } from "./translations/pages/pMain";

export const translate = (objString, boolPlural, objVars) => {
  try {
    // Get translated text with english as fallback
    let objText = translations[objString.split(".")[0]][objString.split(".")[1]];
    if (typeof objText === "undefined") {
      return "";
    }
    let lang = getLangCode();
    let text = typeof objText[lang] === "undefined" ? objText["en"] : objText[lang];
    // Make plural if needed -- In translation file: { en: "Landing #page|pages#" }
    text = text.replace(/#(.+?)\|(.+?)#/, boolPlural ? "$2" : "$1");
    // Add variables -- In translation file: { en: "Welcome, %name%" } -- In translate(): translate(Translations.landingPage, true, { name: "Eddo" })
    if (objVars !== null) {
      Object.keys(objVars).forEach((key) => {
        text = text.replace(`%${key}%`, objVars[key]);
      });
    }
    return text;
  } catch (error) {
    console.error(`Translation error`, objString, boolPlural, objVars);
    return "";
  }
};

const getLangCode = () => {
  try {
    return store.getState().auth.langCode;
  } catch (error) {
    // console.error(error);
    return "en";
  }
};

export const LANGUAGES = { en: "English", nl: "Nederlands" };

// Plural: #word|words#
// Variables: %var%

const translations = {
  // Actions
  aEb,
  // Components - editPane - editForms
  cEditForms,
  // Components - editPane - editComponent
  cEditComponent,
  // Components - editPane - addNewComponent
  cAddNewComponent,
  // Components - editPane - mailStyle
  cMailStyle,
  // Components - layout
  cLayout,
  // Components - modals
  mEmailFonts,
  mImgGalleryCrop,
  mImgGalleryListFiles,
  mImgGalleryUpload,
  mModalBrowseTemplates,
  mModalColorPicker,
  mModalColorSchemeGenerator,
  mModalImageGallery,
  mModalPageOptions,
  mModalSelectIcon,
  mModalSelectImgFromGallery,
  // Lib
  lGeneralVars,
  lParse,
  lProductTour,
  // Pages
  pMain,
};
