import { v4 as uuidv4 } from "uuid";

export const POST_CONFIG = {
  headers: {
    "Content-Type": "application/json",
  },
};

export const loadProjectInitEbContentArray = (data) => {
  // data = res.data from loadProject action
  // Check whether res.data.ebContent already has content. If not, add a first page placeholder.
  if (data.ebContent.length === 0) {
    return {
      ...data,
      ebContent: [
        {
          pageId: uuidv4(),
          version: "A",
          components: [],
        },
      ],
    };
  }
  return data;
};
