import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Slider from "./formComponents/Slider";

import { formGetStartValue } from "../../../../lib/editFunctions";
import { MARGIN_VALUES } from "../../../../lib/editStyleVars";
import { translate } from "../../../../translations/translations";

const Margin = ({ eb: { startStyles } }) => {
  return (
    <>
      <Slider
        label={translate("cEditForms.marginLeft", false, null)}
        id={"marginLeft"}
        min={-10}
        max={10}
        step={1}
        startValue={MARGIN_VALUES.indexOf(formGetStartValue(startStyles, "marginLeft")) - 10}
        tooltipText={translate("cEditForms.ttMarginLeft", false, null)}
      />
      <Slider
        label={translate("cEditForms.marginRight", false, null)}
        id={"marginRight"}
        min={-10}
        max={10}
        step={1}
        startValue={MARGIN_VALUES.indexOf(formGetStartValue(startStyles, "marginRight")) - 10}
        tooltipText={translate("cEditForms.ttMarginRight", false, null)}
      />
      <Slider
        label={translate("cEditForms.marginTop", false, null)}
        id={"marginTop"}
        min={-10}
        max={10}
        step={1}
        startValue={MARGIN_VALUES.indexOf(formGetStartValue(startStyles, "marginTop")) - 10}
        tooltipText={translate("cEditForms.ttMarginTop", false, null)}
      />
      <Slider
        label={translate("cEditForms.marginBottom", false, null)}
        id={"marginBottom"}
        min={-10}
        max={10}
        step={1}
        startValue={MARGIN_VALUES.indexOf(formGetStartValue(startStyles, "marginBottom")) - 10}
        tooltipText={translate("cEditForms.ttMargiBottom", false, null)}
      />
    </>
  );
};

Margin.propTypes = {
  eb: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  eb: state.eb,
});

export default connect(mapStateToProps, null)(Margin);
