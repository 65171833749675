import store from "../store";
import { getState } from "./stateFunctions";
import { startEditingStyles, updateCss, updateClasses, updateAttributes, saveTrackChanges, updateCustoms } from "../actions/eb";
import { MARGIN_VALUES, PADDING_VALUES, SOCIAL_COLORS } from "./editStyleVars";
import { getTargetElement } from "./domFunctions";
import { ensureRgba } from "./colorFunctions";
import { getStartStyles } from "./getStyleFunctions";
import {
  getTargetObj,
  getTargetParent,
  getFirstChildWithAttr,
  getConcatElementId,
  getFirstChildByClassname,
  getAllChildrenWithAttr,
  getFirstChildByClassnameMatchRegex,
} from "./componentObjectFunctions";
import { capitalizeFirstLetter } from "./generalFunctions";

// =================================================================================================================
// Process to add a new special component or new elementAttribute
// 1) Check whether the special component is set to data-editable=true in the component template
// 2) Add the special component to the elementAttributes array
// 3) Add to getStartStyles and make the getStyle function
// 4) Make the editForm
// 5) Make the setStyle function
//    a) Add to getFormValue (if needed) and getUnsetValue functions
//    b) Add to applyChanges function's CHAMGE_CUSTOMS arrays (if the style is not a "normal" property that goes via classname or css rule)
//    c) Add to applyChanges SPECIALS array (= the data-name of the special element, not the style property from 5b)
//    d) Add to applyChangesSpecials function
//    e) Add to updateCustoms redux actions function
//    f) Add to reducer
// =================================================================================================================

// =====================
// == Editable styles ==
// =====================

export const elementAttributes = {
  // Divs
  component: ["component"],
  col_component: ["component", "colLayout"],
  bgdiv: ["bgColor", "border", "borderRadius"],
  divider: ["divider"],
  // Text elemennts
  h: ["width", "margin"],
  p: ["width", "margin"],
  span: ["width", "margin"],
  u: ["width", "margin"],
  strong: ["width", "margin"],
  b: ["width", "margin"],
  i: ["iconEditor", "margin"],
  em: ["width", "margin"],
  s: ["width", "margin"],
  sup: ["width", "margin"],
  sub: ["width", "margin"],
  font: ["width", "margin"],
  a: ["linkEditor", "margin"],
  // Img elements
  img: ["imgSource", "imgResize", "margin", "border", "borderRadius"],
  imgSrcOnly: ["imgSource"],
  socialIcon: ["socialIconSource"],
  downloadApp: ["downloadAppSource"],
  ratingStars: ["numRatingStars"],
  // Form elements
  button: ["buttonEditor", "margin"],
  // Other
};

// =========================
// == Edit form variables ==
// =========================
export const EDIT_FORM_ID_PREFIX = "editForm_";

// Determine which editForms to show (element settings, text editor, general styles (margin, padding, display, ...) and specific styles)
// target = the element that was selected through clickIframeElement in domFunctions
// All html should have a data-name attr, which can then be used to get the attributes that are editable: editForms = elementAttributes[target.dataset.name]
//    If target doesn't have a data-name attr, move to its parent (e.g., an inserted <b> through textEditor should be editable through its parent <p>)
// The burden to make styling work should be on the HTML. The functions to determine which styles to edit should be very simple
//    This is especially important for more complex components to be added later, such as navbar or parallax
//    User should be able to click anywhere, which then selects the full e.g. navbar/parallax component and all the right forms should be shown. Then editing should again be easy
export const determineEditFormsToShow = (target) => {
  // console.log(`determineEditFormsToShow`, target);
  let editFormsToShow =
    typeof elementAttributes[target.dataset.name] === "undefined"
      ? ["elementSettings"]
      : ["elementSettings", ...elementAttributes[target.dataset.name]];
  // Loop through specific data-[] attributes and add those editForms
  if (target.dataset.linkelement === "true") {
    editFormsToShow.unshift("linkElement");
  }
  if (target.dataset.texteditable === "true") {
    editFormsToShow.unshift("textEditor");
  }
  if (target.dataset.iconwrapper === "true") {
    editFormsToShow.push("iconEditor");
  }
  if (target.dataset.listwrapper === "true" || target.dataset.listtext === "true" || target.dataset.listbullet === "true") {
    editFormsToShow.push("listMarker2");
    editFormsToShow = editFormsToShow.filter((editForm) => editForm !== "margin");
  }
  if (target.dataset.imgresizable === "false") {
    // Handle images that are not resizable
    editFormsToShow = editFormsToShow.filter((editForm) => editForm !== "imgResize");
  }
  // Get startStyles and save to redux
  let startStyles = getStartStyles(editFormsToShow);
  // console.log("======= startStyles");
  // console.log(startStyles);
  store.dispatch(startEditingStyles(editFormsToShow, startStyles));
};

export const updateEditFormColorpickerValue = (id, color) => {
  try {
    document.getElementById(id).style.backgroundColor = color;
  } catch (error) {
    console.error(error);
  }
};

export const formGetStartValue = (startStyles, prop) => {
  try {
    return startStyles[prop];
  } catch (error) {
    console.error(error);
    return "";
  }
};

export const clickApplyStyleChanges = (applyTextEditorChanges) => {
  // Get all form values
  let startStyles = getState("eb", "startStyles");
  let selectedElement = getState("eb", "selectedElement");
  // console.log("startStyles", startStyles);
  // console.log("==================");
  // Save track changes to the history stack
  store.dispatch(saveTrackChanges());
  // Get values from all editForms
  let changedStyles = getStyleChangesMade(startStyles);
  // console.log("changedStyles", changedStyles);
  // Take out certain properties used to manage local states of editForms
  const IGNORE_EDITFORM_STATE_VARS = [
    "boxShadowChangeOnHover",
    "textShadowChangeOnHover",
    "imgAdjustChangeOnHover",
    "btnIsLink",
    "sdScaleHor",
    "sdScaleVer",
  ];
  changedStyles = changedStyles.filter((formProperty) => !IGNORE_EDITFORM_STATE_VARS.includes(Object.keys(formProperty)[0]));
  // Apply text editor changes - Before applyChanges() so that text color on links from linkEditor are leading
  applyTextEditorChanges !== null && applyTextEditorChanges.current.click();
  // Apply changed styles
  applyChanges(startStyles, changedStyles);
  // Reset all startStyles, to be sure that changes that were made are picked up in the comparison for changedStyles if user doesn't select another element
  determineEditFormsToShow(getTargetElement(selectedElement));
};

// ========================
// == Make style changes ==
// ========================

const getStyleChangesMade = (startStyles) => {
  try {
    // Check certain properties that may have a start value of null. If so, remove them
    const PROPERTIES_TO_CHECK = ["formDiv_name", "formDiv_successMsg"];
    const START_NULL_VALUES_TO_REMOVE = PROPERTIES_TO_CHECK.filter((prop) => typeof startStyles[prop] !== "undefined" && startStyles[prop] === null);
    let arrStartStyles = Object.keys(startStyles).filter((prop) => !START_NULL_VALUES_TO_REMOVE.includes(prop));
    let changedStyles = arrStartStyles.map((formProperty) => ({ [formProperty]: getFormValue(`${EDIT_FORM_ID_PREFIX}${formProperty}`) }));
    return changedStyles;
  } catch (error) {
    console.error(error);
    return [];
  }
};

const getFormValue = (id) => {
  // Get value from the editForm by its id
  try {
    if (id === `${EDIT_FORM_ID_PREFIX}sdColors`) {
      let sdColors = [];
      let i = 0;
      let target = document.getElementById(`${id}${i}`);
      while (target !== null) {
        sdColors.push(ensureRgba(target.style.backgroundColor));
        i++;
        target = document.getElementById(`${id}${i}`);
      }
      return sdColors;
    }
    if (id === `${EDIT_FORM_ID_PREFIX}navbarLinkData`) {
      let navbarLinkData = [];
      let i = 0;
      let linkText = document.getElementById(`${EDIT_FORM_ID_PREFIX}navbarLinkText${i}`);
      let linkDest = document.getElementById(`${EDIT_FORM_ID_PREFIX}navbarLinkDest${i}`);
      while (linkText !== null) {
        navbarLinkData.push({ childId: linkText.dataset.childid || "", text: linkText.value || "", dest: linkDest.value || "" });
        i++;
        linkText = document.getElementById(`${EDIT_FORM_ID_PREFIX}navbarLinkText${i}`);
        linkDest = document.getElementById(`${EDIT_FORM_ID_PREFIX}navbarLinkDest${i}`);
      }
      return navbarLinkData;
    }
    let target = document.getElementById(id);
    if (target === null) {
      // If editForm is not found (e.g. because onHover part is hidden via bool toggle)
      return getUnsetValue(id);
    }
    if (target.dataset.editform === "colorpicker") {
      // For colors, get their color strings
      return ensureRgba(target.style.backgroundColor);
    }
    if (target.dataset.editform === "iconEditor") {
      // For iconEditor, get its classname
      return target.className;
    }
    if (target.dataset.editform === "boolToggle") {
      // For button text styles, get their data-active attribute
      return target.dataset.active === "true";
    }
    if (id === `${EDIT_FORM_ID_PREFIX}colLayout`) {
      // Column layout is set via data-selected
      return target.dataset.selected;
    }
    if (id === `${EDIT_FORM_ID_PREFIX}boolBgIsGradient` || id === `${EDIT_FORM_ID_PREFIX}componentBoolBgIsGradient`) {
      // boolIsGradient is set via data-isgradient
      return target.dataset.isgradient === "true";
    }
    if (id === `${EDIT_FORM_ID_PREFIX}navbarScrolledpastBool`) {
      return target.value === "true";
    }
    if (id.includes("googlemaps")) {
      // For Google Maps input, get the value but check it is a valid maps link
      if ((target.value.match(/^https:\/\/www.google.com\/maps\/embed(.+?)$/) || ["", ""])[1] !== "") {
        return target.value;
      }
      return "";
    }
    // For all others, get their form values
    // Transform to numbers if needed
    const PARSE_FLOAT = [
      "colMobile",
      "colTablet",
      "colDesktop",
      "widthMobile",
      "widthTablet",
      "widthDesktop",
      "borderWidth",
      "borderRadius",
      "componentRadiusSize",
      "boxShadowHori",
      "boxShadowHoriHover",
      "boxShadowVert",
      "boxShadowVertHover",
      "boxShadowBlur",
      "boxShadowBlurHover",
      "boxShadowSpread",
      "boxShadowSpreadHover",
      "textShadowHori",
      "textShadowHoriHover",
      "textShadowVert",
      "textShadowVertHover",
      "textShadowBlur",
      "textShadowBlurHover",
      "paddingTop",
      "paddingRight",
      "paddingBottom",
      "paddingLeft",
      "marginTop",
      "marginRight",
      "marginBottom",
      "marginLeft",
      "markerMarginRight",
      "imgOpacity",
      "imgOpacityHover",
      "imgBlur",
      "imgBrightness",
      "imgContrast",
      "imgGrayscale",
      "imgHueRotate",
      "imgInvert",
      "imgSaturate",
      "imgSepia",
      "imgBlurHover",
      "imgBrightnessHover",
      "imgContrastHover",
      "imgGrayscaleHover",
      "imgHueRotateHover",
      "imgInvertHover",
      "imgSaturateHover",
      "imgSepiaHover",
      "imgTransformHover",
      "imgResizeWidthPx",
      "imgResizeHeightPx",
      "transition",
      "card6_size",
      "sdSpaceTop",
      "sdSpaceBottom",
      "navbarSize",
      "navbarTransition",
      "navbarBoxShadowHori",
      "navbarBoxShadowVert",
      "navbarBoxShadowBlur",
      "navbarBoxShadowSpread",
      "navbarScrolledpastSize",
      "navbarLogoMaxHeight",
      "navbarScrolledpastLogoMaxHeight",
      "navbarTogglerFontSize",
      "navbarTogglerBorderRadius",
      "navbarLinkFontSize",
      "componentPaddingTop",
      "componentPaddingBottom",
      "backToTop_sizeBtn",
      "backToTop_sizeIcon",
      "inputfield_fontSize",
      "height",
      "width",
      "fontSize",
      "dividerWidthPx",
      "bgGradientDirection",
      "bgGradientStop1",
      "bgGradientStop2",
      "componentBgGradientDirection",
      "componentBgGradientStop1",
      "componentBgGradientStop2",
      "numStars",
    ];
    let formProp = id.replace(EDIT_FORM_ID_PREFIX, "");
    let val = target.value;
    if (PARSE_FLOAT.includes(formProp)) {
      val = parseFloat(val);
    }
    // Margin & padding: return their rem values
    if (["marginTop", "marginBottom", "marginLeft", "marginRight"].includes(formProp)) {
      return MARGIN_VALUES[val + 10];
    }
    if (
      [
        "paddingTop",
        "paddingBottom",
        "paddingLeft",
        "paddingRight",
        "markerMarginRight",
        "sdSpaceTop",
        "sdSpaceBottom",
        "componentPaddingTop",
        "componentPaddingBottom",
        "navbarSize",
        "navbarScrolledpastSize",
      ].includes(formProp)
    ) {
      return PADDING_VALUES[val];
    }
    // Times 1000 (miliseconds to seconds)
    const TIMES_1000 = ["animationDelay", "animationDuration", "animationStaggeredDelay"];
    if (TIMES_1000.includes(formProp)) {
      val = parseFloat(val) * 1000;
    }
    return val;
  } catch (error) {
    console.error(error);
    return getUnsetValue(id);
  }
};

const getUnsetValue = (id) => {
  // Get standardized value of selected editForm
  // For onHover properties, check first the value of its normal style property
  // Otherwise, get the default values as set via their getStyle functions
  try {
    const UNSET_ONLY_ON_HOVER = {
      // Note: the 2nd check below (getFormValue(`${EDIT_FORM_ID_PREFIX}${formProperty.replace("Hover", "")}`)) removes "Hover" from form props
      //   This is an issue for props that only exist on hover
      //   Therefore, check those first
      imgTransformHover: 100,
    };
    const UNSET_VALUES_ZEROS = [
      "borderWidth",
      "borderRadius",
      "componentRadiusSize",
      "boxShadowHori",
      "boxShadowHoriHover",
      "boxShadowHoriFocus",
      "boxShadowVert",
      "boxShadowVertHover",
      "boxShadowVertFocus",
      "boxShadowBlur",
      "boxShadowBlurHover",
      "boxShadowBlurFocus",
      "boxShadowSpread",
      "boxShadowSpreadHover",
      "boxShadowSpreadFocus",
      "textShadowHori",
      "textShadowHoriHover",
      "textShadowVert",
      "textShadowVertHover",
      "textShadowBlur",
      "textShadowBlurHover",
      "paddingTop",
      "paddingRight",
      "paddingBottom",
      "paddingLeft",
      "marginTop",
      "marginRight",
      "marginBottom",
      "marginLeft",
      "markerMarginRight",
      "imgBlur",
      "imgBrightness",
      "imgContrast",
      "imgGrayscale",
      "imgHueRotate",
      "imgInvert",
      "imgSaturate",
      "imgSepia",
      "imgBlurHover",
      "imgBrightnessHover",
      "imgContrastHover",
      "imgGrayscaleHover",
      "imgHueRotateHover",
      "imgInvertHover",
      "imgSaturateHover",
      "imgSepiaHover",
      "imgResizeWidthPx",
      "imgResizeHeightPx",
      "transition",
      "sdSpaceTop",
      "sdSpaceBottom",
      "componentPaddingTop",
      "componentPaddingBottom",
      "dividerWidthPx",
      "bgGradientDirection",
      "bgGradientStop1",
      "bgGradientStop2",
      "componentBgGradientDirection",
      "componentBgGradientStop1",
      "componentBgGradientStop2",
    ];
    const UNSET_VALUES_BLACK_TRANSPARENT = [
      "borderColor",
      "borderColorHover",
      "borderColorFocus",
      "boxShadowRgba",
      "boxShadowRgbaHover",
      "boxShadowRgbaFocus",
      "textShadowRgba",
      "textShadowRgbaHover",
      "markerBorderColor",
      "markerBgColor",
      // "listMarkerColor",
    ];
    const UNSET_VALUES_NONE = ["borderSide", "linkDeco", "linkDecoHover", "markerForm"];
    const UNSET_VALUES_CUSTOM = {
      containerWidth: "container-fluid",
      colMobile: 12,
      colTablet: 12,
      colDesktop: 12,
      widthMobile: 100,
      widthTablet: 100,
      widthDesktop: 100,
      alignCol: "start",
      alignRowAlign: "start",
      alignRowJustify: "start",
      alignHori: "start",
      bgColor: "rgb(255, 255, 255)",
      // bgColorHover: "rgb(255, 255, 255)",
      // bgColorFocus: "rgb(255, 255, 255)",
      bgGradientColor1: "rgb(255, 255, 255)",
      bgGradientColor2: "rgb(255, 255, 255)",
      borderType: "solid",
      textColor: "rgb(0, 0, 0)",
      textColorHover: "rgb(0, 0, 0)",
      textColorFocus: "rgb(0, 0, 0)",
      linkDest: "#!",
      linkTarget: "_blank",
      linkElementDest: "#!",
      display: "block",
      imgResizeWidth: "",
      imgResizeHeight: "",
      imgSource: "",
      imgDesc: "",
      imgOpacity: 1,
      imgOpacityHover: 1,
      // listMarkerStyle: "disc",
      btnIsBold: false,
      btnIsItalic: false,
      btnIsUnderlined: false,
      btnFullWidth: false,
      googlemaps: "",
      hero1_overlay: "rgb(0, 0, 0)",
      parallaxHeightVh: 50,
      parallaxImgSrc: "",
      parallaxVertAlign: "center",
      process1_arrow_color: "rgb(108, 117, 125)",
      process2_lineColor: "rgb(0, 0, 0)",
      process2_iconColor: "rgb(255, 255, 255)",
      callout_testimonial_bgColor: "rgb(250, 250, 250)",
      callout_testimonial_shadowColor: "rgb(0, 0, 0)",
      callout_testimonial_posLeft: 50,
      sdColors: [],
      btnText: null,
      linkContent: null,
      backToTop_pos: "bottom-right",
      backToTop_sizeBtn: 3,
      backToTop_sizeIcon: 1,
      formDiv_name: "",
      formDiv_successMsg: "",
      inputfield_name: "text",
      inputfield_type: "text",
      inputfield_placeholderText: "",
      inputfield_placeholderColor: "rgb(108, 117, 125)",
      animationType: "",
      animationDelay: 100,
      animationDuration: 500,
      animationRepeat: "false",
      animationStaggeredDelay: 200,
      width: 25,
      height: 3,
      componentRadiusSide: "all",
      dividerWidth: "",
      // colLayout: COL_LAYOUT_VALUES[0],
      // colVerticalAlign: "vertical-align-top",
      colLayout: undefined,
      colVerticalAlign: undefined,
      socialIconName: "",
      socialIconColor: "",
      socialIconBgColor: "",
      componentBgColor: "rgb(255, 255, 255)",
      componentBgGradientColor1: "",
      componentBgGradientColor2: "",
      componentBoolBgIsGradient: false,
      numStars: 5,
      fontSize: 16,
    };
    let formProperty = id.replace(EDIT_FORM_ID_PREFIX, "");
    if (UNSET_ONLY_ON_HOVER.hasOwnProperty(formProperty)) {
      return UNSET_ONLY_ON_HOVER[formProperty];
    }
    if (formProperty.includes("Hover")) {
      return getFormValue(`${EDIT_FORM_ID_PREFIX}${formProperty.replace("Hover", "")}`);
    }
    if (UNSET_VALUES_ZEROS.includes(formProperty)) {
      return 0;
    }
    if (UNSET_VALUES_BLACK_TRANSPARENT.includes(formProperty)) {
      return "rgb(0, 0, 0)";
    }
    if (UNSET_VALUES_NONE.includes(formProperty)) {
      return "none";
    }
    if (UNSET_VALUES_CUSTOM.hasOwnProperty(formProperty)) {
      return UNSET_VALUES_CUSTOM[formProperty];
    }
    return "";
  } catch (error) {
    return "";
  }
};

const applyChanges = (startStyles, changedStyles) => {
  // Group all changedStyles into categories:
  // - Change selectedElement classes
  // - Change selectedElement attributes
  // - Customs
  // - Change selectedElement css rules (all remaining items that don't fall in the above categories)
  const CHANGE_CLASSES = ["containerWidth"];
  const CHANGE_ATTRS = [
    "linkDest",
    "linkTarget",
    "imgSource",
    "imgDesc",
    "elementId",
    "googlemaps",
    "inputfield_placeholderText",
    "inputfield_name",
    "formDiv_name",
    "formDiv_successMsg",
  ];
  const ATTRS_TO_CHANGE = {
    linkDest: "data-href",
    linkTarget: "data-target",
    imgSource: "src",
    imgDesc: "alt",
    elementId: "id",
    googlemaps: "src",
    inputfield_placeholderText: "placeholder",
    inputfield_name: "name",
    formDiv_name: "data-ebformname",
    formDiv_successMsg: "data-ebformmsg",
  };
  // Need to always keep imgSource & imgDesc, as startStyles is updated when user selects an image from gallery
  const ALWAYS_INCLUDE_ATTRS = ["src", "alt"];
  const CHAMGE_CUSTOMS = [
    "alignRowAlign",
    "alignRowJustify",
    "colMobile",
    "colTablet",
    "colDesktop",
    "widthMobile",
    "widthTablet",
    "widthDesktop",
    "selectedIcon",
    "markerForm",
    "markerBorderColor",
    "markerBgColor",
    "markerMarginRight",
    "hero1_overlay",
    "card3_desc_bgColor",
    "card3_desc_bgColorHover",
    "card4_popupHeight",
    "card4_popupHeightHover",
    "card6_size",
    "card6_pos",
    "parallaxHeightVh",
    "parallaxImgSrc",
    "process1_arrow_color",
    "process2_lineColor",
    "process2_iconColor",
    "callout_testimonial_bgColor",
    "callout_testimonial_shadowColor",
    "callout_testimonial_posLeft",
    "sdColors",
    "sdSpaceTop",
    "sdSpaceBottom",
    "componentBgColor",
    "componentBoolBgIsGradient",
    "componentBgGradientDirection",
    "componentBgGradientColor1",
    "componentBgGradientColor2",
    "componentBgGradientStop1",
    "componentBgGradientStop2",
    "componentPaddingTop",
    "componentPaddingBottom",
    "componentRadiusSize",
    "componentRadiusSide",
    "btnText",
    "linkContent",
    "backToTop_pos",
    "backToTop_sizeBtn",
    "backToTop_sizeIcon",
    "inputfield_fontSize",
    "formDiv_name",
    "formDiv_successMsg",
    "inputfield_type",
    "inputfield_name",
    "inputfield_placeholderText",
    "inputfield_placeholderColor",
    "animationType",
    "animationDelay",
    "animationDuration",
    "animationRepeat",
    "animationStaggeredDelay",
    "colLayout",
    "colVerticalAlign",
    "socialIconName",
    "socialIconColor",
    "socialIconBgColor",
    "linkElementDest",
    "numStars",
  ];
  let changeClasses = changedStyles
    .filter((formProperty) => CHANGE_CLASSES.includes(Object.keys(formProperty)[0]))
    .map((formProperty) => ({
      property: Object.keys(formProperty)[0],
      oldClassname: startStyles[Object.keys(formProperty)[0]],
      newClassname: Object.values(formProperty)[0],
    }))
    .filter((item) => item.oldClassname !== item.newClassname);
  let changeAttrs = changedStyles
    .filter((formProperty) => CHANGE_ATTRS.includes(Object.keys(formProperty)[0]))
    .map((formProperty) => ({
      attr: ATTRS_TO_CHANGE[Object.keys(formProperty)[0]],
      oldVal: startStyles[Object.keys(formProperty)[0]],
      newVal: Object.values(formProperty)[0],
    }))
    .filter((item) => ALWAYS_INCLUDE_ATTRS.includes(item.attr) || item.oldVal !== item.newVal);
  let changeCss = changedStyles.filter(
    (formProperty) =>
      !CHANGE_CLASSES.includes(Object.keys(formProperty)[0]) &&
      !CHANGE_ATTRS.includes(Object.keys(formProperty)[0]) &&
      !CHAMGE_CUSTOMS.includes(Object.keys(formProperty)[0])
  );
  const ALWAYS_INCLUDE_CUSTOMS = [
    "parallaxImgSrc",
    "card6_size",
    "card6_pos",
    "sdColors",
    "markerForm",
    "markerBorderColor",
    "markerBgColor",
    "markerMarginRight",
    "animationType",
    "animationDelay",
    "animationDuration",
    "animationRepeat",
    "animationStaggeredDelay",
    "componentRadiusSize",
    "componentRadiusSide",
    "socialIconName",
    "socialIconColor",
    "socialIconBgColor",
    "componentBgColor",
    "componentBoolBgIsGradient",
    "componentBgGradientDirection",
    "componentBgGradientColor1",
    "componentBgGradientColor2",
    "componentBgGradientStop1",
    "componentBgGradientStop2",
  ]; // Always keep these customs, even if the same as startStyles
  let changeCustoms = changedStyles
    .filter((formProperty) => CHAMGE_CUSTOMS.includes(Object.keys(formProperty)[0]))
    .map((formProperty) => ({
      property: Object.keys(formProperty)[0],
      oldVal: startStyles[Object.keys(formProperty)[0]],
      newVal: Object.values(formProperty)[0],
    }))
    .filter((item) => ALWAYS_INCLUDE_CUSTOMS.includes(item.property) || item.oldVal !== item.newVal);
  // Get updated CSS rules
  let changedStylesObj = {};
  changedStyles.forEach((item) => {
    changedStylesObj = { ...changedStylesObj, [Object.keys(item)[0]]: Object.values(item)[0] };
  });
  changeCss = getUpdatedCssRules(startStyles, changeCss, changedStylesObj);
  // Apply the style changes to the correct target element
  // =====
  // =====
  // console.log("=======================");
  // console.log("=======================");
  // console.log("=======================");
  // Get selected element
  let selectedElement = getState("eb", "selectedElement");
  let splitSelectedElementId = selectedElement.split("-");
  let target = getTargetObj(splitSelectedElementId);
  // console.log(target);
  // Check whether target.data-name is a special component
  const SPECIALS = [
    "component",
    "animate",
    "element_col",
    "statistics2",
    "statistics3a",
    "hero1",
    "card2",
    "card3",
    "card4",
    "card6",
    "card16",
    "card23",
    "parallax",
    // "parallax3_content",
    "process1",
    "process2",
    "callout_testimonial",
    "section_divider",
    "navbar",
    "img_gallery1",
    "img_gallery2",
    "backtotop",
    "inputfield",
    "headingline",
    "divider",
    "col_component",
    "button",
    "socialIcon",
    "downloadApp",
  ];
  let targetName = target.attributes.filter((attr) => attr.property === "data-name")[0].value;
  if (SPECIALS.includes(targetName)) {
    applyChangesSpecials(target, targetName, changeCss, changeClasses, changeAttrs, changeCustoms);
    // console.log("=======================");
    // console.log("=======================");
    // console.log("=======================");
  } else {
    // Normal component => update just the selectedElement
    splitSelectedElementId = selectedElement.split("-");
    // Update CSS in redux
    changeCss.length > 0 && store.dispatch(updateCss(splitSelectedElementId, changeCss));
    // Update target's classes
    changeClasses.length > 0 && store.dispatch(updateClasses(splitSelectedElementId, changeClasses));
    // Update target's attributes
    changeAttrs.length > 0 && store.dispatch(updateAttributes(splitSelectedElementId, changeAttrs));
    // Update customs
    changeCustoms.length > 0 && store.dispatch(updateCustoms(splitSelectedElementId, changeCustoms));
  }
};

const getUpdatedCssRules = (startStyles, changeCss, changedStylesObj) => {
  // changeCss = [ { marginTop: 2 }, ... ]
  // Group changeCss by css property in format { property: "margin", pseudo: "" }
  let cssPropertyGroups = groupCssProperties(changeCss);
  // For each cssPropertyGroup, get the updated css rule: { property: "margin", pseudo: "", value: "0 0 0.5rem 0" }
  //    Updated css rule is based on the changed styles if available, or elese the starting styles if user didn't make changes
  cssPropertyGroups = cssPropertyGroups.map((cssPropGroup) => getUpdatedCssRule(cssPropGroup, startStyles, changedStylesObj));
  // Return the updated css rules grouped by css property
  return cssPropertyGroups;
};

const groupCssProperties = (changeCss) => {
  // changeCss format: [ { marginTop: 2 }, ... ]
  try {
    // Prepare an array in form of: [ { property: "margin", pseudo: "" } ]
    let cssPropertyGroups = changeCss.map((item) => getCssPropsAndPseudoFromEditForm(Object.keys(item)[0]));
    // Filter duplicates
    return [...new Set(cssPropertyGroups.map((cssProperty) => `${cssProperty.property}%${cssProperty.pseudo}`))].map((item) => ({
      property: item.split("%")[0],
      pseudo: item.split("%")[1] || "",
    }));
  } catch (error) {
    console.error(error);
    return [];
  }
};

const getCssPropsAndPseudoFromEditForm = (editFormProp) => {
  const MAPPING = {
    alignCol: { property: "align-items", pseudo: "" },
    alignRowAlign: { property: "align-items", pseudo: "" },
    alignRowJustify: { property: "justify-content", pseudo: "" },
    parallaxVertAlign: { property: "justify-content", pseudo: "" },
    // alignHori: { property: "align-self", pseudo: "" },
    alignHori: { property: "text-align", pseudo: "" },
    bgColor: { property: "background-color", pseudo: "" },
    boolBgIsGradient: { property: "background", pseudo: "" },
    bgGradientDirection: { property: "background", pseudo: "" },
    bgGradientColor1: { property: "background", pseudo: "" },
    bgGradientColor2: { property: "background", pseudo: "" },
    bgGradientStop1: { property: "background", pseudo: "" },
    bgGradientStop2: { property: "background", pseudo: "" },
    btnBgColor: { property: "background", pseudo: "" },
    borderSide: { property: "border-style", pseudo: "" },
    borderType: { property: "border-style", pseudo: "" },
    borderWidth: { property: "border-width", pseudo: "" },
    borderColor: { property: "border-color", pseudo: "" },
    borderColorHover: { property: "border-color", pseudo: "hover" },
    borderColorFocus: { property: "border-color", pseudo: "focus" },
    borderRadius: { property: "border-radius", pseudo: "" },
    linkDeco: { property: "text-decoration", pseudo: "" },
    linkDecoHover: { property: "text-decoration", pseudo: "hover" },
    paddingTop: { property: "padding", pseudo: "" },
    paddingRight: { property: "padding", pseudo: "" },
    paddingBottom: { property: "padding", pseudo: "" },
    paddingLeft: { property: "padding", pseudo: "" },
    marginTop: { property: "margin", pseudo: "" },
    marginRight: { property: "margin", pseudo: "" },
    marginBottom: { property: "margin", pseudo: "" },
    marginLeft: { property: "margin", pseudo: "" },
    display: { property: "display", pseudo: "" },
    imgOpacity: { property: "opacity", pseudo: "" },
    imgOpacityHover: { property: "opacity", pseudo: "hover" },
    imgBlur: { property: "filter", pseudo: "" },
    imgBrightness: { property: "filter", pseudo: "" },
    imgContrast: { property: "filter", pseudo: "" },
    imgGrayscale: { property: "filter", pseudo: "" },
    imgHueRotate: { property: "filter", pseudo: "" },
    imgInvert: { property: "filter", pseudo: "" },
    imgSaturate: { property: "filter", pseudo: "" },
    imgSepia: { property: "filter", pseudo: "" },
    imgBlurHover: { property: "filter", pseudo: "hover" },
    imgBrightnessHover: { property: "filter", pseudo: "hover" },
    imgContrastHover: { property: "filter", pseudo: "hover" },
    imgGrayscaleHover: { property: "filter", pseudo: "hover" },
    imgHueRotateHover: { property: "filter", pseudo: "hover" },
    imgInvertHover: { property: "filter", pseudo: "hover" },
    imgSaturateHover: { property: "filter", pseudo: "hover" },
    imgSepiaHover: { property: "filter", pseudo: "hover" },
    imgTransformHover: { property: "transform", pseudo: "hover" },
    imgResizeWidth: { property: "width", pseudo: "" },
    imgResizeHeight: { property: "height", pseudo: "" },
    imgResizeWidthPx: { property: "width", pseudo: "" },
    imgResizeHeightPx: { property: "height", pseudo: "" },
    listMarkerStyle: { property: "list-style", pseudo: "" },
    listMarkerColor: { property: "color", pseudo: "marker" },
    btnIsBold: { property: "font-weight", pseudo: "" },
    btnIsItalic: { property: "font-style", pseudo: "" },
    btnIsUnderlined: { property: "text-decoration", pseudo: "" },
    btnFullWidth: { property: "display", pseudo: "" },
    textColor: { property: "color", pseudo: "" },
    textColorHover: { property: "color", pseudo: "hover" },
    textColorFocus: { property: "color", pseudo: "focus" },
    transition: { property: "transition", pseudo: "" },
    sdSpaceTop: { property: "padding-top", pseudo: "" },
    sdSpaceBottom: { property: "padding-bottom", pseudo: "" },
    width: { property: "width", pseudo: "" },
    height: { property: "height", pseudo: "" },
    fontSize: { property: "font-size", pseudo: "" },
    dividerWidth: { property: "width", pseudo: "" },
    dividerWidthPx: { property: "width", pseudo: "" },
  };
  return MAPPING[editFormProp] || { property: "", pseudo: "" };
};

const getUpdatedCssRule = (cssPropGroup, startStyles, changedStylesObj) => {
  try {
    let { property, pseudo } = cssPropGroup;
    // cssPropGroup format: { property: "margin", pseudo: "" }
    if (property === "align-items" && pseudo === "") {
      return { ...cssPropGroup, value: getUpdCss(changedStylesObj.alignCol, startStyles.alignCol) };
    }
    if (property === "align-items" && pseudo === "") {
      return { ...cssPropGroup, value: getUpdCss(changedStylesObj.alignRowAlign, startStyles.alignRowAlign) };
    }
    if (property === "justify-content" && pseudo === "") {
      return { ...cssPropGroup, value: getUpdCss(changedStylesObj.parallaxVertAlign, startStyles.parallaxVertAlign) };
    }
    // if (property === "align-self" && pseudo === "") {
    //   return { ...cssPropGroup, value: getUpdCss(changedStylesObj.alignHori, startStyles.alignHori) };
    // }
    if (property === "text-align" && pseudo === "") {
      return { ...cssPropGroup, value: getUpdCss(changedStylesObj.alignHori, startStyles.alignHori) };
    }
    if (property === "background-color" && pseudo === "") {
      // Can either be bgColor, bgGradientColor1
      let boolBgIsGradient = getUpdCss(changedStylesObj.boolBgIsGradient, startStyles.boolBgIsGradient);
      let val = getUpdCss(changedStylesObj.bgColor, startStyles.bgColor);
      if (boolBgIsGradient) {
        val = getUpdCss(changedStylesObj.bgGradientColor1, startStyles.bgGradientColor1);
      }
      return { ...cssPropGroup, value: val };
    }
    if (property === "background" && pseudo === "") {
      // Can be either bgColor related or btnBgColor
      let val = getUpdCss(changedStylesObj.btnBgColor, startStyles.btnBgColor);
      if (typeof val === "undefined") {
        // background gradient
        let boolBgIsGradient = getUpdCss(changedStylesObj.boolBgIsGradient, startStyles.boolBgIsGradient);
        let bgGradientDirection = getUpdCss(changedStylesObj.bgGradientDirection, startStyles.bgGradientDirection);
        let bgGradientColor1 = getUpdCss(changedStylesObj.bgGradientColor1, startStyles.bgGradientColor1);
        let bgGradientColor2 = getUpdCss(changedStylesObj.bgGradientColor2, startStyles.bgGradientColor2);
        let bgGradientStop1 = getUpdCss(changedStylesObj.bgGradientStop1, startStyles.bgGradientStop1);
        let bgGradientStop2 = getUpdCss(changedStylesObj.bgGradientStop2, startStyles.bgGradientStop2);
        val = getUpdCss(changedStylesObj.bgColor, startStyles.bgColor);
        if (boolBgIsGradient) {
          val = `linear-gradient(${bgGradientDirection}deg, ${bgGradientColor1} ${bgGradientStop1}%, ${bgGradientColor2} ${bgGradientStop2}%)`;
        }
      }
      return { ...cssPropGroup, value: val };
    }
    if (property === "border-style" && pseudo === "") {
      let borderSide = getUpdCss(changedStylesObj.borderSide, startStyles.borderSide);
      let borderType = getUpdCss(changedStylesObj.borderType, startStyles.borderType);
      let val = "none none none none"; // top right bottom left
      if (borderSide === "all") {
        val = `${borderType} ${borderType} ${borderType} ${borderType}`;
      }
      if (borderSide === "left") {
        val = `none none none ${borderType}`;
      }
      if (borderSide === "right") {
        val = `none ${borderType} none none`;
      }
      if (borderSide === "top") {
        val = `${borderType} none none none`;
      }
      if (borderSide === "bottom") {
        val = `none none ${borderType} none`;
      }
      if (borderSide === "topbottom") {
        val = `${borderType} none ${borderType} none`;
      }
      if (borderSide === "leftright") {
        val = `none ${borderType} none ${borderType}`;
      }
      if (borderSide === "exceptleft") {
        val = `${borderType} ${borderType} ${borderType} none`;
      }
      if (borderSide === "exceptright") {
        val = `${borderType} none ${borderType} ${borderType}`;
      }
      if (borderSide === "excepttop") {
        val = `none ${borderType} ${borderType} ${borderType}`;
      }
      if (borderSide === "exceptbottom") {
        val = `${borderType} ${borderType} none ${borderType}`;
      }
      return { ...cssPropGroup, value: val };
    }
    if (property === "border-width" && pseudo === "") {
      return { ...cssPropGroup, value: `${getUpdCss(changedStylesObj.borderWidth, startStyles.borderWidth)}px` };
    }
    if (property === "border-color" && pseudo === "") {
      return { ...cssPropGroup, value: getUpdCss(changedStylesObj.borderColor, startStyles.borderColor) };
    }
    if (property === "border-color" && pseudo === "hover") {
      return { ...cssPropGroup, value: getUpdCss(changedStylesObj.borderColorHover, startStyles.borderColorHover) };
    }
    if (property === "border-color" && pseudo === "focus") {
      return { ...cssPropGroup, value: getUpdCss(changedStylesObj.borderColorFocus, startStyles.borderColorFocus) };
    }
    if (property === "border-radius" && pseudo === "") {
      let val = getUpdCss(changedStylesObj.borderRadius, startStyles.borderRadius);
      if (val < 50) {
        return { ...cssPropGroup, value: `${val}rem` };
      }
      if (val < 100) {
        return { ...cssPropGroup, value: `${val}%` };
      }
      return { ...cssPropGroup, value: `${val}px` };
    }
    if (property === "text-decoration" && pseudo === "") {
      // Can either be linkDeco or btnIsUnderlined
      let val = getUpdCss(changedStylesObj.linkDeco, startStyles.linkDeco);
      if (typeof val === "undefined") {
        val = getUpdCss(changedStylesObj.btnIsUnderlined, startStyles.btnIsUnderlined) ? "underline" : "none";
      }
      return { ...cssPropGroup, value: val };
    }
    if (property === "text-decoration" && pseudo === "hover") {
      return { ...cssPropGroup, value: getUpdCss(changedStylesObj.linkDecoHover, startStyles.linkDecoHover) };
    }
    if (property === "padding" && pseudo === "") {
      return {
        ...cssPropGroup,
        value: `${getUpdCss(changedStylesObj.paddingTop, startStyles.paddingTop)}rem ${getUpdCss(
          changedStylesObj.paddingRight,
          startStyles.paddingRight
        )}rem ${getUpdCss(changedStylesObj.paddingBottom, startStyles.paddingBottom)}rem ${getUpdCss(
          changedStylesObj.paddingLeft,
          startStyles.paddingLeft
        )}rem`,
      };
    }
    if (property === "margin" && pseudo === "") {
      return {
        ...cssPropGroup,
        value: `${getUpdCss(changedStylesObj.marginTop, startStyles.marginTop)}rem ${getUpdCss(
          changedStylesObj.marginRight,
          startStyles.marginRight
        )}rem ${getUpdCss(changedStylesObj.marginBottom, startStyles.marginBottom)}rem ${getUpdCss(
          changedStylesObj.marginLeft,
          startStyles.marginLeft
        )}rem`,
      };
    }
    if (property === "display" && pseudo === "") {
      // Can either display or btnFullWidth
      let val = "";
      if (typeof changedStylesObj.display !== "undefined" && typeof startStyles.display !== "undefined") {
        val = getUpdCss(changedStylesObj.display, startStyles.display);
      }
      if (typeof changedStylesObj.btnFullWidth !== "undefined" && typeof startStyles.btnFullWidth !== "undefined") {
        val = getUpdCss(changedStylesObj.btnFullWidth, startStyles.btnFullWidth) ? "block" : "inline-block";
      }
      return { ...cssPropGroup, value: val };
    }
    if (property === "opacity" && pseudo === "") {
      return { ...cssPropGroup, value: getUpdCss(changedStylesObj.imgOpacity, startStyles.imgOpacity) };
    }
    if (property === "opacity" && pseudo === "hover") {
      return { ...cssPropGroup, value: getUpdCss(changedStylesObj.imgOpacityHover, startStyles.imgOpacityHover) };
    }
    if (property === "filter" && pseudo === "") {
      return {
        ...cssPropGroup,
        value: `brightness(${getUpdCss(changedStylesObj.imgBrightness, startStyles.imgBrightness)}%) blur(${getUpdCss(
          changedStylesObj.imgBlur,
          startStyles.imgBlur
        )}px) contrast(${getUpdCss(changedStylesObj.imgContrast, startStyles.imgContrast)}%) grayscale(${getUpdCss(
          changedStylesObj.imgGrayscale,
          startStyles.imgGrayscale
        )}%) sepia(${getUpdCss(changedStylesObj.imgSepia, startStyles.imgSepia)}%) saturate(${getUpdCss(
          changedStylesObj.imgSaturate,
          startStyles.imgSaturate
        )}%) hue-rotate(${getUpdCss(changedStylesObj.imgHueRotate, startStyles.imgHueRotate)}deg) invert(${getUpdCss(
          changedStylesObj.imgInvert,
          startStyles.imgInvert
        )}%)`,
      };
    }
    if (property === "filter" && pseudo === "hover") {
      return {
        ...cssPropGroup,
        value: `brightness(${getUpdCss(changedStylesObj.imgBrightnessHover, startStyles.imgBrightnessHover)}%) blur(${getUpdCss(
          changedStylesObj.imgBlurHover,
          startStyles.imgBlurHover
        )}px) contrast(${getUpdCss(changedStylesObj.imgContrastHover, startStyles.imgContrastHover)}%) grayscale(${getUpdCss(
          changedStylesObj.imgGrayscaleHover,
          startStyles.imgGrayscaleHover
        )}%) sepia(${getUpdCss(changedStylesObj.imgSepiaHover, startStyles.imgSepiaHover)}%) saturate(${getUpdCss(
          changedStylesObj.imgSaturateHover,
          startStyles.imgSaturateHover
        )}%) hue-rotate(${getUpdCss(changedStylesObj.imgHueRotateHover, startStyles.imgHueRotateHover)}deg) invert(${getUpdCss(
          changedStylesObj.imgInvertHover,
          startStyles.imgInvertHover
        )}%)`,
      };
    }
    if (property === "width" && pseudo === "") {
      // Can either width, imgResizeWidth or dividerWidth
      let val = "";
      if (typeof changedStylesObj.width !== "undefined" && typeof startStyles.width !== "undefined") {
        val = `${getUpdCss(changedStylesObj.width, startStyles.width)}px`;
      }
      if (
        (typeof changedStylesObj.imgResizeWidth !== "undefined" && typeof startStyles.imgResizeWidth !== "undefined") ||
        (typeof changedStylesObj.imgResizeWidthPx !== "undefined" && typeof startStyles.imgResizeWidthPx !== "undefined")
      ) {
        if (["auto", "100%"].includes(changedStylesObj.imgResizeWidth)) {
          val = getUpdCss(changedStylesObj.imgResizeWidth, startStyles.imgResizeWidth);
        } else {
          val = `${getUpdCss(changedStylesObj.imgResizeWidthPx, startStyles.imgResizeWidthPx)}px`;
        }
      }
      if (
        (typeof changedStylesObj.dividerWidth !== "undefined" && typeof startStyles.dividerWidth !== "undefined") ||
        (typeof changedStylesObj.dividerWidthPx !== "undefined" && typeof startStyles.dividerWidthPx !== "undefined")
      ) {
        if (["auto", "100%"].includes(changedStylesObj.dividerWidth)) {
          val = getUpdCss(changedStylesObj.dividerWidth, startStyles.dividerWidth);
        } else {
          val = `${getUpdCss(changedStylesObj.dividerWidthPx, startStyles.dividerWidthPx)}px`;
        }
      }
      return { ...cssPropGroup, value: val };
    }
    if (property === "height" && pseudo === "") {
      // Can be either imgResizeHeight or height
      let val = "";
      if (typeof changedStylesObj.height !== "undefined" && typeof startStyles.height !== "undefined") {
        val = `${getUpdCss(changedStylesObj.height, startStyles.height)}px`;
      }
      if (
        (typeof changedStylesObj.imgResizeHeight !== "undefined" && typeof startStyles.imgResizeHeight !== "undefined") ||
        (typeof changedStylesObj.imgResizeHeightPx !== "undefined" && typeof startStyles.imgResizeHeightPx !== "undefined")
      ) {
        if (["auto", "100%"].includes(changedStylesObj.imgResizeHeight)) {
          val = getUpdCss(changedStylesObj.imgResizeHeight, startStyles.imgResizeHeight);
        } else {
          val = `${getUpdCss(changedStylesObj.imgResizeHeightPx, startStyles.imgResizeHeightPx)}px`;
        }
      }
      return { ...cssPropGroup, value: val };
    }
    if (property === "list-style" && pseudo === "") {
      return { ...cssPropGroup, value: getUpdCss(changedStylesObj.listMarkerStyle, startStyles.listMarkerStyle) };
    }
    if (property === "font-weight" && pseudo === "") {
      return { ...cssPropGroup, value: getUpdCss(changedStylesObj.btnIsBold, startStyles.btnIsBold) ? "bold" : "normal" };
    }
    if (property === "font-style" && pseudo === "") {
      return { ...cssPropGroup, value: getUpdCss(changedStylesObj.btnIsItalic, startStyles.btnIsItalic) ? "italic" : "normal" };
    }
    if (property === "font-size" && pseudo === "") {
      return { ...cssPropGroup, value: `${getUpdCss(changedStylesObj.fontSize, startStyles.fontSize)}px` };
    }
    if (property === "color" && pseudo === "") {
      // Can either be textColor or listMarkerColor
      let val = getUpdCss(changedStylesObj.textColor, startStyles.textColor);
      if (typeof val === "undefined") {
        val = getUpdCss(changedStylesObj.listMarkerColor, startStyles.listMarkerColor);
      }
      return { ...cssPropGroup, value: val };
    }
    if (property === "color" && pseudo === "hover") {
      return { ...cssPropGroup, value: getUpdCss(changedStylesObj.textColorHover, startStyles.textColorHover) };
    }
    if (property === "color" && pseudo === "focus") {
      return { ...cssPropGroup, value: getUpdCss(changedStylesObj.textColorFocus, startStyles.textColorFocus) };
    }
    if (property === "transition" && pseudo === "") {
      return { ...cssPropGroup, value: `all ${getUpdCss(changedStylesObj.transition, startStyles.transition)}s ease-in-out` };
    }
  } catch (error) {
    console.error(error);
    return { ...cssPropGroup, value: "" };
  }
};

const getUpdCss = (changedStyle, startStyle) => {
  return typeof changedStyle === "undefined" ? startStyle : changedStyle;
};

// ===============================================
// == Apply style changes on special components ==
// ===============================================

const applyChangesSpecials = (target, targetName, changeCss, changeClasses, changeAttrs, changeCustoms) => {
  // console.log("changeCss");
  // console.log(changeCss);
  // console.log("changeClasses");
  // console.log(changeClasses);
  // console.log("changeAttrs");
  // console.log(changeAttrs);
  // console.log("changeCustoms");
  // console.log(changeCustoms);
  // Get the changes to apply to each element in format [ { splitElementId: [], css: [], classes: [], attrs: [], customs: [] } ]
  let elementsAndChanges = [];
  if (targetName === "component" || targetName === "col_component") {
    // Get column-container
    let componentColContainer = getFirstChildByClassname(target, "column-container");
    let tdWrapper = getFirstChildByClassnameMatchRegex(target, /col-td-wrapper/);

    // Component styles
    let selectedElement = getState("eb", "selectedElement");
    const TARGET_CSS_PROPS = ["background", "background-color"];
    const TARGET_ATTRS = ["id"];
    elementsAndChanges.push({
      splitElementId: selectedElement.split("-"),
      css: changeCss.filter((item) => TARGET_CSS_PROPS.includes(item.property)),
      classes: [],
      attrs: changeAttrs.filter((item) => TARGET_ATTRS.includes(item.attr)),
      customs: [],
    });
    // column-container styles
    const COL_CONTAINER_CSS_PROPS = ["border-style", "border-width", "border-color"];
    const COL_CONTAINER_CUSTOMS = [
      "componentBgColor",
      "componentBoolBgIsGradient",
      "componentBgGradientDirection",
      "componentBgGradientColor1",
      "componentBgGradientColor2",
      "componentBgGradientStop1",
      "componentBgGradientStop2",
      "componentPaddingTop",
      "componentPaddingBottom",
      "componentRadiusSize",
      "componentRadiusSide",
    ];
    elementsAndChanges.push({
      splitElementId: getConcatElementId(componentColContainer.childId).split("-"),
      css: changeCss.filter((item) => COL_CONTAINER_CSS_PROPS.includes(item.property)),
      classes: [],
      attrs: [],
      customs: changeCustoms.filter((item) => COL_CONTAINER_CUSTOMS.includes(item.property)),
    });
    // col-td-wrapper styles
    const TD_WRAPPER_CSS_PROPS = ["padding", "text-align"];
    elementsAndChanges.push({
      splitElementId: getConcatElementId(tdWrapper.childId).split("-"),
      css: changeCss.filter((item) => TD_WRAPPER_CSS_PROPS.includes(item.property)),
      classes: [],
      attrs: [],
      customs: [],
    });
    // Optionally, in case target is a col_component
    // Get all columns and their .col-[x] layout/vertical alignment
    let columns = getAllChildrenWithAttr(target, "data-colLayout", "true");
    if (columns !== null && columns.length > 0) {
      // For each related element keep only those style updates that should be applied to that element
      let colLayout = changeCustoms.filter((item) => item.property === "colLayout")[0];
      let colVerticalAlign = changeCustoms.filter((item) => item.property === "colVerticalAlign")[0];
      columns.forEach((col, i) => {
        let colClasses = [];
        if (typeof colLayout !== "undefined" && typeof colLayout.newVal !== "undefined") {
          colClasses.push({ oldClassname: `col-${colLayout.oldVal.split("-")[i]}`, newClassname: `col-${colLayout.newVal.split("-")[i]}` });
        }
        if (typeof colVerticalAlign !== "undefined" && typeof colVerticalAlign.newVal !== "undefined") {
          colClasses.push({ oldClassname: colVerticalAlign.oldVal, newClassname: colVerticalAlign.newVal });
        }
        elementsAndChanges.push({
          splitElementId: getConcatElementId(col.childId).split("-"),
          css: [],
          classes: colClasses,
          attrs: [],
          customs: [],
        });
      });
    }
  }
  if (targetName === "divider") {
    // target = td.col-td-wrapper => get divider
    let divDivider = getFirstChildWithAttr(target, "data-elementgetter1", "true");
    // For each related element keep only those style updates that should be applied to that element
    let selectedElement = getState("eb", "selectedElement");
    const TARGET_CSS_PROPS = ["text-align"];
    elementsAndChanges.push({
      splitElementId: selectedElement.split("-"),
      css: changeCss.filter((item) => TARGET_CSS_PROPS.includes(item.property)),
      classes: [],
      attrs: [],
      customs: [],
    });
    const DIVIDER_CSS_PROPS = ["width", "height", "background", "background-color", "border-style", "border-width", "border-color", "border-radius"];
    const DIVIDER_ATTRS = ["id"];
    elementsAndChanges.push({
      splitElementId: getConcatElementId(divDivider.childId).split("-"),
      css: changeCss.filter((item) => DIVIDER_CSS_PROPS.includes(item.property)),
      classes: [],
      attrs: changeAttrs.filter((item) => DIVIDER_ATTRS.includes(item.attr)),
      customs: [],
    });
  }
  if (targetName === "button") {
    // target = button
    let btnParent = getTargetParent(target.childId);
    // For each related element keep only those style updates that should be applied to that element
    let selectedElement = getState("eb", "selectedElement");
    const TARGET_CSS_PROPS = [
      "font-weight",
      "font-style",
      "text-decoration",
      "color",
      "background",
      "border-style",
      "border-width",
      "border-color",
      "border-radius",
      "padding",
      "display",
      "margin",
      "font-size",
    ];
    const TARGET_ATTRS = ["id", "data-href", "data-target"];
    const TARGET_CUSTOMS = ["btnText"];
    elementsAndChanges.push({
      splitElementId: selectedElement.split("-"),
      css: changeCss.filter((item) => TARGET_CSS_PROPS.includes(item.property)),
      classes: [],
      attrs: changeAttrs.filter((item) => TARGET_ATTRS.includes(item.attr)),
      customs: changeCustoms.filter((item) => TARGET_CUSTOMS.includes(item.property)),
    });
    const PARENT_CSS_PROPS = ["text-align"];
    elementsAndChanges.push({
      splitElementId: getConcatElementId(btnParent.childId).split("-"),
      css: changeCss.filter((item) => PARENT_CSS_PROPS.includes(item.property)),
      classes: [],
      attrs: [],
      customs: [],
    });
  }
  if (targetName === "a") {
    // target = a
    // For each related element keep only those style updates that should be applied to that element
    let selectedElement = getState("eb", "selectedElement");
    const TARGET_CSS_PROPS = ["text-decoration", "color", "margin", "font-size"];
    const TARGET_ATTRS = ["id", "data-href", "data-target"];
    const TARGET_CUSTOMS = ["linkContent"];
    elementsAndChanges.push({
      splitElementId: selectedElement.split("-"),
      css: changeCss.filter((item) => TARGET_CSS_PROPS.includes(item.property)),
      classes: [],
      attrs: changeAttrs.filter((item) => TARGET_ATTRS.includes(item.attr)),
      customs: changeCustoms.filter((item) => TARGET_CUSTOMS.includes(item.property)),
    });
  }
  if (targetName === "socialIcon") {
    // target = a. Get its img child
    let socialIconImg = target.children[0];
    // Form new image source link
    let socialIconName = changeCustoms.filter((item) => item.property === "socialIconName")[0];
    let socialIconColor = changeCustoms.filter((item) => item.property === "socialIconColor")[0];
    let socialIconBgColor = changeCustoms.filter((item) => item.property === "socialIconBgColor")[0];
    if (socialIconColor === socialIconBgColor) {
      // Can't have same icon & bg color => Change one of them
      socialIconBgColor === SOCIAL_COLORS[0].val && (socialIconColor = SOCIAL_COLORS[2].val);
      socialIconBgColor === SOCIAL_COLORS[1].val && (socialIconColor = SOCIAL_COLORS[0].val);
      socialIconBgColor === SOCIAL_COLORS[2].val && (socialIconColor = SOCIAL_COLORS[0].val);
    }
    let socialIconAttrsToChange = [
      {
        attr: "src",
        oldVal: `https://cdn.satonda.com/eb/assets/${socialIconName.oldVal}-${socialIconColor.oldVal}-${socialIconBgColor.oldVal}-square.png`,
        newVal: `https://cdn.satonda.com/eb/assets/${socialIconName.newVal}-${socialIconColor.newVal}-${socialIconBgColor.newVal}-square.png`,
      },
      {
        attr: "alt",
        oldVal: capitalizeFirstLetter(socialIconName.oldVal.replace(/-/g, " ")),
        newVal: capitalizeFirstLetter(socialIconName.newVal.replace(/-/g, " ")),
      },
    ];
    // For each related element keep only those style updates that should be applied to that element
    let selectedElement = getState("eb", "selectedElement");
    const TARGET_ATTRS = ["id", "data-href", "data-target"];
    const TARGET_CSS_PROPS = ["margin"];
    elementsAndChanges.push({
      splitElementId: selectedElement.split("-"),
      css: changeCss.filter((item) => TARGET_CSS_PROPS.includes(item.property)),
      classes: [],
      attrs: changeAttrs.filter((item) => TARGET_ATTRS.includes(item.attr)),
      customs: [],
    });
    const IMG_CSS_PROPS = ["border-style", "border-width", "border-color", "border-radius"];
    elementsAndChanges.push({
      splitElementId: getConcatElementId(socialIconImg.childId).split("-"),
      css: changeCss.filter((item) => IMG_CSS_PROPS.includes(item.property)),
      classes: [],
      attrs: socialIconAttrsToChange,
      customs: [],
    });
  }
  if (targetName === "downloadApp") {
    // target = a
    // For each related element keep only those style updates that should be applied to that element
    let selectedElement = getState("eb", "selectedElement");
    const TARGET_ATTRS = ["id", "data-href", "data-target"];
    elementsAndChanges.push({
      splitElementId: selectedElement.split("-"),
      css: [],
      classes: [],
      attrs: changeAttrs.filter((item) => TARGET_ATTRS.includes(item.attr)),
      customs: [],
    });
  }
  if (targetName === "ratingStars") {
    // target = div wrapper
    let selectedElement = getState("eb", "selectedElement");
    const TARGET_CUSTOMS = ["numStars"];
    elementsAndChanges.push({
      splitElementId: selectedElement.split("-"),
      css: [],
      classes: [],
      attrs: [],
      customs: changeCustoms.filter((item) => TARGET_CUSTOMS.includes(item.property)),
    });
  }
  // Apply the changes for each element
  // console.log("elementsAndChanges");
  // console.log(elementsAndChanges);
  elementsAndChanges.forEach((element, i) => {
    element.css.length > 0 && store.dispatch(updateCss(element.splitElementId, element.css));
    element.classes.length > 0 && store.dispatch(updateClasses(element.splitElementId, element.classes));
    element.attrs.length > 0 && store.dispatch(updateAttributes(element.splitElementId, element.attrs));
    element.customs.length > 0 && store.dispatch(updateCustoms(element.splitElementId, element.customs, i));
  });
};

// =============
// == Helpers ==
// =============

export const getBorderStyleCssString = (borderSide, borderType, none = "none") => {
  let val = `${none} ${none} ${none} ${none}`; // top right bottom left
  if (borderSide === "all") {
    val = `${borderType} ${borderType} ${borderType} ${borderType}`;
  }
  if (borderSide === "left") {
    val = `${none} ${none} ${none} ${borderType}`;
  }
  if (borderSide === "right") {
    val = `${none} ${borderType} ${none} ${none}`;
  }
  if (borderSide === "top") {
    val = `${borderType} ${none} ${none} ${none}`;
  }
  if (borderSide === "bottom") {
    val = `${none} ${none} ${borderType} ${none}`;
  }
  if (borderSide === "topbottom") {
    val = `${borderType} ${none} ${borderType} ${none}`;
  }
  if (borderSide === "leftright") {
    val = `${none} ${borderType} ${none} ${borderType}`;
  }
  if (borderSide === "exceptleft") {
    val = `${borderType} ${borderType} ${borderType} ${none}`;
  }
  if (borderSide === "exceptright") {
    val = `${borderType} ${none} ${borderType} ${borderType}`;
  }
  if (borderSide === "excepttop") {
    val = `${none} ${borderType} ${borderType} ${borderType}`;
  }
  if (borderSide === "exceptbottom") {
    val = `${borderType} ${borderType} ${none} ${borderType}`;
  }
  return val;
};
