import axios from "axios";
// Bring in action types
import {
  // Manage project
  LOAD_PROJECT,
  LOAD_TEMPLATE,
  UPDATE_PROJECT_GALLERY,
  UPDATE_GALLERY_FILE_DESC,
  AUTH_ERROR,
  UPDATE_MAILMETA,
  // Product tour
  OPEN_PRODUCT_TOUR,
  CLOSE_PRODUCT_TOUR,
  // Drag & drop
  START_DRAG_FROM_MENU,
  END_DRAG_FROM_MENU,
  DROP_COMPONENT,
  // Edit component
  REORDER_COMPONENT,
  DELETE_COMPONENT,
  SET_SELECTED_ELEMENT,
  // Manage elements
  DELETE_ELEMENT,
  DUPLICATE_ELEMENT,
  REORDER_COL,
  // Manage pages
  ADD_NEW_VERSION,
  SET_ACTIVE_PAGE,
  DELETE_VERSION,
  // Track changes
  SAVE_TRACK_CHANGES,
  UNDO_REDO,
  // Edit styles
  START_EDITING,
  OPEN_COLOR_PICKER,
  UPDATE_CSS_VAR,
  CONFIRM_COLOR_SCHEME,
  CHANGE_MAIL_COLORS,
  UPDATE_COMPONENT_CSS,
  UPDATE_COMPONENT_CLASSES,
  UPDATE_COMPONENT_ATTRIBUTES,
  UPDATE_COMPONENT_HTMLTAGNAME,
  UPDATE_EXISTING_TEXT_ELEMENT,
  ADD_NEW_TEXT_ELEMENT,
  UPDATE_LIST_MARKER2,
  SELECT_IMG_FROM_GALLERY,
  UPDATE_BTN_LINK_TEXT,
  UPDATE_CUSTOMS_NUM_RATING_STARS,
  // xxx
  // Other
  CHANGE_SCREEN_SIZE,
} from "./types";

import { setGlobalLoading, removeGlobalLoading } from "./globalLoading";

import { MAIL_COLOR_VAR_NAMES, API_URL } from "../lib/generalVars";
import { updateEditFormColorpickerValue } from "../lib/editFunctions";
import { templates } from "../lib/templates";
import { getRandomString } from "../lib/domFunctions";
import { POST_CONFIG, loadProjectInitEbContentArray } from "../lib/reducerFunctions";
import { getConcatElementId, getParentWithAttr, getFirstChildWithAttr } from "../lib/componentObjectFunctions";

import { translate } from "../translations/translations";

export const changeScreenSize = (screenSize) => (dispatch) => {
  dispatch({
    type: CHANGE_SCREEN_SIZE,
    payload: screenSize,
  });
};

// ====================
// == Manage project ==
// ====================

export const loadProject = (projectId) => async (dispatch) => {
  let loadingId = dispatch(setGlobalLoading(translate("aEb.loadingProject", false, null)));
  try {
    const res = await axios.get(`${API_URL}/emailbuilder/edit/project/${projectId}`);
    dispatch({
      type: LOAD_PROJECT,
      payload: loadProjectInitEbContentArray(res.data),
    });
    dispatch(removeGlobalLoading(loadingId));
  } catch (error) {
    console.error(error);
    dispatch({ type: AUTH_ERROR });
    dispatch(removeGlobalLoading(loadingId));
  }
};

export const saveProject = (projectId, gallery, mailMeta, ebContent, ebCustomCss, ebCssVars, exportedMail) => async (dispatch) => {
  let loadingId = dispatch(setGlobalLoading(translate("aEb.savingProject", false, null)));
  try {
    const body = JSON.stringify({ projectId, gallery, mailMeta, ebContent, ebCustomCss, ebCssVars, exportedMail });
    await axios.put(`${API_URL}/emailbuilder/edit/save`, body, POST_CONFIG);
  } catch (error) {
    console.error(error);
  }
  dispatch(removeGlobalLoading(loadingId));
};

export const loadTemplate = (templateId) => (dispatch) => {
  let selectedTemplate = templates.filter((template) => template.id === templateId)[0];
  if (typeof selectedTemplate !== "undefined") {
    // Set pageIds
    selectedTemplate.ebContent = selectedTemplate.ebContent.map((page) => ({ ...page, pageId: getRandomString(10) }));
    // Send to reducer
    dispatch({
      type: LOAD_TEMPLATE,
      payload: selectedTemplate,
    });
  }
};

export const updateProjectGallery = (gallery) => (dispatch) => {
  dispatch({
    type: UPDATE_PROJECT_GALLERY,
    payload: gallery,
  });
};

export const updateGalleryFileDesc = (fileId, newDesc) => (dispatch) => {
  dispatch({
    type: UPDATE_GALLERY_FILE_DESC,
    payload: { fileId, newDesc },
  });
};

export const setMailMetaValue = (metaVariable, metaValue) => async (dispatch) => {
  dispatch({
    type: UPDATE_MAILMETA,
    payload: { metaVariable, metaValue },
  });
};

// export const setMailMetaValue = (projectId, metaVariable, metaValue) => async (dispatch) => {
//   const body = JSON.stringify({ projectId, metaVariable, metaValue });
//   await axios.put(`${API_URL}/emailbuilder/edit/setMailMetaValue`, body, POST_CONFIG);
//   dispatch({
//     type: UPDATE_MAILMETA,
//     payload: { metaVariable, metaValue },
//   });
// };

// =============================
// == Open/close product tour ==
// =============================

export const openProductTour = () => (dispatch) => {
  dispatch({ type: OPEN_PRODUCT_TOUR });
};

export const closeProductTour = () => (dispatch) => {
  dispatch({ type: CLOSE_PRODUCT_TOUR });
};

// =================
// == Drag & drop ==
// =================

export const startDragFromMenu = (componentId) => (dispatch) => {
  dispatch({
    type: START_DRAG_FROM_MENU,
    payload: componentId,
  });
};

export const endDragFromMenu = () => (dispatch) => {
  dispatch({
    type: END_DRAG_FROM_MENU,
  });
};

export const dropComponent = (posToInsert) => (dispatch) => {
  dispatch({
    type: DROP_COMPONENT,
    payload: posToInsert,
  });
};

// ====================
// == Edit component ==
// ====================

export const reorderComponent = (componentId, direction) => (dispatch) => {
  dispatch({
    type: REORDER_COMPONENT,
    payload: { componentId, direction },
  });
};

export const deleteComponent = (componentId) => (dispatch) => {
  dispatch({
    type: DELETE_COMPONENT,
    payload: componentId,
  });
};

export const setSelectedElement = (elementId) => (dispatch) => {
  dispatch({
    type: SET_SELECTED_ELEMENT,
    payload: elementId,
  });
};

// =====================
// == Manage elements ==
// =====================

export const duplicateElement = (elementId) => (dispatch) => {
  dispatch({
    type: DUPLICATE_ELEMENT,
    payload: elementId,
  });
};

export const deleteElement = (elementId) => (dispatch) => {
  dispatch({
    type: DELETE_ELEMENT,
    payload: elementId,
  });
};

export const reorderColumn = (elementId) => (dispatch) => {
  dispatch({
    type: REORDER_COL,
    payload: elementId,
  });
};

// ==================
// == Manage pages ==
// ==================

export const addNewVersion = (pageToAdd, customCssToAdd) => (dispatch) => {
  dispatch({
    type: ADD_NEW_VERSION,
    payload: { pageToAdd, customCssToAdd },
  });
};

export const setActivePage = (pageId) => (dispatch) => {
  dispatch({
    type: SET_ACTIVE_PAGE,
    payload: pageId,
  });
};

export const deleteVersion = (pageId) => (dispatch) => {
  dispatch({
    type: DELETE_VERSION,
    payload: pageId,
  });
};

// ===================
// == Track changes ==
// ===================

export const saveTrackChanges = () => (dispatch) => {
  dispatch({
    type: SAVE_TRACK_CHANGES,
  });
};

export const clickUndoRedo = (undoOrRedo) => (dispatch) => {
  dispatch({
    type: UNDO_REDO,
    payload: undoOrRedo,
  });
};

// =================
// == Edit styles ==
// =================

export const startEditingStyles = (editFormsToShow, startStyles) => (dispatch) => {
  dispatch({
    type: START_EDITING,
    payload: { editFormsToShow, startStyles },
  });
};

export const openColorPicker = (title, color, varName) => (dispatch) => {
  // title = modal title
  // color = color to load in color picker
  // varName = name of variable to update after selecting new color
  dispatch({
    type: OPEN_COLOR_PICKER,
    payload: { title, color, varName },
  });
};

export const selectColor = (value, varName) => (dispatch) => {
  if (MAIL_COLOR_VAR_NAMES.includes(varName)) {
    dispatch({
      type: CHANGE_MAIL_COLORS,
      // For some reason, had the following as the regex earlier. This skips the final digit in the rgb() string. Don't know why the regex was like that: /\((\d+, \d+, \d+).+?\)/
      payload: [{ varName, newRgb: (value.match(/\((\d+, \d+, \d+)\)/) || ["", "0, 0, 0"])[1] }],
    });
    dispatch({
      type: UPDATE_CSS_VAR,
      payload: { value, varName },
    });
  } else {
    updateEditFormColorpickerValue(varName, value);
  }
};

export const confirmColorScheme = (arrRgbaStrings) => (dispatch) => {
  dispatch({
    type: CHANGE_MAIL_COLORS,
    payload: arrRgbaStrings.map((rgba, i) => ({ varName: `color${i + 1}`, newRgb: (rgba.match(/\((\d+, \d+, \d+).+?\)/) || ["", "0, 0, 0"])[1] })),
  });
  dispatch({
    type: CONFIRM_COLOR_SCHEME,
    payload: arrRgbaStrings,
  });
};

export const selectFont = (value, varName) => (dispatch) => {
  dispatch({
    type: UPDATE_CSS_VAR,
    payload: { value, varName },
  });
};

export const updateCss = (splitElementId, updatedCssRules) => (dispatch) => {
  // updatedCssRules = [ { property, pseudo, value }, ... ]
  dispatch({
    type: UPDATE_COMPONENT_CSS,
    payload: { splitElementId, updatedCssRules },
  });
};

export const updateClasses = (splitElementId, updatedClasses) => (dispatch) => {
  dispatch({
    type: UPDATE_COMPONENT_CLASSES,
    payload: { splitElementId, updatedClasses },
  });
};

export const updateAttributes = (splitElementId, updatedAttributes) => (dispatch) => {
  dispatch({
    type: UPDATE_COMPONENT_ATTRIBUTES,
    payload: { splitElementId, updatedAttributes },
  });
};

export const updateHtmlTagname = (splitElementId, updatedHtmlTagname) => (dispatch) => {
  dispatch({
    type: UPDATE_COMPONENT_HTMLTAGNAME,
    payload: { splitElementId, updatedHtmlTagname },
  });
};

export const updateExistingTextElement = (elementObj, cssRules) => (dispatch) => {
  dispatch({
    type: UPDATE_EXISTING_TEXT_ELEMENT,
    payload: { elementObj, cssRules },
  });
};

export const addNewTextElement = (elementObj, cssRules) => (dispatch) => {
  dispatch({
    type: ADD_NEW_TEXT_ELEMENT,
    payload: { elementObj, cssRules },
  });
};

export const selectImgFromGallery = (newSrc, targetStartStyleProp, fileDesc) => (dispatch) => {
  dispatch({
    type: SELECT_IMG_FROM_GALLERY,
    payload: { newSrc, targetStartStyleProp, fileDesc },
  });
};

export const updateCustoms = (splitElementId, updatedCustoms, i) => (dispatch) => {
  // updatedCustoms = [ { property, oldVal, newVal }, { ... } ]
  updatedCustoms.forEach((custom) => {
    if (custom.property === "componentBgColor") {
      // Get variables for both background and background-color css props (all need to be included in ALWAYS_INCLUDE_CUSTOMS)
      let componentBgColor = custom.newVal;
      let componentBoolBgIsGradient = updatedCustoms.filter((item) => item.property === "componentBoolBgIsGradient")[0].newVal;
      let componentBgGradientDirection = updatedCustoms.filter((item) => item.property === "componentBgGradientDirection")[0].newVal;
      let componentBgGradientColor1 = updatedCustoms.filter((item) => item.property === "componentBgGradientColor1")[0].newVal;
      let componentBgGradientColor2 = updatedCustoms.filter((item) => item.property === "componentBgGradientColor2")[0].newVal;
      let componentBgGradientStop1 = updatedCustoms.filter((item) => item.property === "componentBgGradientStop1")[0].newVal;
      let componentBgGradientStop2 = updatedCustoms.filter((item) => item.property === "componentBgGradientStop2")[0].newVal;
      if (componentBoolBgIsGradient) {
        dispatch(
          updateCss(splitElementId, [
            {
              property: "background",
              pseudo: "",
              value: `linear-gradient(${componentBgGradientDirection}deg, ${componentBgGradientColor1} ${componentBgGradientStop1}%, ${componentBgGradientColor2} ${componentBgGradientStop2}%)`,
            },
            { property: "background-color", pseudo: "", value: componentBgGradientColor1 },
          ])
        );
      } else {
        dispatch(
          updateCss(splitElementId, [
            { property: "background", pseudo: "", value: componentBgColor },
            { property: "background-color", pseudo: "", value: componentBgColor },
          ])
        );
      }
    }
    if (custom.property === "componentPaddingTop") {
      dispatch(updateCss(splitElementId, [{ property: "padding-top", pseudo: "", value: `${custom.newVal}rem` }]));
    }
    if (custom.property === "componentPaddingBottom") {
      dispatch(updateCss(splitElementId, [{ property: "padding-bottom", pseudo: "", value: `${custom.newVal}rem` }]));
    }
    if (custom.property === "componentRadiusSize") {
      let componentRadiusSize = custom.newVal;
      if (componentRadiusSize < 50) {
        componentRadiusSize = `${componentRadiusSize}rem`;
      } else if (componentRadiusSize < 100) {
        componentRadiusSize = `${componentRadiusSize}%`;
      } else {
        componentRadiusSize = `${componentRadiusSize}px`;
      }
      let componentRadiusSide = updatedCustoms.filter((item) => item.property === "componentRadiusSide")[0].newVal;
      let cssString = `${componentRadiusSize} ${componentRadiusSize} ${componentRadiusSize} ${componentRadiusSize}`;
      if (componentRadiusSide === "top") {
        cssString = `${componentRadiusSize} ${componentRadiusSize} 0 0`;
      }
      if (componentRadiusSide === "bottom") {
        cssString = `0 0 ${componentRadiusSize} ${componentRadiusSize}`;
      }
      dispatch(updateCss(splitElementId, [{ property: "border-radius", pseudo: "", value: cssString }]));
    }
    if (custom.property === "btnText" || custom.property === "linkContent") {
      if (custom.oldVal !== null && custom.newVal !== null) {
        dispatch({
          type: UPDATE_BTN_LINK_TEXT,
          payload: { splitElementId, custom },
        });
      }
    }
    if (
      custom.property === "markerForm" ||
      custom.property === "markerBgColor" ||
      custom.property === "markerBorderColor" ||
      custom.property === "markerMarginRight"
    ) {
      let markerForm = updatedCustoms.filter((item) => item.property === "markerForm")[0].newVal;
      let markerBgColor = updatedCustoms.filter((item) => item.property === "markerBgColor")[0].newVal;
      let markerBorderColor = updatedCustoms.filter((item) => item.property === "markerBorderColor")[0].newVal;
      let markerMarginRight = updatedCustoms.filter((item) => item.property === "markerMarginRight")[0].newVal;
      // Prepare css rules in standard css rule format
      let cssRules = [];
      // let rgb = (markerBorderColor.match(/rgba?\((.+),.+?\)/) || ["", "0, 0, 0"])[1];
      // Form           height === width  borderRadius
      // none           true                 0
      // square         true                 0
      // circle         true                 50
      // line           false                0
      if (markerForm === "none") {
        cssRules = [
          { property: "height", value: "0px" },
          { property: "width", value: "0px" },
          { property: "border-radius", value: "0rem" },
          { property: "background", value: `rgb(255, 255, 255)` },
          { property: "border-color", value: `rgb(255, 255, 255)` },
          { property: "margin-right", value: `0rem` },
        ];
      }
      if (markerForm === "square") {
        cssRules = [
          { property: "height", value: "8px" },
          { property: "width", value: "8px" },
          { property: "border-radius", value: "0rem" },
          { property: "background", value: markerBgColor },
          { property: "border-color", value: markerBorderColor },
          { property: "margin-right", value: `${markerMarginRight}rem` },
        ];
      }
      if (markerForm === "circle") {
        cssRules = [
          { property: "height", value: "8px" },
          { property: "width", value: "8px" },
          { property: "border-radius", value: "50%" },
          { property: "background", value: markerBgColor },
          { property: "border-color", value: markerBorderColor },
          { property: "margin-right", value: `${markerMarginRight}rem` },
        ];
      }
      if (markerForm === "line") {
        cssRules = [
          { property: "height", value: "2px" },
          { property: "width", value: "8px" },
          { property: "border-radius", value: "0rem" },
          { property: "background", value: markerBgColor },
          { property: "border-color", value: markerBorderColor },
          { property: "margin-right", value: `${markerMarginRight}rem` },
        ];
      }
      let listWrapper = getParentWithAttr("data-listwrapper", "true");
      let markerSpan = getFirstChildWithAttr(listWrapper, "data-listbullet", "true");
      dispatch({
        type: UPDATE_LIST_MARKER2,
        payload: { splitElementId: getConcatElementId(markerSpan.childId).split("-"), cssRules },
      });
    }
    if (custom.property === "linkElementDest") {
      // updatedAttributes = [ { attr: "id", oldVal: "xxx", newVal: "yyy" }, ... ]
      let linkWrapper = getParentWithAttr("data-linkwrapper", "true");
      dispatch(updateAttributes(getConcatElementId(linkWrapper.childId).split("-"), [{ attr: "data-href", newVal: custom.newVal }]));
    }
    if (custom.property === "numStars") {
      dispatch({
        type: UPDATE_CUSTOMS_NUM_RATING_STARS,
        payload: { splitElementId, custom: { ...custom, oldVal: `${custom.oldVal}rem`, newVal: `${custom.newVal}rem` } },
      });
    }
    // Additional customs ...
  });
};
