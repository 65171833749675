export const mEmailFonts = {
  all: {
    en: "All",
    nl: "Alle",
  },
  serif: {
    en: "Serif",
    nl: "Serif",
  },
  sansSerif: {
    en: "Sans Serif",
    nl: "Sans Serif",
  },
  display: {
    en: "Display",
    nl: "Display",
  },
  handwriting: {
    en: "Handwriting",
    nl: "Handgeschreven",
  },
  monospace: {
    en: "Monospace",
    nl: "Monospace",
  },
  initialPreviewText: {
    en: "The quick brown fox jumps over the lazy dog.",
    nl: "The quick brown fox jumps over the lazy dog.",
  },
  connectionError: {
    en: "Couldn't connect with server. Please click on the refresh button below.",
    nl: "Server kan niet bereikt worden. Klik aub op de vernieuw knop hieronder.",
  },
  selectNewFontHeaders: {
    en: "Select a new font for headers on your mail",
    nl: "Selecteer een nieuw lettertype voor de koppen in uw email",
  },
  selectNewFontBody: {
    en: "Select a new font for body on your mail",
    nl: "Selecteer een nieuw lettertype voor normale tekst in uw email",
  },
  searchFontName: {
    en: "Search font name",
    nl: "Zoek naar lettertype naam",
  },
  enterFontName: {
    en: "Enter font name",
    nl: "Lettertype naam",
  },
  selectCategory: {
    en: "Select category",
    nl: "Selecteer categorie",
  },
  xFontsMatchSelection: {
    en: "fonts match your criteria",
    nl: "lettertypes voldoen aan uw criteria",
  },
  previewText: {
    en: "Preview text",
    nl: "Voorbeeldtekst",
  },
  error: {
    en: "Error",
    nl: "Fout",
  },
  noFontsMatchSelection: {
    en: "No fonts match your selection",
    nl: "Geen lettertypes voldoen aan uw selectie",
  },
  selectedFont: {
    en: "Selected font",
    nl: "Geselecteerde lettertype",
  },
  weight: {
    en: "weight",
    nl: "dikte",
  },
  refreshFonts: {
    en: "Refresh fonts",
    nl: "Vernieuw lettertypes",
  },
  close: {
    en: "Close",
    nl: "Sluiten",
  },
  confirmFont: {
    en: "Confirm font",
    nl: "Bevestig lettertype",
  },
};
