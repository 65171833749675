import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Select from "./formComponents/Select";
import ColorPicker from "./formComponents/ColorPicker";
import LinkDestTarget from "./formComponents/LinkDestTarget";
import TextInput from "./formComponents/TextInput";
import FontSize from "./FontSize";

import { formGetStartValue } from "../../../../lib/editFunctions";
import { translate } from "../../../../translations/translations";

const LinkEditor = ({ eb: { startStyles } }) => {
  return (
    <>
      {formGetStartValue(startStyles, "linkContent") !== null && (
        <TextInput label={"Link text"} id={"linkContent"} startValue={formGetStartValue(startStyles, "linkContent")} />
      )}
      <LinkDestTarget />
      <ColorPicker
        label={translate("cEditForms.linkColorNormal", false, null)}
        id={"textColor"}
        startValue={formGetStartValue(startStyles, "textColor")}
        tooltipText={translate("cEditForms.ttLinkColorNormal", false, null)}
      />
      <Select
        label={translate("cEditForms.linkStyleNormal", false, null)}
        id={"linkDeco"}
        options={[
          { val: "none", label: translate("cEditForms.none", false, null) },
          { val: "underline", label: translate("cEditForms.underlined", false, null) },
        ]}
        startValue={formGetStartValue(startStyles, "linkDeco")}
        tooltipText={translate("cEditForms.ttLinkStyleNormal", false, null)}
      />
      <FontSize />
    </>
  );
};

LinkEditor.propTypes = {
  eb: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  eb: state.eb,
});

export default connect(mapStateToProps, null)(LinkEditor);
