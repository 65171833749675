import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Tooltip from "./Tooltip";

import { EDIT_FORM_ID_PREFIX, formGetStartValue } from "../../../../../lib/editFunctions";

import { openColorPicker } from "../../../../../actions/eb";

import { translate } from "../../../../../translations/translations";

const GradientColor = ({ eb: { startStyles }, label, tooltipText, arrBgVarNames, openColorPicker }) => {
  // Ensure you provide these variables in the right order
  const [bgColor, boolBgIsGradient, bgGradientDirection, bgGradientColor1, bgGradientColor2, bgGradientStop1, bgGradientStop2] = arrBgVarNames;

  const [boolIsGradient, setBoolIsGradient] = useState(false);
  const [stop1, setStop1] = useState(0);
  const [stop2, setStop2] = useState(0);
  const [direction, setDirection] = useState(0);
  useEffect(() => {
    setBoolIsGradient(formGetStartValue(startStyles, boolBgIsGradient));
    setStop1(formGetStartValue(startStyles, bgGradientStop1));
    setStop2(formGetStartValue(startStyles, bgGradientStop2));
    setDirection(formGetStartValue(startStyles, bgGradientDirection));
    // eslint-disable-next-line
  }, [startStyles]);

  return (
    <div className="w-100 d-flex flex-column align-items-start justify-content-center my-3">
      <p className="mb-2">
        {label} <Tooltip text={tooltipText} />{" "}
      </p>
      <div className="d-flex flex-column w-100" id={EDIT_FORM_ID_PREFIX + boolBgIsGradient} data-isgradient={boolIsGradient}>
        {boolIsGradient ? (
          <>
            {/* Row 1 - Color picker 1 */}
            <div className="d-flex align-items-center w-100">
              <div className="w-50">
                {translate("cEditForms.gradientColor", false, null)} 1
                <Tooltip text={translate("cEditForms.tooltipGradientStart", false, null)} />
              </div>
              <div className="w-50 d-flex align-items-center justify-content-between">
                <div
                  className="d-flex w-60 cursorPointer border border-light"
                  data-editform="colorpicker"
                  id={EDIT_FORM_ID_PREFIX + bgGradientColor1}
                  style={{ height: "24px", backgroundColor: formGetStartValue(startStyles, bgGradientColor1) }}
                  onClick={() =>
                    openColorPicker(
                      translate("cEditForms.selectColor", false, null),
                      formGetStartValue(startStyles, bgGradientColor1),
                      EDIT_FORM_ID_PREFIX + bgGradientColor1
                    )
                  }
                  data-bs-toggle="modal"
                  data-bs-target="#ModalColorPicker"
                ></div>
                <div className="w-30">
                  <input
                    type="number"
                    className="form-control form-control-sm"
                    min={0}
                    max={100}
                    step={1}
                    id={EDIT_FORM_ID_PREFIX + bgGradientStop1}
                    value={stop1}
                    onChange={(e) => setStop1(e.target.value)}
                  />
                </div>
              </div>
            </div>
            {/* Row 2 - Color picker 2 */}
            <div className="d-flex align-items-center w-100 mt-2">
              <div className="w-50">
                {translate("cEditForms.gradientColor", false, null)} 2
                <Tooltip text={translate("cEditForms.tooltipGradientEnd", false, null)} />
              </div>
              <div className="w-50 d-flex align-items-center justify-content-between">
                <div
                  className="d-flex w-60 cursorPointer border border-light"
                  data-editform="colorpicker"
                  id={EDIT_FORM_ID_PREFIX + bgGradientColor2}
                  style={{ height: "24px", backgroundColor: formGetStartValue(startStyles, bgGradientColor2) }}
                  onClick={() =>
                    openColorPicker(
                      translate("cEditForms.selectColor", false, null),
                      formGetStartValue(startStyles, bgGradientColor2),
                      EDIT_FORM_ID_PREFIX + bgGradientColor2
                    )
                  }
                  data-bs-toggle="modal"
                  data-bs-target="#ModalColorPicker"
                ></div>
                <div className="w-30">
                  <input
                    type="number"
                    className="form-control form-control-sm"
                    min={0}
                    max={100}
                    step={1}
                    id={EDIT_FORM_ID_PREFIX + bgGradientStop2}
                    value={stop2}
                    onChange={(e) => setStop2(e.target.value)}
                  />
                </div>
              </div>
            </div>
            {/* Row 3 - Direction */}
            <div className="d-flex align-items-center w-100 mt-2">
              <div className="w-50">
                {translate("cEditForms.gradientDirection", false, null)}
                <Tooltip text={translate("cEditForms.tooltipGradientDirection", false, null)} />
              </div>
              <div className="w-50 d-flex justify-content-center">
                <input
                  type="range"
                  className="form-range"
                  min={0}
                  max={359}
                  step={1}
                  id={EDIT_FORM_ID_PREFIX + bgGradientDirection}
                  value={direction}
                  onChange={(e) => setDirection(e.target.value)}
                />
                <span className="slider-value">{direction}</span>
              </div>
            </div>
            {/* Row 4 - Note gradient doesn't work on outlook */}
            <p className="my-1 fontSize08 text-italic">{translate("cEditForms.noteGradientOutlook", false, null)}</p>
            {/* Row 5 - Button to make a single BG color */}
            <div className="d-flex align-items-center justify-content-end w-100">
              <div className="w-50">
                <button
                  className="btn btn-outline-primary btn-sm mt-2 w-100"
                  title={translate("cEditForms.explainGradient", false, null)}
                  onClick={() => setBoolIsGradient(false)}
                >
                  {translate("cEditForms.useSingleBg", false, null)}
                </button>
              </div>
            </div>
          </>
        ) : (
          <>
            {/* Row 1 - Color picker */}
            <div className="d-flex align-items-center w-100">
              <div className="w-50">
                {translate("cEditForms.bgColor", false, null)}
                <Tooltip text={translate("cEditForms.selectSingleBg", false, null)} />
              </div>
              <div className="w-50 d-flex flex-column justify-content-center">
                <div
                  className="d-flex w-100 cursorPointer border border-light"
                  data-editform="colorpicker"
                  id={EDIT_FORM_ID_PREFIX + bgColor}
                  style={{ height: "24px", backgroundColor: formGetStartValue(startStyles, bgColor) }}
                  onClick={() =>
                    openColorPicker(
                      translate("cEditForms.selectColor", false, null),
                      formGetStartValue(startStyles, bgColor),
                      EDIT_FORM_ID_PREFIX + bgColor
                    )
                  }
                  data-bs-toggle="modal"
                  data-bs-target="#ModalColorPicker"
                ></div>
              </div>
            </div>
            {/* Row 2 - Button */}
            <div className="d-flex align-items-center justify-content-end w-100">
              <div className="w-50">
                <button
                  className="btn btn-outline-primary btn-sm mt-2 w-100"
                  title={translate("cEditForms.explainGradient", false, null)}
                  onClick={() => setBoolIsGradient(true)}
                >
                  {translate("cEditForms.makeBgGradient", false, null)}
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

GradientColor.propTypes = {
  eb: PropTypes.object.isRequired,
  openColorPicker: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  eb: state.eb,
});

export default connect(mapStateToProps, { openColorPicker })(GradientColor);
