export const cEditForms = {
  // ColorPicker
  selectColor: {
    en: "Select color",
    nl: "Selecteer kleur",
  },
  // GradientColor
  gradientColor: {
    en: "Gradient color",
    nl: "Kleurverloop",
  },
  tooltipGradientStart: {
    en: "Select the first gradient color you want to use as well as position the gradient will start (from 0 to 100). A higher starting position means that the gradient into the second color will start later. For example, putting it at 50, means that half of your background will be of the first color you select, after which it will start to transform into the second color.",
    nl: "Selecteer de startkleur en de positie wanneer het verloop begint (van 0 tot 100). Een hogere startpositie betekent dat het verloop naar de tweede kleur later zal beginnen. Bijvoorbeeld, een waarde van 50 betekent dat de helft van de achtergrond de eerste kleur heeft, waarna het verloopt richting de tweede kleur.",
  },
  tooltipGradientEnd: {
    en: "Select the second gradient color you want to use as well as position the gradient will end (from 0 to 100). A lower ending position means that the gradient from the first color will end earlier. For example, putting it at 50, means that half of your background will be of the second color you select.",
    nl: "Selecteer de eindkleur en de positie wanneer het verloop stopt (van 0 tot 100). Een lagere eindpositie betekent dat het verloop van de eerste kleur sneller stopt. Bijvoorbeeld, een waarde van 50 betekent dat de helft van de achtergrond de tweede kleur heeft.",
  },
  gradientDirection: {
    en: "Gradient direction",
    nl: "Verlooprichting",
  },
  tooltipGradientDirection: {
    en: "Direction the gradient moves into, in degrees (0 to 359)",
    nl: "Richting waarin de kleuren verlopen, in grades (0 tot 359)",
  },
  explainGradient: {
    en: "A gradient background has two colors that transform into each other",
    nl: "Een achtergrond met kleurverloop heeft twee kleuren die in elkaar transformeren",
  },
  noteGradientOutlook: {
    en: "Note: Gradient colors are not supported in Outlook email clients. A single background color of gradient color 1 will be shown instead.",
    nl: "Opmerking: Kleurverloop wordt niet ondersteund door Outlook emailprogrammas. Een uniforme kleur van kleur 1 zal worden gebruikt in dit geval.",
  },
  useSingleBg: {
    en: "Use a single background color",
    nl: "Gebruik een uniforme achtergrondskleur",
  },
  bgColor: {
    en: "Background color",
    nl: "Achtergrondskleur",
  },
  selectSingleBg: {
    en: "Select the single, uniform color you want to use as a background",
    nl: "Selecteer de uniforme kleur die u als achtergrond wilt gebruiken",
  },
  makeBgGradient: {
    en: "Make background gradient",
    nl: "Gebruik een kleurverloop als achtergrond",
  },
  // LinkDestTarget
  linkDest: {
    en: "Link destination",
    nl: "Link bestemming",
  },
  tooltipLinkDest: {
    en: "The webpage the link points to.",
    nl: "De website bestemming van de link.",
  },
  notValidUrl: {
    en: "Not a valid URL",
    nl: "Geen geldige URL",
  },
  // AlignHori
  alignItemHori: {
    en: "Horizontally align item",
    nl: "Lijn item horizontaal uit",
  },
  left: {
    en: "Left",
    nl: "Links",
  },
  center: {
    en: "Center",
    nl: "Gecentreerd",
  },
  right: {
    en: "Right",
    nl: "Rechts",
  },
  // BgColor
  setElementBgColor: {
    en: "Set background color of the element",
    nl: "Zet achtergrondskleur van het element",
  },
  // Border
  borderSides: {
    en: "Border sides",
    nl: "Rand kanten",
  },
  noBorder: {
    en: "No border",
    nl: "Geen rand",
  },
  allSides: {
    en: "All sides",
    nl: "Alle kanten",
  },
  topAndBottom: {
    en: "Top and bottom",
    nl: "Boven en onder",
  },
  leftAndRight: {
    en: "Left and right",
    nl: "Links en rechts",
  },
  allExceptLeft: {
    en: "All except left",
    nl: "Alle behalve links",
  },
  allExceptRight: {
    en: "All except right",
    nl: "Alle behalve rechts",
  },
  allExceptTop: {
    en: "All except top",
    nl: "Alle behalve boven",
  },
  allExceptBottom: {
    en: "All except bottom",
    nl: "Alle behalve onder",
  },
  tooltipBorderSides: {
    en: "Set the border sides of the element, if any",
    nl: "Zet de kanten van de rand van het element",
  },
  borderType: {
    en: "Border type",
    nl: "Type rand",
  },
  hidden: {
    en: "Hidden",
    nl: "Verborgen",
  },
  dotted: {
    en: "Dotted",
    nl: "Punten",
  },
  dashed: {
    en: "Dashed",
    nl: "Strepen",
  },
  solid: {
    en: "Solid",
    nl: "Doorgetrokken",
  },
  double: {
    en: "Double",
    nl: "Dubbel",
  },
  groove: {
    en: "Groove",
    nl: "Groef",
  },
  ridge: {
    en: "Ridge",
    nl: "Richel",
  },
  inset: {
    en: "Inset",
    nl: "Binnenkant",
  },
  outset: {
    en: "Outset",
    nl: "Buitenkant",
  },
  tooltipBorderType: {
    en: "Set the type of the border",
    nl: "Zet het type rand",
  },
  borderSize: {
    en: "Border size",
    nl: "Rand grootte",
  },
  tooltipBorderSize: {
    en: "Set the width of the border",
    nl: "Zet de breedte van de rand",
  },
  borderColor: {
    en: "Border color",
    nl: "Rand kleur",
  },
  tooltipBorderColor: {
    en: "Set the color of the border",
    nl: "Zet de kleur van de rand",
  },
  // BorderRadius
  borderRadius: {
    en: "Border radius",
    nl: "Vorm",
  },
  square: {
    en: "Square",
    nl: "Vierkant",
  },
  roundedSmall: {
    en: "Rounded - small",
    nl: "Kleine ronding",
  },
  roundedMedium: {
    en: "Rounded - medium",
    nl: "Middelgrote ronding",
  },
  roundedLarge: {
    en: "Rounded - large",
    nl: "Grote ronding",
  },
  roundedExtraLarge: {
    en: "Rounded - extra large",
    nl: "Extra grote ronding",
  },
  pill: {
    en: "Pill",
    nl: "Ovaal",
  },
  circle: {
    en: "Circle",
    nl: "Cirkel",
  },
  tooltipBorderRadius: {
    en: "Set the roundness of the element's corner",
    nl: "Zet de mate van ronding van de element's hoek",
  },
  // BtnEdit
  btnText: {
    en: "Button text",
    nl: "Knop tekst",
  },
  btnTextStyle: {
    en: "Text style",
    nl: "Tekststijl",
  },
  btnTextColor: {
    en: "Text color",
    nl: "Tekstkleur",
  },
  tooltipBtnTextColor: {
    en: "Color of the button text",
    nl: "Kleur van de knop tekst",
  },
  tooltipBtnBgColor: {
    en: "Set background color of the button",
    nl: "Zet de achtergrondkleur van de knop",
  },
  btnFullWidth: {
    en: "Full width",
    nl: "Volledige breedte",
  },
  tooltipBtnFullWidth: {
    en: "Set button to be full width or not. If yes, you may have to set margin left and right to 0.",
    nl: "Zet de knop op volledige breedte of niet. Als gezet op volledige breedte, moet u mogelijk de marge aan de linkter- en rechterkant op 0 zetten.",
  },
  // ColLayout
  layout: {
    en: "Layout",
    nl: "Layout",
  },
  ttColLayout: {
    en: "Set layout of the columns. This is only relevant for larger screens, the columns will get stacked on top of each other on mobile screens.",
    nl: "Zet de layout van de kolommen. Dit is alleen relevant voor grotere schermen, aangezien de kolommen altijd op elkaar gestapeld worden op mobiele telefoonschermen.",
  },
  // ColVertAlign
  verticalAlignment: {
    en: "Vertical alignment",
    nl: "Verticale uitlijning",
  },
  top: {
    en: "Top",
    nl: "Boven",
  },
  middle: {
    en: "Middle",
    nl: "Midden",
  },
  bottom: {
    en: "Bottom",
    nl: "Onder",
  },
  ttVerticalAlignment: {
    en: "Set the vertical alignment of the content in the columns",
    nl: "Zet de verticale uitlijning van de inhoud van de kolom",
  },
  // Component
  bgColorOutside: {
    en: "Background color outside",
    nl: "Achtergrondskleur buitenkant",
  },
  ttBgColorOutside: {
    en: "Background color of the space left/right to the component",
    nl: "Zet de achtergrondskleur van de ruimte aan de linker- en rechterkant van het component",
  },
  bgColorInside: {
    en: "Background color inside",
    nl: "Achtergrondskleur buitenkant",
  },
  ttBgColorInside: {
    en: "Background color of the component itself",
    nl: "Achtergrondskleur van het component zelf",
  },
  spaceTop: {
    en: "Space top",
    nl: "Ruimte bovenkant",
  },
  ttComponentSpaceTop: {
    en: "Space on top of the component",
    nl: "Zet de ruimte aan de bovenkant van het component",
  },
  spaceBottom: {
    en: "Space bottom",
    nl: "Ruimte onderkant",
  },
  ttComponentSpaceBottom: {
    en: "Space on the bottom of the component",
    nl: "Zet de ruimte aan de onderkant van het component",
  },
  ttComponentBorderRadius: {
    en: "Set the roundness of the component's corner",
    nl: "Zet de mate van ronding van het component's hoek",
  },
  borderRadiusSides: {
    en: "Border radius sides",
    nl: "Ronding kanten",
  },
  ttBorderRadiusSides: {
    en: "Set the sides of the radius for the component",
    nl: "Zet de kanten waar het component gerond moet worden",
  },
  internalPaddingLeft: {
    en: "Internal padding left",
    nl: "Opvulling links",
  },
  ttInternalPaddingLeft: {
    en: "Internal space within the left side of the elements",
    nl: "Lege ruimte aan de linkerkant van de elementen",
  },
  internalPaddingRight: {
    en: "Internal padding right",
    nl: "Opvulling rechts",
  },
  ttInternalPaddingRight: {
    en: "Internal space within the right side of the elements",
    nl: "Lege ruimte aan de rechterkant van de elementen",
  },
  internalPaddingTop: {
    en: "Internal padding top",
    nl: "Opvulling boven",
  },
  ttInternalPaddingTop: {
    en: "Internal space within the upper side of the elements",
    nl: "Lege ruimte aan de bovenkant van de elementen",
  },
  internalPaddingBottom: {
    en: "Internal padding bottom",
    nl: "Opvulling onder",
  },
  ttInternalPaddingBottom: {
    en: "Internal space within the bottom side of the elements",
    nl: "Lege ruimte aan de onderkant van de elementen",
  },
  // ComponentDivider
  fullWidth: {
    en: "Full width",
    nl: "Volledige breedte",
  },
  setWidthManually: {
    en: "Set width manually",
    nl: "Zet breedte handmatig",
  },
  dividerHeight: {
    en: "Divider height",
    nl: "Afscheiding hoogte",
  },
  ttDividerHeight: {
    en: "Set the height of the divider",
    nl: "Zet de hoogte van de afscheiding",
  },
  dividerWidth: {
    en: "Divider width",
    nl: "Afscheiding breedte",
  },
  ttDividerWidth: {
    en: "Set width of the divider",
    nl: "Zet de breedte van de afscheiding",
  },
  bgColorComponentDivider: {
    en: "Background color of the divider / line",
    nl: "Achtergrondskleur van de afscheider of lijn",
  },
  // ElementSettings
  elementId: {
    en: "Element ID",
    nl: "Element ID",
  },
  ttElementSettings: {
    en: `Set the ID of the element. This is only relevant for links and buttons, so you can track which are being clicked the most by your readers. First character needs to be a letter, no spaces or special characters are allowed.`,
    nl: "Zet het unieke ID van de element. Dit is alleen relevant voor links en knoppen, zodat u kan bijhouden waar gebruikers op klikken. Het eerste karakter moet een letter zijn. Spaties en andere speciale karakters zijn niet toegestaan.",
  },
  // FontSize
  fontSizePx: {
    en: "Font size (pixels)",
    nl: "Tekstgrotte (pixels)",
  },
  ttFontSize: {
    en: "Size of the text in the element",
    nl: "Grootte van de tekst in het element",
  },
  // ImgAdjust
  opacity: {
    en: "Opacity",
    nl: "Doorzichtigheid",
  },
  ttOpacity: {
    en: "Opacity of the image",
    nl: "Doorzichtigheid van de afbeelding",
  },
  blur: {
    en: "Blur",
    nl: "Vervaging",
  },
  ttBlur: {
    en: "Blur the image (0 = normal)",
    nl: "Vervaging van de afbeelding (0 = normaal)",
  },
  brightNess: {
    en: "Brightness",
    nl: "Helderheid",
  },
  ttBrightness: {
    en: "Brightness of the image (100 = normal)",
    nl: "Helderheid van de afbeelding (100 = normaal)",
  },
  contrast: {
    en: "Contrast",
    nl: "Contrast",
  },
  ttContrast: {
    en: "Contrast of the image (100 = normal)",
    nl: "Contrast van de afbeelding (100 = normaal)",
  },
  grayscale: {
    en: "Grayscale",
    nl: "Zwart/wit verhouding",
  },
  ttGrayscale: {
    en: "Grayscale of the image (0 = colored)",
    nl: "Zwart/wit verhouding van de afbeelding (0 = volledig in kleur)",
  },
  hue: {
    en: "Hue rotate",
    nl: "Tint",
  },
  ttHue: {
    en: "Set degrees to shift hues of the image",
    nl: "Zet de mate van tint van de afbeelding",
  },
  invert: {
    en: "Invert",
    nl: "Negatief",
  },
  ttInvert: {
    en: "Invert the image (0 = normal)",
    nl: "Maak een negatief van de afbeelding (0 = normaal)",
  },
  saturate: {
    en: "Saturate",
    nl: "Doordrenken",
  },
  ttSaturate: {
    en: "Saturate the image (100 = normal)",
    nl: "Doordrenk de afbeelding (100 = normaal)",
  },
  sepia: {
    en: "Sepia",
    nl: "Warmte",
  },
  ttSepia: {
    en: "Set sepia of the image (0 = normal)",
    nl: "Zet de warmte van de afbeelding (0 = normaal)",
  },
  // ImgSize
  autoBasedOnHeight: {
    en: "Automatic based on height",
    nl: "Automatisch gebaseerd op hoogte",
  },
  fullHeight: {
    en: "Full height",
    nl: "Volledige hoogte",
  },
  autoBasedOnWdith: {
    en: "Automatic based on width",
    nl: "Automatisch gebaseerd op breedte",
  },
  setHeightManually: {
    en: "Set height manually",
    nl: "Zet hoogte handmatig",
  },
  imgWidth: {
    en: "Image width",
    nl: "Afbeelding breedte",
  },
  ttImgWidth: {
    en: "Set width of the image",
    nl: "Zet de breedte van de afbeelding",
  },
  imgHeight: {
    en: "Image height",
    nl: "Afbeelding hoogte",
  },
  ttImgHeight: {
    en: "Set height of the image",
    nl: "Zet de hoogte van de afbeelding",
  },
  // ImgSource
  selectFromGallery: {
    en: "Select from gallery",
    nl: "Selecteer uit gallerij",
  },
  imgSource: {
    en: "Image source",
    nl: "Afbeelding bron",
  },
  ttImgSource: {
    en: "Set link to the image you would like to use. Can either be selected from your gallery or be an external resource",
    nl: "Zet link naar de afbeelding die u wilt gebruiken. Kan geselecteerd worden uit uw gallerij of een externe link zijn",
  },
  imgDesc: {
    en: "Image description",
    nl: "Afbeelding omschrijving",
  },
  ttImgDesc: {
    en: "Provide a meaningful description of the image (optional).",
    nl: "Geef een betekenisvolle beschrijving van de afbeelding (optioneel).",
  },
  // LinkEditor
  linkColorNormal: {
    en: "Link color",
    nl: "Link kleur",
  },
  ttLinkColorNormal: {
    en: "Text color of the link",
    nl: "Tekstkleur van de link",
  },
  linkStyleNormal: {
    en: "Link style",
    nl: "Link stijl",
  },
  none: {
    en: "None",
    nl: "Geen",
  },
  underlined: {
    en: "Underlined",
    nl: "Onderstreept",
  },
  ttLinkStyleNormal: {
    en: "Style of the link",
    nl: "Stijl van de link",
  },
  // LinkElement
  ttLinkElement: {
    en: `The webpage the link points to. Use %UNSUBSCRIBE_LINK% to dynamically point to the link the user can click to unsubscribe from your emails.`,
    nl: `De pagine waar de link naar toe verwijst. Gebruik %UNSUBSCRIBE_LINK% om te verwijzen naar de link waar de gebruiker zich kan uitschrijven van uw emailadressenbestand.`,
  },
  // Listmaker2
  markerStyle: {
    en: "Marker style",
    nl: "Punt stijl",
  },
  noMarker: {
    en: "No marker",
    nl: "Geen teken",
  },
  line: {
    en: "Line",
    nl: "Lijn",
  },
  ttMarkerStyle: {
    en: "Style of the marker of each list item",
    nl: "Stijl van de punt van elk item in de lijst",
  },
  markerBorderColor: {
    en: "Marker border color",
    nl: "Punt rand kleur",
  },
  ttMarkerBorderColor: {
    en: "Border color of the marker of each list item",
    nl: "Kleur van de rand van het punt van elk item in de lijst",
  },
  markerFillColor: {
    en: "Marker fill color",
    nl: "Punt opvullingskleur",
  },
  ttMarkerFillColor: {
    en: "Fill color of the marker of each list item",
    nl: "Opvullingskleur van het punt van elk item in de lijst",
  },
  markerSpaceRight: {
    en: "Space right",
    nl: "Ruimte rechts",
  },
  ttMarkerSpaceRight: {
    en: "Space between the marker and the text",
    nl: "Ruimte tussen het punt en de tekst",
  },
  // Margin
  marginLeft: {
    en: "Margin left",
    nl: "Marge links",
  },
  marginRight: {
    en: "Margin right",
    nl: "Marge rechts",
  },
  marginTop: {
    en: "Margin top",
    nl: "Marge boven",
  },
  marginBottom: {
    en: "Margin bottom",
    nl: "Marge onder",
  },
  ttMarginLeft: {
    en: "External space left of the component",
    nl: "Ruimte aan de linkerbuitenkant van het component",
  },
  ttMarginRight: {
    en: "External space right of the component",
    nl: "Ruimte aan de rechterbuitenkant van het component",
  },
  ttMarginTop: {
    en: "External space on top of the component",
    nl: "Ruimte aan de bovenbuitenkant van het component",
  },
  ttMarginBottom: {
    en: "External space on bottom of the component",
    nl: "Ruimte aan de onderbuitenkant van het component",
  },
  // Padding
  paddingLeft: {
    en: "Padding left",
    nl: "Opvulling links",
  },
  ttPaddingLeft: {
    en: "Internal space within the left side of the component",
    nl: "Lege ruimte aan de linkerkant van het component",
  },
  paddingRight: {
    en: "Padding right",
    nl: "Opvulling rechts",
  },
  ttPaddingRight: {
    en: "Internal space within the right side of the component",
    nl: "Lege ruimte aan de rechterkant van het component",
  },
  paddingTop: {
    en: "Padding top",
    nl: "Opvulling boven",
  },
  ttPaddingTop: {
    en: "Internal space within the top side of the component",
    nl: "Lege ruimte aan de bovenkant van het component",
  },
  paddingBottom: {
    en: "Padding bottom",
    nl: "Opvulling onder",
  },
  ttPaddingBottom: {
    en: "Internal space within the bottom side of the component",
    nl: "Lege ruimte aan de onderkant van het component",
  },
  // NumRatingStars
  rating: {
    en: "Rating",
    nl: "Beoordeling",
  },
  stars: {
    en: "stars",
    nl: "sterren",
  },
  star: {
    en: "star",
    nl: "ster",
  },
  selectRatingToShow: {
    en: "Select the rating to show",
    nl: "Selecteer beoordeling",
  },
  // SocialIconSource
  socialMediaSite: {
    en: "Social media site",
    nl: "Social media site",
  },
  ttSocialMediaSite: {
    en: "Select the name of the social media site",
    nl: "Selecteer de social media site",
  },
  iconColor: {
    en: "Icon color",
    nl: "Icoon kleur",
  },
  ttIconColor: {
    en: "Set the color of the icon to white, black or the social media's brand color",
    nl: "Zet de kleur van het icoon (wit, zwart of de kleur van het social media merk)",
  },
  ttIconBgColor: {
    en: "Set the background color of the icon to white, black or the social media's brand color",
    nl: "Zet de achtergrondskleur van het icoon (wit, zwart of de kleur van het social media merk)",
  },
  // TextEditor
  editText: {
    en: "Edit text",
    nl: "Bewerk tekst",
  },
};
