export const mModalSelectImgFromGallery = {
  selectImgFromGallery: {
    en: "Select image from gallery",
    nl: "Selecteer afbeelding uit gallerij",
  },
  close: {
    en: "Close",
    nl: "Sluiten",
  },
  selectImg: {
    en: "Select image",
    nl: "Selecteer afbeelding",
  },
};
