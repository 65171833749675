export const mModalImageGallery = {
  serverErrorUploading: {
    en: "Server error while uploading your images. Please try again.",
    nl: "Server fout tijdens het uploaden van uw afbeeldingen. Probeer het aub opnieuw.",
  },
  imgGallery: {
    en: "Image gallery",
    nl: "Afbeeldingengallerij",
  },
  close: {
    en: "Close",
    nl: "Sluiten",
  },
};
