import React from "react";

import Tooltip from "./Tooltip";

const Custom = ({ label, column, customComponent: CustomComponent, tooltipText }) => {
  return (
    <div className="w-100 d-flex align-items-center my-3">
      <div className="w-50">
        {label}
        <Tooltip text={tooltipText} />
      </div>
      <div className={`w-50 d-flex ${column ? "flex-column " : ""}justify-content-center`}>
        <CustomComponent />
      </div>
    </div>
  );
};

export default Custom;
