export const iconCategories = {
  accessibility: {
    icons: [
      "accessible-icon",
      "audio-description",
      "braille",
      "circle-info",
      "circle-question",
      "closed-captioning",
      "ear-deaf",
      "ear-listen",
      "eye",
      "eye-low-vision",
      "fingerprint",
      "hands",
      "hands-asl-interpreting",
      "handshake-angle",
      "person-cane",
      "person-walking-with-cane",
      "phone-volume",
      "question",
      "tty",
      "universal-access",
      "wheelchair",
      "wheelchair-move",
    ],
    label: "Accessibility",
  },
  alert: {
    icons: [
      "bell",
      "bell-slash",
      "circle-exclamation",
      "circle-radiation",
      "exclamation",
      "question",
      "radiation",
      "skull-crossbones",
      "triangle-exclamation",
    ],
    label: "Alert",
  },
  alphabet: {
    icons: [
      "a",
      "b",
      "c",
      "circle-h",
      "d",
      "e",
      "f",
      "g",
      "h",
      "i",
      "j",
      "k",
      "l",
      "m",
      "n",
      "o",
      "p",
      "q",
      "r",
      "s",
      "square-h",
      "t",
      "u",
      "v",
      "w",
      "x",
      "y",
      "z",
    ],
    label: "Alphabet",
  },
  animals: {
    icons: [
      "bugs",
      "cat",
      "cow",
      "crow",
      "dog",
      "dove",
      "dragon",
      "feather",
      "feather-pointed",
      "fish",
      "fish-fins",
      "frog",
      "hippo",
      "horse",
      "horse-head",
      "kiwi-bird",
      "locust",
      "mosquito",
      "otter",
      "paw",
      "shield-cat",
      "shield-dog",
      "shrimp",
      "spider",
      "worm",
    ],
    label: "Animals",
  },
  arrows: {
    icons: [
      "angle-down",
      "angle-left",
      "angle-right",
      "angle-up",
      "angles-down",
      "angles-left",
      "angles-right",
      "angles-up",
      "arrow-down",
      "arrow-down-1-9",
      "arrow-down-9-1",
      "arrow-down-a-z",
      "arrow-down-long",
      "arrow-down-short-wide",
      "arrow-down-up-across-line",
      "arrow-down-up-lock",
      "arrow-down-wide-short",
      "arrow-down-z-a",
      "arrow-left",
      "arrow-left-long",
      "arrow-pointer",
      "arrow-right",
      "arrow-right-arrow-left",
      "arrow-right-from-bracket",
      "arrow-right-long",
      "arrow-right-to-bracket",
      "arrow-rotate-left",
      "arrow-rotate-right",
      "arrow-trend-down",
      "arrow-trend-up",
      "arrow-turn-down",
      "arrow-turn-up",
      "arrow-up",
      "arrow-up-1-9",
      "arrow-up-9-1",
      "arrow-up-a-z",
      "arrow-up-from-bracket",
      "arrow-up-long",
      "arrow-up-right-dots",
      "arrow-up-right-from-square",
      "arrow-up-short-wide",
      "arrow-up-wide-short",
      "arrow-up-z-a",
      "arrows-down-to-line",
      "arrows-left-right",
      "arrows-left-right-to-line",
      "arrows-rotate",
      "arrows-spin",
      "arrows-split-up-and-left",
      "arrows-to-circle",
      "arrows-to-dot",
      "arrows-to-eye",
      "arrows-turn-right",
      "arrows-turn-to-dots",
      "arrows-up-down",
      "arrows-up-down-left-right",
      "arrows-up-to-line",
      "caret-down",
      "caret-left",
      "caret-right",
      "caret-up",
      "chevron-down",
      "chevron-left",
      "chevron-right",
      "chevron-up",
      "circle-arrow-down",
      "circle-arrow-left",
      "circle-arrow-right",
      "circle-arrow-up",
      "circle-chevron-down",
      "circle-chevron-left",
      "circle-chevron-right",
      "circle-chevron-up",
      "circle-down",
      "circle-left",
      "circle-right",
      "circle-up",
      "clock-rotate-left",
      "cloud-arrow-down",
      "cloud-arrow-up",
      "down-left-and-up-right-to-center",
      "down-long",
      "download",
      "left-long",
      "left-right",
      "location-arrow",
      "maximize",
      "recycle",
      "repeat",
      "reply",
      "reply-all",
      "retweet",
      "right-from-bracket",
      "right-left",
      "right-long",
      "right-to-bracket",
      "rotate",
      "rotate-left",
      "rotate-right",
      "share",
      "share-from-square",
      "shuffle",
      "sort",
      "sort-down",
      "sort-up",
      "square-arrow-up-right",
      "square-caret-down",
      "square-caret-left",
      "square-caret-right",
      "square-caret-up",
      "square-up-right",
      "turn-down",
      "turn-up",
      "up-down",
      "up-down-left-right",
      "up-long",
      "up-right-and-down-left-from-center",
      "up-right-from-square",
      "upload",
    ],
    label: "Arrows",
  },
  astronomy: {
    icons: ["globe", "meteor", "moon", "satellite", "satellite-dish", "shuttle-space", "user-astronaut"],
    label: "Astronomy",
  },
  automotive: {
    icons: [
      "bus",
      "bus-simple",
      "car",
      "car-battery",
      "car-burst",
      "car-on",
      "car-rear",
      "car-side",
      "car-tunnel",
      "caravan",
      "charging-station",
      "gas-pump",
      "gauge",
      "gauge-high",
      "gauge-simple",
      "gauge-simple-high",
      "motorcycle",
      "oil-can",
      "spray-can-sparkles",
      "taxi",
      "trailer",
      "truck",
      "truck-field",
      "truck-field-un",
      "truck-medical",
      "truck-monster",
      "truck-pickup",
      "van-shuttle",
    ],
    label: "Automotive",
  },
  buildings: {
    icons: [
      "archway",
      "arrow-right-to-city",
      "building",
      "building-circle-arrow-right",
      "building-circle-check",
      "building-circle-exclamation",
      "building-circle-xmark",
      "building-columns",
      "building-flag",
      "building-lock",
      "building-ngo",
      "building-shield",
      "building-un",
      "building-user",
      "building-wheat",
      "campground",
      "church",
      "city",
      "dungeon",
      "gopuram",
      "hospital",
      "hospital-user",
      "hotel",
      "house",
      "house-chimney",
      "house-chimney-crack",
      "house-chimney-medical",
      "house-chimney-window",
      "house-circle-check",
      "house-circle-exclamation",
      "house-circle-xmark",
      "house-crack",
      "house-fire",
      "house-flag",
      "house-lock",
      "house-medical",
      "house-medical-circle-check",
      "house-medical-circle-exclamation",
      "house-medical-circle-xmark",
      "house-medical-flag",
      "igloo",
      "industry",
      "kaaba",
      "landmark",
      "landmark-dome",
      "landmark-flag",
      "monument",
      "mosque",
      "mountain-city",
      "oil-well",
      "place-of-worship",
      "school",
      "school-circle-check",
      "school-circle-exclamation",
      "school-circle-xmark",
      "school-flag",
      "school-lock",
      "shop",
      "shop-lock",
      "store",
      "synagogue",
      "tent",
      "tent-arrow-down-to-line",
      "tent-arrow-left-right",
      "tent-arrow-turn-left",
      "tent-arrows-down",
      "tents",
      "toilet-portable",
      "toilets-portable",
      "torii-gate",
      "tower-observation",
      "tree-city",
      "vihara",
      "warehouse",
    ],
    label: "Buildings",
  },
  business: {
    icons: [
      "address-book",
      "address-card",
      "arrows-spin",
      "arrows-to-dot",
      "arrows-to-eye",
      "bars-progress",
      "bars-staggered",
      "book",
      "box-archive",
      "boxes-packing",
      "briefcase",
      "building",
      "bullhorn",
      "bullseye",
      "business-time",
      "cake-candles",
      "calculator",
      "calendar",
      "calendar-days",
      "certificate",
      "chart-line",
      "chart-pie",
      "chart-simple",
      "city",
      "clipboard",
      "clipboard-question",
      "compass",
      "copy",
      "copyright",
      "envelope",
      "envelope-circle-check",
      "envelope-open",
      "eraser",
      "fax",
      "file",
      "file-circle-plus",
      "file-lines",
      "floppy-disk",
      "folder",
      "folder-minus",
      "folder-open",
      "folder-plus",
      "folder-tree",
      "glasses",
      "globe",
      "highlighter",
      "house-laptop",
      "industry",
      "landmark",
      "laptop-file",
      "list-check",
      "magnifying-glass-arrow-right",
      "magnifying-glass-chart",
      "marker",
      "mug-saucer",
      "network-wired",
      "note-sticky",
      "paperclip",
      "paste",
      "pen",
      "pen-clip",
      "pen-fancy",
      "pen-nib",
      "pen-to-square",
      "pencil",
      "percent",
      "person-chalkboard",
      "phone",
      "phone-flip",
      "phone-slash",
      "phone-volume",
      "print",
      "registered",
      "scale-balanced",
      "scale-unbalanced",
      "scale-unbalanced-flip",
      "scissors",
      "signature",
      "sitemap",
      "socks",
      "square-envelope",
      "square-pen",
      "square-phone",
      "square-phone-flip",
      "square-poll-horizontal",
      "square-poll-vertical",
      "table",
      "table-columns",
      "tag",
      "tags",
      "thumbtack",
      "timeline",
      "trademark",
      "vault",
      "wallet",
    ],
    label: "Business",
  },
  camping: {
    icons: [
      "binoculars",
      "bottle-water",
      "bucket",
      "campground",
      "caravan",
      "compass",
      "faucet",
      "faucet-drip",
      "fire",
      "fire-burner",
      "fire-flame-curved",
      "frog",
      "kit-medical",
      "map",
      "map-location",
      "map-location-dot",
      "mattress-pillow",
      "mosquito",
      "mosquito-net",
      "mountain",
      "mountain-sun",
      "people-roof",
      "person-hiking",
      "person-shelter",
      "route",
      "signs-post",
      "tarp",
      "tarp-droplet",
      "tent",
      "tent-arrow-down-to-line",
      "tent-arrow-left-right",
      "tent-arrow-turn-left",
      "tent-arrows-down",
      "tents",
      "toilet-paper",
      "trailer",
      "tree",
    ],
    label: "Camping",
  },
  charity: {
    icons: [
      "circle-dollar-to-slot",
      "dollar-sign",
      "dove",
      "gift",
      "globe",
      "hand-holding-dollar",
      "hand-holding-droplet",
      "hand-holding-hand",
      "hand-holding-heart",
      "hands-holding-child",
      "hands-holding-circle",
      "handshake",
      "handshake-angle",
      "handshake-simple",
      "heart",
      "leaf",
      "parachute-box",
      "piggy-bank",
      "ribbon",
      "seedling",
    ],
    label: "Charity",
  },
  "charts-diagrams": {
    icons: [
      "bars-progress",
      "chart-area",
      "chart-bar",
      "chart-column",
      "chart-gantt",
      "chart-line",
      "chart-pie",
      "chart-simple",
      "diagram-next",
      "diagram-predecessor",
      "diagram-project",
      "diagram-successor",
      "square-poll-horizontal",
      "square-poll-vertical",
    ],
    label: "Charts + Diagrams",
  },
  childhood: {
    icons: [
      "apple-whole",
      "baby",
      "baby-carriage",
      "baseball-bat-ball",
      "bath",
      "bucket",
      "cake-candles",
      "child",
      "child-dress",
      "child-reaching",
      "children",
      "cookie",
      "cookie-bite",
      "cubes-stacked",
      "gamepad",
      "hands-holding-child",
      "ice-cream",
      "mitten",
      "person-biking",
      "person-breastfeeding",
      "puzzle-piece",
      "robot",
      "school",
      "shapes",
      "snowman",
    ],
    label: "Childhood",
  },
  "clothing-fashion": {
    icons: ["glasses", "graduation-cap", "hat-cowboy", "hat-cowboy-side", "hat-wizard", "mitten", "shirt", "shoe-prints", "socks", "user-tie"],
    label: "Clothing + Fashion",
  },
  coding: {
    icons: [
      "barcode",
      "bars",
      "bars-staggered",
      "bath",
      "box-archive",
      "bug",
      "bug-slash",
      "circle-nodes",
      "code",
      "code-branch",
      "code-commit",
      "code-compare",
      "code-fork",
      "code-merge",
      "code-pull-request",
      "cube",
      "cubes",
      "diagram-project",
      "file",
      "file-code",
      "file-lines",
      "filter",
      "fire-extinguisher",
      "folder",
      "folder-open",
      "font-awesome",
      "gears",
      "keyboard",
      "laptop-code",
      "microchip",
      "mug-saucer",
      "network-wired",
      "qrcode",
      "rectangle-xmark",
      "shield",
      "shield-halved",
      "sitemap",
      "terminal",
      "user-secret",
      "window-maximize",
      "window-minimize",
      "window-restore",
    ],
    label: "Coding",
  },
  communication: {
    icons: [
      "address-book",
      "address-card",
      "at",
      "blender-phone",
      "bluetooth-b",
      "bullhorn",
      "comment",
      "comment-dots",
      "comment-medical",
      "comment-slash",
      "comment-sms",
      "comments",
      "ear-listen",
      "envelope",
      "envelope-circle-check",
      "envelope-open",
      "face-frown",
      "face-meh",
      "face-smile",
      "fax",
      "hands-asl-interpreting",
      "icons",
      "inbox",
      "language",
      "message",
      "microphone",
      "microphone-lines",
      "microphone-lines-slash",
      "microphone-slash",
      "mobile",
      "mobile-button",
      "mobile-retro",
      "mobile-screen",
      "mobile-screen-button",
      "paper-plane",
      "phone",
      "phone-flip",
      "phone-slash",
      "phone-volume",
      "poo",
      "quote-left",
      "quote-right",
      "square-envelope",
      "square-phone",
      "square-phone-flip",
      "square-rss",
      "tower-cell",
      "tty",
      "video",
      "video-slash",
      "voicemail",
      "walkie-talkie",
    ],
    label: "Communication",
  },
  connectivity: {
    icons: [
      "bluetooth",
      "circle-nodes",
      "cloud",
      "cloud-arrow-down",
      "cloud-arrow-up",
      "globe",
      "house-signal",
      "rss",
      "satellite-dish",
      "signal",
      "tower-broadcast",
      "tower-cell",
      "wifi",
    ],
    label: "Connectivity",
  },
  construction: {
    icons: [
      "arrow-up-from-ground-water",
      "bore-hole",
      "brush",
      "bucket",
      "compass-drafting",
      "dumpster",
      "dumpster-fire",
      "hammer",
      "helmet-safety",
      "mound",
      "paint-roller",
      "pen-ruler",
      "pencil",
      "person-digging",
      "ruler",
      "ruler-combined",
      "ruler-horizontal",
      "ruler-vertical",
      "screwdriver",
      "screwdriver-wrench",
      "sheet-plastic",
      "tarp",
      "tarp-droplet",
      "toilet-portable",
      "toilets-portable",
      "toolbox",
      "trowel",
      "trowel-bricks",
      "truck-pickup",
      "wrench",
    ],
    label: "Construction",
  },
  design: {
    icons: [
      "bezier-curve",
      "brush",
      "circle-half-stroke",
      "circle-nodes",
      "clone",
      "compass-drafting",
      "copy",
      "crop",
      "crop-simple",
      "crosshairs",
      "cube",
      "cubes",
      "draw-polygon",
      "droplet",
      "droplet-slash",
      "eraser",
      "eye",
      "eye-dropper",
      "eye-slash",
      "fill",
      "fill-drip",
      "floppy-disk",
      "font-awesome",
      "highlighter",
      "icons",
      "layer-group",
      "lines-leaning",
      "marker",
      "object-group",
      "object-ungroup",
      "paint-roller",
      "paintbrush",
      "palette",
      "paste",
      "pen",
      "pen-clip",
      "pen-fancy",
      "pen-nib",
      "pen-ruler",
      "pen-to-square",
      "pencil",
      "ruler-combined",
      "ruler-horizontal",
      "ruler-vertical",
      "scissors",
      "splotch",
      "spray-can",
      "stamp",
      "swatchbook",
      "vector-square",
      "wand-magic",
      "wand-magic-sparkles",
    ],
    label: "Design",
  },
  "devices-hardware": {
    icons: [
      "blender-phone",
      "camera",
      "camera-retro",
      "car-battery",
      "compact-disc",
      "computer",
      "computer-mouse",
      "database",
      "desktop",
      "display",
      "download",
      "ethernet",
      "fax",
      "floppy-disk",
      "gamepad",
      "hard-drive",
      "headphones",
      "house-laptop",
      "keyboard",
      "laptop",
      "laptop-file",
      "memory",
      "microchip",
      "mobile",
      "mobile-button",
      "mobile-retro",
      "mobile-screen",
      "mobile-screen-button",
      "plug",
      "power-off",
      "print",
      "satellite",
      "satellite-dish",
      "sd-card",
      "server",
      "sim-card",
      "tablet",
      "tablet-button",
      "tablet-screen-button",
      "tachograph-digital",
      "tv",
      "upload",
      "walkie-talkie",
    ],
    label: "Devices + Hardware",
  },
  disaster: {
    icons: [
      "biohazard",
      "bugs",
      "burst",
      "child-rifle",
      "circle-radiation",
      "cloud-bolt",
      "cloud-showers-heavy",
      "cloud-showers-water",
      "helmet-un",
      "hill-avalanche",
      "hill-rockslide",
      "house-chimney-crack",
      "house-crack",
      "house-fire",
      "house-flood-water",
      "house-flood-water-circle-arrow-right",
      "house-tsunami",
      "hurricane",
      "locust",
      "mosquito",
      "person-drowning",
      "person-rifle",
      "person-walking-arrow-loop-left",
      "person-walking-arrow-right",
      "person-walking-dashed-line-arrow-right",
      "plant-wilt",
      "radiation",
      "snowflake",
      "sun-plant-wilt",
      "temperature-arrow-down",
      "temperature-arrow-up",
      "tornado",
      "volcano",
      "wheat-awn-circle-exclamation",
      "wind",
      "worm",
      "xmarks-lines",
    ],
    label: "Disaster + Crisis",
  },
  editing: {
    icons: [
      "arrows-rotate",
      "bandage",
      "bars",
      "brush",
      "chart-simple",
      "check",
      "check-double",
      "circle-check",
      "circle-half-stroke",
      "crop",
      "crop-simple",
      "cube",
      "delete-left",
      "ellipsis",
      "ellipsis-vertical",
      "eye-dropper",
      "eye-slash",
      "grip",
      "grip-lines",
      "grip-lines-vertical",
      "grip-vertical",
      "link",
      "link-slash",
      "minus",
      "paintbrush",
      "pen",
      "pen-clip",
      "pen-fancy",
      "pen-nib",
      "pen-ruler",
      "pen-to-square",
      "pencil",
      "plus",
      "rotate",
      "scissors",
      "signature",
      "sliders",
      "square-check",
      "square-pen",
      "trash",
      "trash-arrow-up",
      "trash-can",
      "trash-can-arrow-up",
      "wand-magic",
      "wand-magic-sparkles",
      "xmark",
    ],
    label: "Editing",
  },
  education: {
    icons: [
      "apple-whole",
      "atom",
      "award",
      "bell",
      "bell-slash",
      "book-open",
      "book-open-reader",
      "chalkboard",
      "chalkboard-user",
      "graduation-cap",
      "laptop-code",
      "laptop-file",
      "masks-theater",
      "microscope",
      "music",
      "person-chalkboard",
      "school",
      "school-circle-check",
      "school-circle-exclamation",
      "school-circle-xmark",
      "school-flag",
      "school-lock",
      "shapes",
      "user-graduate",
    ],
    label: "Education",
  },
  emoji: {
    icons: [
      "face-angry",
      "face-dizzy",
      "face-flushed",
      "face-frown",
      "face-frown-open",
      "face-grimace",
      "face-grin",
      "face-grin-beam",
      "face-grin-beam-sweat",
      "face-grin-hearts",
      "face-grin-squint",
      "face-grin-squint-tears",
      "face-grin-stars",
      "face-grin-tears",
      "face-grin-tongue",
      "face-grin-tongue-squint",
      "face-grin-tongue-wink",
      "face-grin-wide",
      "face-grin-wink",
      "face-kiss",
      "face-kiss-beam",
      "face-kiss-wink-heart",
      "face-laugh",
      "face-laugh-beam",
      "face-laugh-squint",
      "face-laugh-wink",
      "face-meh",
      "face-meh-blank",
      "face-rolling-eyes",
      "face-sad-cry",
      "face-sad-tear",
      "face-smile",
      "face-smile-beam",
      "face-smile-wink",
      "face-surprise",
      "face-tired",
    ],
    label: "Emoji",
  },
  energy: {
    icons: [
      "arrow-up-from-ground-water",
      "atom",
      "battery-empty",
      "battery-full",
      "battery-half",
      "battery-quarter",
      "battery-three-quarters",
      "bolt",
      "car-battery",
      "charging-station",
      "circle-radiation",
      "explosion",
      "fan",
      "fire",
      "fire-flame-curved",
      "fire-flame-simple",
      "gas-pump",
      "industry",
      "leaf",
      "lightbulb",
      "oil-well",
      "plug",
      "plug-circle-bolt",
      "plug-circle-check",
      "plug-circle-exclamation",
      "plug-circle-minus",
      "plug-circle-plus",
      "plug-circle-xmark",
      "poop",
      "power-off",
      "radiation",
      "seedling",
      "solar-panel",
      "sun",
      "tower-broadcast",
      "water",
      "wind",
    ],
    label: "Energy",
  },
  files: {
    icons: [
      "box-archive",
      "clone",
      "copy",
      "file",
      "file-arrow-down",
      "file-arrow-up",
      "file-audio",
      "file-circle-check",
      "file-circle-exclamation",
      "file-circle-minus",
      "file-circle-plus",
      "file-circle-question",
      "file-circle-xmark",
      "file-code",
      "file-csv",
      "file-excel",
      "file-export",
      "file-image",
      "file-import",
      "file-lines",
      "file-pdf",
      "file-pen",
      "file-powerpoint",
      "file-shield",
      "file-video",
      "file-word",
      "file-zipper",
      "floppy-disk",
      "folder",
      "folder-closed",
      "folder-open",
      "note-sticky",
      "paste",
      "photo-film",
      "scissors",
    ],
    label: "Files",
  },
  "film-video": {
    icons: [
      "audio-description",
      "circle",
      "clapperboard",
      "closed-captioning",
      "compact-disc",
      "file-audio",
      "file-video",
      "film",
      "headphones",
      "microphone",
      "microphone-lines",
      "microphone-lines-slash",
      "microphone-slash",
      "photo-film",
      "podcast",
      "square-rss",
      "ticket",
      "tower-broadcast",
      "tower-cell",
      "tv",
      "video",
      "video-slash",
      "youtube",
    ],
    label: "Film + Video",
  },
  "food-beverage": {
    icons: [
      "apple-whole",
      "bacon",
      "beer-mug-empty",
      "blender",
      "bone",
      "bottle-droplet",
      "bottle-water",
      "bowl-food",
      "bowl-rice",
      "bread-slice",
      "burger",
      "cake-candles",
      "candy-cane",
      "carrot",
      "champagne-glasses",
      "cheese",
      "cloud-meatball",
      "cookie",
      "cubes-stacked",
      "drumstick-bite",
      "egg",
      "fish",
      "fish-fins",
      "flask",
      "glass-water",
      "glass-water-droplet",
      "hotdog",
      "ice-cream",
      "jar",
      "jar-wheat",
      "lemon",
      "martini-glass",
      "martini-glass-citrus",
      "martini-glass-empty",
      "mug-hot",
      "mug-saucer",
      "pepper-hot",
      "pizza-slice",
      "plate-wheat",
      "seedling",
      "shrimp",
      "stroopwafel",
      "wheat-awn",
      "wheat-awn-circle-exclamation",
      "whiskey-glass",
      "wine-bottle",
      "wine-glass",
      "wine-glass-empty",
    ],
    label: "Food + Beverage",
  },
  "fruits-vegetables": {
    icons: ["apple-whole", "carrot", "leaf", "lemon", "pepper-hot", "seedling"],
    label: "Fruits + Vegetables",
  },
  gaming: {
    icons: [
      "book-skull",
      "chess",
      "chess-bishop",
      "chess-board",
      "chess-king",
      "chess-knight",
      "chess-pawn",
      "chess-queen",
      "chess-rook",
      "critical-role",
      "d-and-d",
      "d-and-d-beyond",
      "diamond",
      "dice",
      "dice-d20",
      "dice-d6",
      "dice-five",
      "dice-four",
      "dice-one",
      "dice-six",
      "dice-three",
      "dice-two",
      "dragon",
      "dungeon",
      "fantasy-flight-games",
      "gamepad",
      "ghost",
      "hand-fist",
      "hat-wizard",
      "headset",
      "heart",
      "playstation",
      "puzzle-piece",
      "ring",
      "scroll",
      "shield-halved",
      "skull-crossbones",
      "square-full",
      "steam",
      "steam-square",
      "steam-symbol",
      "twitch",
      "vr-cardboard",
      "wand-sparkles",
      "wizards-of-the-coast",
      "xbox",
    ],
    label: "Gaming",
  },
  gender: {
    icons: [
      "genderless",
      "mars",
      "mars-and-venus",
      "mars-double",
      "mars-stroke",
      "mars-stroke-right",
      "mars-stroke-up",
      "mercury",
      "neuter",
      "person-half-dress",
      "transgender",
      "venus",
      "venus-double",
      "venus-mars",
    ],
    label: "Genders",
  },
  halloween: {
    icons: [
      "book-skull",
      "broom",
      "cat",
      "cloud-moon",
      "crow",
      "ghost",
      "hat-wizard",
      "mask",
      "skull",
      "skull-crossbones",
      "spider",
      "toilet-paper",
      "wand-sparkles",
    ],
    label: "Halloween",
  },
  hands: {
    icons: [
      "hand",
      "hand-back-fist",
      "hand-dots",
      "hand-fist",
      "hand-holding",
      "hand-holding-dollar",
      "hand-holding-droplet",
      "hand-holding-hand",
      "hand-holding-heart",
      "hand-holding-medical",
      "hand-lizard",
      "hand-middle-finger",
      "hand-peace",
      "hand-point-down",
      "hand-point-left",
      "hand-point-right",
      "hand-point-up",
      "hand-pointer",
      "hand-scissors",
      "hand-sparkles",
      "hand-spock",
      "hands-bound",
      "hands-bubbles",
      "hands-clapping",
      "hands-holding",
      "hands-holding-child",
      "hands-holding-circle",
      "hands-praying",
      "handshake",
      "handshake-angle",
      "handshake-simple",
      "handshake-simple-slash",
      "handshake-slash",
      "thumbs-down",
      "thumbs-up",
    ],
    label: "Hands",
  },
  holidays: {
    icons: [
      "candy-cane",
      "carrot",
      "champagne-glasses",
      "cookie-bite",
      "face-grin-hearts",
      "face-kiss-wink-heart",
      "gift",
      "gifts",
      "heart",
      "holly-berry",
      "menorah",
      "mug-hot",
      "sleigh",
      "snowman",
    ],
    label: "Holidays",
  },
  household: {
    icons: [
      "arrow-up-from-water-pump",
      "bath",
      "bed",
      "bell",
      "blender",
      "box-tissue",
      "chair",
      "computer",
      "couch",
      "door-closed",
      "door-open",
      "dungeon",
      "fan",
      "faucet",
      "faucet-drip",
      "fire-burner",
      "house-chimney-user",
      "house-chimney-window",
      "house-fire",
      "house-laptop",
      "house-lock",
      "house-signal",
      "house-user",
      "jar",
      "jar-wheat",
      "jug-detergent",
      "kitchen-set",
      "lightbulb",
      "mattress-pillow",
      "mug-saucer",
      "people-roof",
      "plug",
      "pump-soap",
      "rug",
      "sheet-plastic",
      "shower",
      "sink",
      "snowflake",
      "soap",
      "spoon",
      "stairs",
      "temperature-arrow-down",
      "temperature-arrow-up",
      "toilet",
      "toilet-paper",
      "toilet-paper-slash",
      "tv",
      "utensils",
    ],
    label: "Household",
  },
  humanitarian: {
    icons: [
      "anchor",
      "anchor-circle-check",
      "anchor-circle-exclamation",
      "anchor-circle-xmark",
      "anchor-lock",
      "arrow-down-up-across-line",
      "arrow-down-up-lock",
      "arrow-right-to-city",
      "arrow-up-from-ground-water",
      "arrow-up-from-water-pump",
      "arrow-up-right-dots",
      "arrow-up-right-from-square",
      "arrows-down-to-line",
      "arrows-down-to-people",
      "arrows-left-right-to-line",
      "arrows-spin",
      "arrows-split-up-and-left",
      "arrows-to-circle",
      "arrows-to-dot",
      "arrows-to-eye",
      "arrows-turn-right",
      "arrows-turn-to-dots",
      "arrows-up-to-line",
      "baby",
      "bacterium",
      "ban",
      "bed",
      "biohazard",
      "book-bookmark",
      "bore-hole",
      "bottle-droplet",
      "bottle-water",
      "bowl-food",
      "bowl-rice",
      "boxes-packing",
      "bridge",
      "bridge-circle-check",
      "bridge-circle-exclamation",
      "bridge-circle-xmark",
      "bridge-lock",
      "bridge-water",
      "bucket",
      "bugs",
      "building",
      "building-circle-arrow-right",
      "building-circle-check",
      "building-circle-exclamation",
      "building-circle-xmark",
      "building-columns",
      "building-flag",
      "building-lock",
      "building-ngo",
      "building-shield",
      "building-un",
      "building-user",
      "building-wheat",
      "burst",
      "bus",
      "car",
      "car-on",
      "car-tunnel",
      "child-rifle",
      "children",
      "church",
      "circle-h",
      "circle-nodes",
      "clipboard-question",
      "clipboard-user",
      "cloud-bolt",
      "cloud-showers-heavy",
      "cloud-showers-water",
      "computer",
      "cow",
      "cubes-stacked",
      "display",
      "droplet",
      "envelope",
      "envelope-circle-check",
      "explosion",
      "faucet-drip",
      "fax",
      "ferry",
      "file",
      "file-circle-check",
      "file-circle-exclamation",
      "file-circle-minus",
      "file-circle-plus",
      "file-circle-question",
      "file-circle-xmark",
      "file-csv",
      "file-pdf",
      "file-pen",
      "file-shield",
      "fire-burner",
      "fire-flame-simple",
      "fish-fins",
      "flag",
      "flask-vial",
      "gas-pump",
      "glass-water",
      "glass-water-droplet",
      "gopuram",
      "group-arrows-rotate",
      "hammer",
      "hand-holding-hand",
      "handcuffs",
      "hands-bound",
      "hands-bubbles",
      "hands-holding-child",
      "hands-holding-circle",
      "handshake-simple",
      "headset",
      "heart-circle-bolt",
      "heart-circle-check",
      "heart-circle-exclamation",
      "heart-circle-minus",
      "heart-circle-plus",
      "heart-circle-xmark",
      "helicopter",
      "helicopter-symbol",
      "helmet-un",
      "hill-avalanche",
      "hill-rockslide",
      "hospital",
      "hotel",
      "house-chimney",
      "house-chimney-crack",
      "house-circle-check",
      "house-circle-exclamation",
      "house-circle-xmark",
      "house-fire",
      "house-flag",
      "house-flood-water",
      "house-flood-water-circle-arrow-right",
      "house-lock",
      "house-medical",
      "house-medical-circle-check",
      "house-medical-circle-exclamation",
      "house-medical-circle-xmark",
      "house-medical-flag",
      "house-signal",
      "house-tsunami",
      "hurricane",
      "id-card",
      "jar",
      "jar-wheat",
      "jet-fighter-up",
      "jug-detergent",
      "kitchen-set",
      "land-mine-on",
      "landmark",
      "landmark-dome",
      "landmark-flag",
      "laptop",
      "laptop-file",
      "life-ring",
      "lines-leaning",
      "location-pin-lock",
      "locust",
      "lungs",
      "magnifying-glass-arrow-right",
      "magnifying-glass-chart",
      "mars-and-venus",
      "mars-and-venus-burst",
      "mask-face",
      "mask-ventilator",
      "mattress-pillow",
      "microscope",
      "mobile-retro",
      "mobile-screen",
      "money-bill-transfer",
      "money-bill-trend-up",
      "money-bill-wheat",
      "money-bills",
      "mosque",
      "mosquito",
      "mosquito-net",
      "mound",
      "mountain-city",
      "mountain-sun",
      "oil-well",
      "parachute-box",
      "people-arrows-left-right",
      "people-group",
      "people-line",
      "people-pulling",
      "people-robbery",
      "people-roof",
      "person",
      "person-arrow-down-to-line",
      "person-arrow-up-from-line",
      "person-breastfeeding",
      "person-burst",
      "person-cane",
      "person-chalkboard",
      "person-circle-check",
      "person-circle-exclamation",
      "person-circle-minus",
      "person-circle-plus",
      "person-circle-question",
      "person-circle-xmark",
      "person-digging",
      "person-dress",
      "person-dress-burst",
      "person-drowning",
      "person-falling",
      "person-falling-burst",
      "person-half-dress",
      "person-harassing",
      "person-military-pointing",
      "person-military-rifle",
      "person-military-to-person",
      "person-pregnant",
      "person-rays",
      "person-rifle",
      "person-shelter",
      "person-through-window",
      "person-walking",
      "person-walking-arrow-loop-left",
      "person-walking-arrow-right",
      "person-walking-dashed-line-arrow-right",
      "person-walking-luggage",
      "pills",
      "plane-circle-check",
      "plane-circle-exclamation",
      "plane-circle-xmark",
      "plane-lock",
      "plane-up",
      "plant-wilt",
      "plate-wheat",
      "plug",
      "plug-circle-bolt",
      "plug-circle-check",
      "plug-circle-exclamation",
      "plug-circle-minus",
      "plug-circle-plus",
      "plug-circle-xmark",
      "pump-soap",
      "radiation",
      "radio",
      "ranking-star",
      "road",
      "road-barrier",
      "road-bridge",
      "road-circle-check",
      "road-circle-exclamation",
      "road-circle-xmark",
      "road-lock",
      "road-spikes",
      "rug",
      "sack-dollar",
      "sack-xmark",
      "sailboat",
      "satellite-dish",
      "scale-balanced",
      "school",
      "school-circle-check",
      "school-circle-exclamation",
      "school-circle-xmark",
      "school-flag",
      "school-lock",
      "seedling",
      "sheet-plastic",
      "shield-cat",
      "shield-dog",
      "shield-heart",
      "ship",
      "shirt",
      "shop",
      "shop-lock",
      "shower",
      "skull-crossbones",
      "snowflake",
      "soap",
      "square-nfi",
      "square-person-confined",
      "square-virus",
      "staff-aesculapius",
      "stethoscope",
      "suitcase-medical",
      "sun-plant-wilt",
      "syringe",
      "tarp",
      "tarp-droplet",
      "temperature-arrow-down",
      "temperature-arrow-up",
      "tent",
      "tent-arrow-down-to-line",
      "tent-arrow-left-right",
      "tent-arrow-turn-left",
      "tent-arrows-down",
      "tents",
      "toilet",
      "toilet-portable",
      "toilets-portable",
      "tornado",
      "tower-broadcast",
      "tower-cell",
      "tower-observation",
      "train-subway",
      "trash-can",
      "tree-city",
      "trowel",
      "trowel-bricks",
      "truck",
      "truck-arrow-right",
      "truck-droplet",
      "truck-field",
      "truck-field-un",
      "truck-front",
      "truck-medical",
      "truck-plane",
      "user-doctor",
      "user-injured",
      "users-between-lines",
      "users-line",
      "users-rays",
      "users-rectangle",
      "users-viewfinder",
      "vial-circle-check",
      "vial-virus",
      "vihara",
      "virus",
      "virus-covid",
      "volcano",
      "walkie-talkie",
      "wheat-awn",
      "wheat-awn-circle-exclamation",
      "wheelchair-move",
      "wifi",
      "wind",
      "worm",
      "xmarks-lines",
    ],
    label: "Humanitarian",
  },
  logistics: {
    icons: [
      "anchor",
      "anchor-circle-check",
      "anchor-circle-exclamation",
      "anchor-circle-xmark",
      "anchor-lock",
      "box",
      "boxes-packing",
      "boxes-stacked",
      "bridge",
      "bridge-circle-check",
      "bridge-circle-exclamation",
      "bridge-circle-xmark",
      "bridge-lock",
      "bridge-water",
      "bus",
      "bus-simple",
      "car",
      "car-tunnel",
      "cart-flatbed",
      "chart-simple",
      "clipboard-check",
      "clipboard-list",
      "clipboard-question",
      "dolly",
      "ferry",
      "gas-pump",
      "gears",
      "helicopter",
      "helicopter-symbol",
      "helmet-safety",
      "jet-fighter-up",
      "pallet",
      "plane-circle-check",
      "plane-circle-exclamation",
      "plane-circle-xmark",
      "plane-lock",
      "road",
      "road-barrier",
      "road-bridge",
      "road-circle-check",
      "road-circle-exclamation",
      "road-circle-xmark",
      "road-lock",
      "sailboat",
      "square-nfi",
      "train",
      "train-subway",
      "truck",
      "truck-arrow-right",
      "truck-fast",
      "truck-field",
      "truck-field-un",
      "truck-front",
      "truck-plane",
      "warehouse",
      "xmarks-lines",
    ],
    label: "Logistics",
  },
  maps: {
    icons: [
      "anchor",
      "bag-shopping",
      "basket-shopping",
      "bath",
      "bed",
      "beer-mug-empty",
      "bell",
      "bell-slash",
      "bicycle",
      "binoculars",
      "bomb",
      "book",
      "book-atlas",
      "bookmark",
      "bridge",
      "bridge-water",
      "briefcase",
      "building",
      "building-columns",
      "cake-candles",
      "car",
      "cart-shopping",
      "circle-info",
      "crosshairs",
      "diamond-turn-right",
      "dollar-sign",
      "draw-polygon",
      "droplet",
      "eye",
      "eye-low-vision",
      "eye-slash",
      "fire",
      "fire-extinguisher",
      "fire-flame-curved",
      "flag",
      "flag-checkered",
      "flask",
      "gamepad",
      "gavel",
      "gift",
      "globe",
      "graduation-cap",
      "heart",
      "heart-pulse",
      "helicopter",
      "helicopter-symbol",
      "hospital",
      "house",
      "image",
      "images",
      "industry",
      "info",
      "jet-fighter",
      "key",
      "landmark",
      "landmark-flag",
      "layer-group",
      "leaf",
      "lemon",
      "life-ring",
      "lightbulb",
      "location-arrow",
      "location-crosshairs",
      "location-dot",
      "location-pin",
      "location-pin-lock",
      "magnet",
      "magnifying-glass",
      "magnifying-glass-minus",
      "magnifying-glass-plus",
      "map",
      "map-pin",
      "martini-glass-empty",
      "money-bill",
      "money-bill-1",
      "motorcycle",
      "mountain-sun",
      "mug-saucer",
      "music",
      "newspaper",
      "paw",
      "person",
      "person-walking-with-cane",
      "phone",
      "phone-flip",
      "phone-volume",
      "plane",
      "plug",
      "plus",
      "print",
      "recycle",
      "restroom",
      "road",
      "rocket",
      "route",
      "scale-balanced",
      "scale-unbalanced",
      "scale-unbalanced-flip",
      "ship",
      "shoe-prints",
      "shower",
      "signs-post",
      "snowplow",
      "spoon",
      "square-h",
      "square-parking",
      "square-phone",
      "square-phone-flip",
      "square-plus",
      "street-view",
      "suitcase",
      "suitcase-medical",
      "tag",
      "tags",
      "taxi",
      "thumbtack",
      "ticket",
      "ticket-simple",
      "traffic-light",
      "train",
      "train-subway",
      "train-tram",
      "tree",
      "trophy",
      "truck",
      "truck-medical",
      "tty",
      "umbrella",
      "utensils",
      "vest",
      "vest-patches",
      "wheelchair",
      "wheelchair-move",
      "wifi",
      "wine-glass",
      "wrench",
    ],
    label: "Maps",
  },
  maritime: {
    icons: [
      "anchor",
      "anchor-circle-check",
      "anchor-circle-exclamation",
      "anchor-circle-xmark",
      "anchor-lock",
      "ferry",
      "fish",
      "fish-fins",
      "otter",
      "person-swimming",
      "sailboat",
      "ship",
      "shrimp",
      "water",
    ],
    label: "Maritime",
  },
  marketing: {
    icons: [
      "arrows-spin",
      "arrows-to-dot",
      "arrows-to-eye",
      "bullhorn",
      "bullseye",
      "chart-simple",
      "comment-dollar",
      "comments-dollar",
      "envelope-open-text",
      "envelopes-bulk",
      "filter-circle-dollar",
      "group-arrows-rotate",
      "lightbulb",
      "magnifying-glass-arrow-right",
      "magnifying-glass-chart",
      "magnifying-glass-dollar",
      "magnifying-glass-location",
      "people-group",
      "person-rays",
      "ranking-star",
      "rectangle-ad",
      "square-poll-horizontal",
      "square-poll-vertical",
      "timeline",
    ],
    label: "Marketing",
  },
  mathematics: {
    icons: [
      "calculator",
      "circle-minus",
      "circle-plus",
      "circle-xmark",
      "divide",
      "equals",
      "greater-than",
      "greater-than-equal",
      "infinity",
      "less-than",
      "less-than-equal",
      "minus",
      "not-equal",
      "percent",
      "plus",
      "plus-minus",
      "square-minus",
      "square-root-variable",
      "square-xmark",
      "subscript",
      "superscript",
      "wave-square",
      "xmark",
    ],
    label: "Mathematics",
  },
  "media-playback": {
    icons: [
      "arrow-rotate-left",
      "arrow-rotate-right",
      "arrows-rotate",
      "backward",
      "backward-fast",
      "backward-step",
      "circle-pause",
      "circle-play",
      "circle-stop",
      "compress",
      "down-left-and-up-right-to-center",
      "eject",
      "expand",
      "forward",
      "forward-fast",
      "forward-step",
      "hand",
      "maximize",
      "minimize",
      "music",
      "pause",
      "phone-volume",
      "play",
      "plus-minus",
      "repeat",
      "rotate",
      "rotate-left",
      "rotate-right",
      "rss",
      "shuffle",
      "sliders",
      "stop",
      "up-right-and-down-left-from-center",
      "volume-high",
      "volume-low",
      "volume-off",
      "volume-xmark",
    ],
    label: "Media Playback",
  },
  "medical-health": {
    icons: [
      "accessible-icon",
      "bacteria",
      "bacterium",
      "ban-smoking",
      "bandage",
      "bed-pulse",
      "biohazard",
      "bone",
      "bong",
      "book-medical",
      "brain",
      "briefcase-medical",
      "cannabis",
      "capsules",
      "circle-h",
      "circle-radiation",
      "clipboard-user",
      "clock-rotate-left",
      "comment-medical",
      "crutch",
      "disease",
      "dna",
      "eye",
      "eye-dropper",
      "file-medical",
      "file-prescription",
      "file-waveform",
      "fire-flame-simple",
      "flask",
      "flask-vial",
      "hand-dots",
      "hand-holding-medical",
      "head-side-cough",
      "head-side-cough-slash",
      "head-side-mask",
      "head-side-virus",
      "heart",
      "heart-circle-bolt",
      "heart-circle-check",
      "heart-circle-exclamation",
      "heart-circle-minus",
      "heart-circle-plus",
      "heart-circle-xmark",
      "heart-pulse",
      "hospital",
      "hospital-user",
      "house-chimney-medical",
      "house-medical",
      "house-medical-circle-check",
      "house-medical-circle-exclamation",
      "house-medical-circle-xmark",
      "house-medical-flag",
      "id-card-clip",
      "joint",
      "kit-medical",
      "laptop-medical",
      "lungs",
      "lungs-virus",
      "mask-face",
      "mask-ventilator",
      "microscope",
      "mortar-pestle",
      "notes-medical",
      "pager",
      "person-breastfeeding",
      "person-cane",
      "person-dots-from-line",
      "person-half-dress",
      "pills",
      "plus",
      "poop",
      "prescription",
      "prescription-bottle",
      "prescription-bottle-medical",
      "pump-medical",
      "radiation",
      "receipt",
      "shield-virus",
      "skull",
      "skull-crossbones",
      "smoking",
      "square-h",
      "square-plus",
      "square-virus",
      "staff-aesculapius",
      "star-of-life",
      "stethoscope",
      "suitcase-medical",
      "syringe",
      "tablets",
      "teeth",
      "teeth-open",
      "thermometer",
      "tooth",
      "truck-medical",
      "user-doctor",
      "user-nurse",
      "vial",
      "vial-circle-check",
      "vial-virus",
      "vials",
      "virus",
      "virus-covid",
      "virus-covid-slash",
      "virus-slash",
      "viruses",
      "weight-scale",
      "wheelchair",
      "wheelchair-move",
      "x-ray",
    ],
    label: "Medical + Health",
  },
  money: {
    icons: [
      "austral-sign",
      "baht-sign",
      "bitcoin",
      "bitcoin-sign",
      "brazilian-real-sign",
      "btc",
      "cash-register",
      "cedi-sign",
      "cent-sign",
      "chart-line",
      "chart-pie",
      "circle-dollar-to-slot",
      "coins",
      "colon-sign",
      "comment-dollar",
      "comments-dollar",
      "credit-card",
      "cruzeiro-sign",
      "dollar-sign",
      "dong-sign",
      "ethereum",
      "euro-sign",
      "file-invoice",
      "file-invoice-dollar",
      "florin-sign",
      "franc-sign",
      "gg",
      "gg-circle",
      "guarani-sign",
      "hand-holding-dollar",
      "hryvnia-sign",
      "indian-rupee-sign",
      "kip-sign",
      "landmark",
      "lari-sign",
      "lira-sign",
      "litecoin-sign",
      "manat-sign",
      "mill-sign",
      "money-bill",
      "money-bill-1",
      "money-bill-1-wave",
      "money-bill-transfer",
      "money-bill-trend-up",
      "money-bill-wave",
      "money-bill-wheat",
      "money-bills",
      "money-check",
      "money-check-dollar",
      "naira-sign",
      "percent",
      "peseta-sign",
      "peso-sign",
      "piggy-bank",
      "receipt",
      "ruble-sign",
      "rupee-sign",
      "rupiah-sign",
      "sack-dollar",
      "sack-xmark",
      "scale-balanced",
      "scale-unbalanced",
      "scale-unbalanced-flip",
      "shekel-sign",
      "stamp",
      "sterling-sign",
      "tenge-sign",
      "turkish-lira-sign",
      "vault",
      "wallet",
      "won-sign",
      "yen-sign",
    ],
    label: "Money",
  },
  moving: {
    icons: [
      "box-archive",
      "box-open",
      "boxes-packing",
      "caravan",
      "couch",
      "dolly",
      "house-chimney",
      "people-carry-box",
      "route",
      "sign-hanging",
      "suitcase",
      "tape",
      "trailer",
      "truck-moving",
      "truck-ramp-box",
      "wine-glass",
    ],
    label: "Moving",
  },
  "music-audio": {
    icons: [
      "compact-disc",
      "drum",
      "drum-steelpan",
      "file-audio",
      "guitar",
      "headphones",
      "headphones-simple",
      "microphone",
      "microphone-lines",
      "microphone-lines-slash",
      "microphone-slash",
      "music",
      "napster",
      "radio",
      "record-vinyl",
      "sliders",
      "soundcloud",
      "spotify",
      "volume-high",
      "volume-low",
      "volume-off",
      "volume-xmark",
    ],
    label: "Music + Audio",
  },
  nature: {
    icons: [
      "binoculars",
      "bug",
      "bugs",
      "cannabis",
      "cloud-sun",
      "clover",
      "feather",
      "feather-pointed",
      "fire",
      "frog",
      "icicles",
      "leaf",
      "locust",
      "mosquito",
      "mound",
      "mountain",
      "mountain-city",
      "mountain-sun",
      "person-hiking",
      "plant-wilt",
      "seedling",
      "signs-post",
      "spider",
      "tree",
      "volcano",
      "water",
      "wind",
      "worm",
    ],
    label: "Nature",
  },
  numbers: {
    icons: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
    label: "Numbers",
  },
  "photos-images": {
    icons: [
      "bolt",
      "bolt-lightning",
      "camera",
      "camera-retro",
      "camera-rotate",
      "chalkboard",
      "circle-half-stroke",
      "clone",
      "droplet",
      "eye",
      "eye-dropper",
      "eye-slash",
      "file-image",
      "film",
      "id-badge",
      "id-card",
      "image",
      "image-portrait",
      "images",
      "minimize",
      "panorama",
      "photo-film",
      "sliders",
      "unsplash",
    ],
    label: "Photos + Images",
  },
  political: {
    icons: [
      "award",
      "building-flag",
      "bullhorn",
      "check-double",
      "check-to-slot",
      "circle-dollar-to-slot",
      "democrat",
      "dove",
      "dumpster-fire",
      "flag-usa",
      "hand-fist",
      "handshake",
      "landmark-dome",
      "landmark-flag",
      "person-booth",
      "piggy-bank",
      "republican",
      "scale-balanced",
      "scale-unbalanced",
      "scale-unbalanced-flip",
    ],
    label: "Political",
  },
  "punctuation-symbols": {
    icons: [
      "asterisk",
      "at",
      "check",
      "check-double",
      "circle-exclamation",
      "circle-question",
      "equals",
      "exclamation",
      "greater-than",
      "hashtag",
      "less-than",
      "minus",
      "percent",
      "plus",
      "question",
      "quote-left",
      "quote-right",
      "section",
    ],
    label: "Punctuation + Symbols",
  },
  religion: {
    icons: [
      "ankh",
      "atom",
      "bahai",
      "book-bible",
      "book-journal-whills",
      "book-quran",
      "church",
      "cross",
      "dharmachakra",
      "dove",
      "gopuram",
      "hamsa",
      "hands-praying",
      "hanukiah",
      "jedi",
      "kaaba",
      "khanda",
      "menorah",
      "mosque",
      "om",
      "peace",
      "person-praying",
      "place-of-worship",
      "scroll-torah",
      "spaghetti-monster-flying",
      "star-and-crescent",
      "star-of-david",
      "synagogue",
      "torii-gate",
      "vihara",
      "yin-yang",
    ],
    label: "Religion",
  },
  science: {
    icons: [
      "atom",
      "biohazard",
      "brain",
      "capsules",
      "circle-radiation",
      "clipboard-check",
      "disease",
      "dna",
      "eye-dropper",
      "filter",
      "fire",
      "fire-flame-curved",
      "fire-flame-simple",
      "flask",
      "flask-vial",
      "frog",
      "magnet",
      "microscope",
      "mortar-pestle",
      "pills",
      "prescription-bottle",
      "radiation",
      "seedling",
      "skull-crossbones",
      "square-virus",
      "syringe",
      "tablets",
      "temperature-high",
      "temperature-low",
      "vial",
      "vial-circle-check",
      "vial-virus",
      "vials",
    ],
    label: "Science",
  },
  "science-fiction": {
    icons: [
      "atom",
      "book-journal-whills",
      "explosion",
      "galactic-republic",
      "galactic-senate",
      "hand-spock",
      "jedi",
      "jedi-order",
      "old-republic",
      "robot",
      "rocket",
      "user-astronaut",
    ],
    label: "Science Fiction",
  },
  security: {
    icons: [
      "ban",
      "bug",
      "bug-slash",
      "building-lock",
      "building-shield",
      "burst",
      "car-on",
      "door-closed",
      "door-open",
      "dungeon",
      "explosion",
      "eye",
      "eye-slash",
      "file-contract",
      "file-shield",
      "file-signature",
      "fingerprint",
      "gun",
      "handcuffs",
      "hands-bound",
      "hands-holding-child",
      "hands-holding-circle",
      "house-fire",
      "house-lock",
      "id-badge",
      "id-card",
      "id-card-clip",
      "key",
      "land-mine-on",
      "lock",
      "lock-open",
      "mars-and-venus-burst",
      "mask",
      "passport",
      "people-pulling",
      "people-robbery",
      "person-burst",
      "person-dress-burst",
      "person-falling-burst",
      "person-harassing",
      "person-military-pointing",
      "person-military-rifle",
      "person-military-to-person",
      "person-rifle",
      "person-shelter",
      "person-through-window",
      "road-spikes",
      "shield",
      "shield-cat",
      "shield-dog",
      "shield-halved",
      "shield-heart",
      "skull-crossbones",
      "square-person-confined",
      "tower-observation",
      "unlock",
      "unlock-keyhole",
      "user-lock",
      "user-secret",
      "user-shield",
      "vault",
    ],
    label: "Security",
  },
  shapes: {
    icons: [
      "bookmark",
      "burst",
      "calendar",
      "certificate",
      "circle",
      "cloud",
      "clover",
      "comment",
      "crown",
      "cubes-stacked",
      "diamond",
      "file",
      "folder",
      "heart",
      "heart-crack",
      "lines-leaning",
      "location-pin",
      "play",
      "shapes",
      "shield",
      "square",
      "star",
      "ticket-simple",
    ],
    label: "Shapes",
  },
  shopping: {
    icons: [
      "alipay",
      "amazon-pay",
      "apple-pay",
      "bag-shopping",
      "barcode",
      "basket-shopping",
      "bell",
      "bitcoin",
      "bookmark",
      "btc",
      "bullhorn",
      "camera",
      "camera-retro",
      "cart-arrow-down",
      "cart-plus",
      "cart-shopping",
      "cash-register",
      "cc-amazon-pay",
      "cc-amex",
      "cc-apple-pay",
      "cc-diners-club",
      "cc-discover",
      "cc-jcb",
      "cc-mastercard",
      "cc-paypal",
      "cc-stripe",
      "cc-visa",
      "certificate",
      "credit-card",
      "ethereum",
      "gem",
      "gift",
      "gifts",
      "google-pay",
      "google-wallet",
      "handshake",
      "heart",
      "key",
      "money-check",
      "money-check-dollar",
      "nfc-directional",
      "nfc-symbol",
      "paypal",
      "person-booth",
      "receipt",
      "shirt",
      "shop",
      "shop-lock",
      "shop-slash",
      "star",
      "store",
      "store-slash",
      "stripe",
      "stripe-s",
      "tag",
      "tags",
      "thumbs-down",
      "thumbs-up",
      "trophy",
      "truck",
      "truck-fast",
    ],
    label: "Shopping",
  },
  social: {
    icons: [
      "bell",
      "cake-candles",
      "camera",
      "circle-user",
      "comment",
      "envelope",
      "hashtag",
      "heart",
      "icons",
      "image",
      "images",
      "location-dot",
      "location-pin",
      "message",
      "photo-film",
      "retweet",
      "share",
      "share-from-square",
      "share-nodes",
      "square-poll-horizontal",
      "square-poll-vertical",
      "square-share-nodes",
      "star",
      "thumbs-down",
      "thumbs-up",
      "thumbtack",
      "user",
      "user-group",
      "user-plus",
      "users",
      "video",
    ],
    label: "Social",
  },
  spinners: {
    icons: [
      "arrows-spin",
      "asterisk",
      "atom",
      "bahai",
      "certificate",
      "circle-notch",
      "compact-disc",
      "compass",
      "crosshairs",
      "dharmachakra",
      "fan",
      "gear",
      "hurricane",
      "life-ring",
      "palette",
      "ring",
      "rotate",
      "slash",
      "snowflake",
      "spinner",
      "stroopwafel",
      "sun",
      "yin-yang",
    ],
    label: "Spinners",
  },
  "sports-fitness": {
    icons: [
      "baseball",
      "baseball-bat-ball",
      "basketball",
      "bicycle",
      "bowling-ball",
      "broom-ball",
      "dumbbell",
      "fire-flame-curved",
      "fire-flame-simple",
      "football",
      "futbol",
      "golf-ball-tee",
      "heart",
      "heart-pulse",
      "hockey-puck",
      "medal",
      "mound",
      "person-biking",
      "person-hiking",
      "person-running",
      "person-skating",
      "person-skiing",
      "person-skiing-nordic",
      "person-snowboarding",
      "person-swimming",
      "person-walking",
      "ranking-star",
      "shoe-prints",
      "spa",
      "stopwatch-20",
      "table-tennis-paddle-ball",
      "volleyball",
      "weight-hanging",
    ],
    label: "Sports + Fitness",
  },
  "text-formatting": {
    icons: [
      "align-center",
      "align-justify",
      "align-left",
      "align-right",
      "bold",
      "border-all",
      "border-none",
      "border-top-left",
      "check",
      "check-double",
      "circle-check",
      "filter-circle-xmark",
      "font",
      "heading",
      "highlighter",
      "i-cursor",
      "icons",
      "indent",
      "italic",
      "list",
      "list-check",
      "list-ol",
      "list-ul",
      "outdent",
      "paragraph",
      "rectangle-list",
      "spell-check",
      "square-check",
      "strikethrough",
      "subscript",
      "superscript",
      "table",
      "table-cells",
      "table-cells-large",
      "table-columns",
      "table-list",
      "text-height",
      "text-slash",
      "text-width",
      "underline",
    ],
    label: "Text Formatting",
  },
  time: {
    icons: [
      "bell",
      "bell-slash",
      "calendar",
      "calendar-check",
      "calendar-day",
      "calendar-days",
      "calendar-minus",
      "calendar-plus",
      "calendar-week",
      "calendar-xmark",
      "clock",
      "hourglass",
      "hourglass-empty",
      "hourglass-end",
      "hourglass-start",
      "stopwatch",
      "stopwatch-20",
    ],
    label: "Time",
  },
  toggle: {
    icons: [
      "bullseye",
      "circle",
      "circle-check",
      "circle-dot",
      "location-crosshairs",
      "microphone",
      "microphone-slash",
      "plane-up",
      "signal",
      "star",
      "star-half",
      "star-half-stroke",
      "toggle-off",
      "toggle-on",
      "wifi",
    ],
    label: "Toggle",
  },
  transportation: {
    icons: [
      "accessible-icon",
      "baby-carriage",
      "bicycle",
      "bus",
      "bus-simple",
      "car",
      "car-burst",
      "car-rear",
      "car-side",
      "car-tunnel",
      "cart-shopping",
      "ferry",
      "helicopter",
      "horse",
      "jet-fighter",
      "jet-fighter-up",
      "motorcycle",
      "mound",
      "paper-plane",
      "plane",
      "plane-slash",
      "plane-up",
      "road",
      "road-barrier",
      "road-spikes",
      "rocket",
      "sailboat",
      "ship",
      "shuttle-space",
      "sleigh",
      "snowplow",
      "taxi",
      "tractor",
      "train",
      "train-subway",
      "train-tram",
      "truck",
      "truck-arrow-right",
      "truck-droplet",
      "truck-field",
      "truck-field-un",
      "truck-front",
      "truck-medical",
      "truck-monster",
      "truck-pickup",
      "truck-plane",
      "van-shuttle",
      "wheelchair",
      "wheelchair-move",
    ],
    label: "Transportation",
  },
  "travel-hotel": {
    icons: [
      "archway",
      "baby-carriage",
      "ban-smoking",
      "bath",
      "bed",
      "bell-concierge",
      "book-atlas",
      "briefcase",
      "bus",
      "bus-simple",
      "car",
      "caravan",
      "cart-flatbed-suitcase",
      "dice",
      "dice-five",
      "door-closed",
      "door-open",
      "dumbbell",
      "earth-africa",
      "earth-americas",
      "earth-asia",
      "earth-europe",
      "earth-oceania",
      "elevator",
      "hot-tub-person",
      "hotel",
      "infinity",
      "key",
      "kitchen-set",
      "map",
      "map-location",
      "map-location-dot",
      "martini-glass",
      "martini-glass-citrus",
      "martini-glass-empty",
      "monument",
      "mountain-city",
      "mug-saucer",
      "passport",
      "person-swimming",
      "person-walking-luggage",
      "plane",
      "plane-arrival",
      "plane-circle-check",
      "plane-circle-exclamation",
      "plane-circle-xmark",
      "plane-departure",
      "plane-lock",
      "plane-slash",
      "plane-up",
      "shower",
      "smoking",
      "snowflake",
      "spa",
      "stairs",
      "suitcase",
      "suitcase-rolling",
      "taxi",
      "toilet",
      "train-tram",
      "tree-city",
      "tv",
      "umbrella-beach",
      "utensils",
      "van-shuttle",
      "water-ladder",
      "wheelchair",
      "wheelchair-move",
      "wifi",
      "wine-glass",
      "wine-glass-empty",
    ],
    label: "Travel + Hotel",
  },
  "users-people": {
    icons: [
      "accessible-icon",
      "address-book",
      "address-card",
      "arrows-down-to-people",
      "baby",
      "bed",
      "chalkboard-user",
      "child",
      "child-dress",
      "child-reaching",
      "children",
      "circle-user",
      "clipboard-user",
      "elevator",
      "face-frown",
      "face-meh",
      "face-smile",
      "head-side-cough",
      "head-side-cough-slash",
      "head-side-mask",
      "head-side-virus",
      "hospital-user",
      "hot-tub-person",
      "house-chimney-user",
      "house-user",
      "id-badge",
      "id-card",
      "id-card-clip",
      "image-portrait",
      "mars-and-venus-burst",
      "people-arrows-left-right",
      "people-carry-box",
      "people-group",
      "people-line",
      "people-pulling",
      "people-robbery",
      "people-roof",
      "person",
      "person-arrow-down-to-line",
      "person-arrow-up-from-line",
      "person-biking",
      "person-booth",
      "person-breastfeeding",
      "person-burst",
      "person-cane",
      "person-chalkboard",
      "person-circle-check",
      "person-circle-exclamation",
      "person-circle-minus",
      "person-circle-plus",
      "person-circle-question",
      "person-circle-xmark",
      "person-digging",
      "person-dots-from-line",
      "person-dress",
      "person-dress-burst",
      "person-drowning",
      "person-falling",
      "person-falling-burst",
      "person-half-dress",
      "person-harassing",
      "person-hiking",
      "person-military-pointing",
      "person-military-rifle",
      "person-military-to-person",
      "person-praying",
      "person-pregnant",
      "person-rays",
      "person-rifle",
      "person-running",
      "person-shelter",
      "person-skating",
      "person-skiing",
      "person-skiing-nordic",
      "person-snowboarding",
      "person-swimming",
      "person-through-window",
      "person-walking",
      "person-walking-arrow-loop-left",
      "person-walking-arrow-right",
      "person-walking-dashed-line-arrow-right",
      "person-walking-luggage",
      "person-walking-with-cane",
      "poo",
      "restroom",
      "skull",
      "square-person-confined",
      "street-view",
      "user",
      "user-astronaut",
      "user-check",
      "user-clock",
      "user-doctor",
      "user-gear",
      "user-graduate",
      "user-group",
      "user-injured",
      "user-large",
      "user-large-slash",
      "user-lock",
      "user-minus",
      "user-ninja",
      "user-nurse",
      "user-pen",
      "user-plus",
      "user-secret",
      "user-shield",
      "user-slash",
      "user-tag",
      "user-tie",
      "user-xmark",
      "users",
      "users-between-lines",
      "users-gear",
      "users-line",
      "users-rays",
      "users-rectangle",
      "users-slash",
      "users-viewfinder",
      "wheelchair",
      "wheelchair-move",
    ],
    label: "Users + People",
  },
  weather: {
    icons: [
      "bolt",
      "bolt-lightning",
      "cloud",
      "cloud-bolt",
      "cloud-meatball",
      "cloud-moon",
      "cloud-moon-rain",
      "cloud-rain",
      "cloud-showers-heavy",
      "cloud-showers-water",
      "cloud-sun",
      "cloud-sun-rain",
      "house-tsunami",
      "hurricane",
      "icicles",
      "meteor",
      "moon",
      "poo-storm",
      "rainbow",
      "smog",
      "snowflake",
      "sun",
      "sun-plant-wilt",
      "temperature-arrow-down",
      "temperature-arrow-up",
      "temperature-empty",
      "temperature-full",
      "temperature-half",
      "temperature-high",
      "temperature-low",
      "temperature-quarter",
      "temperature-three-quarters",
      "tornado",
      "umbrella",
      "volcano",
      "water",
      "wind",
    ],
    label: "Weather",
  },
  writing: {
    icons: [
      "blog",
      "book",
      "book-bookmark",
      "bookmark",
      "box-archive",
      "envelope",
      "envelope-open",
      "eraser",
      "file",
      "file-lines",
      "folder",
      "folder-open",
      "keyboard",
      "newspaper",
      "note-sticky",
      "paper-plane",
      "paperclip",
      "paragraph",
      "pen",
      "pen-clip",
      "pen-to-square",
      "pencil",
      "quote-left",
      "quote-right",
      "signature",
      "square-pen",
      "thumbtack",
    ],
    label: "Writing",
  },
};
