// Safe fonts: https://www.w3schools.com/cssref/css_websafe_fonts.php

export const CssVars = `
:root {
    --bs-body-font-size: 1rem;
    --bs-body-font-weight: 400;
    --bs-body-line-height: 1.5;
    --bs-body-color: #212529;
    --bs-body-bg: #fff;
    --font-body: "Arial", sans-serif;
    --font-body-weight: 300;
    --font-headers: "Arial", sans-serif;
    --font-headers-weight: 300;
    --color-1: #e52a05;
    --color-2: #f68100;
    --color-3: #28a745;
    --color-4: #0dcaf0;
    --color-5: #ffc107;
    --color-6: #dc3545;
    --color-7: #e9ecef;
    --color-8: #212529;
    --color-9: #ced4da;
    --color-10: #6c757d;
}`;

export const CssVarsObj = {
  fontHeadersName: `Arial`,
  fontHeadersWeight: `400`,
  fontBodyName: `Arial`,
  fontBodyWeight: `400`,
  color1: "rgb(229, 42, 5)",
  color2: "rgb(246, 129, 0)",
  color3: "rgb(40, 167, 69)",
  color4: "rgb(13, 202, 240)",
  color5: "rgb(255, 193, 7)",
  color6: "rgb(220, 53, 69)",
  color7: "rgb(233, 236, 239)",
  color8: "rgb(33, 37, 41)",
  color9: "rgb(206, 212, 218)",
  color10: "rgb(108, 117, 125)",
};

export const compileCssVarsObj = (cssVarsObj, boolMailExport = false) => {
  const {
    fontHeadersName,
    fontHeadersWeight,
    fontBodyName,
    fontBodyWeight,
    color1,
    color2,
    color3,
    color4,
    color5,
    color6,
    color7,
    color8,
    color9,
    color10,
  } = cssVarsObj;

  return boolMailExport
    ? `:root {
    --body-line-height: 1.5;
    --body-bg: rgb(255, 255, 255);
    --font-body: "${fontBodyName}", Arial, sans-serif;
    --font-body-weight: ${fontBodyWeight};
    --font-headers: "${fontHeadersName}", Arial, sans-serif;
    --font-headers-weight: ${fontHeadersWeight};
    }`
    : `@import url("/assets/css/fontawesome/css/all.css");
    :root {
        --body-line-height: 1.5;
        --body-bg: rgb(255, 255, 255);
        --font-body: "${fontBodyName}", Arial, sans-serif;
        --font-body-weight: ${fontBodyWeight};
        --font-headers: "${fontHeadersName}", Arial, sans-serif;
        --font-headers-weight: ${fontHeadersWeight};
        --color-1: ${color1};
        --color-2: ${color2};
        --color-3: ${color3};
        --color-4: ${color4};
        --color-5: ${color5};
        --color-6: ${color6};
        --color-7: ${color7};
        --color-8: ${color8};
        --color-9: ${color9};
        --color-10: ${color10};
    }`;
};
