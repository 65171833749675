import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Custom from "./formComponents/Custom";

import { getTargetElement } from "../../../../lib/domFunctions";
import { EDIT_FORM_ID_PREFIX } from "../../../../lib/editFunctions";
import { translate } from "../../../../translations/translations";

const ElementSettings = ({ eb: { selectedElement } }) => {
  const ElementId = () => {
    const [value, setValue] = useState("");

    useEffect(() => {
      let target = getTargetElement(selectedElement);
      setValue(target === null ? "" : target.id);
      // eslint-disable-next-line
    }, []);

    const onType = (val) => {
      // 1st char needs to be a letter
      // no spaces/special chars
      setValue(val.replace(/^\d/, "").replace(/[^A-Za-z0-9_-]/g, ""));
    };

    return (
      <>
        <input
          type="text"
          className="form-control form-control-sm"
          id={`${EDIT_FORM_ID_PREFIX}elementId`}
          value={value}
          onChange={(e) => onType(e.target.value)}
        />
      </>
    );
  };

  return (
    <>
      <Custom
        label={translate("cEditForms.elementId", false, null)}
        column={true}
        customComponent={ElementId}
        tooltipText={translate("cEditForms.ttElementSettings", false, null)}
      />
    </>
  );
};

ElementSettings.propTypes = {
  eb: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  eb: state.eb,
});

export default connect(mapStateToProps, null)(ElementSettings);
