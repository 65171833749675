export const cAddNewComponent = {
  buildingBlocks: {
    en: "Building blocks",
    nl: "Bouwstenen",
  },
  hero: {
    en: "Hero",
    nl: "Hero",
  },
  cards: {
    en: "Cards",
    nl: "Kaarten",
  },
  footers: {
    en: "Footers",
    nl: "Footers",
  },
  compName_buildingBlock_header: {
    en: "Building block - Header",
    nl: "Bouwsteen - Tekstkop",
  },
  compName_buildingBlock_headerLine: {
    en: "Building block - Header line",
    nl: "Bouwsteen - Koplijn",
  },
  compName_buildingBlock_paragraph: {
    en: "Building block - Paragraph",
    nl: "Bouwsteen - Paragraaf",
  },
  compName_buildingBlock_btn: {
    en: "Building block - Button",
    nl: "Bouwsteen - Knop",
  },
  compName_buildingBlock_img: {
    en: "Building block - Image",
    nl: "Bouwsteen - Afbeelding",
  },
  compName_buildingBlock_imgLink: {
    en: "Building block - Image link",
    nl: "Bouwsteen - Afbeelding als link",
  },
  compName_buildingBlock_list: {
    en: "Building block - List",
    nl: "Bouwsteen - Lijst",
  },
  compName_buildingBlock_table: {
    en: "Building block - Table",
    nl: "Bouwsteen - Tabel",
  },
  compName_buildingBlock_divider: {
    en: "Building block - Divider",
    nl: "Bouwsteen - Scheiding",
  },
  compName_buildingBlock_rowLinks1: {
    en: "Building block - Row of links (1)",
    nl: "Bouwsteen - Links (1)",
  },
  compName_buildingBlock_rowLinks2: {
    en: "Building block - Row of links (2)",
    nl: "Bouwsteen - Links (2)",
  },
  compName_buildingBlock_promoCode: {
    en: "Building block - Promo code",
    nl: "Bouwsteen - Actiecode",
  },
  compName_buildingBlock_callout: {
    en: "Building block - Call out box",
    nl: "Bouwsteen - Aandachtsbox",
  },
};
