export const template11 = {
  id: "66034c334a9158ba3030b8e6",
  name: { en: "Promotion code", nl: "Kortingscode" },
  desc: { en: "Send a promotion code", nl: "Verstuur een kortingscode" },
  categories: ["product"],
  ebContent: [
    {
      pageId: "9454724c-2ec8-4880-acde-440e7d5f3783",
      version: "A",
      components: [
        {
          componentId: "MVdGTE",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "basics-7-component-MVdGTE"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "Pl26gi",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "basics-7-col-container-MVdGTE"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "bCxRYq",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-12"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "gCKQDW",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "Oj8Hq7",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "XujLu8",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "Fd5nSk",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["basics-7-col-td-wrapper-MVdGTE"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-name", value: "divider" },
                                    { property: "data-editable", value: "true" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "Ckl2cZ",
                                      type: "",
                                      htmlTagName: "div",
                                      classes: ["basics-7-divider-MVdGTE"],
                                      styles: [],
                                      attributes: [{ property: "data-elementgetter1", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "gdZl5Y",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "basics-13-component-gdZl5Y"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "aljuaW",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "basics-13-col-container-gdZl5Y"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "RTEgfk",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-12"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "Vot3PR",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "WwUtzL",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "mD2dYN",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "CzHhjR",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["basics-13-col-td-wrapper-gdZl5Y"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "YK8HwF",
                                      type: "",
                                      htmlTagName: "a",
                                      classes: ["basics-13-linkwrapper-gdZl5Y"],
                                      styles: [],
                                      attributes: [
                                        { property: "href", value: "#!" },
                                        { property: "data-href", value: "#!" },
                                        { property: "data-target", value: "_blank" },
                                        { property: "data-linkwrapper", value: "true" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "DCu4TM",
                                          type: "",
                                          htmlTagName: "img",
                                          classes: ["basics-13-img-gdZl5Y"],
                                          styles: [],
                                          attributes: [
                                            { property: "data-editable", value: "true" },
                                            { property: "data-name", value: "img" },
                                            { property: "src", value: "https://cdn.satonda.com/eb/assets/logo-companyname-orbitron-black.png" },
                                            { property: "alt", value: "Image description" },
                                          ],
                                          content: "",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "CFEP2W",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "basics-1-component-CFEP2W"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "eUlG8m",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "basics-1-col-container-CFEP2W"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "JKsyMl",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-12"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "WMNGdp",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "xuSL3N",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "E2pUeQ",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "SDThvK",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["basics-1-col-td-wrapper-CFEP2W"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "XBGdZW",
                                      type: "",
                                      htmlTagName: "h1",
                                      classes: ["basics-1-title-CFEP2W"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "h" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Shop now and get 20% off!",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "RyPQsd",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "basics-2-component-RyPQsd"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "b0eQNq",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "basics-2-col-container-RyPQsd"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "YDgZJn",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-12"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "B9lnfH",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "cHfSST",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "dNg8tY",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "yBjmgl",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["basics-2-col-td-wrapper-RyPQsd"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "xXtxQn",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["basics-2-text-RyPQsd"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content:
                                            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et magna aliqua. Sit amet porttitor eget dolor morbi non arcu.",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "H0WPdF",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "basics-2-component-H0WPdF"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "o3M4DE",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "basics-2-col-container-H0WPdF"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "rQmxQ2",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-12"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "FVPviR",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "ZvLKFu",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "Bf3Q47",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "k2frVG",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["basics-2-col-td-wrapper-H0WPdF"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "fkkyq9",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["basics-2-text-H0WPdF"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Use promo code during checkout:",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "XnGiio",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "basics-11-component-XnGiio"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "EUcjvR",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "basics-11-col-container-XnGiio"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "eDX1Tx",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-12"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "ulR3wC",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "kn5Xsb",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "IurUXz",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "TJJXt8",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["basics-11-col-td-wrapper-XnGiio"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "FOkuPO",
                                      type: "",
                                      htmlTagName: "div",
                                      classes: ["basics-11-bgdiv-XnGiio"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-name", value: "bgdiv" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "hoQfL5",
                                          type: "",
                                          htmlTagName: "p",
                                          classes: ["basics-11-text-XnGiio"],
                                          styles: [],
                                          attributes: [
                                            { property: "data-editable", value: "true" },
                                            { property: "data-texteditable", value: "true" },
                                            { property: "data-name", value: "p" },
                                          ],
                                          content: "",
                                          children: [
                                            {
                                              childId: "",
                                              type: "",
                                              htmlTagName: "textNode",
                                              classes: [],
                                              styles: [],
                                              attributes: [],
                                              content: "X A 7 L 1 9 E S K",
                                              children: [],
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "OuwXeZ",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "basics-2-component-OuwXeZ"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "eNmKPM",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "basics-2-col-container-OuwXeZ"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "Z9pIot",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-12"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "eAEpqU",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "FTL13B",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "RyrlBQ",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "JrBOTU",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["basics-2-col-td-wrapper-OuwXeZ"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "MVzpLS",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["basics-2-text-OuwXeZ"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Highlighted products:",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "EU0g2l",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "cards-12-component-EU0g2l"],
          styles: [],
          attributes: [{ property: "data-name", value: "col_component" }],
          content: "",
          children: [
            {
              childId: "zfRDeW",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "cards-12-col-container-EU0g2l"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "SeDsRZ",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-4", "vertical-align-top"],
                  styles: [],
                  attributes: [{ property: "data-colLayout", value: "true" }],
                  content: "",
                  children: [
                    {
                      childId: "qNeT4S",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "leUr7z",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "eO8LLy",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "w4K1zA",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["cards-12-col-td-wrapper-EU0g2l"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "oGoWGf",
                                      type: "",
                                      htmlTagName: "img",
                                      classes: ["cards-12-img-EU0g2l"],
                                      styles: [],
                                      attributes: [
                                        { property: "src", value: "https://cdn.satonda.com/eb/assets/product2.jpg" },
                                        { property: "alt", value: "Image description" },
                                        { property: "data-editable", value: "true" },
                                        { property: "data-name", value: "img" },
                                      ],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "DsrqQv",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-8", "vertical-align-top"],
                  styles: [],
                  attributes: [{ property: "data-colLayout", value: "true" }],
                  content: "",
                  children: [
                    {
                      childId: "Ry1Wft",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "cmWtHr",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "W5iFGv",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "QoHuj1",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["cards-12-col-td-wrapper-EU0g2l"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "VidVSG",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["cards-12-title-EU0g2l"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Bottle",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "L85Vzo",
                                      type: "",
                                      htmlTagName: "div",
                                      classes: ["cards-12-rating-wrapper-EU0g2l"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-name", value: "ratingStars" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "bhns8R",
                                          type: "",
                                          htmlTagName: "img",
                                          classes: ["cards-12-rating-star-EU0g2l"],
                                          styles: [],
                                          attributes: [
                                            { property: "src", value: "https://cdn.satonda.com/eb/assets/star-full.png" },
                                            { property: "data-checkparent", value: "true" },
                                            { property: "data-name", value: "img" },
                                          ],
                                          content: "",
                                          children: [],
                                        },
                                        {
                                          childId: "iwqCl1",
                                          type: "",
                                          htmlTagName: "img",
                                          classes: ["cards-12-rating-star-EU0g2l"],
                                          styles: [],
                                          attributes: [
                                            { property: "src", value: "https://cdn.satonda.com/eb/assets/star-full.png" },
                                            { property: "data-checkparent", value: "true" },
                                            { property: "data-name", value: "img" },
                                          ],
                                          content: "",
                                          children: [],
                                        },
                                        {
                                          childId: "Qi1OSS",
                                          type: "",
                                          htmlTagName: "img",
                                          classes: ["cards-12-rating-star-EU0g2l"],
                                          styles: [],
                                          attributes: [
                                            { property: "src", value: "https://cdn.satonda.com/eb/assets/star-full.png" },
                                            { property: "data-checkparent", value: "true" },
                                            { property: "data-name", value: "img" },
                                          ],
                                          content: "",
                                          children: [],
                                        },
                                        {
                                          childId: "WRr05v",
                                          type: "",
                                          htmlTagName: "img",
                                          classes: ["cards-12-rating-star-EU0g2l"],
                                          styles: [],
                                          attributes: [
                                            { property: "src", value: "https://cdn.satonda.com/eb/assets/star-full.png" },
                                            { property: "data-checkparent", value: "true" },
                                            { property: "data-name", value: "img" },
                                          ],
                                          content: "",
                                          children: [],
                                        },
                                        {
                                          childId: "DZSfI6",
                                          type: "",
                                          htmlTagName: "img",
                                          classes: ["cards-12-rating-star-EU0g2l"],
                                          styles: [],
                                          attributes: [
                                            { property: "src", value: "https://cdn.satonda.com/eb/assets/star-empty.png" },
                                            { property: "data-checkparent", value: "true" },
                                            { property: "data-name", value: "img" },
                                          ],
                                          content: "",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "u9KByd",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["cards-12-desc-EU0g2l"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Lorem ipsum dolor sit, amet consectetur adipisicing elit.",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "gD033O",
                                      type: "",
                                      htmlTagName: "a",
                                      classes: ["cards-12-btn-EU0g2l"],
                                      styles: [],
                                      attributes: [
                                        { property: "href", value: "#!" },
                                        { property: "data-href", value: "#!" },
                                        { property: "data-target", value: "_blank" },
                                        { property: "data-editable", value: "true" },
                                        { property: "data-name", value: "button" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Order now",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "k0v3fv",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "cards-12-component-k0v3fv"],
          styles: [],
          attributes: [{ property: "data-name", value: "col_component" }],
          content: "",
          children: [
            {
              childId: "keDQsC",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "cards-12-col-container-k0v3fv"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "woPjaD",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-8", "vertical-align-top"],
                  styles: [],
                  attributes: [{ property: "data-colLayout", value: "true" }],
                  content: "",
                  children: [
                    {
                      childId: "ZsV8bT",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "NmhkvD",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "Ie7saq",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "PFX8O7",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["cards-12-col-td-wrapper-k0v3fv"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "mKtGYH",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["cards-12-title-k0v3fv"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Dress",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "rwjOIs",
                                      type: "",
                                      htmlTagName: "div",
                                      classes: ["cards-12-rating-wrapper-k0v3fv"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-name", value: "ratingStars" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "img",
                                          classes: ["cards-12-rating-star-k0v3fv"],
                                          styles: [],
                                          attributes: [
                                            { property: "src", value: "https://cdn.satonda.com/eb/assets/star-full.png" },
                                            { property: "data-checkparent", value: "true" },
                                            { property: "data-name", value: "img" },
                                          ],
                                          content: "",
                                          children: [],
                                        },
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "img",
                                          classes: ["cards-12-rating-star-k0v3fv"],
                                          styles: [],
                                          attributes: [
                                            { property: "src", value: "https://cdn.satonda.com/eb/assets/star-full.png" },
                                            { property: "data-checkparent", value: "true" },
                                            { property: "data-name", value: "img" },
                                          ],
                                          content: "",
                                          children: [],
                                        },
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "img",
                                          classes: ["cards-12-rating-star-k0v3fv"],
                                          styles: [],
                                          attributes: [
                                            { property: "src", value: "https://cdn.satonda.com/eb/assets/star-full.png" },
                                            { property: "data-checkparent", value: "true" },
                                            { property: "data-name", value: "img" },
                                          ],
                                          content: "",
                                          children: [],
                                        },
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "img",
                                          classes: ["cards-12-rating-star-k0v3fv"],
                                          styles: [],
                                          attributes: [
                                            { property: "src", value: "https://cdn.satonda.com/eb/assets/star-full.png" },
                                            { property: "data-checkparent", value: "true" },
                                            { property: "data-name", value: "img" },
                                          ],
                                          content: "",
                                          children: [],
                                        },
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "img",
                                          classes: ["cards-12-rating-star-k0v3fv"],
                                          styles: [],
                                          attributes: [
                                            { property: "src", value: "https://cdn.satonda.com/eb/assets/star-half.png" },
                                            { property: "data-checkparent", value: "true" },
                                            { property: "data-name", value: "img" },
                                          ],
                                          content: "",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "yXb4sm",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["cards-12-desc-k0v3fv"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Lorem ipsum dolor sit, amet consectetur adipisicing elit.",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "UH9nAn",
                                      type: "",
                                      htmlTagName: "a",
                                      classes: ["cards-12-btn-k0v3fv"],
                                      styles: [],
                                      attributes: [
                                        { property: "href", value: "#!" },
                                        { property: "data-href", value: "#!" },
                                        { property: "data-target", value: "_blank" },
                                        { property: "data-editable", value: "true" },
                                        { property: "data-name", value: "button" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Order now",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "JbJGiZ",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-4", "vertical-align-top"],
                  styles: [],
                  attributes: [{ property: "data-colLayout", value: "true" }],
                  content: "",
                  children: [
                    {
                      childId: "zSd2mw",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "CcScxI",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "fAoHRA",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "P8MyaL",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["cards-12-col-td-wrapper-k0v3fv"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "GHGZUa",
                                      type: "",
                                      htmlTagName: "img",
                                      classes: ["cards-12-img-k0v3fv"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-name", value: "img" },
                                        { property: "src", value: "https://cdn.satonda.com/eb/assets/product1.jpg" },
                                        { property: "alt", value: "Image description" },
                                      ],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "FMJM3i",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "footer-1-component-FMJM3i"],
          styles: [],
          attributes: [{ property: "data-name", value: "col_component" }],
          content: "",
          children: [
            {
              childId: "mQnjr4",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "footer-1-col-container-FMJM3i"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "P2FjDx",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-6", "vertical-align-middle"],
                  styles: [],
                  attributes: [{ property: "data-colLayout", value: "true" }],
                  content: "",
                  children: [
                    {
                      childId: "utqQ03",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "Wlluu0",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "APttm7",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "vtBXJE",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["footer-1-col-td-wrapper-FMJM3i"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "d0y4SR",
                                      type: "block",
                                      htmlTagName: "div",
                                      classes: ["footer-1-wrapper-icons-FMJM3i"],
                                      styles: [],
                                      attributes: [{ property: "data-name", value: "block" }],
                                      content: "",
                                      children: [
                                        {
                                          childId: "f9nXUt",
                                          type: "element",
                                          htmlTagName: "a",
                                          classes: ["footer-1-social-link-FMJM3i"],
                                          styles: [],
                                          attributes: [
                                            { property: "href", value: "#!" },
                                            { property: "data-href", value: "#!" },
                                            { property: "data-target", value: "_blank" },
                                            { property: "data-editable", value: "true" },
                                            { property: "data-name", value: "socialIcon" },
                                          ],
                                          content: "",
                                          children: [
                                            {
                                              childId: "nOs8hZ",
                                              type: "",
                                              htmlTagName: "img",
                                              classes: ["footer-1-social-icon-FMJM3i"],
                                              styles: [],
                                              attributes: [
                                                { property: "src", value: "https://cdn.satonda.com/eb/assets/facebook-brand-white-square.png" },
                                                { property: "alt", value: "Facebook" },
                                                { property: "data-editable", value: "false" },
                                              ],
                                              content: "",
                                              children: [],
                                            },
                                          ],
                                        },
                                        {
                                          childId: "oMVr8k",
                                          type: "element",
                                          htmlTagName: "a",
                                          classes: ["footer-1-social-link-FMJM3i"],
                                          styles: [],
                                          attributes: [
                                            { property: "href", value: "#!" },
                                            { property: "data-href", value: "#!" },
                                            { property: "data-target", value: "_blank" },
                                            { property: "data-editable", value: "true" },
                                            { property: "data-name", value: "socialIcon" },
                                          ],
                                          content: "",
                                          children: [
                                            {
                                              childId: "XtLEpG",
                                              type: "",
                                              htmlTagName: "img",
                                              classes: ["footer-1-social-icon-FMJM3i"],
                                              styles: [],
                                              attributes: [
                                                { property: "src", value: "https://cdn.satonda.com/eb/assets/twitter-brand-white-square.png" },
                                                { property: "alt", value: "Twitter" },
                                                { property: "data-editable", value: "false" },
                                              ],
                                              content: "",
                                              children: [],
                                            },
                                          ],
                                        },
                                        {
                                          childId: "SNvsHK",
                                          type: "element",
                                          htmlTagName: "a",
                                          classes: ["footer-1-social-link-FMJM3i"],
                                          styles: [],
                                          attributes: [
                                            { property: "href", value: "#!" },
                                            { property: "data-href", value: "#!" },
                                            { property: "data-target", value: "_blank" },
                                            { property: "data-editable", value: "true" },
                                            { property: "data-name", value: "socialIcon" },
                                          ],
                                          content: "",
                                          children: [
                                            {
                                              childId: "MriR2k",
                                              type: "",
                                              htmlTagName: "img",
                                              classes: ["footer-1-social-icon-FMJM3i"],
                                              styles: [],
                                              attributes: [
                                                { property: "src", value: "https://cdn.satonda.com/eb/assets/youtube-brand-white-square.png" },
                                                { property: "alt", value: "Youtube" },
                                                { property: "data-editable", value: "false" },
                                              ],
                                              content: "",
                                              children: [],
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "WuQwgU",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-6", "vertical-align-middle"],
                  styles: [],
                  attributes: [{ property: "data-colLayout", value: "true" }],
                  content: "",
                  children: [
                    {
                      childId: "nUGn6E",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "CiOibz",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "uQQxXX",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "TWZ9DA",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["footer-1-col-td-wrapper-FMJM3i"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "TOTEO0",
                                      type: "block",
                                      htmlTagName: "div",
                                      classes: ["footer-1-wrapper-links-FMJM3i"],
                                      styles: [],
                                      attributes: [{ property: "data-name", value: "block" }],
                                      content: "",
                                      children: [
                                        {
                                          childId: "Ip0RbU",
                                          type: "element",
                                          htmlTagName: "a",
                                          classes: ["footer-1-footer-link-FMJM3i"],
                                          styles: [],
                                          attributes: [
                                            { property: "href", value: "#!" },
                                            { property: "data-href", value: "#!" },
                                            { property: "data-target", value: "_blank" },
                                            { property: "data-editable", value: "true" },
                                            { property: "data-name", value: "a" },
                                          ],
                                          content: "",
                                          children: [
                                            {
                                              childId: "",
                                              type: "",
                                              htmlTagName: "textNode",
                                              classes: [],
                                              styles: [],
                                              attributes: [],
                                              content: "Link 1",
                                              children: [],
                                            },
                                          ],
                                        },
                                        {
                                          childId: "kCDqVv",
                                          type: "element",
                                          htmlTagName: "a",
                                          classes: ["footer-1-footer-link-FMJM3i"],
                                          styles: [],
                                          attributes: [
                                            { property: "href", value: "#!" },
                                            { property: "data-href", value: "#!" },
                                            { property: "data-target", value: "_blank" },
                                            { property: "data-editable", value: "true" },
                                            { property: "data-name", value: "a" },
                                          ],
                                          content: "",
                                          children: [
                                            {
                                              childId: "",
                                              type: "",
                                              htmlTagName: "textNode",
                                              classes: [],
                                              styles: [],
                                              attributes: [],
                                              content: "Link 2",
                                              children: [],
                                            },
                                          ],
                                        },
                                        {
                                          childId: "RYhRcM",
                                          type: "element",
                                          htmlTagName: "a",
                                          classes: ["footer-1-footer-link-FMJM3i"],
                                          styles: [],
                                          attributes: [
                                            { property: "href", value: "#!" },
                                            { property: "data-href", value: "#!" },
                                            { property: "data-target", value: "_blank" },
                                            { property: "data-editable", value: "true" },
                                            { property: "data-name", value: "a" },
                                          ],
                                          content: "",
                                          children: [
                                            {
                                              childId: "",
                                              type: "",
                                              htmlTagName: "textNode",
                                              classes: [],
                                              styles: [],
                                              attributes: [],
                                              content: "Link 3",
                                              children: [],
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "d6bFKc",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "footer-9-component-d6bFKc"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "Eq9k3O",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "footer-9-col-container-d6bFKc"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "VDbOtR",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-12"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "aX55rq",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "MXaB3i",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "E39K2X",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "aaEEH8",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["footer-9-col-td-wrapper-d6bFKc"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "GJ7Qqx",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["footer-9-text1-d6bFKc"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                        { property: "data-uniqueclassname", value: "true" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "© Company name",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "DTUkQN",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["footer-9-text2-d6bFKc"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                        { property: "data-uniqueclassname", value: "true" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Streetname 1, 1000 XX Amsterdam, The Netherlands",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "km964j",
                                      type: "element",
                                      htmlTagName: "a",
                                      classes: ["footer-9-link-d6bFKc"],
                                      styles: [],
                                      attributes: [
                                        { property: "href", value: "#!" },
                                        { property: "data-href", value: "%UNSUBSCRIBE_LINK%" },
                                        { property: "data-target", value: "_blank" },
                                        { property: "data-editable", value: "true" },
                                        { property: "data-name", value: "a" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Unsubscribe",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  ebCustomCss: [
    {
      componentId: "MVdGTE",
      classes: [
        {
          className: "basics-7-component-MVdGTE",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
          ],
        },
        {
          className: "basics-7-col-container-MVdGTE",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "background-color", value: "rgb(255, 255, 255)" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "basics-7-col-td-wrapper-MVdGTE",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0rem 0rem 0rem 0rem" },
            { property: "text-align", value: "start" },
          ],
        },
        {
          className: "basics-7-divider-MVdGTE",
          pseudo: "",
          rules: [
            { property: "display", value: "inline-block" },
            { property: "height", value: "25px" },
            { property: "width", value: "100%" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
            { property: "border-radius", value: "0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(230, 230, 230)" },
          ],
        },
      ],
    },
    {
      componentId: "gdZl5Y",
      classes: [
        {
          className: "basics-13-linkwrapper-gdZl5Y",
          pseudo: "",
          rules: [
            { property: "display", value: "block" },
            { property: "line-height", value: "0 !important" },
          ],
        },
        {
          className: "basics-13-img-gdZl5Y",
          pseudo: "",
          rules: [
            { property: "outline", value: "none" },
            { property: "-ms-interpolation-mode", value: "bicubic" },
            { property: "max-width", value: "100%" },
            { property: "max-height", value: "200px" },
            { property: "width", value: "180px" },
            { property: "height", value: "auto" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "0px" },
            { property: "border-color", value: "rgb(0, 0, 0)" },
            { property: "border-radius", value: "0rem" },
          ],
        },
        {
          className: "basics-13-component-gdZl5Y",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
          ],
        },
        {
          className: "basics-13-col-container-gdZl5Y",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-top", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "background-color", value: "rgb(255, 255, 255)" },
            { property: "padding-bottom", value: "1rem" },
            { property: "border-radius", value: "1rem 1rem 0 0" },
          ],
        },
        {
          className: "basics-13-col-td-wrapper-gdZl5Y",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0.5rem 0rem 0.5rem 0rem" },
            { property: "text-align", value: "center" },
          ],
        },
      ],
    },
    {
      componentId: "CFEP2W",
      classes: [
        {
          className: "basics-1-component-CFEP2W",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
          ],
        },
        {
          className: "basics-1-col-container-CFEP2W",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "background-color", value: "rgb(255, 255, 255)" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "basics-1-col-td-wrapper-CFEP2W",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0.5rem 0.5rem 0.5rem 0.5rem" },
            { property: "text-align", value: "start" },
          ],
        },
        {
          className: "basics-1-title-CFEP2W",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgb(70, 180, 169)" },
            { property: "font-weight", value: "bold" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
          ],
        },
      ],
    },
    {
      componentId: "XnGiio",
      classes: [
        {
          className: "basics-11-bgdiv-XnGiio",
          pseudo: "",
          rules: [
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "background-color", value: "rgb(255, 255, 255)" },
            { property: "border-radius", value: "1rem" },
            { property: "border-style", value: "dashed dashed dashed dashed" },
            { property: "border-width", value: "2px" },
            { property: "border-color", value: "rgb(70, 180, 169)" },
            { property: "padding", value: "10px 20px 10px 20px" },
          ],
        },
        {
          className: "basics-11-text-XnGiio",
          pseudo: "",
          rules: [
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "text-align", value: "center" },
            { property: "font-weight", value: "bold" },
            { property: "color", value: "rgb(70, 180, 169)" },
          ],
        },
        {
          className: "basics-11-component-XnGiio",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
          ],
        },
        {
          className: "basics-11-col-container-XnGiio",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "background-color", value: "rgb(255, 255, 255)" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "basics-11-col-td-wrapper-XnGiio",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0.5rem 0.75rem 0.5rem 0.75rem" },
            { property: "text-align", value: "start" },
          ],
        },
      ],
    },
    {
      componentId: "RyPQsd",
      classes: [
        {
          className: "basics-2-component-RyPQsd",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
          ],
        },
        {
          className: "basics-2-col-container-RyPQsd",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "background-color", value: "rgb(255, 255, 255)" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "basics-2-col-td-wrapper-RyPQsd",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0.5rem 0.75rem 0.5rem 0.75rem" },
            { property: "text-align", value: "start" },
          ],
        },
        {
          className: "basics-2-text-RyPQsd",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgb(33, 37, 41)" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
          ],
        },
      ],
    },
    {
      componentId: "EU0g2l",
      classes: [
        {
          className: "cards-12-title-EU0g2l",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(70, 180, 169)" },
            { property: "margin", value: "0rem 0rem 0.5rem 0rem" },
            { property: "text-align", value: "start" },
            { property: "font-weight", value: "bold" },
          ],
        },
        {
          className: "cards-12-desc-EU0g2l",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(33, 37, 41)" },
            { property: "margin", value: "0.5rem 0rem 1rem 0rem" },
            { property: "text-align", value: "start" },
          ],
        },
        {
          className: "cards-12-btn-EU0g2l",
          pseudo: "",
          rules: [
            { property: "background", value: "rgb(70, 180, 169)" },
            { property: "color", value: "rgb(255, 255, 255)" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(70, 180, 169)" },
            { property: "border-radius", value: "1000px" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "padding", value: "0.5rem 1rem 0.5rem 1rem" },
            { property: "text-decoration", value: "none" },
            { property: "text-align", value: "center" },
            { property: "font-weight", value: "bold" },
            { property: "display", value: "inline-block" },
          ],
        },
        {
          className: "cards-12-img-EU0g2l",
          pseudo: "",
          rules: [
            { property: "outline", value: "none" },
            { property: "-ms-interpolation-mode", value: "bicubic" },
            { property: "max-width", value: "100%" },
            { property: "width", value: "100%" },
            { property: "max-height", value: "200px" },
            { property: "height", value: "auto" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        { className: "cards-12-rating-wrapper-EU0g2l", pseudo: "", rules: [{ property: "display", value: "block" }] },
        {
          className: "cards-12-rating-star-EU0g2l",
          pseudo: "",
          rules: [
            { property: "outline", value: "none" },
            { property: "-ms-interpolation-mode", value: "bicubic" },
            { property: "width", value: "auto" },
            { property: "height", value: "16px" },
            { property: "margin", value: "0rem 0.125rem 0rem 0rem" },
            { property: "display", value: "inline-block" },
          ],
        },
        {
          className: "cards-12-component-EU0g2l",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
          ],
        },
        {
          className: "cards-12-col-container-EU0g2l",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "background-color", value: "rgb(255, 255, 255)" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "cards-12-col-td-wrapper-EU0g2l",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0.5rem 0.75rem 0.5rem 0.75rem" },
            { property: "text-align", value: "start" },
          ],
        },
      ],
    },
    {
      componentId: "k0v3fv",
      classes: [
        {
          className: "cards-12-desc-k0v3fv",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(33, 37, 41)" },
            { property: "margin", value: "0.5rem 0rem 1rem 0rem" },
            { property: "text-align", value: "start" },
          ],
        },
        {
          className: "cards-12-btn-k0v3fv",
          pseudo: "",
          rules: [
            { property: "background", value: "rgb(70, 180, 169)" },
            { property: "color", value: "rgb(255, 255, 255)" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(70, 180, 169)" },
            { property: "border-radius", value: "1000px" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "padding", value: "0.5rem 1rem 0.5rem 1rem" },
            { property: "text-decoration", value: "none" },
            { property: "text-align", value: "center" },
            { property: "font-weight", value: "bold" },
            { property: "display", value: "inline-block" },
          ],
        },
        { className: "cards-12-rating-wrapper-k0v3fv", pseudo: "", rules: [{ property: "display", value: "block" }] },
        {
          className: "cards-12-rating-star-k0v3fv",
          pseudo: "",
          rules: [
            { property: "outline", value: "none" },
            { property: "-ms-interpolation-mode", value: "bicubic" },
            { property: "width", value: "auto" },
            { property: "height", value: "16px" },
            { property: "margin", value: "0rem 0.125rem 0rem 0rem" },
            { property: "display", value: "inline-block" },
          ],
        },
        {
          className: "cards-12-component-k0v3fv",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
          ],
        },
        {
          className: "cards-12-col-container-k0v3fv",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "background-color", value: "rgb(255, 255, 255)" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "cards-12-col-td-wrapper-k0v3fv",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0.5rem 0.75rem 0.5rem 0.75rem" },
            { property: "text-align", value: "start" },
          ],
        },
        {
          className: "cards-12-img-k0v3fv",
          pseudo: "",
          rules: [
            { property: "outline", value: "none" },
            { property: "-ms-interpolation-mode", value: "bicubic" },
            { property: "max-width", value: "100%" },
            { property: "max-height", value: "200px" },
            { property: "width", value: "100%" },
            { property: "height", value: "auto" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "0px" },
            { property: "border-color", value: "rgb(0, 0, 0)" },
            { property: "border-radius", value: "0rem" },
          ],
        },
        {
          className: "cards-12-title-k0v3fv",
          pseudo: "",
          rules: [
            { property: "text-align", value: "start" },
            { property: "color", value: "rgb(70, 180, 169)" },
            { property: "font-weight", value: "bold" },
            { property: "margin", value: "0rem 0rem 0.5rem 0rem" },
          ],
        },
      ],
    },
    {
      componentId: "FMJM3i",
      classes: [
        {
          className: "footer-1-wrapper-icons-FMJM3i",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "display", value: "block" },
          ],
        },
        {
          className: "footer-1-social-link-FMJM3i",
          pseudo: "",
          rules: [
            { property: "display", value: "inline-block" },
            { property: "text-decoration", value: "none" },
            { property: "margin", value: "0rem 1rem 0rem 0rem" },
          ],
        },
        { className: "footer-1-social-link-FMJM3i", pseudo: "last-child", rules: [{ property: "margin-right", value: "0" }] },
        {
          className: "footer-1-social-icon-FMJM3i",
          pseudo: "",
          rules: [
            { property: "outline", value: "none" },
            { property: "-ms-interpolation-mode", value: "bicubic" },
            { property: "width", value: "32px" },
            { property: "height", value: "32px" },
            { property: "border-radius", value: "50%" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(255, 255, 255)" },
          ],
        },
        { className: "footer-1-wrapper-links-FMJM3i", pseudo: "", rules: [{ property: "text-align", value: "center" }] },
        {
          className: "footer-1-footer-link-FMJM3i",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(245, 245, 245)" },
            { property: "margin", value: "0rem 1rem 0rem 0rem" },
            { property: "display", value: "inline-block" },
            { property: "text-decoration", value: "none" },
          ],
        },
        { className: "footer-1-footer-link-FMJM3i", pseudo: "last-child", rules: [{ property: "margin-right", value: "0" }] },
        {
          className: "footer-1-component-FMJM3i",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
          ],
        },
        {
          className: "footer-1-col-container-FMJM3i",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-top", value: "1rem" },
            { property: "padding-bottom", value: "1rem" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(50, 129, 121)" },
            { property: "background-color", value: "rgb(50, 129, 121)" },
            { property: "border-radius", value: "0 0 1rem 1rem" },
          ],
        },
        {
          className: "footer-1-col-td-wrapper-FMJM3i",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0.5rem 0rem 0.5rem 0rem" },
            { property: "text-align", value: "start" },
          ],
        },
      ],
    },
    {
      componentId: "d6bFKc",
      classes: [
        {
          className: "footer-9-text1-d6bFKc",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(108, 117, 125)" },
            { property: "margin", value: "0rem 0rem 0.25rem 0rem" },
            { property: "text-align", value: "center" },
            { property: "font-size", value: "12px" },
          ],
        },
        {
          className: "footer-9-text2-d6bFKc",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(108, 117, 125)" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-align", value: "center" },
            { property: "font-size", value: "12px" },
          ],
        },
        {
          className: "footer-9-link-d6bFKc",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(108, 117, 125)" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "display", value: "block" },
            { property: "text-align", value: "center" },
            { property: "text-decoration", value: "underline" },
            { property: "font-size", value: "12px" },
          ],
        },
        {
          className: "footer-9-component-d6bFKc",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
          ],
        },
        {
          className: "footer-9-col-container-d6bFKc",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(234, 237, 240)" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "padding-top", value: "1.5rem" },
            { property: "padding-bottom", value: "3rem" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "footer-9-col-td-wrapper-d6bFKc",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0.5rem 0rem 0.5rem 0rem" },
            { property: "text-align", value: "start" },
          ],
        },
      ],
    },
    {
      componentId: "H0WPdF",
      classes: [
        {
          className: "basics-2-text-H0WPdF",
          pseudo: "",
          rules: [
            { property: "text-align", value: "start" },
            { property: "color", value: "rgb(70, 180, 169)" },
            { property: "font-weight", value: "bold" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "basics-2-component-H0WPdF",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
          ],
        },
        {
          className: "basics-2-col-container-H0WPdF",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "background-color", value: "rgb(255, 255, 255)" },
            { property: "padding-top", value: "1rem" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "basics-2-col-td-wrapper-H0WPdF",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0.5rem 0.75rem 0.5rem 0.75rem" },
            { property: "text-align", value: "start" },
          ],
        },
      ],
    },
    {
      componentId: "OuwXeZ",
      classes: [
        {
          className: "basics-2-text-OuwXeZ",
          pseudo: "",
          rules: [
            { property: "text-align", value: "start" },
            { property: "color", value: "rgb(70, 180, 169)" },
            { property: "font-weight", value: "bold" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "basics-2-component-OuwXeZ",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
          ],
        },
        {
          className: "basics-2-col-container-OuwXeZ",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "background-color", value: "rgb(255, 255, 255)" },
            { property: "padding-top", value: "1rem" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "basics-2-col-td-wrapper-OuwXeZ",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0.5rem 0.75rem 0.5rem 0.75rem" },
            { property: "text-align", value: "start" },
          ],
        },
      ],
    },
  ],
  ebCssVars: {
    fontHeadersName: "Arial",
    fontHeadersWeight: "400",
    fontBodyName: "Arial",
    fontBodyWeight: "400",
    color1: "rgb(70, 180, 169)",
    color2: "rgb(50, 129, 121)",
    color3: "rgba(40, 167, 69, 1)",
    color4: "rgba(13, 202, 240, 1)",
    color5: "rgba(255, 193, 7, 1)",
    color6: "rgba(220, 53, 69, 1)",
    color7: "rgba(233, 236, 239, 1)",
    color8: "rgba(33, 37, 41, 1)",
    color9: "rgba(206, 212, 218, 1)",
    color10: "rgba(108, 117, 125, 1)",
  },
};
