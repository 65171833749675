import React from "react";

const Spinner = ({ fullPage, msg }) => {
  return (
    <div className={fullPage ? `vh-100 vw-100 d-flex flex-column justify-content-center align-items-center` : `text-center`}>
      <div className="spinner-border spinner-border-sm text-primary"></div>
      <p className="mb-0 mt-2 text-primary fontSize08">{msg}</p>
    </div>
  );
};

export default Spinner;
