import React from "react";

import MailFonts from "./components/MailFonts";

const ModalMailFontsBody = () => {
  return (
    <div className="modal fade" id="ModalMailFontsBody" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-hidden="true">
      <MailFonts target={"body"} />
    </div>
  );
};

export default ModalMailFontsBody;
