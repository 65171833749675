import React from "react";

import PreviewTemplate from "./PreviewTemplate";
import { translate } from "../../../translations/translations";

const AddNewComponent = () => {
  return (
    <div className="accordion accordion-flush trans-3 border rounded-4 overflow-hidden" id="accordion-AddNewComponent">
      {/* Basics */}
      <div className="accordion-item">
        <h2 className="accordion-header" id="addNewComponent-basics">
          <button
            className="accordion-button addNewComponent-accordion collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapse-addNewComponent-basics"
            aria-expanded="false"
            aria-controls="collapse-addNewComponent-basics"
          >
            {translate("cAddNewComponent.buildingBlocks", false, null)}
          </button>
        </h2>
        <div
          id="collapse-addNewComponent-basics"
          className="accordion-collapse collapse"
          aria-labelledby="addNewComponent-basics"
          data-bs-parent="#accordion-AddNewComponent"
        >
          <div className="accordion-body px-0 text-center">
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "basics-1", name: translate("cAddNewComponent.compName_buildingBlock_header", false, null) }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "basics-8", name: translate("cAddNewComponent.compName_buildingBlock_headerLine", false, null) }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "basics-2", name: translate("cAddNewComponent.compName_buildingBlock_paragraph", false, null) }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "basics-3", name: translate("cAddNewComponent.compName_buildingBlock_btn", false, null) }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "basics-4", name: translate("cAddNewComponent.compName_buildingBlock_img", false, null) }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "basics-13", name: translate("cAddNewComponent.compName_buildingBlock_imgLink", false, null) }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "basics-5", name: translate("cAddNewComponent.compName_buildingBlock_list", false, null) }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "basics-6", name: translate("cAddNewComponent.compName_buildingBlock_table", false, null) }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "basics-7", name: translate("cAddNewComponent.compName_buildingBlock_divider", false, null) }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "basics-9", name: translate("cAddNewComponent.compName_buildingBlock_rowLinks1", false, null) }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "basics-10", name: translate("cAddNewComponent.compName_buildingBlock_rowLinks2", false, null) }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "basics-11", name: translate("cAddNewComponent.compName_buildingBlock_promoCode", false, null) }} />
            </div>
            <div className="p-2">
              <PreviewTemplate component={{ id: "basics-12", name: translate("cAddNewComponent.compName_buildingBlock_callout", false, null) }} />
            </div>
          </div>
        </div>
      </div>
      {/* Hero */}
      <div className="accordion-item">
        <h2 className="accordion-header" id="addNewComponent-hero">
          <button
            className="accordion-button addNewComponent-accordion collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapse-addNewComponent-hero"
            aria-expanded="false"
            aria-controls="collapse-addNewComponent-hero"
          >
            {translate("cAddNewComponent.hero", false, null)}
          </button>
        </h2>
        <div
          id="collapse-addNewComponent-hero"
          className="accordion-collapse collapse"
          aria-labelledby="addNewComponent-hero"
          data-bs-parent="#accordion-AddNewComponent"
        >
          <div className="accordion-body px-0 text-center">
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "hero-1", name: `${translate("cAddNewComponent.hero", false, null)} 1` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "hero-2", name: `${translate("cAddNewComponent.hero", false, null)} 2` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "hero-3", name: `${translate("cAddNewComponent.hero", false, null)} 3` }} />
            </div>
            <div className="p-2">
              <PreviewTemplate component={{ id: "hero-4", name: `${translate("cAddNewComponent.hero", false, null)} 4` }} />
            </div>
          </div>
        </div>
      </div>
      {/* Cards */}
      <div className="accordion-item">
        <h2 className="accordion-header" id="addNewComponent-cards">
          <button
            className="accordion-button addNewComponent-accordion collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapse-addNewComponent-cards"
            aria-expanded="false"
            aria-controls="collapse-addNewComponent-cards"
          >
            {translate("cAddNewComponent.cards", false, null)}
          </button>
        </h2>
        <div
          id="collapse-addNewComponent-cards"
          className="accordion-collapse collapse"
          aria-labelledby="addNewComponent-cards"
          data-bs-parent="#accordion-AddNewComponent"
        >
          <div className="accordion-body px-0 text-center">
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "cards-1", name: `${translate("cAddNewComponent.cards", false, null)} 1` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "cards-2", name: `${translate("cAddNewComponent.cards", false, null)} 2` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "cards-3", name: `${translate("cAddNewComponent.cards", false, null)} 3` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "cards-4", name: `${translate("cAddNewComponent.cards", false, null)} 4` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "cards-5", name: `${translate("cAddNewComponent.cards", false, null)} 5` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "cards-6", name: `${translate("cAddNewComponent.cards", false, null)} 6` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "cards-7", name: `${translate("cAddNewComponent.cards", false, null)} 7` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "cards-8", name: `${translate("cAddNewComponent.cards", false, null)} 8` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "cards-9", name: `${translate("cAddNewComponent.cards", false, null)} 9` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "cards-10", name: `${translate("cAddNewComponent.cards", false, null)} 10` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "cards-11", name: `${translate("cAddNewComponent.cards", false, null)} 11` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "cards-12", name: `${translate("cAddNewComponent.cards", false, null)} 12` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "cards-13", name: `${translate("cAddNewComponent.cards", false, null)} 13` }} />
            </div>
          </div>
        </div>
      </div>
      {/* Footers */}
      <div className="accordion-item">
        <h2 className="accordion-header" id="addNewComponent-footer">
          <button
            className="accordion-button addNewComponent-accordion collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapse-addNewComponent-footer"
            aria-expanded="false"
            aria-controls="collapse-addNewComponent-footer"
          >
            {translate("cAddNewComponent.footers", false, null)}
          </button>
        </h2>
        <div
          id="collapse-addNewComponent-footer"
          className="accordion-collapse collapse"
          aria-labelledby="addNewComponent-footer"
          data-bs-parent="#accordion-AddNewComponent"
        >
          <div className="accordion-body px-0 text-center">
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "footer-1", name: `${translate("cAddNewComponent.footers", false, null)} 1` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "footer-2", name: `${translate("cAddNewComponent.footers", false, null)} 2` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "footer-3", name: `${translate("cAddNewComponent.footers", false, null)} 3` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "footer-4", name: `${translate("cAddNewComponent.footers", false, null)} 4` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "footer-5", name: `${translate("cAddNewComponent.footers", false, null)} 5` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "footer-6", name: `${translate("cAddNewComponent.footers", false, null)} 6` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "footer-7", name: `${translate("cAddNewComponent.footers", false, null)} 7` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "footer-8", name: `${translate("cAddNewComponent.footers", false, null)} 8` }} />
            </div>
            <div className="p-2 border-bottom">
              <PreviewTemplate component={{ id: "footer-9", name: `${translate("cAddNewComponent.footers", false, null)} 9` }} />
            </div>
            <div className="p-2">
              <PreviewTemplate component={{ id: "footer-10", name: `${translate("cAddNewComponent.footers", false, null)} 10` }} />
            </div>
            {/* <div className="p-2">
              <PreviewTemplate component={{ id: "footer-11", name: `${translate("cAddNewComponent.footers", false, null)} 11` }} />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNewComponent;
