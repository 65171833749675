import React, { useState, useEffect } from "react";
import { useParams, useSearchParams, useNavigate } from "react-router-dom";

import { templates } from "../lib/templates";

import { TEMPLATE_CATEGORIES } from "../lib/templateVars";

const BrowseTemplates = () => {
  const PARAM = "category";

  const SUPPORTED_LANGUAGES = ["en", "nl"];
  const LANGUAGES = { en: "English", nl: "Nederlands" };
  const DICTIONARY = {
    browseTemplates: {
      en: "Browse templates",
      nl: "Bekijk templates",
    },
    selectLanguage: {
      en: "Select language",
      nl: "Selecteer taal",
    },
    selectCategory: {
      en: "Select category",
      nl: "Selecteer categorie",
    },
    allCategories: {
      en: "All categories",
      nl: "Alle categorieen",
    },
    noTemplatesFound: {
      en: "No templates found for the category you selected.",
      nl: "Geen templates gevonden voor de geselecteerde categorie.",
    },
    previewTemplate: {
      en: "Preview template",
      nl: "Bekijk template",
    },
    lastTemplates: {
      en: "These are the last templates",
      nl: "Dit zijn de laatste templates",
    },
    seeAddnlTemplates: {
      en: "See additional templates",
      nl: "Bekijk meer templates",
    },
    showingTemplates: {
      en: "Showing templates",
      nl: "Templates",
    },
    of: {
      en: "of",
      nl: "van",
    },
  };

  let [searchParams, setSearchParams] = useSearchParams();
  let { langCode } = useParams();
  const navigate = useNavigate();

  const [allTemplates, setAllTemplates] = useState(templates);
  const [selectedCategory, setSelectedCategory] = useState("all");

  // Pagination
  const PER_PAGE = 12;
  const [templatesToRender, setTemplatesToRender] = useState([]);
  const [currPage, setCurrPage] = useState(0);
  const [maxPages, setMaxPages] = useState(0);

  const clickPageBtn = (inc) => {
    setCurrPage((prev) => prev + inc);
  };

  const navigateTo = (lang, cat) => {
    navigate(`/templates/browse/${lang}?category=${cat}`, { replace: true });
  };

  const setLangCode = (lang) => {
    navigateTo(lang, selectedCategory);
  };

  const setCategory = (cat) => {
    setSearchParams({ [PARAM]: cat });
  };

  useEffect(() => {
    // Redirect to english page if unsupported language is selected
    if (!SUPPORTED_LANGUAGES.includes(langCode)) {
      navigateTo("en", selectedCategory);
    }
    // eslint-disable-next-line
  }, [langCode]);

  useEffect(() => {
    const category = searchParams.get(PARAM);
    if (category === null || category === "") {
      setSelectedCategory("all");
    } else {
      setSelectedCategory(category);
    }
    // eslint-disable-next-line
  }, [searchParams]);

  useEffect(() => {
    const selectedTemplates = selectedCategory === "all" ? templates : templates.filter((template) => template.categories.includes(selectedCategory));
    const maxPagesSelectedTemplates = Math.ceil(selectedTemplates.length / PER_PAGE);
    setMaxPages(maxPagesSelectedTemplates);
    if (currPage > maxPagesSelectedTemplates) {
      setCurrPage(0);
    }
    setAllTemplates(selectedTemplates);
    setTemplatesToRender(selectedTemplates.slice(currPage * PER_PAGE, currPage * PER_PAGE + PER_PAGE));
    // eslint-disable-next-line
  }, [selectedCategory, currPage]);

  const translate = (dict) => {
    try {
      return dict[langCode];
    } catch (error) {
      return "";
    }
  };

  const SelectLanguage = () => {
    return (
      <div className="dropdown">
        <div
          id="dropdown_selectLang"
          className="dropdown-toggle cursorPointer"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          title={translate(DICTIONARY.selectLanguage)}
        >
          <img src={`/assets/img/flags/${langCode}.png`} alt={LANGUAGES[langCode]} />
        </div>
        <ul className="dropdown-menu p-0" aria-labelledby="dropdown_selectLang">
          <p className="p-2 m-0">{translate(DICTIONARY.selectLanguage)}:</p>
          {SUPPORTED_LANGUAGES.map((lang) => (
            <li key={`lang_${lang}`}>
              <div className="dropdown-item p-2 cursorPointer" onClick={() => setLangCode(lang)}>
                <img src={`/assets/img/flags/${lang}.png`} alt={LANGUAGES[lang]} />
                <span className="ms-2">{LANGUAGES[lang]}</span>
              </div>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const SelectCategory = () => {
    return (
      <div className="d-flex align-items-center mt-5">
        <div className="row row-cols-lg-auto g-3 align-items-center">
          <div className="col-12">
            <p className="m-0 me-3">{translate(DICTIONARY.selectCategory)}</p>
          </div>
          <div className="col-12">
            <select className="form-select form-select-sm" value={selectedCategory} onChange={(e) => setCategory(e.target.value)}>
              <option value="all">{translate(DICTIONARY.allCategories)}</option>
              {TEMPLATE_CATEGORIES.map((cat) => (
                <option key={cat.val} value={cat.val}>
                  {translate(cat.label)}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    );
  };

  const Template = ({ template }) => {
    return (
      <a
        className={`col-12 col-md-6 col-lg-4 p-3 card-browse-templates`}
        title={translate(DICTIONARY.previewTemplate)}
        href={`/templates/preview/${langCode}/${template.id}`}
        target="_blank"
        rel="noreferrer"
      >
        <div className="flexSameHeight">
          <img className="templatePreview" src={`/assets/templatePreviews/${template.id}.png`} alt={template.name[langCode]} />
          <div className="toGrow px-2">
            <h5>{template.name[langCode]}</h5>
            <p>{template.desc[langCode]}</p>
          </div>
        </div>
      </a>
    );
  };

  const Pagination = () => {
    return (
      <div className="d-flex justify-content-center align-items-center mt-4 mb-4">
        <button
          className="btn btn-outline-primary trans-3 me-4"
          onClick={() => clickPageBtn(-1)}
          title={currPage === 0 ? translate(DICTIONARY.lastTemplates) : translate(DICTIONARY.seeAddnlTemplates)}
          disabled={currPage === 0}
        >
          <i className="fa-solid fa-chevron-left"></i>
        </button>
        <span className="fontSize08 text-dark">
          {translate(DICTIONARY.showingTemplates)}{" "}
          <span className="text-bold">
            {Math.min(currPage * PER_PAGE + 1, allTemplates.length)} - {Math.min((currPage + 1) * PER_PAGE, allTemplates.length)}
          </span>{" "}
          {translate(DICTIONARY.of)} <span className="text-bold">{allTemplates.length}</span>
        </span>
        <button
          className="btn btn-outline-primary trans-3 ms-4"
          onClick={() => clickPageBtn(1)}
          title={currPage + 1 === maxPages ? translate(DICTIONARY.lastTemplates) : translate(DICTIONARY.seeAddnlTemplates)}
          disabled={currPage + 1 === maxPages}
        >
          <i className="fa-solid fa-chevron-right"></i>
        </button>
      </div>
    );
  };

  return (
    <div className="container">
      <div className="d-flex justify-content-between align-items-center mt-5">
        <h1 className="h3 text-bold text-primary m-0">{translate(DICTIONARY.browseTemplates)}</h1>
        <SelectLanguage />
      </div>
      <SelectCategory />
      <div className="row mt-4">
        {allTemplates.length === 0 ? (
          <p className="text-italic my-3">{translate(DICTIONARY.noTemplatesFound)}</p>
        ) : (
          templatesToRender.map((template) => <Template key={template.id} template={template} />)
        )}
      </div>
      <Pagination />
    </div>
  );
};

export default BrowseTemplates;
