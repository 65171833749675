import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Slider from "./formComponents/Slider";
import Border from "./Border";
import Divider from "./formComponents/Divider";
import Select from "./formComponents/Select";
import GradientColor from "./formComponents/GradientColor";
import AlignHori from "./AlignHori";

import { formGetStartValue } from "../../../../lib/editFunctions";
import { PADDING_VALUES } from "../../../../lib/editStyleVars";
import { translate } from "../../../../translations/translations";

const Component = ({ eb: { startStyles } }) => {
  return (
    <>
      <GradientColor
        label={translate("cEditForms.bgColorOutside", false, null)}
        arrBgVarNames={[
          "bgColor",
          "boolBgIsGradient",
          "bgGradientDirection",
          "bgGradientColor1",
          "bgGradientColor2",
          "bgGradientStop1",
          "bgGradientStop2",
        ]}
        tooltipText={translate("cEditForms.ttBgColorOutside", false, null)}
      />
      <Divider />
      <GradientColor
        label={translate("cEditForms.bgColorInside", false, null)}
        arrBgVarNames={[
          "componentBgColor",
          "componentBoolBgIsGradient",
          "componentBgGradientDirection",
          "componentBgGradientColor1",
          "componentBgGradientColor2",
          "componentBgGradientStop1",
          "componentBgGradientStop2",
        ]}
        tooltipText={translate("cEditForms.ttBgColorInside", false, null)}
      />
      <Divider />
      <Slider
        label={translate("cEditForms.spaceTop", false, null)}
        id={"componentPaddingTop"}
        min={0}
        max={10}
        step={1}
        startValue={PADDING_VALUES.indexOf(formGetStartValue(startStyles, "componentPaddingTop"))}
        tooltipText={translate("cEditForms.ttComponentSpaceTop", false, null)}
      />
      <Slider
        label={translate("cEditForms.spaceBottom", false, null)}
        id={"componentPaddingBottom"}
        min={0}
        max={10}
        step={1}
        startValue={PADDING_VALUES.indexOf(formGetStartValue(startStyles, "componentPaddingBottom"))}
        tooltipText={translate("cEditForms.ttComponentSpaceBottom", false, null)}
      />
      <Divider />
      <Border />
      <Divider />
      <Select
        label={translate("cEditForms.borderRadius", false, null)}
        id={"componentRadiusSize"}
        options={[
          { val: 0, label: translate("cEditForms.square", false, null) },
          { val: 0.2, label: translate("cEditForms.roundedSmall", false, null) },
          { val: 0.4, label: translate("cEditForms.roundedMedium", false, null) },
          { val: 0.75, label: translate("cEditForms.roundedLarge", false, null) },
          { val: 1, label: translate("cEditForms.roundedExtraLarge", false, null) },
          { val: 1000, label: translate("cEditForms.pill", false, null) },
          { val: 50, label: translate("cEditForms.circle", false, null) },
        ]}
        startValue={formGetStartValue(startStyles, "componentRadiusSize")}
        tooltipText={translate("cEditForms.ttComponentBorderRadius", false, null)}
      />
      <Select
        label={translate("cEditForms.borderRadiusSides", false, null)}
        id={"componentRadiusSide"}
        options={[
          { val: "all", label: translate("cEditForms.allSides", false, null) },
          { val: "top", label: translate("cEditForms.top", false, null) },
          { val: "bottom", label: translate("cEditForms.bottom", false, null) },
        ]}
        startValue={formGetStartValue(startStyles, "componentRadiusSide")}
        tooltipText={translate("cEditForms.ttBorderRadiusSides", false, null)}
      />
      <Divider />
      <AlignHori />
      <Divider />
      <Slider
        label={translate("cEditForms.internalPaddingLeft", false, null)}
        id={"paddingLeft"}
        min={0}
        max={10}
        step={1}
        startValue={PADDING_VALUES.indexOf(formGetStartValue(startStyles, "paddingLeft"))}
        tooltipText={translate("cEditForms.ttInternalPaddingLeft", false, null)}
      />
      <Slider
        label={translate("cEditForms.internalPaddingRight", false, null)}
        id={"paddingRight"}
        min={0}
        max={10}
        step={1}
        startValue={PADDING_VALUES.indexOf(formGetStartValue(startStyles, "paddingRight"))}
        tooltipText={translate("cEditForms.ttInternalPaddingRight", false, null)}
      />
      <Slider
        label={translate("cEditForms.internalPaddingTop", false, null)}
        id={"paddingTop"}
        min={0}
        max={10}
        step={1}
        startValue={PADDING_VALUES.indexOf(formGetStartValue(startStyles, "paddingTop"))}
        tooltipText={translate("cEditForms.ttInternalPaddingTop", false, null)}
      />
      <Slider
        label={translate("cEditForms.internalPaddingBottom", false, null)}
        id={"paddingBottom"}
        min={0}
        max={10}
        step={1}
        startValue={PADDING_VALUES.indexOf(formGetStartValue(startStyles, "paddingBottom"))}
        tooltipText={translate("cEditForms.ttInternalPaddingBottom", false, null)}
      />
    </>
  );
};

Component.propTypes = {
  eb: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  eb: state.eb,
});

export default connect(mapStateToProps, null)(Component);
