// https://github.com/jpuri/draftjs-to-html/tree/master/js (17 March 2022)
// https://github.com/jpuri/html-to-draftjs/tree/master/src/library (17 March 2022)

import { CharacterMetadata, ContentBlock, genKey, Entity } from "draft-js";
import { Map, List, OrderedMap, OrderedSet } from "immutable";

export const draftToHtml = (editorContent, hashtagConfig, directional, customEntityTransform) => {
  const html = [];
  if (editorContent) {
    const { blocks, entityMap } = editorContent;
    if (blocks && blocks.length > 0) {
      let listBlocks = [];
      blocks.forEach((block) => {
        if (isList(block.type)) {
          listBlocks.push(block);
        } else {
          if (listBlocks.length > 0) {
            const listHtml = getListMarkup(listBlocks, entityMap, hashtagConfig, customEntityTransform); // eslint-disable-line max-len
            html.push(listHtml);
            listBlocks = [];
          }
          const blockHtml = getBlockMarkup(block, entityMap, hashtagConfig, directional, customEntityTransform);
          html.push(blockHtml);
        }
      });
      if (listBlocks.length > 0) {
        const listHtml = getListMarkup(listBlocks, entityMap, hashtagConfig, directional, customEntityTransform); // eslint-disable-line max-len
        html.push(listHtml);
        listBlocks = [];
      }
    }
  }
  return html.join("");
};

export const htmlToDraft = (html, customChunkGenerator = null) => {
  const chunkData = getChunkForHTML(html, customChunkGenerator);
  if (chunkData) {
    const { chunk } = chunkData;
    let entityMap = new OrderedMap({});
    chunk.entities &&
      chunk.entities.forEach((entity) => {
        if (entity) {
          entityMap = entityMap.set(entity, Entity.__get(entity));
        }
      });
    let start = 0;
    return {
      contentBlocks: chunk.text.split("\r").map((textBlock, ii) => {
        const end = start + textBlock.length;
        const inlines = chunk && chunk.inlines.slice(start, end);
        const entities = chunk && chunk.entities.slice(start, end);
        const characterList = new List(
          inlines.map((style, index) => {
            const data = { style, entity: null };
            if (entities[index]) {
              data.entity = entities[index];
            }
            return CharacterMetadata.create(data);
          })
        );
        start = end;
        return new ContentBlock({
          key: genKey(),
          type: (chunk && chunk.blocks[ii] && chunk.blocks[ii].type) || "unstyled",
          depth: chunk && chunk.blocks[ii] && chunk.blocks[ii].depth,
          data: (chunk && chunk.blocks[ii] && chunk.blocks[ii].data) || new Map({}),
          text: textBlock,
          characterList,
        });
      }),
      entityMap,
    };
  }
  return null;
};

// =============================
// ======= Draft to HTML =======
// =============================

// Function to check if a block is of type list
const isList = (blockType) => {
  return blockType === "unordered-list-item" || blockType === "ordered-list-item";
};

// Return html markup for a list block
const getListMarkup = (listBlocks, entityMap, hashtagConfig, directional, customEntityTransform) => {
  const listHtml = [];
  let nestedListBlock = [];
  let previousBlock;
  listBlocks.forEach((block) => {
    let nestedBlock = false;
    if (!previousBlock) {
      listHtml.push(`<${getBlockTag(block.type)}>\n`);
    } else if (previousBlock.type !== block.type) {
      listHtml.push(`</${getBlockTag(previousBlock.type)}>\n`);
      listHtml.push(`<${getBlockTag(block.type)}>\n`);
    } else if (previousBlock.depth === block.depth) {
      if (nestedListBlock && nestedListBlock.length > 0) {
        listHtml.push(getListMarkup(nestedListBlock, entityMap, hashtagConfig, directional, customEntityTransform));
        nestedListBlock = [];
      }
    } else {
      nestedBlock = true;
      nestedListBlock.push(block);
    }
    if (!nestedBlock) {
      listHtml.push("<li");
      const blockStyle = getBlockStyle(block.data);
      if (blockStyle) {
        listHtml.push(` style="${blockStyle}"`);
      }
      if (directional) {
        listHtml.push(' dir = "auto"');
      }
      listHtml.push(">");
      listHtml.push(getBlockInnerMarkup(block, entityMap, hashtagConfig, customEntityTransform));
      listHtml.push("</li>\n");
      previousBlock = block;
    }
  });
  if (nestedListBlock && nestedListBlock.length > 0) {
    listHtml.push(getListMarkup(nestedListBlock, entityMap, hashtagConfig, directional, customEntityTransform));
  }
  listHtml.push(`</${getBlockTag(previousBlock.type)}>\n`);
  return listHtml.join("");
};

// Utility function to execute callback for eack key->value pair
const forEach = (obj, callback) => {
  if (obj) {
    for (const key in obj) {
      // eslint-disable-line no-restricted-syntax
      if ({}.hasOwnProperty.call(obj, key)) {
        callback(key, obj[key]);
      }
    }
  }
};

// The function returns true if the string passed to it has no content
const isEmptyString = (str) => {
  if (str === undefined || str === null || str.length === 0 || str.trim().length === 0) {
    return true;
  }
  return false;
};

// Mapping block-type to corresponding html tag
const blockTypesMapping = {
  unstyled: "p",
  "header-one": "h1",
  "header-two": "h2",
  "header-three": "h3",
  "header-four": "h4",
  "header-five": "h5",
  "header-six": "h6",
  "unordered-list-item": "ul",
  "ordered-list-item": "ol",
  blockquote: "blockquote",
  code: "pre",
};

// Function will return HTML tag for a block
const getBlockTag = (type) => {
  return type && blockTypesMapping[type];
};

// Function will return style string for a block
const getBlockStyle = (data) => {
  let styles = "";
  forEach(data, (key, value) => {
    if (value) {
      styles += `${key}:${value};`;
    }
  });
  return styles;
};

// Returns an array of hashtag-sections in blocks. These will be areas in block which have hashtags applicable to them
const getHashtagRanges = (blockText, hashtagConfig) => {
  const sections = [];
  if (hashtagConfig) {
    let counter = 0;
    let startIndex = 0;
    let text = blockText;
    const trigger = hashtagConfig.trigger || "#";
    const separator = hashtagConfig.separator || " ";
    for (; text.length > 0 && startIndex >= 0; ) {
      if (text[0] === trigger) {
        startIndex = 0;
        counter = 0;
        text = text.substr(trigger.length);
      } else {
        startIndex = text.indexOf(separator + trigger);
        if (startIndex >= 0) {
          text = text.substr(startIndex + (separator + trigger).length);
          counter += startIndex + separator.length;
        }
      }
      if (startIndex >= 0) {
        const endIndex = text.indexOf(separator) >= 0 ? text.indexOf(separator) : text.length;
        const hashtag = text.substr(0, endIndex);
        if (hashtag && hashtag.length > 0) {
          sections.push({
            offset: counter,
            length: hashtag.length + trigger.length,
            type: "HASHTAG",
          });
        }
        counter += trigger.length;
      }
    }
  }
  return sections;
};

// Returns an array of entity-sections in blocks. These will be areas in block which have same entity or no entity applicable to them
const getSections = (block, hashtagConfig) => {
  const sections = [];
  let lastOffset = 0;
  let sectionRanges = block.entityRanges.map((range) => {
    const { offset, length, key } = range;
    return {
      offset,
      length,
      key,
      type: "ENTITY",
    };
  });
  sectionRanges = sectionRanges.concat(getHashtagRanges(block.text, hashtagConfig));
  sectionRanges = sectionRanges.sort((s1, s2) => s1.offset - s2.offset);
  sectionRanges.forEach((r) => {
    if (r.offset > lastOffset) {
      sections.push({
        start: lastOffset,
        end: r.offset,
      });
    }
    sections.push({
      start: r.offset,
      end: r.offset + r.length,
      entityKey: r.key,
      type: r.type,
    });
    lastOffset = r.offset + r.length;
  });
  if (lastOffset < block.text.length) {
    sections.push({
      start: lastOffset,
      end: block.text.length,
    });
  }
  return sections;
};

// Check if the block is an atomic entity block
const isAtomicEntityBlock = (block) => {
  if (block.entityRanges.length > 0 && (isEmptyString(block.text) || block.type === "atomic")) {
    return true;
  }
  return false;
};

// Returns array of inline styles applicable to the block
const getStyleArrayForBlock = (block) => {
  const { text, inlineStyleRanges } = block;
  const inlineStyles = {
    BOLD: new Array(text.length),
    ITALIC: new Array(text.length),
    UNDERLINE: new Array(text.length),
    STRIKETHROUGH: new Array(text.length),
    CODE: new Array(text.length),
    SUPERSCRIPT: new Array(text.length),
    SUBSCRIPT: new Array(text.length),
    COLOR: new Array(text.length),
    BGCOLOR: new Array(text.length),
    FONTSIZE: new Array(text.length),
    FONTFAMILY: new Array(text.length),
    TEXT_UPPERCASE: new Array(text.length),
    length: text.length,
  };
  if (inlineStyleRanges && inlineStyleRanges.length > 0) {
    inlineStyleRanges.forEach((range) => {
      const { offset } = range;
      const length = offset + range.length;
      for (let i = offset; i < length; i += 1) {
        if (range.style.indexOf("color-") === 0) {
          inlineStyles.COLOR[i] = range.style.substring(6);
        } else if (range.style.indexOf("bgcolor-") === 0) {
          inlineStyles.BGCOLOR[i] = range.style.substring(8);
        } else if (range.style.indexOf("fontsize-") === 0) {
          inlineStyles.FONTSIZE[i] = range.style.substring(9);
        } else if (range.style.indexOf("fontfamily-") === 0) {
          inlineStyles.FONTFAMILY[i] = range.style.substring(11);
        } else if (range.style.indexOf("text-transform") === 0) {
          inlineStyles.TEXT_UPPERCASE[i] = range.style.substring(14);
        } else if (inlineStyles[range.style]) {
          inlineStyles[range.style][i] = true;
        }
      }
    });
  }
  return inlineStyles;
};

// Return inline style applicable at some offset within a block
const getStylesAtOffset = (inlineStyles, offset) => {
  const styles = {};
  if (inlineStyles.COLOR[offset]) {
    styles.COLOR = inlineStyles.COLOR[offset];
  }
  if (inlineStyles.BGCOLOR[offset]) {
    styles.BGCOLOR = inlineStyles.BGCOLOR[offset];
  }
  if (inlineStyles.FONTSIZE[offset]) {
    styles.FONTSIZE = inlineStyles.FONTSIZE[offset];
  }
  if (inlineStyles.FONTFAMILY[offset]) {
    styles.FONTFAMILY = inlineStyles.FONTFAMILY[offset];
  }
  if (inlineStyles.TEXT_UPPERCASE[offset]) {
    styles.TEXT_UPPERCASE = inlineStyles.TEXT_UPPERCASE[offset];
  }
  if (inlineStyles.UNDERLINE[offset]) {
    styles.UNDERLINE = true;
  }
  if (inlineStyles.ITALIC[offset]) {
    styles.ITALIC = true;
  }
  if (inlineStyles.BOLD[offset]) {
    styles.BOLD = true;
  }
  if (inlineStyles.STRIKETHROUGH[offset]) {
    styles.STRIKETHROUGH = true;
  }
  if (inlineStyles.CODE[offset]) {
    styles.CODE = true;
  }
  if (inlineStyles.SUBSCRIPT[offset]) {
    styles.SUBSCRIPT = true;
  }
  if (inlineStyles.SUPERSCRIPT[offset]) {
    styles.SUPERSCRIPT = true;
  }
  return styles;
};

// Returns true for a set of styles if the value of these styles at an offset are same as that on the previous offset
const sameStyleAsPrevious = (inlineStyles, styles, index) => {
  let sameStyled = true;
  if (index > 0 && index < inlineStyles.length) {
    styles.forEach((style) => {
      sameStyled = sameStyled && inlineStyles[style][index] === inlineStyles[style][index - 1];
    });
  } else {
    sameStyled = false;
  }
  return sameStyled;
};

// Returns html for text depending on inline style tags applicable to it
const addInlineStyleMarkup = (style, content) => {
  if (style === "BOLD") {
    return `<strong>${content}</strong>`;
  }
  if (style === "ITALIC") {
    return `<em>${content}</em>`;
  }
  if (style === "UNDERLINE") {
    return `<ins>${content}</ins>`;
  }
  if (style === "STRIKETHROUGH") {
    return `<del>${content}</del>`;
  }
  if (style === "CODE") {
    return `<code>${content}</code>`;
  }
  if (style === "SUPERSCRIPT") {
    return `<sup>${content}</sup>`;
  }
  if (style === "SUBSCRIPT") {
    return `<sub>${content}</sub>`;
  }
  return content;
};

// Returns text for given section of block after doing required character replacements
const getSectionText = (text) => {
  if (text && text.length > 0) {
    const chars = text.map((ch) => {
      switch (ch) {
        case "\n":
          return "<br>";
        case "&":
          return "&amp;";
        case "<":
          return "&lt;";
        case ">":
          return "&gt;";
        default:
          return ch;
      }
    });
    return chars.join("");
  }
  return "";
};

// Returns html for text depending on inline style tags applicable to it
const addStylePropertyMarkup = (styles, text) => {
  if (styles && (styles.COLOR || styles.BGCOLOR || styles.FONTSIZE || styles.FONTFAMILY || styles.TEXT_UPPERCASE)) {
    let styleString = 'style="';
    if (styles.COLOR) {
      styleString += `color: ${styles.COLOR};`;
    }
    if (styles.BGCOLOR) {
      styleString += `background-color: ${styles.BGCOLOR};`;
    }
    if (styles.FONTSIZE) {
      styleString += `font-size: ${styles.FONTSIZE}${/^\d+$/.test(styles.FONTSIZE) ? "px" : ""};`;
    }
    if (styles.FONTFAMILY) {
      styleString += `font-family: ${styles.FONTFAMILY};`;
    }
    if (styles.TEXT_UPPERCASE) {
      //   styleString += `text-transform: ${styles.TEXT_UPPERCASE};`;
      styleString += `text-transform: uppercase;`;
    }
    styleString += '"';
    return `<span ${styleString}>${text}</span>`;
  }
  return text;
};

// Return markup for Entity
const getEntityMarkup = (entityMap, entityKey, text, customEntityTransform) => {
  const entity = entityMap[entityKey];
  if (typeof customEntityTransform === "function") {
    const html = customEntityTransform(entity, text);
    if (html) {
      return html;
    }
  }
  if (entity.type === "MENTION") {
    return `<a href="${entity.data.url}" class="wysiwyg-mention" data-mention data-value="${entity.data.value}">${text}</a>`;
  }
  if (entity.type === "LINK") {
    const targetOption = entity.data.targetOption || "_blank";
    return `<a href="${entity.data.url}" target="${targetOption}">${text}</a>`;
  }
  if (entity.type === "IMAGE") {
    const { alignment } = entity.data;
    if (alignment && alignment.length) {
      return `<div style="text-align:${alignment};"><img src="${entity.data.src}" alt="${entity.data.alt}" style="height: ${entity.data.height};width: ${entity.data.width}"/></div>`;
    }
    return `<img src="${entity.data.src}" alt="${entity.data.alt}" style="height: ${entity.data.height};width: ${entity.data.width}"/>`;
  }
  if (entity.type === "EMBEDDED_LINK") {
    return `<iframe width="${entity.data.width}" height="${entity.data.height}" src="${entity.data.src}" frameBorder="0"></iframe>`;
  }
  return text;
};

// For a given section in a block the function will return a further list of sections with similar inline styles applicable to them
const getInlineStyleSections = (block, styles, start, end) => {
  const styleSections = [];
  const text = Array.from(block.text);
  if (text.length > 0) {
    const inlineStyles = getStyleArrayForBlock(block);
    let section;
    for (let i = start; i < end; i += 1) {
      if (i !== start && sameStyleAsPrevious(inlineStyles, styles, i)) {
        section.text.push(text[i]);
        section.end = i + 1;
      } else {
        section = {
          styles: getStylesAtOffset(inlineStyles, i),
          text: [text[i]],
          start: i,
          end: i + 1,
        };
        styleSections.push(section);
      }
    }
  }
  return styleSections;
};

// Replace leading blank spaces by &nbsp;
const trimLeadingZeros = (sectionText) => {
  if (sectionText) {
    let replacedText = sectionText;
    for (let i = 0; i < replacedText.length; i += 1) {
      if (sectionText[i] === " ") {
        replacedText = replacedText.replace(" ", "&nbsp;");
      } else {
        break;
      }
    }
    return replacedText;
  }
  return sectionText;
};

// Replace trailing blank spaces by &nbsp;
const trimTrailingZeros = (sectionText) => {
  if (sectionText) {
    let replacedText = sectionText;
    for (let i = replacedText.length - 1; i >= 0; i -= 1) {
      if (replacedText[i] === " ") {
        replacedText = `${replacedText.substring(0, i)}&nbsp;${replacedText.substring(i + 1)}`;
      } else {
        break;
      }
    }
    return replacedText;
  }
  return sectionText;
};

// The method returns markup for section to which inline styles like BOLD, ITALIC, UNDERLINE, STRIKETHROUGH, CODE, SUPERSCRIPT, SUBSCRIPT are applicable
const getStyleTagSectionMarkup = (styleSection) => {
  const { styles, text } = styleSection;
  let content = getSectionText(text);
  forEach(styles, (style, value) => {
    content = addInlineStyleMarkup(style, content, value);
  });
  return content;
};

// Returns markup for section to which inline styles like color, background-color, font-size are applicable
const getInlineStyleSectionMarkup = (block, styleSection) => {
  const styleTagSections = getInlineStyleSections(
    block,
    ["BOLD", "ITALIC", "UNDERLINE", "STRIKETHROUGH", "CODE", "SUPERSCRIPT", "SUBSCRIPT"],
    styleSection.start,
    styleSection.end
  );
  let styleSectionText = "";
  styleTagSections.forEach((stylePropertySection) => {
    styleSectionText += getStyleTagSectionMarkup(stylePropertySection);
  });
  styleSectionText = addStylePropertyMarkup(styleSection.styles, styleSectionText);
  return styleSectionText;
};

// Returns markup for an entity section. An entity section is a continuous section in a block to which same entity or no entity is applicable
const getSectionMarkup = (block, entityMap, section, customEntityTransform) => {
  const entityInlineMarkup = [];
  const inlineStyleSections = getInlineStyleSections(
    block,
    ["COLOR", "BGCOLOR", "FONTSIZE", "FONTFAMILY", "TEXT_UPPERCASE"],
    section.start,
    section.end
  );
  inlineStyleSections.forEach((styleSection) => {
    entityInlineMarkup.push(getInlineStyleSectionMarkup(block, styleSection));
  });
  let sectionText = entityInlineMarkup.join("");
  if (section.type === "ENTITY") {
    if (section.entityKey !== undefined && section.entityKey !== null) {
      sectionText = getEntityMarkup(entityMap, section.entityKey, sectionText, customEntityTransform); // eslint-disable-line max-len
    }
  } else if (section.type === "HASHTAG") {
    sectionText = `<a href="${sectionText}" class="wysiwyg-hashtag">${sectionText}</a>`;
  }
  return sectionText;
};

// Return the markup for block preserving the inline styles and special characters like newlines or blank spaces
const getBlockInnerMarkup = (block, entityMap, hashtagConfig, customEntityTransform) => {
  const blockMarkup = [];
  const sections = getSections(block, hashtagConfig);
  sections.forEach((section, index) => {
    let sectionText = getSectionMarkup(block, entityMap, section, customEntityTransform);
    if (index === 0) {
      sectionText = trimLeadingZeros(sectionText);
    }
    if (index === sections.length - 1) {
      sectionText = trimTrailingZeros(sectionText);
    }
    blockMarkup.push(sectionText);
  });
  return blockMarkup.join("");
};

// Return html for the block
const getBlockMarkup = (block, entityMap, hashtagConfig, directional, customEntityTransform) => {
  const blockHtml = [];
  if (isAtomicEntityBlock(block)) {
    blockHtml.push(getEntityMarkup(entityMap, block.entityRanges[0].key, undefined, customEntityTransform));
  } else {
    const blockTag = getBlockTag(block.type);
    if (blockTag) {
      blockHtml.push(`<${blockTag}`);
      const blockStyle = getBlockStyle(block.data);
      if (blockStyle) {
        blockHtml.push(` style="${blockStyle}"`);
      }
      if (directional) {
        blockHtml.push(' dir = "auto"');
      }
      blockHtml.push(">");
      blockHtml.push(getBlockInnerMarkup(block, entityMap, hashtagConfig, customEntityTransform));
      blockHtml.push(`</${blockTag}>`);
    }
  }
  blockHtml.push("\n");
  return blockHtml.join("");
};

// =============================
// ======= HTML to Draft =======
// =============================

const SPACE = " ";
const REGEX_NBSP = new RegExp("&nbsp;", "g");
const MAX_DEPTH = 4;

let firstBlock = true;

// type CustomChunkGenerator = (nodeName: string, node: HTMLElement) => ?{type: string, mutability: string, data: {}};

const genFragment = (node, inlineStyle, depth, lastList, inEntity, customChunkGenerator) => {
  const nodeName = node.nodeName.toLowerCase();

  if (customChunkGenerator) {
    const value = customChunkGenerator(nodeName, node);
    if (value) {
      const entityId = Entity.__create(value.type, value.mutability, value.data || {});
      return { chunk: getAtomicBlockChunk(entityId) };
    }
  }

  if (nodeName === "#text" && node.textContent !== "\n") {
    return createTextChunk(node, inlineStyle, inEntity);
  }

  if (nodeName === "br") {
    return { chunk: getSoftNewlineChunk() };
  }

  if (nodeName === "img" && node instanceof HTMLImageElement) {
    const entityConfig = {};
    entityConfig.src = node.getAttribute ? node.getAttribute("src") || node.src : node.src;
    entityConfig.alt = node.alt;
    entityConfig.height = node.style.height;
    entityConfig.width = node.style.width;
    if (node.style.float) {
      entityConfig.alignment = node.style.float;
    }
    const entityId = Entity.__create("IMAGE", "MUTABLE", entityConfig);
    return { chunk: getAtomicBlockChunk(entityId) };
  }

  if (nodeName === "video" && node instanceof HTMLVideoElement) {
    const entityConfig = {};
    entityConfig.src = node.getAttribute ? node.getAttribute("src") || node.src : node.src;
    entityConfig.alt = node.alt;
    entityConfig.height = node.style.height;
    entityConfig.width = node.style.width;
    if (node.style.float) {
      entityConfig.alignment = node.style.float;
    }
    const entityId = Entity.__create("VIDEO", "MUTABLE", entityConfig);
    return { chunk: getAtomicBlockChunk(entityId) };
  }

  if (nodeName === "iframe" && node instanceof HTMLIFrameElement) {
    const entityConfig = {};
    entityConfig.src = node.getAttribute ? node.getAttribute("src") || node.src : node.src;
    entityConfig.height = node.height;
    entityConfig.width = node.width;
    const entityId = Entity.__create("EMBEDDED_LINK", "MUTABLE", entityConfig);
    return { chunk: getAtomicBlockChunk(entityId) };
  }

  const blockType = getBlockTypeForTag(nodeName, lastList);

  let chunk;
  if (blockType) {
    if (nodeName === "ul" || nodeName === "ol") {
      lastList = nodeName;
      depth += 1;
    } else {
      if (blockType !== "unordered-list-item" && blockType !== "ordered-list-item") {
        lastList = "";
        depth = -1;
      }
      if (!firstBlock) {
        chunk = getBlockDividerChunk(blockType, depth, getBlockData(node));
      } else {
        chunk = getFirstBlockChunk(blockType, getBlockData(node));
        firstBlock = false;
      }
    }
  }
  if (!chunk) {
    chunk = getEmptyChunk();
  }

  inlineStyle = processInlineTag(nodeName, node, inlineStyle);

  let child = node.firstChild;
  while (child) {
    const entityId = getEntityId(child);
    const { chunk: generatedChunk } = genFragment(child, inlineStyle, depth, lastList, entityId || inEntity, customChunkGenerator);
    chunk = joinChunks(chunk, generatedChunk);
    const sibling = child.nextSibling;
    child = sibling;
  }
  return { chunk };
};

const getChunkForHTML = (html, customChunkGenerator) => {
  const sanitizedHtml = html.trim().replace(REGEX_NBSP, SPACE);
  const safeBody = getSafeBodyFromHTML(sanitizedHtml);
  if (!safeBody) {
    return null;
  }
  firstBlock = true;
  const { chunk } = genFragment(safeBody, new OrderedSet(), -1, "", undefined, customChunkGenerator);
  return { chunk };
};

const getWhitespaceChunk = (entityId) => {
  return {
    text: SPACE,
    inlines: [new OrderedSet()],
    entities: [entityId],
    blocks: [],
  };
};

const createTextChunk = (node, inlineStyle, entityId) => {
  const text = node.textContent;
  if (text.trim() === "") {
    return { chunk: getWhitespaceChunk(entityId) };
  }
  return {
    chunk: {
      text,
      inlines: Array(text.length).fill(inlineStyle),
      entities: Array(text.length).fill(entityId),
      blocks: [],
    },
  };
};

const getSoftNewlineChunk = () => {
  return {
    text: "\n",
    inlines: [new OrderedSet()],
    entities: new Array(1),
    blocks: [],
  };
};

const getEmptyChunk = () => {
  return {
    text: "",
    inlines: [],
    entities: [],
    blocks: [],
  };
};

const getFirstBlockChunk = (blockType, data) => {
  return {
    text: "",
    inlines: [],
    entities: [],
    blocks: [
      {
        type: blockType,
        depth: 0,
        data: data || new Map({}),
      },
    ],
  };
};

const getBlockDividerChunk = (blockType, depth, data) => {
  return {
    text: "\r",
    inlines: [],
    entities: [],
    blocks: [
      {
        type: blockType,
        depth: Math.max(0, Math.min(MAX_DEPTH, depth)),
        data: data || new Map({}),
      },
    ],
  };
};

const getAtomicBlockChunk = (entityId) => {
  return {
    text: "\r ",
    inlines: [new OrderedSet()],
    entities: [entityId],
    blocks: [
      {
        type: "atomic",
        depth: 0,
        data: new Map({}),
      },
    ],
  };
};

const joinChunks = (A, B) => {
  return {
    text: A.text + B.text,
    inlines: A.inlines.concat(B.inlines),
    entities: A.entities.concat(B.entities),
    blocks: A.blocks.concat(B.blocks),
  };
};

const getBlockData = (node) => {
  if (node.style.textAlign) {
    return new Map({
      "text-align": node.style.textAlign,
    });
  } else if (node.style.marginLeft) {
    return new Map({
      "margin-left": node.style.marginLeft,
    });
  }
  return undefined;
};

const blockRenderMap = new Map({
  "header-one": {
    element: "h1",
  },
  "header-two": {
    element: "h2",
  },
  "header-three": {
    element: "h3",
  },
  "header-four": {
    element: "h4",
  },
  "header-five": {
    element: "h5",
  },
  "header-six": {
    element: "h6",
  },
  "unordered-list-item": {
    element: "li",
    wrapper: "ul",
  },
  "ordered-list-item": {
    element: "li",
    wrapper: "ol",
  },
  blockquote: {
    element: "blockquote",
  },
  code: {
    element: "pre",
  },
  atomic: {
    element: "figure",
  },
  unstyled: {
    element: "p",
    aliasedElements: ["div"],
  },
});

const getBlockTypeForTag = (tag, lastList) => {
  const matchedTypes = blockRenderMap
    .filter((draftBlock) => {
      return (
        (draftBlock.element === tag && (!draftBlock.wrapper || draftBlock.wrapper === lastList)) ||
        draftBlock.wrapper === tag ||
        (draftBlock.aliasedElements && draftBlock.aliasedElements.indexOf(tag) > -1)
      );
    })
    .keySeq()
    .toSet()
    .toArray();

  if (matchedTypes.length === 1) {
    return matchedTypes[0];
  }
  return undefined;
};

const getEntityId = (node) => {
  let entityId = undefined;
  if (node instanceof HTMLAnchorElement) {
    const entityConfig = {};
    if (node.dataset && node.dataset.mention !== undefined) {
      entityConfig.url = node.href;
      entityConfig.text = node.innerHTML;
      entityConfig.value = node.dataset.value;
      entityId = Entity.__create("MENTION", "IMMUTABLE", entityConfig);
    } else {
      entityConfig.url = node.getAttribute ? node.getAttribute("href") || node.href : node.href;
      entityConfig.title = node.innerHTML;
      entityConfig.targetOption = node.target;
      entityId = Entity.__create("LINK", "MUTABLE", entityConfig);
    }
  }
  return entityId;
};

const getSafeBodyFromHTML = (html) => {
  let doc;
  let root = null;
  if (document.implementation && document.implementation.createHTMLDocument) {
    doc = document.implementation.createHTMLDocument("foo");
    doc.documentElement.innerHTML = html;
    root = doc.getElementsByTagName("body")[0];
  }
  return root;
};

const inlineTags = {
  code: "CODE",
  del: "STRIKETHROUGH",
  em: "ITALIC",
  strong: "BOLD",
  ins: "UNDERLINE",
  sub: "SUBSCRIPT",
  sup: "SUPERSCRIPT",
};

const processInlineTag = (tag, node, currentStyle) => {
  const styleToCheck = inlineTags[tag];
  let inlineStyle;
  if (styleToCheck) {
    inlineStyle = currentStyle.add(styleToCheck).toOrderedSet();
  } else if (node instanceof HTMLElement) {
    inlineStyle = currentStyle;
    const htmlElement = node;
    inlineStyle = inlineStyle
      .withMutations((style) => {
        const color = htmlElement.style.color;
        const backgroundColor = htmlElement.style.backgroundColor;
        const fontSize = htmlElement.style.fontSize;
        const fontFamily = htmlElement.style.fontFamily.replace(/^"|"$/g, "");
        const fontWeight = htmlElement.style.fontWeight;
        const textDecoration = htmlElement.style.textDecoration;
        const fontStyle = htmlElement.style.fontStyle;
        const textTransform = htmlElement.style.textTransform;
        if (color) {
          style.add(`color-${color.replace(/ /g, "")}`);
        }
        if (backgroundColor) {
          style.add(`bgcolor-${backgroundColor.replace(/ /g, "")}`);
        }
        if (fontSize) {
          style.add(`fontsize-${fontSize.replace(/px$/g, "")}`);
        }
        if (fontFamily) {
          style.add(`fontfamily-${fontFamily}`);
        }
        if (textTransform) {
          style.add(`texttransform-${textTransform}`);
        }
        if (fontWeight === "bold") {
          style.add(inlineTags.strong);
        }
        if (textDecoration === "underline") {
          style.add(inlineTags.ins);
        }
        if (fontStyle === "italic") {
          style.add(inlineTags.em);
        }
      })
      .toOrderedSet();
  }
  return inlineStyle;
};
