export const TEMPLATE_CATEGORIES = [
  {
    val: "text",
    label: {
      en: "Text",
      nl: "Tekst",
    },
  },
  {
    val: "event",
    label: {
      en: "Event",
      nl: "Evenement",
    },
  },
  {
    val: "product",
    label: {
      en: "Product promotion",
      nl: "Product aanbieding",
    },
  },
  {
    val: "newsletter",
    label: {
      en: "Newsletter",
      nl: "Nieuwsbrief",
    },
  },
];
