import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";
import { TourProvider } from "@reactour/tour";
import store from "./store";
import "./styles/style.css";

import Main from "./pages/Main";
import Open from "./pages/Open";
import AuthRequired from "./components/auth/AuthRequired";
import NotFound from "./components/auth/NotFound";
// import Icons from "./pages/Icons";
import BrowseTemplates from "./pages/BrowseTemplates";
import PreviewTemplate from "./pages/PreviewTemplate";

// Reactour docs: https://github.com/elrumordelaluz/reactour/tree/main/packages/tour
// Reactour demo: https://reactour.vercel.app/tour
import { steps } from "./lib/productTour/steps";
import { openProductTour, closeProductTour } from "./actions/eb";

// Auth functions
import { loadUser } from "./actions/auth";
import setAuthToken from "./utils/setAuthToken";
import setWorkspace from "./utils/setWorkspace";

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

if (localStorage.workspace) {
  setWorkspace(localStorage.workspace);
}

const App = () => {
  useEffect(() => {
    store.dispatch(loadUser());
    // eslint-disable-next-line
  }, []);

  return (
    <Provider store={store}>
      <BrowserRouter>
        <TourProvider
          steps={steps}
          scrollSmooth
          disableDotsNavigation
          disableKeyboardNavigation={["esc"]}
          showBadge={false}
          onClickHighlighted={(e) => {
            e.stopPropagation();
          }}
          disableInteraction
          styles={{
            popover: (base) => ({
              ...base,
              "--reactour-accent": "rgb(229, 42, 5)",
            }),
          }}
          prevButton={({ currentStep, setCurrentStep }) => {
            const first = currentStep === 0;
            return (
              <i
                className={`fa-solid fa-arrow-left trans-3 ${first ? "text-midgray" : "cursorPointer text-gray textHover-primary"}`}
                title={first ? `You're at the first step` : `Go back to previous step`}
                onClick={() => {
                  if (!first) {
                    setCurrentStep((s) => s - 1);
                  }
                }}
              ></i>
            );
          }}
          nextButton={({ currentStep, stepsLength, setCurrentStep, steps }) => {
            const last = currentStep === stepsLength - 1;
            return (
              <i
                className={`fa-solid fa-arrow-right trans-3 ${last ? "text-midgray" : "cursorPointer text-gray textHover-primary"}`}
                title={last ? `You've finished the tour. You can click the close button now.` : `Go to next step`}
                onClick={() => {
                  if (!last) {
                    setCurrentStep((s) => (s === steps?.length - 1 ? 0 : s + 1));
                  }
                }}
              ></i>
            );
          }}
          onClickMask={({ setCurrentStep, currentStep, steps, setIsOpen }) => {
            if (steps) {
              if (currentStep === steps.length - 1) {
                setIsOpen(false);
              }
              setCurrentStep((s) => (s === steps.length - 1 ? 0 : s + 1));
            }
          }}
          onClickClose={({ currentStep, setIsOpen }) => {
            setIsOpen(false);
            store.dispatch(closeProductTour());
          }}
          afterOpen={() => {
            store.dispatch(openProductTour());
          }}
        >
          <Routes>
            {/* Auth protected pages */}
            <Route element={<AuthRequired />}>
              <Route
                path="/:projectId"
                element={
                  <React.StrictMode>
                    <Main />
                  </React.StrictMode>
                }
              />
            </Route>
            {/* Load token transferred from mySatonda */}
            <Route path="/open/:token/:wsId/:projectId" element={<Open />} />
            {/* Public pages */}
            {/* <Route path="/icons" element={<Icons />} /> */}
            <Route path="/templates/browse/:langCode" element={<BrowseTemplates />} />
            <Route path="/templates/preview/:langCode/:templateId" element={<PreviewTemplate />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </TourProvider>
      </BrowserRouter>
    </Provider>
  );
};

export default App;
