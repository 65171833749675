export const template13 = {
  id: "66035064306f89d31fafdd17",
  name: { en: "Travel blog update", nl: "Reisblog update" },
  desc: { en: "Update your readers on your latest travels", nl: "Update uw lezers over uw laatste reizen" },
  categories: ["newsletter"],
  ebContent: [
    {
      pageId: "4d83f7fa-0249-4c5a-9440-e5cca6674cbb",
      version: "A",
      components: [
        {
          componentId: "UaJ4XL",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "basics-7-component-UaJ4XL"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "QiHuzz",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "basics-7-col-container-UaJ4XL"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "bZpvDO",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-12"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "S7BXa7",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "JIzJHj",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "WLBBh2",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "Zwo5LU",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["basics-7-col-td-wrapper-UaJ4XL"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-name", value: "divider" },
                                    { property: "data-editable", value: "true" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "qpiQOk",
                                      type: "",
                                      htmlTagName: "div",
                                      classes: ["basics-7-divider-UaJ4XL"],
                                      styles: [],
                                      attributes: [{ property: "data-elementgetter1", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "nLQFyI",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "basics-1-component-nLQFyI"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "nA3Ix9",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "basics-1-col-container-nLQFyI"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "vA9f92",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-12"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "JSF7Yf",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "eDIn1H",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "gUg7Jo",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "BYrThV",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["basics-1-col-td-wrapper-nLQFyI"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "Ms3JmD",
                                      type: "",
                                      htmlTagName: "h1",
                                      classes: ["basics-1-title-nLQFyI"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "h" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Our latest trips",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "zc3TIk",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "basics-2-component-zc3TIk"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "XuHXIA",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "basics-2-col-container-zc3TIk"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "OegwcM",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-12"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "HJcE7w",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "RMOtOp",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "uOoTnv",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "BkGAAv",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["basics-2-col-td-wrapper-zc3TIk"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "MKwGvm",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["basics-2-text-zc3TIk"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content:
                                            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sit amet porttitor eget dolor morbi non arcu.",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "TX2wuU",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "cards-7-component-TX2wuU"],
          styles: [],
          attributes: [{ property: "data-name", value: "col_component" }],
          content: "",
          children: [
            {
              childId: "an12tI",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "cards-7-col-container-TX2wuU"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "GmwDO0",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "vertical-align-middle", "col-8"],
                  styles: [],
                  attributes: [{ property: "data-colLayout", value: "true" }],
                  content: "",
                  children: [
                    {
                      childId: "aK0ea9",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "VshApM",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "H0U9uG",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "H0r8QJ",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["cards-7-col-td-wrapper-TX2wuU"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "iyS9Ll",
                                      type: "",
                                      htmlTagName: "img",
                                      classes: ["cards-7-img-TX2wuU"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-name", value: "img" },
                                        { property: "src", value: "https://cdn.satonda.com/eb/assets/nature5.png" },
                                        { property: "alt", value: "Image description" },
                                      ],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "dogfaO",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "vertical-align-middle", "col-4"],
                  styles: [],
                  attributes: [{ property: "data-colLayout", value: "true" }],
                  content: "",
                  children: [
                    {
                      childId: "dbvB6m",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "ZY221D",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "PPZae0",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "BlRcFu",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["cards-7-col-td-wrapper-TX2wuU"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "nZleQx",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["cards-7-text-TX2wuU"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content:
                                            "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Beatae ratione omnis temporibus vero fugit quam necessitatibus dolore voluptatibus libero nesciunt! ",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "SrUtlI",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "cards-7-component-SrUtlI"],
          styles: [],
          attributes: [{ property: "data-name", value: "col_component" }],
          content: "",
          children: [
            {
              childId: "vtj5Dj",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "cards-7-col-container-SrUtlI"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "GeGbVa",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "vertical-align-middle", "col-4"],
                  styles: [],
                  attributes: [{ property: "data-colLayout", value: "true" }],
                  content: "",
                  children: [
                    {
                      childId: "FcA2YC",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "ZctZsD",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "gCISdI",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "UKRM5v",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["cards-7-col-td-wrapper-SrUtlI"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "M4kkcV",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["cards-7-text-SrUtlI"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content:
                                            "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Beatae ratione omnis temporibus vero fugit quam necessitatibus dolore voluptatibus libero nesciunt! ",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "Aaz4yh",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "vertical-align-middle", "col-8"],
                  styles: [],
                  attributes: [{ property: "data-colLayout", value: "true" }],
                  content: "",
                  children: [
                    {
                      childId: "K7x8ZT",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "Sh1a50",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "HbVLMc",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "STyCK9",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["cards-7-col-td-wrapper-SrUtlI"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "UALpfV",
                                      type: "",
                                      htmlTagName: "img",
                                      classes: ["cards-7-img-SrUtlI"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-name", value: "img" },
                                        { property: "src", value: "https://cdn.satonda.com/eb/assets/mountain3.png" },
                                        { property: "alt", value: "Image description" },
                                      ],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "AV6zWG",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "cards-7-component-AV6zWG"],
          styles: [],
          attributes: [{ property: "data-name", value: "col_component" }],
          content: "",
          children: [
            {
              childId: "JCYook",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "cards-7-col-container-AV6zWG"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "Wv1chU",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "vertical-align-middle", "col-8"],
                  styles: [],
                  attributes: [{ property: "data-colLayout", value: "true" }],
                  content: "",
                  children: [
                    {
                      childId: "QDnXtI",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "b0EyDP",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "cGah4Z",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "kXYk3g",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["cards-7-col-td-wrapper-AV6zWG"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "TxLZIf",
                                      type: "",
                                      htmlTagName: "img",
                                      classes: ["cards-7-img-AV6zWG"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-name", value: "img" },
                                        { property: "src", value: "https://cdn.satonda.com/eb/assets/nature7.png" },
                                        { property: "alt", value: "Image description" },
                                      ],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "vKuq9W",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "vertical-align-middle", "col-4"],
                  styles: [],
                  attributes: [{ property: "data-colLayout", value: "true" }],
                  content: "",
                  children: [
                    {
                      childId: "uSL1MK",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "amMIDb",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "Aq97Mx",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "UFv2UV",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["cards-7-col-td-wrapper-AV6zWG"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "XSbMeM",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["cards-7-text-AV6zWG"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content:
                                            "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Beatae ratione omnis temporibus vero fugit quam necessitatibus dolore voluptatibus libero nesciunt! ",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "Tse8hP",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "footer-3-component-Tse8hP"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "q5q1Hf",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "footer-3-col-container-Tse8hP"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "NGRKZx",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-12"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "ai4Liy",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "uPASvf",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "MusOR1",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "IywEOG",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["footer-3-col-td-wrapper-Tse8hP"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "BJlgCa",
                                      type: "",
                                      htmlTagName: "table",
                                      classes: ["column-table", "footer-3-table-table-Tse8hP"],
                                      styles: [],
                                      attributes: [
                                        { property: "role", value: "presentation" },
                                        { property: "width", value: "100%" },
                                        { property: "cellspacing", value: "0" },
                                        { property: "cellpadding", value: "0" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "v9sXIN",
                                          type: "",
                                          htmlTagName: "tbody",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "",
                                          children: [
                                            {
                                              childId: "bowbap",
                                              type: "",
                                              htmlTagName: "tr",
                                              classes: ["footer-3-table-row-Tse8hP"],
                                              styles: [],
                                              attributes: [],
                                              content: "",
                                              children: [
                                                {
                                                  childId: "FwrKtP",
                                                  type: "",
                                                  htmlTagName: "td",
                                                  classes: ["footer-3-table-cell-all-Tse8hP"],
                                                  styles: [],
                                                  attributes: [{ property: "data-tablecell", value: "true" }],
                                                  content: "",
                                                  children: [
                                                    {
                                                      childId: "Z1fiaN",
                                                      type: "block",
                                                      htmlTagName: "div",
                                                      classes: ["footer-3-wrapper-icons-Tse8hP"],
                                                      styles: [],
                                                      attributes: [{ property: "data-name", value: "block" }],
                                                      content: "",
                                                      children: [
                                                        {
                                                          childId: "YPga6N",
                                                          type: "element",
                                                          htmlTagName: "a",
                                                          classes: ["footer-3-social-link-Tse8hP"],
                                                          styles: [],
                                                          attributes: [
                                                            { property: "href", value: "#!" },
                                                            { property: "data-href", value: "#!" },
                                                            { property: "data-target", value: "_blank" },
                                                            { property: "data-editable", value: "true" },
                                                            { property: "data-name", value: "socialIcon" },
                                                          ],
                                                          content: "",
                                                          children: [
                                                            {
                                                              childId: "WyteUL",
                                                              type: "",
                                                              htmlTagName: "img",
                                                              classes: ["footer-3-social-icon-Tse8hP"],
                                                              styles: [],
                                                              attributes: [
                                                                {
                                                                  property: "src",
                                                                  value: "https://cdn.satonda.com/eb/assets/facebook-white-brand-square.png",
                                                                },
                                                                { property: "alt", value: "Facebook" },
                                                                { property: "data-editable", value: "false" },
                                                              ],
                                                              content: "",
                                                              children: [],
                                                            },
                                                          ],
                                                        },
                                                        {
                                                          childId: "ri44jM",
                                                          type: "element",
                                                          htmlTagName: "a",
                                                          classes: ["footer-3-social-link-Tse8hP"],
                                                          styles: [],
                                                          attributes: [
                                                            { property: "href", value: "#!" },
                                                            { property: "data-href", value: "#!" },
                                                            { property: "data-target", value: "_blank" },
                                                            { property: "data-editable", value: "true" },
                                                            { property: "data-name", value: "socialIcon" },
                                                          ],
                                                          content: "",
                                                          children: [
                                                            {
                                                              childId: "mcv6e8",
                                                              type: "",
                                                              htmlTagName: "img",
                                                              classes: ["footer-3-social-icon-Tse8hP"],
                                                              styles: [],
                                                              attributes: [
                                                                {
                                                                  property: "src",
                                                                  value: "https://cdn.satonda.com/eb/assets/twitter-white-brand-square.png",
                                                                },
                                                                { property: "alt", value: "Twitter" },
                                                                { property: "data-editable", value: "false" },
                                                              ],
                                                              content: "",
                                                              children: [],
                                                            },
                                                          ],
                                                        },
                                                        {
                                                          childId: "q3dU5s",
                                                          type: "element",
                                                          htmlTagName: "a",
                                                          classes: ["footer-3-social-link-Tse8hP"],
                                                          styles: [],
                                                          attributes: [
                                                            { property: "href", value: "#!" },
                                                            { property: "data-href", value: "#!" },
                                                            { property: "data-target", value: "_blank" },
                                                            { property: "data-editable", value: "true" },
                                                            { property: "data-name", value: "socialIcon" },
                                                          ],
                                                          content: "",
                                                          children: [
                                                            {
                                                              childId: "WmbPXL",
                                                              type: "",
                                                              htmlTagName: "img",
                                                              classes: ["footer-3-social-icon-Tse8hP"],
                                                              styles: [],
                                                              attributes: [
                                                                {
                                                                  property: "src",
                                                                  value: "https://cdn.satonda.com/eb/assets/youtube-white-brand-square.png",
                                                                },
                                                                { property: "alt", value: "Youtube" },
                                                                { property: "data-editable", value: "false" },
                                                              ],
                                                              content: "",
                                                              children: [],
                                                            },
                                                          ],
                                                        },
                                                      ],
                                                    },
                                                  ],
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "LHy66s",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "footer-9-component-LHy66s"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "ZKY3Bz",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "footer-9-col-container-LHy66s"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "wLZ8CJ",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-12"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "JmSWa4",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "erl1Te",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "ezP1i5",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "UOKTSN",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["footer-9-col-td-wrapper-LHy66s"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "y6V3X2",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["footer-9-text1-LHy66s"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                        { property: "data-uniqueclassname", value: "true" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "© Company name",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "RDpHBA",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["footer-9-text2-LHy66s"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                        { property: "data-uniqueclassname", value: "true" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Streetname 1, 1000 XX Amsterdam, The Netherlands",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "D7nZKQ",
                                      type: "element",
                                      htmlTagName: "a",
                                      classes: ["footer-9-link-LHy66s"],
                                      styles: [],
                                      attributes: [
                                        { property: "href", value: "#!" },
                                        { property: "data-href", value: "%UNSUBSCRIBE_LINK%" },
                                        { property: "data-target", value: "_blank" },
                                        { property: "data-editable", value: "true" },
                                        { property: "data-name", value: "a" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Unsubscribe",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  ebCustomCss: [
    {
      componentId: "UaJ4XL",
      classes: [
        {
          className: "basics-7-component-UaJ4XL",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
          ],
        },
        {
          className: "basics-7-col-container-UaJ4XL",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "background-color", value: "rgb(255, 255, 255)" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "basics-7-col-td-wrapper-UaJ4XL",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0rem 0rem 0rem 0rem" },
            { property: "text-align", value: "start" },
          ],
        },
        {
          className: "basics-7-divider-UaJ4XL",
          pseudo: "",
          rules: [
            { property: "display", value: "inline-block" },
            { property: "height", value: "25px" },
            { property: "width", value: "100%" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
            { property: "border-radius", value: "0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(230, 230, 230)" },
          ],
        },
      ],
    },
    {
      componentId: "nLQFyI",
      classes: [
        {
          className: "basics-1-title-nLQFyI",
          pseudo: "",
          rules: [
            { property: "text-align", value: "left" },
            { property: "color", value: "rgb(5, 147, 230)" },
            { property: "font-weight", value: "bold" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "basics-1-component-nLQFyI",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
          ],
        },
        {
          className: "basics-1-col-container-nLQFyI",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "background-color", value: "rgb(255, 255, 255)" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "basics-1-col-td-wrapper-nLQFyI",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0.5rem 0.75rem 0.5rem 0.75rem" },
            { property: "text-align", value: "start" },
          ],
        },
      ],
    },
    {
      componentId: "zc3TIk",
      classes: [
        {
          className: "basics-2-text-zc3TIk",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(33, 37, 41)" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "basics-2-component-zc3TIk",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
          ],
        },
        {
          className: "basics-2-col-container-zc3TIk",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "background-color", value: "rgb(255, 255, 255)" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "basics-2-col-td-wrapper-zc3TIk",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0.5rem 0.75rem 0.5rem 0.75rem" },
            { property: "text-align", value: "start" },
          ],
        },
      ],
    },
    {
      componentId: "TX2wuU",
      classes: [
        {
          className: "cards-7-title-TX2wuU",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(5, 147, 230)" },
            { property: "margin", value: "0rem 0rem 0.5rem 0rem" },
            { property: "text-align", value: "center" },
          ],
        },
        {
          className: "cards-7-text-TX2wuU",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(33, 37, 41)" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "text-align", value: "center" },
          ],
        },
        {
          className: "cards-7-btn-TX2wuU",
          pseudo: "",
          rules: [
            { property: "background", value: "rgb(5, 147, 230)" },
            { property: "color", value: "rgb(255, 255, 255)" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(5, 147, 230)" },
            { property: "border-radius", value: "1000px" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "padding", value: "0.5rem 0rem 0.5rem 0rem" },
            { property: "text-decoration", value: "none" },
            { property: "text-align", value: "center" },
            { property: "font-weight", value: "bold" },
            { property: "display", value: "block" },
          ],
        },
        {
          className: "cards-7-img-TX2wuU",
          pseudo: "",
          rules: [
            { property: "outline", value: "none" },
            { property: "-ms-interpolation-mode", value: "bicubic" },
            { property: "max-width", value: "100%" },
            { property: "max-height", value: "200px" },
            { property: "width", value: "100%" },
            { property: "height", value: "auto" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "0px" },
            { property: "border-color", value: "rgb(0, 0, 0)" },
            { property: "border-radius", value: "0rem" },
          ],
        },
        {
          className: "cards-7-component-TX2wuU",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
          ],
        },
        {
          className: "cards-7-col-container-TX2wuU",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "background-color", value: "rgb(255, 255, 255)" },
            { property: "padding-top", value: "1rem" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "cards-7-col-td-wrapper-TX2wuU",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0.5rem 0.75rem 0.5rem 0.75rem" },
            { property: "text-align", value: "start" },
          ],
        },
      ],
    },
    {
      componentId: "SrUtlI",
      classes: [
        {
          className: "cards-7-title-SrUtlI",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(5, 147, 230)" },
            { property: "margin", value: "0rem 0rem 0.5rem 0rem" },
            { property: "text-align", value: "center" },
          ],
        },
        {
          className: "cards-7-text-SrUtlI",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(33, 37, 41)" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "text-align", value: "center" },
          ],
        },
        {
          className: "cards-7-btn-SrUtlI",
          pseudo: "",
          rules: [
            { property: "background", value: "rgb(5, 147, 230)" },
            { property: "color", value: "rgb(255, 255, 255)" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(5, 147, 230)" },
            { property: "border-radius", value: "1000px" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "padding", value: "0.5rem 0rem 0.5rem 0rem" },
            { property: "text-decoration", value: "none" },
            { property: "text-align", value: "center" },
            { property: "font-weight", value: "bold" },
            { property: "display", value: "block" },
          ],
        },
        {
          className: "cards-7-component-SrUtlI",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
          ],
        },
        {
          className: "cards-7-col-container-SrUtlI",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "padding-top", value: "1.5rem" },
            { property: "padding-bottom", value: "1.5rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "background-color", value: "rgb(255, 255, 255)" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "cards-7-col-td-wrapper-SrUtlI",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0.5rem 0.75rem 0.5rem 0.75rem" },
            { property: "text-align", value: "start" },
          ],
        },
        {
          className: "cards-7-img-SrUtlI",
          pseudo: "",
          rules: [
            { property: "outline", value: "none" },
            { property: "-ms-interpolation-mode", value: "bicubic" },
            { property: "max-width", value: "100%" },
            { property: "max-height", value: "200px" },
            { property: "width", value: "100%" },
            { property: "height", value: "auto" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "0px" },
            { property: "border-color", value: "rgb(0, 0, 0)" },
            { property: "border-radius", value: "0rem" },
          ],
        },
      ],
    },
    {
      componentId: "AV6zWG",
      classes: [
        {
          className: "cards-7-title-AV6zWG",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(5, 147, 230)" },
            { property: "margin", value: "0rem 0rem 0.5rem 0rem" },
            { property: "text-align", value: "center" },
          ],
        },
        {
          className: "cards-7-text-AV6zWG",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(33, 37, 41)" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "text-align", value: "center" },
          ],
        },
        {
          className: "cards-7-btn-AV6zWG",
          pseudo: "",
          rules: [
            { property: "background", value: "rgb(5, 147, 230)" },
            { property: "color", value: "rgb(255, 255, 255)" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(5, 147, 230)" },
            { property: "border-radius", value: "1000px" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "padding", value: "0.5rem 0rem 0.5rem 0rem" },
            { property: "text-decoration", value: "none" },
            { property: "text-align", value: "center" },
            { property: "font-weight", value: "bold" },
            { property: "display", value: "block" },
          ],
        },
        {
          className: "cards-7-component-AV6zWG",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
          ],
        },
        {
          className: "cards-7-col-container-AV6zWG",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-top", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "padding-bottom", value: "0.75rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "background-color", value: "rgb(255, 255, 255)" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "cards-7-col-td-wrapper-AV6zWG",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0.5rem 0.75rem 0.5rem 0.75rem" },
            { property: "text-align", value: "start" },
          ],
        },
        {
          className: "cards-7-img-AV6zWG",
          pseudo: "",
          rules: [
            { property: "outline", value: "none" },
            { property: "-ms-interpolation-mode", value: "bicubic" },
            { property: "max-width", value: "100%" },
            { property: "max-height", value: "200px" },
            { property: "width", value: "100%" },
            { property: "height", value: "auto" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "0px" },
            { property: "border-color", value: "rgb(0, 0, 0)" },
            { property: "border-radius", value: "0rem" },
          ],
        },
      ],
    },
    {
      componentId: "Tse8hP",
      classes: [
        { className: "footer-3-table-table-Tse8hP", pseudo: "", rules: [] },
        { className: "footer-3-table-row-Tse8hP", pseudo: "", rules: [] },
        {
          className: "footer-3-table-cell-all-Tse8hP",
          pseudo: "",
          rules: [
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "padding", value: "0px 10px 0px 10px" },
          ],
        },
        {
          className: "footer-3-wrapper-icons-Tse8hP",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "display", value: "block" },
          ],
        },
        {
          className: "footer-3-social-link-Tse8hP",
          pseudo: "",
          rules: [
            { property: "display", value: "inline-block" },
            { property: "text-decoration", value: "none" },
            { property: "margin", value: "0rem 1rem 0rem 0rem" },
          ],
        },
        { className: "footer-3-social-link-Tse8hP", pseudo: "last-child", rules: [{ property: "margin-right", value: "0" }] },
        {
          className: "footer-3-social-icon-Tse8hP",
          pseudo: "",
          rules: [
            { property: "outline", value: "none" },
            { property: "-ms-interpolation-mode", value: "bicubic" },
            { property: "width", value: "32px" },
            { property: "height", value: "32px" },
            { property: "border-radius", value: "50%" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(255, 255, 255)" },
          ],
        },
        {
          className: "footer-3-component-Tse8hP",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
          ],
        },
        {
          className: "footer-3-col-container-Tse8hP",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-bottom", value: "1.5rem" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(234, 237, 240)" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "padding-top", value: "2rem" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "footer-3-col-td-wrapper-Tse8hP",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0.5rem 0rem 0.5rem 0rem" },
            { property: "text-align", value: "start" },
          ],
        },
      ],
    },
    {
      componentId: "LHy66s",
      classes: [
        {
          className: "footer-9-text1-LHy66s",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(108, 117, 125)" },
            { property: "margin", value: "0rem 0rem 0.25rem 0rem" },
            { property: "text-align", value: "center" },
            { property: "font-size", value: "12px" },
          ],
        },
        {
          className: "footer-9-text2-LHy66s",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(108, 117, 125)" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-align", value: "center" },
            { property: "font-size", value: "12px" },
          ],
        },
        {
          className: "footer-9-link-LHy66s",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(108, 117, 125)" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "display", value: "block" },
            { property: "text-align", value: "center" },
            { property: "text-decoration", value: "underline" },
            { property: "font-size", value: "12px" },
          ],
        },
        {
          className: "footer-9-component-LHy66s",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
          ],
        },
        {
          className: "footer-9-col-container-LHy66s",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(234, 237, 240)" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "footer-9-col-td-wrapper-LHy66s",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0.5rem 0rem 0.5rem 0rem" },
            { property: "text-align", value: "start" },
          ],
        },
      ],
    },
  ],
  ebCssVars: {
    fontHeadersName: "Arial",
    fontHeadersWeight: "400",
    fontBodyName: "Arial",
    fontBodyWeight: "400",
    color1: "rgb(5, 147, 230)",
    color2: "rgba(246, 129, 0, 1)",
    color3: "rgba(40, 167, 69, 1)",
    color4: "rgba(13, 202, 240, 1)",
    color5: "rgba(255, 193, 7, 1)",
    color6: "rgba(220, 53, 69, 1)",
    color7: "rgba(233, 236, 239, 1)",
    color8: "rgba(33, 37, 41, 1)",
    color9: "rgba(206, 212, 218, 1)",
    color10: "rgba(108, 117, 125, 1)",
  },
};
