export const template15 = {
  id: "66035656e5f4528e6822758a",
  name: { en: "Invite to an expert panel", nl: "Uitnodiging voor een panelgesprek" },
  desc: { en: "Invite to an expert panel", nl: "Uitnodiging voor een panelgesprek" },
  categories: ["event"],
  ebContent: [
    {
      pageId: "9f290a56-bb0d-4d75-a6cf-706f867c63cd",
      version: "A",
      components: [
        {
          componentId: "z9VlVM",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "basics-7-component-z9VlVM"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "z9Z03H",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "basics-7-col-container-z9VlVM"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "G9PLdN",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-12"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "qniyXZ",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "CXJKwO",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "zRAOIf",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "GBptmi",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["basics-7-col-td-wrapper-z9VlVM"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-name", value: "divider" },
                                    { property: "data-editable", value: "true" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "zkoim5",
                                      type: "",
                                      htmlTagName: "div",
                                      classes: ["basics-7-divider-z9VlVM"],
                                      styles: [],
                                      attributes: [{ property: "data-elementgetter1", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "guJrQM",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "basics-13-component-guJrQM"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "iq4Sk4",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "basics-13-col-container-guJrQM"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "zX7vOl",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-12"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "R1TX9p",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "LvrxVo",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "DBJ2bT",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "s9pNan",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["basics-13-col-td-wrapper-guJrQM"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "N5NIv4",
                                      type: "",
                                      htmlTagName: "a",
                                      classes: ["basics-13-linkwrapper-guJrQM"],
                                      styles: [],
                                      attributes: [
                                        { property: "href", value: "#!" },
                                        { property: "data-href", value: "#!" },
                                        { property: "data-target", value: "_blank" },
                                        { property: "data-linkwrapper", value: "true" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "uCVe9z",
                                          type: "",
                                          htmlTagName: "img",
                                          classes: ["basics-13-img-guJrQM"],
                                          styles: [],
                                          attributes: [
                                            { property: "data-editable", value: "true" },
                                            { property: "data-name", value: "img" },
                                            { property: "src", value: "https://cdn.satonda.com/eb/assets/logo5.png" },
                                            { property: "alt", value: "Logo" },
                                          ],
                                          content: "",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "D45yik",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "basics-1-component-D45yik"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "gjOCDi",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "basics-1-col-container-D45yik"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "illN6m",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-12"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "uU2nbk",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "CdDl4h",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "pEQXaw",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "cXt2RY",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["basics-1-col-td-wrapper-D45yik"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "iPPsjQ",
                                      type: "",
                                      htmlTagName: "h1",
                                      classes: ["basics-1-title-D45yik"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "h" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Expert call: Impact of AI on marketing",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "ExA7wo",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "basics-2-component-ExA7wo"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "QDymiq",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "basics-2-col-container-ExA7wo"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "UeFFeu",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-12"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "Hf16aQ",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "pN9Isb",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "s5kpDX",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "OowvWr",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["basics-2-col-td-wrapper-ExA7wo"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "ARx81L",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["basics-2-text-ExA7wo"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content:
                                            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut magna aliqua. Sit amet porttitor eget dolor morbi non arcu.",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "fDxsUf",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "basics-2-component-fDxsUf"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "fzSO30",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "basics-2-col-container-fDxsUf"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "GqcFKg",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-12"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "CkjSvV",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "c9U4HX",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "UPFOFQ",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "pOBmf9",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["basics-2-col-td-wrapper-fDxsUf"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "F5GDYA",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["basics-2-text-fDxsUf"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Topics we'll discuss:",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "f5kObO",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "cards-11-component-f5kObO"],
          styles: [],
          attributes: [{ property: "data-name", value: "col_component" }],
          content: "",
          children: [
            {
              childId: "jWI74o",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "cards-11-col-container-f5kObO"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "siOKIf",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-4", "vertical-align-top"],
                  styles: [],
                  attributes: [{ property: "data-colLayout", value: "true" }],
                  content: "",
                  children: [
                    {
                      childId: "kYL351",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "cXYjsU",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "t8Psv6",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "KAqed0",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["cards-11-col-td-wrapper-f5kObO"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "jcKlZk",
                                      type: "",
                                      htmlTagName: "h4",
                                      classes: ["cards-11-title-f5kObO"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "h" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Topic #1",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "MtRKC2",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["cards-11-text-f5kObO"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Lorem ipsum dolor sit amet consectetur.",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "ffkFHs",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-4", "vertical-align-top"],
                  styles: [],
                  attributes: [{ property: "data-colLayout", value: "true" }],
                  content: "",
                  children: [
                    {
                      childId: "Y10Ug2",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "Mjvb7r",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "Km5IPP",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "HMwhsn",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["cards-11-col-td-wrapper-f5kObO"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "pAFYAD",
                                      type: "",
                                      htmlTagName: "h4",
                                      classes: ["cards-11-title-f5kObO"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "h" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Topic #2",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "fnjeIS",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["cards-11-text-f5kObO"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Lorem ipsum dolor sit amet consectetur.",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "iOmV1H",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-4", "vertical-align-top"],
                  styles: [],
                  attributes: [{ property: "data-colLayout", value: "true" }],
                  content: "",
                  children: [
                    {
                      childId: "sV7bdV",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "FBpGVl",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "xM8Pl9",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "tzYuYs",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["cards-11-col-td-wrapper-f5kObO"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "to64JP",
                                      type: "",
                                      htmlTagName: "h4",
                                      classes: ["cards-11-title-f5kObO"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "h" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Topic #3",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "IuJN3q",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["cards-11-text-f5kObO"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Lorem ipsum dolor sit amet consectetur.",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "AcyD4j",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "basics-2-component-AcyD4j"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "llmTNV",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "basics-2-col-container-AcyD4j"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "stjj4d",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-12"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "sGoAnN",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "HJu62E",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "CSNpFC",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "kwNYxP",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["basics-2-col-td-wrapper-AcyD4j"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "osG56c",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["basics-2-text-AcyD4j"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Experts you'll hear from:",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "wlu5Hg",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "cards-10-component-wlu5Hg"],
          styles: [],
          attributes: [{ property: "data-name", value: "col_component" }],
          content: "",
          children: [
            {
              childId: "jJq1vp",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "cards-10-col-container-wlu5Hg"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "tgdytM",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-4", "vertical-align-top"],
                  styles: [],
                  attributes: [{ property: "data-colLayout", value: "true" }],
                  content: "",
                  children: [
                    {
                      childId: "NsNJDf",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "RpOUvt",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "NlyUvk",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "hyDh3L",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["cards-10-col-td-wrapper-wlu5Hg"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "Y4SPxI",
                                      type: "",
                                      htmlTagName: "img",
                                      classes: ["cards-10-img-wlu5Hg"],
                                      styles: [],
                                      attributes: [
                                        { property: "src", value: "https://cdn.satonda.com/eb/assets/avatar-1.jpg" },
                                        { property: "alt", value: "Image description" },
                                        { property: "data-editable", value: "true" },
                                        { property: "data-name", value: "img" },
                                      ],
                                      content: "",
                                      children: [],
                                    },
                                    {
                                      childId: "fzC21E",
                                      type: "",
                                      htmlTagName: "h3",
                                      classes: ["cards-10-title-wlu5Hg"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "h" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Rosa Johnson",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "Zc4SiJ",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["cards-10-text-wlu5Hg"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Founder & CEO",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "OE7VXU",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-4", "vertical-align-top"],
                  styles: [],
                  attributes: [{ property: "data-colLayout", value: "true" }],
                  content: "",
                  children: [
                    {
                      childId: "rYbkVO",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "ShZe1x",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "M6FNp7",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "Rs0YTE",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["cards-10-col-td-wrapper-wlu5Hg"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "nbXoto",
                                      type: "",
                                      htmlTagName: "img",
                                      classes: ["cards-10-img-wlu5Hg"],
                                      styles: [],
                                      attributes: [
                                        { property: "src", value: "https://cdn.satonda.com/eb/assets/avatar-2.jpg" },
                                        { property: "alt", value: "Image description" },
                                        { property: "data-editable", value: "true" },
                                        { property: "data-name", value: "img" },
                                      ],
                                      content: "",
                                      children: [],
                                    },
                                    {
                                      childId: "IBAIQQ",
                                      type: "",
                                      htmlTagName: "h3",
                                      classes: ["cards-10-title-wlu5Hg"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "h" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Mike Rogers",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "lGqytP",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["cards-10-text-wlu5Hg"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "CTO",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "Ml6Klx",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-4", "vertical-align-top"],
                  styles: [],
                  attributes: [{ property: "data-colLayout", value: "true" }],
                  content: "",
                  children: [
                    {
                      childId: "Uk4K2F",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "chizd9",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "mj84NP",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "dR6Qpg",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["cards-10-col-td-wrapper-wlu5Hg"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "zDokyA",
                                      type: "",
                                      htmlTagName: "img",
                                      classes: ["cards-10-img-wlu5Hg"],
                                      styles: [],
                                      attributes: [
                                        { property: "src", value: "https://cdn.satonda.com/eb/assets/avatar-3.jpg" },
                                        { property: "alt", value: "Image description" },
                                        { property: "data-editable", value: "true" },
                                        { property: "data-name", value: "img" },
                                      ],
                                      content: "",
                                      children: [],
                                    },
                                    {
                                      childId: "FTvO4x",
                                      type: "",
                                      htmlTagName: "h3",
                                      classes: ["cards-10-title-wlu5Hg"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "h" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Michelle Laredo",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "y1WeMJ",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["cards-10-text-wlu5Hg"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "SEO specialist",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "NZE7no",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "basics-3-component-NZE7no"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "gk3RWK",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "basics-3-col-container-NZE7no"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "bQMcmF",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-12"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "Le02DX",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "oi1iCU",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "j1U0XR",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "xPPq9p",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["basics-3-col-td-wrapper-NZE7no"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "i2dQHa",
                                      type: "",
                                      htmlTagName: "a",
                                      classes: ["basics-3-btn-NZE7no"],
                                      styles: [],
                                      attributes: [
                                        { property: "href", value: "#!" },
                                        { property: "data-href", value: "#!" },
                                        { property: "data-target", value: "_blank" },
                                        { property: "data-editable", value: "true" },
                                        { property: "data-name", value: "button" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Register now!",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "x07fLH",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "footer-5-component-x07fLH"],
          styles: [],
          attributes: [{ property: "data-name", value: "col_component" }],
          content: "",
          children: [
            {
              childId: "w3TIvr",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "footer-5-col-container-x07fLH"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "D7Cmez",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-6", "vertical-align-top"],
                  styles: [],
                  attributes: [{ property: "data-colLayout", value: "true" }],
                  content: "",
                  children: [
                    {
                      childId: "c5BTtV",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "l7Qi04",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "DudQdj",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "LualWX",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["footer-5-col-td-wrapper-x07fLH"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "VAHKXY",
                                      type: "",
                                      htmlTagName: "h1",
                                      classes: ["footer-5-title-x07fLH"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "h" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Contact",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "x3ka9l",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["footer-5-text-x07fLH"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Streetname 40",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "vl2yAm",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["footer-5-text-x07fLH"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "1000 XO Amsterdam",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "DlSsNz",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["footer-5-text-x07fLH"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "The Netherlands",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "W7RVab",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["footer-5-text-x07fLH"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Phone: 123 456 789",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "D2B2hg",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["footer-5-text-x07fLH"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Email: contact@us.com",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "YsPL19",
                                      type: "element",
                                      htmlTagName: "a",
                                      classes: ["footer-5-link-x07fLH"],
                                      styles: [],
                                      attributes: [
                                        { property: "href", value: "#!" },
                                        { property: "data-href", value: "#!" },
                                        { property: "data-target", value: "_blank" },
                                        { property: "data-editable", value: "true" },
                                        { property: "data-name", value: "a" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Visit our website",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "XotDhw",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-6", "vertical-align-top"],
                  styles: [],
                  attributes: [{ property: "data-colLayout", value: "true" }],
                  content: "",
                  children: [
                    {
                      childId: "pC4T90",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "pVGvrX",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "nc24at",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "LuJpMP",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["footer-5-col-td-wrapper-x07fLH"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "YDffGE",
                                      type: "",
                                      htmlTagName: "h1",
                                      classes: ["footer-5-title-x07fLH"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "h" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Follow us",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "YftDrx",
                                      type: "block",
                                      htmlTagName: "div",
                                      classes: ["footer-5-wrapper-icons-x07fLH"],
                                      styles: [],
                                      attributes: [{ property: "data-name", value: "block" }],
                                      content: "",
                                      children: [
                                        {
                                          childId: "SfTM9U",
                                          type: "element",
                                          htmlTagName: "a",
                                          classes: ["footer-5-social-link-x07fLH"],
                                          styles: [],
                                          attributes: [
                                            { property: "href", value: "#!" },
                                            { property: "data-href", value: "#!" },
                                            { property: "data-target", value: "_blank" },
                                            { property: "data-editable", value: "true" },
                                            { property: "data-name", value: "socialIcon" },
                                          ],
                                          content: "",
                                          children: [
                                            {
                                              childId: "H19QoZ",
                                              type: "",
                                              htmlTagName: "img",
                                              classes: ["footer-5-social-icon-x07fLH"],
                                              styles: [],
                                              attributes: [
                                                { property: "src", value: "https://cdn.satonda.com/eb/assets/facebook-brand-white-square.png" },
                                                { property: "alt", value: "Facebook" },
                                                { property: "data-editable", value: "false" },
                                              ],
                                              content: "",
                                              children: [],
                                            },
                                          ],
                                        },
                                        {
                                          childId: "Bfgvmt",
                                          type: "element",
                                          htmlTagName: "a",
                                          classes: ["footer-5-social-link-x07fLH"],
                                          styles: [],
                                          attributes: [
                                            { property: "href", value: "#!" },
                                            { property: "data-href", value: "#!" },
                                            { property: "data-target", value: "_blank" },
                                            { property: "data-editable", value: "true" },
                                            { property: "data-name", value: "socialIcon" },
                                          ],
                                          content: "",
                                          children: [
                                            {
                                              childId: "qupt40",
                                              type: "",
                                              htmlTagName: "img",
                                              classes: ["footer-5-social-icon-x07fLH"],
                                              styles: [],
                                              attributes: [
                                                { property: "src", value: "https://cdn.satonda.com/eb/assets/twitter-brand-white-square.png" },
                                                { property: "alt", value: "Twitter" },
                                                { property: "data-editable", value: "false" },
                                              ],
                                              content: "",
                                              children: [],
                                            },
                                          ],
                                        },
                                        {
                                          childId: "XCJAQ5",
                                          type: "element",
                                          htmlTagName: "a",
                                          classes: ["footer-5-social-link-x07fLH"],
                                          styles: [],
                                          attributes: [
                                            { property: "href", value: "#!" },
                                            { property: "data-href", value: "#!" },
                                            { property: "data-target", value: "_blank" },
                                            { property: "data-editable", value: "true" },
                                            { property: "data-name", value: "socialIcon" },
                                          ],
                                          content: "",
                                          children: [
                                            {
                                              childId: "wS0teM",
                                              type: "",
                                              htmlTagName: "img",
                                              classes: ["footer-5-social-icon-x07fLH"],
                                              styles: [],
                                              attributes: [
                                                { property: "src", value: "https://cdn.satonda.com/eb/assets/youtube-brand-white-square.png" },
                                                { property: "alt", value: "Youtube" },
                                                { property: "data-editable", value: "false" },
                                              ],
                                              content: "",
                                              children: [],
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "qNXVaD",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "footer-9-component-qNXVaD"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "QfDvGw",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "footer-9-col-container-qNXVaD"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "ISDZfk",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-12"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "OuMYEv",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "wTfPv7",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "IAZdjU",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "yCCsai",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["footer-9-col-td-wrapper-qNXVaD"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "D6lA4r",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["footer-9-text1-qNXVaD"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                        { property: "data-uniqueclassname", value: "true" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "© Company name",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "g8XbXF",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["footer-9-text2-qNXVaD"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                        { property: "data-uniqueclassname", value: "true" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Streetname 1, 1000 XX Amsterdam, The Netherlands",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "F0BwO2",
                                      type: "element",
                                      htmlTagName: "a",
                                      classes: ["footer-9-link-qNXVaD"],
                                      styles: [],
                                      attributes: [
                                        { property: "href", value: "#!" },
                                        { property: "data-href", value: "%UNSUBSCRIBE_LINK%" },
                                        { property: "data-target", value: "_blank" },
                                        { property: "data-editable", value: "true" },
                                        { property: "data-name", value: "a" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Unsubscribe",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  ebCustomCss: [
    {
      componentId: "z9VlVM",
      classes: [
        {
          className: "basics-7-component-z9VlVM",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
          ],
        },
        {
          className: "basics-7-col-container-z9VlVM",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(234, 237, 240)" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "basics-7-col-td-wrapper-z9VlVM",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0rem 0rem 0rem 0rem" },
            { property: "text-align", value: "start" },
          ],
        },
        {
          className: "basics-7-divider-z9VlVM",
          pseudo: "",
          rules: [
            { property: "display", value: "inline-block" },
            { property: "height", value: "20px" },
            { property: "width", value: "100%" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
            { property: "border-radius", value: "0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(230, 230, 230)" },
          ],
        },
      ],
    },
    {
      componentId: "guJrQM",
      classes: [
        {
          className: "basics-13-linkwrapper-guJrQM",
          pseudo: "",
          rules: [
            { property: "display", value: "block" },
            { property: "line-height", value: "0 !important" },
          ],
        },
        {
          className: "basics-13-img-guJrQM",
          pseudo: "",
          rules: [
            { property: "outline", value: "none" },
            { property: "-ms-interpolation-mode", value: "bicubic" },
            { property: "max-width", value: "100%" },
            { property: "max-height", value: "200px" },
            { property: "width", value: "auto" },
            { property: "height", value: "auto" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "0px" },
            { property: "border-color", value: "rgb(0, 0, 0)" },
            { property: "border-radius", value: "0rem" },
          ],
        },
        {
          className: "basics-13-component-guJrQM",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
          ],
        },
        {
          className: "basics-13-col-container-guJrQM",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-top", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(234, 237, 240)" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "padding-bottom", value: "0.75rem" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "basics-13-col-td-wrapper-guJrQM",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0.5rem 0rem 0.5rem 0rem" },
            { property: "text-align", value: "center" },
          ],
        },
      ],
    },
    {
      componentId: "D45yik",
      classes: [
        {
          className: "basics-1-component-D45yik",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
          ],
        },
        {
          className: "basics-1-col-container-D45yik",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "background-color", value: "rgb(255, 255, 255)" },
            { property: "padding-top", value: "0.5rem" },
            { property: "border-radius", value: "0.75rem 0.75rem 0 0" },
          ],
        },
        {
          className: "basics-1-col-td-wrapper-D45yik",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0.5rem 0.75rem 0.5rem 0.75rem" },
            { property: "text-align", value: "start" },
          ],
        },
        {
          className: "basics-1-title-D45yik",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgb(229, 42, 5)" },
            { property: "font-weight", value: "bold" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
          ],
        },
      ],
    },
    {
      componentId: "ExA7wo",
      classes: [
        {
          className: "basics-2-component-ExA7wo",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
          ],
        },
        {
          className: "basics-2-col-container-ExA7wo",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "background-color", value: "rgb(255, 255, 255)" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "basics-2-col-td-wrapper-ExA7wo",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0.5rem 0.75rem 0.5rem 0.75rem" },
            { property: "text-align", value: "start" },
          ],
        },
        {
          className: "basics-2-text-ExA7wo",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(33, 37, 41)" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
          ],
        },
      ],
    },
    {
      componentId: "fDxsUf",
      classes: [
        {
          className: "basics-2-text-fDxsUf",
          pseudo: "",
          rules: [
            { property: "text-align", value: "start" },
            { property: "color", value: "rgb(229, 42, 5)" },
            { property: "font-weight", value: "bold" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "basics-2-component-fDxsUf",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
          ],
        },
        {
          className: "basics-2-col-container-fDxsUf",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "background-color", value: "rgb(255, 255, 255)" },
            { property: "padding-top", value: "1.5rem" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "basics-2-col-td-wrapper-fDxsUf",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0.5rem 0.75rem 0.5rem 0.75rem" },
            { property: "text-align", value: "start" },
          ],
        },
      ],
    },
    {
      componentId: "f5kObO",
      classes: [
        {
          className: "cards-11-text-f5kObO",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(33, 37, 41)" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "text-align", value: "center" },
          ],
        },
        {
          className: "cards-11-component-f5kObO",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
          ],
        },
        {
          className: "cards-11-col-container-f5kObO",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "background-color", value: "rgb(255, 255, 255)" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "cards-11-col-td-wrapper-f5kObO",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0.5rem 0.75rem 0.5rem 0.75rem" },
            { property: "text-align", value: "start" },
          ],
        },
        {
          className: "cards-11-title-f5kObO",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgb(229, 42, 5)" },
            { property: "margin", value: "0rem 0rem 0.5rem 0rem" },
          ],
        },
      ],
    },
    {
      componentId: "wlu5Hg",
      classes: [
        {
          className: "cards-10-title-wlu5Hg",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(229, 42, 5)" },
            { property: "margin", value: "0rem 0rem 0.5rem 0rem" },
            { property: "text-align", value: "center" },
            { property: "font-weight", value: "bold" },
          ],
        },
        {
          className: "cards-10-text-wlu5Hg",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(33, 37, 41)" },
            { property: "margin", value: "0rem 0rem 0.5rem 0rem" },
            { property: "text-align", value: "center" },
          ],
        },
        {
          className: "cards-10-img-wlu5Hg",
          pseudo: "",
          rules: [
            { property: "outline", value: "none" },
            { property: "-ms-interpolation-mode", value: "bicubic" },
            { property: "max-width", value: "200px" },
            { property: "width", value: "100%" },
            { property: "max-height", value: "200px" },
            { property: "height", value: "auto" },
            { property: "margin", value: "0rem 0rem 0.5rem 0rem" },
            { property: "border-radius", value: "50%" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "2px" },
            { property: "border-color", value: "rgb(229, 42, 5)" },
          ],
        },
        {
          className: "cards-10-component-wlu5Hg",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
          ],
        },
        {
          className: "cards-10-col-container-wlu5Hg",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "background-color", value: "rgb(255, 255, 255)" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "cards-10-col-td-wrapper-wlu5Hg",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0.5rem 0.75rem 0.5rem 0.75rem" },
            { property: "text-align", value: "center" },
          ],
        },
      ],
    },
    {
      componentId: "AcyD4j",
      classes: [
        {
          className: "basics-2-text-AcyD4j",
          pseudo: "",
          rules: [
            { property: "text-align", value: "start" },
            { property: "color", value: "rgb(229, 42, 5)" },
            { property: "font-weight", value: "bold" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "basics-2-component-AcyD4j",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
          ],
        },
        {
          className: "basics-2-col-container-AcyD4j",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "background-color", value: "rgb(255, 255, 255)" },
            { property: "padding-top", value: "1.5rem" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "basics-2-col-td-wrapper-AcyD4j",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0.5rem 0.75rem 0.5rem 0.75rem" },
            { property: "text-align", value: "start" },
          ],
        },
      ],
    },
    {
      componentId: "x07fLH",
      classes: [
        {
          className: "footer-5-text-x07fLH",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(33, 37, 41)" },
            { property: "margin", value: "0rem 0rem 0.125rem 0rem" },
            { property: "text-align", value: "start" },
            { property: "font-size", value: "12px" },
          ],
        },
        {
          className: "footer-5-link-x07fLH",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(244, 126, 0)" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "display", value: "block" },
            { property: "text-decoration", value: "underline" },
            { property: "font-size", value: "12px" },
          ],
        },
        {
          className: "footer-5-wrapper-icons-x07fLH",
          pseudo: "",
          rules: [
            { property: "text-align", value: "start" },
            { property: "display", value: "block" },
          ],
        },
        {
          className: "footer-5-social-link-x07fLH",
          pseudo: "",
          rules: [
            { property: "display", value: "inline-block" },
            { property: "text-decoration", value: "none" },
            { property: "margin", value: "0rem 1rem 0rem 0rem" },
          ],
        },
        { className: "footer-5-social-link-x07fLH", pseudo: "last-child", rules: [{ property: "margin-right", value: "0" }] },
        {
          className: "footer-5-social-icon-x07fLH",
          pseudo: "",
          rules: [
            { property: "outline", value: "none" },
            { property: "-ms-interpolation-mode", value: "bicubic" },
            { property: "width", value: "32px" },
            { property: "height", value: "32px" },
            { property: "border-radius", value: "50%" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(255, 255, 255)" },
          ],
        },
        {
          className: "footer-5-title-x07fLH",
          pseudo: "",
          rules: [
            { property: "text-align", value: "start" },
            { property: "color", value: "rgb(108, 117, 125)" },
            { property: "font-weight", value: "bold" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
          ],
        },
        {
          className: "footer-5-component-x07fLH",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
          ],
        },
        {
          className: "footer-5-col-container-x07fLH",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-bottom", value: "1rem" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "background-color", value: "rgb(255, 255, 255)" },
            { property: "padding-top", value: "1.5rem" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "footer-5-col-td-wrapper-x07fLH",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0.5rem 0.75rem 0.5rem 0.75rem" },
            { property: "text-align", value: "start" },
          ],
        },
      ],
    },
    {
      componentId: "BdfCq8",
      classes: [
        {
          className: "footer-10-text1-BdfCq8",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(108, 117, 125)" },
            { property: "margin", value: "0rem 0rem 0.125rem 0rem" },
            { property: "text-align", value: "center" },
            { property: "font-size", value: "12px" },
          ],
        },
        {
          className: "footer-10-text2-BdfCq8",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(108, 117, 125)" },
            { property: "margin", value: "0rem 0rem 0.125rem 0rem" },
            { property: "text-align", value: "center" },
            { property: "font-size", value: "12px" },
          ],
        },
        {
          className: "footer-10-text3-BdfCq8",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(108, 117, 125)" },
            { property: "margin", value: "1rem 0rem 0.125rem 0rem" },
            { property: "text-align", value: "center" },
            { property: "font-size", value: "12px" },
          ],
        },
        {
          className: "footer-10-text4-BdfCq8",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(108, 117, 125)" },
            { property: "margin", value: "0rem 0rem 0.125rem 0rem" },
            { property: "text-align", value: "center" },
            { property: "font-size", value: "12px" },
          ],
        },
        {
          className: "footer-10-text5-BdfCq8",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(108, 117, 125)" },
            { property: "margin", value: "0rem 0rem 0.125rem 0rem" },
            { property: "text-align", value: "center" },
            { property: "font-size", value: "12px" },
          ],
        },
        {
          className: "footer-10-text6-BdfCq8",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(108, 117, 125)" },
            { property: "margin", value: "0rem 0rem 0.25rem 0rem" },
            { property: "text-align", value: "center" },
            { property: "font-size", value: "12px" },
          ],
        },
        {
          className: "footer-10-link-BdfCq8",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(108, 117, 125)" },
            { property: "margin", value: "1rem 0rem 0rem 0rem" },
            { property: "display", value: "block" },
            { property: "text-align", value: "center" },
            { property: "text-decoration", value: "underline" },
            { property: "font-size", value: "12px" },
          ],
        },
        {
          className: "footer-10-component-BdfCq8",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
          ],
        },
        {
          className: "footer-10-col-container-BdfCq8",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(234, 237, 240)" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "padding-top", value: "1.5rem" },
            { property: "padding-bottom", value: "3rem" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "footer-10-col-td-wrapper-BdfCq8",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0.5rem 0rem 0.5rem 0rem" },
            { property: "text-align", value: "start" },
          ],
        },
      ],
    },
    {
      componentId: "qNXVaD",
      classes: [
        {
          className: "footer-9-text1-qNXVaD",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(108, 117, 125)" },
            { property: "margin", value: "0rem 0rem 0.25rem 0rem" },
            { property: "text-align", value: "center" },
            { property: "font-size", value: "12px" },
          ],
        },
        {
          className: "footer-9-text2-qNXVaD",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(108, 117, 125)" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-align", value: "center" },
            { property: "font-size", value: "12px" },
          ],
        },
        {
          className: "footer-9-link-qNXVaD",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(108, 117, 125)" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "display", value: "block" },
            { property: "text-align", value: "center" },
            { property: "text-decoration", value: "underline" },
            { property: "font-size", value: "12px" },
          ],
        },
        {
          className: "footer-9-component-qNXVaD",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
          ],
        },
        {
          className: "footer-9-col-container-qNXVaD",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(234, 237, 240)" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "padding-top", value: "1.5rem" },
            { property: "padding-bottom", value: "3rem" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "footer-9-col-td-wrapper-qNXVaD",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0.5rem 0rem 0.5rem 0rem" },
            { property: "text-align", value: "start" },
          ],
        },
      ],
    },
    {
      componentId: "NZE7no",
      classes: [
        {
          className: "basics-3-btn-NZE7no",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "font-weight", value: "bold" },
            { property: "font-style", value: "normal" },
            { property: "text-decoration", value: "none" },
            { property: "color", value: "rgb(230, 42, 5)" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(229, 42, 5)" },
            { property: "border-radius", value: "1000px" },
            { property: "padding", value: "0.5rem 0rem 0.5rem 0rem" },
            { property: "display", value: "block" },
            { property: "font-size", value: "16px" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "basics-3-component-NZE7no",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
          ],
        },
        {
          className: "basics-3-col-container-NZE7no",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "background-color", value: "rgb(255, 255, 255)" },
            { property: "padding-top", value: "1rem" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "basics-3-col-td-wrapper-NZE7no",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0.5rem 0.75rem 0.5rem 0.75rem" },
            { property: "text-align", value: "start" },
          ],
        },
      ],
    },
  ],
  ebCssVars: {
    fontHeadersName: "Arial",
    fontHeadersWeight: "400",
    fontBodyName: "Arial",
    fontBodyWeight: "400",
    color1: "rgba(229, 42, 5, 1)",
    color2: "rgba(246, 129, 0, 1)",
    color3: "rgba(40, 167, 69, 1)",
    color4: "rgba(13, 202, 240, 1)",
    color5: "rgba(255, 193, 7, 1)",
    color6: "rgba(220, 53, 69, 1)",
    color7: "rgba(233, 236, 239, 1)",
    color8: "rgba(33, 37, 41, 1)",
    color9: "rgba(206, 212, 218, 1)",
    color10: "rgba(108, 117, 125, 1)",
  },
};
