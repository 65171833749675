// https://observablehq.com/@hugodf/mongodb-objectid-generator

import { template1 } from "./template1";
import { template2 } from "./template2";
import { template3 } from "./template3";
import { template4 } from "./template4";
import { template5 } from "./template5";
import { template6 } from "./template6";
import { template7 } from "./template7";
import { template8 } from "./template8";
import { template9 } from "./template9";
import { template10 } from "./template10";
import { template11 } from "./template11";
import { template12 } from "./template12";
import { template13 } from "./template13";
import { template14 } from "./template14";
import { template15 } from "./template15";
// import { template16 } from "./template16";

export const templates = [
  template1,
  template2,
  template3,
  template4,
  template5,
  template6,
  template7,
  template8,
  template9,
  template10,
  template11,
  template12,
  template13,
  template14,
  template15,
  // template16
];
