// Import dependencies
import axios from "axios";

const setBrandId = (brandId) => {
  if (brandId) {
    // If there is, add it to the axios global headers
    axios.defaults.headers.common["x-auth-brandid"] = brandId;
  } else {
    // If there is no id passed in, delete the one that was set as the global header
    delete axios.defaults.headers.common["x-auth-brandid"];
  }
};

export default setBrandId;
