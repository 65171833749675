export const mModalPageOptions = {
  // EmailLangCode
  langCodeUpdated: {
    en: "Language code successfully updated!",
    nl: "Taal bijgewerkt!",
  },
  setEmailLangCode: {
    en: "Set mail languade code",
    nl: "Email taal instellen",
  },
  searchLanguage: {
    en: "Search language by name (in English)",
    nl: "Zoek taal bij naam (in het Engels)",
  },
  selectYourLang: {
    en: "Select your language",
    nl: "Selecteer uw taal",
  },
  noSpecificLang: {
    en: "No specific language",
    nl: "Geen specifieke taal",
  },
  updateLangCode: {
    en: "Update language code",
    nl: "Taal bijwerken",
  },
  // MailErrors
  component: {
    en: "Component",
    nl: "Component",
  },
  linkWithoutDest: {
    en: "Link without a destination found",
    nl: "Link zonder bestemming gevonden",
  },
  imgWithoutDesc: {
    en: "Image without a description found",
    nl: "Afbeelding zonder beschrijving gevonden",
  },
  nonExistingDynVar: {
    en: "Non-existing dynamic content variable name found (%wrongVarName%)",
    nl: "Niet-bestaande dynamische inhoud variabele naam gevonden (%wrongVarName%)",
  },
  mailErrors: {
    en: "Mail errors",
    nl: "Email fouten",
  },
  noErrorsFound: {
    en: "Success: No errors found",
    nl: "Succes: Geen fouten gevonden",
  },
  foundErrors: {
    en: "We've found the following errors/optimizations",
    nl: "We hebben de volgende fouten / optimilisaties gevonden",
  },
  clickCheckForErrors: {
    en: `Click on the "check for errors" button to run a test to find any errors or possible optimizations in your email draft`,
    nl: `Klik op de "Vind fouten" knop om uw email te testen op fouten of mogelijke optimalisaties`,
  },
  checkForErrors: {
    en: "Check for errors",
    nl: "Vind fouten",
  },
  // SelectAddVersion
  newVersionAdded: {
    en: "New version has been added!",
    nl: "Nieuwe versie gecreerd!",
  },
  reachedMaxVersions: {
    en: "You've reached the maximum number of versions per project in your subscription package. Please upgrade to be able to have more versions.",
    nl: "U heeft het maximale aantal paginas per project in uw pakket bereikt. Upgrade aub uw pakket om meer paginas te kunnen maken.",
  },
  versionRemoved: {
    en: "Version has been removed!",
    nl: "Versie verwijderd!",
  },
  selectAddRemoveVersions: {
    en: "Select, add or remove email versions",
    nl: "Selecteer, maak of verwijder email versies",
  },
  explainerMultipleVersions: {
    en: "You can create multiple versions of the same email, to test which one leads to the highest engagement with your recipients. When sending the emails, which version of the email each recipient receives will be randomized.",
    nl: "U kunt meerdere versies van uw email maken, zodat u kunt testen welke het beste resultaat boeken met uw ontvangers. Wanneer de emails verzonden worden, zal er willekeurig bepaald worden welke ontvanger welke versie krijgt te zien.",
  },
  selectVersionToEdit: {
    en: "Select version to edit",
    nl: "Selecteer versie om bij te werken",
  },
  version: {
    en: "Version",
    nl: "Versie",
  },
  selectVersionToDuplicate: {
    en: "Select version to duplicate",
    nl: "Selecteer versie om te dupliceren",
  },
  selectVersionAsBase: {
    en: "Select version to use as a base for new version",
    nl: "Selecteer versie om te gebruiken als basis voor de nieuwe versie",
  },
  addAdditionalVersion: {
    en: "Add additional version",
    nl: "Maak nieuwe versie",
  },
  selectVersionToRemove: {
    en: "Select version to remove",
    nl: "Selecteer versie om te verwijderen",
  },
  removeVersion: {
    en: "Remove version",
    nl: "Verwijder versie",
  },
  // Modal
  mailOptions: {
    en: "Mail options",
    nl: "Mail opties",
  },
  setMailLang: {
    en: "Set mail language meta data",
    nl: "Stel mail taal in",
  },
  selectOrAddNewVersion: {
    en: "Select or add new version",
    nl: "Selecteer of maak nieuwe versie",
  },
  checkErrors: {
    en: "Check errors",
    nl: "Vind fouten",
  },
  close: {
    en: "Close",
    nl: "Sluiten",
  },
};
