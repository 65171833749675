import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Select from "./formComponents/Select";

import { formGetStartValue } from "../../../../lib/editFunctions";
import { translate } from "../../../../translations/translations";

const AlignHori = ({ eb: { startStyles } }) => {
  return (
    <>
      <Select
        label={translate("cEditForms.alignItemHori", false, null)}
        id={"alignHori"}
        options={[
          { val: "start", label: translate("cEditForms.left", false, null) },
          { val: "center", label: translate("cEditForms.center", false, null) },
          { val: "end", label: translate("cEditForms.right", false, null) },
        ]}
        startValue={formGetStartValue(startStyles, "alignHori")}
      />
    </>
  );
};

AlignHori.propTypes = {
  eb: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  eb: state.eb,
});

export default connect(mapStateToProps, null)(AlignHori);
