import store from "../store";
import { getState } from "./stateFunctions";
import { getTargetElement, getRandomId, getRandomString } from "./domFunctions";
import { ensureRgba, getRgbValuesFromString, isColorString } from "./colorFunctions";
import icon_alignCenter from "./textEditorIcons/align-center.svg";
import icon_alignJustify from "./textEditorIcons/align-justify.svg";
import icon_alignLeft from "./textEditorIcons/align-left.svg";
import icon_alignRight from "./textEditorIcons/align-right.svg";
import icon_bold from "./textEditorIcons/bold.svg";
import icon_erase from "./textEditorIcons/eraser.svg";
import icon_italic from "./textEditorIcons/italic.svg";
import icon_link from "./textEditorIcons/link.svg";
import icon_list_ol from "./textEditorIcons/list-ol.svg";
import icon_list_ul from "./textEditorIcons/list-ul.svg";
import icon_colorpicker from "./textEditorIcons/palette.svg";
import icon_redo from "./textEditorIcons/redo-alt.svg";
import icon_emoji from "./textEditorIcons/smile.svg";
import icon_strikethrough from "./textEditorIcons/strikethrough.svg";
import icon_subscript from "./textEditorIcons/subscript.svg";
import icon_superscript from "./textEditorIcons/superscript.svg";
import icon_underline from "./textEditorIcons/underline.svg";
import icon_undo from "./textEditorIcons/undo-alt.svg";
import { convertToRaw } from "draft-js";
import DOMPurify from "dompurify";
import { draftToHtml } from "./draftHtmlConvert";
import { getTargetObj } from "./componentObjectFunctions";
import { convertHtml } from "./parse";
import { updateExistingTextElement, addNewTextElement, deleteElement } from "../actions/eb";

// =========================
// ===== Exported vars =====
// =========================
export const TEXT_EDITOR_EDITABLE_CSS_PROPS = [
  "text-align",
  "font-weight",
  "font-style",
  "text-decoration",
  "color",
  "background",
  "background-color",
  "font-family",
  "font-size",
];

// =====================
// ===== Functions =====
// =====================

export const updateTextEditorChanges = (editorState) => {
  try {
    let selectedElement = getState("eb", "selectedElement");
    let target = getTargetElement(selectedElement);
    if (target.dataset.texteditable === "true") {
      // Get html
      let exportedHtml = DOMPurify.sanitize(draftToHtml(convertToRaw(editorState.getCurrentContent())));
      if (target.localName === "a") {
        // Re-write as a link
        exportedHtml = exportedHtml
          .replace(/\n/g, "")
          .replace(/^<p>/, "<a>")
          .replace(/<\/p>$/, "</a>");
      }
      // Create DOM nodes for easier traversing
      let tempDiv = document.createElement("div");
      tempDiv.className = "d-none";
      tempDiv.innerHTML = exportedHtml;
      document.body.appendChild(tempDiv);
      // Need to reverse, in case that multiple new text elements are created get added in the right order
      let elements = [...tempDiv.children].reverse();
      elements.forEach((element, i) => {
        if (i === elements.length - 1) {
          // Update existing element in virtual DOM
          if ([...element.childNodes].length === 0) {
            // Element doesn't have any content => delete from ebContent
            // let splitId = selectedElement.split("-");
            // store.dispatch(deleteElement(splitId[splitId.length - 1]));
            store.dispatch(deleteElement(selectedElement));
          } else {
            // Element has content => reflect updates
            pullOutStylingElements(element);
            // Get element's customCss rules
            let styleRules = getStyleRulesAndRemoveFromElement(element);
            // Get ebContent meta data and add to DOM element
            addEbPagesMetaDataToUpdatedElement(target, element);
            // Check for inserted links
            handleInsertedLinks(element);
            let elementObj = convertHtml(element, false);
            store.dispatch(updateExistingTextElement(elementObj, styleRules));
          }
        } else {
          // Create new element in virtual DOM
          pullOutStylingElements(element);
          // Get element's customCss rules
          let styleRules = getStyleRulesAndRemoveFromElement(element);
          // Get ebContent meta data and add to DOM element
          addEbPagesMetaDataToNewElement(element);
          // Check for inserted links
          handleInsertedLinks(element);
          let elementObj = convertHtml(element, false);
          store.dispatch(addNewTextElement(elementObj, styleRules));
        }
      });
    }
  } catch (error) {
    console.error(error);
  }
};

export const getEditorConfig = (cssVars) => {
  return {
    // Change sequence of options and which to show in below
    options: [
      "fontFamily",
      "blockType",
      "fontSize",
      "inline",
      "list",
      "textAlign",
      "colorPicker",
      "link",
      // "embedded",
      "emoji",
      // "image",
      "remove",
      "history",
    ],
    inline: {
      inDropdown: false,
      className: `${classWrapper} editor-inline`,
      component: undefined,
      dropdownClassName: undefined,
      options: [
        "bold",
        "italic",
        "underline",
        "strikethrough",
        // "monospace",
        "superscript",
        "subscript",
      ],
      bold: {
        icon: icon_bold,
        className: `${classBtn}`,
      },
      italic: {
        icon: icon_italic,
        className: `${classBtn}`,
      },
      underline: {
        icon: icon_underline,
        className: `${classBtn}`,
      },
      strikethrough: {
        icon: icon_strikethrough,
        className: `${classBtn}`,
      },
      monospace: {
        // icon: monospace,
        className: `${classBtn}`,
      },
      superscript: {
        icon: icon_superscript,
        className: `${classBtn}`,
      },
      subscript: {
        icon: icon_subscript,
        className: `${classBtn}`,
      },
    },
    blockType: {
      inDropdown: true,
      options: [
        "Normal",
        "H1",
        "H2",
        "H3",
        "H4",
        "H5",
        "H6",
        // "Blockquote",
        // "Code"
      ],
      className: `${classWrapper}`,
      component: undefined,
      dropdownClassName: undefined,
    },
    fontSize: {
      // icon: fontSize,
      options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
      className: `${classWrapper}`,
      component: undefined,
      dropdownClassName: undefined,
    },
    fontFamily: {
      // options: ["Arial", "Georgia", "Impact", "Tahoma", "Times New Roman", "Verdana"],
      options: config_getProjectFontFamilies(cssVars),
      className: `${classWrapper}`,
      component: undefined,
      dropdownClassName: undefined,
    },
    list: {
      inDropdown: false,
      className: `${classWrapper}`,
      component: undefined,
      dropdownClassName: undefined,
      // options: ["unordered", "ordered", "indent", "outdent"],
      options: ["unordered", "ordered"],
      unordered: { icon: icon_list_ul, className: `${classBtn}` },
      ordered: { icon: icon_list_ol, className: `${classBtn}` },
      // indent: { icon: icon_indent, className: `${classBtn}` },
      // outdent: { icon: icon_outdent, className: `${classBtn}` },
    },
    textAlign: {
      inDropdown: false,
      className: `${classWrapper}`,
      component: undefined,
      dropdownClassName: undefined,
      options: ["left", "center", "right", "justify"],
      left: { icon: icon_alignLeft, className: `${classBtn}` },
      center: { icon: icon_alignCenter, className: `${classBtn}` },
      right: { icon: icon_alignRight, className: `${classBtn}` },
      justify: { icon: icon_alignJustify, className: `${classBtn}` },
    },
    colorPicker: {
      icon: icon_colorpicker,
      className: `${classWrapper} ${classBtn}`,
      component: undefined,
      popupClassName: undefined,
      colors: config_getProjectColors(cssVars),
      // colors: ["rgba(97,189,109, 1)", "rgba(26,188,156, 1)", "rgba(84,172,210, 1)", "rgba(44,130,201, 1)"],
    },
    link: {
      inDropdown: false,
      className: `${classWrapper}`,
      component: undefined,
      popupClassName: undefined,
      dropdownClassName: undefined,
      showOpenOptionOnHover: true,
      defaultTargetOption: "_blank",
      // options: ["link", "unlink"],
      options: ["link"],
      link: { icon: icon_link, className: `${classBtn}` },
      // unlink: { icon: unlink, className: undefined },
      linkCallback: undefined,
    },
    emoji: {
      icon: icon_emoji,
      className: `${classBtn}`,
      component: undefined,
      popupClassName: undefined,
      // Full list emojis: https://www.w3schools.com/charsets/ref_emoji.asp
      emojis: [
        "😀",
        "😁",
        "😂",
        "😃",
        "😉",
        "😋",
        "😎",
        "😍",
        "😗",
        "🤗",
        "🤔",
        "😣",
        "😫",
        "😴",
        "😌",
        "🤓",
        "😛",
        "😜",
        "😠",
        "😇",
        "😷",
        "😈",
        "👻",
        "😺",
        "😸",
        "😹",
        "😻",
        "😼",
        "😽",
        "🙀",
        "🙈",
        "🙉",
        "🙊",
        "👼",
        "👮",
        "🕵",
        "💂",
        "👳",
        "🎅",
        "👸",
        "👰",
        "👲",
        "🙍",
        "🙇",
        "🚶",
        "🏃",
        "💃",
        "⛷",
        "🏂",
        "🏌",
        "🏄",
        "🚣",
        "🏊",
        "⛹",
        "🏋",
        "🚴",
        "👫",
        "💪",
        "👈",
        "👉",
        "👉",
        "👆",
        "🖕",
        "👇",
        "🖖",
        "🤘",
        "🖐",
        "👌",
        "👍",
        "👎",
        "✊",
        "👊",
        "👏",
        "🙌",
        "🙏",
        "🐵",
        "🐶",
        "🐇",
        "🐥",
        "🐸",
        "🐌",
        "🐛",
        "🐜",
        "🐝",
        "🍉",
        "🍄",
        "🍔",
        "🍤",
        "🍨",
        "🍪",
        "🎂",
        "🍰",
        "🍾",
        "🍷",
        "🍸",
        "🍺",
        "🌍",
        "🚑",
        "⏰",
        "🌙",
        "🌝",
        "🌞",
        "⭐",
        "🌟",
        "🌠",
        "🌨",
        "🌩",
        "⛄",
        "🔥",
        "🎄",
        "🎈",
        "🎉",
        "🎊",
        "🎁",
        "🎗",
        "🏀",
        "🏈",
        "🎲",
        "🔇",
        "🔈",
        "📣",
        "🔔",
        "🎵",
        "🎷",
        "💰",
        "🖊",
        "📅",
        "✅",
        "❎",
        "💯",
        "✔",
        "✖",
      ],
    },
    embedded: {
      // icon: embedded,
      className: `${classWrapper}`,
      component: undefined,
      popupClassName: undefined,
      embedCallback: undefined,
      defaultSize: {
        height: "auto",
        width: "auto",
      },
    },
    image: {
      // icon: image,
      className: `${classWrapper}`,
      component: undefined,
      popupClassName: undefined,
      urlEnabled: true,
      uploadEnabled: true,
      alignmentEnabled: true,
      uploadCallback: undefined,
      previewImage: false,
      inputAccept: "image/gif,image/jpeg,image/jpg,image/png,image/svg",
      alt: { present: false, mandatory: false },
      defaultSize: {
        height: "auto",
        width: "auto",
      },
    },
    remove: { icon: icon_erase, className: `${classBtn}`, component: undefined },
    history: {
      inDropdown: false,
      className: `${classWrapper}`,
      component: undefined,
      dropdownClassName: undefined,
      options: ["undo", "redo"],
      undo: { icon: icon_undo, className: `${classBtn}` },
      redo: { icon: icon_redo, className: `${classBtn}` },
    },
  };
};

export const customStyleMap = {
  // Not used
  TEXT_UPPERCASE: {
    textTransform: "uppercase",
  },
};

export const importHtml = (selectedTextElement, customCss) => {
  // Prepare an html string that can be loaded into the text editor
  // Requires moving selectedTextElement´s styling from its CSS class to inline style
  // Only done for the selectedTextElement itself, not its children, as the children should not have its own classes
  let html = import_classToInlineStyle(selectedTextElement, customCss);
  html = import_editLinkTag(html);
  determineTextEditorBackground(html);
  // If a link, transform to a p
  if (html.match(/^<a /) !== null) {
    html = html.replace(/^<a /, "<p ").replace(/<\/a>$/, "</p>");
  }
  return html;
};

// =====================
// ====== Helpers= =====
// =====================

const pullOutStylingElements = (element) => {
  // If element has 1 child & 1 childnode, it's a styling element => save the css style and remove the styling element
  while ([...element.children].length === 1 && [...element.childNodes].length === 1) {
    let child = [...element.children][0];
    addStyleToElement(element, child);
    [...child.childNodes].forEach((childNode) => element.appendChild(childNode));
    child.remove();
  }
};

const addEbPagesMetaDataToUpdatedElement = (target, element) => {
  try {
    let selectedElement = getState("eb", "selectedElement");
    let splitSelectedElementId = selectedElement.split("-");
    let targetObj = getTargetObj(splitSelectedElementId);
    // Add attributes
    targetObj.attributes.forEach((attr) => {
      if (attr.property === "data-name") {
        // For data-name attr, replace potential 1-6 in h tags
        element.setAttribute(attr.property, element.localName.replace(/\d/g, ""));
      } else {
        element.setAttribute(attr.property, attr.value);
      }
    });
    // Add data-id
    element.setAttribute("data-id", target.dataset.id);
    // Add classes
    element.classList.add(...targetObj.classes);
    // Html tag name: done in convert_prepareElementObj()
    // Styles: already removed in getStyleRulesAndRemoveFromElement()
    // Add ebtype
    element.setAttribute("data-ebtype", targetObj.type);
  } catch (error) {
    console.error(error);
  }
};

const addEbPagesMetaDataToNewElement = (element) => {
  try {
    let elementName = element.localName.replace(/\d/g, "");
    // Only proceed if the element is a texteditable element
    if (elementName === "p" || elementName === "h" || elementName === "ul") {
      // Set attributes (data-name, data-editable, data-texteditable)
      element.setAttribute("data-name", elementName);
      element.setAttribute("data-editable", "true");
      element.setAttribute("data-texteditable", "true");
      // Generate ID
      element.setAttribute("data-id", getRandomId());
      // Add a class
      element.classList.add(getRandomString(30));
      // Don't set: html tagname (done in convert_prepareElementObj()), styles (should be empty) or data-ebtype (should be empty as well)
    }
  } catch (error) {
    console.error(error);
  }
};

const handleInsertedLinks = (element) => {
  try {
    [...element.children].forEach((child) => {
      if (child.localName === "a") {
        child.setAttribute("data-href", child.getAttribute("href"));
        child.setAttribute("href", "#!");
        child.setAttribute("data-target", "_blank");
        child.setAttribute("data-editable", "true");
        child.setAttribute("data-texteditable", "false");
        child.setAttribute("data-id", getRandomId());
        child.setAttribute("data-name", "a");
        child.classList.add(getRandomString(30));
        // TODO: Confirm it is ok to just remove style like this
        let linkTextContent = child.textContent;
        [...child.children].forEach((c) => c.remove());
        child.textContent = linkTextContent;
      }
    });
  } catch (error) {
    console.error(error);
  }
};

const getStyleRulesAndRemoveFromElement = (element) => {
  try {
    let styles = [...element.attributes].filter((attr) => attr.localName === "style")[0].value.replace(/\s/g, "");
    let rules = styles
      .split(";")
      .filter((style) => style !== "")
      .map((style) => ({ property: style.split(":")[0], value: style.split(":")[1] }));
    element.style.cssText = "";
    // return and ensure rgba colors
    return rules.map((rule) => (isColorString(rule.value) ? { ...rule, value: ensureRgba(rule.value) } : rule));
  } catch (error) {
    return [];
  }
};

const addStyleToElement = (element, childElement) => {
  const STYLES = {
    strong: ["font-weight", "bold"],
    em: ["font-style", "italic"],
    ins: ["text-decoration", "underline"],
    del: ["text-decoration", "line-through"],
  };
  if (childElement.localName === "span") {
    childElement.style.cssText
      .replace(/\s/g, "")
      .split(";")
      .filter((style) => style !== "")
      .forEach((style) => {
        element.style.setProperty(style.split(":")[0], style.split(":")[1]);
      });
  } else {
    element.style.setProperty(STYLES[childElement.localName][0], STYLES[childElement.localName][1]);
  }
};

const config_getProjectFontFamilies = (cssVars) => {
  // Return array of font names (eg ["Arial", "Georgia"])
  if (typeof cssVars !== "undefined" && typeof cssVars.fontHeadersName !== "undefined" && typeof cssVars.fontBodyName !== "undefined") {
    return [cssVars.fontHeadersName, cssVars.fontBodyName];
  }
  // return ["Arial", "Georgia", "Impact", "Tahoma", "Times New Roman", "Verdana"];
  return [];
};

const config_getProjectColors = (cssVars) => {
  // Return array of rgba colors
  if (
    typeof cssVars !== "undefined" &&
    typeof cssVars.color1 !== "undefined" &&
    typeof cssVars.color2 !== "undefined" &&
    typeof cssVars.color3 !== "undefined" &&
    typeof cssVars.color4 !== "undefined" &&
    typeof cssVars.color5 !== "undefined" &&
    typeof cssVars.color6 !== "undefined" &&
    typeof cssVars.color7 !== "undefined" &&
    typeof cssVars.color8 !== "undefined" &&
    typeof cssVars.color9 !== "undefined" &&
    typeof cssVars.color10 !== "undefined"
  ) {
    return [
      ensureRgba(cssVars.color1),
      ensureRgba(cssVars.color2),
      ensureRgba(cssVars.color3),
      ensureRgba(cssVars.color4),
      ensureRgba(cssVars.color5),
      ensureRgba(cssVars.color6),
      ensureRgba(cssVars.color7),
      ensureRgba(cssVars.color8),
      ensureRgba(cssVars.color9),
      ensureRgba(cssVars.color10),
      "rgb(0, 0, 0)",
      "rgb(255, 255, 255)",
    ];
  }
  return [];
};

const getElementCustomCss = (selectedTextElement, customCss) => {
  // Only keep the style css properties that you can actually change in the text editor (TEXT_EDITOR_EDITABLE_CSS_PROPS)
  try {
    let componentCssClasses = getComponentCssClasses(selectedTextElement.dataset.id.split("-")[0], customCss);
    let elementCustomClass = getElementCustomClass([...selectedTextElement.classList], componentCssClasses);
    return componentCssClasses
      .filter((className) => className.className === elementCustomClass)[0]
      .rules.filter((rule) => TEXT_EDITOR_EDITABLE_CSS_PROPS.includes(rule.property))
      .map((rule) => `${rule.property}: ${rule.value};`)
      .join(" ");
  } catch (error) {
    return "";
  }
};

const getElementCustomClass = (classList, componentCssClasses) => {
  try {
    return classList.filter((className) => componentCssClasses.map((className) => className.className).includes(className))[0];
  } catch (error) {
    return "";
  }
};

const getComponentCssClasses = (componentId, customCss) => {
  try {
    return customCss.filter((component) => component.componentId === componentId)[0].classes.filter((item) => item.pseudo === "");
  } catch (error) {
    return [];
  }
};

const import_classToInlineStyle = (selectedTextElement, customCss) => {
  try {
    let elementCustomCss = getElementCustomCss(selectedTextElement, customCss);
    let styleAttr = [...selectedTextElement.attributes].filter((attr) => attr.localName === "style")[0];
    if (typeof styleAttr === "undefined") {
      // No existing style attribute => add new
      let posSelectedElementClosingTag = selectedTextElement.outerHTML.indexOf(">");
      return `${selectedTextElement.outerHTML.substring(
        0,
        posSelectedElementClosingTag
      )} style="${elementCustomCss}"${selectedTextElement.outerHTML.substring(posSelectedElementClosingTag)}`;
    } else {
      // Has existing style attribute => add customCss to existing css
      let existingCss = styleAttr.value;
      return selectedTextElement.outerHTML.replace(`style="${existingCss}"`, `style="${existingCss} ${elementCustomCss}"`);
    }
  } catch (error) {
    return "";
  }
};

const import_editLinkTag = (html) => {
  // Replace `data-target="xx" with `target="xx"
  return html.replace(/data-target="/g, `target="`);
};

const determineTextEditorBackground = (html) => {
  let textEditorWrapper = document.getElementById("textEditorWrapper");
  let textColorStrings = [...html.matchAll(/<.+?color: (.+?);.*?>/gi)];
  let textColorStringToCheckContrastFor = textColorStrings.length > 0 ? textColorStrings[0][1] : "";
  textEditorWrapper.setAttribute(
    "data-bgcolor",
    textColorStringToCheckContrastFor === ""
      ? "#fff"
      : colorContrast_getLightOrDark(textColorStringToCheckContrastFor, 100) === "light"
      ? "#fff"
      : "#464646"
  );
};

export const setTextEditorBackground = () => {
  try {
    let textEditorWrapper = document.getElementById("textEditorWrapper");
    let iframe = document.getElementById("iframe_textEditor") === null ? null : document.getElementById("iframe_textEditor").contentWindow;
    let editor = iframe.document.querySelector(".DraftEditor-root");
    editor.style.backgroundColor = textEditorWrapper.dataset.bgcolor;
  } catch (error) {
    console.error(error);
  }
};

const colorContrast_getLightOrDark = (rgbaString, threshold = 130) => {
  let rgb = getRgbValuesFromString(rgbaString);
  let brightness = (rgb[0] * 299 + rgb[1] * 587 + rgb[2] * 114) / 1000;
  return brightness > threshold ? "dark" : "light";
};

// =====================
// === Editor config ===
// =====================

// Variables
const classBtn = "editor-btn";
const classWrapper = "editor-section-wrapper";
export const wrapperClassName = "wrapper-class";
export const editorClassName = "editor-class";
export const toolbarClassName = "toolbar-class";

// Override labels in general format: https://github.com/jpuri/react-draft-wysiwyg/blob/master/src/i18n/en.js
export const LABELS_OVERWRITES = {
  en: {
    "components.controls.blocktype.h1": "Heading 1",
    "components.controls.blocktype.h2": "Heading 2",
    "components.controls.blocktype.h3": "Heading 3",
    "components.controls.blocktype.h4": "Heading 4",
    "components.controls.blocktype.h5": "Heading 5",
    "components.controls.blocktype.h6": "Heading 6",
    "components.controls.blocktype.blockquote": "Blockquote",
    "components.controls.blocktype.code": "Code",
    "components.controls.blocktype.blocktype": "Block Type",
    "components.controls.blocktype.normal": "Normal",
  },
};

export const CONFIG_TOOLBAR = {
  // Change sequence of options and which to show in below
  options: [
    "inline",
    "blockType",
    "fontSize",
    "fontFamily",
    "list",
    "textAlign",
    "colorPicker",
    // "link",
    // "embedded",
    "emoji",
    // "image",
    "remove",
    "history",
  ],
  inline: {
    inDropdown: false,
    className: `${classWrapper} editor-inline`,
    component: undefined,
    dropdownClassName: undefined,
    options: [
      "bold",
      "italic",
      "underline",
      "strikethrough",
      // "monospace",
      "superscript",
      "subscript",
    ],
    bold: {
      // icon: bold,
      className: `${classBtn}`,
    },
    italic: {
      // icon: italic,
      className: `${classBtn}`,
    },
    underline: {
      // icon: underline,
      className: `${classBtn}`,
    },
    strikethrough: {
      // icon: strikethrough,
      className: `${classBtn}`,
    },
    monospace: {
      // icon: monospace,
      className: `${classBtn}`,
    },
    superscript: {
      // icon: superscript,
      className: `${classBtn}`,
    },
    subscript: {
      // icon: subscript,
      className: `${classBtn}`,
    },
  },
  blockType: {
    inDropdown: true,
    options: [
      "Normal",
      "H1",
      "H2",
      "H3",
      "H4",
      "H5",
      "H6",
      // "Blockquote",
      // "Code"
    ],
    className: `${classWrapper}`,
    component: undefined,
    dropdownClassName: undefined,
  },
  fontSize: {
    // icon: fontSize,
    options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
    className: `${classWrapper}`,
    component: undefined,
    dropdownClassName: undefined,
  },
  fontFamily: {
    // options: ["Arial", "Georgia", "Impact", "Tahoma", "Times New Roman", "Verdana"],
    options: config_getProjectFontFamilies(),
    className: `${classWrapper}`,
    component: undefined,
    dropdownClassName: undefined,
  },
  list: {
    inDropdown: false,
    className: `${classWrapper}`,
    component: undefined,
    dropdownClassName: undefined,
    options: ["unordered", "ordered", "indent", "outdent"],
    // unordered: { icon: unordered, className: undefined },
    // ordered: { icon: ordered, className: undefined },
    // indent: { icon: indent, className: undefined },
    // outdent: { icon: outdent, className: undefined },
  },
  textAlign: {
    inDropdown: false,
    className: `${classWrapper}`,
    component: undefined,
    dropdownClassName: undefined,
    options: ["left", "center", "right", "justify"],
    // left: { icon: left, className: undefined },
    // center: { icon: center, className: undefined },
    // right: { icon: right, className: undefined },
    // justify: { icon: justify, className: undefined },
  },
  colorPicker: {
    // icon: color,
    className: `${classWrapper}`,
    component: undefined,
    popupClassName: undefined,
    colors: [
      "rgb(97,189,109)",
      "rgb(26,188,156)",
      "rgb(84,172,210)",
      "rgb(44,130,201)",
      "rgb(147,101,184)",
      "rgb(71,85,119)",
      "rgb(204,204,204)",
      "rgb(65,168,95)",
      "rgb(0,168,133)",
      "rgb(61,142,185)",
      "rgb(41,105,176)",
      "rgb(85,57,130)",
      "rgb(40,50,78)",
      "rgb(0,0,0)",
      "rgb(247,218,100)",
      "rgb(251,160,38)",
      "rgb(235,107,86)",
      "rgb(226,80,65)",
      "rgb(163,143,132)",
      "rgb(239,239,239)",
      "rgb(255,255,255)",
      "rgb(250,197,28)",
      "rgb(243,121,52)",
      "rgb(209,72,65)",
      "rgb(184,49,47)",
      "rgb(124,112,107)",
      "rgb(209,213,216)",
    ],
  },
  link: {
    inDropdown: false,
    className: `${classWrapper}`,
    component: undefined,
    popupClassName: undefined,
    dropdownClassName: undefined,
    showOpenOptionOnHover: true,
    defaultTargetOption: "_blank",
    options: ["link", "unlink"],
    // link: { icon: link, className: undefined },
    // unlink: { icon: unlink, className: undefined },
    linkCallback: undefined,
  },
  // emoji: {
  //   icon: emoji,
  //   className: undefined,
  //   component: undefined,
  //   popupClassName: undefined,
  //   emojis: [
  //     "😀",
  //     "😁",
  //     "😂",
  //     "😃",
  //     "😉",
  //     "😋",
  //     "😎",
  //     "😍",
  //     "😗",
  //     "🤗",
  //     "🤔",
  //     "😣",
  //     "😫",
  //     "😴",
  //     "😌",
  //     "🤓",
  //     "😛",
  //     "😜",
  //     "😠",
  //     "😇",
  //     "😷",
  //     "😈",
  //     "👻",
  //     "😺",
  //     "😸",
  //     "😹",
  //     "😻",
  //     "😼",
  //     "😽",
  //     "🙀",
  //     "🙈",
  //     "🙉",
  //     "🙊",
  //     "👼",
  //     "👮",
  //     "🕵",
  //     "💂",
  //     "👳",
  //     "🎅",
  //     "👸",
  //     "👰",
  //     "👲",
  //     "🙍",
  //     "🙇",
  //     "🚶",
  //     "🏃",
  //     "💃",
  //     "⛷",
  //     "🏂",
  //     "🏌",
  //     "🏄",
  //     "🚣",
  //     "🏊",
  //     "⛹",
  //     "🏋",
  //     "🚴",
  //     "👫",
  //     "💪",
  //     "👈",
  //     "👉",
  //     "👉",
  //     "👆",
  //     "🖕",
  //     "👇",
  //     "🖖",
  //     "🤘",
  //     "🖐",
  //     "👌",
  //     "👍",
  //     "👎",
  //     "✊",
  //     "👊",
  //     "👏",
  //     "🙌",
  //     "🙏",
  //     "🐵",
  //     "🐶",
  //     "🐇",
  //     "🐥",
  //     "🐸",
  //     "🐌",
  //     "🐛",
  //     "🐜",
  //     "🐝",
  //     "🍉",
  //     "🍄",
  //     "🍔",
  //     "🍤",
  //     "🍨",
  //     "🍪",
  //     "🎂",
  //     "🍰",
  //     "🍾",
  //     "🍷",
  //     "🍸",
  //     "🍺",
  //     "🌍",
  //     "🚑",
  //     "⏰",
  //     "🌙",
  //     "🌝",
  //     "🌞",
  //     "⭐",
  //     "🌟",
  //     "🌠",
  //     "🌨",
  //     "🌩",
  //     "⛄",
  //     "🔥",
  //     "🎄",
  //     "🎈",
  //     "🎉",
  //     "🎊",
  //     "🎁",
  //     "🎗",
  //     "🏀",
  //     "🏈",
  //     "🎲",
  //     "🔇",
  //     "🔈",
  //     "📣",
  //     "🔔",
  //     "🎵",
  //     "🎷",
  //     "💰",
  //     "🖊",
  //     "📅",
  //     "✅",
  //     "❎",
  //     "💯",
  //   ],
  // },
  embedded: {
    // icon: embedded,
    className: `${classWrapper}`,
    component: undefined,
    popupClassName: undefined,
    embedCallback: undefined,
    defaultSize: {
      height: "auto",
      width: "auto",
    },
  },
  image: {
    // icon: image,
    className: `${classWrapper}`,
    component: undefined,
    popupClassName: undefined,
    urlEnabled: true,
    uploadEnabled: true,
    alignmentEnabled: true,
    uploadCallback: undefined,
    previewImage: false,
    inputAccept: "image/gif,image/jpeg,image/jpg,image/png,image/svg",
    alt: { present: false, mandatory: false },
    defaultSize: {
      height: "auto",
      width: "auto",
    },
  },
  // remove: { icon: eraser, className: undefined, component: undefined },
  history: {
    inDropdown: false,
    className: `${classWrapper}`,
    component: undefined,
    dropdownClassName: undefined,
    options: ["undo", "redo"],
    // undo: { icon: undo, className: undefined },
    // redo: { icon: redo, className: undefined },
  },
};
