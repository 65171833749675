import React from "react";

const Divider = () => {
  return (
    <div className="mx-n4">
      <div className="border-bottom"></div>
    </div>
  );
};

export default Divider;
