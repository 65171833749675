export const cLayout = {
  dragDropFirstComponent: {
    en: "Drag & drop your first component!",
    nl: "Drag & drop uw eerste component!",
  },
  confirmExitEb: {
    en: "Are you sure you want to exit Emailbuilder? Any unsaved progress will be lost.",
    nl: "Weet u zeker dat u Emailbuilder wilt sluiten? Niet opgeslaagde veranderingen zullen worden verloren.",
  },
  saveExitEb: {
    en: "Save and exit Emailbuilder",
    nl: "Veranderingen opslaan en Emailbuilder sluiten",
  },
  version: {
    en: "Version",
    nl: "Versie",
  },
  mailOptions: {
    en: "Mail options",
    nl: "Mail opties",
  },
  desktopScreen: {
    en: "See how your email looks like on a desktop screen",
    nl: "Bekijk hoe uw email eruit ziet op een desktop scherm",
  },
  tabletScreen: {
    en: "See how your email looks like on a tablet screen",
    nl: "Bekijk hoe uw email eruit ziet op een tablet scherm",
  },
  mobileScreen: {
    en: "See how your email looks like on a mobile screen",
    nl: "Bekijk hoe uw email eruit ziet op een mobiele telefoon scherm",
  },
  undo: {
    en: "Undo most recent change",
    nl: "Recente verandering ongedaan maken",
  },
  noUndoChange: {
    en: "There is no change to undo",
    nl: "Er is geen recente verandering om ongedaan te maken",
  },
  redo: {
    en: "Redo most recent change",
    nl: "Verandering terugzetten",
  },
  noRedoChange: {
    en: "There is no change to redo",
    nl: "Er is geen verandering om terug te zetten",
  },
  saveProject: {
    en: "Save project",
    nl: "Project opslaan",
  },
  browseTemplates: {
    en: "Browse templates",
    nl: "Bekijk templates",
  },
  openImgGallery: {
    en: "Open image gallery",
    nl: "Open afbeeldinggallerij",
  },
  mailStyleSettings: {
    en: "Mail style settings",
    nl: "Mail stijl instellingen",
  },
  addNewComponent: {
    en: "Add new component",
    nl: "Voeg nieuw component toe",
  },
  editComponent: {
    en: "Edit component",
    nl: "Bewerk component",
  },
  ebSettings: {
    en: "Settings",
    nl: "Instellingen",
  },
  takeTour: {
    en: "Take product tour",
    nl: "Neem rondleiding",
  },
  selectLanguage: {
    en: "Select language",
    nl: "Selecteer taal",
  },
};
