export const template8 = {
  id: "660342d10fe07db6cae46007",
  name: { en: "Newsletter", nl: "Nieuwsbrief" },
  desc: { en: "A newsletter with links to highlighted stories", nl: "Een nieuwsbrief met links naar uitgelichte artikelen" },
  categories: ["newsletter"],
  ebContent: [
    {
      pageId: "29a1d06f-3233-4a5b-aa0d-7df4aca5d6d6",
      version: "A",
      components: [
        {
          componentId: "Mb4BWQ",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "basics-7-component-Mb4BWQ"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "TY5fOO",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "basics-7-col-container-Mb4BWQ"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "gFi3G8",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-12"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "wP5zQ3",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "q91JUu",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "riSMgk",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "NeE3EJ",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["basics-7-col-td-wrapper-Mb4BWQ"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-name", value: "divider" },
                                    { property: "data-editable", value: "true" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "ZyYoDV",
                                      type: "",
                                      htmlTagName: "div",
                                      classes: ["basics-7-divider-Mb4BWQ"],
                                      styles: [],
                                      attributes: [{ property: "data-elementgetter1", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "bOefrG",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "basics-1-component-bOefrG"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "FiA06y",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "basics-1-col-container-bOefrG"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "qKzaIL",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-12"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "IKiwou",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "WY4GVY",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "hfIkgC",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "abwOon",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["basics-1-col-td-wrapper-bOefrG"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "DvKopR",
                                      type: "",
                                      htmlTagName: "h1",
                                      classes: ["basics-1-title-bOefrG"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "h" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Orange Daily",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "pZJfyG",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "basics-2-component-pZJfyG"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "YrYOf3",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "basics-2-col-container-pZJfyG"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "qfyiyN",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-12"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "geg89Q",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "N0n06v",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "gcHR15",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "M7ArPg",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["basics-2-col-td-wrapper-pZJfyG"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "yFF1Gp",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["basics-2-text-pZJfyG"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content:
                                            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sit amet porttitor eget dolor morbi non arcu. Diam in arcu cursus euismod quis viverra nibh. Ultrices mi tempus imperdiet nulla malesuada pellentesque.",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "R4JCcn",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "basics-3-component-R4JCcn"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "Fi0gzS",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "basics-3-col-container-R4JCcn"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "j5Mq1j",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-12"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "JMSAEr",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "RA6Mjw",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "GyBz1l",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "EcqhgC",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["basics-3-col-td-wrapper-R4JCcn"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "smTKmP",
                                      type: "",
                                      htmlTagName: "a",
                                      classes: ["basics-3-btn-R4JCcn"],
                                      styles: [],
                                      attributes: [
                                        { property: "href", value: "#!" },
                                        { property: "data-href", value: "#!" },
                                        { property: "data-target", value: "_blank" },
                                        { property: "data-editable", value: "true" },
                                        { property: "data-name", value: "button" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Read more",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "puaLC5",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "cards-2-component-puaLC5"],
          styles: [],
          attributes: [{ property: "data-name", value: "col_component" }],
          content: "",
          children: [
            {
              childId: "UJig0x",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "cards-2-col-container-puaLC5"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "qFKQ7h",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-6", "vertical-align-top"],
                  styles: [],
                  attributes: [{ property: "data-colLayout", value: "true" }],
                  content: "",
                  children: [
                    {
                      childId: "FnL2xV",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "vyPz4h",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "jw1J2R",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "EQ1qqg",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["cards-2-col-td-wrapper-puaLC5"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "qYbfqe",
                                      type: "",
                                      htmlTagName: "h3",
                                      classes: ["cards-2-title-puaLC5"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "h" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "This is a title",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "dnxwIz",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["cards-2-text-puaLC5"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content:
                                            "Cras semper auctor neque vitae tempus quam pellentesque. Elementum sagittis vitae et leo duis ut diam. Tellus molestie nunc non blandit massa enim. ",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "wBhyoh",
                                      type: "",
                                      htmlTagName: "a",
                                      classes: ["cards-2-btn-puaLC5"],
                                      styles: [],
                                      attributes: [
                                        { property: "href", value: "#!" },
                                        { property: "data-href", value: "#!" },
                                        { property: "data-target", value: "_blank" },
                                        { property: "data-editable", value: "true" },
                                        { property: "data-name", value: "button" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Read post",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "Z5n1Lu",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-6", "vertical-align-top"],
                  styles: [],
                  attributes: [{ property: "data-colLayout", value: "true" }],
                  content: "",
                  children: [
                    {
                      childId: "vPeKYy",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "oaYzoZ",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "EOIaOi",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "dz3FMX",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["cards-2-col-td-wrapper-puaLC5"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "azaRXw",
                                      type: "",
                                      htmlTagName: "h3",
                                      classes: ["cards-2-title-puaLC5"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "h" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "This is a title",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "t070SM",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["cards-2-text-puaLC5"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content:
                                            "Tristique et egestas quis ipsum suspendisse ultrices gravida dictum fusce. Leo integer malesuada nunc vel risus commodo viverra maecenas accumsan. ",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "sy1dHw",
                                      type: "",
                                      htmlTagName: "a",
                                      classes: ["cards-2-btn-puaLC5"],
                                      styles: [],
                                      attributes: [
                                        { property: "href", value: "#!" },
                                        { property: "data-href", value: "#!" },
                                        { property: "data-target", value: "_blank" },
                                        { property: "data-editable", value: "true" },
                                        { property: "data-name", value: "button" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Read post",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "ZSHw9c",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "footer-8-component-ZSHw9c"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "yHGaTH",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "footer-8-col-container-ZSHw9c"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "TGz1XT",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-12"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "QtrqMj",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "S5q9Ug",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "IW10Dj",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "uZCFCJ",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["footer-8-col-td-wrapper-ZSHw9c"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "T2IT0r",
                                      type: "",
                                      htmlTagName: "div",
                                      classes: ["footer-8-wrapper-img-ZSHw9c"],
                                      styles: [],
                                      attributes: [],
                                      content: "",
                                      children: [
                                        {
                                          childId: "zFnPPJ",
                                          type: "",
                                          htmlTagName: "a",
                                          classes: ["footer-8-link-img-ZSHw9c"],
                                          styles: [],
                                          attributes: [
                                            { property: "href", value: "#!" },
                                            { property: "data-href", value: "#!" },
                                            { property: "data-target", value: "_blank" },
                                            { property: "data-editable", value: "true" },
                                            { property: "data-name", value: "downloadApp" },
                                          ],
                                          content: "",
                                          children: [
                                            {
                                              childId: "xeqLaE",
                                              type: "",
                                              htmlTagName: "img",
                                              classes: ["footer-8-img-ZSHw9c"],
                                              styles: [],
                                              attributes: [
                                                { property: "src", value: "https://cdn.satonda.com/eb/assets/download_app_store.png" },
                                                { property: "alt", value: "Download iOS app" },
                                                { property: "data-editable", value: "false" },
                                              ],
                                              content: "",
                                              children: [],
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "E7tfh4",
                                      type: "",
                                      htmlTagName: "div",
                                      classes: ["footer-8-wrapper-img-ZSHw9c"],
                                      styles: [],
                                      attributes: [],
                                      content: "",
                                      children: [
                                        {
                                          childId: "wQQtun",
                                          type: "",
                                          htmlTagName: "a",
                                          classes: ["footer-8-link-img-ZSHw9c"],
                                          styles: [],
                                          attributes: [
                                            { property: "href", value: "#!" },
                                            { property: "data-href", value: "#!" },
                                            { property: "data-target", value: "_blank" },
                                            { property: "data-editable", value: "true" },
                                            { property: "data-name", value: "downloadApp" },
                                          ],
                                          content: "",
                                          children: [
                                            {
                                              childId: "zxLsjX",
                                              type: "",
                                              htmlTagName: "img",
                                              classes: ["footer-8-img-ZSHw9c"],
                                              styles: [],
                                              attributes: [
                                                { property: "src", value: "https://cdn.satonda.com/eb/assets/download_play_store.png" },
                                                { property: "alt", value: "Download Android app" },
                                                { property: "data-editable", value: "false" },
                                              ],
                                              content: "",
                                              children: [],
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "JJhM1v",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "footer-9-component-JJhM1v"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "MVbEnC",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "footer-9-col-container-JJhM1v"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "rQGw5y",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-12"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "zcrEgO",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "GhS4ja",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "CTUE78",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "lbtuht",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["footer-9-col-td-wrapper-JJhM1v"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "PEwNyA",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["footer-9-text1-JJhM1v"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                        { property: "data-uniqueclassname", value: "true" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "© Company name",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "Af5rE7",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["footer-9-text2-JJhM1v"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                        { property: "data-uniqueclassname", value: "true" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Streetname 1, 1000 XX Amsterdam, The Netherlands",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "SEBbx8",
                                      type: "element",
                                      htmlTagName: "a",
                                      classes: ["footer-9-link-JJhM1v"],
                                      styles: [],
                                      attributes: [
                                        { property: "href", value: "#!" },
                                        { property: "data-href", value: "%UNSUBSCRIBE_LINK%" },
                                        { property: "data-target", value: "_blank" },
                                        { property: "data-editable", value: "true" },
                                        { property: "data-name", value: "a" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Unsubscribe",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  ebCustomCss: [
    {
      componentId: "Mb4BWQ",
      classes: [
        {
          className: "basics-7-component-Mb4BWQ",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(255, 235, 214)" },
            { property: "background", value: "rgb(255, 235, 214)" },
          ],
        },
        {
          className: "basics-7-col-container-Mb4BWQ",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "background-color", value: "rgb(255, 255, 255)" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "basics-7-col-td-wrapper-Mb4BWQ",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0rem 0rem 0rem 0rem" },
            { property: "text-align", value: "start" },
          ],
        },
        {
          className: "basics-7-divider-Mb4BWQ",
          pseudo: "",
          rules: [
            { property: "display", value: "inline-block" },
            { property: "height", value: "20px" },
            { property: "width", value: "100%" },
            { property: "background-color", value: "rgb(255, 235, 214)" },
            { property: "background", value: "rgb(255, 235, 214)" },
            { property: "border-radius", value: "0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(230, 230, 230)" },
          ],
        },
      ],
    },
    {
      componentId: "bOefrG",
      classes: [
        {
          className: "basics-1-title-bOefrG",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgb(245, 126, 0)" },
            { property: "font-weight", value: "bold" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "basics-1-component-bOefrG",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(255, 235, 214)" },
            { property: "background", value: "rgb(255, 235, 214)" },
          ],
        },
        {
          className: "basics-1-col-container-bOefrG",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "background-color", value: "rgb(255, 255, 255)" },
            { property: "padding-top", value: "1rem" },
            { property: "padding-bottom", value: "1rem" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "basics-1-col-td-wrapper-bOefrG",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0.5rem 0.5rem 0.5rem 0.5rem" },
            { property: "text-align", value: "start" },
          ],
        },
      ],
    },
    {
      componentId: "pZJfyG",
      classes: [
        {
          className: "basics-2-component-pZJfyG",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(255, 235, 214)" },
            { property: "background", value: "rgb(255, 235, 214)" },
          ],
        },
        {
          className: "basics-2-col-container-pZJfyG",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "background-color", value: "rgb(255, 255, 255)" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "basics-2-col-td-wrapper-pZJfyG",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0.5rem 0.75rem 0.5rem 0.75rem" },
            { property: "text-align", value: "start" },
          ],
        },
        {
          className: "basics-2-text-pZJfyG",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(33, 37, 41)" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
          ],
        },
      ],
    },
    {
      componentId: "R4JCcn",
      classes: [
        {
          className: "basics-3-btn-R4JCcn",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "font-weight", value: "bold" },
            { property: "font-style", value: "normal" },
            { property: "text-decoration", value: "none" },
            { property: "color", value: "rgb(255, 255, 255)" },
            { property: "background", value: "rgb(245, 126, 0)" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(245, 126, 0)" },
            { property: "border-radius", value: "0rem" },
            { property: "padding", value: "0.5rem 0.75rem 0.5rem 0.75rem" },
            { property: "display", value: "inline-block" },
            { property: "font-size", value: "16px" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "basics-3-component-R4JCcn",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(255, 235, 214)" },
            { property: "background", value: "rgb(255, 235, 214)" },
          ],
        },
        {
          className: "basics-3-col-container-R4JCcn",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "background-color", value: "rgb(255, 255, 255)" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "basics-3-col-td-wrapper-R4JCcn",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0.5rem 0.75rem 0.5rem 0.75rem" },
            { property: "text-align", value: "start" },
          ],
        },
      ],
    },
    {
      componentId: "puaLC5",
      classes: [
        {
          className: "cards-2-title-puaLC5",
          pseudo: "",
          rules: [
            { property: "text-align", value: "left" },
            { property: "color", value: "rgb(245, 126, 0)" },
            { property: "margin", value: "0rem 0rem 0.5rem 0rem" },
          ],
        },
        {
          className: "cards-2-text-puaLC5",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(33, 37, 41)" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
          ],
        },
        {
          className: "cards-2-component-puaLC5",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(255, 235, 214)" },
            { property: "background", value: "rgb(255, 235, 214)" },
          ],
        },
        {
          className: "cards-2-col-container-puaLC5",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "padding-top", value: "1.5rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "background-color", value: "rgb(255, 255, 255)" },
            { property: "padding-bottom", value: "0.75rem" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "cards-2-btn-puaLC5",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "font-weight", value: "bold" },
            { property: "font-style", value: "normal" },
            { property: "text-decoration", value: "none" },
            { property: "color", value: "rgb(255, 255, 255)" },
            { property: "background", value: "rgb(245, 126, 0)" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(245, 126, 0)" },
            { property: "border-radius", value: "0.2rem" },
            { property: "padding", value: "0.5rem 0.75rem 0.5rem 0.75rem" },
            { property: "display", value: "inline-block" },
            { property: "font-size", value: "16px" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "cards-2-col-td-wrapper-puaLC5",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0.5rem 0.75rem 0.5rem 0.75rem" },
            { property: "text-align", value: "start" },
          ],
        },
      ],
    },
    {
      componentId: "JJhM1v",
      classes: [
        {
          className: "footer-9-text1-JJhM1v",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(108, 117, 125)" },
            { property: "margin", value: "0rem 0rem 0.25rem 0rem" },
            { property: "text-align", value: "center" },
            { property: "font-size", value: "12px" },
          ],
        },
        {
          className: "footer-9-text2-JJhM1v",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(108, 117, 125)" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-align", value: "center" },
            { property: "font-size", value: "12px" },
          ],
        },
        {
          className: "footer-9-link-JJhM1v",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(108, 117, 125)" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "display", value: "block" },
            { property: "text-align", value: "center" },
            { property: "text-decoration", value: "underline" },
            { property: "font-size", value: "12px" },
          ],
        },
        {
          className: "footer-9-component-JJhM1v",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(255, 235, 214)" },
            { property: "background", value: "rgb(255, 235, 214)" },
          ],
        },
        {
          className: "footer-9-col-container-JJhM1v",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(255, 235, 214)" },
            { property: "background-color", value: "rgb(255, 235, 214)" },
            { property: "padding-top", value: "1.5rem" },
            { property: "padding-bottom", value: "3rem" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "footer-9-col-td-wrapper-JJhM1v",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0.5rem 0rem 0.5rem 0rem" },
            { property: "text-align", value: "start" },
          ],
        },
      ],
    },
    {
      componentId: "ZSHw9c",
      classes: [
        { className: "footer-8-wrapper-img-ZSHw9c", pseudo: "", rules: [{ property: "display", value: "inline-block" }] },
        {
          className: "footer-8-link-img-ZSHw9c",
          pseudo: "",
          rules: [
            { property: "display", value: "block" },
            { property: "padding", value: "0px 10px 0px 10px" },
          ],
        },
        {
          className: "footer-8-img-ZSHw9c",
          pseudo: "",
          rules: [
            { property: "outline", value: "none" },
            { property: "-ms-interpolation-mode", value: "bicubic" },
            { property: "width", value: "125px" },
            { property: "height", value: "auto" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "footer-8-component-ZSHw9c",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(255, 235, 214)" },
            { property: "background", value: "rgb(255, 235, 214)" },
          ],
        },
        {
          className: "footer-8-col-container-ZSHw9c",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "padding-top", value: "2rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(255, 235, 214)" },
            { property: "background-color", value: "rgb(255, 235, 214)" },
            { property: "padding-bottom", value: "0rem" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "footer-8-col-td-wrapper-ZSHw9c",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0.5rem 0rem 0.5rem 0rem" },
            { property: "text-align", value: "center" },
          ],
        },
      ],
    },
  ],
  ebCssVars: {
    fontHeadersName: "Arial",
    fontHeadersWeight: "400",
    fontBodyName: "Arial",
    fontBodyWeight: "400",
    color1: "rgb(245, 126, 0)",
    color2: "rgb(20, 126, 255)",
    color3: "rgb(255, 235, 214)",
    color4: "rgba(13, 202, 240, 1)",
    color5: "rgba(255, 193, 7, 1)",
    color6: "rgba(220, 53, 69, 1)",
    color7: "rgba(233, 236, 239, 1)",
    color8: "rgba(33, 37, 41, 1)",
    color9: "rgba(206, 212, 218, 1)",
    color10: "rgba(108, 117, 125, 1)",
  },
};
