// from https://r12a.github.io/app-subtags/languages.js
export const metaLangCodes = [
  { subtag: "aa", description: "Afar" },
  { subtag: "ab", description: "Abkhazian" },
  { subtag: "ae", description: "Avestan" },
  { subtag: "af", description: "Afrikaans" },
  { subtag: "ak", description: "Akan" },
  { subtag: "am", description: "Amharic" },
  { subtag: "an", description: "Aragonese" },
  { subtag: "ar", description: "Arabic" },
  { subtag: "as", description: "Assamese" },
  { subtag: "av", description: "Avaric" },
  { subtag: "ay", description: "Aymara" },
  { subtag: "az", description: "Azerbaijani" },
  { subtag: "ba", description: "Bashkir" },
  { subtag: "be", description: "Belarusian" },
  { subtag: "bg", description: "Bulgarian" },
  { subtag: "bh", description: "Bihari languages" },
  { subtag: "bi", description: "Bislama" },
  { subtag: "bm", description: "Bambara" },
  { subtag: "bn", description: "Bengali, Bangla" },
  { subtag: "bo", description: "Tibetan" },
  { subtag: "br", description: "Breton" },
  { subtag: "bs", description: "Bosnian" },
  { subtag: "ca", description: "Catalan, Valencian" },
  { subtag: "ce", description: "Chechen" },
  { subtag: "ch", description: "Chamorro" },
  { subtag: "co", description: "Corsican" },
  { subtag: "cr", description: "Cree" },
  { subtag: "cs", description: "Czech" },
  {
    subtag: "cu",
    description: "Church Slavic",
  },
  { subtag: "cv", description: "Chuvash" },
  { subtag: "cy", description: "Welsh" },
  { subtag: "da", description: "Danish" },
  { subtag: "de", description: "German" },
  { subtag: "dv", description: "Dhivehi, Divehi, Maldivian" },
  { subtag: "dz", description: "Dzongkha" },
  { subtag: "ee", description: "Ewe" },
  { subtag: "el", description: "Modern Greek (1453-)" },
  { subtag: "en", description: "English" },
  { subtag: "eo", description: "Esperanto" },
  { subtag: "es", description: "Spanish, Castilian" },
  { subtag: "et", description: "Estonian" },
  { subtag: "eu", description: "Basque" },
  { subtag: "fa", description: "Persian" },
  { subtag: "ff", description: "Fulah" },
  { subtag: "fi", description: "Finnish" },
  { subtag: "fj", description: "Fijian" },
  { subtag: "fo", description: "Faroese" },
  { subtag: "fr", description: "French" },
  { subtag: "fy", description: "Western Frisian" },
  { subtag: "ga", description: "Irish" },
  { subtag: "gd", description: "Scottish Gaelic, Gaelic" },
  { subtag: "gl", description: "Galician" },
  { subtag: "gn", description: "Guarani" },
  { subtag: "gu", description: "Gujarati" },
  { subtag: "gv", description: "Manx" },
  { subtag: "ha", description: "Hausa" },
  { subtag: "he", description: "Hebrew" },
  { subtag: "hi", description: "Hindi" },
  { subtag: "ho", description: "Hiri Motu" },
  { subtag: "hr", description: "Croatian" },
  { subtag: "ht", description: "Haitian, Haitian Creole" },
  { subtag: "hu", description: "Hungarian" },
  { subtag: "hy", description: "Armenian" },
  { subtag: "hz", description: "Herero" },
  { subtag: "ia", description: "Interlingua (International Auxiliary Language Association)" },
  { subtag: "id", description: "Indonesian" },
  { subtag: "ie", description: "Interlingue, Occidental" },
  { subtag: "ig", description: "Igbo" },
  { subtag: "ii", description: "Sichuan Yi, Nuosu" },
  { subtag: "ik", description: "Inupiaq" },
  { subtag: "io", description: "Ido" },
  { subtag: "is", description: "Icelandic" },
  { subtag: "it", description: "Italian" },
  { subtag: "iu", description: "Inuktitut" },
  { subtag: "ja", description: "Japanese" },
  { subtag: "jv", description: "Javanese" },
  { subtag: "ka", description: "Georgian" },
  { subtag: "kg", description: "Kongo" },
  { subtag: "ki", description: "Kikuyu, Gikuyu" },
  { subtag: "kj", description: "Kuanyama, Kwanyama" },
  { subtag: "kk", description: "Kazakh" },
  { subtag: "kl", description: "Kalaallisut, Greenlandic" },
  { subtag: "km", description: "Khmer, Central Khmer" },
  { subtag: "kn", description: "Kannada" },
  { subtag: "ko", description: "Korean" },
  { subtag: "kr", description: "Kanuri" },
  { subtag: "ks", description: "Kashmiri" },
  { subtag: "ku", description: "Kurdish" },
  { subtag: "kv", description: "Komi" },
  { subtag: "kw", description: "Cornish" },
  { subtag: "ky", description: "Kirghiz, Kyrgyz" },
  { subtag: "la", description: "Latin" },
  { subtag: "lb", description: "Luxembourgish, Letzeburgesch" },
  { subtag: "lg", description: "Ganda, Luganda" },
  { subtag: "li", description: "Limburgan, Limburger, Limburgish" },
  { subtag: "ln", description: "Lingala" },
  { subtag: "lo", description: "Lao" },
  { subtag: "lt", description: "Lithuanian" },
  { subtag: "lu", description: "Luba-Katanga" },
  { subtag: "lv", description: "Latvian" },
  { subtag: "mg", description: "Malagasy" },
  { subtag: "mh", description: "Marshallese" },
  { subtag: "mi", description: "Maori" },
  { subtag: "mk", description: "Macedonian" },
  { subtag: "ml", description: "Malayalam" },
  { subtag: "mn", description: "Mongolian" },
  { subtag: "mr", description: "Marathi" },
  { subtag: "mt", description: "Maltese" },
  { subtag: "my", description: "Burmese" },
  { subtag: "na", description: "Nauru" },
  { subtag: "nb", description: "Norwegian Bokmål" },
  { subtag: "nd", description: "North Ndebele" },
  { subtag: "ng", description: "Ndonga" },
  { subtag: "nl", description: "Dutch, Flemish" },
  { subtag: "nn", description: "Norwegian Nynorsk" },
  { subtag: "no", description: "Norwegian" },
  { subtag: "nr", description: "South Ndebele" },
  { subtag: "nv", description: "Navajo, Navaho" },
  { subtag: "ny", description: "Nyanja, Chewa, Chichewa" },
  { subtag: "oc", description: "Occitan (post 1500)" },
  { subtag: "oj", description: "Ojibwa" },
  { subtag: "om", description: "Oromo" },
  { subtag: "os", description: "Ossetian, Ossetic" },
  { subtag: "pa", description: "Panjabi, Punjabi" },
  { subtag: "pi", description: "Pali" },
  { subtag: "pl", description: "Polish" },
  { subtag: "ps", description: "Pushto, Pashto" },
  { subtag: "pt", description: "Portuguese" },
  { subtag: "qu", description: "Quechua" },
  { subtag: "rm", description: "Romansh" },
  { subtag: "rn", description: "Rundi" },
  { subtag: "ro", description: "Romanian, Moldavian, Moldovan" },
  { subtag: "ru", description: "Russian" },
  { subtag: "rw", description: "Kinyarwanda" },
  { subtag: "sa", description: "Sanskrit" },
  { subtag: "sc", description: "Sardinian" },
  { subtag: "sd", description: "Sindhi" },
  { subtag: "se", description: "Northern Sami" },
  { subtag: "sg", description: "Sango" },
  {
    subtag: "sh",
    description: "Serbo-Croatian",
  },
  { subtag: "si", description: "Sinhala, Sinhalese" },
  { subtag: "sk", description: "Slovak" },
  { subtag: "sl", description: "Slovenian" },
  { subtag: "sm", description: "Samoan" },
  { subtag: "sn", description: "Shona" },
  { subtag: "so", description: "Somali" },
  { subtag: "sq", description: "Albanian" },
  { subtag: "sr", description: "Serbian" },
  { subtag: "ss", description: "Swati" },
  { subtag: "st", description: "Southern Sotho" },
  { subtag: "su", description: "Sundanese" },
  { subtag: "sv", description: "Swedish" },
  { subtag: "ta", description: "Tamil" },
  { subtag: "te", description: "Telugu" },
  { subtag: "tg", description: "Tajik" },
  { subtag: "th", description: "Thai" },
  { subtag: "ti", description: "Tigrinya" },
  { subtag: "tk", description: "Turkmen" },
  { subtag: "tl", description: "Tagalog" },
  { subtag: "tn", description: "Tswana" },
  { subtag: "to", description: "Tonga (Tonga Islands)" },
  { subtag: "tr", description: "Turkish" },
  { subtag: "ts", description: "Tsonga" },
  { subtag: "tt", description: "Tatar" },
  { subtag: "tw", description: "Twi" },
  { subtag: "ty", description: "Tahitian" },
  { subtag: "ug", description: "Uighur, Uyghur" },
  { subtag: "uk", description: "Ukrainian" },
  { subtag: "ur", description: "Urdu" },
  { subtag: "uz", description: "Uzbek" },
  { subtag: "ve", description: "Venda" },
  { subtag: "vi", description: "Vietnamese" },
  { subtag: "vo", description: "Volapük" },
  { subtag: "wa", description: "Walloon" },
  { subtag: "wo", description: "Wolof" },
  { subtag: "xh", description: "Xhosa" },
  { subtag: "yi", description: "Yiddish" },
  { subtag: "yo", description: "Yoruba" },
  { subtag: "za", description: "Zhuang, Chuang" },
  { subtag: "zh", description: "Chinese" },
  { subtag: "zu", description: "Zulu" },
  { subtag: "aaa", description: "Ghotuo" },
  { subtag: "aab", description: "Alumu-Tesu" },
  { subtag: "aac", description: "Ari" },
  { subtag: "aad", description: "Amal" },
  { subtag: "aae", description: "Arbëreshë Albanian" },
  { subtag: "aaf", description: "Aranadan" },
  { subtag: "aag", description: "Ambrak" },
  { subtag: "aah", description: "Abu' Arapesh" },
  { subtag: "aai", description: "Arifama-Miniafia" },
  { subtag: "aak", description: "Ankave" },
  { subtag: "aal", description: "Afade" },
  { subtag: "aan", description: "Anambé" },
  { subtag: "aao", description: "Algerian Saharan Arabic" },
  { subtag: "aap", description: "Pará Arára" },
  { subtag: "aaq", description: "Eastern Abnaki" },
  { subtag: "aas", description: "Aasáx" },
  { subtag: "aat", description: "Arvanitika Albanian" },
  { subtag: "aau", description: "Abau" },
  { subtag: "aav", description: "Austro-Asiatic languages" },
  { subtag: "aaw", description: "Solong" },
  { subtag: "aax", description: "Mandobo Atas" },
  { subtag: "aaz", description: "Amarasi" },
  { subtag: "aba", description: "Abé" },
  { subtag: "abb", description: "Bankon" },
  { subtag: "abc", description: "Ambala Ayta" },
  { subtag: "abd", description: "Manide" },
  { subtag: "abe", description: "Western Abnaki" },
  { subtag: "abf", description: "Abai Sungai" },
  { subtag: "abg", description: "Abaga" },
  { subtag: "abh", description: "Tajiki Arabic" },
  { subtag: "abi", description: "Abidji" },
  { subtag: "abj", description: "Aka-Bea" },
  { subtag: "abl", description: "Lampung Nyo" },
  { subtag: "abm", description: "Abanyom" },
  { subtag: "abn", description: "Abua" },
  { subtag: "abo", description: "Abon" },
  { subtag: "abp", description: "Abellen Ayta" },
  { subtag: "abq", description: "Abaza" },
  { subtag: "abr", description: "Abron" },
  { subtag: "abs", description: "Ambonese Malay" },
  { subtag: "abt", description: "Ambulas" },
  { subtag: "abu", description: "Abure" },
  { subtag: "abv", description: "Baharna Arabic" },
  { subtag: "abw", description: "Pal" },
  { subtag: "abx", description: "Inabaknon" },
  { subtag: "aby", description: "Aneme Wake" },
  { subtag: "abz", description: "Abui" },
  { subtag: "aca", description: "Achagua" },
  { subtag: "acb", description: "Áncá" },
  { subtag: "acd", description: "Gikyode" },
  { subtag: "ace", description: "Achinese" },
  { subtag: "acf", description: "Saint Lucian Creole French" },
  { subtag: "ach", description: "Acoli" },
  { subtag: "aci", description: "Aka-Cari" },
  { subtag: "ack", description: "Aka-Kora" },
  { subtag: "acl", description: "Akar-Bale" },
  { subtag: "acm", description: "Mesopotamian Arabic" },
  { subtag: "acn", description: "Achang" },
  { subtag: "acp", description: "Eastern Acipa" },
  { subtag: "acq", description: "Ta'izzi-Adeni Arabic" },
  { subtag: "acr", description: "Achi" },
  { subtag: "acs", description: "Acroá" },
  { subtag: "act", description: "Achterhoeks" },
  { subtag: "acu", description: "Achuar-Shiwiar" },
  { subtag: "acv", description: "Achumawi" },
  { subtag: "acw", description: "Hijazi Arabic" },
  { subtag: "acx", description: "Omani Arabic" },
  { subtag: "acy", description: "Cypriot Arabic" },
  { subtag: "acz", description: "Acheron" },
  { subtag: "ada", description: "Adangme" },
  { subtag: "adb", description: "Atauran" },
  { subtag: "add", description: "Lidzonka, Dzodinka" },
  { subtag: "ade", description: "Adele" },
  { subtag: "adf", description: "Dhofari Arabic" },
  { subtag: "adg", description: "Andegerebinha" },
  { subtag: "adh", description: "Adhola" },
  { subtag: "adi", description: "Adi" },
  { subtag: "adj", description: "Adioukrou" },
  { subtag: "adl", description: "Galo" },
  { subtag: "adn", description: "Adang" },
  { subtag: "ado", description: "Abu" },
  { subtag: "adq", description: "Adangbe" },
  { subtag: "adr", description: "Adonara" },
  { subtag: "ads", description: "Adamorobe Sign Language" },
  { subtag: "adt", description: "Adnyamathanha" },
  { subtag: "adu", description: "Aduge" },
  { subtag: "adw", description: "Amundava" },
  { subtag: "adx", description: "Amdo Tibetan" },
  { subtag: "ady", description: "Adyghe, Adygei" },
  { subtag: "adz", description: "Adzera" },
  { subtag: "aea", description: "Areba" },
  { subtag: "aeb", description: "Tunisian Arabic" },
  { subtag: "aec", description: "Saidi Arabic" },
  { subtag: "aed", description: "Argentine Sign Language" },
  { subtag: "aee", description: "Northeast Pashai, Northeast Pashayi" },
  { subtag: "aek", description: "Haeke" },
  { subtag: "ael", description: "Ambele" },
  { subtag: "aem", description: "Arem" },
  { subtag: "aen", description: "Armenian Sign Language" },
  { subtag: "aeq", description: "Aer" },
  { subtag: "aer", description: "Eastern Arrernte" },
  { subtag: "aes", description: "Alsea" },
  { subtag: "aeu", description: "Akeu" },
  { subtag: "aew", description: "Ambakich" },
  { subtag: "aey", description: "Amele" },
  { subtag: "aez", description: "Aeka" },
  { subtag: "afa", description: "Afro-Asiatic languages" },
  { subtag: "afb", description: "Gulf Arabic" },
  { subtag: "afd", description: "Andai" },
  { subtag: "afe", description: "Putukwam" },
  { subtag: "afg", description: "Afghan Sign Language" },
  { subtag: "afh", description: "Afrihili" },
  { subtag: "afi", description: "Akrukay, Chini" },
  { subtag: "afk", description: "Nanubae" },
  { subtag: "afn", description: "Defaka" },
  { subtag: "afo", description: "Eloyi" },
  { subtag: "afp", description: "Tapei" },
  { subtag: "afs", description: "Afro-Seminole Creole" },
  { subtag: "aft", description: "Afitti" },
  { subtag: "afu", description: "Awutu" },
  { subtag: "afz", description: "Obokuitai" },
  { subtag: "aga", description: "Aguano" },
  { subtag: "agb", description: "Legbo" },
  { subtag: "agc", description: "Agatu" },
  { subtag: "agd", description: "Agarabi" },
  { subtag: "age", description: "Angal" },
  { subtag: "agf", description: "Arguni" },
  { subtag: "agg", description: "Angor" },
  { subtag: "agh", description: "Ngelima" },
  { subtag: "agi", description: "Agariya" },
  { subtag: "agj", description: "Argobba" },
  { subtag: "agk", description: "Isarog Agta" },
  { subtag: "agl", description: "Fembe" },
  { subtag: "agm", description: "Angaataha" },
  { subtag: "agn", description: "Agutaynen" },
  { subtag: "ago", description: "Tainae" },
  { subtag: "agq", description: "Aghem" },
  { subtag: "agr", description: "Aguaruna" },
  { subtag: "ags", description: "Esimbi" },
  { subtag: "agt", description: "Central Cagayan Agta" },
  { subtag: "agu", description: "Aguacateco" },
  { subtag: "agv", description: "Remontado Dumagat" },
  { subtag: "agw", description: "Kahua" },
  { subtag: "agx", description: "Aghul" },
  { subtag: "agy", description: "Southern Alta" },
  { subtag: "agz", description: "Mt. Iriga Agta" },
  { subtag: "aha", description: "Ahanta" },
  { subtag: "ahb", description: "Axamb" },
  { subtag: "ahg", description: "Qimant" },
  { subtag: "ahh", description: "Aghu" },
  { subtag: "ahi", description: "Tiagbamrin Aizi" },
  { subtag: "ahk", description: "Akha" },
  { subtag: "ahl", description: "Igo" },
  { subtag: "ahm", description: "Mobumrin Aizi" },
  { subtag: "ahn", description: "Àhàn" },
  { subtag: "aho", description: "Ahom" },
  { subtag: "ahp", description: "Aproumu Aizi" },
  { subtag: "ahr", description: "Ahirani" },
  { subtag: "ahs", description: "Ashe" },
  { subtag: "aht", description: "Ahtena" },
  { subtag: "aia", description: "Arosi" },
  { subtag: "aib", description: "Ainu (China)" },
  { subtag: "aic", description: "Ainbai" },
  { subtag: "aid", description: "Alngith" },
  { subtag: "aie", description: "Amara" },
  { subtag: "aif", description: "Agi" },
  { subtag: "aig", description: "Antigua and Barbuda Creole English" },
  { subtag: "aih", description: "Ai-Cham" },
  { subtag: "aii", description: "Assyrian Neo-Aramaic" },
  { subtag: "aij", description: "Lishanid Noshan" },
  { subtag: "aik", description: "Ake" },
  { subtag: "ail", description: "Aimele" },
  { subtag: "aim", description: "Aimol" },
  { subtag: "ain", description: "Ainu (Japan)" },
  { subtag: "aio", description: "Aiton" },
  { subtag: "aip", description: "Burumakok" },
  { subtag: "aiq", description: "Aimaq" },
  { subtag: "air", description: "Airoran" },
  { subtag: "ait", description: "Arikem" },
  { subtag: "aiw", description: "Aari" },
  { subtag: "aix", description: "Aighon" },
  { subtag: "aiy", description: "Ali" },
  { subtag: "aja", description: "Aja (South Sudan)" },
  { subtag: "ajg", description: "Aja (Benin)" },
  { subtag: "aji", description: "Ajië" },
  { subtag: "ajn", description: "Andajin" },
  { subtag: "ajp", description: "South Levantine Arabic" },
  { subtag: "ajs", description: "Algerian Jewish Sign Language" },
  { subtag: "aju", description: "Judeo-Moroccan Arabic" },
  { subtag: "ajw", description: "Ajawa" },
  { subtag: "ajz", description: "Amri Karbi" },
  { subtag: "akb", description: "Batak Angkola" },
  { subtag: "akc", description: "Mpur" },
  { subtag: "akd", description: "Ukpet-Ehom" },
  { subtag: "ake", description: "Akawaio" },
  { subtag: "akf", description: "Akpa" },
  { subtag: "akg", description: "Anakalangu" },
  { subtag: "akh", description: "Angal Heneng" },
  { subtag: "aki", description: "Aiome" },
  { subtag: "akj", description: "Aka-Jeru" },
  { subtag: "akk", description: "Akkadian" },
  { subtag: "akl", description: "Aklanon" },
  { subtag: "akm", description: "Aka-Bo" },
  { subtag: "ako", description: "Akurio" },
  { subtag: "akp", description: "Siwu" },
  { subtag: "akq", description: "Ak" },
  { subtag: "akr", description: "Araki" },
  { subtag: "aks", description: "Akaselem" },
  { subtag: "akt", description: "Akolet" },
  { subtag: "aku", description: "Akum" },
  { subtag: "akv", description: "Akhvakh" },
  { subtag: "akw", description: "Akwa" },
  { subtag: "akx", description: "Aka-Kede" },
  { subtag: "aky", description: "Aka-Kol" },
  { subtag: "akz", description: "Alabama" },
  { subtag: "ala", description: "Alago" },
  { subtag: "alc", description: "Qawasqar" },
  { subtag: "ald", description: "Alladian" },
  { subtag: "ale", description: "Aleut" },
  { subtag: "alf", description: "Alege" },
  { subtag: "alg", description: "Algonquian languages" },
  { subtag: "alh", description: "Alawa" },
  { subtag: "ali", description: "Amaimon" },
  { subtag: "alj", description: "Alangan" },
  { subtag: "alk", description: "Alak" },
  { subtag: "all", description: "Allar" },
  { subtag: "alm", description: "Amblong" },
  { subtag: "aln", description: "Gheg Albanian" },
  { subtag: "alo", description: "Larike-Wakasihu" },
  { subtag: "alp", description: "Alune" },
  { subtag: "alq", description: "Algonquin" },
  { subtag: "alr", description: "Alutor" },
  { subtag: "als", description: "Tosk Albanian" },
  { subtag: "alt", description: "Southern Altai" },
  { subtag: "alu", description: "'Are'are" },
  { subtag: "alv", description: "Atlantic-Congo languages" },
  { subtag: "alw", description: "Alaba-K’abeena, Wanbasana" },
  { subtag: "alx", description: "Amol" },
  { subtag: "aly", description: "Alyawarr" },
  { subtag: "alz", description: "Alur" },
  { subtag: "ama", description: "Amanayé" },
  { subtag: "amb", description: "Ambo" },
  { subtag: "amc", description: "Amahuaca" },
  { subtag: "ame", description: "Yanesha'" },
  { subtag: "amf", description: "Hamer-Banna" },
  { subtag: "amg", description: "Amurdak" },
  { subtag: "ami", description: "Amis" },
  { subtag: "amj", description: "Amdang" },
  { subtag: "amk", description: "Ambai" },
  { subtag: "aml", description: "War-Jaintia" },
  { subtag: "amm", description: "Ama (Papua New Guinea)" },
  { subtag: "amn", description: "Amanab" },
  { subtag: "amo", description: "Amo" },
  { subtag: "amp", description: "Alamblak" },
  { subtag: "amq", description: "Amahai" },
  { subtag: "amr", description: "Amarakaeri" },
  { subtag: "ams", description: "Southern Amami-Oshima" },
  { subtag: "amt", description: "Amto" },
  { subtag: "amu", description: "Guerrero Amuzgo" },
  { subtag: "amv", description: "Ambelau" },
  { subtag: "amw", description: "Western Neo-Aramaic" },
  { subtag: "amx", description: "Anmatyerre" },
  { subtag: "amy", description: "Ami" },
  { subtag: "amz", description: "Atampaya" },
  { subtag: "ana", description: "Andaqui" },
  { subtag: "anb", description: "Andoa" },
  { subtag: "anc", description: "Ngas" },
  { subtag: "and", description: "Ansus" },
  { subtag: "ane", description: "Xârâcùù" },
  { subtag: "anf", description: "Animere" },
  { subtag: "ang", description: "Old English (ca. 450-1100)" },
  { subtag: "anh", description: "Nend" },
  { subtag: "ani", description: "Andi" },
  { subtag: "anj", description: "Anor" },
  { subtag: "ank", description: "Goemai" },
  { subtag: "anl", description: "Anu-Hkongso Chin" },
  { subtag: "anm", description: "Anal" },
  { subtag: "ann", description: "Obolo" },
  { subtag: "ano", description: "Andoque" },
  { subtag: "anp", description: "Angika" },
  { subtag: "anq", description: "Jarawa (India)" },
  { subtag: "anr", description: "Andh" },
  { subtag: "ans", description: "Anserma" },
  { subtag: "ant", description: "Antakarinya, Antikarinya" },
  { subtag: "anu", description: "Anuak" },
  { subtag: "anv", description: "Denya" },
  { subtag: "anw", description: "Anaang" },
  { subtag: "anx", description: "Andra-Hus" },
  { subtag: "any", description: "Anyin" },
  { subtag: "anz", description: "Anem" },
  { subtag: "aoa", description: "Angolar" },
  { subtag: "aob", description: "Abom" },
  { subtag: "aoc", description: "Pemon" },
  { subtag: "aod", description: "Andarum" },
  { subtag: "aoe", description: "Angal Enen" },
  { subtag: "aof", description: "Bragat" },
  { subtag: "aog", description: "Angoram" },
  { subtag: "aoi", description: "Anindilyakwa" },
  { subtag: "aoj", description: "Mufian" },
  { subtag: "aok", description: "Arhö" },
  { subtag: "aol", description: "Alor" },
  { subtag: "aom", description: "Ömie" },
  { subtag: "aon", description: "Bumbita Arapesh" },
  { subtag: "aor", description: "Aore" },
  { subtag: "aos", description: "Taikat" },
  { subtag: "aot", description: "Atong (India), A'tong" },
  { subtag: "aou", description: "A'ou" },
  { subtag: "aox", description: "Atorada" },
  { subtag: "aoz", description: "Uab Meto" },
  { subtag: "apa", description: "Apache languages" },
  { subtag: "apb", description: "Sa'a" },
  { subtag: "apc", description: "North Levantine Arabic" },
  { subtag: "apd", description: "Sudanese Arabic" },
  { subtag: "ape", description: "Bukiyip" },
  { subtag: "apf", description: "Pahanan Agta" },
  { subtag: "apg", description: "Ampanang" },
  { subtag: "aph", description: "Athpariya" },
  { subtag: "api", description: "Apiaká" },
  { subtag: "apj", description: "Jicarilla Apache" },
  { subtag: "apk", description: "Kiowa Apache" },
  { subtag: "apl", description: "Lipan Apache" },
  { subtag: "apm", description: "Mescalero-Chiricahua Apache" },
  { subtag: "apn", description: "Apinayé" },
  { subtag: "apo", description: "Ambul" },
  { subtag: "app", description: "Apma" },
  { subtag: "apq", description: "A-Pucikwar" },
  { subtag: "apr", description: "Arop-Lokep" },
  { subtag: "aps", description: "Arop-Sissano" },
  { subtag: "apt", description: "Apatani" },
  { subtag: "apu", description: "Apurinã" },
  { subtag: "apv", description: "Alapmunte" },
  { subtag: "apw", description: "Western Apache" },
  { subtag: "apx", description: "Aputai" },
  { subtag: "apy", description: "Apalaí" },
  { subtag: "apz", description: "Safeyoka" },
  { subtag: "aqa", description: "Alacalufan languages" },
  { subtag: "aqc", description: "Archi" },
  { subtag: "aqd", description: "Ampari Dogon" },
  { subtag: "aqg", description: "Arigidi" },
  { subtag: "aqk", description: "Aninka" },
  { subtag: "aql", description: "Algic languages" },
  { subtag: "aqm", description: "Atohwaim" },
  { subtag: "aqn", description: "Northern Alta" },
  { subtag: "aqp", description: "Atakapa" },
  { subtag: "aqr", description: "Arhâ" },
  { subtag: "aqt", description: "Angaité" },
  { subtag: "aqz", description: "Akuntsu" },
  { subtag: "arb", description: "Standard Arabic" },
  { subtag: "arc", description: "Official Aramaic (700-300 BCE), Imperial Aramaic (700-300 BCE)" },
  { subtag: "ard", description: "Arabana" },
  { subtag: "are", description: "Western Arrarnta" },
  { subtag: "arh", description: "Arhuaco" },
  { subtag: "ari", description: "Arikara" },
  { subtag: "arj", description: "Arapaso" },
  { subtag: "ark", description: "Arikapú" },
  { subtag: "arl", description: "Arabela" },
  { subtag: "arn", description: "Mapudungun, Mapuche" },
  { subtag: "aro", description: "Araona" },
  { subtag: "arp", description: "Arapaho" },
  { subtag: "arq", description: "Algerian Arabic" },
  { subtag: "arr", description: "Karo (Brazil)" },
  { subtag: "ars", description: "Najdi Arabic" },
  { subtag: "art", description: "Artificial languages" },
  { subtag: "aru", description: "Aruá (Amazonas State), Arawá" },
  { subtag: "arv", description: "Arbore" },
  { subtag: "arw", description: "Arawak" },
  { subtag: "arx", description: "Aruá (Rodonia State)" },
  { subtag: "ary", description: "Moroccan Arabic" },
  { subtag: "arz", description: "Egyptian Arabic" },
  { subtag: "asa", description: "Asu (Tanzania)" },
  { subtag: "asb", description: "Assiniboine" },
  { subtag: "asc", description: "Casuarina Coast Asmat" },
  { subtag: "ase", description: "American Sign Language" },
  { subtag: "asf", description: "Auslan, Australian Sign Language" },
  { subtag: "asg", description: "Cishingini" },
  { subtag: "ash", description: "Abishira" },
  { subtag: "asi", description: "Buruwai" },
  { subtag: "asj", description: "Sari" },
  { subtag: "ask", description: "Ashkun" },
  { subtag: "asl", description: "Asilulu" },
  { subtag: "asn", description: "Xingú Asuriní" },
  { subtag: "aso", description: "Dano" },
  { subtag: "asp", description: "Algerian Sign Language" },
  { subtag: "asq", description: "Austrian Sign Language" },
  { subtag: "asr", description: "Asuri" },
  { subtag: "ass", description: "Ipulo" },
  { subtag: "ast", description: "Asturian, Asturleonese, Bable, Leonese" },
  { subtag: "asu", description: "Tocantins Asurini" },
  { subtag: "asv", description: "Asoa" },
  { subtag: "asw", description: "Australian Aborigines Sign Language" },
  { subtag: "asx", description: "Muratayak" },
  { subtag: "asy", description: "Yaosakor Asmat" },
  { subtag: "asz", description: "As" },
  { subtag: "ata", description: "Pele-Ata" },
  { subtag: "atb", description: "Zaiwa" },
  { subtag: "atc", description: "Atsahuaca" },
  { subtag: "atd", description: "Ata Manobo" },
  { subtag: "ate", description: "Atemble" },
  { subtag: "atg", description: "Ivbie North-Okpela-Arhe" },
  { subtag: "ath", description: "Athapascan languages" },
  { subtag: "ati", description: "Attié" },
  { subtag: "atj", description: "Atikamekw" },
  { subtag: "atk", description: "Ati" },
  { subtag: "atl", description: "Mt. Iraya Agta" },
  { subtag: "atm", description: "Ata" },
  { subtag: "atn", description: "Ashtiani" },
  { subtag: "ato", description: "Atong (Cameroon)" },
  { subtag: "atp", description: "Pudtol Atta" },
  { subtag: "atq", description: "Aralle-Tabulahan" },
  { subtag: "atr", description: "Waimiri-Atroari" },
  { subtag: "ats", description: "Gros Ventre" },
  { subtag: "att", description: "Pamplona Atta" },
  { subtag: "atu", description: "Reel" },
  { subtag: "atv", description: "Northern Altai" },
  { subtag: "atw", description: "Atsugewi" },
  { subtag: "atx", description: "Arutani" },
  { subtag: "aty", description: "Aneityum" },
  { subtag: "atz", description: "Arta" },
  { subtag: "aua", description: "Asumboa" },
  { subtag: "aub", description: "Alugu" },
  { subtag: "auc", description: "Waorani" },
  { subtag: "aud", description: "Anuta" },
  { subtag: "auf", description: "Arauan languages" },
  { subtag: "aug", description: "Aguna" },
  { subtag: "auh", description: "Aushi" },
  { subtag: "aui", description: "Anuki" },
  { subtag: "auj", description: "Awjilah" },
  { subtag: "auk", description: "Heyo" },
  { subtag: "aul", description: "Aulua" },
  { subtag: "aum", description: "Asu (Nigeria)" },
  { subtag: "aun", description: "Molmo One" },
  { subtag: "auo", description: "Auyokawa" },
  { subtag: "aup", description: "Makayam" },
  { subtag: "auq", description: "Anus, Korur" },
  { subtag: "aur", description: "Aruek" },
  { subtag: "aus", description: "Australian languages" },
  { subtag: "aut", description: "Austral" },
  { subtag: "auu", description: "Auye" },
  { subtag: "auw", description: "Awyi" },
  { subtag: "aux", description: "Aurá" },
  { subtag: "auy", description: "Awiyaana" },
  { subtag: "auz", description: "Uzbeki Arabic" },
  { subtag: "avb", description: "Avau" },
  { subtag: "avd", description: "Alviri-Vidari" },
  { subtag: "avi", description: "Avikam" },
  { subtag: "avk", description: "Kotava" },
  { subtag: "avl", description: "Eastern Egyptian Bedawi Arabic" },
  { subtag: "avm", description: "Angkamuthi" },
  { subtag: "avn", description: "Avatime" },
  { subtag: "avo", description: "Agavotaguerra" },
  { subtag: "avs", description: "Aushiri" },
  { subtag: "avt", description: "Au" },
  { subtag: "avu", description: "Avokaya" },
  { subtag: "avv", description: "Avá-Canoeiro" },
  { subtag: "awa", description: "Awadhi" },
  { subtag: "awb", description: "Awa (Papua New Guinea)" },
  { subtag: "awc", description: "Cicipu" },
  { subtag: "awd", description: "Arawakan languages" },
  { subtag: "awe", description: "Awetí" },
  { subtag: "awg", description: "Anguthimri" },
  { subtag: "awh", description: "Awbono" },
  { subtag: "awi", description: "Aekyom" },
  { subtag: "awk", description: "Awabakal" },
  { subtag: "awm", description: "Arawum" },
  { subtag: "awn", description: "Awngi" },
  { subtag: "awo", description: "Awak" },
  { subtag: "awr", description: "Awera" },
  { subtag: "aws", description: "South Awyu" },
  { subtag: "awt", description: "Araweté" },
  { subtag: "awu", description: "Central Awyu" },
  { subtag: "awv", description: "Jair Awyu" },
  { subtag: "aww", description: "Awun" },
  { subtag: "awx", description: "Awara" },
  { subtag: "awy", description: "Edera Awyu" },
  { subtag: "axb", description: "Abipon" },
  { subtag: "axe", description: "Ayerrerenge" },
  { subtag: "axg", description: "Mato Grosso Arára" },
  { subtag: "axk", description: "Yaka (Central African Republic)" },
  { subtag: "axl", description: "Lower Southern Aranda" },
  { subtag: "axm", description: "Middle Armenian" },
  { subtag: "axx", description: "Xârâgurè" },
  { subtag: "aya", description: "Awar" },
  { subtag: "ayb", description: "Ayizo Gbe" },
  { subtag: "ayc", description: "Southern Aymara" },
  { subtag: "ayd", description: "Ayabadhu" },
  { subtag: "aye", description: "Ayere" },
  { subtag: "ayg", description: "Ginyanga" },
  { subtag: "ayh", description: "Hadrami Arabic" },
  { subtag: "ayi", description: "Leyigha" },
  { subtag: "ayk", description: "Akuku" },
  { subtag: "ayl", description: "Libyan Arabic" },
  { subtag: "ayn", description: "Sanaani Arabic" },
  { subtag: "ayo", description: "Ayoreo" },
  { subtag: "ayp", description: "North Mesopotamian Arabic" },
  { subtag: "ayq", description: "Ayi (Papua New Guinea)" },
  { subtag: "ayr", description: "Central Aymara" },
  { subtag: "ays", description: "Sorsogon Ayta" },
  { subtag: "ayt", description: "Magbukun Ayta" },
  { subtag: "ayu", description: "Ayu" },
  { subtag: "ayz", description: "Mai Brat" },
  { subtag: "aza", description: "Azha" },
  { subtag: "azb", description: "South Azerbaijani" },
  { subtag: "azc", description: "Uto-Aztecan languages" },
  { subtag: "azd", description: "Eastern Durango Nahuatl" },
  { subtag: "azg", description: "San Pedro Amuzgos Amuzgo" },
  { subtag: "azj", description: "North Azerbaijani" },
  { subtag: "azm", description: "Ipalapa Amuzgo" },
  { subtag: "azn", description: "Western Durango Nahuatl" },
  { subtag: "azo", description: "Awing" },
  { subtag: "azt", description: "Faire Atta" },
  { subtag: "azz", description: "Highland Puebla Nahuatl" },
  { subtag: "baa", description: "Babatana" },
  { subtag: "bab", description: "Bainouk-Gunyuño" },
  { subtag: "bac", description: "Badui" },
  { subtag: "bad", description: "Banda languages" },
  { subtag: "bae", description: "Baré" },
  { subtag: "baf", description: "Nubaca" },
  { subtag: "bag", description: "Tuki" },
  { subtag: "bah", description: "Bahamas Creole English" },
  { subtag: "bai", description: "Bamileke languages" },
  { subtag: "baj", description: "Barakai" },
  { subtag: "bal", description: "Baluchi" },
  { subtag: "ban", description: "Balinese" },
  { subtag: "bao", description: "Waimaha" },
  { subtag: "bap", description: "Bantawa" },
  { subtag: "bar", description: "Bavarian" },
  { subtag: "bas", description: "Basa (Cameroon)" },
  { subtag: "bat", description: "Baltic languages" },
  { subtag: "bau", description: "Bada (Nigeria)" },
  { subtag: "bav", description: "Vengo" },
  { subtag: "baw", description: "Bambili-Bambui" },
  { subtag: "bax", description: "Bamun" },
  { subtag: "bay", description: "Batuley" },
  { subtag: "bba", description: "Baatonum" },
  { subtag: "bbb", description: "Barai" },
  { subtag: "bbc", description: "Batak Toba" },
  { subtag: "bbd", description: "Bau" },
  { subtag: "bbe", description: "Bangba" },
  { subtag: "bbf", description: "Baibai" },
  { subtag: "bbg", description: "Barama" },
  { subtag: "bbh", description: "Bugan" },
  { subtag: "bbi", description: "Barombi" },
  { subtag: "bbj", description: "Ghomálá'" },
  { subtag: "bbk", description: "Babanki" },
  { subtag: "bbl", description: "Bats" },
  { subtag: "bbm", description: "Babango" },
  { subtag: "bbn", description: "Uneapa" },
  { subtag: "bbo", description: "Northern Bobo Madaré, Konabéré" },
  { subtag: "bbp", description: "West Central Banda" },
  { subtag: "bbq", description: "Bamali" },
  { subtag: "bbr", description: "Girawa" },
  { subtag: "bbs", description: "Bakpinka" },
  { subtag: "bbt", description: "Mburku" },
  { subtag: "bbu", description: "Kulung (Nigeria)" },
  { subtag: "bbv", description: "Karnai" },
  { subtag: "bbw", description: "Baba" },
  { subtag: "bbx", description: "Bubia" },
  { subtag: "bby", description: "Befang" },
  { subtag: "bca", description: "Central Bai" },
  { subtag: "bcb", description: "Bainouk-Samik" },
  { subtag: "bcc", description: "Southern Balochi" },
  { subtag: "bcd", description: "North Babar" },
  { subtag: "bce", description: "Bamenyam" },
  { subtag: "bcf", description: "Bamu" },
  { subtag: "bcg", description: "Baga Pokur" },
  { subtag: "bch", description: "Bariai" },
  { subtag: "bci", description: "Baoulé" },
  { subtag: "bcj", description: "Bardi" },
  { subtag: "bck", description: "Bunuba" },
  { subtag: "bcl", description: "Central Bikol" },
  { subtag: "bcm", description: "Bannoni" },
  { subtag: "bcn", description: "Bali (Nigeria)" },
  { subtag: "bco", description: "Kaluli" },
  { subtag: "bcp", description: "Bali (Democratic Republic of Congo)" },
  { subtag: "bcq", description: "Bench" },
  { subtag: "bcr", description: "Babine" },
  { subtag: "bcs", description: "Kohumono" },
  { subtag: "bct", description: "Bendi" },
  { subtag: "bcu", description: "Awad Bing" },
  { subtag: "bcv", description: "Shoo-Minda-Nye" },
  { subtag: "bcw", description: "Bana" },
  { subtag: "bcy", description: "Bacama" },
  { subtag: "bcz", description: "Bainouk-Gunyaamolo" },
  { subtag: "bda", description: "Bayot" },
  { subtag: "bdb", description: "Basap" },
  { subtag: "bdc", description: "Emberá-Baudó" },
  { subtag: "bdd", description: "Bunama" },
  { subtag: "bde", description: "Bade" },
  { subtag: "bdf", description: "Biage" },
  { subtag: "bdg", description: "Bonggi" },
  { subtag: "bdh", description: "Baka (South Sudan)" },
  { subtag: "bdi", description: "Burun" },
  { subtag: "bdj", description: "Bai (South Sudan), Bai" },
  { subtag: "bdk", description: "Budukh" },
  { subtag: "bdl", description: "Indonesian Bajau" },
  { subtag: "bdm", description: "Buduma" },
  { subtag: "bdn", description: "Baldemu" },
  { subtag: "bdo", description: "Morom" },
  { subtag: "bdp", description: "Bende" },
  { subtag: "bdq", description: "Bahnar" },
  { subtag: "bdr", description: "West Coast Bajau" },
  { subtag: "bds", description: "Burunge" },
  { subtag: "bdt", description: "Bokoto" },
  { subtag: "bdu", description: "Oroko" },
  { subtag: "bdv", description: "Bodo Parja" },
  { subtag: "bdw", description: "Baham" },
  { subtag: "bdx", description: "Budong-Budong" },
  { subtag: "bdy", description: "Bandjalang" },
  { subtag: "bdz", description: "Badeshi" },
  { subtag: "bea", description: "Beaver" },
  { subtag: "beb", description: "Bebele" },
  { subtag: "bec", description: "Iceve-Maci" },
  { subtag: "bed", description: "Bedoanas" },
  { subtag: "bee", description: "Byangsi" },
  { subtag: "bef", description: "Benabena" },
  { subtag: "beg", description: "Belait" },
  { subtag: "beh", description: "Biali" },
  { subtag: "bei", description: "Bekati'" },
  { subtag: "bej", description: "Beja, Bedawiyet" },
  { subtag: "bek", description: "Bebeli" },
  { subtag: "bem", description: "Bemba (Zambia)" },
  { subtag: "beo", description: "Beami" },
  { subtag: "bep", description: "Besoa" },
  { subtag: "beq", description: "Beembe" },
  { subtag: "ber", description: "Berber languages" },
  { subtag: "bes", description: "Besme" },
  { subtag: "bet", description: "Guiberoua Béte" },
  { subtag: "beu", description: "Blagar" },
  { subtag: "bev", description: "Daloa Bété" },
  { subtag: "bew", description: "Betawi" },
  { subtag: "bex", description: "Jur Modo" },
  { subtag: "bey", description: "Beli (Papua New Guinea)" },
  { subtag: "bez", description: "Bena (Tanzania)" },
  { subtag: "bfa", description: "Bari" },
  { subtag: "bfb", description: "Pauri Bareli" },
  { subtag: "bfc", description: "Panyi Bai, Northern Bai" },
  { subtag: "bfd", description: "Bafut" },
  { subtag: "bfe", description: "Betaf, Tena" },
  { subtag: "bff", description: "Bofi" },
  { subtag: "bfg", description: "Busang Kayan" },
  { subtag: "bfh", description: "Blafe" },
  { subtag: "bfi", description: "British Sign Language" },
  { subtag: "bfj", description: "Bafanji" },
  { subtag: "bfk", description: "Ban Khor Sign Language" },
  { subtag: "bfl", description: "Banda-Ndélé" },
  { subtag: "bfm", description: "Mmen" },
  { subtag: "bfn", description: "Bunak" },
  { subtag: "bfo", description: "Malba Birifor" },
  { subtag: "bfp", description: "Beba" },
  { subtag: "bfq", description: "Badaga" },
  { subtag: "bfr", description: "Bazigar" },
  { subtag: "bfs", description: "Southern Bai" },
  { subtag: "bft", description: "Balti" },
  { subtag: "bfu", description: "Gahri" },
  { subtag: "bfw", description: "Bondo" },
  { subtag: "bfx", description: "Bantayanon" },
  { subtag: "bfy", description: "Bagheli" },
  { subtag: "bfz", description: "Mahasu Pahari" },
  { subtag: "bga", description: "Gwamhi-Wuri" },
  { subtag: "bgb", description: "Bobongko" },
  { subtag: "bgc", description: "Haryanvi" },
  { subtag: "bgd", description: "Rathwi Bareli" },
  { subtag: "bge", description: "Bauria" },
  { subtag: "bgf", description: "Bangandu" },
  { subtag: "bgg", description: "Bugun" },
  { subtag: "bgi", description: "Giangan" },
  { subtag: "bgj", description: "Bangolan" },
  { subtag: "bgk", description: "Bit, Buxinhua" },
  { subtag: "bgl", description: "Bo (Laos)" },
  { subtag: "bgn", description: "Western Balochi" },
  { subtag: "bgo", description: "Baga Koga" },
  { subtag: "bgp", description: "Eastern Balochi" },
  { subtag: "bgq", description: "Bagri" },
  { subtag: "bgr", description: "Bawm Chin" },
  { subtag: "bgs", description: "Tagabawa" },
  { subtag: "bgt", description: "Bughotu" },
  { subtag: "bgu", description: "Mbongno" },
  { subtag: "bgv", description: "Warkay-Bipim" },
  { subtag: "bgw", description: "Bhatri" },
  { subtag: "bgx", description: "Balkan Gagauz Turkish" },
  { subtag: "bgy", description: "Benggoi" },
  { subtag: "bgz", description: "Banggai" },
  { subtag: "bha", description: "Bharia" },
  { subtag: "bhb", description: "Bhili" },
  { subtag: "bhc", description: "Biga" },
  { subtag: "bhd", description: "Bhadrawahi" },
  { subtag: "bhe", description: "Bhaya" },
  { subtag: "bhf", description: "Odiai" },
  { subtag: "bhg", description: "Binandere" },
  { subtag: "bhh", description: "Bukharic" },
  { subtag: "bhi", description: "Bhilali" },
  { subtag: "bhj", description: "Bahing" },
  { subtag: "bhl", description: "Bimin" },
  { subtag: "bhm", description: "Bathari" },
  { subtag: "bhn", description: "Bohtan Neo-Aramaic" },
  { subtag: "bho", description: "Bhojpuri" },
  { subtag: "bhp", description: "Bima" },
  { subtag: "bhq", description: "Tukang Besi South" },
  { subtag: "bhr", description: "Bara Malagasy" },
  { subtag: "bhs", description: "Buwal" },
  { subtag: "bht", description: "Bhattiyali" },
  { subtag: "bhu", description: "Bhunjia" },
  { subtag: "bhv", description: "Bahau" },
  { subtag: "bhw", description: "Biak" },
  { subtag: "bhx", description: "Bhalay" },
  { subtag: "bhy", description: "Bhele" },
  { subtag: "bhz", description: "Bada (Indonesia)" },
  { subtag: "bia", description: "Badimaya" },
  { subtag: "bib", description: "Bissa, Bisa" },
  { subtag: "bid", description: "Bidiyo" },
  { subtag: "bie", description: "Bepour" },
  { subtag: "bif", description: "Biafada" },
  { subtag: "big", description: "Biangai" },
  { subtag: "bik", description: "Bikol" },
  { subtag: "bil", description: "Bile" },
  { subtag: "bim", description: "Bimoba" },
  { subtag: "bin", description: "Bini, Edo" },
  { subtag: "bio", description: "Nai" },
  { subtag: "bip", description: "Bila" },
  { subtag: "biq", description: "Bipi" },
  { subtag: "bir", description: "Bisorio" },
  { subtag: "bit", description: "Berinomo" },
  { subtag: "biu", description: "Biete" },
  { subtag: "biv", description: "Southern Birifor" },
  { subtag: "biw", description: "Kol (Cameroon)" },
  { subtag: "bix", description: "Bijori" },
  { subtag: "biy", description: "Birhor" },
  { subtag: "biz", description: "Baloi" },
  { subtag: "bja", description: "Budza" },
  { subtag: "bjb", description: "Banggarla" },
  { subtag: "bjc", description: "Bariji" },
  { subtag: "bje", description: "Biao-Jiao Mien" },
  { subtag: "bjf", description: "Barzani Jewish Neo-Aramaic" },
  { subtag: "bjg", description: "Bidyogo" },
  { subtag: "bjh", description: "Bahinemo" },
  { subtag: "bji", description: "Burji" },
  { subtag: "bjj", description: "Kanauji" },
  { subtag: "bjk", description: "Barok" },
  { subtag: "bjl", description: "Bulu (Papua New Guinea)" },
  { subtag: "bjm", description: "Bajelani" },
  { subtag: "bjn", description: "Banjar" },
  { subtag: "bjo", description: "Mid-Southern Banda" },
  { subtag: "bjp", description: "Fanamaket" },
  { subtag: "bjr", description: "Binumarien" },
  { subtag: "bjs", description: "Bajan" },
  { subtag: "bjt", description: "Balanta-Ganja" },
  { subtag: "bju", description: "Busuu" },
  { subtag: "bjv", description: "Bedjond" },
  { subtag: "bjw", description: "Bakwé" },
  { subtag: "bjx", description: "Banao Itneg" },
  { subtag: "bjy", description: "Bayali" },
  { subtag: "bjz", description: "Baruga" },
  { subtag: "bka", description: "Kyak" },
  { subtag: "bkc", description: "Baka (Cameroon)" },
  { subtag: "bkd", description: "Binukid, Talaandig" },
  { subtag: "bkf", description: "Beeke" },
  { subtag: "bkg", description: "Buraka" },
  { subtag: "bkh", description: "Bakoko" },
  { subtag: "bki", description: "Baki" },
  { subtag: "bkj", description: "Pande" },
  { subtag: "bkk", description: "Brokskat" },
  { subtag: "bkl", description: "Berik" },
  { subtag: "bkm", description: "Kom (Cameroon)" },
  { subtag: "bkn", description: "Bukitan" },
  { subtag: "bko", description: "Kwa'" },
  { subtag: "bkp", description: "Boko (Democratic Republic of Congo)" },
  { subtag: "bkq", description: "Bakairí" },
  { subtag: "bkr", description: "Bakumpai" },
  { subtag: "bks", description: "Northern Sorsoganon" },
  { subtag: "bkt", description: "Boloki" },
  { subtag: "bku", description: "Buhid" },
  { subtag: "bkv", description: "Bekwarra" },
  { subtag: "bkw", description: "Bekwel" },
  { subtag: "bkx", description: "Baikeno" },
  { subtag: "bky", description: "Bokyi" },
  { subtag: "bkz", description: "Bungku" },
  { subtag: "bla", description: "Siksika" },
  { subtag: "blb", description: "Bilua" },
  { subtag: "blc", description: "Bella Coola" },
  { subtag: "bld", description: "Bolango" },
  { subtag: "ble", description: "Balanta-Kentohe" },
  { subtag: "blf", description: "Buol" },
  { subtag: "blh", description: "Kuwaa" },
  { subtag: "bli", description: "Bolia" },
  { subtag: "blj", description: "Bolongan" },
  { subtag: "blk", description: "Pa'o Karen, Pa'O" },
  { subtag: "bll", description: "Biloxi" },
  { subtag: "blm", description: "Beli (South Sudan)" },
  { subtag: "bln", description: "Southern Catanduanes Bikol" },
  { subtag: "blo", description: "Anii" },
  { subtag: "blp", description: "Blablanga" },
  { subtag: "blq", description: "Baluan-Pam" },
  { subtag: "blr", description: "Blang" },
  { subtag: "bls", description: "Balaesang" },
  { subtag: "blt", description: "Tai Dam" },
  { subtag: "blv", description: "Kibala, Bolo" },
  { subtag: "blw", description: "Balangao" },
  { subtag: "blx", description: "Mag-Indi Ayta" },
  { subtag: "bly", description: "Notre" },
  { subtag: "blz", description: "Balantak" },
  { subtag: "bma", description: "Lame" },
  { subtag: "bmb", description: "Bembe" },
  { subtag: "bmc", description: "Biem" },
  { subtag: "bmd", description: "Baga Manduri" },
  { subtag: "bme", description: "Limassa" },
  { subtag: "bmf", description: "Bom-Kim" },
  { subtag: "bmg", description: "Bamwe" },
  { subtag: "bmh", description: "Kein" },
  { subtag: "bmi", description: "Bagirmi" },
  { subtag: "bmj", description: "Bote-Majhi" },
  { subtag: "bmk", description: "Ghayavi" },
  { subtag: "bml", description: "Bomboli" },
  { subtag: "bmm", description: "Northern Betsimisaraka Malagasy" },
  { subtag: "bmn", description: "Bina (Papua New Guinea)" },
  { subtag: "bmo", description: "Bambalang" },
  { subtag: "bmp", description: "Bulgebi" },
  { subtag: "bmq", description: "Bomu" },
  { subtag: "bmr", description: "Muinane" },
  { subtag: "bms", description: "Bilma Kanuri" },
  { subtag: "bmt", description: "Biao Mon" },
  { subtag: "bmu", description: "Somba-Siawari" },
  { subtag: "bmv", description: "Bum" },
  { subtag: "bmw", description: "Bomwali" },
  { subtag: "bmx", description: "Baimak" },
  { subtag: "bmz", description: "Baramu" },
  { subtag: "bna", description: "Bonerate" },
  { subtag: "bnb", description: "Bookan" },
  { subtag: "bnc", description: "Bontok" },
  { subtag: "bnd", description: "Banda (Indonesia)" },
  { subtag: "bne", description: "Bintauna" },
  { subtag: "bnf", description: "Masiwang" },
  { subtag: "bng", description: "Benga" },
  { subtag: "bni", description: "Bangi" },
  { subtag: "bnj", description: "Eastern Tawbuid" },
  { subtag: "bnk", description: "Bierebo" },
  { subtag: "bnl", description: "Boon" },
  { subtag: "bnm", description: "Batanga" },
  { subtag: "bnn", description: "Bunun" },
  { subtag: "bno", description: "Bantoanon" },
  { subtag: "bnp", description: "Bola" },
  { subtag: "bnq", description: "Bantik" },
  { subtag: "bnr", description: "Butmas-Tur" },
  { subtag: "bns", description: "Bundeli" },
  { subtag: "bnt", description: "Bantu languages" },
  { subtag: "bnu", description: "Bentong" },
  { subtag: "bnv", description: "Bonerif, Beneraf, Edwas" },
  { subtag: "bnw", description: "Bisis" },
  { subtag: "bnx", description: "Bangubangu" },
  { subtag: "bny", description: "Bintulu" },
  { subtag: "bnz", description: "Beezen" },
  { subtag: "boa", description: "Bora" },
  { subtag: "bob", description: "Aweer" },
  { subtag: "boe", description: "Mundabli" },
  { subtag: "bof", description: "Bolon" },
  { subtag: "bog", description: "Bamako Sign Language" },
  { subtag: "boh", description: "Boma" },
  { subtag: "boi", description: "Barbareño" },
  { subtag: "boj", description: "Anjam" },
  { subtag: "bok", description: "Bonjo" },
  { subtag: "bol", description: "Bole" },
  { subtag: "bom", description: "Berom" },
  { subtag: "bon", description: "Bine" },
  { subtag: "boo", description: "Tiemacèwè Bozo" },
  { subtag: "bop", description: "Bonkiman" },
  { subtag: "boq", description: "Bogaya" },
  { subtag: "bor", description: "Borôro" },
  { subtag: "bot", description: "Bongo" },
  { subtag: "bou", description: "Bondei" },
  { subtag: "bov", description: "Tuwuli" },
  { subtag: "bow", description: "Rema" },
  { subtag: "box", description: "Buamu" },
  { subtag: "boy", description: "Bodo (Central African Republic)" },
  { subtag: "boz", description: "Tiéyaxo Bozo" },
  { subtag: "bpa", description: "Daakaka" },
  { subtag: "bpc", description: "Mbuk" },
  { subtag: "bpd", description: "Banda-Banda" },
  { subtag: "bpe", description: "Bauni" },
  { subtag: "bpg", description: "Bonggo" },
  { subtag: "bph", description: "Botlikh" },
  { subtag: "bpi", description: "Bagupi" },
  { subtag: "bpj", description: "Binji" },
  { subtag: "bpk", description: "Orowe, 'Ôrôê" },
  { subtag: "bpl", description: "Broome Pearling Lugger Pidgin" },
  { subtag: "bpm", description: "Biyom" },
  { subtag: "bpn", description: "Dzao Min" },
  { subtag: "bpo", description: "Anasi" },
  { subtag: "bpp", description: "Kaure" },
  { subtag: "bpq", description: "Banda Malay" },
  { subtag: "bpr", description: "Koronadal Blaan" },
  { subtag: "bps", description: "Sarangani Blaan" },
  { subtag: "bpt", description: "Barrow Point" },
  { subtag: "bpu", description: "Bongu" },
  { subtag: "bpv", description: "Bian Marind" },
  { subtag: "bpw", description: "Bo (Papua New Guinea)" },
  { subtag: "bpx", description: "Palya Bareli" },
  { subtag: "bpy", description: "Bishnupriya" },
  { subtag: "bpz", description: "Bilba" },
  { subtag: "bqa", description: "Tchumbuli" },
  { subtag: "bqb", description: "Bagusa" },
  { subtag: "bqc", description: "Boko (Benin), Boo" },
  { subtag: "bqd", description: "Bung" },
  { subtag: "bqf", description: "Baga Kaloum" },
  { subtag: "bqg", description: "Bago-Kusuntu" },
  { subtag: "bqh", description: "Baima" },
  { subtag: "bqi", description: "Bakhtiari" },
  { subtag: "bqj", description: "Bandial" },
  { subtag: "bqk", description: "Banda-Mbrès" },
  { subtag: "bql", description: "Bilakura" },
  { subtag: "bqm", description: "Wumboko" },
  { subtag: "bqn", description: "Bulgarian Sign Language" },
  { subtag: "bqo", description: "Balo" },
  { subtag: "bqp", description: "Busa" },
  { subtag: "bqq", description: "Biritai" },
  { subtag: "bqr", description: "Burusu" },
  { subtag: "bqs", description: "Bosngun" },
  { subtag: "bqt", description: "Bamukumbit" },
  { subtag: "bqu", description: "Boguru" },
  { subtag: "bqv", description: "Koro Wachi, Begbere-Ejar" },
  { subtag: "bqw", description: "Buru (Nigeria)" },
  { subtag: "bqx", description: "Baangi" },
  { subtag: "bqy", description: "Bengkala Sign Language" },
  { subtag: "bqz", description: "Bakaka" },
  { subtag: "bra", description: "Braj" },
  { subtag: "brb", description: "Brao, Lave" },
  { subtag: "brc", description: "Berbice Creole Dutch" },
  { subtag: "brd", description: "Baraamu" },
  { subtag: "brf", description: "Bira" },
  { subtag: "brg", description: "Baure" },
  { subtag: "brh", description: "Brahui" },
  { subtag: "bri", description: "Mokpwe" },
  { subtag: "brj", description: "Bieria" },
  { subtag: "brk", description: "Birked" },
  { subtag: "brl", description: "Birwa" },
  { subtag: "brm", description: "Barambu" },
  { subtag: "brn", description: "Boruca" },
  { subtag: "bro", description: "Brokkat" },
  { subtag: "brp", description: "Barapasi" },
  { subtag: "brq", description: "Breri" },
  { subtag: "brr", description: "Birao" },
  { subtag: "brs", description: "Baras" },
  { subtag: "brt", description: "Bitare" },
  { subtag: "bru", description: "Eastern Bru" },
  { subtag: "brv", description: "Western Bru" },
  { subtag: "brw", description: "Bellari" },
  { subtag: "brx", description: "Bodo (India)" },
  { subtag: "bry", description: "Burui" },
  { subtag: "brz", description: "Bilbil" },
  { subtag: "bsa", description: "Abinomn" },
  { subtag: "bsb", description: "Brunei Bisaya" },
  { subtag: "bsc", description: "Bassari, Oniyan" },
  { subtag: "bse", description: "Wushi" },
  { subtag: "bsf", description: "Bauchi" },
  { subtag: "bsg", description: "Bashkardi" },
  { subtag: "bsh", description: "Kati" },
  { subtag: "bsi", description: "Bassossi" },
  { subtag: "bsj", description: "Bangwinji" },
  { subtag: "bsk", description: "Burushaski" },
  { subtag: "bsl", description: "Basa-Gumna" },
  { subtag: "bsm", description: "Busami" },
  { subtag: "bsn", description: "Barasana-Eduria" },
  { subtag: "bso", description: "Buso" },
  { subtag: "bsp", description: "Baga Sitemu" },
  { subtag: "bsq", description: "Bassa" },
  { subtag: "bsr", description: "Bassa-Kontagora" },
  { subtag: "bss", description: "Akoose" },
  { subtag: "bst", description: "Basketo" },
  { subtag: "bsu", description: "Bahonsuai" },
  { subtag: "bsv", description: "Baga Sobané" },
  { subtag: "bsw", description: "Baiso" },
  { subtag: "bsx", description: "Yangkam" },
  { subtag: "bsy", description: "Sabah Bisaya" },
  { subtag: "bta", description: "Bata" },
  { subtag: "btc", description: "Bati (Cameroon)" },
  { subtag: "btd", description: "Batak Dairi" },
  { subtag: "bte", description: "Gamo-Ningi" },
  { subtag: "btf", description: "Birgit" },
  { subtag: "btg", description: "Gagnoa Bété" },
  { subtag: "bth", description: "Biatah Bidayuh" },
  { subtag: "bti", description: "Burate" },
  { subtag: "btj", description: "Bacanese Malay" },
  { subtag: "btk", description: "Batak languages" },
  { subtag: "btm", description: "Batak Mandailing" },
  { subtag: "btn", description: "Ratagnon" },
  { subtag: "bto", description: "Rinconada Bikol" },
  { subtag: "btp", description: "Budibud" },
  { subtag: "btq", description: "Batek" },
  { subtag: "btr", description: "Baetora" },
  { subtag: "bts", description: "Batak Simalungun" },
  { subtag: "btt", description: "Bete-Bendi" },
  { subtag: "btu", description: "Batu" },
  { subtag: "btv", description: "Bateri" },
  { subtag: "btw", description: "Butuanon" },
  { subtag: "btx", description: "Batak Karo" },
  { subtag: "bty", description: "Bobot" },
  { subtag: "btz", description: "Batak Alas-Kluet" },
  { subtag: "bua", description: "Buriat" },
  { subtag: "bub", description: "Bua" },
  { subtag: "buc", description: "Bushi" },
  { subtag: "bud", description: "Ntcham" },
  { subtag: "bue", description: "Beothuk" },
  { subtag: "buf", description: "Bushoong" },
  { subtag: "bug", description: "Buginese" },
  { subtag: "buh", description: "Younuo Bunu" },
  { subtag: "bui", description: "Bongili" },
  { subtag: "buj", description: "Basa-Gurmana" },
  { subtag: "buk", description: "Bugawac" },
  { subtag: "bum", description: "Bulu (Cameroon)" },
  { subtag: "bun", description: "Sherbro" },
  { subtag: "buo", description: "Terei" },
  { subtag: "bup", description: "Busoa" },
  { subtag: "buq", description: "Brem" },
  { subtag: "bus", description: "Bokobaru" },
  { subtag: "but", description: "Bungain" },
  { subtag: "buu", description: "Budu" },
  { subtag: "buv", description: "Bun" },
  { subtag: "buw", description: "Bubi" },
  { subtag: "bux", description: "Boghom" },
  { subtag: "buy", description: "Bullom So" },
  { subtag: "buz", description: "Bukwen" },
  { subtag: "bva", description: "Barein" },
  { subtag: "bvb", description: "Bube" },
  { subtag: "bvc", description: "Baelelea" },
  { subtag: "bvd", description: "Baeggu" },
  { subtag: "bve", description: "Berau Malay" },
  { subtag: "bvf", description: "Boor" },
  { subtag: "bvg", description: "Bonkeng" },
  { subtag: "bvh", description: "Bure" },
  { subtag: "bvi", description: "Belanda Viri" },
  { subtag: "bvj", description: "Baan" },
  { subtag: "bvk", description: "Bukat" },
  { subtag: "bvl", description: "Bolivian Sign Language" },
  { subtag: "bvm", description: "Bamunka" },
  { subtag: "bvn", description: "Buna" },
  { subtag: "bvo", description: "Bolgo" },
  { subtag: "bvp", description: "Bumang" },
  { subtag: "bvq", description: "Birri" },
  { subtag: "bvr", description: "Burarra" },
  { subtag: "bvt", description: "Bati (Indonesia)" },
  { subtag: "bvu", description: "Bukit Malay" },
  { subtag: "bvv", description: "Baniva" },
  { subtag: "bvw", description: "Boga" },
  { subtag: "bvx", description: "Dibole" },
  { subtag: "bvy", description: "Baybayanon" },
  { subtag: "bvz", description: "Bauzi" },
  { subtag: "bwa", description: "Bwatoo" },
  { subtag: "bwb", description: "Namosi-Naitasiri-Serua" },
  { subtag: "bwc", description: "Bwile" },
  { subtag: "bwd", description: "Bwaidoka" },
  { subtag: "bwe", description: "Bwe Karen" },
  { subtag: "bwf", description: "Boselewa" },
  { subtag: "bwg", description: "Barwe" },
  { subtag: "bwh", description: "Bishuo" },
  { subtag: "bwi", description: "Baniwa" },
  { subtag: "bwj", description: "Láá Láá Bwamu" },
  { subtag: "bwk", description: "Bauwaki" },
  { subtag: "bwl", description: "Bwela" },
  { subtag: "bwm", description: "Biwat" },
  { subtag: "bwn", description: "Wunai Bunu" },
  { subtag: "bwo", description: "Boro (Ethiopia), Borna (Ethiopia)" },
  { subtag: "bwp", description: "Mandobo Bawah" },
  { subtag: "bwq", description: "Southern Bobo Madaré" },
  { subtag: "bwr", description: "Bura-Pabir" },
  { subtag: "bws", description: "Bomboma" },
  { subtag: "bwt", description: "Bafaw-Balong" },
  { subtag: "bwu", description: "Buli (Ghana)" },
  { subtag: "bww", description: "Bwa" },
  { subtag: "bwx", description: "Bu-Nao Bunu" },
  { subtag: "bwy", description: "Cwi Bwamu" },
  { subtag: "bwz", description: "Bwisi" },
  { subtag: "bxa", description: "Tairaha" },
  { subtag: "bxb", description: "Belanda Bor" },
  { subtag: "bxc", description: "Molengue" },
  { subtag: "bxd", description: "Pela" },
  { subtag: "bxe", description: "Birale" },
  { subtag: "bxf", description: "Bilur, Minigir" },
  { subtag: "bxg", description: "Bangala" },
  { subtag: "bxh", description: "Buhutu" },
  { subtag: "bxi", description: "Pirlatapa" },
  { subtag: "bxj", description: "Bayungu" },
  { subtag: "bxk", description: "Bukusu, Lubukusu" },
  { subtag: "bxl", description: "Jalkunan" },
  { subtag: "bxm", description: "Mongolia Buriat" },
  { subtag: "bxn", description: "Burduna" },
  { subtag: "bxo", description: "Barikanchi" },
  { subtag: "bxp", description: "Bebil" },
  { subtag: "bxq", description: "Beele" },
  { subtag: "bxr", description: "Russia Buriat" },
  { subtag: "bxs", description: "Busam" },
  { subtag: "bxu", description: "China Buriat" },
  { subtag: "bxv", description: "Berakou" },
  { subtag: "bxw", description: "Bankagooma" },
  { subtag: "bxz", description: "Binahari" },
  { subtag: "bya", description: "Batak" },
  { subtag: "byb", description: "Bikya" },
  { subtag: "byc", description: "Ubaghara" },
  { subtag: "byd", description: "Benyadu'" },
  { subtag: "bye", description: "Pouye" },
  { subtag: "byf", description: "Bete" },
  { subtag: "byg", description: "Baygo" },
  { subtag: "byh", description: "Bhujel" },
  { subtag: "byi", description: "Buyu" },
  { subtag: "byj", description: "Bina (Nigeria)" },
  { subtag: "byk", description: "Biao" },
  { subtag: "byl", description: "Bayono" },
  { subtag: "bym", description: "Bidjara" },
  { subtag: "byn", description: "Bilin, Blin" },
  { subtag: "byo", description: "Biyo" },
  { subtag: "byp", description: "Bumaji" },
  { subtag: "byq", description: "Basay" },
  { subtag: "byr", description: "Baruya, Yipma" },
  { subtag: "bys", description: "Burak" },
  { subtag: "byt", description: "Berti" },
  { subtag: "byv", description: "Medumba" },
  { subtag: "byw", description: "Belhariya" },
  { subtag: "byx", description: "Qaqet" },
  { subtag: "byz", description: "Banaro" },
  { subtag: "bza", description: "Bandi" },
  { subtag: "bzb", description: "Andio" },
  { subtag: "bzc", description: "Southern Betsimisaraka Malagasy" },
  { subtag: "bzd", description: "Bribri" },
  { subtag: "bze", description: "Jenaama Bozo" },
  { subtag: "bzf", description: "Boikin" },
  { subtag: "bzg", description: "Babuza" },
  { subtag: "bzh", description: "Mapos Buang" },
  { subtag: "bzi", description: "Bisu" },
  { subtag: "bzj", description: "Belize Kriol English" },
  { subtag: "bzk", description: "Nicaragua Creole English" },
  { subtag: "bzl", description: "Boano (Sulawesi)" },
  { subtag: "bzm", description: "Bolondo" },
  { subtag: "bzn", description: "Boano (Maluku)" },
  { subtag: "bzo", description: "Bozaba" },
  { subtag: "bzp", description: "Kemberano" },
  { subtag: "bzq", description: "Buli (Indonesia)" },
  { subtag: "bzr", description: "Biri" },
  { subtag: "bzs", description: "Brazilian Sign Language" },
  { subtag: "bzt", description: "Brithenig" },
  { subtag: "bzu", description: "Burmeso" },
  { subtag: "bzv", description: "Naami" },
  { subtag: "bzw", description: "Basa (Nigeria)" },
  { subtag: "bzx", description: "Kɛlɛngaxo Bozo" },
  { subtag: "bzy", description: "Obanliku" },
  { subtag: "bzz", description: "Evant" },
  { subtag: "caa", description: "Chortí" },
  { subtag: "cab", description: "Garifuna" },
  { subtag: "cac", description: "Chuj" },
  { subtag: "cad", description: "Caddo" },
  { subtag: "cae", description: "Lehar, Laalaa" },
  { subtag: "caf", description: "Southern Carrier" },
  { subtag: "cag", description: "Nivaclé" },
  { subtag: "cah", description: "Cahuarano" },
  { subtag: "cai", description: "Central American Indian languages" },
  { subtag: "caj", description: "Chané" },
  { subtag: "cak", description: "Kaqchikel, Cakchiquel" },
  { subtag: "cal", description: "Carolinian" },
  { subtag: "cam", description: "Cemuhî" },
  { subtag: "can", description: "Chambri" },
  { subtag: "cao", description: "Chácobo" },
  { subtag: "cap", description: "Chipaya" },
  { subtag: "caq", description: "Car Nicobarese" },
  { subtag: "car", description: "Galibi Carib" },
  { subtag: "cas", description: "Tsimané" },
  { subtag: "cau", description: "Caucasian languages" },
  { subtag: "cav", description: "Cavineña" },
  { subtag: "caw", description: "Callawalla" },
  { subtag: "cax", description: "Chiquitano" },
  { subtag: "cay", description: "Cayuga" },
  { subtag: "caz", description: "Canichana" },
  { subtag: "cba", description: "Chibchan languages" },
  { subtag: "cbb", description: "Cabiyarí" },
  { subtag: "cbc", description: "Carapana" },
  { subtag: "cbd", description: "Carijona" },
  { subtag: "cbg", description: "Chimila" },
  { subtag: "cbi", description: "Chachi" },
  { subtag: "cbj", description: "Ede Cabe" },
  { subtag: "cbk", description: "Chavacano" },
  { subtag: "cbl", description: "Bualkhaw Chin" },
  { subtag: "cbn", description: "Nyahkur" },
  { subtag: "cbo", description: "Izora" },
  { subtag: "cbq", description: "Tsucuba, Cuba" },
  { subtag: "cbr", description: "Cashibo-Cacataibo" },
  { subtag: "cbs", description: "Cashinahua" },
  { subtag: "cbt", description: "Chayahuita" },
  { subtag: "cbu", description: "Candoshi-Shapra" },
  { subtag: "cbv", description: "Cacua" },
  { subtag: "cbw", description: "Kinabalian" },
  { subtag: "cby", description: "Carabayo" },
  { subtag: "ccc", description: "Chamicuro" },
  { subtag: "ccd", description: "Cafundo Creole" },
  { subtag: "cce", description: "Chopi" },
  { subtag: "ccg", description: "Samba Daka" },
  { subtag: "cch", description: "Atsam" },
  { subtag: "ccj", description: "Kasanga" },
  { subtag: "ccl", description: "Cutchi-Swahili" },
  { subtag: "ccm", description: "Malaccan Creole Malay" },
  { subtag: "ccn", description: "North Caucasian languages" },
  { subtag: "cco", description: "Comaltepec Chinantec" },
  { subtag: "ccp", description: "Chakma" },
  { subtag: "ccr", description: "Cacaopera" },
  { subtag: "ccs", description: "South Caucasian languages" },
  { subtag: "cda", description: "Choni" },
  { subtag: "cdc", description: "Chadic languages" },
  { subtag: "cdd", description: "Caddoan languages" },
  { subtag: "cde", description: "Chenchu" },
  { subtag: "cdf", description: "Chiru" },
  { subtag: "cdh", description: "Chambeali" },
  { subtag: "cdi", description: "Chodri" },
  { subtag: "cdj", description: "Churahi" },
  { subtag: "cdm", description: "Chepang" },
  { subtag: "cdn", description: "Chaudangsi" },
  { subtag: "cdo", description: "Min Dong Chinese" },
  { subtag: "cdr", description: "Cinda-Regi-Tiyal" },
  { subtag: "cds", description: "Chadian Sign Language" },
  { subtag: "cdy", description: "Chadong" },
  { subtag: "cdz", description: "Koda" },
  { subtag: "cea", description: "Lower Chehalis" },
  { subtag: "ceb", description: "Cebuano" },
  { subtag: "ceg", description: "Chamacoco" },
  { subtag: "cek", description: "Eastern Khumi Chin" },
  { subtag: "cel", description: "Celtic languages" },
  { subtag: "cen", description: "Cen" },
  { subtag: "cet", description: "Centúúm" },
  { subtag: "cey", description: "Ekai Chin" },
  { subtag: "cfa", description: "Dijim-Bwilim" },
  { subtag: "cfd", description: "Cara" },
  { subtag: "cfg", description: "Como Karim" },
  { subtag: "cfm", description: "Falam Chin" },
  { subtag: "cga", description: "Changriwa" },
  { subtag: "cgc", description: "Kagayanen" },
  { subtag: "cgg", description: "Chiga" },
  { subtag: "cgk", description: "Chocangacakha" },
  { subtag: "chb", description: "Chibcha" },
  { subtag: "chc", description: "Catawba" },
  { subtag: "chd", description: "Highland Oaxaca Chontal" },
  { subtag: "chf", description: "Tabasco Chontal" },
  { subtag: "chg", description: "Chagatai" },
  { subtag: "chh", description: "Chinook" },
  { subtag: "chj", description: "Ojitlán Chinantec" },
  { subtag: "chk", description: "Chuukese" },
  { subtag: "chl", description: "Cahuilla" },
  { subtag: "chm", description: "Mari (Russia)" },
  { subtag: "chn", description: "Chinook jargon" },
  { subtag: "cho", description: "Choctaw" },
  { subtag: "chp", description: "Chipewyan, Dene Suline" },
  { subtag: "chq", description: "Quiotepec Chinantec" },
  { subtag: "chr", description: "Cherokee" },
  { subtag: "cht", description: "Cholón" },
  { subtag: "chw", description: "Chuwabu" },
  { subtag: "chx", description: "Chantyal" },
  { subtag: "chy", description: "Cheyenne" },
  { subtag: "chz", description: "Ozumacín Chinantec" },
  { subtag: "cia", description: "Cia-Cia" },
  { subtag: "cib", description: "Ci Gbe" },
  { subtag: "cic", description: "Chickasaw" },
  { subtag: "cid", description: "Chimariko" },
  { subtag: "cie", description: "Cineni" },
  { subtag: "cih", description: "Chinali" },
  { subtag: "cik", description: "Chitkuli Kinnauri" },
  { subtag: "cim", description: "Cimbrian" },
  { subtag: "cin", description: "Cinta Larga" },
  { subtag: "cip", description: "Chiapanec" },
  { subtag: "cir", description: "Tiri, Haméa, Méa" },
  { subtag: "ciw", description: "Chippewa" },
  { subtag: "ciy", description: "Chaima" },
  { subtag: "cja", description: "Western Cham" },
  { subtag: "cje", description: "Chru" },
  { subtag: "cjh", description: "Upper Chehalis" },
  { subtag: "cji", description: "Chamalal" },
  { subtag: "cjk", description: "Chokwe" },
  { subtag: "cjm", description: "Eastern Cham" },
  { subtag: "cjn", description: "Chenapian" },
  { subtag: "cjo", description: "Ashéninka Pajonal" },
  { subtag: "cjp", description: "Cabécar" },
  { subtag: "cjs", description: "Shor" },
  { subtag: "cjv", description: "Chuave" },
  { subtag: "cjy", description: "Jinyu Chinese" },
  { subtag: "ckb", description: "Central Kurdish" },
  { subtag: "ckh", description: "Chak" },
  { subtag: "ckl", description: "Cibak" },
  { subtag: "ckm", description: "Chakavian" },
  { subtag: "ckn", description: "Kaang Chin" },
  { subtag: "cko", description: "Anufo" },
  { subtag: "ckq", description: "Kajakse" },
  { subtag: "ckr", description: "Kairak" },
  { subtag: "cks", description: "Tayo" },
  { subtag: "ckt", description: "Chukot" },
  { subtag: "cku", description: "Koasati" },
  { subtag: "ckv", description: "Kavalan" },
  { subtag: "ckx", description: "Caka" },
  { subtag: "cky", description: "Cakfem-Mushere" },
  { subtag: "ckz", description: "Cakchiquel-Quiché Mixed Language" },
  { subtag: "cla", description: "Ron" },
  { subtag: "clc", description: "Chilcotin" },
  { subtag: "cld", description: "Chaldean Neo-Aramaic" },
  { subtag: "cle", description: "Lealao Chinantec" },
  { subtag: "clh", description: "Chilisso" },
  { subtag: "cli", description: "Chakali" },
  { subtag: "clj", description: "Laitu Chin" },
  { subtag: "clk", description: "Idu-Mishmi" },
  { subtag: "cll", description: "Chala" },
  { subtag: "clm", description: "Clallam" },
  { subtag: "clo", description: "Lowland Oaxaca Chontal" },
  { subtag: "clt", description: "Lautu Chin" },
  { subtag: "clu", description: "Caluyanun" },
  { subtag: "clw", description: "Chulym" },
  { subtag: "cly", description: "Eastern Highland Chatino" },
  { subtag: "cma", description: "Maa" },
  { subtag: "cmc", description: "Chamic languages" },
  { subtag: "cme", description: "Cerma" },
  { subtag: "cmg", description: "Classical Mongolian" },
  { subtag: "cmi", description: "Emberá-Chamí" },
  { subtag: "cml", description: "Campalagian" },
  { subtag: "cmm", description: "Michigamea" },
  { subtag: "cmn", description: "Mandarin Chinese" },
  { subtag: "cmo", description: "Central Mnong" },
  { subtag: "cmr", description: "Mro-Khimi Chin" },
  { subtag: "cms", description: "Messapic" },
  { subtag: "cmt", description: "Camtho" },
  { subtag: "cna", description: "Changthang" },
  { subtag: "cnb", description: "Chinbon Chin" },
  { subtag: "cnc", description: "Côông" },
  { subtag: "cng", description: "Northern Qiang" },
  { subtag: "cnh", description: "Hakha Chin, Haka Chin" },
  { subtag: "cni", description: "Asháninka" },
  { subtag: "cnk", description: "Khumi Chin" },
  { subtag: "cnl", description: "Lalana Chinantec" },
  { subtag: "cno", description: "Con" },
  { subtag: "cnp", description: "Northern Ping Chinese, Northern Pinghua" },
  { subtag: "cnq", description: "Chung" },
  { subtag: "cnr", description: "Montenegrin" },
  { subtag: "cns", description: "Central Asmat" },
  { subtag: "cnt", description: "Tepetotutla Chinantec" },
  { subtag: "cnu", description: "Chenoua" },
  { subtag: "cnw", description: "Ngawn Chin" },
  { subtag: "cnx", description: "Middle Cornish" },
  { subtag: "coa", description: "Cocos Islands Malay" },
  { subtag: "cob", description: "Chicomuceltec" },
  { subtag: "coc", description: "Cocopa" },
  { subtag: "cod", description: "Cocama-Cocamilla" },
  { subtag: "coe", description: "Koreguaje" },
  { subtag: "cof", description: "Colorado" },
  { subtag: "cog", description: "Chong" },
  { subtag: "coh", description: "Chonyi-Dzihana-Kauma, Chichonyi-Chidzihana-Chikauma" },
  { subtag: "coj", description: "Cochimi" },
  { subtag: "cok", description: "Santa Teresa Cora" },
  { subtag: "col", description: "Columbia-Wenatchi" },
  { subtag: "com", description: "Comanche" },
  { subtag: "con", description: "Cofán" },
  { subtag: "coo", description: "Comox" },
  { subtag: "cop", description: "Coptic" },
  { subtag: "coq", description: "Coquille" },
  { subtag: "cot", description: "Caquinte" },
  { subtag: "cou", description: "Wamey" },
  { subtag: "cov", description: "Cao Miao" },
  { subtag: "cow", description: "Cowlitz" },
  { subtag: "cox", description: "Nanti" },
  { subtag: "coz", description: "Chochotec" },
  { subtag: "cpa", description: "Palantla Chinantec" },
  { subtag: "cpb", description: "Ucayali-Yurúa Ashéninka" },
  { subtag: "cpc", description: "Ajyíninka Apurucayali" },
  { subtag: "cpe", description: "English-based creoles and pidgins" },
  { subtag: "cpf", description: "French-based creoles and pidgins" },
  { subtag: "cpg", description: "Cappadocian Greek" },
  { subtag: "cpi", description: "Chinese Pidgin English" },
  { subtag: "cpn", description: "Cherepon" },
  { subtag: "cpo", description: "Kpeego" },
  { subtag: "cpp", description: "Portuguese-based creoles and pidgins" },
  { subtag: "cps", description: "Capiznon" },
  { subtag: "cpu", description: "Pichis Ashéninka" },
  { subtag: "cpx", description: "Pu-Xian Chinese" },
  { subtag: "cpy", description: "South Ucayali Ashéninka" },
  { subtag: "cqd", description: "Chuanqiandian Cluster Miao" },

  { subtag: "cra", description: "Chara" },
  { subtag: "crb", description: "Island Carib" },
  { subtag: "crc", description: "Lonwolwol" },
  { subtag: "crd", description: "Coeur d'Alene" },
  { subtag: "crf", description: "Caramanta" },
  { subtag: "crg", description: "Michif" },
  { subtag: "crh", description: "Crimean Tatar, Crimean Turkish" },
  { subtag: "cri", description: "Sãotomense" },
  { subtag: "crj", description: "Southern East Cree" },
  { subtag: "crk", description: "Plains Cree" },
  { subtag: "crl", description: "Northern East Cree" },
  { subtag: "crm", description: "Moose Cree" },
  { subtag: "crn", description: "El Nayar Cora" },
  { subtag: "cro", description: "Crow" },
  { subtag: "crp", description: "Creoles and pidgins" },
  { subtag: "crq", description: "Iyo'wujwa Chorote" },
  { subtag: "crr", description: "Carolina Algonquian" },
  { subtag: "crs", description: "Seselwa Creole French" },
  { subtag: "crt", description: "Iyojwa'ja Chorote" },
  { subtag: "crv", description: "Chaura" },
  { subtag: "crw", description: "Chrau" },
  { subtag: "crx", description: "Carrier" },
  { subtag: "cry", description: "Cori" },
  { subtag: "crz", description: "Cruzeño" },
  { subtag: "csa", description: "Chiltepec Chinantec" },
  { subtag: "csb", description: "Kashubian" },
  {
    subtag: "csc",
    description: "Catalan Sign Language, Lengua de señas catalana, Llengua de Signes Catalana",
  },
  { subtag: "csd", description: "Chiangmai Sign Language" },
  { subtag: "cse", description: "Czech Sign Language" },
  { subtag: "csf", description: "Cuba Sign Language" },
  { subtag: "csg", description: "Chilean Sign Language" },
  { subtag: "csh", description: "Asho Chin" },
  { subtag: "csi", description: "Coast Miwok" },
  { subtag: "csj", description: "Songlai Chin" },
  { subtag: "csk", description: "Jola-Kasa" },
  { subtag: "csl", description: "Chinese Sign Language" },
  { subtag: "csm", description: "Central Sierra Miwok" },
  { subtag: "csn", description: "Colombian Sign Language" },
  { subtag: "cso", description: "Sochiapam Chinantec, Sochiapan Chinantec" },
  { subtag: "csp", description: "Southern Ping Chinese, Southern Pinghua" },
  { subtag: "csq", description: "Croatia Sign Language" },
  { subtag: "csr", description: "Costa Rican Sign Language" },
  { subtag: "css", description: "Southern Ohlone" },
  { subtag: "cst", description: "Northern Ohlone" },
  { subtag: "csu", description: "Central Sudanic languages" },
  { subtag: "csv", description: "Sumtu Chin" },
  { subtag: "csw", description: "Swampy Cree" },
  { subtag: "csx", description: "Cambodian Sign Language" },
  { subtag: "csy", description: "Siyin Chin" },
  { subtag: "csz", description: "Coos" },
  { subtag: "cta", description: "Tataltepec Chatino" },
  { subtag: "ctc", description: "Chetco" },
  { subtag: "ctd", description: "Tedim Chin" },
  { subtag: "cte", description: "Tepinapa Chinantec" },
  { subtag: "ctg", description: "Chittagonian" },
  { subtag: "cth", description: "Thaiphum Chin" },
  { subtag: "ctl", description: "Tlacoatzintepec Chinantec" },
  { subtag: "ctm", description: "Chitimacha" },
  { subtag: "ctn", description: "Chhintange" },
  { subtag: "cto", description: "Emberá-Catío" },
  { subtag: "ctp", description: "Western Highland Chatino" },
  { subtag: "cts", description: "Northern Catanduanes Bikol" },
  { subtag: "ctt", description: "Wayanad Chetti" },
  { subtag: "ctu", description: "Chol" },
  { subtag: "cty", description: "Moundadan Chetty" },
  { subtag: "ctz", description: "Zacatepec Chatino" },
  { subtag: "cua", description: "Cua" },
  { subtag: "cub", description: "Cubeo" },
  { subtag: "cuc", description: "Usila Chinantec" },
  { subtag: "cuh", description: "Chuka, Gichuka" },
  { subtag: "cui", description: "Cuiba" },
  { subtag: "cuj", description: "Mashco Piro" },
  { subtag: "cuk", description: "San Blas Kuna" },
  { subtag: "cul", description: "Culina, Kulina" },
  { subtag: "cuo", description: "Cumanagoto" },
  { subtag: "cup", description: "Cupeño" },
  { subtag: "cuq", description: "Cun" },
  { subtag: "cur", description: "Chhulung" },
  { subtag: "cus", description: "Cushitic languages" },
  { subtag: "cut", description: "Teutila Cuicatec" },
  { subtag: "cuu", description: "Tai Ya" },
  { subtag: "cuv", description: "Cuvok" },
  { subtag: "cuw", description: "Chukwa" },
  { subtag: "cux", description: "Tepeuxila Cuicatec" },
  { subtag: "cuy", description: "Cuitlatec" },
  { subtag: "cvg", description: "Chug" },
  { subtag: "cvn", description: "Valle Nacional Chinantec" },
  { subtag: "cwa", description: "Kabwa" },
  { subtag: "cwb", description: "Maindo" },
  { subtag: "cwd", description: "Woods Cree" },
  { subtag: "cwe", description: "Kwere" },
  { subtag: "cwg", description: "Chewong, Cheq Wong" },
  { subtag: "cwt", description: "Kuwaataay" },
  { subtag: "cya", description: "Nopala Chatino" },
  { subtag: "cyb", description: "Cayubaba" },
  { subtag: "cyo", description: "Cuyonon" },
  { subtag: "czh", description: "Huizhou Chinese" },
  { subtag: "czk", description: "Knaanic" },
  { subtag: "czn", description: "Zenzontepec Chatino" },
  { subtag: "czo", description: "Min Zhong Chinese" },
  { subtag: "czt", description: "Zotung Chin" },
  { subtag: "daa", description: "Dangaléat" },
  { subtag: "dac", description: "Dambi" },
  { subtag: "dad", description: "Marik" },
  { subtag: "dae", description: "Duupa" },
  { subtag: "dag", description: "Dagbani" },
  { subtag: "dah", description: "Gwahatike" },
  { subtag: "dai", description: "Day" },
  { subtag: "daj", description: "Dar Fur Daju" },
  { subtag: "dak", description: "Dakota" },
  { subtag: "dal", description: "Dahalo" },
  { subtag: "dam", description: "Damakawa" },
  { subtag: "dao", description: "Daai Chin" },
  { subtag: "daq", description: "Dandami Maria" },
  { subtag: "dar", description: "Dargwa" },
  { subtag: "das", description: "Daho-Doo" },
  { subtag: "dau", description: "Dar Sila Daju" },
  { subtag: "dav", description: "Taita, Dawida" },
  { subtag: "daw", description: "Davawenyo" },
  { subtag: "dax", description: "Dayi" },
  { subtag: "day", description: "Land Dayak languages" },
  { subtag: "daz", description: "Dao" },
  { subtag: "dba", description: "Bangime" },
  { subtag: "dbb", description: "Deno" },
  { subtag: "dbd", description: "Dadiya" },
  { subtag: "dbe", description: "Dabe" },
  { subtag: "dbf", description: "Edopi" },
  { subtag: "dbg", description: "Dogul Dom Dogon" },
  { subtag: "dbi", description: "Doka" },
  { subtag: "dbj", description: "Ida'an" },
  { subtag: "dbl", description: "Dyirbal" },
  { subtag: "dbm", description: "Duguri" },
  { subtag: "dbn", description: "Duriankere" },
  { subtag: "dbo", description: "Dulbu" },
  { subtag: "dbp", description: "Duwai" },
  { subtag: "dbq", description: "Daba" },
  { subtag: "dbr", description: "Dabarre" },
  { subtag: "dbt", description: "Ben Tey Dogon" },
  { subtag: "dbu", description: "Bondum Dom Dogon" },
  { subtag: "dbv", description: "Dungu" },
  { subtag: "dbw", description: "Bankan Tey Dogon" },
  { subtag: "dby", description: "Dibiyaso" },
  { subtag: "dcc", description: "Deccan" },
  { subtag: "dcr", description: "Negerhollands" },
  { subtag: "dda", description: "Dadi Dadi" },
  { subtag: "ddd", description: "Dongotono" },
  { subtag: "dde", description: "Doondo" },
  { subtag: "ddg", description: "Fataluku" },
  { subtag: "ddi", description: "West Goodenough" },
  { subtag: "ddj", description: "Jaru" },
  { subtag: "ddn", description: "Dendi (Benin)" },
  { subtag: "ddo", description: "Dido" },
  { subtag: "ddr", description: "Dhudhuroa" },
  { subtag: "dds", description: "Donno So Dogon" },
  { subtag: "ddw", description: "Dawera-Daweloor" },
  { subtag: "dec", description: "Dagik" },
  { subtag: "ded", description: "Dedua" },
  { subtag: "dee", description: "Dewoin" },
  { subtag: "def", description: "Dezfuli" },
  { subtag: "deg", description: "Degema" },
  { subtag: "deh", description: "Dehwari" },
  { subtag: "dei", description: "Demisa" },
  { subtag: "dek", description: "Dek" },
  { subtag: "del", description: "Delaware" },
  { subtag: "dem", description: "Dem" },
  { subtag: "den", description: "Slave (Athapascan)" },
  { subtag: "dep", description: "Pidgin Delaware" },
  { subtag: "deq", description: "Dendi (Central African Republic)" },
  { subtag: "der", description: "Deori" },
  { subtag: "des", description: "Desano" },
  { subtag: "dev", description: "Domung" },
  { subtag: "dez", description: "Dengese" },
  { subtag: "dga", description: "Southern Dagaare" },
  { subtag: "dgb", description: "Bunoge Dogon" },
  { subtag: "dgc", description: "Casiguran Dumagat Agta" },
  { subtag: "dgd", description: "Dagaari Dioula" },
  { subtag: "dge", description: "Degenan" },
  { subtag: "dgg", description: "Doga" },
  { subtag: "dgh", description: "Dghwede" },
  { subtag: "dgi", description: "Northern Dagara" },
  { subtag: "dgk", description: "Dagba" },
  { subtag: "dgl", description: "Andaandi, Dongolawi" },
  { subtag: "dgn", description: "Dagoman" },
  { subtag: "dgo", description: "Dogri (individual language)" },
  { subtag: "dgr", description: "Dogrib, Tłı̨chǫ" },
  { subtag: "dgs", description: "Dogoso" },
  { subtag: "dgt", description: "Ndra'ngith" },
  { subtag: "dgw", description: "Daungwurrung" },
  { subtag: "dgx", description: "Doghoro" },
  { subtag: "dgz", description: "Daga" },
  { subtag: "dhd", description: "Dhundari" },
  { subtag: "dhg", description: "Dhangu-Djangu, Dhangu, Djangu" },
  { subtag: "dhi", description: "Dhimal" },
  { subtag: "dhl", description: "Dhalandji" },
  { subtag: "dhm", description: "Zemba" },
  { subtag: "dhn", description: "Dhanki" },
  { subtag: "dho", description: "Dhodia" },
  { subtag: "dhr", description: "Dhargari" },
  { subtag: "dhs", description: "Dhaiso" },
  { subtag: "dhu", description: "Dhurga" },
  { subtag: "dhv", description: "Dehu, Drehu" },
  { subtag: "dhw", description: "Dhanwar (Nepal)" },
  { subtag: "dhx", description: "Dhungaloo" },
  { subtag: "dia", description: "Dia" },
  { subtag: "dib", description: "South Central Dinka" },
  { subtag: "dic", description: "Lakota Dida" },
  { subtag: "did", description: "Didinga" },
  { subtag: "dif", description: "Dieri, Diyari" },
  { subtag: "dig", description: "Digo, Chidigo" },
  { subtag: "dih", description: "Kumiai" },
  { subtag: "dii", description: "Dimbong" },
  { subtag: "dij", description: "Dai" },
  { subtag: "dik", description: "Southwestern Dinka" },
  { subtag: "dil", description: "Dilling" },
  { subtag: "dim", description: "Dime" },
  { subtag: "din", description: "Dinka" },
  { subtag: "dio", description: "Dibo" },
  { subtag: "dip", description: "Northeastern Dinka" },
  { subtag: "diq", description: "Dimli (individual language)" },
  { subtag: "dir", description: "Dirim" },
  { subtag: "dis", description: "Dimasa" },
  { subtag: "diu", description: "Diriku" },
  { subtag: "diw", description: "Northwestern Dinka" },
  { subtag: "dix", description: "Dixon Reef" },
  { subtag: "diy", description: "Diuwe" },
  { subtag: "diz", description: "Ding" },
  { subtag: "dja", description: "Djadjawurrung" },
  { subtag: "djb", description: "Djinba" },
  { subtag: "djc", description: "Dar Daju Daju" },
  { subtag: "djd", description: "Djamindjung, Ngaliwurru" },
  { subtag: "dje", description: "Zarma" },
  { subtag: "djf", description: "Djangun" },
  { subtag: "dji", description: "Djinang" },
  { subtag: "djj", description: "Djeebbana" },
  { subtag: "djk", description: "Eastern Maroon Creole, Businenge Tongo, Nenge" },
  { subtag: "djm", description: "Jamsay Dogon" },
  { subtag: "djn", description: "Jawoyn, Djauan" },
  { subtag: "djo", description: "Jangkang" },
  { subtag: "djr", description: "Djambarrpuyngu" },
  { subtag: "dju", description: "Kapriman" },
  { subtag: "djw", description: "Djawi" },
  { subtag: "dka", description: "Dakpakha" },
  { subtag: "dkg", description: "Kadung" },
  { subtag: "dkk", description: "Dakka" },
  { subtag: "dkr", description: "Kuijau" },
  { subtag: "dks", description: "Southeastern Dinka" },
  { subtag: "dkx", description: "Mazagway" },
  { subtag: "dlg", description: "Dolgan" },
  { subtag: "dlk", description: "Dahalik" },
  { subtag: "dlm", description: "Dalmatian" },
  { subtag: "dln", description: "Darlong" },
  { subtag: "dma", description: "Duma" },
  { subtag: "dmb", description: "Mombo Dogon" },
  { subtag: "dmc", description: "Gavak" },
  { subtag: "dmd", description: "Madhi Madhi" },
  { subtag: "dme", description: "Dugwor" },
  { subtag: "dmf", description: "Medefaidrin" },
  { subtag: "dmg", description: "Upper Kinabatangan" },
  { subtag: "dmk", description: "Domaaki" },
  { subtag: "dml", description: "Dameli" },
  { subtag: "dmm", description: "Dama" },
  { subtag: "dmn", description: "Mande languages" },
  { subtag: "dmo", description: "Kemedzung" },
  { subtag: "dmr", description: "East Damar" },
  { subtag: "dms", description: "Dampelas" },
  { subtag: "dmu", description: "Dubu, Tebi" },
  { subtag: "dmv", description: "Dumpas" },
  { subtag: "dmw", description: "Mudburra" },
  { subtag: "dmx", description: "Dema" },
  { subtag: "dmy", description: "Demta, Sowari" },
  { subtag: "dna", description: "Upper Grand Valley Dani" },
  { subtag: "dnd", description: "Daonda" },
  { subtag: "dne", description: "Ndendeule" },
  { subtag: "dng", description: "Dungan" },
  { subtag: "dni", description: "Lower Grand Valley Dani" },
  { subtag: "dnj", description: "Dan" },
  { subtag: "dnk", description: "Dengka" },
  { subtag: "dnn", description: "Dzùùngoo" },
  { subtag: "dno", description: "Ndrulo, Northern Lendu" },
  { subtag: "dnr", description: "Danaru" },
  { subtag: "dnt", description: "Mid Grand Valley Dani" },
  { subtag: "dnu", description: "Danau" },
  { subtag: "dnv", description: "Danu" },
  { subtag: "dnw", description: "Western Dani" },
  { subtag: "dny", description: "Dení" },
  { subtag: "doa", description: "Dom" },
  { subtag: "dob", description: "Dobu" },
  { subtag: "doc", description: "Northern Dong" },
  { subtag: "doe", description: "Doe" },
  { subtag: "dof", description: "Domu" },
  { subtag: "doh", description: "Dong" },
  { subtag: "dok", description: "Dondo" },
  { subtag: "dol", description: "Doso" },
  { subtag: "don", description: "Toura (Papua New Guinea)" },
  { subtag: "doo", description: "Dongo" },
  { subtag: "dop", description: "Lukpa" },
  { subtag: "doq", description: "Dominican Sign Language" },
  { subtag: "dor", description: "Dori'o" },
  { subtag: "dos", description: "Dogosé" },
  { subtag: "dot", description: "Dass" },
  { subtag: "dov", description: "Dombe" },
  { subtag: "dow", description: "Doyayo" },
  { subtag: "dox", description: "Bussa" },
  { subtag: "doy", description: "Dompo" },
  { subtag: "doz", description: "Dorze" },
  { subtag: "dpp", description: "Papar" },
  { subtag: "dra", description: "Dravidian languages" },
  { subtag: "drb", description: "Dair" },
  { subtag: "drc", description: "Minderico" },
  { subtag: "drd", description: "Darmiya" },
  { subtag: "dre", description: "Dolpo" },
  { subtag: "drg", description: "Rungus" },
  { subtag: "dri", description: "C'Lela" },
  { subtag: "drl", description: "Paakantyi" },
  { subtag: "drn", description: "West Damar" },
  { subtag: "dro", description: "Daro-Matu Melanau" },
  { subtag: "drq", description: "Dura" },
  { subtag: "drs", description: "Gedeo" },
  { subtag: "drt", description: "Drents" },
  { subtag: "dru", description: "Rukai" },
  { subtag: "dry", description: "Darai" },
  { subtag: "dsb", description: "Lower Sorbian" },
  { subtag: "dse", description: "Dutch Sign Language" },
  { subtag: "dsh", description: "Daasanach" },
  { subtag: "dsi", description: "Disa" },
  { subtag: "dsl", description: "Danish Sign Language" },
  { subtag: "dsn", description: "Dusner" },
  { subtag: "dso", description: "Desiya" },
  { subtag: "dsq", description: "Tadaksahak" },
  { subtag: "dsz", description: "Mardin Sign Language" },
  { subtag: "dta", description: "Daur" },
  { subtag: "dtb", description: "Labuk-Kinabatangan Kadazan" },
  { subtag: "dtd", description: "Ditidaht" },
  { subtag: "dth", description: "Adithinngithigh" },
  { subtag: "dti", description: "Ana Tinga Dogon" },
  { subtag: "dtk", description: "Tene Kan Dogon" },
  { subtag: "dtm", description: "Tomo Kan Dogon" },
  { subtag: "dtn", description: "Daatsʼíin" },
  { subtag: "dto", description: "Tommo So Dogon" },
  { subtag: "dtp", description: "Kadazan Dusun, Central Dusun" },
  { subtag: "dtr", description: "Lotud" },
  { subtag: "dts", description: "Toro So Dogon" },
  { subtag: "dtt", description: "Toro Tegu Dogon" },
  { subtag: "dtu", description: "Tebul Ure Dogon" },
  { subtag: "dty", description: "Dotyali" },
  { subtag: "dua", description: "Duala" },
  { subtag: "dub", description: "Dubli" },
  { subtag: "duc", description: "Duna" },
  { subtag: "due", description: "Umiray Dumaget Agta" },
  { subtag: "duf", description: "Dumbea, Drubea" },
  { subtag: "dug", description: "Duruma, Chiduruma" },
  { subtag: "duh", description: "Dungra Bhil" },
  { subtag: "dui", description: "Dumun" },
  { subtag: "duk", description: "Uyajitaya" },
  { subtag: "dul", description: "Alabat Island Agta" },
  { subtag: "dum", description: "Middle Dutch (ca. 1050-1350)" },
  { subtag: "dun", description: "Dusun Deyah" },
  { subtag: "duo", description: "Dupaninan Agta" },
  { subtag: "dup", description: "Duano" },
  { subtag: "duq", description: "Dusun Malang" },
  { subtag: "dur", description: "Dii" },
  { subtag: "dus", description: "Dumi" },
  { subtag: "duu", description: "Drung" },
  { subtag: "duv", description: "Duvle" },
  { subtag: "duw", description: "Dusun Witu" },
  { subtag: "dux", description: "Duungooma" },
  { subtag: "duy", description: "Dicamay Agta" },
  { subtag: "duz", description: "Duli-Gey" },
  { subtag: "dva", description: "Duau" },
  { subtag: "dwa", description: "Diri" },
  { subtag: "dwk", description: "Dawik Kui" },
  { subtag: "dwr", description: "Dawro" },
  { subtag: "dws", description: "Dutton World Speedwords" },
  { subtag: "dwu", description: "Dhuwal" },
  { subtag: "dww", description: "Dawawa" },
  { subtag: "dwy", description: "Dhuwaya" },
  { subtag: "dwz", description: "Dewas Rai" },
  { subtag: "dya", description: "Dyan" },
  { subtag: "dyb", description: "Dyaberdyaber" },
  { subtag: "dyd", description: "Dyugun" },
  { subtag: "dyg", description: "Villa Viciosa Agta" },
  { subtag: "dyi", description: "Djimini Senoufo" },
  { subtag: "dym", description: "Yanda Dom Dogon" },
  { subtag: "dyn", description: "Dyangadi, Dhanggatti" },
  { subtag: "dyo", description: "Jola-Fonyi" },
  { subtag: "dyu", description: "Dyula" },
  { subtag: "dyy", description: "Djabugay, Dyaabugay" },
  { subtag: "dza", description: "Tunzu" },
  { subtag: "dze", description: "Djiwarli" },
  { subtag: "dzg", description: "Dazaga" },
  { subtag: "dzl", description: "Dzalakha" },
  { subtag: "dzn", description: "Dzando" },
  { subtag: "eaa", description: "Karenggapa" },
  { subtag: "ebc", description: "Beginci" },
  { subtag: "ebg", description: "Ebughu" },
  { subtag: "ebk", description: "Eastern Bontok" },
  { subtag: "ebo", description: "Teke-Ebo" },
  { subtag: "ebr", description: "Ebrié" },
  { subtag: "ebu", description: "Embu, Kiembu" },
  { subtag: "ecr", description: "Eteocretan" },
  { subtag: "ecs", description: "Ecuadorian Sign Language" },
  { subtag: "ecy", description: "Eteocypriot" },
  { subtag: "eee", description: "E" },
  { subtag: "efa", description: "Efai" },
  { subtag: "efe", description: "Efe" },
  { subtag: "efi", description: "Efik" },
  { subtag: "ega", description: "Ega" },
  { subtag: "egl", description: "Emilian" },
  { subtag: "egm", description: "Benamanga" },
  { subtag: "ego", description: "Eggon" },
  { subtag: "egx", description: "Egyptian languages" },
  { subtag: "egy", description: "Egyptian (Ancient)" },
  { subtag: "ehs", description: "Miyakubo Sign Language" },
  { subtag: "ehu", description: "Ehueun" },
  { subtag: "eip", description: "Eipomek" },
  { subtag: "eit", description: "Eitiep" },
  { subtag: "eiv", description: "Askopan" },
  { subtag: "eja", description: "Ejamat" },
  { subtag: "eka", description: "Ekajuk" },
  { subtag: "eke", description: "Ekit" },
  { subtag: "ekg", description: "Ekari" },
  { subtag: "eki", description: "Eki" },
  { subtag: "ekk", description: "Standard Estonian" },
  { subtag: "ekl", description: "Kol (Bangladesh), Kol" },
  { subtag: "ekm", description: "Elip" },
  { subtag: "eko", description: "Koti" },
  { subtag: "ekp", description: "Ekpeye" },
  { subtag: "ekr", description: "Yace" },
  { subtag: "eky", description: "Eastern Kayah" },
  { subtag: "ele", description: "Elepi" },
  { subtag: "elh", description: "El Hugeirat" },
  { subtag: "eli", description: "Nding" },
  { subtag: "elk", description: "Elkei" },
  { subtag: "elm", description: "Eleme" },
  { subtag: "elo", description: "El Molo" },
  { subtag: "elu", description: "Elu" },
  { subtag: "elx", description: "Elamite" },
  { subtag: "ema", description: "Emai-Iuleha-Ora" },
  { subtag: "emb", description: "Embaloh" },
  { subtag: "eme", description: "Emerillon" },
  { subtag: "emg", description: "Eastern Meohang" },
  { subtag: "emi", description: "Mussau-Emira" },
  { subtag: "emk", description: "Eastern Maninkakan" },
  { subtag: "emm", description: "Mamulique" },
  { subtag: "emn", description: "Eman" },
  { subtag: "emp", description: "Northern Emberá" },
  { subtag: "emq", description: "Eastern Minyag" },
  { subtag: "ems", description: "Pacific Gulf Yupik" },
  { subtag: "emu", description: "Eastern Muria" },
  { subtag: "emw", description: "Emplawas" },
  { subtag: "emx", description: "Erromintxela" },
  { subtag: "emy", description: "Epigraphic Mayan" },
  { subtag: "emz", description: "Mbessa" },
  { subtag: "ena", description: "Apali" },
  { subtag: "enb", description: "Markweeta" },
  { subtag: "enc", description: "En" },
  { subtag: "end", description: "Ende" },
  { subtag: "enf", description: "Forest Enets" },
  { subtag: "enh", description: "Tundra Enets" },
  { subtag: "enl", description: "Enlhet" },
  { subtag: "enm", description: "Middle English (1100-1500)" },
  { subtag: "enn", description: "Engenni" },
  { subtag: "eno", description: "Enggano" },
  { subtag: "enq", description: "Enga" },
  { subtag: "enr", description: "Emumu, Emem" },
  { subtag: "enu", description: "Enu" },
  { subtag: "env", description: "Enwan (Edo State)" },
  { subtag: "enw", description: "Enwan (Akwa Ibom State)" },
  { subtag: "enx", description: "Enxet" },
  { subtag: "eot", description: "Beti (Côte d'Ivoire)" },
  { subtag: "epi", description: "Epie" },
  { subtag: "era", description: "Eravallan" },
  { subtag: "erg", description: "Sie" },
  { subtag: "erh", description: "Eruwa" },
  { subtag: "eri", description: "Ogea" },
  { subtag: "erk", description: "South Efate" },
  { subtag: "ero", description: "Horpa" },
  { subtag: "err", description: "Erre" },
  { subtag: "ers", description: "Ersu" },
  { subtag: "ert", description: "Eritai" },
  { subtag: "erw", description: "Erokwanas" },
  { subtag: "ese", description: "Ese Ejja" },
  { subtag: "esg", description: "Aheri Gondi" },
  { subtag: "esh", description: "Eshtehardi" },
  { subtag: "esi", description: "North Alaskan Inupiatun" },
  { subtag: "esk", description: "Northwest Alaska Inupiatun" },
  { subtag: "esl", description: "Egypt Sign Language" },
  { subtag: "esm", description: "Esuma" },
  { subtag: "esn", description: "Salvadoran Sign Language" },
  { subtag: "eso", description: "Estonian Sign Language" },
  { subtag: "esq", description: "Esselen" },
  { subtag: "ess", description: "Central Siberian Yupik" },
  { subtag: "esu", description: "Central Yupik" },
  { subtag: "esx", description: "Eskimo-Aleut languages" },
  { subtag: "esy", description: "Eskayan" },
  { subtag: "etb", description: "Etebi" },
  { subtag: "etc", description: "Etchemin" },
  { subtag: "eth", description: "Ethiopian Sign Language" },
  { subtag: "etn", description: "Eton (Vanuatu)" },
  { subtag: "eto", description: "Eton (Cameroon)" },
  { subtag: "etr", description: "Edolo" },
  { subtag: "ets", description: "Yekhee" },
  { subtag: "ett", description: "Etruscan" },
  { subtag: "etu", description: "Ejagham" },
  { subtag: "etx", description: "Eten" },
  { subtag: "etz", description: "Semimi" },
  { subtag: "euq", description: "Basque (family)" },
  { subtag: "eve", description: "Even" },
  { subtag: "evh", description: "Uvbie" },
  { subtag: "evn", description: "Evenki" },
  { subtag: "ewo", description: "Ewondo" },
  { subtag: "ext", description: "Extremaduran" },
  { subtag: "eya", description: "Eyak" },
  { subtag: "eyo", description: "Keiyo" },
  { subtag: "eza", description: "Ezaa" },
  { subtag: "eze", description: "Uzekwe" },
  { subtag: "faa", description: "Fasu" },
  { subtag: "fab", description: "Fa d'Ambu" },
  { subtag: "fad", description: "Wagi" },
  { subtag: "faf", description: "Fagani" },
  { subtag: "fag", description: "Finongan" },
  { subtag: "fah", description: "Baissa Fali" },
  { subtag: "fai", description: "Faiwol" },
  { subtag: "faj", description: "Faita" },
  { subtag: "fak", description: "Fang (Cameroon)" },
  { subtag: "fal", description: "South Fali" },
  { subtag: "fam", description: "Fam" },
  { subtag: "fan", description: "Fang (Equatorial Guinea)" },
  { subtag: "fap", description: "Paloor" },
  { subtag: "far", description: "Fataleka" },
  { subtag: "fat", description: "Fanti" },
  { subtag: "fau", description: "Fayu" },
  { subtag: "fax", description: "Fala" },
  { subtag: "fay", description: "Southwestern Fars" },
  { subtag: "faz", description: "Northwestern Fars" },
  { subtag: "fbl", description: "West Albay Bikol" },
  { subtag: "fcs", description: "Quebec Sign Language" },
  { subtag: "fer", description: "Feroge" },
  { subtag: "ffi", description: "Foia Foia" },
  { subtag: "ffm", description: "Maasina Fulfulde" },
  { subtag: "fgr", description: "Fongoro" },
  { subtag: "fia", description: "Nobiin" },
  { subtag: "fie", description: "Fyer" },
  { subtag: "fif", description: "Faifi" },
  { subtag: "fil", description: "Filipino, Pilipino" },
  { subtag: "fip", description: "Fipa" },
  { subtag: "fir", description: "Firan" },
  { subtag: "fit", description: "Tornedalen Finnish, Meänkieli" },
  { subtag: "fiu", description: "Finno-Ugrian languages" },
  { subtag: "fiw", description: "Fiwaga" },
  { subtag: "fkk", description: "Kirya-Konzəl" },
  { subtag: "fkv", description: "Kven Finnish" },
  { subtag: "fla", description: "Kalispel-Pend d'Oreille" },
  { subtag: "flh", description: "Foau" },
  { subtag: "fli", description: "Fali" },
  { subtag: "fll", description: "North Fali" },
  { subtag: "fln", description: "Flinders Island" },
  { subtag: "flr", description: "Fuliiru" },
  { subtag: "fly", description: "Flaaitaal, Tsotsitaal" },
  { subtag: "fmp", description: "Fe'fe'" },
  { subtag: "fmu", description: "Far Western Muria" },
  { subtag: "fnb", description: "Fanbak" },
  { subtag: "fng", description: "Fanagalo" },
  { subtag: "fni", description: "Fania" },
  { subtag: "fod", description: "Foodo" },
  { subtag: "foi", description: "Foi" },
  { subtag: "fom", description: "Foma" },
  { subtag: "fon", description: "Fon" },
  { subtag: "for", description: "Fore" },
  { subtag: "fos", description: "Siraya" },
  { subtag: "fox", description: "Formosan languages" },
  { subtag: "fpe", description: "Fernando Po Creole English" },
  { subtag: "fqs", description: "Fas" },
  { subtag: "frc", description: "Cajun French" },
  { subtag: "frd", description: "Fordata" },
  { subtag: "frk", description: "Frankish" },
  { subtag: "frm", description: "Middle French (ca. 1400-1600)" },
  { subtag: "fro", description: "Old French (842-ca. 1400)" },
  { subtag: "frp", description: "Arpitan, Francoprovençal" },
  { subtag: "frq", description: "Forak" },
  { subtag: "frr", description: "Northern Frisian" },
  { subtag: "frs", description: "Eastern Frisian" },
  { subtag: "frt", description: "Fortsenal" },
  { subtag: "fse", description: "Finnish Sign Language" },
  { subtag: "fsl", description: "French Sign Language" },
  {
    subtag: "fss",
    description: "Finland-Swedish Sign Language, finlandssvenskt teckenspråk, suomenruotsalainen viittomakieli",
  },
  { subtag: "fub", description: "Adamawa Fulfulde" },
  { subtag: "fuc", description: "Pulaar" },
  { subtag: "fud", description: "East Futuna" },
  { subtag: "fue", description: "Borgu Fulfulde" },
  { subtag: "fuf", description: "Pular" },
  { subtag: "fuh", description: "Western Niger Fulfulde" },
  { subtag: "fui", description: "Bagirmi Fulfulde" },
  { subtag: "fuj", description: "Ko" },
  { subtag: "fum", description: "Fum" },
  { subtag: "fun", description: "Fulniô" },
  { subtag: "fuq", description: "Central-Eastern Niger Fulfulde" },
  { subtag: "fur", description: "Friulian" },
  { subtag: "fut", description: "Futuna-Aniwa" },
  { subtag: "fuu", description: "Furu" },
  { subtag: "fuv", description: "Nigerian Fulfulde" },
  { subtag: "fuy", description: "Fuyug" },
  { subtag: "fvr", description: "Fur" },
  { subtag: "fwa", description: "Fwâi" },
  { subtag: "fwe", description: "Fwe" },
  { subtag: "gaa", description: "Ga" },
  { subtag: "gab", description: "Gabri" },
  { subtag: "gac", description: "Mixed Great Andamanese" },
  { subtag: "gad", description: "Gaddang" },
  { subtag: "gae", description: "Guarequena" },
  { subtag: "gaf", description: "Gende" },
  { subtag: "gag", description: "Gagauz" },
  { subtag: "gah", description: "Alekano" },
  { subtag: "gai", description: "Borei" },
  { subtag: "gaj", description: "Gadsup" },
  { subtag: "gak", description: "Gamkonora" },
  { subtag: "gal", description: "Galolen" },
  { subtag: "gam", description: "Kandawo" },
  { subtag: "gan", description: "Gan Chinese" },
  { subtag: "gao", description: "Gants" },
  { subtag: "gap", description: "Gal" },
  { subtag: "gaq", description: "Gata'" },
  { subtag: "gar", description: "Galeya" },
  { subtag: "gas", description: "Adiwasi Garasia" },
  { subtag: "gat", description: "Kenati" },
  { subtag: "gau", description: "Mudhili Gadaba" },
  { subtag: "gaw", description: "Nobonob" },
  { subtag: "gax", description: "Borana-Arsi-Guji Oromo" },
  { subtag: "gay", description: "Gayo" },
  { subtag: "gaz", description: "West Central Oromo" },
  { subtag: "gba", description: "Gbaya (Central African Republic)" },
  { subtag: "gbb", description: "Kaytetye" },
  { subtag: "gbd", description: "Karajarri" },
  { subtag: "gbe", description: "Niksek" },
  { subtag: "gbf", description: "Gaikundi" },
  { subtag: "gbg", description: "Gbanziri" },
  { subtag: "gbh", description: "Defi Gbe" },
  { subtag: "gbi", description: "Galela" },
  { subtag: "gbj", description: "Bodo Gadaba" },
  { subtag: "gbk", description: "Gaddi" },
  { subtag: "gbl", description: "Gamit" },
  { subtag: "gbm", description: "Garhwali" },
  { subtag: "gbn", description: "Mo'da" },
  { subtag: "gbo", description: "Northern Grebo" },
  { subtag: "gbp", description: "Gbaya-Bossangoa" },
  { subtag: "gbq", description: "Gbaya-Bozoum" },
  { subtag: "gbr", description: "Gbagyi" },
  { subtag: "gbs", description: "Gbesi Gbe" },
  { subtag: "gbu", description: "Gagadu" },
  { subtag: "gbv", description: "Gbanu" },
  { subtag: "gbw", description: "Gabi-Gabi" },
  { subtag: "gbx", description: "Eastern Xwla Gbe" },
  { subtag: "gby", description: "Gbari" },
  { subtag: "gbz", description: "Zoroastrian Dari" },
  { subtag: "gcc", description: "Mali" },
  { subtag: "gcd", description: "Ganggalida" },
  { subtag: "gce", description: "Galice" },
  { subtag: "gcf", description: "Guadeloupean Creole French" },
  { subtag: "gcl", description: "Grenadian Creole English" },
  { subtag: "gcn", description: "Gaina" },
  { subtag: "gcr", description: "Guianese Creole French" },
  { subtag: "gct", description: "Colonia Tovar German" },
  { subtag: "gda", description: "Gade Lohar" },
  { subtag: "gdb", description: "Pottangi Ollar Gadaba" },
  { subtag: "gdc", description: "Gugu Badhun" },
  { subtag: "gdd", description: "Gedaged" },
  { subtag: "gde", description: "Gude" },
  { subtag: "gdf", description: "Guduf-Gava" },
  { subtag: "gdg", description: "Ga'dang" },
  { subtag: "gdh", description: "Gadjerawang, Gajirrabeng" },
  { subtag: "gdi", description: "Gundi" },
  { subtag: "gdj", description: "Gurdjar" },
  { subtag: "gdk", description: "Gadang" },
  { subtag: "gdl", description: "Dirasha" },
  { subtag: "gdm", description: "Laal" },
  { subtag: "gdn", description: "Umanakaina" },
  { subtag: "gdo", description: "Ghodoberi" },
  { subtag: "gdq", description: "Mehri" },
  { subtag: "gdr", description: "Wipi" },
  { subtag: "gds", description: "Ghandruk Sign Language" },
  { subtag: "gdt", description: "Kungardutyi" },
  { subtag: "gdu", description: "Gudu" },
  { subtag: "gdx", description: "Godwari" },
  { subtag: "gea", description: "Geruma" },
  { subtag: "geb", description: "Kire" },
  { subtag: "gec", description: "Gboloo Grebo" },
  { subtag: "ged", description: "Gade" },
  { subtag: "gef", description: "Gerai" },
  { subtag: "geg", description: "Gengle" },
  { subtag: "geh", description: "Hutterite German, Hutterisch" },
  { subtag: "gei", description: "Gebe" },
  { subtag: "gej", description: "Gen" },
  { subtag: "gek", description: "Ywom" },
  { subtag: "gel", description: "ut-Ma'in" },
  { subtag: "gem", description: "Germanic languages" },
  { subtag: "geq", description: "Geme" },
  { subtag: "ges", description: "Geser-Gorom" },
  { subtag: "gev", description: "Eviya" },
  { subtag: "gew", description: "Gera" },
  { subtag: "gex", description: "Garre" },
  { subtag: "gey", description: "Enya" },
  { subtag: "gez", description: "Geez" },
  { subtag: "gfk", description: "Patpatar" },
  { subtag: "gft", description: "Gafat" },
  { subtag: "gga", description: "Gao" },
  { subtag: "ggb", description: "Gbii" },
  { subtag: "ggd", description: "Gugadj" },
  { subtag: "gge", description: "Gurr-goni" },
  { subtag: "ggg", description: "Gurgula" },
  { subtag: "ggk", description: "Kungarakany" },
  { subtag: "ggl", description: "Ganglau" },
  { subtag: "ggt", description: "Gitua" },
  { subtag: "ggu", description: "Gagu, Gban" },
  { subtag: "ggw", description: "Gogodala" },
  { subtag: "gha", description: "Ghadamès" },
  { subtag: "ghc", description: "Hiberno-Scottish Gaelic" },
  { subtag: "ghe", description: "Southern Ghale" },
  { subtag: "ghh", description: "Northern Ghale" },
  { subtag: "ghk", description: "Geko Karen" },
  { subtag: "ghl", description: "Ghulfan" },
  { subtag: "ghn", description: "Ghanongga" },
  { subtag: "gho", description: "Ghomara" },
  { subtag: "ghr", description: "Ghera" },
  { subtag: "ghs", description: "Guhu-Samane" },
  { subtag: "ght", description: "Kuke, Kutang Ghale" },
  { subtag: "gia", description: "Kija" },
  { subtag: "gib", description: "Gibanawa" },
  { subtag: "gic", description: "Gail" },
  { subtag: "gid", description: "Gidar" },
  { subtag: "gie", description: "Gaɓogbo, Guébie" },
  { subtag: "gig", description: "Goaria" },
  { subtag: "gih", description: "Githabul" },
  { subtag: "gii", description: "Girirra" },
  { subtag: "gil", description: "Gilbertese" },
  { subtag: "gim", description: "Gimi (Eastern Highlands)" },
  { subtag: "gin", description: "Hinukh" },
  { subtag: "gip", description: "Gimi (West New Britain)" },
  { subtag: "giq", description: "Green Gelao" },
  { subtag: "gir", description: "Red Gelao" },
  { subtag: "gis", description: "North Giziga" },
  { subtag: "git", description: "Gitxsan" },
  { subtag: "giu", description: "Mulao" },
  { subtag: "giw", description: "White Gelao" },
  { subtag: "gix", description: "Gilima" },
  { subtag: "giy", description: "Giyug" },
  { subtag: "giz", description: "South Giziga" },
  { subtag: "gjk", description: "Kachi Koli" },
  { subtag: "gjm", description: "Gunditjmara" },
  { subtag: "gjn", description: "Gonja" },
  { subtag: "gjr", description: "Gurindji Kriol" },
  { subtag: "gju", description: "Gujari" },
  { subtag: "gka", description: "Guya" },
  { subtag: "gkd", description: "Magɨ (Madang Province)" },
  { subtag: "gke", description: "Ndai" },
  { subtag: "gkn", description: "Gokana" },
  { subtag: "gko", description: "Kok-Nar" },
  { subtag: "gkp", description: "Guinea Kpelle" },
  { subtag: "gku", description: "ǂUngkue" },
  { subtag: "glb", description: "Belning" },
  { subtag: "glc", description: "Bon Gula" },
  { subtag: "gld", description: "Nanai" },
  { subtag: "glh", description: "Northwest Pashai, Northwest Pashayi" },
  { subtag: "glj", description: "Gula Iro" },
  { subtag: "glk", description: "Gilaki" },
  { subtag: "gll", description: "Garlali" },
  { subtag: "glo", description: "Galambu" },
  { subtag: "glr", description: "Glaro-Twabo" },
  { subtag: "glu", description: "Gula (Chad)" },
  { subtag: "glw", description: "Glavda" },
  { subtag: "gly", description: "Gule" },
  { subtag: "gma", description: "Gambera" },
  { subtag: "gmb", description: "Gula'alaa" },
  { subtag: "gmd", description: "Mághdì" },
  { subtag: "gme", description: "East Germanic languages" },
  { subtag: "gmg", description: "Magɨyi" },
  { subtag: "gmh", description: "Middle High German (ca. 1050-1500)" },
  { subtag: "gml", description: "Middle Low German" },
  { subtag: "gmm", description: "Gbaya-Mbodomo" },
  { subtag: "gmn", description: "Gimnime" },
  { subtag: "gmq", description: "North Germanic languages" },
  { subtag: "gmr", description: "Mirning, Mirniny" },
  { subtag: "gmu", description: "Gumalu" },
  { subtag: "gmv", description: "Gamo" },
  { subtag: "gmw", description: "West Germanic languages" },
  { subtag: "gmx", description: "Magoma" },
  { subtag: "gmy", description: "Mycenaean Greek" },
  { subtag: "gmz", description: "Mgbolizhia" },
  { subtag: "gna", description: "Kaansa" },
  { subtag: "gnb", description: "Gangte" },
  { subtag: "gnc", description: "Guanche" },
  { subtag: "gnd", description: "Zulgo-Gemzek" },
  { subtag: "gne", description: "Ganang" },
  { subtag: "gng", description: "Ngangam" },
  { subtag: "gnh", description: "Lere" },
  { subtag: "gni", description: "Gooniyandi" },
  { subtag: "gnj", description: "Ngen" },
  { subtag: "gnk", description: "ǁGana" },
  { subtag: "gnl", description: "Gangulu" },
  { subtag: "gnm", description: "Ginuman" },
  { subtag: "gnn", description: "Gumatj" },
  { subtag: "gno", description: "Northern Gondi" },
  { subtag: "gnq", description: "Gana" },
  { subtag: "gnr", description: "Gureng Gureng" },
  { subtag: "gnt", description: "Guntai" },
  { subtag: "gnu", description: "Gnau" },
  { subtag: "gnw", description: "Western Bolivian Guaraní" },
  { subtag: "gnz", description: "Ganzi" },
  { subtag: "goa", description: "Guro" },
  { subtag: "gob", description: "Playero" },
  { subtag: "goc", description: "Gorakor" },
  { subtag: "god", description: "Godié" },
  { subtag: "goe", description: "Gongduk" },
  { subtag: "gof", description: "Gofa" },
  { subtag: "gog", description: "Gogo" },
  { subtag: "goh", description: "Old High German (ca. 750-1050)" },
  { subtag: "goi", description: "Gobasi" },
  { subtag: "goj", description: "Gowlan" },
  { subtag: "gok", description: "Gowli" },
  { subtag: "gol", description: "Gola" },
  { subtag: "gom", description: "Goan Konkani" },
  { subtag: "gon", description: "Gondi" },
  { subtag: "goo", description: "Gone Dau" },
  { subtag: "gop", description: "Yeretuar" },
  { subtag: "goq", description: "Gorap" },
  { subtag: "gor", description: "Gorontalo" },
  { subtag: "gos", description: "Gronings" },
  { subtag: "got", description: "Gothic" },
  { subtag: "gou", description: "Gavar" },
  { subtag: "gov", description: "Goo" },
  { subtag: "gow", description: "Gorowa" },
  { subtag: "gox", description: "Gobu" },
  { subtag: "goy", description: "Goundo" },
  { subtag: "goz", description: "Gozarkhani" },
  { subtag: "gpa", description: "Gupa-Abawa" },
  { subtag: "gpe", description: "Ghanaian Pidgin English" },
  { subtag: "gpn", description: "Taiap" },
  { subtag: "gqa", description: "Ga'anda" },
  { subtag: "gqi", description: "Guiqiong" },
  { subtag: "gqn", description: "Guana (Brazil)" },
  { subtag: "gqr", description: "Gor" },
  { subtag: "gqu", description: "Qau" },
  { subtag: "gra", description: "Rajput Garasia" },
  { subtag: "grb", description: "Grebo" },
  { subtag: "grc", description: "Ancient Greek (to 1453)" },
  { subtag: "grd", description: "Guruntum-Mbaaru" },
  { subtag: "grg", description: "Madi" },
  { subtag: "grh", description: "Gbiri-Niragu" },
  { subtag: "gri", description: "Ghari" },
  { subtag: "grj", description: "Southern Grebo" },
  { subtag: "grk", description: "Greek languages" },
  { subtag: "grm", description: "Kota Marudu Talantang" },
  { subtag: "gro", description: "Groma" },
  { subtag: "grq", description: "Gorovu" },
  { subtag: "grr", description: "Taznatit" },
  { subtag: "grs", description: "Gresi" },
  { subtag: "grt", description: "Garo" },
  { subtag: "gru", description: "Kistane" },
  { subtag: "grv", description: "Central Grebo" },
  { subtag: "grw", description: "Gweda" },
  { subtag: "grx", description: "Guriaso" },
  { subtag: "gry", description: "Barclayville Grebo" },
  { subtag: "grz", description: "Guramalum" },
  { subtag: "gse", description: "Ghanaian Sign Language" },
  { subtag: "gsg", description: "German Sign Language" },
  { subtag: "gsl", description: "Gusilay" },
  { subtag: "gsm", description: "Guatemalan Sign Language" },
  { subtag: "gsn", description: "Nema, Gusan" },
  { subtag: "gso", description: "Southwest Gbaya" },
  { subtag: "gsp", description: "Wasembo" },
  { subtag: "gss", description: "Greek Sign Language" },
  { subtag: "gsw", description: "Swiss German, Alemannic, Alsatian" },
  { subtag: "gta", description: "Guató" },
  { subtag: "gtu", description: "Aghu-Tharnggala" },
  { subtag: "gua", description: "Shiki" },
  { subtag: "gub", description: "Guajajára" },
  { subtag: "guc", description: "Wayuu" },
  { subtag: "gud", description: "Yocoboué Dida" },
  { subtag: "gue", description: "Gurindji" },
  { subtag: "guf", description: "Gupapuyngu" },
  { subtag: "gug", description: "Paraguayan Guaraní" },
  { subtag: "guh", description: "Guahibo" },
  { subtag: "gui", description: "Eastern Bolivian Guaraní" },
  { subtag: "guk", description: "Gumuz" },
  { subtag: "gul", description: "Sea Island Creole English" },
  { subtag: "gum", description: "Guambiano" },
  { subtag: "gun", description: "Mbyá Guaraní" },
  { subtag: "guo", description: "Guayabero" },
  { subtag: "gup", description: "Gunwinggu" },
  { subtag: "guq", description: "Aché" },
  { subtag: "gur", description: "Farefare" },
  { subtag: "gus", description: "Guinean Sign Language" },
  { subtag: "gut", description: "Maléku Jaíka" },
  { subtag: "guu", description: "Yanomamö" },
  { subtag: "guw", description: "Gun" },
  { subtag: "gux", description: "Gourmanchéma" },
  { subtag: "guz", description: "Gusii, Ekegusii" },
  { subtag: "gva", description: "Guana (Paraguay)" },
  { subtag: "gvc", description: "Guanano" },
  { subtag: "gve", description: "Duwet" },
  { subtag: "gvf", description: "Golin" },
  { subtag: "gvj", description: "Guajá" },
  { subtag: "gvl", description: "Gulay" },
  { subtag: "gvm", description: "Gurmana" },
  { subtag: "gvn", description: "Kuku-Yalanji" },
  { subtag: "gvo", description: "Gavião Do Jiparaná" },
  { subtag: "gvp", description: "Pará Gavião" },
  { subtag: "gvr", description: "Gurung" },
  { subtag: "gvs", description: "Gumawana" },
  { subtag: "gvy", description: "Guyani" },
  { subtag: "gwa", description: "Mbato" },
  { subtag: "gwb", description: "Gwa" },
  { subtag: "gwc", description: "Gawri, Kalami" },
  { subtag: "gwd", description: "Gawwada" },
  { subtag: "gwe", description: "Gweno" },
  { subtag: "gwf", description: "Gowro" },
  { subtag: "gwg", description: "Moo" },
  { subtag: "gwi", description: "Gwichʼin" },
  { subtag: "gwj", description: "ǀGwi" },
  { subtag: "gwm", description: "Awngthim" },
  { subtag: "gwn", description: "Gwandara" },
  { subtag: "gwr", description: "Gwere" },
  { subtag: "gwt", description: "Gawar-Bati" },
  { subtag: "gwu", description: "Guwamu" },
  { subtag: "gww", description: "Kwini" },
  { subtag: "gwx", description: "Gua" },
  { subtag: "gxx", description: "Wè Southern" },
  { subtag: "gya", description: "Northwest Gbaya" },
  { subtag: "gyb", description: "Garus" },
  { subtag: "gyd", description: "Kayardild" },
  { subtag: "gye", description: "Gyem" },
  { subtag: "gyf", description: "Gungabula" },
  { subtag: "gyg", description: "Gbayi" },
  { subtag: "gyi", description: "Gyele" },
  { subtag: "gyl", description: "Gayil" },
  { subtag: "gym", description: "Ngäbere" },
  { subtag: "gyn", description: "Guyanese Creole English" },
  { subtag: "gyo", description: "Gyalsumdo" },
  { subtag: "gyr", description: "Guarayu" },
  { subtag: "gyy", description: "Gunya" },
  { subtag: "gyz", description: "Geji, Gyaazi" },
  { subtag: "gza", description: "Ganza" },
  { subtag: "gzi", description: "Gazi" },
  { subtag: "gzn", description: "Gane" },
  { subtag: "haa", description: "Han" },
  { subtag: "hab", description: "Hanoi Sign Language" },
  { subtag: "hac", description: "Gurani" },
  { subtag: "had", description: "Hatam" },
  { subtag: "hae", description: "Eastern Oromo" },
  { subtag: "haf", description: "Haiphong Sign Language" },
  { subtag: "hag", description: "Hanga" },
  { subtag: "hah", description: "Hahon" },
  { subtag: "hai", description: "Haida" },
  { subtag: "haj", description: "Hajong" },
  { subtag: "hak", description: "Hakka Chinese" },
  { subtag: "hal", description: "Halang" },
  { subtag: "ham", description: "Hewa" },
  { subtag: "han", description: "Hangaza" },
  { subtag: "hao", description: "Hakö" },
  { subtag: "hap", description: "Hupla" },
  { subtag: "haq", description: "Ha" },
  { subtag: "har", description: "Harari" },
  { subtag: "has", description: "Haisla" },
  { subtag: "hav", description: "Havu" },
  { subtag: "haw", description: "Hawaiian" },
  { subtag: "hax", description: "Southern Haida" },
  { subtag: "hay", description: "Haya" },
  { subtag: "haz", description: "Hazaragi" },
  { subtag: "hba", description: "Hamba" },
  { subtag: "hbb", description: "Huba" },
  { subtag: "hbn", description: "Heiban" },
  { subtag: "hbo", description: "Ancient Hebrew" },
  { subtag: "hbu", description: "Habu" },
  { subtag: "hca", description: "Andaman Creole Hindi" },
  { subtag: "hch", description: "Huichol" },
  { subtag: "hdn", description: "Northern Haida" },
  { subtag: "hds", description: "Honduras Sign Language" },
  { subtag: "hdy", description: "Hadiyya" },
  { subtag: "hea", description: "Northern Qiandong Miao" },
  { subtag: "hed", description: "Herdé" },
  { subtag: "heg", description: "Helong" },
  { subtag: "heh", description: "Hehe" },
  { subtag: "hei", description: "Heiltsuk" },
  { subtag: "hem", description: "Hemba" },
  { subtag: "hgm", description: "Haiǁom" },
  { subtag: "hgw", description: "Haigwai" },
  { subtag: "hhi", description: "Hoia Hoia" },
  { subtag: "hhr", description: "Kerak" },
  { subtag: "hhy", description: "Hoyahoya" },
  { subtag: "hia", description: "Lamang" },
  { subtag: "hib", description: "Hibito" },
  { subtag: "hid", description: "Hidatsa" },
  { subtag: "hif", description: "Fiji Hindi" },
  { subtag: "hig", description: "Kamwe" },
  { subtag: "hih", description: "Pamosu" },
  { subtag: "hii", description: "Hinduri" },
  { subtag: "hij", description: "Hijuk" },
  { subtag: "hik", description: "Seit-Kaitetu" },
  { subtag: "hil", description: "Hiligaynon" },
  { subtag: "him", description: "Himachali languages, Western Pahari languages" },
  { subtag: "hio", description: "Tsoa" },
  { subtag: "hir", description: "Himarimã" },
  { subtag: "hit", description: "Hittite" },
  { subtag: "hiw", description: "Hiw" },
  { subtag: "hix", description: "Hixkaryána" },
  { subtag: "hji", description: "Haji" },
  { subtag: "hka", description: "Kahe" },
  { subtag: "hke", description: "Hunde" },
  { subtag: "hkh", description: "Khah, Poguli" },
  { subtag: "hkk", description: "Hunjara-Kaina Ke" },
  { subtag: "hkn", description: "Mel-Khaonh" },
  { subtag: "hks", description: "Hong Kong Sign Language, Heung Kong Sau Yue" },
  { subtag: "hla", description: "Halia" },
  { subtag: "hlb", description: "Halbi" },
  { subtag: "hld", description: "Halang Doan" },
  { subtag: "hle", description: "Hlersu" },
  { subtag: "hlt", description: "Matu Chin" },
  { subtag: "hlu", description: "Hieroglyphic Luwian" },
  { subtag: "hma", description: "Southern Mashan Hmong, Southern Mashan Miao" },
  { subtag: "hmb", description: "Humburi Senni Songhay" },
  { subtag: "hmc", description: "Central Huishui Hmong, Central Huishui Miao" },
  { subtag: "hmd", description: "Large Flowery Miao, A-hmaos, Da-Hua Miao" },
  { subtag: "hme", description: "Eastern Huishui Hmong, Eastern Huishui Miao" },
  { subtag: "hmf", description: "Hmong Don" },
  { subtag: "hmg", description: "Southwestern Guiyang Hmong" },
  {
    subtag: "hmh",
    description: "Southwestern Huishui Hmong, Southwestern Huishui Miao",
  },
  { subtag: "hmi", description: "Northern Huishui Hmong, Northern Huishui Miao" },
  { subtag: "hmj", description: "Ge, Gejia" },
  { subtag: "hmk", description: "Maek" },
  { subtag: "hml", description: "Luopohe Hmong, Luopohe Miao" },
  { subtag: "hmm", description: "Central Mashan Hmong, Central Mashan Miao" },
  { subtag: "hmn", description: "Hmong, Mong" },
  { subtag: "hmp", description: "Northern Mashan Hmong, Northern Mashan Miao" },
  { subtag: "hmq", description: "Eastern Qiandong Miao" },
  { subtag: "hmr", description: "Hmar" },
  { subtag: "hms", description: "Southern Qiandong Miao" },
  { subtag: "hmt", description: "Hamtai" },
  { subtag: "hmu", description: "Hamap" },
  { subtag: "hmv", description: "Hmong Dô" },
  { subtag: "hmw", description: "Western Mashan Hmong, Western Mashan Miao" },
  { subtag: "hmx", description: "Hmong-Mien languages" },
  { subtag: "hmy", description: "Southern Guiyang Hmong, Southern Guiyang Miao" },
  { subtag: "hmz", description: "Hmong Shua, Sinicized Miao" },
  { subtag: "hna", description: "Mina (Cameroon)" },
  { subtag: "hnd", description: "Southern Hindko" },
  { subtag: "hne", description: "Chhattisgarhi" },
  { subtag: "hng", description: "Hungu" },
  { subtag: "hnh", description: "ǁAni" },
  { subtag: "hni", description: "Hani" },
  { subtag: "hnj", description: "Hmong Njua, Mong Leng, Mong Njua" },
  { subtag: "hnn", description: "Hanunoo" },
  { subtag: "hno", description: "Northern Hindko" },
  { subtag: "hns", description: "Caribbean Hindustani" },
  { subtag: "hnu", description: "Hung" },
  { subtag: "hoa", description: "Hoava" },
  { subtag: "hob", description: "Mari (Madang Province)" },
  { subtag: "hoc", description: "Ho" },
  { subtag: "hod", description: "Holma" },
  { subtag: "hoe", description: "Horom" },
  { subtag: "hoh", description: "Hobyót" },
  { subtag: "hoi", description: "Holikachuk" },
  { subtag: "hoj", description: "Hadothi, Haroti" },
  { subtag: "hok", description: "Hokan languages" },
  { subtag: "hol", description: "Holu" },
  { subtag: "hom", description: "Homa" },
  { subtag: "hoo", description: "Holoholo" },
  { subtag: "hop", description: "Hopi" },
  { subtag: "hor", description: "Horo" },
  { subtag: "hos", description: "Ho Chi Minh City Sign Language" },
  { subtag: "hot", description: "Hote, Malê" },
  { subtag: "hov", description: "Hovongan" },
  { subtag: "how", description: "Honi" },
  { subtag: "hoy", description: "Holiya" },
  { subtag: "hoz", description: "Hozo" },
  { subtag: "hpo", description: "Hpon" },
  { subtag: "hps", description: "Hawai'i Sign Language (HSL), Hawai'i Pidgin Sign Language" },
  { subtag: "hra", description: "Hrangkhol" },
  { subtag: "hrc", description: "Niwer Mil" },
  { subtag: "hre", description: "Hre" },
  { subtag: "hrk", description: "Haruku" },
  { subtag: "hrm", description: "Horned Miao" },
  { subtag: "hro", description: "Haroi" },
  { subtag: "hrp", description: "Nhirrpi" },
  { subtag: "hrt", description: "Hértevin" },
  { subtag: "hru", description: "Hruso" },
  { subtag: "hrw", description: "Warwar Feni" },
  { subtag: "hrx", description: "Hunsrik" },
  { subtag: "hrz", description: "Harzani" },
  { subtag: "hsb", description: "Upper Sorbian" },
  { subtag: "hsh", description: "Hungarian Sign Language" },
  { subtag: "hsl", description: "Hausa Sign Language" },
  { subtag: "hsn", description: "Xiang Chinese" },
  { subtag: "hss", description: "Harsusi" },
  { subtag: "hti", description: "Hoti" },
  { subtag: "hto", description: "Minica Huitoto" },
  { subtag: "hts", description: "Hadza" },
  { subtag: "htu", description: "Hitu" },
  { subtag: "htx", description: "Middle Hittite" },
  { subtag: "hub", description: "Huambisa" },
  { subtag: "huc", description: "ǂHua, ǂʼAmkhoe" },
  { subtag: "hud", description: "Huaulu" },
  { subtag: "hue", description: "San Francisco Del Mar Huave" },
  { subtag: "huf", description: "Humene" },
  { subtag: "hug", description: "Huachipaeri" },
  { subtag: "huh", description: "Huilliche" },
  { subtag: "hui", description: "Huli" },
  { subtag: "huj", description: "Northern Guiyang Hmong, Northern Guiyang Miao" },
  { subtag: "huk", description: "Hulung" },
  { subtag: "hul", description: "Hula" },
  { subtag: "hum", description: "Hungana" },
  { subtag: "huo", description: "Hu" },
  { subtag: "hup", description: "Hupa" },
  { subtag: "huq", description: "Tsat" },
  { subtag: "hur", description: "Halkomelem" },
  { subtag: "hus", description: "Huastec" },
  { subtag: "hut", description: "Humla" },
  { subtag: "huu", description: "Murui Huitoto" },
  { subtag: "huv", description: "San Mateo Del Mar Huave" },
  { subtag: "huw", description: "Hukumina" },
  { subtag: "hux", description: "Nüpode Huitoto" },
  { subtag: "huy", description: "Hulaulá" },
  { subtag: "huz", description: "Hunzib" },
  { subtag: "hvc", description: "Haitian Vodoun Culture Language" },
  { subtag: "hve", description: "San Dionisio Del Mar Huave" },
  { subtag: "hvk", description: "Haveke" },
  { subtag: "hvn", description: "Sabu" },
  { subtag: "hvv", description: "Santa María Del Mar Huave" },
  { subtag: "hwa", description: "Wané" },
  { subtag: "hwc", description: "Hawai'i Creole English, Hawai'i Pidgin" },
  { subtag: "hwo", description: "Hwana" },
  { subtag: "hya", description: "Hya" },
  { subtag: "hyw", description: "Western Armenian" },
  { subtag: "hyx", description: "Armenian (family)" },
  { subtag: "iai", description: "Iaai" },
  { subtag: "ian", description: "Iatmul" },
  { subtag: "iar", description: "Purari" },
  { subtag: "iba", description: "Iban" },
  { subtag: "ibb", description: "Ibibio" },
  { subtag: "ibd", description: "Iwaidja" },
  { subtag: "ibe", description: "Akpes" },
  { subtag: "ibg", description: "Ibanag" },
  { subtag: "ibh", description: "Bih" },
  { subtag: "ibl", description: "Ibaloi" },
  { subtag: "ibm", description: "Agoi" },
  { subtag: "ibn", description: "Ibino" },
  { subtag: "ibr", description: "Ibuoro" },
  { subtag: "ibu", description: "Ibu" },
  { subtag: "iby", description: "Ibani" },
  { subtag: "ica", description: "Ede Ica" },
  { subtag: "ich", description: "Etkywan" },
  { subtag: "icl", description: "Icelandic Sign Language" },
  { subtag: "icr", description: "Islander Creole English" },
  { subtag: "ida", description: "Idakho-Isukha-Tiriki, Luidakho-Luisukha-Lutirichi" },
  { subtag: "idb", description: "Indo-Portuguese" },
  { subtag: "idc", description: "Idon, Ajiya" },
  { subtag: "idd", description: "Ede Idaca" },
  { subtag: "ide", description: "Idere" },
  { subtag: "idi", description: "Idi" },
  { subtag: "idr", description: "Indri" },
  { subtag: "ids", description: "Idesa" },
  { subtag: "idt", description: "Idaté" },
  { subtag: "idu", description: "Idoma" },
  { subtag: "ifa", description: "Amganad Ifugao" },
  { subtag: "ifb", description: "Batad Ifugao, Ayangan Ifugao" },
  { subtag: "ife", description: "Ifè" },
  { subtag: "iff", description: "Ifo" },
  { subtag: "ifk", description: "Tuwali Ifugao" },
  { subtag: "ifm", description: "Teke-Fuumu" },
  { subtag: "ifu", description: "Mayoyao Ifugao" },
  { subtag: "ify", description: "Keley-I Kallahan" },
  { subtag: "igb", description: "Ebira" },
  { subtag: "ige", description: "Igede" },
  { subtag: "igg", description: "Igana" },
  { subtag: "igl", description: "Igala" },
  { subtag: "igm", description: "Kanggape" },
  { subtag: "ign", description: "Ignaciano" },
  { subtag: "igo", description: "Isebe" },
  { subtag: "igs", description: "Interglossa" },
  { subtag: "igw", description: "Igwe" },
  { subtag: "ihb", description: "Iha Based Pidgin" },
  { subtag: "ihi", description: "Ihievbe" },
  { subtag: "ihp", description: "Iha" },
  { subtag: "ihw", description: "Bidhawal" },
  { subtag: "iin", description: "Thiin" },
  { subtag: "iir", description: "Indo-Iranian languages" },
  { subtag: "ijc", description: "Izon" },
  { subtag: "ije", description: "Biseni" },
  { subtag: "ijj", description: "Ede Ije" },
  { subtag: "ijn", description: "Kalabari" },
  { subtag: "ijo", description: "Ijo languages" },
  { subtag: "ijs", description: "Southeast Ijo" },
  { subtag: "ike", description: "Eastern Canadian Inuktitut" },
  { subtag: "iki", description: "Iko" },
  { subtag: "ikk", description: "Ika" },
  { subtag: "ikl", description: "Ikulu" },
  { subtag: "iko", description: "Olulumo-Ikom" },
  { subtag: "ikp", description: "Ikpeshi" },
  { subtag: "ikr", description: "Ikaranggal" },
  { subtag: "iks", description: "Inuit Sign Language" },
  { subtag: "ikt", description: "Inuinnaqtun, Western Canadian Inuktitut" },
  { subtag: "ikv", description: "Iku-Gora-Ankwa" },
  { subtag: "ikw", description: "Ikwere" },
  { subtag: "ikx", description: "Ik" },
  { subtag: "ikz", description: "Ikizu" },
  { subtag: "ila", description: "Ile Ape" },
  { subtag: "ilb", description: "Ila" },
  { subtag: "ilg", description: "Garig-Ilgar" },
  { subtag: "ili", description: "Ili Turki" },
  { subtag: "ilk", description: "Ilongot" },
  { subtag: "ilm", description: "Iranun (Malaysia)" },
  { subtag: "ilo", description: "Iloko" },
  { subtag: "ilp", description: "Iranun (Philippines)" },
  { subtag: "ils", description: "International Sign" },
  { subtag: "ilu", description: "Ili'uun" },
  { subtag: "ilv", description: "Ilue" },
  { subtag: "ima", description: "Mala Malasar" },
  { subtag: "imi", description: "Anamgura" },
  { subtag: "iml", description: "Miluk" },
  { subtag: "imn", description: "Imonda" },
  { subtag: "imo", description: "Imbongu" },
  { subtag: "imr", description: "Imroing" },
  { subtag: "ims", description: "Marsian" },
  { subtag: "imt", description: "Imotong" },
  { subtag: "imy", description: "Milyan" },
  { subtag: "inb", description: "Inga" },
  { subtag: "inc", description: "Indic languages" },
  { subtag: "ine", description: "Indo-European languages" },
  { subtag: "ing", description: "Degexit'an" },
  { subtag: "inh", description: "Ingush" },
  { subtag: "inj", description: "Jungle Inga" },
  { subtag: "inl", description: "Indonesian Sign Language" },
  { subtag: "inm", description: "Minaean" },
  { subtag: "inn", description: "Isinai" },
  { subtag: "ino", description: "Inoke-Yate" },
  { subtag: "inp", description: "Iñapari" },
  { subtag: "ins", description: "Indian Sign Language" },
  { subtag: "int", description: "Intha" },
  { subtag: "inz", description: "Ineseño" },
  { subtag: "ior", description: "Inor" },
  { subtag: "iou", description: "Tuma-Irumu" },
  { subtag: "iow", description: "Iowa-Oto" },
  { subtag: "ipi", description: "Ipili" },
  { subtag: "ipo", description: "Ipiko" },
  { subtag: "iqu", description: "Iquito" },
  { subtag: "iqw", description: "Ikwo" },
  { subtag: "ira", description: "Iranian languages" },
  { subtag: "ire", description: "Iresim" },
  { subtag: "irh", description: "Irarutu" },
  { subtag: "iri", description: "Rigwe, Irigwe" },
  { subtag: "irk", description: "Iraqw" },
  { subtag: "irn", description: "Irántxe" },
  { subtag: "iro", description: "Iroquoian languages" },
  { subtag: "irr", description: "Ir" },
  { subtag: "iru", description: "Irula" },
  { subtag: "irx", description: "Kamberau" },
  { subtag: "iry", description: "Iraya" },
  { subtag: "isa", description: "Isabi" },
  { subtag: "isc", description: "Isconahua" },
  { subtag: "isd", description: "Isnag" },
  { subtag: "ise", description: "Italian Sign Language" },
  { subtag: "isg", description: "Irish Sign Language" },
  { subtag: "ish", description: "Esan" },
  { subtag: "isi", description: "Nkem-Nkum" },
  { subtag: "isk", description: "Ishkashimi" },
  { subtag: "ism", description: "Masimasi" },
  { subtag: "isn", description: "Isanzu" },
  { subtag: "iso", description: "Isoko" },
  { subtag: "isr", description: "Israeli Sign Language" },
  { subtag: "ist", description: "Istriot" },
  { subtag: "isu", description: "Isu (Menchum Division)" },
  { subtag: "itb", description: "Binongan Itneg" },
  { subtag: "itc", description: "Italic languages" },
  { subtag: "itd", description: "Southern Tidung" },
  { subtag: "ite", description: "Itene" },
  { subtag: "iti", description: "Inlaod Itneg" },
  { subtag: "itk", description: "Judeo-Italian" },
  { subtag: "itl", description: "Itelmen" },
  { subtag: "itm", description: "Itu Mbon Uzo" },
  { subtag: "ito", description: "Itonama" },
  { subtag: "itr", description: "Iteri" },
  { subtag: "its", description: "Isekiri" },
  { subtag: "itt", description: "Maeng Itneg" },
  { subtag: "itv", description: "Itawit" },
  { subtag: "itw", description: "Ito" },
  { subtag: "itx", description: "Itik" },
  { subtag: "ity", description: "Moyadan Itneg" },
  { subtag: "itz", description: "Itzá" },
  { subtag: "ium", description: "Iu Mien" },
  { subtag: "ivb", description: "Ibatan" },
  { subtag: "ivv", description: "Ivatan" },
  { subtag: "iwk", description: "I-Wak" },
  { subtag: "iwm", description: "Iwam" },
  { subtag: "iwo", description: "Iwur" },
  { subtag: "iws", description: "Sepik Iwam" },
  { subtag: "ixc", description: "Ixcatec" },
  { subtag: "ixl", description: "Ixil" },
  { subtag: "iya", description: "Iyayu" },
  { subtag: "iyo", description: "Mesaka" },
  { subtag: "iyx", description: "Yaka (Congo)" },
  { subtag: "izh", description: "Ingrian" },
  { subtag: "izr", description: "Izere" },
  { subtag: "izz", description: "Izii" },
  { subtag: "jaa", description: "Jamamadí" },
  { subtag: "jab", description: "Hyam" },
  { subtag: "jac", description: "Popti', Jakalteko" },
  { subtag: "jad", description: "Jahanka" },
  { subtag: "jae", description: "Yabem" },
  { subtag: "jaf", description: "Jara" },
  { subtag: "jah", description: "Jah Hut" },
  { subtag: "jaj", description: "Zazao" },
  { subtag: "jak", description: "Jakun" },
  { subtag: "jal", description: "Yalahatan" },
  { subtag: "jam", description: "Jamaican Creole English" },
  { subtag: "jan", description: "Jandai" },
  { subtag: "jao", description: "Yanyuwa" },
  { subtag: "jaq", description: "Yaqay" },
  { subtag: "jas", description: "New Caledonian Javanese" },
  { subtag: "jat", description: "Jakati" },
  { subtag: "jau", description: "Yaur" },
  { subtag: "jax", description: "Jambi Malay" },
  { subtag: "jay", description: "Yan-nhangu, Nhangu" },
  { subtag: "jaz", description: "Jawe" },
  { subtag: "jbe", description: "Judeo-Berber" },
  { subtag: "jbi", description: "Badjiri" },
  { subtag: "jbj", description: "Arandai" },
  { subtag: "jbk", description: "Barikewa" },
  { subtag: "jbm", description: "Bijim" },
  { subtag: "jbn", description: "Nafusi" },
  { subtag: "jbo", description: "Lojban" },
  { subtag: "jbr", description: "Jofotek-Bromnya" },
  { subtag: "jbt", description: "Jabutí" },
  { subtag: "jbu", description: "Jukun Takum" },
  { subtag: "jbw", description: "Yawijibaya" },
  { subtag: "jcs", description: "Jamaican Country Sign Language" },
  { subtag: "jct", description: "Krymchak" },
  { subtag: "jda", description: "Jad" },
  { subtag: "jdg", description: "Jadgali" },
  { subtag: "jdt", description: "Judeo-Tat" },
  { subtag: "jeb", description: "Jebero" },
  { subtag: "jee", description: "Jerung" },
  { subtag: "jeh", description: "Jeh" },
  { subtag: "jei", description: "Yei" },
  { subtag: "jek", description: "Jeri Kuo" },
  { subtag: "jel", description: "Yelmek" },
  { subtag: "jen", description: "Dza" },
  { subtag: "jer", description: "Jere" },
  { subtag: "jet", description: "Manem" },
  { subtag: "jeu", description: "Jonkor Bourmataguil" },
  { subtag: "jgb", description: "Ngbee" },
  { subtag: "jge", description: "Judeo-Georgian" },
  { subtag: "jgk", description: "Gwak" },
  { subtag: "jgo", description: "Ngomba" },
  { subtag: "jhi", description: "Jehai" },
  { subtag: "jhs", description: "Jhankot Sign Language" },
  { subtag: "jia", description: "Jina" },
  { subtag: "jib", description: "Jibu" },
  { subtag: "jic", description: "Tol" },
  { subtag: "jid", description: "Bu (Kaduna State)" },
  { subtag: "jie", description: "Jilbe" },
  { subtag: "jig", description: "Jingulu, Djingili" },
  { subtag: "jih", description: "sTodsde, Shangzhai" },
  { subtag: "jii", description: "Jiiddu" },
  { subtag: "jil", description: "Jilim" },
  { subtag: "jim", description: "Jimi (Cameroon)" },
  { subtag: "jio", description: "Jiamao" },
  { subtag: "jiq", description: "Guanyinqiao, Lavrung" },
  { subtag: "jit", description: "Jita" },
  { subtag: "jiu", description: "Youle Jinuo" },
  { subtag: "jiv", description: "Shuar" },
  { subtag: "jiy", description: "Buyuan Jinuo" },
  { subtag: "jje", description: "Jejueo" },
  { subtag: "jjr", description: "Bankal" },
  { subtag: "jka", description: "Kaera" },
  { subtag: "jkm", description: "Mobwa Karen" },
  { subtag: "jko", description: "Kubo" },
  { subtag: "jkp", description: "Paku Karen" },
  { subtag: "jkr", description: "Koro (India)" },
  { subtag: "jks", description: "Amami Koniya Sign Language" },
  { subtag: "jku", description: "Labir" },
  { subtag: "jle", description: "Ngile" },
  { subtag: "jls", description: "Jamaican Sign Language" },
  { subtag: "jma", description: "Dima" },
  { subtag: "jmb", description: "Zumbun" },
  { subtag: "jmc", description: "Machame" },
  { subtag: "jmd", description: "Yamdena" },
  { subtag: "jmi", description: "Jimi (Nigeria)" },
  { subtag: "jml", description: "Jumli" },
  { subtag: "jmn", description: "Makuri Naga" },
  { subtag: "jmr", description: "Kamara" },
  { subtag: "jms", description: "Mashi (Nigeria)" },
  { subtag: "jmw", description: "Mouwase" },
  { subtag: "jmx", description: "Western Juxtlahuaca Mixtec" },
  { subtag: "jna", description: "Jangshung" },
  { subtag: "jnd", description: "Jandavra" },
  { subtag: "jng", description: "Yangman" },
  { subtag: "jni", description: "Janji" },
  { subtag: "jnj", description: "Yemsa" },
  { subtag: "jnl", description: "Rawat" },
  { subtag: "jns", description: "Jaunsari" },
  { subtag: "job", description: "Joba" },
  { subtag: "jod", description: "Wojenaka" },
  { subtag: "jog", description: "Jogi" },
  { subtag: "jor", description: "Jorá" },
  { subtag: "jos", description: "Jordanian Sign Language" },
  { subtag: "jow", description: "Jowulu" },
  { subtag: "jpa", description: "Jewish Palestinian Aramaic" },
  { subtag: "jpr", description: "Judeo-Persian" },
  { subtag: "jpx", description: "Japanese (family)" },
  { subtag: "jqr", description: "Jaqaru" },
  { subtag: "jra", description: "Jarai" },
  { subtag: "jrb", description: "Judeo-Arabic" },
  { subtag: "jrr", description: "Jiru" },
  { subtag: "jrt", description: "Jakattoe" },
  { subtag: "jru", description: "Japrería" },
  { subtag: "jsl", description: "Japanese Sign Language" },
  { subtag: "jua", description: "Júma" },
  { subtag: "jub", description: "Wannu" },
  { subtag: "juc", description: "Jurchen" },
  { subtag: "jud", description: "Worodougou" },
  { subtag: "juh", description: "Hõne" },
  { subtag: "jui", description: "Ngadjuri" },
  { subtag: "juk", description: "Wapan" },
  { subtag: "jul", description: "Jirel" },
  { subtag: "jum", description: "Jumjum" },
  { subtag: "jun", description: "Juang" },
  { subtag: "juo", description: "Jiba" },
  { subtag: "jup", description: "Hupdë" },
  { subtag: "jur", description: "Jurúna" },
  { subtag: "jus", description: "Jumla Sign Language" },
  { subtag: "jut", description: "Jutish" },
  { subtag: "juu", description: "Ju" },
  { subtag: "juw", description: "Wãpha" },
  { subtag: "juy", description: "Juray" },
  { subtag: "jvd", description: "Javindo" },
  { subtag: "jvn", description: "Caribbean Javanese" },
  { subtag: "jwi", description: "Jwira-Pepesa" },
  { subtag: "jya", description: "Jiarong" },
  { subtag: "jye", description: "Judeo-Yemeni Arabic" },
  { subtag: "jyy", description: "Jaya" },
  { subtag: "kaa", description: "Kara-Kalpak, Karakalpak" },
  { subtag: "kab", description: "Kabyle" },
  { subtag: "kac", description: "Kachin, Jingpho" },
  { subtag: "kad", description: "Adara" },
  { subtag: "kae", description: "Ketangalan" },
  { subtag: "kaf", description: "Katso" },
  { subtag: "kag", description: "Kajaman" },
  { subtag: "kah", description: "Kara (Central African Republic)" },
  { subtag: "kai", description: "Karekare" },
  { subtag: "kaj", description: "Jju" },
  { subtag: "kak", description: "Kalanguya, Kayapa Kallahan" },
  { subtag: "kam", description: "Kamba (Kenya)" },
  { subtag: "kao", description: "Xaasongaxango" },
  { subtag: "kap", description: "Bezhta" },
  { subtag: "kaq", description: "Capanahua" },
  { subtag: "kar", description: "Karen languages" },
  { subtag: "kav", description: "Katukína" },
  { subtag: "kaw", description: "Kawi" },
  { subtag: "kax", description: "Kao" },
  { subtag: "kay", description: "Kamayurá" },
  { subtag: "kba", description: "Kalarko" },
  { subtag: "kbb", description: "Kaxuiâna" },
  { subtag: "kbc", description: "Kadiwéu" },
  { subtag: "kbd", description: "Kabardian" },
  { subtag: "kbe", description: "Kanju" },
  { subtag: "kbg", description: "Khamba" },
  { subtag: "kbh", description: "Camsá" },
  { subtag: "kbi", description: "Kaptiau" },
  { subtag: "kbj", description: "Kari" },
  { subtag: "kbk", description: "Grass Koiari" },
  { subtag: "kbl", description: "Kanembu" },
  { subtag: "kbm", description: "Iwal" },
  { subtag: "kbn", description: "Kare (Central African Republic)" },
  { subtag: "kbo", description: "Keliko" },
  { subtag: "kbp", description: "Kabiyè" },
  { subtag: "kbq", description: "Kamano" },
  { subtag: "kbr", description: "Kafa" },
  { subtag: "kbs", description: "Kande" },
  { subtag: "kbt", description: "Abadi" },
  { subtag: "kbu", description: "Kabutra" },
  { subtag: "kbv", description: "Dera (Indonesia)" },
  { subtag: "kbw", description: "Kaiep" },
  { subtag: "kbx", description: "Ap Ma" },
  { subtag: "kby", description: "Manga Kanuri" },
  { subtag: "kbz", description: "Duhwa" },
  { subtag: "kca", description: "Khanty" },
  { subtag: "kcb", description: "Kawacha" },
  { subtag: "kcc", description: "Lubila" },
  { subtag: "kcd", description: "Ngkâlmpw Kanum" },
  { subtag: "kce", description: "Kaivi" },
  { subtag: "kcf", description: "Ukaan" },
  { subtag: "kcg", description: "Tyap" },
  { subtag: "kch", description: "Vono" },
  { subtag: "kci", description: "Kamantan" },
  { subtag: "kcj", description: "Kobiana" },
  { subtag: "kck", description: "Kalanga" },
  { subtag: "kcl", description: "Kela (Papua New Guinea), Kala" },
  { subtag: "kcm", description: "Gula (Central African Republic)" },
  { subtag: "kcn", description: "Nubi" },
  { subtag: "kco", description: "Kinalakna" },
  { subtag: "kcp", description: "Kanga" },
  { subtag: "kcq", description: "Kamo" },
  { subtag: "kcr", description: "Katla" },
  { subtag: "kcs", description: "Koenoem" },
  { subtag: "kct", description: "Kaian" },
  { subtag: "kcu", description: "Kami (Tanzania)" },
  { subtag: "kcv", description: "Kete" },
  { subtag: "kcw", description: "Kabwari" },
  { subtag: "kcx", description: "Kachama-Ganjule" },
  { subtag: "kcy", description: "Korandje" },
  { subtag: "kcz", description: "Konongo" },
  { subtag: "kda", description: "Worimi" },
  { subtag: "kdc", description: "Kutu" },
  { subtag: "kdd", description: "Yankunytjatjara" },
  { subtag: "kde", description: "Makonde" },
  { subtag: "kdf", description: "Mamusi" },
  { subtag: "kdg", description: "Seba" },
  { subtag: "kdh", description: "Tem" },
  { subtag: "kdi", description: "Kumam" },
  { subtag: "kdj", description: "Karamojong" },
  { subtag: "kdk", description: "Numèè, Kwényi" },
  { subtag: "kdl", description: "Tsikimba" },
  { subtag: "kdm", description: "Kagoma" },
  { subtag: "kdn", description: "Kunda" },
  { subtag: "kdo", description: "Kordofanian languages" },
  { subtag: "kdp", description: "Kaningdon-Nindem" },
  { subtag: "kdq", description: "Koch" },
  { subtag: "kdr", description: "Karaim" },
  { subtag: "kdt", description: "Kuy" },
  { subtag: "kdu", description: "Kadaru" },
  { subtag: "kdw", description: "Koneraw" },
  { subtag: "kdx", description: "Kam" },
  { subtag: "kdy", description: "Keder, Keijar" },
  { subtag: "kdz", description: "Kwaja" },
  { subtag: "kea", description: "Kabuverdianu" },
  { subtag: "keb", description: "Kélé" },
  { subtag: "kec", description: "Keiga" },
  { subtag: "ked", description: "Kerewe" },
  { subtag: "kee", description: "Eastern Keres" },
  { subtag: "kef", description: "Kpessi" },
  { subtag: "keg", description: "Tese" },
  { subtag: "keh", description: "Keak" },
  { subtag: "kei", description: "Kei" },
  { subtag: "kej", description: "Kadar" },
  { subtag: "kek", description: "Kekchí" },
  { subtag: "kel", description: "Kela (Democratic Republic of Congo)" },
  { subtag: "kem", description: "Kemak" },
  { subtag: "ken", description: "Kenyang" },
  { subtag: "keo", description: "Kakwa" },
  { subtag: "kep", description: "Kaikadi" },
  { subtag: "keq", description: "Kamar" },
  { subtag: "ker", description: "Kera" },
  { subtag: "kes", description: "Kugbo" },
  { subtag: "ket", description: "Ket" },
  { subtag: "keu", description: "Akebu" },
  { subtag: "kev", description: "Kanikkaran" },
  { subtag: "kew", description: "West Kewa" },
  { subtag: "kex", description: "Kukna" },
  { subtag: "key", description: "Kupia" },
  { subtag: "kez", description: "Kukele" },
  { subtag: "kfa", description: "Kodava" },
  { subtag: "kfb", description: "Northwestern Kolami" },
  { subtag: "kfc", description: "Konda-Dora" },
  { subtag: "kfd", description: "Korra Koraga" },
  { subtag: "kfe", description: "Kota (India)" },
  { subtag: "kff", description: "Koya" },
  { subtag: "kfg", description: "Kudiya" },
  { subtag: "kfh", description: "Kurichiya" },
  { subtag: "kfi", description: "Kannada Kurumba" },
  { subtag: "kfj", description: "Kemiehua" },
  { subtag: "kfk", description: "Kinnauri" },
  { subtag: "kfl", description: "Kung" },
  { subtag: "kfm", description: "Khunsari" },
  { subtag: "kfn", description: "Kuk" },
  { subtag: "kfo", description: "Koro (Côte d'Ivoire)" },
  { subtag: "kfp", description: "Korwa" },
  { subtag: "kfq", description: "Korku" },
  { subtag: "kfr", description: "Kachhi, Kutchi" },
  { subtag: "kfs", description: "Bilaspuri" },
  { subtag: "kft", description: "Kanjari" },
  { subtag: "kfu", description: "Katkari" },
  { subtag: "kfv", description: "Kurmukar" },
  { subtag: "kfw", description: "Kharam Naga" },
  { subtag: "kfx", description: "Kullu Pahari" },
  { subtag: "kfy", description: "Kumaoni" },
  { subtag: "kfz", description: "Koromfé" },
  { subtag: "kga", description: "Koyaga" },
  { subtag: "kgb", description: "Kawe" },
  { subtag: "kge", description: "Komering" },
  { subtag: "kgf", description: "Kube" },
  { subtag: "kgg", description: "Kusunda" },
  { subtag: "kgi", description: "Selangor Sign Language" },
  { subtag: "kgj", description: "Gamale Kham" },
  { subtag: "kgk", description: "Kaiwá" },
  { subtag: "kgl", description: "Kunggari" },
  { subtag: "kgm", description: "Karipúna" },
  { subtag: "kgn", description: "Karingani" },
  { subtag: "kgo", description: "Krongo" },
  { subtag: "kgp", description: "Kaingang" },
  { subtag: "kgq", description: "Kamoro" },
  { subtag: "kgr", description: "Abun" },
  { subtag: "kgs", description: "Kumbainggar" },
  { subtag: "kgt", description: "Somyev" },
  { subtag: "kgu", description: "Kobol" },
  { subtag: "kgv", description: "Karas" },
  { subtag: "kgw", description: "Karon Dori" },
  { subtag: "kgx", description: "Kamaru" },
  { subtag: "kgy", description: "Kyerung" },
  {
    subtag: "kha",
    description: "Khasi",
  },
  { subtag: "khb", description: "Lü" },
  { subtag: "khc", description: "Tukang Besi North" },
  { subtag: "khd", description: "Bädi Kanum" },
  { subtag: "khe", description: "Korowai" },
  { subtag: "khf", description: "Khuen" },
  { subtag: "khg", description: "Khams Tibetan" },
  { subtag: "khh", description: "Kehu" },
  { subtag: "khi", description: "Khoisan languages" },
  { subtag: "khj", description: "Kuturmi" },
  { subtag: "khk", description: "Halh Mongolian" },
  { subtag: "khl", description: "Lusi" },
  { subtag: "khn", description: "Khandesi" },
  { subtag: "kho", description: "Khotanese, Sakan" },
  { subtag: "khp", description: "Kapori, Kapauri" },
  { subtag: "khq", description: "Koyra Chiini Songhay" },
  { subtag: "khr", description: "Kharia" },
  { subtag: "khs", description: "Kasua" },
  { subtag: "kht", description: "Khamti" },
  { subtag: "khu", description: "Nkhumbi" },
  { subtag: "khv", description: "Khvarshi" },
  { subtag: "khw", description: "Khowar" },
  { subtag: "khx", description: "Kanu" },
  { subtag: "khy", description: "Kele (Democratic Republic of Congo)" },
  { subtag: "khz", description: "Keapara" },
  { subtag: "kia", description: "Kim" },
  { subtag: "kib", description: "Koalib" },
  { subtag: "kic", description: "Kickapoo" },
  { subtag: "kid", description: "Koshin" },
  { subtag: "kie", description: "Kibet" },
  { subtag: "kif", description: "Eastern Parbate Kham" },
  { subtag: "kig", description: "Kimaama, Kimaghima" },
  { subtag: "kih", description: "Kilmeri" },
  { subtag: "kii", description: "Kitsai" },
  { subtag: "kij", description: "Kilivila" },
  { subtag: "kil", description: "Kariya" },
  { subtag: "kim", description: "Karagas" },
  { subtag: "kio", description: "Kiowa" },
  { subtag: "kip", description: "Sheshi Kham" },
  { subtag: "kiq", description: "Kosadle, Kosare" },
  { subtag: "kis", description: "Kis" },
  { subtag: "kit", description: "Agob" },
  { subtag: "kiu", description: "Kirmanjki (individual language)" },
  { subtag: "kiv", description: "Kimbu" },
  { subtag: "kiw", description: "Northeast Kiwai" },
  { subtag: "kix", description: "Khiamniungan Naga" },
  { subtag: "kiy", description: "Kirikiri" },
  { subtag: "kiz", description: "Kisi" },
  { subtag: "kja", description: "Mlap" },
  { subtag: "kjb", description: "Q'anjob'al, Kanjobal" },
  { subtag: "kjc", description: "Coastal Konjo" },
  { subtag: "kjd", description: "Southern Kiwai" },
  { subtag: "kje", description: "Kisar" },
  { subtag: "kjg", description: "Khmu" },
  { subtag: "kjh", description: "Khakas" },
  { subtag: "kji", description: "Zabana" },
  { subtag: "kjj", description: "Khinalugh" },
  { subtag: "kjk", description: "Highland Konjo" },
  { subtag: "kjl", description: "Western Parbate Kham" },
  { subtag: "kjm", description: "Kháng" },
  { subtag: "kjn", description: "Kunjen" },
  { subtag: "kjo", description: "Harijan Kinnauri" },
  { subtag: "kjp", description: "Pwo Eastern Karen" },
  { subtag: "kjq", description: "Western Keres" },
  { subtag: "kjr", description: "Kurudu" },
  { subtag: "kjs", description: "East Kewa" },
  { subtag: "kjt", description: "Phrae Pwo Karen" },
  { subtag: "kju", description: "Kashaya" },
  { subtag: "kjv", description: "Kaikavian Literary Language" },
  { subtag: "kjx", description: "Ramopa" },
  { subtag: "kjy", description: "Erave" },
  { subtag: "kjz", description: "Bumthangkha" },
  { subtag: "kka", description: "Kakanda" },
  { subtag: "kkb", description: "Kwerisa" },
  { subtag: "kkc", description: "Odoodee" },
  { subtag: "kkd", description: "Kinuku" },
  { subtag: "kke", description: "Kakabe" },
  { subtag: "kkf", description: "Kalaktang Monpa" },
  { subtag: "kkg", description: "Mabaka Valley Kalinga" },
  { subtag: "kkh", description: "Khün" },
  { subtag: "kki", description: "Kagulu" },
  { subtag: "kkj", description: "Kako" },
  { subtag: "kkk", description: "Kokota" },
  { subtag: "kkl", description: "Kosarek Yale" },
  { subtag: "kkm", description: "Kiong" },
  { subtag: "kkn", description: "Kon Keu" },
  { subtag: "kko", description: "Karko" },
  { subtag: "kkp", description: "Gugubera, Koko-Bera" },
  { subtag: "kkq", description: "Kaeku" },
  { subtag: "kkr", description: "Kir-Balar" },
  { subtag: "kks", description: "Giiwo" },
  { subtag: "kkt", description: "Koi" },
  { subtag: "kku", description: "Tumi" },
  { subtag: "kkv", description: "Kangean" },
  { subtag: "kkw", description: "Teke-Kukuya" },
  { subtag: "kkx", description: "Kohin" },
  { subtag: "kky", description: "Guugu Yimidhirr, Guguyimidjir" },
  { subtag: "kkz", description: "Kaska" },
  { subtag: "kla", description: "Klamath-Modoc" },
  { subtag: "klb", description: "Kiliwa" },
  { subtag: "klc", description: "Kolbila" },
  { subtag: "kld", description: "Gamilaraay" },
  { subtag: "kle", description: "Kulung (Nepal)" },
  { subtag: "klf", description: "Kendeje" },
  { subtag: "klg", description: "Tagakaulo" },
  { subtag: "klh", description: "Weliki" },
  { subtag: "kli", description: "Kalumpang" },
  { subtag: "klj", description: "Khalaj" },
  { subtag: "klk", description: "Kono (Nigeria)" },
  { subtag: "kll", description: "Kagan Kalagan" },
  { subtag: "klm", description: "Migum" },
  { subtag: "kln", description: "Kalenjin" },
  { subtag: "klo", description: "Kapya" },
  { subtag: "klp", description: "Kamasa" },
  { subtag: "klq", description: "Rumu" },
  { subtag: "klr", description: "Khaling" },
  { subtag: "kls", description: "Kalasha" },
  { subtag: "klt", description: "Nukna" },
  { subtag: "klu", description: "Klao" },
  { subtag: "klv", description: "Maskelynes" },
  { subtag: "klw", description: "Tado, Lindu" },
  { subtag: "klx", description: "Koluwawa" },
  { subtag: "kly", description: "Kalao" },
  { subtag: "klz", description: "Kabola" },
  { subtag: "kma", description: "Konni" },
  { subtag: "kmb", description: "Kimbundu" },
  { subtag: "kmc", description: "Southern Dong" },
  { subtag: "kmd", description: "Majukayang Kalinga" },
  { subtag: "kme", description: "Bakole" },
  { subtag: "kmf", description: "Kare (Papua New Guinea)" },
  { subtag: "kmg", description: "Kâte" },
  { subtag: "kmh", description: "Kalam" },
  { subtag: "kmi", description: "Kami (Nigeria)" },
  { subtag: "kmj", description: "Kumarbhag Paharia" },
  { subtag: "kmk", description: "Limos Kalinga" },
  { subtag: "kml", description: "Tanudan Kalinga" },
  { subtag: "kmm", description: "Kom (India)" },
  { subtag: "kmn", description: "Awtuw" },
  { subtag: "kmo", description: "Kwoma" },
  { subtag: "kmp", description: "Gimme" },
  { subtag: "kmq", description: "Kwama" },
  { subtag: "kmr", description: "Northern Kurdish" },
  { subtag: "kms", description: "Kamasau" },
  { subtag: "kmt", description: "Kemtuik" },
  { subtag: "kmu", description: "Kanite" },
  { subtag: "kmv", description: "Karipúna Creole French" },
  { subtag: "kmw", description: "Komo (Democratic Republic of Congo)" },
  { subtag: "kmx", description: "Waboda" },
  { subtag: "kmy", description: "Koma" },
  { subtag: "kmz", description: "Khorasani Turkish" },
  { subtag: "kna", description: "Dera (Nigeria)" },
  { subtag: "knb", description: "Lubuagan Kalinga" },
  { subtag: "knc", description: "Central Kanuri" },
  { subtag: "knd", description: "Konda" },
  { subtag: "kne", description: "Kankanaey" },
  { subtag: "knf", description: "Mankanya" },
  { subtag: "kng", description: "Koongo" },
  { subtag: "kni", description: "Kanufi" },
  { subtag: "knj", description: "Western Kanjobal" },
  { subtag: "knk", description: "Kuranko" },
  { subtag: "knl", description: "Keninjal" },
  { subtag: "knm", description: "Kanamarí" },
  { subtag: "knn", description: "Konkani (individual language)" },
  { subtag: "kno", description: "Kono (Sierra Leone)" },
  { subtag: "knp", description: "Kwanja" },
  { subtag: "knq", description: "Kintaq" },
  { subtag: "knr", description: "Kaningra" },
  { subtag: "kns", description: "Kensiu" },
  { subtag: "knt", description: "Panoan Katukína" },
  { subtag: "knu", description: "Kono (Guinea)" },
  { subtag: "knv", description: "Tabo" },
  { subtag: "knw", description: "Kung-Ekoka" },
  { subtag: "knx", description: "Kendayan, Salako" },
  { subtag: "kny", description: "Kanyok" },
  { subtag: "knz", description: "Kalamsé" },
  { subtag: "koa", description: "Konomala" },
  { subtag: "koc", description: "Kpati" },
  { subtag: "kod", description: "Kodi" },
  { subtag: "koe", description: "Kacipo-Bale Suri" },
  { subtag: "kof", description: "Kubi" },
  { subtag: "kog", description: "Cogui, Kogi" },
  { subtag: "koh", description: "Koyo" },
  { subtag: "koi", description: "Komi-Permyak" },
  { subtag: "kol", description: "Kol (Papua New Guinea)" },
  { subtag: "koo", description: "Konzo" },
  { subtag: "kop", description: "Waube" },
  { subtag: "koq", description: "Kota (Gabon)" },
  { subtag: "kos", description: "Kosraean" },
  { subtag: "kot", description: "Lagwan" },
  { subtag: "kou", description: "Koke" },
  { subtag: "kov", description: "Kudu-Camo" },
  { subtag: "kow", description: "Kugama" },
  { subtag: "koy", description: "Koyukon" },
  { subtag: "koz", description: "Korak" },
  { subtag: "kpa", description: "Kutto" },
  { subtag: "kpb", description: "Mullu Kurumba" },
  { subtag: "kpc", description: "Curripaco" },
  { subtag: "kpd", description: "Koba" },
  { subtag: "kpe", description: "Kpelle" },
  { subtag: "kpf", description: "Komba" },
  { subtag: "kpg", description: "Kapingamarangi" },
  { subtag: "kph", description: "Kplang" },
  { subtag: "kpi", description: "Kofei" },
  { subtag: "kpj", description: "Karajá" },
  { subtag: "kpk", description: "Kpan" },
  { subtag: "kpl", description: "Kpala" },
  { subtag: "kpm", description: "Koho" },
  { subtag: "kpn", description: "Kepkiriwát" },
  { subtag: "kpo", description: "Ikposo" },
  { subtag: "kpq", description: "Korupun-Sela" },
  { subtag: "kpr", description: "Korafe-Yegha" },
  { subtag: "kps", description: "Tehit" },
  { subtag: "kpt", description: "Karata" },
  { subtag: "kpu", description: "Kafoa" },
  { subtag: "kpv", description: "Komi-Zyrian" },
  { subtag: "kpw", description: "Kobon" },
  { subtag: "kpx", description: "Mountain Koiali" },
  { subtag: "kpy", description: "Koryak" },
  { subtag: "kpz", description: "Kupsabiny" },
  { subtag: "kqa", description: "Mum" },
  { subtag: "kqb", description: "Kovai" },
  { subtag: "kqc", description: "Doromu-Koki" },
  { subtag: "kqd", description: "Koy Sanjaq Surat" },
  { subtag: "kqe", description: "Kalagan" },
  { subtag: "kqf", description: "Kakabai" },
  { subtag: "kqg", description: "Khe" },
  { subtag: "kqh", description: "Kisankasa" },
  { subtag: "kqi", description: "Koitabu" },
  { subtag: "kqj", description: "Koromira" },
  { subtag: "kqk", description: "Kotafon Gbe" },
  { subtag: "kql", description: "Kyenele" },
  { subtag: "kqm", description: "Khisa" },
  { subtag: "kqn", description: "Kaonde" },
  { subtag: "kqo", description: "Eastern Krahn" },
  { subtag: "kqp", description: "Kimré" },
  { subtag: "kqq", description: "Krenak" },
  { subtag: "kqr", description: "Kimaragang" },
  { subtag: "kqs", description: "Northern Kissi" },
  { subtag: "kqt", description: "Klias River Kadazan" },
  { subtag: "kqu", description: "Seroa" },
  { subtag: "kqv", description: "Okolod" },
  { subtag: "kqw", description: "Kandas" },
  { subtag: "kqx", description: "Mser" },
  { subtag: "kqy", description: "Koorete" },
  { subtag: "kqz", description: "Korana" },
  { subtag: "kra", description: "Kumhali" },
  { subtag: "krb", description: "Karkin" },
  { subtag: "krc", description: "Karachay-Balkar" },
  { subtag: "krd", description: "Kairui-Midiki" },
  { subtag: "kre", description: "Panará" },
  { subtag: "krf", description: "Koro (Vanuatu)" },
  { subtag: "krh", description: "Kurama" },
  { subtag: "kri", description: "Krio" },
  { subtag: "krj", description: "Kinaray-A" },
  { subtag: "krk", description: "Kerek" },
  { subtag: "krl", description: "Karelian" },
  { subtag: "krn", description: "Sapo" },
  { subtag: "kro", description: "Kru languages" },
  { subtag: "krp", description: "Korop" },
  { subtag: "krr", description: "Krung" },
  { subtag: "krs", description: "Gbaya (Sudan)" },
  { subtag: "krt", description: "Tumari Kanuri" },
  { subtag: "kru", description: "Kurukh" },
  { subtag: "krv", description: "Kavet" },
  { subtag: "krw", description: "Western Krahn" },
  { subtag: "krx", description: "Karon" },
  { subtag: "kry", description: "Kryts" },
  { subtag: "krz", description: "Sota Kanum" },
  { subtag: "ksa", description: "Shuwa-Zamani" },
  { subtag: "ksb", description: "Shambala" },
  { subtag: "ksc", description: "Southern Kalinga" },
  { subtag: "ksd", description: "Kuanua" },
  { subtag: "kse", description: "Kuni" },
  { subtag: "ksf", description: "Bafia" },
  { subtag: "ksg", description: "Kusaghe" },
  { subtag: "ksh", description: "Kölsch" },
  { subtag: "ksi", description: "Krisa, I'saka" },
  { subtag: "ksj", description: "Uare" },
  { subtag: "ksk", description: "Kansa" },
  { subtag: "ksl", description: "Kumalu" },
  { subtag: "ksm", description: "Kumba" },
  { subtag: "ksn", description: "Kasiguranin" },
  { subtag: "kso", description: "Kofa" },
  { subtag: "ksp", description: "Kaba" },
  { subtag: "ksq", description: "Kwaami" },
  { subtag: "ksr", description: "Borong" },
  { subtag: "kss", description: "Southern Kisi" },
  { subtag: "kst", description: "Winyé" },
  { subtag: "ksu", description: "Khamyang" },
  { subtag: "ksv", description: "Kusu" },
  { subtag: "ksw", description: "S'gaw Karen" },
  { subtag: "ksx", description: "Kedang" },
  { subtag: "ksy", description: "Kharia Thar" },
  { subtag: "ksz", description: "Kodaku" },
  { subtag: "kta", description: "Katua" },
  { subtag: "ktb", description: "Kambaata" },
  { subtag: "ktc", description: "Kholok" },
  { subtag: "ktd", description: "Kokata, Kukatha" },
  { subtag: "kte", description: "Nubri" },
  { subtag: "ktf", description: "Kwami" },
  { subtag: "ktg", description: "Kalkutung" },
  { subtag: "kth", description: "Karanga" },
  { subtag: "kti", description: "North Muyu" },
  { subtag: "ktj", description: "Plapo Krumen" },
  { subtag: "ktk", description: "Kaniet" },
  { subtag: "ktl", description: "Koroshi" },
  { subtag: "ktm", description: "Kurti" },
  { subtag: "ktn", description: "Karitiâna" },
  { subtag: "kto", description: "Kuot" },
  { subtag: "ktp", description: "Kaduo" },
  { subtag: "ktq", description: "Katabaga" },
  { subtag: "kts", description: "South Muyu" },
  { subtag: "ktt", description: "Ketum" },
  { subtag: "ktu", description: "Kituba (Democratic Republic of Congo)" },
  { subtag: "ktv", description: "Eastern Katu" },
  { subtag: "ktw", description: "Kato" },
  { subtag: "ktx", description: "Kaxararí" },
  { subtag: "kty", description: "Kango (Bas-Uélé District)" },
  { subtag: "ktz", description: "Juǀʼhoan, Juǀʼhoansi" },
  { subtag: "kub", description: "Kutep" },
  { subtag: "kuc", description: "Kwinsu" },
  { subtag: "kud", description: "'Auhelawa" },
  { subtag: "kue", description: "Kuman (Papua New Guinea)" },
  { subtag: "kuf", description: "Western Katu" },
  { subtag: "kug", description: "Kupa" },
  { subtag: "kuh", description: "Kushi" },
  { subtag: "kui", description: "Kuikúro-Kalapálo, Kalapalo" },
  { subtag: "kuj", description: "Kuria" },
  { subtag: "kuk", description: "Kepo'" },
  { subtag: "kul", description: "Kulere" },
  { subtag: "kum", description: "Kumyk" },
  { subtag: "kun", description: "Kunama" },
  { subtag: "kuo", description: "Kumukio" },
  { subtag: "kup", description: "Kunimaipa" },
  { subtag: "kuq", description: "Karipuna" },
  { subtag: "kus", description: "Kusaal" },
  { subtag: "kut", description: "Kutenai" },
  { subtag: "kuu", description: "Upper Kuskokwim" },
  { subtag: "kuv", description: "Kur" },
  { subtag: "kuw", description: "Kpagua" },
  { subtag: "kux", description: "Kukatja" },
  { subtag: "kuy", description: "Kuuku-Ya'u" },
  { subtag: "kuz", description: "Kunza" },
  { subtag: "kva", description: "Bagvalal" },
  { subtag: "kvb", description: "Kubu" },
  { subtag: "kvc", description: "Kove" },
  { subtag: "kvd", description: "Kui (Indonesia)" },
  { subtag: "kve", description: "Kalabakan" },
  { subtag: "kvf", description: "Kabalai" },
  { subtag: "kvg", description: "Kuni-Boazi" },
  { subtag: "kvh", description: "Komodo" },
  { subtag: "kvi", description: "Kwang" },
  { subtag: "kvj", description: "Psikye" },
  { subtag: "kvk", description: "Korean Sign Language" },
  { subtag: "kvl", description: "Kayaw" },
  { subtag: "kvm", description: "Kendem" },
  { subtag: "kvn", description: "Border Kuna" },
  { subtag: "kvo", description: "Dobel" },
  { subtag: "kvp", description: "Kompane" },
  { subtag: "kvq", description: "Geba Karen" },
  { subtag: "kvr", description: "Kerinci" },
  { subtag: "kvt", description: "Lahta Karen, Lahta" },
  { subtag: "kvu", description: "Yinbaw Karen" },
  { subtag: "kvv", description: "Kola" },
  { subtag: "kvw", description: "Wersing" },
  { subtag: "kvx", description: "Parkari Koli" },
  { subtag: "kvy", description: "Yintale Karen, Yintale" },
  { subtag: "kvz", description: "Tsakwambo, Tsaukambo" },
  { subtag: "kwa", description: "Dâw" },
  { subtag: "kwb", description: "Kwa" },
  { subtag: "kwc", description: "Likwala" },
  { subtag: "kwd", description: "Kwaio" },
  { subtag: "kwe", description: "Kwerba" },
  { subtag: "kwf", description: "Kwara'ae" },
  { subtag: "kwg", description: "Sara Kaba Deme" },
  { subtag: "kwh", description: "Kowiai" },
  { subtag: "kwi", description: "Awa-Cuaiquer" },
  { subtag: "kwj", description: "Kwanga" },
  { subtag: "kwk", description: "Kwakiutl" },
  { subtag: "kwl", description: "Kofyar" },
  { subtag: "kwm", description: "Kwambi" },
  { subtag: "kwn", description: "Kwangali" },
  { subtag: "kwo", description: "Kwomtari" },
  { subtag: "kwp", description: "Kodia" },
  { subtag: "kwr", description: "Kwer" },
  { subtag: "kws", description: "Kwese" },
  { subtag: "kwt", description: "Kwesten" },
  { subtag: "kwu", description: "Kwakum" },
  { subtag: "kwv", description: "Sara Kaba Náà" },
  { subtag: "kww", description: "Kwinti" },
  { subtag: "kwx", description: "Khirwar" },
  { subtag: "kwy", description: "San Salvador Kongo" },
  { subtag: "kwz", description: "Kwadi" },
  { subtag: "kxa", description: "Kairiru" },
  { subtag: "kxb", description: "Krobu" },
  { subtag: "kxc", description: "Konso, Khonso" },
  { subtag: "kxd", description: "Brunei" },
  { subtag: "kxf", description: "Manumanaw Karen, Manumanaw" },
  { subtag: "kxh", description: "Karo (Ethiopia)" },
  { subtag: "kxi", description: "Keningau Murut" },
  { subtag: "kxj", description: "Kulfa" },
  { subtag: "kxk", description: "Zayein Karen" },
  { subtag: "kxm", description: "Northern Khmer" },
  { subtag: "kxn", description: "Kanowit-Tanjong Melanau" },
  { subtag: "kxo", description: "Kanoé" },
  { subtag: "kxp", description: "Wadiyara Koli" },
  { subtag: "kxq", description: "Smärky Kanum" },
  { subtag: "kxr", description: "Koro (Papua New Guinea)" },
  { subtag: "kxs", description: "Kangjia" },
  { subtag: "kxt", description: "Koiwat" },
  { subtag: "kxv", description: "Kuvi" },
  { subtag: "kxw", description: "Konai" },
  { subtag: "kxx", description: "Likuba" },
  { subtag: "kxy", description: "Kayong" },
  { subtag: "kxz", description: "Kerewo" },
  { subtag: "kya", description: "Kwaya" },
  { subtag: "kyb", description: "Butbut Kalinga" },
  { subtag: "kyc", description: "Kyaka" },
  { subtag: "kyd", description: "Karey" },
  { subtag: "kye", description: "Krache" },
  { subtag: "kyf", description: "Kouya" },
  { subtag: "kyg", description: "Keyagana" },
  { subtag: "kyh", description: "Karok" },
  { subtag: "kyi", description: "Kiput" },
  { subtag: "kyj", description: "Karao" },
  { subtag: "kyk", description: "Kamayo" },
  { subtag: "kyl", description: "Kalapuya" },
  { subtag: "kym", description: "Kpatili" },
  { subtag: "kyn", description: "Northern Binukidnon" },
  { subtag: "kyo", description: "Kelon" },
  { subtag: "kyp", description: "Kang" },
  { subtag: "kyq", description: "Kenga" },
  { subtag: "kyr", description: "Kuruáya" },
  { subtag: "kys", description: "Baram Kayan" },
  { subtag: "kyt", description: "Kayagar" },
  { subtag: "kyu", description: "Western Kayah" },
  { subtag: "kyv", description: "Kayort" },
  { subtag: "kyw", description: "Kudmali" },
  { subtag: "kyx", description: "Rapoisi" },
  { subtag: "kyy", description: "Kambaira" },
  { subtag: "kyz", description: "Kayabí" },
  { subtag: "kza", description: "Western Karaboro" },
  { subtag: "kzb", description: "Kaibobo" },
  { subtag: "kzc", description: "Bondoukou Kulango" },
  { subtag: "kzd", description: "Kadai" },
  { subtag: "kze", description: "Kosena" },
  { subtag: "kzf", description: "Da'a Kaili" },
  { subtag: "kzg", description: "Kikai" },
  { subtag: "kzi", description: "Kelabit" },
  { subtag: "kzk", description: "Kazukuru" },
  { subtag: "kzl", description: "Kayeli" },
  { subtag: "kzm", description: "Kais" },
  { subtag: "kzn", description: "Kokola" },
  { subtag: "kzo", description: "Kaningi" },
  { subtag: "kzp", description: "Kaidipang" },
  { subtag: "kzq", description: "Kaike" },
  { subtag: "kzr", description: "Karang" },
  { subtag: "kzs", description: "Sugut Dusun" },
  { subtag: "kzu", description: "Kayupulau" },
  { subtag: "kzv", description: "Komyandaret" },
  { subtag: "kzw", description: "Karirí-Xocó" },
  { subtag: "kzx", description: "Kamarian" },
  { subtag: "kzy", description: "Kango (Tshopo District)" },
  { subtag: "kzz", description: "Kalabra" },
  { subtag: "laa", description: "Southern Subanen" },
  { subtag: "lab", description: "Linear A" },
  { subtag: "lac", description: "Lacandon" },
  { subtag: "lad", description: "Ladino" },
  { subtag: "lae", description: "Pattani" },
  { subtag: "laf", description: "Lafofa" },
  { subtag: "lag", description: "Langi" },
  { subtag: "lah", description: "Lahnda" },
  { subtag: "lai", description: "Lambya" },
  { subtag: "laj", description: "Lango (Uganda)" },
  { subtag: "lal", description: "Lalia" },
  { subtag: "lam", description: "Lamba" },
  { subtag: "lan", description: "Laru" },
  { subtag: "lap", description: "Laka (Chad)" },
  { subtag: "laq", description: "Qabiao" },
  { subtag: "lar", description: "Larteh" },
  { subtag: "las", description: "Lama (Togo)" },
  { subtag: "lau", description: "Laba" },
  { subtag: "law", description: "Lauje" },
  { subtag: "lax", description: "Tiwa" },
  { subtag: "lay", description: "Lama Bai" },
  { subtag: "laz", description: "Aribwatsa" },
  { subtag: "lbb", description: "Label" },
  { subtag: "lbc", description: "Lakkia" },
  { subtag: "lbe", description: "Lak" },
  { subtag: "lbf", description: "Tinani" },
  { subtag: "lbg", description: "Laopang" },
  { subtag: "lbi", description: "La'bi" },
  { subtag: "lbj", description: "Ladakhi" },
  { subtag: "lbk", description: "Central Bontok" },
  { subtag: "lbl", description: "Libon Bikol" },
  { subtag: "lbm", description: "Lodhi" },
  { subtag: "lbn", description: "Rmeet" },
  { subtag: "lbo", description: "Laven" },
  { subtag: "lbq", description: "Wampar" },
  { subtag: "lbr", description: "Lohorung" },
  { subtag: "lbs", description: "Libyan Sign Language" },
  { subtag: "lbt", description: "Lachi" },
  { subtag: "lbu", description: "Labu" },
  { subtag: "lbv", description: "Lavatbura-Lamusong" },
  { subtag: "lbw", description: "Tolaki" },
  { subtag: "lbx", description: "Lawangan" },
  { subtag: "lby", description: "Lamalama, Lamu-Lamu" },
  { subtag: "lbz", description: "Lardil" },
  { subtag: "lcc", description: "Legenyem" },
  { subtag: "lcd", description: "Lola" },
  { subtag: "lce", description: "Loncong, Sekak" },
  { subtag: "lcf", description: "Lubu" },
  { subtag: "lch", description: "Luchazi" },
  { subtag: "lcl", description: "Lisela" },
  { subtag: "lcm", description: "Tungag" },
  { subtag: "lcp", description: "Western Lawa" },
  { subtag: "lcq", description: "Luhu" },
  { subtag: "lcs", description: "Lisabata-Nuniali" },
  { subtag: "lda", description: "Kla-Dan" },
  { subtag: "ldb", description: "Dũya" },
  { subtag: "ldd", description: "Luri" },
  { subtag: "ldg", description: "Lenyima" },
  { subtag: "ldh", description: "Lamja-Dengsa-Tola" },
  { subtag: "ldi", description: "Laari" },
  { subtag: "ldj", description: "Lemoro" },
  { subtag: "ldk", description: "Leelau" },
  { subtag: "ldl", description: "Kaan" },
  { subtag: "ldm", description: "Landoma" },
  { subtag: "ldn", description: "Láadan" },
  { subtag: "ldo", description: "Loo" },
  { subtag: "ldp", description: "Tso" },
  { subtag: "ldq", description: "Lufu" },
  { subtag: "lea", description: "Lega-Shabunda" },
  { subtag: "leb", description: "Lala-Bisa" },
  { subtag: "lec", description: "Leco" },
  { subtag: "led", description: "Lendu" },
  { subtag: "lee", description: "Lyélé" },
  { subtag: "lef", description: "Lelemi" },
  { subtag: "leh", description: "Lenje" },
  { subtag: "lei", description: "Lemio" },
  { subtag: "lej", description: "Lengola" },
  { subtag: "lek", description: "Leipon" },
  { subtag: "lel", description: "Lele (Democratic Republic of Congo)" },
  { subtag: "lem", description: "Nomaande" },
  { subtag: "len", description: "Lenca" },
  { subtag: "leo", description: "Leti (Cameroon)" },
  { subtag: "lep", description: "Lepcha" },
  { subtag: "leq", description: "Lembena" },
  { subtag: "ler", description: "Lenkau" },
  { subtag: "les", description: "Lese" },
  { subtag: "let", description: "Lesing-Gelimi, Amio-Gelimi" },
  { subtag: "leu", description: "Kara (Papua New Guinea)" },
  { subtag: "lev", description: "Lamma" },
  { subtag: "lew", description: "Ledo Kaili" },
  { subtag: "lex", description: "Luang" },
  { subtag: "ley", description: "Lemolang" },
  { subtag: "lez", description: "Lezghian" },
  { subtag: "lfa", description: "Lefa" },
  { subtag: "lfn", description: "Lingua Franca Nova" },
  { subtag: "lga", description: "Lungga" },
  { subtag: "lgb", description: "Laghu" },
  { subtag: "lgg", description: "Lugbara" },
  { subtag: "lgh", description: "Laghuu" },
  { subtag: "lgi", description: "Lengilu" },
  { subtag: "lgk", description: "Lingarak, Neverver" },
  { subtag: "lgl", description: "Wala" },
  { subtag: "lgm", description: "Lega-Mwenga" },
  { subtag: "lgn", description: "T'apo, Opuuo" },
  { subtag: "lgo", description: "Lango (South Sudan)" },
  { subtag: "lgq", description: "Logba" },
  { subtag: "lgr", description: "Lengo" },
  { subtag: "lgt", description: "Pahi" },
  { subtag: "lgu", description: "Longgu" },
  { subtag: "lgz", description: "Ligenza" },
  { subtag: "lha", description: "Laha (Viet Nam)" },
  { subtag: "lhh", description: "Laha (Indonesia)" },
  { subtag: "lhi", description: "Lahu Shi" },
  { subtag: "lhl", description: "Lahul Lohar" },
  { subtag: "lhm", description: "Lhomi" },
  { subtag: "lhn", description: "Lahanan" },
  { subtag: "lhp", description: "Lhokpu" },
  { subtag: "lhs", description: "Mlahsö" },
  { subtag: "lht", description: "Lo-Toga" },
  { subtag: "lhu", description: "Lahu" },
  { subtag: "lia", description: "West-Central Limba" },
  { subtag: "lib", description: "Likum" },
  { subtag: "lic", description: "Hlai" },
  { subtag: "lid", description: "Nyindrou" },
  { subtag: "lie", description: "Likila" },
  { subtag: "lif", description: "Limbu" },
  { subtag: "lig", description: "Ligbi" },
  { subtag: "lih", description: "Lihir" },
  { subtag: "lij", description: "Ligurian" },
  { subtag: "lik", description: "Lika" },
  { subtag: "lil", description: "Lillooet" },
  { subtag: "lio", description: "Liki" },
  { subtag: "lip", description: "Sekpele" },
  { subtag: "liq", description: "Libido" },
  { subtag: "lir", description: "Liberian English" },
  { subtag: "lis", description: "Lisu" },
  { subtag: "liu", description: "Logorik" },
  { subtag: "liv", description: "Liv" },
  { subtag: "liw", description: "Col" },
  { subtag: "lix", description: "Liabuku" },
  { subtag: "liy", description: "Banda-Bambari" },
  { subtag: "liz", description: "Libinza" },
  { subtag: "lja", description: "Golpa" },
  { subtag: "lje", description: "Rampi" },
  { subtag: "lji", description: "Laiyolo" },
  { subtag: "ljl", description: "Li'o" },
  { subtag: "ljp", description: "Lampung Api" },
  { subtag: "ljw", description: "Yirandali" },
  { subtag: "ljx", description: "Yuru" },
  { subtag: "lka", description: "Lakalei" },
  { subtag: "lkb", description: "Kabras, Lukabaras" },
  { subtag: "lkc", description: "Kucong" },
  { subtag: "lkd", description: "Lakondê" },
  { subtag: "lke", description: "Kenyi" },
  { subtag: "lkh", description: "Lakha" },
  { subtag: "lki", description: "Laki" },
  { subtag: "lkj", description: "Remun" },
  { subtag: "lkl", description: "Laeko-Libuat" },
  { subtag: "lkm", description: "Kalaamaya" },
  { subtag: "lkn", description: "Lakon, Vure" },
  { subtag: "lko", description: "Khayo, Olukhayo" },
  { subtag: "lkr", description: "Päri" },
  { subtag: "lks", description: "Kisa, Olushisa" },
  { subtag: "lkt", description: "Lakota" },
  { subtag: "lku", description: "Kungkari" },
  { subtag: "lky", description: "Lokoya" },
  { subtag: "lla", description: "Lala-Roba" },
  { subtag: "llb", description: "Lolo" },
  { subtag: "llc", description: "Lele (Guinea)" },
  { subtag: "lld", description: "Ladin" },
  { subtag: "lle", description: "Lele (Papua New Guinea)" },
  { subtag: "llf", description: "Hermit" },
  { subtag: "llg", description: "Lole" },
  { subtag: "llh", description: "Lamu" },
  { subtag: "lli", description: "Teke-Laali" },
  { subtag: "llj", description: "Ladji Ladji" },
  { subtag: "llk", description: "Lelak" },
  { subtag: "lll", description: "Lilau" },
  { subtag: "llm", description: "Lasalimu" },
  { subtag: "lln", description: "Lele (Chad)" },
  { subtag: "llp", description: "North Efate" },
  { subtag: "llq", description: "Lolak" },
  { subtag: "lls", description: "Lithuanian Sign Language" },
  { subtag: "llu", description: "Lau" },
  { subtag: "llx", description: "Lauan" },
  { subtag: "lma", description: "East Limba" },
  { subtag: "lmb", description: "Merei" },
  { subtag: "lmc", description: "Limilngan" },
  { subtag: "lmd", description: "Lumun" },
  { subtag: "lme", description: "Pévé" },
  { subtag: "lmf", description: "South Lembata" },
  { subtag: "lmg", description: "Lamogai" },
  { subtag: "lmh", description: "Lambichhong" },
  { subtag: "lmi", description: "Lombi" },
  { subtag: "lmj", description: "West Lembata" },
  { subtag: "lmk", description: "Lamkang" },
  { subtag: "lml", description: "Hano" },
  { subtag: "lmn", description: "Lambadi" },
  { subtag: "lmo", description: "Lombard" },
  { subtag: "lmp", description: "Limbum" },
  { subtag: "lmq", description: "Lamatuka" },
  { subtag: "lmr", description: "Lamalera" },
  { subtag: "lmu", description: "Lamenu" },
  { subtag: "lmv", description: "Lomaiviti" },
  { subtag: "lmw", description: "Lake Miwok" },
  { subtag: "lmx", description: "Laimbue" },
  { subtag: "lmy", description: "Lamboya" },
  { subtag: "lna", description: "Langbashe" },
  { subtag: "lnb", description: "Mbalanhu" },
  { subtag: "lnd", description: "Lundayeh, Lun Bawang" },
  { subtag: "lng", description: "Langobardic" },
  { subtag: "lnh", description: "Lanoh" },
  { subtag: "lni", description: "Daantanai'" },
  { subtag: "lnj", description: "Leningitij" },
  { subtag: "lnl", description: "South Central Banda" },
  { subtag: "lnm", description: "Langam" },
  { subtag: "lnn", description: "Lorediakarkar" },
  { subtag: "lns", description: "Lamnso'" },
  { subtag: "lnu", description: "Longuda" },
  { subtag: "lnw", description: "Lanima" },
  { subtag: "lnz", description: "Lonzo" },
  { subtag: "loa", description: "Loloda" },
  { subtag: "lob", description: "Lobi" },
  { subtag: "loc", description: "Inonhan" },
  { subtag: "loe", description: "Saluan" },
  { subtag: "lof", description: "Logol" },
  { subtag: "log", description: "Logo" },
  { subtag: "loh", description: "Narim" },
  { subtag: "loi", description: "Loma (Côte d'Ivoire)" },
  { subtag: "loj", description: "Lou" },
  { subtag: "lok", description: "Loko" },
  { subtag: "lol", description: "Mongo" },
  { subtag: "lom", description: "Loma (Liberia)" },
  { subtag: "lon", description: "Malawi Lomwe" },
  { subtag: "loo", description: "Lombo" },
  { subtag: "lop", description: "Lopa" },
  { subtag: "loq", description: "Lobala" },
  { subtag: "lor", description: "Téén" },
  { subtag: "los", description: "Loniu" },
  { subtag: "lot", description: "Otuho" },
  { subtag: "lou", description: "Louisiana Creole" },
  { subtag: "lov", description: "Lopi" },
  { subtag: "low", description: "Tampias Lobu" },
  { subtag: "lox", description: "Loun" },
  { subtag: "loy", description: "Loke" },
  { subtag: "loz", description: "Lozi" },
  { subtag: "lpa", description: "Lelepa" },
  { subtag: "lpe", description: "Lepki" },
  { subtag: "lpn", description: "Long Phuri Naga" },
  { subtag: "lpo", description: "Lipo" },
  { subtag: "lpx", description: "Lopit" },
  { subtag: "lqr", description: "Logir" },
  { subtag: "lra", description: "Rara Bakati'" },
  { subtag: "lrc", description: "Northern Luri" },
  { subtag: "lre", description: "Laurentian" },
  { subtag: "lrg", description: "Laragia" },
  { subtag: "lri", description: "Marachi, Olumarachi" },
  { subtag: "lrk", description: "Loarki" },
  { subtag: "lrl", description: "Lari" },
  { subtag: "lrm", description: "Marama, Olumarama" },
  { subtag: "lrn", description: "Lorang" },
  { subtag: "lro", description: "Laro" },
  { subtag: "lrr", description: "Southern Yamphu" },
  { subtag: "lrt", description: "Larantuka Malay" },
  { subtag: "lrv", description: "Larevat" },
  { subtag: "lrz", description: "Lemerig" },
  { subtag: "lsa", description: "Lasgerdi" },
  { subtag: "lsb", description: "Burundian Sign Language, Langue des Signes Burundaise" },
  { subtag: "lsc", description: "Albarradas Sign Language, Lengua de señas Albarradas" },
  { subtag: "lsd", description: "Lishana Deni" },
  { subtag: "lse", description: "Lusengo" },
  { subtag: "lsh", description: "Lish" },
  { subtag: "lsi", description: "Lashi" },
  { subtag: "lsl", description: "Latvian Sign Language" },
  { subtag: "lsm", description: "Saamia, Olusamia" },
  { subtag: "lsn", description: "Tibetan Sign Language" },
  { subtag: "lso", description: "Laos Sign Language" },
  { subtag: "lsp", description: "Panamanian Sign Language, Lengua de Señas Panameñas" },
  { subtag: "lsr", description: "Aruop" },
  { subtag: "lss", description: "Lasi" },
  { subtag: "lst", description: "Trinidad and Tobago Sign Language" },
  { subtag: "lsv", description: "Sivia Sign Language" },
  {
    subtag: "lsw",
    description: "Seychelles Sign Language, Lalang Siny Seselwa, Langue des Signes Seychelloise",
  },
  { subtag: "lsy", description: "Mauritian Sign Language" },
  { subtag: "ltc", description: "Late Middle Chinese" },
  { subtag: "ltg", description: "Latgalian" },
  { subtag: "lth", description: "Thur" },
  { subtag: "lti", description: "Leti (Indonesia)" },
  { subtag: "ltn", description: "Latundê" },
  { subtag: "lto", description: "Tsotso, Olutsotso" },
  { subtag: "lts", description: "Tachoni, Lutachoni" },
  { subtag: "ltu", description: "Latu" },
  { subtag: "lua", description: "Luba-Lulua" },
  { subtag: "luc", description: "Aringa" },
  { subtag: "lud", description: "Ludian" },
  { subtag: "lue", description: "Luvale" },
  { subtag: "luf", description: "Laua" },
  { subtag: "lui", description: "Luiseno" },
  { subtag: "luj", description: "Luna" },
  { subtag: "luk", description: "Lunanakha" },
  { subtag: "lul", description: "Olu'bo" },
  { subtag: "lum", description: "Luimbi" },
  { subtag: "lun", description: "Lunda" },
  { subtag: "luo", description: "Luo (Kenya and Tanzania), Dholuo" },
  { subtag: "lup", description: "Lumbu" },
  { subtag: "luq", description: "Lucumi" },
  { subtag: "lur", description: "Laura" },
  { subtag: "lus", description: "Lushai" },
  { subtag: "lut", description: "Lushootseed" },
  { subtag: "luu", description: "Lumba-Yakkha" },
  { subtag: "luv", description: "Luwati" },
  { subtag: "luw", description: "Luo (Cameroon)" },
  { subtag: "luy", description: "Luyia, Oluluyia" },
  { subtag: "luz", description: "Southern Luri" },
  { subtag: "lva", description: "Maku'a" },
  { subtag: "lvi", description: "Lavi" },
  { subtag: "lvk", description: "Lavukaleve" },
  { subtag: "lvs", description: "Standard Latvian" },
  { subtag: "lvu", description: "Levuka" },
  { subtag: "lwa", description: "Lwalu" },
  { subtag: "lwe", description: "Lewo Eleng" },
  { subtag: "lwg", description: "Wanga, Oluwanga" },
  { subtag: "lwh", description: "White Lachi" },
  { subtag: "lwl", description: "Eastern Lawa" },
  { subtag: "lwm", description: "Laomian" },
  { subtag: "lwo", description: "Luwo" },
  { subtag: "lws", description: "Malawian Sign Language" },
  { subtag: "lwt", description: "Lewotobi" },
  { subtag: "lwu", description: "Lawu" },
  { subtag: "lww", description: "Lewo" },
  { subtag: "lxm", description: "Lakurumau" },
  { subtag: "lya", description: "Layakha" },
  { subtag: "lyg", description: "Lyngngam" },
  { subtag: "lyn", description: "Luyana" },
  { subtag: "lzh", description: "Literary Chinese" },
  { subtag: "lzl", description: "Litzlitz" },
  { subtag: "lzn", description: "Leinong Naga" },
  { subtag: "lzz", description: "Laz" },
  { subtag: "maa", description: "San Jerónimo Tecóatl Mazatec" },
  { subtag: "mab", description: "Yutanduchi Mixtec" },
  { subtag: "mad", description: "Madurese" },
  { subtag: "mae", description: "Bo-Rukul" },
  { subtag: "maf", description: "Mafa" },
  { subtag: "mag", description: "Magahi" },
  { subtag: "mai", description: "Maithili" },
  { subtag: "maj", description: "Jalapa De Díaz Mazatec" },
  { subtag: "mak", description: "Makasar" },
  { subtag: "mam", description: "Mam" },
  { subtag: "man", description: "Mandingo, Manding" },
  { subtag: "map", description: "Austronesian languages" },
  { subtag: "maq", description: "Chiquihuitlán Mazatec" },
  { subtag: "mas", description: "Masai" },
  { subtag: "mat", description: "San Francisco Matlatzinca" },
  { subtag: "mau", description: "Huautla Mazatec" },
  { subtag: "mav", description: "Sateré-Mawé" },
  { subtag: "maw", description: "Mampruli" },
  { subtag: "max", description: "North Moluccan Malay" },
  { subtag: "maz", description: "Central Mazahua" },
  { subtag: "mba", description: "Higaonon" },
  { subtag: "mbb", description: "Western Bukidnon Manobo" },
  { subtag: "mbc", description: "Macushi" },
  { subtag: "mbd", description: "Dibabawon Manobo" },
  { subtag: "mbe", description: "Molale" },
  { subtag: "mbf", description: "Baba Malay" },
  { subtag: "mbh", description: "Mangseng" },
  { subtag: "mbi", description: "Ilianen Manobo" },
  { subtag: "mbj", description: "Nadëb" },
  { subtag: "mbk", description: "Malol" },
  { subtag: "mbl", description: "Maxakalí" },
  { subtag: "mbm", description: "Ombamba" },
  { subtag: "mbn", description: "Macaguán" },
  { subtag: "mbo", description: "Mbo (Cameroon)" },
  { subtag: "mbp", description: "Malayo" },
  { subtag: "mbq", description: "Maisin" },
  { subtag: "mbr", description: "Nukak Makú" },
  { subtag: "mbs", description: "Sarangani Manobo" },
  { subtag: "mbt", description: "Matigsalug Manobo" },
  { subtag: "mbu", description: "Mbula-Bwazza" },
  { subtag: "mbv", description: "Mbulungish" },
  { subtag: "mbw", description: "Maring" },
  { subtag: "mbx", description: "Mari (East Sepik Province)" },
  { subtag: "mby", description: "Memoni" },
  { subtag: "mbz", description: "Amoltepec Mixtec" },
  { subtag: "mca", description: "Maca" },
  { subtag: "mcb", description: "Machiguenga" },
  { subtag: "mcc", description: "Bitur" },
  { subtag: "mcd", description: "Sharanahua" },
  { subtag: "mce", description: "Itundujia Mixtec" },
  { subtag: "mcf", description: "Matsés" },
  { subtag: "mcg", description: "Mapoyo" },
  { subtag: "mch", description: "Maquiritari" },
  { subtag: "mci", description: "Mese" },
  { subtag: "mcj", description: "Mvanip" },
  { subtag: "mck", description: "Mbunda" },
  { subtag: "mcl", description: "Macaguaje" },
  { subtag: "mcm", description: "Malaccan Creole Portuguese" },
  { subtag: "mcn", description: "Masana" },
  { subtag: "mco", description: "Coatlán Mixe" },
  { subtag: "mcp", description: "Makaa" },
  { subtag: "mcq", description: "Ese" },
  { subtag: "mcr", description: "Menya" },
  { subtag: "mcs", description: "Mambai" },
  { subtag: "mct", description: "Mengisa" },
  { subtag: "mcu", description: "Cameroon Mambila" },
  { subtag: "mcv", description: "Minanibai" },
  { subtag: "mcw", description: "Mawa (Chad)" },
  { subtag: "mcx", description: "Mpiemo" },
  { subtag: "mcy", description: "South Watut" },
  { subtag: "mcz", description: "Mawan" },
  { subtag: "mda", description: "Mada (Nigeria)" },
  { subtag: "mdb", description: "Morigi" },
  { subtag: "mdc", description: "Male (Papua New Guinea)" },
  { subtag: "mdd", description: "Mbum" },
  { subtag: "mde", description: "Maba (Chad)" },
  { subtag: "mdf", description: "Moksha" },
  { subtag: "mdg", description: "Massalat" },
  { subtag: "mdh", description: "Maguindanaon" },
  { subtag: "mdi", description: "Mamvu" },
  { subtag: "mdj", description: "Mangbetu" },
  { subtag: "mdk", description: "Mangbutu" },
  { subtag: "mdl", description: "Maltese Sign Language" },
  { subtag: "mdm", description: "Mayogo" },
  { subtag: "mdn", description: "Mbati" },
  { subtag: "mdp", description: "Mbala" },
  { subtag: "mdq", description: "Mbole" },
  { subtag: "mdr", description: "Mandar" },
  { subtag: "mds", description: "Maria (Papua New Guinea)" },
  { subtag: "mdt", description: "Mbere" },
  { subtag: "mdu", description: "Mboko" },
  { subtag: "mdv", description: "Santa Lucía Monteverde Mixtec" },
  { subtag: "mdw", description: "Mbosi" },
  { subtag: "mdx", description: "Dizin" },
  { subtag: "mdy", description: "Male (Ethiopia)" },
  { subtag: "mdz", description: "Suruí Do Pará" },
  { subtag: "mea", description: "Menka" },
  { subtag: "meb", description: "Ikobi" },
  { subtag: "mec", description: "Marra" },
  { subtag: "med", description: "Melpa" },
  { subtag: "mee", description: "Mengen" },
  { subtag: "mef", description: "Megam" },
  { subtag: "meh", description: "Southwestern Tlaxiaco Mixtec" },
  { subtag: "mei", description: "Midob" },
  { subtag: "mej", description: "Meyah" },
  { subtag: "mek", description: "Mekeo" },
  { subtag: "mel", description: "Central Melanau" },
  { subtag: "mem", description: "Mangala" },
  { subtag: "men", description: "Mende (Sierra Leone)" },
  { subtag: "meo", description: "Kedah Malay" },
  { subtag: "mep", description: "Miriwoong" },
  { subtag: "meq", description: "Merey" },
  { subtag: "mer", description: "Meru" },
  { subtag: "mes", description: "Masmaje" },
  { subtag: "met", description: "Mato" },
  { subtag: "meu", description: "Motu" },
  { subtag: "mev", description: "Mano" },
  { subtag: "mew", description: "Maaka" },
  { subtag: "mey", description: "Hassaniyya" },
  { subtag: "mez", description: "Menominee" },
  { subtag: "mfa", description: "Pattani Malay" },
  { subtag: "mfb", description: "Bangka" },
  { subtag: "mfc", description: "Mba" },
  { subtag: "mfd", description: "Mendankwe-Nkwen" },
  { subtag: "mfe", description: "Morisyen" },
  { subtag: "mff", description: "Naki" },
  { subtag: "mfg", description: "Mogofin" },
  { subtag: "mfh", description: "Matal" },
  { subtag: "mfi", description: "Wandala" },
  { subtag: "mfj", description: "Mefele" },
  { subtag: "mfk", description: "North Mofu" },
  { subtag: "mfl", description: "Putai" },
  { subtag: "mfm", description: "Marghi South" },
  { subtag: "mfn", description: "Cross River Mbembe" },
  { subtag: "mfo", description: "Mbe" },
  { subtag: "mfp", description: "Makassar Malay" },
  { subtag: "mfq", description: "Moba" },
  { subtag: "mfr", description: "Marrithiyel" },
  { subtag: "mfs", description: "Mexican Sign Language" },
  { subtag: "mft", description: "Mokerang" },
  { subtag: "mfu", description: "Mbwela" },
  { subtag: "mfv", description: "Mandjak" },
  { subtag: "mfw", description: "Mulaha" },
  { subtag: "mfx", description: "Melo" },
  { subtag: "mfy", description: "Mayo" },
  { subtag: "mfz", description: "Mabaan" },
  { subtag: "mga", description: "Middle Irish (900-1200)" },
  { subtag: "mgb", description: "Mararit" },
  { subtag: "mgc", description: "Morokodo" },
  { subtag: "mgd", description: "Moru" },
  { subtag: "mge", description: "Mango" },
  { subtag: "mgf", description: "Maklew" },
  { subtag: "mgg", description: "Mpumpong" },
  { subtag: "mgh", description: "Makhuwa-Meetto" },
  { subtag: "mgi", description: "Lijili" },
  { subtag: "mgj", description: "Abureni" },
  { subtag: "mgk", description: "Mawes" },
  { subtag: "mgl", description: "Maleu-Kilenge" },
  { subtag: "mgm", description: "Mambae" },
  { subtag: "mgn", description: "Mbangi" },
  { subtag: "mgo", description: "Meta'" },
  { subtag: "mgp", description: "Eastern Magar" },
  { subtag: "mgq", description: "Malila" },
  { subtag: "mgr", description: "Mambwe-Lungu" },
  { subtag: "mgs", description: "Manda (Tanzania)" },
  { subtag: "mgt", description: "Mongol" },
  { subtag: "mgu", description: "Mailu" },
  { subtag: "mgv", description: "Matengo" },
  { subtag: "mgw", description: "Matumbi" },
  { subtag: "mgy", description: "Mbunga" },
  { subtag: "mgz", description: "Mbugwe" },
  { subtag: "mha", description: "Manda (India)" },
  { subtag: "mhb", description: "Mahongwe" },
  { subtag: "mhc", description: "Mocho" },
  { subtag: "mhd", description: "Mbugu" },
  { subtag: "mhe", description: "Besisi, Mah Meri" },
  { subtag: "mhf", description: "Mamaa" },
  { subtag: "mhg", description: "Margu" },
  { subtag: "mhi", description: "Ma'di" },
  { subtag: "mhj", description: "Mogholi" },
  { subtag: "mhk", description: "Mungaka" },
  { subtag: "mhl", description: "Mauwake" },
  { subtag: "mhm", description: "Makhuwa-Moniga" },
  { subtag: "mhn", description: "Mócheno" },
  { subtag: "mho", description: "Mashi (Zambia)" },
  { subtag: "mhp", description: "Balinese Malay" },
  { subtag: "mhq", description: "Mandan" },
  { subtag: "mhr", description: "Eastern Mari" },
  { subtag: "mhs", description: "Buru (Indonesia)" },
  { subtag: "mht", description: "Mandahuaca" },
  { subtag: "mhu", description: "Digaro-Mishmi, Darang Deng" },
  { subtag: "mhw", description: "Mbukushu" },
  { subtag: "mhx", description: "Maru, Lhaovo" },
  { subtag: "mhy", description: "Ma'anyan" },
  { subtag: "mhz", description: "Mor (Mor Islands)" },
  { subtag: "mia", description: "Miami" },
  { subtag: "mib", description: "Atatláhuca Mixtec" },
  { subtag: "mic", description: "Mi'kmaq, Micmac" },
  { subtag: "mid", description: "Mandaic" },
  { subtag: "mie", description: "Ocotepec Mixtec" },
  { subtag: "mif", description: "Mofu-Gudur" },
  { subtag: "mig", description: "San Miguel El Grande Mixtec" },
  { subtag: "mih", description: "Chayuco Mixtec" },
  { subtag: "mii", description: "Chigmecatitlán Mixtec" },
  { subtag: "mij", description: "Abar, Mungbam" },
  { subtag: "mik", description: "Mikasuki" },
  { subtag: "mil", description: "Peñoles Mixtec" },
  { subtag: "mim", description: "Alacatlatzala Mixtec" },
  { subtag: "min", description: "Minangkabau" },
  { subtag: "mio", description: "Pinotepa Nacional Mixtec" },
  { subtag: "mip", description: "Apasco-Apoala Mixtec" },
  { subtag: "miq", description: "Mískito" },
  { subtag: "mir", description: "Isthmus Mixe" },
  { subtag: "mis", description: "Uncoded languages" },
  { subtag: "mit", description: "Southern Puebla Mixtec" },
  { subtag: "miu", description: "Cacaloxtepec Mixtec" },
  { subtag: "miw", description: "Akoye" },
  { subtag: "mix", description: "Mixtepec Mixtec" },
  { subtag: "miy", description: "Ayutla Mixtec" },
  { subtag: "miz", description: "Coatzospan Mixtec" },
  { subtag: "mjb", description: "Makalero" },
  { subtag: "mjc", description: "San Juan Colorado Mixtec" },
  { subtag: "mjd", description: "Northwest Maidu" },
  { subtag: "mje", description: "Muskum" },
  { subtag: "mjg", description: "Tu" },
  { subtag: "mjh", description: "Mwera (Nyasa)" },
  { subtag: "mji", description: "Kim Mun" },
  { subtag: "mjj", description: "Mawak" },
  { subtag: "mjk", description: "Matukar" },
  { subtag: "mjl", description: "Mandeali" },
  { subtag: "mjm", description: "Medebur" },
  { subtag: "mjn", description: "Ma (Papua New Guinea)" },
  { subtag: "mjo", description: "Malankuravan" },
  { subtag: "mjp", description: "Malapandaram" },
  { subtag: "mjq", description: "Malaryan" },
  { subtag: "mjr", description: "Malavedan" },
  { subtag: "mjs", description: "Miship" },
  { subtag: "mjt", description: "Sauria Paharia" },
  { subtag: "mju", description: "Manna-Dora" },
  { subtag: "mjv", description: "Mannan" },
  { subtag: "mjw", description: "Karbi" },
  { subtag: "mjx", description: "Mahali" },
  { subtag: "mjy", description: "Mahican" },
  { subtag: "mjz", description: "Majhi" },
  { subtag: "mka", description: "Mbre" },
  { subtag: "mkb", description: "Mal Paharia" },
  { subtag: "mkc", description: "Siliput" },
  { subtag: "mke", description: "Mawchi" },
  { subtag: "mkf", description: "Miya" },
  { subtag: "mkg", description: "Mak (China)" },
  { subtag: "mkh", description: "Mon-Khmer languages" },
  { subtag: "mki", description: "Dhatki" },
  { subtag: "mkj", description: "Mokilese" },
  { subtag: "mkk", description: "Byep" },
  { subtag: "mkl", description: "Mokole" },
  { subtag: "mkm", description: "Moklen" },
  { subtag: "mkn", description: "Kupang Malay" },
  { subtag: "mko", description: "Mingang Doso" },
  { subtag: "mkp", description: "Moikodi" },
  { subtag: "mkq", description: "Bay Miwok" },
  { subtag: "mkr", description: "Malas" },
  { subtag: "mks", description: "Silacayoapan Mixtec" },
  { subtag: "mkt", description: "Vamale" },
  { subtag: "mku", description: "Konyanka Maninka" },
  { subtag: "mkv", description: "Mafea" },
  { subtag: "mkw", description: "Kituba (Congo)" },
  { subtag: "mkx", description: "Kinamiging Manobo" },
  { subtag: "mky", description: "East Makian" },
  { subtag: "mkz", description: "Makasae" },
  { subtag: "mla", description: "Malo" },
  { subtag: "mlb", description: "Mbule" },
  { subtag: "mlc", description: "Cao Lan" },
  { subtag: "mle", description: "Manambu" },
  { subtag: "mlf", description: "Mal" },
  { subtag: "mlh", description: "Mape" },
  { subtag: "mli", description: "Malimpung" },
  { subtag: "mlj", description: "Miltu" },
  { subtag: "mlk", description: "Ilwana, Kiwilwana" },
  { subtag: "mll", description: "Malua Bay" },
  { subtag: "mlm", description: "Mulam" },
  { subtag: "mln", description: "Malango" },
  { subtag: "mlo", description: "Mlomp" },
  { subtag: "mlp", description: "Bargam" },
  { subtag: "mlq", description: "Western Maninkakan" },
  { subtag: "mlr", description: "Vame" },
  { subtag: "mls", description: "Masalit" },
  { subtag: "mlu", description: "To'abaita" },
  { subtag: "mlv", description: "Motlav, Mwotlap" },
  { subtag: "mlw", description: "Moloko" },
  { subtag: "mlx", description: "Malfaxal, Naha'ai" },
  { subtag: "mlz", description: "Malaynon" },
  { subtag: "mma", description: "Mama" },
  { subtag: "mmb", description: "Momina" },
  { subtag: "mmc", description: "Michoacán Mazahua" },
  { subtag: "mmd", description: "Maonan" },
  { subtag: "mme", description: "Mae" },
  { subtag: "mmf", description: "Mundat" },
  { subtag: "mmg", description: "North Ambrym" },
  { subtag: "mmh", description: "Mehináku" },
  { subtag: "mmi", description: "Musar" },
  { subtag: "mmj", description: "Majhwar" },
  { subtag: "mmk", description: "Mukha-Dora" },
  { subtag: "mml", description: "Man Met" },
  { subtag: "mmm", description: "Maii" },
  { subtag: "mmn", description: "Mamanwa" },
  { subtag: "mmo", description: "Mangga Buang" },
  { subtag: "mmp", description: "Siawi" },
  { subtag: "mmq", description: "Musak" },
  { subtag: "mmr", description: "Western Xiangxi Miao" },
  { subtag: "mmt", description: "Malalamai" },
  { subtag: "mmu", description: "Mmaala" },
  { subtag: "mmv", description: "Miriti" },
  { subtag: "mmw", description: "Emae" },
  { subtag: "mmx", description: "Madak" },
  { subtag: "mmy", description: "Migaama" },
  { subtag: "mmz", description: "Mabaale" },
  { subtag: "mna", description: "Mbula" },
  { subtag: "mnb", description: "Muna" },
  { subtag: "mnc", description: "Manchu" },
  { subtag: "mnd", description: "Mondé" },
  { subtag: "mne", description: "Naba" },
  { subtag: "mnf", description: "Mundani" },
  { subtag: "mng", description: "Eastern Mnong" },
  { subtag: "mnh", description: "Mono (Democratic Republic of Congo)" },
  { subtag: "mni", description: "Manipuri" },
  { subtag: "mnj", description: "Munji" },
  { subtag: "mnk", description: "Mandinka" },
  { subtag: "mnl", description: "Tiale" },
  { subtag: "mnm", description: "Mapena" },
  { subtag: "mnn", description: "Southern Mnong" },
  { subtag: "mno", description: "Manobo languages" },
  { subtag: "mnp", description: "Min Bei Chinese" },
  { subtag: "mnq", description: "Minriq" },
  { subtag: "mnr", description: "Mono (USA)" },
  { subtag: "mns", description: "Mansi" },
  { subtag: "mnu", description: "Mer" },
  { subtag: "mnv", description: "Rennell-Bellona" },
  { subtag: "mnw", description: "Mon" },
  { subtag: "mnx", description: "Manikion" },
  { subtag: "mny", description: "Manyawa" },
  { subtag: "mnz", description: "Moni" },
  { subtag: "moa", description: "Mwan" },
  { subtag: "moc", description: "Mocoví" },
  { subtag: "mod", description: "Mobilian" },
  { subtag: "moe", description: "Innu, Montagnais" },
  { subtag: "mog", description: "Mongondow" },
  { subtag: "moh", description: "Mohawk" },
  { subtag: "moi", description: "Mboi" },
  { subtag: "moj", description: "Monzombo" },
  { subtag: "mok", description: "Morori" },
  { subtag: "mom", description: "Mangue" },
  { subtag: "moo", description: "Monom" },
  { subtag: "mop", description: "Mopán Maya" },
  { subtag: "moq", description: "Mor (Bomberai Peninsula)" },
  { subtag: "mor", description: "Moro" },
  { subtag: "mos", description: "Mossi" },
  { subtag: "mot", description: "Barí" },
  { subtag: "mou", description: "Mogum" },
  { subtag: "mov", description: "Mohave" },
  { subtag: "mow", description: "Moi (Congo)" },
  { subtag: "mox", description: "Molima" },
  { subtag: "moy", description: "Shekkacho" },
  { subtag: "moz", description: "Mukulu, Gergiko" },
  { subtag: "mpa", description: "Mpoto" },
  { subtag: "mpb", description: "Malak Malak, Mullukmulluk" },
  { subtag: "mpc", description: "Mangarrayi" },
  { subtag: "mpd", description: "Machinere" },
  { subtag: "mpe", description: "Majang" },
  { subtag: "mpg", description: "Marba" },
  { subtag: "mph", description: "Maung" },
  { subtag: "mpi", description: "Mpade" },
  { subtag: "mpj", description: "Martu Wangka, Wangkajunga" },
  { subtag: "mpk", description: "Mbara (Chad)" },
  { subtag: "mpl", description: "Middle Watut" },
  { subtag: "mpm", description: "Yosondúa Mixtec" },
  { subtag: "mpn", description: "Mindiri" },
  { subtag: "mpo", description: "Miu" },
  { subtag: "mpp", description: "Migabac" },
  { subtag: "mpq", description: "Matís" },
  { subtag: "mpr", description: "Vangunu" },
  { subtag: "mps", description: "Dadibi" },
  { subtag: "mpt", description: "Mian" },
  { subtag: "mpu", description: "Makuráp" },
  { subtag: "mpv", description: "Mungkip" },
  { subtag: "mpw", description: "Mapidian" },
  { subtag: "mpx", description: "Misima-Panaeati" },
  { subtag: "mpy", description: "Mapia" },
  { subtag: "mpz", description: "Mpi" },
  { subtag: "mqa", description: "Maba (Indonesia)" },
  { subtag: "mqb", description: "Mbuko" },
  { subtag: "mqc", description: "Mangole" },
  { subtag: "mqe", description: "Matepi" },
  { subtag: "mqf", description: "Momuna" },
  { subtag: "mqg", description: "Kota Bangun Kutai Malay" },
  { subtag: "mqh", description: "Tlazoyaltepec Mixtec" },
  { subtag: "mqi", description: "Mariri" },
  { subtag: "mqj", description: "Mamasa" },
  { subtag: "mqk", description: "Rajah Kabunsuwan Manobo" },
  { subtag: "mql", description: "Mbelime" },
  { subtag: "mqm", description: "South Marquesan" },
  { subtag: "mqn", description: "Moronene" },
  { subtag: "mqo", description: "Modole" },
  { subtag: "mqp", description: "Manipa" },
  { subtag: "mqq", description: "Minokok" },
  { subtag: "mqr", description: "Mander" },
  { subtag: "mqs", description: "West Makian" },
  { subtag: "mqt", description: "Mok" },
  { subtag: "mqu", description: "Mandari" },
  { subtag: "mqv", description: "Mosimo" },
  { subtag: "mqw", description: "Murupi" },
  { subtag: "mqx", description: "Mamuju" },
  { subtag: "mqy", description: "Manggarai" },
  { subtag: "mqz", description: "Pano" },
  { subtag: "mra", description: "Mlabri" },
  { subtag: "mrb", description: "Marino" },
  { subtag: "mrc", description: "Maricopa" },
  { subtag: "mrd", description: "Western Magar" },
  { subtag: "mre", description: "Martha's Vineyard Sign Language" },
  { subtag: "mrf", description: "Elseng" },
  { subtag: "mrg", description: "Mising" },
  { subtag: "mrh", description: "Mara Chin" },
  { subtag: "mrj", description: "Western Mari" },
  { subtag: "mrk", description: "Hmwaveke" },
  { subtag: "mrl", description: "Mortlockese" },
  { subtag: "mrm", description: "Merlav, Mwerlap" },
  { subtag: "mrn", description: "Cheke Holo" },
  { subtag: "mro", description: "Mru" },
  { subtag: "mrp", description: "Morouas" },
  { subtag: "mrq", description: "North Marquesan" },
  { subtag: "mrr", description: "Maria (India)" },
  { subtag: "mrs", description: "Maragus" },
  { subtag: "mrt", description: "Marghi Central" },
  { subtag: "mru", description: "Mono (Cameroon)" },
  { subtag: "mrv", description: "Mangareva" },
  { subtag: "mrw", description: "Maranao" },
  { subtag: "mrx", description: "Maremgi, Dineor" },
  { subtag: "mry", description: "Mandaya" },
  { subtag: "mrz", description: "Marind" },
  { subtag: "msb", description: "Masbatenyo" },
  { subtag: "msc", description: "Sankaran Maninka" },
  { subtag: "msd", description: "Yucatec Maya Sign Language" },
  { subtag: "mse", description: "Musey" },
  { subtag: "msf", description: "Mekwei" },
  { subtag: "msg", description: "Moraid" },
  { subtag: "msh", description: "Masikoro Malagasy" },
  { subtag: "msi", description: "Sabah Malay" },
  { subtag: "msj", description: "Ma (Democratic Republic of Congo)" },
  { subtag: "msk", description: "Mansaka" },
  { subtag: "msl", description: "Molof, Poule" },
  { subtag: "msm", description: "Agusan Manobo" },
  { subtag: "msn", description: "Vurës" },
  { subtag: "mso", description: "Mombum" },
  { subtag: "msp", description: "Maritsauá" },
  { subtag: "msq", description: "Caac" },
  { subtag: "msr", description: "Mongolian Sign Language" },
  { subtag: "mss", description: "West Masela" },
  { subtag: "msu", description: "Musom" },
  { subtag: "msv", description: "Maslam" },
  { subtag: "msw", description: "Mansoanka" },
  { subtag: "msx", description: "Moresada" },
  { subtag: "msy", description: "Aruamu" },
  { subtag: "msz", description: "Momare" },
  { subtag: "mta", description: "Cotabato Manobo" },
  { subtag: "mtb", description: "Anyin Morofo" },
  { subtag: "mtc", description: "Munit" },
  { subtag: "mtd", description: "Mualang" },
  { subtag: "mte", description: "Mono (Solomon Islands)" },
  { subtag: "mtf", description: "Murik (Papua New Guinea)" },
  { subtag: "mtg", description: "Una" },
  { subtag: "mth", description: "Munggui" },
  { subtag: "mti", description: "Maiwa (Papua New Guinea)" },
  { subtag: "mtj", description: "Moskona" },
  { subtag: "mtk", description: "Mbe'" },
  { subtag: "mtl", description: "Montol" },
  { subtag: "mtm", description: "Mator" },
  { subtag: "mtn", description: "Matagalpa" },
  { subtag: "mto", description: "Totontepec Mixe" },
  { subtag: "mtp", description: "Wichí Lhamtés Nocten" },
  { subtag: "mtq", description: "Muong" },
  { subtag: "mtr", description: "Mewari" },
  { subtag: "mts", description: "Yora" },
  { subtag: "mtt", description: "Mota" },
  { subtag: "mtu", description: "Tututepec Mixtec" },
  { subtag: "mtv", description: "Asaro'o" },
  { subtag: "mtw", description: "Southern Binukidnon" },
  { subtag: "mtx", description: "Tidaá Mixtec" },
  { subtag: "mty", description: "Nabi" },
  { subtag: "mua", description: "Mundang" },
  { subtag: "mub", description: "Mubi" },
  { subtag: "muc", description: "Ajumbu" },
  { subtag: "mud", description: "Mednyj Aleut" },
  { subtag: "mue", description: "Media Lengua" },
  { subtag: "mug", description: "Musgu" },
  { subtag: "muh", description: "Mündü" },
  { subtag: "mui", description: "Musi" },
  { subtag: "muj", description: "Mabire" },
  { subtag: "muk", description: "Mugom" },
  { subtag: "mul", description: "Multiple languages" },
  { subtag: "mum", description: "Maiwala" },
  { subtag: "mun", description: "Munda languages" },
  { subtag: "muo", description: "Nyong" },
  { subtag: "mup", description: "Malvi" },
  { subtag: "muq", description: "Eastern Xiangxi Miao" },
  { subtag: "mur", description: "Murle" },
  { subtag: "mus", description: "Creek" },
  { subtag: "mut", description: "Western Muria" },
  { subtag: "muu", description: "Yaaku" },
  { subtag: "muv", description: "Muthuvan" },
  { subtag: "mux", description: "Bo-Ung" },
  { subtag: "muy", description: "Muyang" },
  { subtag: "muz", description: "Mursi" },
  { subtag: "mva", description: "Manam" },
  { subtag: "mvb", description: "Mattole" },
  { subtag: "mvd", description: "Mamboru" },
  { subtag: "mve", description: "Marwari (Pakistan)" },
  { subtag: "mvf", description: "Peripheral Mongolian" },
  { subtag: "mvg", description: "Yucuañe Mixtec" },
  { subtag: "mvh", description: "Mulgi" },
  { subtag: "mvi", description: "Miyako" },
  { subtag: "mvk", description: "Mekmek" },
  { subtag: "mvl", description: "Mbara (Australia)" },
  { subtag: "mvn", description: "Minaveha" },
  { subtag: "mvo", description: "Marovo" },
  { subtag: "mvp", description: "Duri" },
  { subtag: "mvq", description: "Moere" },
  { subtag: "mvr", description: "Marau" },
  { subtag: "mvs", description: "Massep" },
  { subtag: "mvt", description: "Mpotovoro" },
  { subtag: "mvu", description: "Marfa" },
  { subtag: "mvv", description: "Tagal Murut" },
  { subtag: "mvw", description: "Machinga" },
  { subtag: "mvx", description: "Meoswar" },
  { subtag: "mvy", description: "Indus Kohistani" },
  { subtag: "mvz", description: "Mesqan" },
  { subtag: "mwa", description: "Mwatebu" },
  { subtag: "mwb", description: "Juwal" },
  { subtag: "mwc", description: "Are" },
  { subtag: "mwe", description: "Mwera (Chimwera)" },
  { subtag: "mwf", description: "Murrinh-Patha" },
  { subtag: "mwg", description: "Aiklep" },
  { subtag: "mwh", description: "Mouk-Aria" },
  { subtag: "mwi", description: "Labo, Ninde" },
  { subtag: "mwk", description: "Kita Maninkakan" },
  { subtag: "mwl", description: "Mirandese" },
  { subtag: "mwm", description: "Sar" },
  { subtag: "mwn", description: "Nyamwanga" },
  { subtag: "mwo", description: "Central Maewo" },
  { subtag: "mwp", description: "Kala Lagaw Ya" },
  { subtag: "mwq", description: "Mün Chin" },
  { subtag: "mwr", description: "Marwari" },
  { subtag: "mws", description: "Mwimbi-Muthambi" },
  { subtag: "mwt", description: "Moken" },
  { subtag: "mwu", description: "Mittu" },
  { subtag: "mwv", description: "Mentawai" },
  { subtag: "mww", description: "Hmong Daw" },
  { subtag: "mwz", description: "Moingi" },
  { subtag: "mxa", description: "Northwest Oaxaca Mixtec" },
  { subtag: "mxb", description: "Tezoatlán Mixtec" },
  { subtag: "mxc", description: "Manyika" },
  { subtag: "mxd", description: "Modang" },
  { subtag: "mxe", description: "Mele-Fila" },
  { subtag: "mxf", description: "Malgbe" },
  { subtag: "mxg", description: "Mbangala" },
  { subtag: "mxh", description: "Mvuba" },
  { subtag: "mxi", description: "Mozarabic" },
  { subtag: "mxj", description: "Miju-Mishmi, Geman Deng" },
  { subtag: "mxk", description: "Monumbo" },
  { subtag: "mxl", description: "Maxi Gbe" },
  { subtag: "mxm", description: "Meramera" },
  { subtag: "mxn", description: "Moi (Indonesia)" },
  { subtag: "mxo", description: "Mbowe" },
  { subtag: "mxp", description: "Tlahuitoltepec Mixe" },
  { subtag: "mxq", description: "Juquila Mixe" },
  { subtag: "mxr", description: "Murik (Malaysia)" },
  { subtag: "mxs", description: "Huitepec Mixtec" },
  { subtag: "mxt", description: "Jamiltepec Mixtec" },
  { subtag: "mxu", description: "Mada (Cameroon)" },
  { subtag: "mxv", description: "Metlatónoc Mixtec" },
  { subtag: "mxw", description: "Namo" },
  { subtag: "mxx", description: "Mahou, Mawukakan" },
  { subtag: "mxy", description: "Southeastern Nochixtlán Mixtec" },
  { subtag: "mxz", description: "Central Masela" },
  { subtag: "myb", description: "Mbay" },
  { subtag: "myc", description: "Mayeka" },
  { subtag: "mye", description: "Myene" },
  { subtag: "myf", description: "Bambassi" },
  { subtag: "myg", description: "Manta" },
  { subtag: "myh", description: "Makah" },
  { subtag: "myj", description: "Mangayat" },
  { subtag: "myk", description: "Mamara Senoufo" },
  { subtag: "myl", description: "Moma" },
  { subtag: "mym", description: "Me'en" },
  { subtag: "myn", description: "Mayan languages" },
  { subtag: "myo", description: "Anfillo" },
  { subtag: "myp", description: "Pirahã" },
  { subtag: "myr", description: "Muniche" },
  { subtag: "mys", description: "Mesmes" },
  { subtag: "myu", description: "Mundurukú" },
  { subtag: "myv", description: "Erzya" },
  { subtag: "myw", description: "Muyuw" },
  { subtag: "myx", description: "Masaaba" },
  { subtag: "myy", description: "Macuna" },
  { subtag: "myz", description: "Classical Mandaic" },
  { subtag: "mza", description: "Santa María Zacatepec Mixtec" },
  { subtag: "mzb", description: "Tumzabt" },
  { subtag: "mzc", description: "Madagascar Sign Language" },
  { subtag: "mzd", description: "Malimba" },
  { subtag: "mze", description: "Morawa" },
  { subtag: "mzg", description: "Monastic Sign Language" },
  { subtag: "mzh", description: "Wichí Lhamtés Güisnay" },
  { subtag: "mzi", description: "Ixcatlán Mazatec" },
  { subtag: "mzj", description: "Manya" },
  { subtag: "mzk", description: "Nigeria Mambila" },
  { subtag: "mzl", description: "Mazatlán Mixe" },
  { subtag: "mzm", description: "Mumuye" },
  { subtag: "mzn", description: "Mazanderani" },
  { subtag: "mzo", description: "Matipuhy" },
  { subtag: "mzp", description: "Movima" },
  { subtag: "mzq", description: "Mori Atas" },
  { subtag: "mzr", description: "Marúbo" },
  { subtag: "mzs", description: "Macanese" },
  { subtag: "mzt", description: "Mintil" },
  { subtag: "mzu", description: "Inapang" },
  { subtag: "mzv", description: "Manza" },
  { subtag: "mzw", description: "Deg" },
  { subtag: "mzx", description: "Mawayana" },
  { subtag: "mzy", description: "Mozambican Sign Language" },
  { subtag: "mzz", description: "Maiadomu" },
  { subtag: "naa", description: "Namla" },
  { subtag: "nab", description: "Southern Nambikuára" },
  { subtag: "nac", description: "Narak" },
  { subtag: "nae", description: "Naka'ela" },
  { subtag: "naf", description: "Nabak" },
  { subtag: "nag", description: "Naga Pidgin" },
  { subtag: "nah", description: "Nahuatl languages" },
  { subtag: "nai", description: "North American Indian languages" },
  { subtag: "naj", description: "Nalu" },
  { subtag: "nak", description: "Nakanai" },
  { subtag: "nal", description: "Nalik" },
  { subtag: "nam", description: "Ngan'gityemerri" },
  { subtag: "nan", description: "Min Nan Chinese" },
  { subtag: "nao", description: "Naaba" },
  { subtag: "nap", description: "Neapolitan" },
  { subtag: "naq", description: "Khoekhoe, Nama (Namibia)" },
  { subtag: "nar", description: "Iguta" },
  { subtag: "nas", description: "Naasioi" },
  { subtag: "nat", description: "Ca̱hungwa̱rya̱, Hungworo" },
  { subtag: "naw", description: "Nawuri" },
  { subtag: "nax", description: "Nakwi" },
  { subtag: "nay", description: "Ngarrindjeri" },
  { subtag: "naz", description: "Coatepec Nahuatl" },
  { subtag: "nba", description: "Nyemba" },
  { subtag: "nbb", description: "Ndoe" },
  { subtag: "nbc", description: "Chang Naga" },
  { subtag: "nbd", description: "Ngbinda" },
  { subtag: "nbe", description: "Konyak Naga" },
  { subtag: "nbg", description: "Nagarchal" },
  { subtag: "nbh", description: "Ngamo" },
  { subtag: "nbi", description: "Mao Naga" },
  { subtag: "nbj", description: "Ngarinyman" },
  { subtag: "nbk", description: "Nake" },
  { subtag: "nbm", description: "Ngbaka Ma'bo" },
  { subtag: "nbn", description: "Kuri" },
  { subtag: "nbo", description: "Nkukoli" },
  { subtag: "nbp", description: "Nnam" },
  { subtag: "nbq", description: "Nggem" },
  { subtag: "nbr", description: "Numana" },
  { subtag: "nbs", description: "Namibian Sign Language" },
  { subtag: "nbt", description: "Na" },
  { subtag: "nbu", description: "Rongmei Naga" },
  { subtag: "nbv", description: "Ngamambo" },
  { subtag: "nbw", description: "Southern Ngbandi" },
  { subtag: "nby", description: "Ningera" },
  { subtag: "nca", description: "Iyo" },
  { subtag: "ncb", description: "Central Nicobarese" },
  { subtag: "ncc", description: "Ponam" },
  { subtag: "ncd", description: "Nachering" },
  { subtag: "nce", description: "Yale" },
  { subtag: "ncf", description: "Notsi" },
  { subtag: "ncg", description: "Nisga'a" },
  { subtag: "nch", description: "Central Huasteca Nahuatl" },
  { subtag: "nci", description: "Classical Nahuatl" },
  { subtag: "ncj", description: "Northern Puebla Nahuatl" },
  { subtag: "nck", description: "Na-kara" },
  { subtag: "ncl", description: "Michoacán Nahuatl" },
  { subtag: "ncm", description: "Nambo" },
  { subtag: "ncn", description: "Nauna" },
  { subtag: "nco", description: "Sibe" },
  { subtag: "ncq", description: "Northern Katang" },
  { subtag: "ncr", description: "Ncane" },
  { subtag: "ncs", description: "Nicaraguan Sign Language" },
  { subtag: "nct", description: "Chothe Naga" },
  { subtag: "ncu", description: "Chumburung" },
  { subtag: "ncx", description: "Central Puebla Nahuatl" },
  { subtag: "ncz", description: "Natchez" },
  { subtag: "nda", description: "Ndasa" },
  { subtag: "ndb", description: "Kenswei Nsei" },
  { subtag: "ndc", description: "Ndau" },
  { subtag: "ndd", description: "Nde-Nsele-Nta" },
  { subtag: "ndf", description: "Nadruvian" },
  { subtag: "ndg", description: "Ndengereko" },
  { subtag: "ndh", description: "Ndali" },
  { subtag: "ndi", description: "Samba Leko" },
  { subtag: "ndj", description: "Ndamba" },
  { subtag: "ndk", description: "Ndaka" },
  { subtag: "ndl", description: "Ndolo" },
  { subtag: "ndm", description: "Ndam" },
  { subtag: "ndn", description: "Ngundi" },
  { subtag: "ndp", description: "Ndo" },
  { subtag: "ndq", description: "Ndombe" },
  { subtag: "ndr", description: "Ndoola" },
  { subtag: "nds", description: "Low German, Low Saxon" },
  { subtag: "ndt", description: "Ndunga" },
  { subtag: "ndu", description: "Dugun" },
  { subtag: "ndv", description: "Ndut" },
  { subtag: "ndw", description: "Ndobo" },
  { subtag: "ndx", description: "Nduga" },
  { subtag: "ndy", description: "Lutos" },
  { subtag: "ndz", description: "Ndogo" },
  { subtag: "nea", description: "Eastern Ngad'a" },
  { subtag: "neb", description: "Toura (Côte d'Ivoire)" },
  { subtag: "nec", description: "Nedebang" },
  { subtag: "ned", description: "Nde-Gbite" },
  { subtag: "nee", description: "Nêlêmwa-Nixumwak" },
  { subtag: "nef", description: "Nefamese" },
  { subtag: "neg", description: "Negidal" },
  { subtag: "neh", description: "Nyenkha" },
  { subtag: "nei", description: "Neo-Hittite" },
  { subtag: "nej", description: "Neko" },
  { subtag: "nek", description: "Neku" },
  { subtag: "nem", description: "Nemi" },
  { subtag: "nen", description: "Nengone" },
  { subtag: "neo", description: "Ná-Meo" },
  { subtag: "neq", description: "North Central Mixe" },
  { subtag: "ner", description: "Yahadian" },
  { subtag: "nes", description: "Bhoti Kinnauri" },
  { subtag: "net", description: "Nete" },
  { subtag: "neu", description: "Neo" },
  { subtag: "nev", description: "Nyaheun" },
  { subtag: "new", description: "Newari, Nepal Bhasa" },
  { subtag: "nex", description: "Neme" },
  { subtag: "ney", description: "Neyo" },
  { subtag: "nez", description: "Nez Perce" },
  { subtag: "nfa", description: "Dhao" },
  { subtag: "nfd", description: "Ahwai" },
  { subtag: "nfl", description: "Ayiwo, Äiwoo" },
  { subtag: "nfr", description: "Nafaanra" },
  { subtag: "nfu", description: "Mfumte" },
  { subtag: "nga", description: "Ngbaka" },
  { subtag: "ngb", description: "Northern Ngbandi" },
  { subtag: "ngc", description: "Ngombe (Democratic Republic of Congo)" },
  { subtag: "ngd", description: "Ngando (Central African Republic)" },
  { subtag: "nge", description: "Ngemba" },
  { subtag: "ngf", description: "Trans-New Guinea languages" },
  { subtag: "ngg", description: "Ngbaka Manza" },
  { subtag: "ngh", description: "Nǁng" },
  { subtag: "ngi", description: "Ngizim" },
  { subtag: "ngj", description: "Ngie" },
  { subtag: "ngk", description: "Dalabon" },
  { subtag: "ngl", description: "Lomwe" },
  { subtag: "ngm", description: "Ngatik Men's Creole" },
  { subtag: "ngn", description: "Ngwo" },
  { subtag: "ngp", description: "Ngulu" },
  { subtag: "ngq", description: "Ngurimi, Ngoreme" },
  { subtag: "ngr", description: "Engdewu" },
  { subtag: "ngs", description: "Gvoko" },
  { subtag: "ngt", description: "Kriang, Ngeq" },
  { subtag: "ngu", description: "Guerrero Nahuatl" },
  { subtag: "ngv", description: "Nagumi" },
  { subtag: "ngw", description: "Ngwaba" },
  { subtag: "ngx", description: "Nggwahyi" },
  { subtag: "ngy", description: "Tibea" },
  { subtag: "ngz", description: "Ngungwel" },
  { subtag: "nha", description: "Nhanda" },
  { subtag: "nhb", description: "Beng" },
  { subtag: "nhc", description: "Tabasco Nahuatl" },
  { subtag: "nhd", description: "Chiripá, Ava Guaraní" },
  { subtag: "nhe", description: "Eastern Huasteca Nahuatl" },
  { subtag: "nhf", description: "Nhuwala" },
  { subtag: "nhg", description: "Tetelcingo Nahuatl" },
  { subtag: "nhh", description: "Nahari" },
  { subtag: "nhi", description: "Zacatlán-Ahuacatlán-Tepetzintla Nahuatl" },
  { subtag: "nhk", description: "Isthmus-Cosoleacaque Nahuatl" },
  { subtag: "nhm", description: "Morelos Nahuatl" },
  { subtag: "nhn", description: "Central Nahuatl" },
  { subtag: "nho", description: "Takuu" },
  { subtag: "nhp", description: "Isthmus-Pajapan Nahuatl" },
  { subtag: "nhq", description: "Huaxcaleca Nahuatl" },
  { subtag: "nhr", description: "Naro" },
  { subtag: "nht", description: "Ometepec Nahuatl" },
  { subtag: "nhu", description: "Noone" },
  { subtag: "nhv", description: "Temascaltepec Nahuatl" },
  { subtag: "nhw", description: "Western Huasteca Nahuatl" },
  { subtag: "nhx", description: "Isthmus-Mecayapan Nahuatl" },
  { subtag: "nhy", description: "Northern Oaxaca Nahuatl" },
  { subtag: "nhz", description: "Santa María La Alta Nahuatl" },
  { subtag: "nia", description: "Nias" },
  { subtag: "nib", description: "Nakame" },
  { subtag: "nic", description: "Niger-Kordofanian languages" },
  { subtag: "nid", description: "Ngandi" },
  { subtag: "nie", description: "Niellim" },
  { subtag: "nif", description: "Nek" },
  { subtag: "nig", description: "Ngalakgan" },
  { subtag: "nih", description: "Nyiha (Tanzania)" },
  { subtag: "nii", description: "Nii" },
  { subtag: "nij", description: "Ngaju" },
  { subtag: "nik", description: "Southern Nicobarese" },
  { subtag: "nil", description: "Nila" },
  { subtag: "nim", description: "Nilamba" },
  { subtag: "nin", description: "Ninzo" },
  { subtag: "nio", description: "Nganasan" },
  { subtag: "niq", description: "Nandi" },
  { subtag: "nir", description: "Nimboran" },
  { subtag: "nis", description: "Nimi" },
  { subtag: "nit", description: "Southeastern Kolami" },
  { subtag: "niu", description: "Niuean" },
  { subtag: "niv", description: "Gilyak" },
  { subtag: "niw", description: "Nimo" },
  { subtag: "nix", description: "Hema" },
  { subtag: "niy", description: "Ngiti" },
  { subtag: "niz", description: "Ningil" },
  { subtag: "nja", description: "Nzanyi" },
  { subtag: "njb", description: "Nocte Naga" },
  { subtag: "njd", description: "Ndonde Hamba" },
  { subtag: "njh", description: "Lotha Naga" },
  { subtag: "nji", description: "Gudanji" },
  { subtag: "njj", description: "Njen" },
  { subtag: "njl", description: "Njalgulgule" },
  { subtag: "njm", description: "Angami Naga" },
  { subtag: "njn", description: "Liangmai Naga" },
  { subtag: "njo", description: "Ao Naga" },
  { subtag: "njr", description: "Njerep" },
  { subtag: "njs", description: "Nisa" },
  { subtag: "njt", description: "Ndyuka-Trio Pidgin" },
  { subtag: "nju", description: "Ngadjunmaya" },
  { subtag: "njx", description: "Kunyi" },
  { subtag: "njy", description: "Njyem" },
  { subtag: "njz", description: "Nyishi" },
  { subtag: "nka", description: "Nkoya" },
  { subtag: "nkb", description: "Khoibu Naga" },
  { subtag: "nkc", description: "Nkongho" },
  { subtag: "nkd", description: "Koireng" },
  { subtag: "nke", description: "Duke" },
  { subtag: "nkf", description: "Inpui Naga" },
  { subtag: "nkg", description: "Nekgini" },
  { subtag: "nkh", description: "Khezha Naga" },
  { subtag: "nki", description: "Thangal Naga" },
  { subtag: "nkj", description: "Nakai" },
  { subtag: "nkk", description: "Nokuku" },
  { subtag: "nkm", description: "Namat" },
  { subtag: "nkn", description: "Nkangala" },
  { subtag: "nko", description: "Nkonya" },
  { subtag: "nkp", description: "Niuatoputapu" },
  { subtag: "nkq", description: "Nkami" },
  { subtag: "nkr", description: "Nukuoro" },
  { subtag: "nks", description: "North Asmat" },
  { subtag: "nkt", description: "Nyika (Tanzania)" },
  { subtag: "nku", description: "Bouna Kulango" },
  { subtag: "nkv", description: "Nyika (Malawi and Zambia)" },
  { subtag: "nkw", description: "Nkutu" },
  { subtag: "nkx", description: "Nkoroo" },
  { subtag: "nkz", description: "Nkari" },
  { subtag: "nla", description: "Ngombale" },
  { subtag: "nlc", description: "Nalca" },
  { subtag: "nle", description: "East Nyala" },
  { subtag: "nlg", description: "Gela" },
  { subtag: "nli", description: "Grangali" },
  { subtag: "nlj", description: "Nyali" },
  { subtag: "nlk", description: "Ninia Yali" },
  { subtag: "nll", description: "Nihali" },
  { subtag: "nlm", description: "Mankiyali" },
  { subtag: "nlo", description: "Ngul" },
  { subtag: "nlq", description: "Lao Naga" },
  { subtag: "nlu", description: "Nchumbulu" },
  { subtag: "nlv", description: "Orizaba Nahuatl" },
  { subtag: "nlw", description: "Walangama" },
  { subtag: "nlx", description: "Nahali" },
  { subtag: "nly", description: "Nyamal" },
  { subtag: "nlz", description: "Nalögo" },
  { subtag: "nma", description: "Maram Naga" },
  { subtag: "nmb", description: "Big Nambas, V'ënen Taut" },
  { subtag: "nmc", description: "Ngam" },
  { subtag: "nmd", description: "Ndumu" },
  { subtag: "nme", description: "Mzieme Naga" },
  { subtag: "nmf", description: "Tangkhul Naga (India)" },
  { subtag: "nmg", description: "Kwasio" },
  { subtag: "nmh", description: "Monsang Naga" },
  { subtag: "nmi", description: "Nyam" },
  { subtag: "nmj", description: "Ngombe (Central African Republic)" },
  { subtag: "nmk", description: "Namakura" },
  { subtag: "nml", description: "Ndemli" },
  { subtag: "nmm", description: "Manangba" },
  { subtag: "nmn", description: "ǃXóõ" },
  { subtag: "nmo", description: "Moyon Naga" },
  { subtag: "nmp", description: "Nimanbur" },
  { subtag: "nmq", description: "Nambya" },
  { subtag: "nmr", description: "Nimbari" },
  { subtag: "nms", description: "Letemboi" },
  { subtag: "nmt", description: "Namonuito" },
  { subtag: "nmu", description: "Northeast Maidu" },
  { subtag: "nmv", description: "Ngamini" },
  { subtag: "nmw", description: "Nimoa, Rifao" },
  { subtag: "nmx", description: "Nama (Papua New Guinea)" },
  { subtag: "nmy", description: "Namuyi" },
  { subtag: "nmz", description: "Nawdm" },
  { subtag: "nna", description: "Nyangumarta" },
  { subtag: "nnb", description: "Nande" },
  { subtag: "nnc", description: "Nancere" },
  { subtag: "nnd", description: "West Ambae" },
  { subtag: "nne", description: "Ngandyera" },
  { subtag: "nnf", description: "Ngaing" },
  { subtag: "nng", description: "Maring Naga" },
  { subtag: "nnh", description: "Ngiemboon" },
  { subtag: "nni", description: "North Nuaulu" },
  { subtag: "nnj", description: "Nyangatom" },
  { subtag: "nnk", description: "Nankina" },
  { subtag: "nnl", description: "Northern Rengma Naga" },
  { subtag: "nnm", description: "Namia" },
  { subtag: "nnn", description: "Ngete" },
  { subtag: "nnp", description: "Wancho Naga" },
  { subtag: "nnq", description: "Ngindo" },
  { subtag: "nnr", description: "Narungga" },
  { subtag: "nnt", description: "Nanticoke" },
  { subtag: "nnu", description: "Dwang" },
  { subtag: "nnv", description: "Nugunu (Australia)" },
  { subtag: "nnw", description: "Southern Nuni" },
  { subtag: "nny", description: "Nyangga" },
  { subtag: "nnz", description: "Nda'nda'" },
  { subtag: "noa", description: "Woun Meu" },
  { subtag: "noc", description: "Nuk" },
  { subtag: "nod", description: "Northern Thai" },
  { subtag: "noe", description: "Nimadi" },
  { subtag: "nof", description: "Nomane" },
  { subtag: "nog", description: "Nogai" },
  { subtag: "noh", description: "Nomu" },
  { subtag: "noi", description: "Noiri" },
  { subtag: "noj", description: "Nonuya" },
  { subtag: "nok", description: "Nooksack" },
  { subtag: "nol", description: "Nomlaki" },
  { subtag: "nom", description: "Nocamán" },
  { subtag: "non", description: "Old Norse" },
  { subtag: "nop", description: "Numanggang" },
  { subtag: "noq", description: "Ngongo" },
  { subtag: "nos", description: "Eastern Nisu" },
  { subtag: "not", description: "Nomatsiguenga" },
  { subtag: "nou", description: "Ewage-Notu" },
  { subtag: "nov", description: "Novial" },
  { subtag: "now", description: "Nyambo" },
  { subtag: "noy", description: "Noy" },
  { subtag: "noz", description: "Nayi" },
  { subtag: "npa", description: "Nar Phu" },
  { subtag: "npb", description: "Nupbikha" },
  { subtag: "npg", description: "Ponyo-Gongwang Naga" },
  { subtag: "nph", description: "Phom Naga" },
  { subtag: "npi", description: "Nepali (individual language)" },
  { subtag: "npl", description: "Southeastern Puebla Nahuatl" },
  { subtag: "npn", description: "Mondropolon" },
  { subtag: "npo", description: "Pochuri Naga" },
  { subtag: "nps", description: "Nipsan" },
  { subtag: "npu", description: "Puimei Naga" },
  { subtag: "npx", description: "Noipx" },
  { subtag: "npy", description: "Napu" },
  { subtag: "nqg", description: "Southern Nago" },
  { subtag: "nqk", description: "Kura Ede Nago" },
  { subtag: "nql", description: "Ngendelengo" },
  { subtag: "nqm", description: "Ndom" },
  { subtag: "nqn", description: "Nen" },
  { subtag: "nqo", description: "N'Ko, N’Ko" },
  { subtag: "nqq", description: "Kyan-Karyaw Naga" },
  { subtag: "nqt", description: "Nteng" },
  { subtag: "nqy", description: "Akyaung Ari Naga" },
  { subtag: "nra", description: "Ngom" },
  { subtag: "nrb", description: "Nara" },
  { subtag: "nrc", description: "Noric" },
  { subtag: "nre", description: "Southern Rengma Naga" },
  { subtag: "nrf", description: "Jèrriais, Guernésiais, Sercquiais" },
  { subtag: "nrg", description: "Narango" },
  { subtag: "nri", description: "Chokri Naga" },
  { subtag: "nrk", description: "Ngarla" },
  { subtag: "nrl", description: "Ngarluma" },
  { subtag: "nrm", description: "Narom" },
  { subtag: "nrn", description: "Norn" },
  { subtag: "nrp", description: "North Picene" },
  { subtag: "nrr", description: "Norra, Nora" },
  { subtag: "nrt", description: "Northern Kalapuya" },
  { subtag: "nru", description: "Narua" },
  { subtag: "nrx", description: "Ngurmbur" },
  { subtag: "nrz", description: "Lala" },
  { subtag: "nsa", description: "Sangtam Naga" },
  { subtag: "nsb", description: "Lower Nossob" },
  { subtag: "nsc", description: "Nshi" },
  { subtag: "nsd", description: "Southern Nisu" },
  { subtag: "nse", description: "Nsenga" },
  { subtag: "nsf", description: "Northwestern Nisu" },
  { subtag: "nsg", description: "Ngasa" },
  { subtag: "nsh", description: "Ngoshie" },
  { subtag: "nsi", description: "Nigerian Sign Language" },
  { subtag: "nsk", description: "Naskapi" },
  { subtag: "nsl", description: "Norwegian Sign Language" },
  { subtag: "nsm", description: "Sumi Naga" },
  { subtag: "nsn", description: "Nehan" },
  { subtag: "nso", description: "Pedi, Northern Sotho, Sepedi" },
  { subtag: "nsp", description: "Nepalese Sign Language" },
  { subtag: "nsq", description: "Northern Sierra Miwok" },
  { subtag: "nsr", description: "Maritime Sign Language" },
  { subtag: "nss", description: "Nali" },
  { subtag: "nst", description: "Tase Naga" },
  { subtag: "nsu", description: "Sierra Negra Nahuatl" },
  { subtag: "nsv", description: "Southwestern Nisu" },
  { subtag: "nsw", description: "Navut" },
  { subtag: "nsx", description: "Nsongo" },
  { subtag: "nsy", description: "Nasal" },
  { subtag: "nsz", description: "Nisenan" },
  { subtag: "ntd", description: "Northern Tidung" },
  { subtag: "nte", description: "Nathembo" },
  { subtag: "ntg", description: "Ngantangarra" },
  { subtag: "nti", description: "Natioro" },
  { subtag: "ntj", description: "Ngaanyatjarra" },
  { subtag: "ntk", description: "Ikoma-Nata-Isenye" },
  { subtag: "ntm", description: "Nateni" },
  { subtag: "nto", description: "Ntomba" },
  { subtag: "ntp", description: "Northern Tepehuan" },
  { subtag: "ntr", description: "Delo" },
  { subtag: "ntu", description: "Natügu" },
  { subtag: "ntw", description: "Nottoway" },
  { subtag: "ntx", description: "Tangkhul Naga (Myanmar)" },
  { subtag: "nty", description: "Mantsi" },
  { subtag: "ntz", description: "Natanzi" },
  { subtag: "nua", description: "Yuanga" },
  { subtag: "nub", description: "Nubian languages" },
  { subtag: "nuc", description: "Nukuini" },
  { subtag: "nud", description: "Ngala" },
  { subtag: "nue", description: "Ngundu" },
  { subtag: "nuf", description: "Nusu" },
  { subtag: "nug", description: "Nungali" },
  { subtag: "nuh", description: "Ndunda" },
  { subtag: "nui", description: "Ngumbi" },
  { subtag: "nuj", description: "Nyole" },
  { subtag: "nuk", description: "Nuu-chah-nulth, Nuuchahnulth" },
  { subtag: "nul", description: "Nusa Laut" },
  { subtag: "num", description: "Niuafo'ou" },
  { subtag: "nun", description: "Anong" },
  { subtag: "nuo", description: "Nguôn" },
  { subtag: "nup", description: "Nupe-Nupe-Tako" },
  { subtag: "nuq", description: "Nukumanu" },
  { subtag: "nur", description: "Nukuria" },
  { subtag: "nus", description: "Nuer" },
  { subtag: "nut", description: "Nung (Viet Nam)" },
  { subtag: "nuu", description: "Ngbundu" },
  { subtag: "nuv", description: "Northern Nuni" },
  { subtag: "nuw", description: "Nguluwan" },
  { subtag: "nux", description: "Mehek" },
  { subtag: "nuy", description: "Nunggubuyu" },
  { subtag: "nuz", description: "Tlamacazapa Nahuatl" },
  { subtag: "nvh", description: "Nasarian" },
  { subtag: "nvm", description: "Namiae" },
  { subtag: "nvo", description: "Nyokon" },
  { subtag: "nwa", description: "Nawathinehena" },
  { subtag: "nwb", description: "Nyabwa" },
  { subtag: "nwc", description: "Classical Newari, Classical Nepal Bhasa, Old Newari" },
  { subtag: "nwe", description: "Ngwe" },
  { subtag: "nwg", description: "Ngayawung" },
  { subtag: "nwi", description: "Southwest Tanna" },
  { subtag: "nwm", description: "Nyamusa-Molo" },
  { subtag: "nwo", description: "Nauo" },
  { subtag: "nwr", description: "Nawaru" },
  { subtag: "nww", description: "Ndwewe" },
  { subtag: "nwx", description: "Middle Newar" },
  { subtag: "nwy", description: "Nottoway-Meherrin" },
  { subtag: "nxa", description: "Nauete" },
  { subtag: "nxd", description: "Ngando (Democratic Republic of Congo)" },
  { subtag: "nxe", description: "Nage" },
  { subtag: "nxg", description: "Ngad'a" },
  { subtag: "nxi", description: "Nindi" },
  { subtag: "nxk", description: "Koki Naga" },
  { subtag: "nxl", description: "South Nuaulu" },
  { subtag: "nxm", description: "Numidian" },
  { subtag: "nxn", description: "Ngawun" },
  { subtag: "nxo", description: "Ndambomo" },
  { subtag: "nxq", description: "Naxi" },
  { subtag: "nxr", description: "Ninggerum" },
  { subtag: "nxx", description: "Nafri" },
  { subtag: "nyb", description: "Nyangbo" },
  { subtag: "nyc", description: "Nyanga-li" },
  { subtag: "nyd", description: "Nyore, Olunyole" },
  { subtag: "nye", description: "Nyengo" },
  { subtag: "nyf", description: "Giryama, Kigiryama" },
  { subtag: "nyg", description: "Nyindu" },
  { subtag: "nyh", description: "Nyikina" },
  { subtag: "nyi", description: "Ama (Sudan)" },
  { subtag: "nyj", description: "Nyanga" },
  { subtag: "nyk", description: "Nyaneka" },
  { subtag: "nyl", description: "Nyeu" },
  { subtag: "nym", description: "Nyamwezi" },
  { subtag: "nyn", description: "Nyankole" },
  { subtag: "nyo", description: "Nyoro" },
  { subtag: "nyp", description: "Nyang'i" },
  { subtag: "nyq", description: "Nayini" },
  { subtag: "nyr", description: "Nyiha (Malawi)" },
  { subtag: "nys", description: "Nyungar" },
  { subtag: "nyt", description: "Nyawaygi" },
  { subtag: "nyu", description: "Nyungwe" },
  { subtag: "nyv", description: "Nyulnyul" },
  { subtag: "nyw", description: "Nyaw" },
  { subtag: "nyx", description: "Nganyaywana" },
  { subtag: "nyy", description: "Nyakyusa-Ngonde" },
  { subtag: "nza", description: "Tigon Mbembe" },
  { subtag: "nzb", description: "Njebi" },
  { subtag: "nzd", description: "Nzadi" },
  { subtag: "nzi", description: "Nzima" },
  { subtag: "nzk", description: "Nzakara" },
  { subtag: "nzm", description: "Zeme Naga" },
  { subtag: "nzs", description: "New Zealand Sign Language" },
  { subtag: "nzu", description: "Teke-Nzikou" },
  { subtag: "nzy", description: "Nzakambay" },
  { subtag: "nzz", description: "Nanga Dama Dogon" },
  { subtag: "oaa", description: "Orok" },
  { subtag: "oac", description: "Oroch" },
  { subtag: "oar", description: "Old Aramaic (up to 700 BCE), Ancient Aramaic (up to 700 BCE)" },
  { subtag: "oav", description: "Old Avar" },
  { subtag: "obi", description: "Obispeño" },
  { subtag: "obk", description: "Southern Bontok" },
  { subtag: "obl", description: "Oblo" },
  { subtag: "obm", description: "Moabite" },
  { subtag: "obo", description: "Obo Manobo" },
  { subtag: "obr", description: "Old Burmese" },
  { subtag: "obt", description: "Old Breton" },
  { subtag: "obu", description: "Obulom" },
  { subtag: "oca", description: "Ocaina" },
  { subtag: "och", description: "Old Chinese" },
  { subtag: "ocm", description: "Old Cham" },
  { subtag: "oco", description: "Old Cornish" },
  { subtag: "ocu", description: "Atzingo Matlatzinca" },
  { subtag: "oda", description: "Odut" },
  { subtag: "odk", description: "Od" },
  { subtag: "odt", description: "Old Dutch" },
  { subtag: "odu", description: "Odual" },
  { subtag: "ofo", description: "Ofo" },
  { subtag: "ofs", description: "Old Frisian" },
  { subtag: "ofu", description: "Efutop" },
  { subtag: "ogb", description: "Ogbia" },
  { subtag: "ogc", description: "Ogbah" },
  { subtag: "oge", description: "Old Georgian" },
  { subtag: "ogg", description: "Ogbogolo" },
  { subtag: "ogo", description: "Khana" },
  { subtag: "ogu", description: "Ogbronuagum" },
  { subtag: "oht", description: "Old Hittite" },
  { subtag: "ohu", description: "Old Hungarian" },
  { subtag: "oia", description: "Oirata" },
  { subtag: "oie", description: "Okolie" },
  { subtag: "oin", description: "Inebu One" },
  { subtag: "ojb", description: "Northwestern Ojibwa" },
  { subtag: "ojc", description: "Central Ojibwa" },
  { subtag: "ojg", description: "Eastern Ojibwa" },
  { subtag: "ojp", description: "Old Japanese" },
  { subtag: "ojs", description: "Severn Ojibwa" },
  { subtag: "ojv", description: "Ontong Java" },
  { subtag: "ojw", description: "Western Ojibwa" },
  { subtag: "oka", description: "Okanagan" },
  { subtag: "okb", description: "Okobo" },
  { subtag: "okc", description: "Kobo" },
  { subtag: "okd", description: "Okodia" },
  { subtag: "oke", description: "Okpe (Southwestern Edo)" },
  { subtag: "okg", description: "Koko Babangk" },
  { subtag: "okh", description: "Koresh-e Rostam" },
  { subtag: "oki", description: "Okiek" },
  { subtag: "okj", description: "Oko-Juwoi" },
  { subtag: "okk", description: "Kwamtim One" },
  { subtag: "okl", description: "Old Kentish Sign Language" },
  { subtag: "okm", description: "Middle Korean (10th-16th cent.)" },
  { subtag: "okn", description: "Oki-No-Erabu" },
  { subtag: "oko", description: "Old Korean (3rd-9th cent.)" },
  { subtag: "okr", description: "Kirike" },
  { subtag: "oks", description: "Oko-Eni-Osayen" },
  { subtag: "oku", description: "Oku" },
  { subtag: "okv", description: "Orokaiva" },
  { subtag: "okx", description: "Okpe (Northwestern Edo)" },
  { subtag: "okz", description: "Old Khmer" },
  { subtag: "ola", description: "Walungge" },
  { subtag: "old", description: "Mochi" },
  { subtag: "ole", description: "Olekha" },
  { subtag: "olk", description: "Olkol" },
  { subtag: "olm", description: "Oloma" },
  { subtag: "olo", description: "Livvi" },
  { subtag: "olr", description: "Olrat" },
  { subtag: "olt", description: "Old Lithuanian" },
  { subtag: "olu", description: "Kuvale" },
  { subtag: "oma", description: "Omaha-Ponca" },
  { subtag: "omb", description: "East Ambae" },
  { subtag: "omc", description: "Mochica" },
  { subtag: "omg", description: "Omagua" },
  { subtag: "omi", description: "Omi" },
  { subtag: "omk", description: "Omok" },
  { subtag: "oml", description: "Ombo" },
  { subtag: "omn", description: "Minoan" },
  { subtag: "omo", description: "Utarmbung" },
  { subtag: "omp", description: "Old Manipuri" },
  { subtag: "omq", description: "Oto-Manguean languages" },
  { subtag: "omr", description: "Old Marathi" },
  { subtag: "omt", description: "Omotik" },
  { subtag: "omu", description: "Omurano" },
  { subtag: "omv", description: "Omotic languages" },
  { subtag: "omw", description: "South Tairora" },
  { subtag: "omx", description: "Old Mon" },
  { subtag: "omy", description: "Old Malay" },
  { subtag: "ona", description: "Ona" },
  { subtag: "onb", description: "Lingao" },
  { subtag: "one", description: "Oneida" },
  { subtag: "ong", description: "Olo" },
  { subtag: "oni", description: "Onin" },
  { subtag: "onj", description: "Onjob" },
  { subtag: "onk", description: "Kabore One" },
  { subtag: "onn", description: "Onobasulu" },
  { subtag: "ono", description: "Onondaga" },
  { subtag: "onp", description: "Sartang" },
  { subtag: "onr", description: "Northern One" },
  { subtag: "ons", description: "Ono" },
  { subtag: "ont", description: "Ontenu" },
  { subtag: "onu", description: "Unua" },
  { subtag: "onw", description: "Old Nubian" },
  { subtag: "onx", description: "Onin Based Pidgin" },
  { subtag: "ood", description: "Tohono O'odham" },
  { subtag: "oog", description: "Ong" },
  { subtag: "oon", description: "Önge" },
  { subtag: "oor", description: "Oorlams" },
  { subtag: "oos", description: "Old Ossetic" },
  { subtag: "opa", description: "Okpamheri" },
  { subtag: "opk", description: "Kopkaka" },
  { subtag: "opm", description: "Oksapmin" },
  { subtag: "opo", description: "Opao" },
  { subtag: "opt", description: "Opata" },
  { subtag: "opy", description: "Ofayé" },
  { subtag: "ora", description: "Oroha" },
  { subtag: "orc", description: "Orma" },
  { subtag: "ore", description: "Orejón" },
  { subtag: "org", description: "Oring" },
  { subtag: "orh", description: "Oroqen" },
  { subtag: "orn", description: "Orang Kanaq" },
  { subtag: "oro", description: "Orokolo" },
  { subtag: "orr", description: "Oruma" },
  { subtag: "ors", description: "Orang Seletar" },
  { subtag: "ort", description: "Adivasi Oriya" },
  { subtag: "oru", description: "Ormuri" },
  { subtag: "orv", description: "Old Russian" },
  { subtag: "orw", description: "Oro Win" },
  { subtag: "orx", description: "Oro" },
  {
    subtag: "ory",
    description: "Odia (individual language), Oriya (individual language)",
  },
  { subtag: "orz", description: "Ormu" },
  { subtag: "osa", description: "Osage" },
  { subtag: "osc", description: "Oscan" },
  { subtag: "osi", description: "Osing" },
  { subtag: "osn", description: "Old Sundanese" },
  { subtag: "oso", description: "Ososo" },
  { subtag: "osp", description: "Old Spanish" },
  { subtag: "ost", description: "Osatu" },
  { subtag: "osu", description: "Southern One" },
  { subtag: "osx", description: "Old Saxon" },
  { subtag: "ota", description: "Ottoman Turkish (1500-1928)" },
  { subtag: "otb", description: "Old Tibetan" },
  { subtag: "otd", description: "Ot Danum" },
  { subtag: "ote", description: "Mezquital Otomi" },
  { subtag: "oti", description: "Oti" },
  { subtag: "otk", description: "Old Turkish" },
  { subtag: "otl", description: "Tilapa Otomi" },
  { subtag: "otm", description: "Eastern Highland Otomi" },
  { subtag: "otn", description: "Tenango Otomi" },
  { subtag: "oto", description: "Otomian languages" },
  { subtag: "otq", description: "Querétaro Otomi" },
  { subtag: "otr", description: "Otoro" },
  { subtag: "ots", description: "Estado de México Otomi" },
  { subtag: "ott", description: "Temoaya Otomi" },
  { subtag: "otu", description: "Otuke" },
  { subtag: "otw", description: "Ottawa" },
  { subtag: "otx", description: "Texcatepec Otomi" },
  { subtag: "oty", description: "Old Tamil" },
  { subtag: "otz", description: "Ixtenco Otomi" },
  { subtag: "oua", description: "Tagargrent" },
  { subtag: "oub", description: "Glio-Oubi" },
  { subtag: "oue", description: "Oune" },
  { subtag: "oui", description: "Old Uighur" },
  { subtag: "oum", description: "Ouma" },
  { subtag: "ovd", description: "Elfdalian, Övdalian" },
  { subtag: "owi", description: "Owiniga" },
  { subtag: "owl", description: "Old Welsh" },
  { subtag: "oyb", description: "Oy" },
  { subtag: "oyd", description: "Oyda" },
  { subtag: "oym", description: "Wayampi" },
  { subtag: "oyy", description: "Oya'oya" },
  { subtag: "ozm", description: "Koonzime" },
  { subtag: "paa", description: "Papuan languages" },
  { subtag: "pab", description: "Parecís" },
  { subtag: "pac", description: "Pacoh" },
  { subtag: "pad", description: "Paumarí" },
  { subtag: "pae", description: "Pagibete" },
  { subtag: "paf", description: "Paranawát" },
  { subtag: "pag", description: "Pangasinan" },
  { subtag: "pah", description: "Tenharim" },
  { subtag: "pai", description: "Pe" },
  { subtag: "pak", description: "Parakanã" },
  { subtag: "pal", description: "Pahlavi" },
  { subtag: "pam", description: "Pampanga, Kapampangan" },
  { subtag: "pao", description: "Northern Paiute" },
  { subtag: "pap", description: "Papiamento" },
  { subtag: "paq", description: "Parya" },
  { subtag: "par", description: "Panamint, Timbisha" },
  { subtag: "pas", description: "Papasena" },
  { subtag: "pau", description: "Palauan" },
  { subtag: "pav", description: "Pakaásnovos" },
  { subtag: "paw", description: "Pawnee" },
  { subtag: "pax", description: "Pankararé" },
  { subtag: "pay", description: "Pech" },
  { subtag: "paz", description: "Pankararú" },
  { subtag: "pbb", description: "Páez" },
  { subtag: "pbc", description: "Patamona" },
  { subtag: "pbe", description: "Mezontla Popoloca" },
  { subtag: "pbf", description: "Coyotepec Popoloca" },
  { subtag: "pbg", description: "Paraujano" },
  { subtag: "pbh", description: "E'ñapa Woromaipu" },
  { subtag: "pbi", description: "Parkwa" },
  { subtag: "pbl", description: "Mak (Nigeria)" },
  { subtag: "pbm", description: "Puebla Mazatec" },
  { subtag: "pbn", description: "Kpasam" },
  { subtag: "pbo", description: "Papel" },
  { subtag: "pbp", description: "Badyara" },
  { subtag: "pbr", description: "Pangwa" },
  { subtag: "pbs", description: "Central Pame" },
  { subtag: "pbt", description: "Southern Pashto" },
  { subtag: "pbu", description: "Northern Pashto" },
  { subtag: "pbv", description: "Pnar" },
  { subtag: "pby", description: "Pyu (Papua New Guinea)" },
  { subtag: "pca", description: "Santa Inés Ahuatempan Popoloca" },
  { subtag: "pcb", description: "Pear" },
  { subtag: "pcc", description: "Bouyei" },
  { subtag: "pcd", description: "Picard" },
  { subtag: "pce", description: "Ruching Palaung" },
  { subtag: "pcf", description: "Paliyan" },
  { subtag: "pcg", description: "Paniya" },
  { subtag: "pch", description: "Pardhan" },
  { subtag: "pci", description: "Duruwa" },
  { subtag: "pcj", description: "Parenga" },
  { subtag: "pck", description: "Paite Chin" },
  { subtag: "pcl", description: "Pardhi" },
  { subtag: "pcm", description: "Nigerian Pidgin" },
  { subtag: "pcn", description: "Piti" },
  { subtag: "pcp", description: "Pacahuara" },
  { subtag: "pcw", description: "Pyapun" },
  { subtag: "pda", description: "Anam" },
  { subtag: "pdc", description: "Pennsylvania German" },
  { subtag: "pdi", description: "Pa Di" },
  { subtag: "pdn", description: "Podena, Fedan" },
  { subtag: "pdo", description: "Padoe" },
  { subtag: "pdt", description: "Plautdietsch" },
  { subtag: "pdu", description: "Kayan" },
  { subtag: "pea", description: "Peranakan Indonesian" },
  { subtag: "peb", description: "Eastern Pomo" },
  { subtag: "ped", description: "Mala (Papua New Guinea)" },
  { subtag: "pee", description: "Taje" },
  { subtag: "pef", description: "Northeastern Pomo" },
  { subtag: "peg", description: "Pengo" },
  { subtag: "peh", description: "Bonan" },
  { subtag: "pei", description: "Chichimeca-Jonaz" },
  { subtag: "pej", description: "Northern Pomo" },
  { subtag: "pek", description: "Penchal" },
  { subtag: "pel", description: "Pekal" },
  { subtag: "pem", description: "Phende" },
  { subtag: "peo", description: "Old Persian (ca. 600-400 B.C.)" },
  { subtag: "pep", description: "Kunja" },
  { subtag: "peq", description: "Southern Pomo" },
  { subtag: "pes", description: "Iranian Persian" },
  { subtag: "pev", description: "Pémono" },
  { subtag: "pex", description: "Petats" },
  { subtag: "pey", description: "Petjo" },
  { subtag: "pez", description: "Eastern Penan" },
  { subtag: "pfa", description: "Pááfang" },
  { subtag: "pfe", description: "Pere" },
  { subtag: "pfl", description: "Pfaelzisch" },
  { subtag: "pga", description: "Sudanese Creole Arabic" },
  { subtag: "pgd", description: "Gāndhārī" },
  { subtag: "pgg", description: "Pangwali" },
  { subtag: "pgi", description: "Pagi" },
  { subtag: "pgk", description: "Rerep" },
  { subtag: "pgl", description: "Primitive Irish" },
  { subtag: "pgn", description: "Paelignian" },
  { subtag: "pgs", description: "Pangseng" },
  { subtag: "pgu", description: "Pagu" },
  { subtag: "pgz", description: "Papua New Guinean Sign Language" },
  { subtag: "pha", description: "Pa-Hng" },
  { subtag: "phd", description: "Phudagi" },
  { subtag: "phg", description: "Phuong" },
  { subtag: "phh", description: "Phukha" },
  { subtag: "phi", description: "Philippine languages" },
  { subtag: "phj", description: "Pahari" },
  { subtag: "phk", description: "Phake" },
  { subtag: "phl", description: "Phalura, Palula" },
  { subtag: "phm", description: "Phimbi" },
  { subtag: "phn", description: "Phoenician" },
  { subtag: "pho", description: "Phunoi" },
  { subtag: "phq", description: "Phana'" },
  { subtag: "phr", description: "Pahari-Potwari" },
  { subtag: "pht", description: "Phu Thai" },
  { subtag: "phu", description: "Phuan" },
  { subtag: "phv", description: "Pahlavani" },
  { subtag: "phw", description: "Phangduwali" },
  { subtag: "pia", description: "Pima Bajo" },
  { subtag: "pib", description: "Yine" },
  { subtag: "pic", description: "Pinji" },
  { subtag: "pid", description: "Piaroa" },
  { subtag: "pie", description: "Piro" },
  { subtag: "pif", description: "Pingelapese" },
  { subtag: "pig", description: "Pisabo" },
  { subtag: "pih", description: "Pitcairn-Norfolk" },
  { subtag: "pij", description: "Pijao" },
  { subtag: "pil", description: "Yom" },
  { subtag: "pim", description: "Powhatan" },
  { subtag: "pin", description: "Piame" },
  { subtag: "pio", description: "Piapoco" },
  { subtag: "pip", description: "Pero" },
  { subtag: "pir", description: "Piratapuyo" },
  { subtag: "pis", description: "Pijin" },
  { subtag: "pit", description: "Pitta Pitta" },
  { subtag: "piu", description: "Pintupi-Luritja" },
  { subtag: "piv", description: "Pileni, Vaeakau-Taumako" },
  { subtag: "piw", description: "Pimbwe" },
  { subtag: "pix", description: "Piu" },
  { subtag: "piy", description: "Piya-Kwonci" },
  { subtag: "piz", description: "Pije" },
  { subtag: "pjt", description: "Pitjantjatjara" },
  { subtag: "pka", description: "Ardhamāgadhī Prākrit" },
  { subtag: "pkb", description: "Pokomo, Kipfokomo" },
  { subtag: "pkc", description: "Paekche" },
  { subtag: "pkg", description: "Pak-Tong" },
  { subtag: "pkh", description: "Pankhu" },
  { subtag: "pkn", description: "Pakanha" },
  { subtag: "pko", description: "Pökoot" },
  { subtag: "pkp", description: "Pukapuka" },
  { subtag: "pkr", description: "Attapady Kurumba" },
  { subtag: "pks", description: "Pakistan Sign Language" },
  { subtag: "pkt", description: "Maleng" },
  { subtag: "pku", description: "Paku" },
  { subtag: "pla", description: "Miani" },
  { subtag: "plb", description: "Polonombauk" },
  { subtag: "plc", description: "Central Palawano" },
  { subtag: "pld", description: "Polari" },
  { subtag: "ple", description: "Palu'e" },
  { subtag: "plf", description: "Central Malayo-Polynesian languages" },
  { subtag: "plg", description: "Pilagá" },
  { subtag: "plh", description: "Paulohi" },
  { subtag: "plj", description: "Polci" },
  { subtag: "plk", description: "Kohistani Shina" },
  { subtag: "pll", description: "Shwe Palaung" },
  { subtag: "pln", description: "Palenquero" },
  { subtag: "plo", description: "Oluta Popoluca" },
  { subtag: "plq", description: "Palaic" },
  { subtag: "plr", description: "Palaka Senoufo" },
  { subtag: "pls", description: "San Marcos Tlacoyalco Popoloca, San Marcos Tlalcoyalco Popoloca" },
  { subtag: "plt", description: "Plateau Malagasy" },
  { subtag: "plu", description: "Palikúr" },
  { subtag: "plv", description: "Southwest Palawano" },
  { subtag: "plw", description: "Brooke's Point Palawano" },
  { subtag: "ply", description: "Bolyu" },
  { subtag: "plz", description: "Paluan" },
  { subtag: "pma", description: "Paama" },
  { subtag: "pmb", description: "Pambia" },
  { subtag: "pmd", description: "Pallanganmiddang" },
  { subtag: "pme", description: "Pwaamei" },
  { subtag: "pmf", description: "Pamona" },
  { subtag: "pmh", description: "Māhārāṣṭri Prākrit" },
  { subtag: "pmi", description: "Northern Pumi" },
  { subtag: "pmj", description: "Southern Pumi" },
  { subtag: "pmk", description: "Pamlico" },
  { subtag: "pml", description: "Lingua Franca" },
  { subtag: "pmm", description: "Pomo" },
  { subtag: "pmn", description: "Pam" },
  { subtag: "pmo", description: "Pom" },
  { subtag: "pmq", description: "Northern Pame" },
  { subtag: "pmr", description: "Paynamar" },
  { subtag: "pms", description: "Piemontese" },
  { subtag: "pmt", description: "Tuamotuan" },
  { subtag: "pmw", description: "Plains Miwok" },
  { subtag: "pmx", description: "Poumei Naga" },
  { subtag: "pmy", description: "Papuan Malay" },
  { subtag: "pmz", description: "Southern Pame" },
  { subtag: "pna", description: "Punan Bah-Biau" },
  { subtag: "pnb", description: "Western Panjabi" },
  { subtag: "pnc", description: "Pannei" },
  { subtag: "pnd", description: "Mpinda" },
  { subtag: "pne", description: "Western Penan" },
  { subtag: "png", description: "Pangu, Pongu" },
  { subtag: "pnh", description: "Penrhyn" },
  { subtag: "pni", description: "Aoheng" },
  { subtag: "pnj", description: "Pinjarup" },
  { subtag: "pnk", description: "Paunaka" },
  { subtag: "pnl", description: "Paleni" },
  { subtag: "pnm", description: "Punan Batu 1" },
  { subtag: "pnn", description: "Pinai-Hagahai" },
  { subtag: "pno", description: "Panobo" },
  { subtag: "pnp", description: "Pancana" },
  { subtag: "pnq", description: "Pana (Burkina Faso)" },
  { subtag: "pnr", description: "Panim" },
  { subtag: "pns", description: "Ponosakan" },
  { subtag: "pnt", description: "Pontic" },
  { subtag: "pnu", description: "Jiongnai Bunu" },
  { subtag: "pnv", description: "Pinigura" },
  { subtag: "pnw", description: "Banyjima, Panytyima" },
  { subtag: "pnx", description: "Phong-Kniang" },
  {
    subtag: "pny",
    description: "Pinyin",
  },
  { subtag: "pnz", description: "Pana (Central African Republic)" },
  { subtag: "poc", description: "Poqomam" },
  { subtag: "poe", description: "San Juan Atzingo Popoloca" },
  { subtag: "pof", description: "Poke" },
  { subtag: "pog", description: "Potiguára" },
  { subtag: "poh", description: "Poqomchi'" },
  { subtag: "poi", description: "Highland Popoluca" },
  { subtag: "pok", description: "Pokangá" },
  { subtag: "pom", description: "Southeastern Pomo" },
  { subtag: "pon", description: "Pohnpeian" },
  { subtag: "poo", description: "Central Pomo" },
  { subtag: "pop", description: "Pwapwâ" },
  { subtag: "poq", description: "Texistepec Popoluca" },
  { subtag: "pos", description: "Sayula Popoluca" },
  { subtag: "pot", description: "Potawatomi" },
  { subtag: "pov", description: "Upper Guinea Crioulo" },
  { subtag: "pow", description: "San Felipe Otlaltepec Popoloca" },
  { subtag: "pox", description: "Polabian" },
  { subtag: "poy", description: "Pogolo" },
  { subtag: "poz", description: "Malayo-Polynesian languages" },
  { subtag: "ppe", description: "Papi" },
  { subtag: "ppi", description: "Paipai" },
  { subtag: "ppk", description: "Uma" },
  { subtag: "ppl", description: "Pipil, Nicarao" },
  { subtag: "ppm", description: "Papuma" },
  { subtag: "ppn", description: "Papapana" },
  { subtag: "ppo", description: "Folopa" },
  { subtag: "ppp", description: "Pelende" },
  { subtag: "ppq", description: "Pei" },
  { subtag: "pps", description: "San Luís Temalacayuca Popoloca" },
  { subtag: "ppt", description: "Pare" },
  { subtag: "ppu", description: "Papora" },
  { subtag: "pqa", description: "Pa'a" },
  { subtag: "pqe", description: "Eastern Malayo-Polynesian languages" },
  { subtag: "pqm", description: "Malecite-Passamaquoddy" },
  { subtag: "pqw", description: "Western Malayo-Polynesian languages" },
  { subtag: "pra", description: "Prakrit languages" },
  { subtag: "prc", description: "Parachi" },
  { subtag: "prd", description: "Parsi-Dari" },
  { subtag: "pre", description: "Principense" },
  { subtag: "prf", description: "Paranan" },
  { subtag: "prg", description: "Prussian" },
  { subtag: "prh", description: "Porohanon" },
  { subtag: "pri", description: "Paicî" },
  { subtag: "prk", description: "Parauk" },
  { subtag: "prl", description: "Peruvian Sign Language" },
  { subtag: "prm", description: "Kibiri" },
  { subtag: "prn", description: "Prasuni" },
  { subtag: "pro", description: "Old Provençal (to 1500), Old Occitan (to 1500)" },
  { subtag: "prp", description: "Parsi" },
  { subtag: "prq", description: "Ashéninka Perené" },
  { subtag: "prr", description: "Puri" },
  { subtag: "prs", description: "Dari, Afghan Persian" },
  { subtag: "prt", description: "Phai" },
  { subtag: "pru", description: "Puragi" },
  { subtag: "prw", description: "Parawen" },
  { subtag: "prx", description: "Purik" },
  { subtag: "prz", description: "Providencia Sign Language" },
  { subtag: "psa", description: "Asue Awyu" },
  { subtag: "psc", description: "Iranian Sign Language, Persian Sign Language" },
  { subtag: "psd", description: "Plains Indian Sign Language" },
  { subtag: "pse", description: "Central Malay" },
  { subtag: "psg", description: "Penang Sign Language" },
  { subtag: "psh", description: "Southwest Pashai, Southwest Pashayi" },
  { subtag: "psi", description: "Southeast Pashai, Southeast Pashayi" },
  { subtag: "psl", description: "Puerto Rican Sign Language" },
  { subtag: "psm", description: "Pauserna" },
  { subtag: "psn", description: "Panasuan" },
  { subtag: "pso", description: "Polish Sign Language" },
  { subtag: "psp", description: "Philippine Sign Language" },
  { subtag: "psq", description: "Pasi" },
  { subtag: "psr", description: "Portuguese Sign Language" },
  { subtag: "pss", description: "Kaulong" },
  { subtag: "pst", description: "Central Pashto" },
  { subtag: "psu", description: "Sauraseni Prākrit" },
  { subtag: "psw", description: "Port Sandwich" },
  { subtag: "psy", description: "Piscataway" },
  { subtag: "pta", description: "Pai Tavytera" },
  { subtag: "pth", description: "Pataxó Hã-Ha-Hãe" },
  { subtag: "pti", description: "Pindiini, Wangkatha" },
  { subtag: "ptn", description: "Patani" },
  { subtag: "pto", description: "Zo'é" },
  { subtag: "ptp", description: "Patep" },
  { subtag: "ptq", description: "Pattapu" },
  { subtag: "ptr", description: "Piamatsina" },
  { subtag: "ptt", description: "Enrekang" },
  { subtag: "ptu", description: "Bambam" },
  { subtag: "ptv", description: "Port Vato" },
  { subtag: "ptw", description: "Pentlatch" },
  { subtag: "pty", description: "Pathiya" },
  { subtag: "pua", description: "Western Highland Purepecha" },
  { subtag: "pub", description: "Purum" },
  { subtag: "puc", description: "Punan Merap" },
  { subtag: "pud", description: "Punan Aput" },
  { subtag: "pue", description: "Puelche" },
  { subtag: "puf", description: "Punan Merah" },
  { subtag: "pug", description: "Phuie" },
  { subtag: "pui", description: "Puinave" },
  { subtag: "puj", description: "Punan Tubu" },
  { subtag: "pum", description: "Puma" },
  { subtag: "puo", description: "Puoc" },
  { subtag: "pup", description: "Pulabu" },
  { subtag: "puq", description: "Puquina" },
  { subtag: "pur", description: "Puruborá" },
  { subtag: "put", description: "Putoh" },
  { subtag: "puu", description: "Punu" },
  { subtag: "puw", description: "Puluwatese" },
  { subtag: "pux", description: "Puare" },
  { subtag: "puy", description: "Purisimeño" },
  { subtag: "pwa", description: "Pawaia" },
  { subtag: "pwb", description: "Panawa" },
  { subtag: "pwg", description: "Gapapaiwa" },
  { subtag: "pwi", description: "Patwin" },
  { subtag: "pwm", description: "Molbog" },
  { subtag: "pwn", description: "Paiwan" },
  { subtag: "pwo", description: "Pwo Western Karen" },
  { subtag: "pwr", description: "Powari" },
  { subtag: "pww", description: "Pwo Northern Karen" },
  { subtag: "pxm", description: "Quetzaltepec Mixe" },
  { subtag: "pye", description: "Pye Krumen" },
  { subtag: "pym", description: "Fyam" },
  { subtag: "pyn", description: "Poyanáwa" },
  { subtag: "pys", description: "Paraguayan Sign Language, Lengua de Señas del Paraguay" },
  { subtag: "pyu", description: "Puyuma" },
  { subtag: "pyx", description: "Pyu (Myanmar)" },
  { subtag: "pyy", description: "Pyen" },
  { subtag: "pzh", description: "Pazeh" },
  { subtag: "pzn", description: "Jejara Naga, Para Naga" },
  { subtag: "qua", description: "Quapaw" },
  { subtag: "qub", description: "Huallaga Huánuco Quechua" },
  { subtag: "quc", description: "K'iche', Quiché" },
  { subtag: "qud", description: "Calderón Highland Quichua" },
  { subtag: "quf", description: "Lambayeque Quechua" },
  { subtag: "qug", description: "Chimborazo Highland Quichua" },
  { subtag: "quh", description: "South Bolivian Quechua" },
  { subtag: "qui", description: "Quileute" },
  { subtag: "quk", description: "Chachapoyas Quechua" },
  { subtag: "qul", description: "North Bolivian Quechua" },
  { subtag: "qum", description: "Sipacapense" },
  { subtag: "qun", description: "Quinault" },
  { subtag: "qup", description: "Southern Pastaza Quechua" },
  { subtag: "quq", description: "Quinqui" },
  { subtag: "qur", description: "Yanahuanca Pasco Quechua" },
  { subtag: "qus", description: "Santiago del Estero Quichua" },
  { subtag: "quv", description: "Sacapulteco" },
  { subtag: "quw", description: "Tena Lowland Quichua" },
  { subtag: "qux", description: "Yauyos Quechua" },
  { subtag: "quy", description: "Ayacucho Quechua" },
  { subtag: "quz", description: "Cusco Quechua" },
  { subtag: "qva", description: "Ambo-Pasco Quechua" },
  { subtag: "qvc", description: "Cajamarca Quechua" },
  { subtag: "qve", description: "Eastern Apurímac Quechua" },
  { subtag: "qvh", description: "Huamalíes-Dos de Mayo Huánuco Quechua" },
  { subtag: "qvi", description: "Imbabura Highland Quichua" },
  { subtag: "qvj", description: "Loja Highland Quichua" },
  { subtag: "qvl", description: "Cajatambo North Lima Quechua" },
  { subtag: "qvm", description: "Margos-Yarowilca-Lauricocha Quechua" },
  { subtag: "qvn", description: "North Junín Quechua" },
  { subtag: "qvo", description: "Napo Lowland Quechua" },
  { subtag: "qvp", description: "Pacaraos Quechua" },
  { subtag: "qvs", description: "San Martín Quechua" },
  { subtag: "qvw", description: "Huaylla Wanca Quechua" },
  { subtag: "qvy", description: "Queyu" },
  { subtag: "qvz", description: "Northern Pastaza Quichua" },
  { subtag: "qwa", description: "Corongo Ancash Quechua" },
  { subtag: "qwc", description: "Classical Quechua" },
  { subtag: "qwe", description: "Quechuan (family)" },
  { subtag: "qwh", description: "Huaylas Ancash Quechua" },
  { subtag: "qwm", description: "Kuman (Russia)" },
  { subtag: "qws", description: "Sihuas Ancash Quechua" },
  { subtag: "qwt", description: "Kwalhioqua-Tlatskanai" },
  { subtag: "qxa", description: "Chiquián Ancash Quechua" },
  { subtag: "qxc", description: "Chincha Quechua" },
  { subtag: "qxh", description: "Panao Huánuco Quechua" },
  { subtag: "qxl", description: "Salasaca Highland Quichua" },
  { subtag: "qxn", description: "Northern Conchucos Ancash Quechua" },
  { subtag: "qxo", description: "Southern Conchucos Ancash Quechua" },
  { subtag: "qxp", description: "Puno Quechua" },
  { subtag: "qxq", description: "Qashqa'i" },
  { subtag: "qxr", description: "Cañar Highland Quichua" },
  { subtag: "qxs", description: "Southern Qiang" },
  { subtag: "qxt", description: "Santa Ana de Tusi Pasco Quechua" },
  { subtag: "qxu", description: "Arequipa-La Unión Quechua" },
  { subtag: "qxw", description: "Jauja Wanca Quechua" },
  { subtag: "qya", description: "Quenya" },
  { subtag: "qyp", description: "Quiripi" },
  { subtag: "raa", description: "Dungmali" },
  { subtag: "rab", description: "Camling" },
  { subtag: "rac", description: "Rasawa" },
  { subtag: "rad", description: "Rade" },
  { subtag: "raf", description: "Western Meohang" },
  { subtag: "rag", description: "Logooli, Lulogooli" },
  { subtag: "rah", description: "Rabha" },
  { subtag: "rai", description: "Ramoaaina" },
  { subtag: "raj", description: "Rajasthani" },
  { subtag: "rak", description: "Tulu-Bohuai" },
  { subtag: "ral", description: "Ralte" },
  { subtag: "ram", description: "Canela" },
  { subtag: "ran", description: "Riantana" },
  { subtag: "rao", description: "Rao" },
  { subtag: "rap", description: "Rapanui" },
  { subtag: "raq", description: "Saam" },
  { subtag: "rar", description: "Rarotongan, Cook Islands Maori" },
  { subtag: "ras", description: "Tegali" },
  { subtag: "rat", description: "Razajerdi" },
  { subtag: "rau", description: "Raute" },
  { subtag: "rav", description: "Sampang" },
  { subtag: "raw", description: "Rawang" },
  { subtag: "rax", description: "Rang" },
  { subtag: "ray", description: "Rapa" },
  { subtag: "raz", description: "Rahambuu" },
  { subtag: "rbb", description: "Rumai Palaung" },
  { subtag: "rbk", description: "Northern Bontok" },
  { subtag: "rbl", description: "Miraya Bikol" },
  { subtag: "rbp", description: "Barababaraba" },
  { subtag: "rcf", description: "Réunion Creole French" },
  { subtag: "rdb", description: "Rudbari" },
  { subtag: "rea", description: "Rerau" },
  { subtag: "reb", description: "Rembong" },
  { subtag: "ree", description: "Rejang Kayan" },
  { subtag: "reg", description: "Kara (Tanzania)" },
  { subtag: "rei", description: "Reli" },
  { subtag: "rej", description: "Rejang" },
  { subtag: "rel", description: "Rendille" },
  { subtag: "rem", description: "Remo" },
  { subtag: "ren", description: "Rengao" },
  { subtag: "rer", description: "Rer Bare" },
  { subtag: "res", description: "Reshe" },
  { subtag: "ret", description: "Retta" },
  { subtag: "rey", description: "Reyesano" },
  { subtag: "rga", description: "Roria" },
  { subtag: "rge", description: "Romano-Greek" },
  { subtag: "rgk", description: "Rangkas" },
  { subtag: "rgn", description: "Romagnol" },
  { subtag: "rgr", description: "Resígaro" },
  { subtag: "rgs", description: "Southern Roglai" },
  { subtag: "rgu", description: "Ringgou" },
  { subtag: "rhg", description: "Rohingya" },
  { subtag: "rhp", description: "Yahang" },
  { subtag: "ria", description: "Riang (India)" },
  { subtag: "rib", description: "Bribri Sign Language" },
  { subtag: "rif", description: "Tarifit" },
  { subtag: "ril", description: "Riang Lang, Riang (Myanmar)" },
  { subtag: "rim", description: "Nyaturu" },
  { subtag: "rin", description: "Nungu" },
  { subtag: "rir", description: "Ribun" },
  { subtag: "rit", description: "Ritharrngu" },
  { subtag: "riu", description: "Riung" },
  { subtag: "rjg", description: "Rajong" },
  { subtag: "rji", description: "Raji" },
  { subtag: "rjs", description: "Rajbanshi" },
  { subtag: "rka", description: "Kraol" },
  { subtag: "rkb", description: "Rikbaktsa" },
  { subtag: "rkh", description: "Rakahanga-Manihiki" },
  { subtag: "rki", description: "Rakhine" },
  { subtag: "rkm", description: "Marka" },
  { subtag: "rkt", description: "Rangpuri, Kamta" },
  { subtag: "rkw", description: "Arakwal" },
  { subtag: "rma", description: "Rama" },
  { subtag: "rmb", description: "Rembarrnga" },
  { subtag: "rmc", description: "Carpathian Romani" },
  { subtag: "rmd", description: "Traveller Danish" },
  { subtag: "rme", description: "Angloromani" },
  { subtag: "rmf", description: "Kalo Finnish Romani" },
  { subtag: "rmg", description: "Traveller Norwegian" },
  { subtag: "rmh", description: "Murkim" },
  { subtag: "rmi", description: "Lomavren" },
  { subtag: "rmk", description: "Romkun" },
  { subtag: "rml", description: "Baltic Romani" },
  { subtag: "rmm", description: "Roma" },
  { subtag: "rmn", description: "Balkan Romani" },
  { subtag: "rmo", description: "Sinte Romani" },
  { subtag: "rmp", description: "Rempi" },
  { subtag: "rmq", description: "Caló" },
  { subtag: "rms", description: "Romanian Sign Language" },
  { subtag: "rmt", description: "Domari" },
  { subtag: "rmu", description: "Tavringer Romani" },
  { subtag: "rmv", description: "Romanova" },
  { subtag: "rmw", description: "Welsh Romani" },
  { subtag: "rmx", description: "Romam" },
  { subtag: "rmy", description: "Vlax Romani" },
  { subtag: "rmz", description: "Marma" },
  { subtag: "rnb", description: "Brunca Sign Language" },
  { subtag: "rnd", description: "Ruund" },
  { subtag: "rng", description: "Ronga" },
  { subtag: "rnl", description: "Ranglong" },
  { subtag: "rnn", description: "Roon" },
  { subtag: "rnp", description: "Rongpo" },
  { subtag: "rnr", description: "Nari Nari" },
  { subtag: "rnw", description: "Rungwa" },
  { subtag: "roa", description: "Romance languages" },
  { subtag: "rob", description: "Tae'" },
  { subtag: "roc", description: "Cacgia Roglai" },
  { subtag: "rod", description: "Rogo" },
  { subtag: "roe", description: "Ronji" },
  { subtag: "rof", description: "Rombo" },
  { subtag: "rog", description: "Northern Roglai" },
  { subtag: "rol", description: "Romblomanon" },
  { subtag: "rom", description: "Romany" },
  { subtag: "roo", description: "Rotokas" },
  { subtag: "rop", description: "Kriol" },
  { subtag: "ror", description: "Rongga" },
  { subtag: "rou", description: "Runga" },
  { subtag: "row", description: "Dela-Oenale" },
  { subtag: "rpn", description: "Repanbitip" },
  { subtag: "rpt", description: "Rapting" },
  { subtag: "rri", description: "Ririo" },
  { subtag: "rro", description: "Waima" },
  { subtag: "rrt", description: "Arritinngithigh" },
  { subtag: "rsb", description: "Romano-Serbian" },
  { subtag: "rsk", description: "Ruthenian, Rusyn" },
  { subtag: "rsl", description: "Russian Sign Language" },
  { subtag: "rsm", description: "Miriwoong Sign Language" },
  { subtag: "rsn", description: "Rwandan Sign Language" },
  { subtag: "rtc", description: "Rungtu Chin" },
  { subtag: "rth", description: "Ratahan" },
  { subtag: "rtm", description: "Rotuman" },
  { subtag: "rts", description: "Yurats" },
  { subtag: "rtw", description: "Rathawi" },
  { subtag: "rub", description: "Gungu" },
  { subtag: "ruc", description: "Ruuli" },
  { subtag: "rue", description: "Rusyn" },
  { subtag: "ruf", description: "Luguru" },
  { subtag: "rug", description: "Roviana" },
  { subtag: "ruh", description: "Ruga" },
  { subtag: "rui", description: "Rufiji" },
  { subtag: "ruk", description: "Che" },
  { subtag: "ruo", description: "Istro Romanian" },
  { subtag: "rup", description: "Macedo-Romanian, Aromanian, Arumanian" },
  { subtag: "ruq", description: "Megleno Romanian" },
  { subtag: "rut", description: "Rutul" },
  { subtag: "ruu", description: "Lanas Lobu" },
  { subtag: "ruy", description: "Mala (Nigeria)" },
  { subtag: "ruz", description: "Ruma" },
  { subtag: "rwa", description: "Rawo" },
  { subtag: "rwk", description: "Rwa" },
  { subtag: "rwl", description: "Ruwila" },
  { subtag: "rwm", description: "Amba (Uganda)" },
  { subtag: "rwo", description: "Rawa" },
  { subtag: "rwr", description: "Marwari (India)" },
  { subtag: "rxd", description: "Ngardi" },
  { subtag: "rxw", description: "Karuwali, Garuwali" },
  { subtag: "ryn", description: "Northern Amami-Oshima" },
  { subtag: "rys", description: "Yaeyama" },
  { subtag: "ryu", description: "Central Okinawan" },
  { subtag: "rzh", description: "Rāziḥī" },
  { subtag: "saa", description: "Saba" },
  { subtag: "sab", description: "Buglere" },
  { subtag: "sac", description: "Meskwaki" },
  { subtag: "sad", description: "Sandawe" },
  { subtag: "sae", description: "Sabanê" },
  { subtag: "saf", description: "Safaliba" },
  { subtag: "sah", description: "Yakut" },
  { subtag: "sai", description: "South American Indian languages" },
  { subtag: "saj", description: "Sahu" },
  { subtag: "sak", description: "Sake" },
  { subtag: "sal", description: "Salishan languages" },
  { subtag: "sam", description: "Samaritan Aramaic" },
  { subtag: "sao", description: "Sause" },
  { subtag: "saq", description: "Samburu" },
  { subtag: "sar", description: "Saraveca" },
  { subtag: "sas", description: "Sasak" },
  { subtag: "sat", description: "Santali" },
  { subtag: "sau", description: "Saleman" },
  { subtag: "sav", description: "Saafi-Saafi" },
  { subtag: "saw", description: "Sawi" },
  { subtag: "sax", description: "Sa" },
  { subtag: "say", description: "Saya" },
  { subtag: "saz", description: "Saurashtra" },
  { subtag: "sba", description: "Ngambay" },
  { subtag: "sbb", description: "Simbo" },
  { subtag: "sbc", description: "Kele (Papua New Guinea)" },
  { subtag: "sbd", description: "Southern Samo" },
  { subtag: "sbe", description: "Saliba" },
  { subtag: "sbf", description: "Chabu, Shabo" },
  { subtag: "sbg", description: "Seget" },
  { subtag: "sbh", description: "Sori-Harengan" },
  { subtag: "sbi", description: "Seti" },
  { subtag: "sbj", description: "Surbakhal" },
  { subtag: "sbk", description: "Safwa" },
  { subtag: "sbl", description: "Botolan Sambal" },
  { subtag: "sbm", description: "Sagala" },
  { subtag: "sbn", description: "Sindhi Bhil" },
  { subtag: "sbo", description: "Sabüm" },
  { subtag: "sbp", description: "Sangu (Tanzania)" },
  { subtag: "sbq", description: "Sileibi" },
  { subtag: "sbr", description: "Sembakung Murut" },
  { subtag: "sbs", description: "Subiya" },
  { subtag: "sbt", description: "Kimki" },
  { subtag: "sbu", description: "Stod Bhoti" },
  { subtag: "sbv", description: "Sabine" },
  { subtag: "sbw", description: "Simba" },
  { subtag: "sbx", description: "Seberuang" },
  { subtag: "sby", description: "Soli" },
  { subtag: "sbz", description: "Sara Kaba" },
  { subtag: "scb", description: "Chut" },
  { subtag: "sce", description: "Dongxiang" },
  { subtag: "scf", description: "San Miguel Creole French" },
  { subtag: "scg", description: "Sanggau" },
  { subtag: "sch", description: "Sakachep" },
  { subtag: "sci", description: "Sri Lankan Creole Malay" },
  { subtag: "sck", description: "Sadri" },
  { subtag: "scl", description: "Shina" },
  { subtag: "scn", description: "Sicilian" },
  { subtag: "sco", description: "Scots" },
  { subtag: "scp", description: "Hyolmo, Helambu Sherpa" },
  { subtag: "scq", description: "Sa'och" },
  { subtag: "scs", description: "North Slavey" },
  { subtag: "sct", description: "Southern Katang" },
  { subtag: "scu", description: "Shumcho" },
  { subtag: "scv", description: "Sheni" },
  { subtag: "scw", description: "Sha" },
  { subtag: "scx", description: "Sicel" },
  { subtag: "sda", description: "Toraja-Sa'dan" },
  { subtag: "sdb", description: "Shabak" },
  { subtag: "sdc", description: "Sassarese Sardinian" },
  { subtag: "sde", description: "Surubu" },
  { subtag: "sdf", description: "Sarli" },
  { subtag: "sdg", description: "Savi" },
  { subtag: "sdh", description: "Southern Kurdish" },
  { subtag: "sdj", description: "Suundi" },
  { subtag: "sdk", description: "Sos Kundi" },
  { subtag: "sdl", description: "Saudi Arabian Sign Language" },
  { subtag: "sdn", description: "Gallurese Sardinian" },
  { subtag: "sdo", description: "Bukar-Sadung Bidayuh" },
  { subtag: "sdp", description: "Sherdukpen" },
  { subtag: "sdq", description: "Semandang" },
  { subtag: "sdr", description: "Oraon Sadri" },
  { subtag: "sds", description: "Sened" },
  { subtag: "sdt", description: "Shuadit" },
  { subtag: "sdu", description: "Sarudu" },
  { subtag: "sdv", description: "Eastern Sudanic languages" },
  { subtag: "sdx", description: "Sibu Melanau" },
  { subtag: "sdz", description: "Sallands" },
  { subtag: "sea", description: "Semai" },
  { subtag: "seb", description: "Shempire Senoufo" },
  { subtag: "sec", description: "Sechelt" },
  { subtag: "sed", description: "Sedang" },
  { subtag: "see", description: "Seneca" },
  { subtag: "sef", description: "Cebaara Senoufo" },
  { subtag: "seg", description: "Segeju" },
  { subtag: "seh", description: "Sena" },
  { subtag: "sei", description: "Seri" },
  { subtag: "sej", description: "Sene" },
  { subtag: "sek", description: "Sekani" },
  { subtag: "sel", description: "Selkup" },
  { subtag: "sem", description: "Semitic languages" },
  { subtag: "sen", description: "Nanerigé Sénoufo" },
  { subtag: "seo", description: "Suarmin" },
  { subtag: "sep", description: "Sìcìté Sénoufo" },
  { subtag: "seq", description: "Senara Sénoufo" },
  { subtag: "ser", description: "Serrano" },
  { subtag: "ses", description: "Koyraboro Senni Songhai" },
  { subtag: "set", description: "Sentani" },
  { subtag: "seu", description: "Serui-Laut" },
  { subtag: "sev", description: "Nyarafolo Senoufo" },
  { subtag: "sew", description: "Sewa Bay" },
  { subtag: "sey", description: "Secoya" },
  { subtag: "sez", description: "Senthang Chin" },
  { subtag: "sfb", description: "Langue des signes de Belgique Francophone, French Belgian Sign Language" },
  { subtag: "sfe", description: "Eastern Subanen" },
  { subtag: "sfm", description: "Small Flowery Miao" },
  { subtag: "sfs", description: "South African Sign Language" },
  { subtag: "sfw", description: "Sehwi" },
  { subtag: "sga", description: "Old Irish (to 900)" },
  { subtag: "sgb", description: "Mag-antsi Ayta" },
  { subtag: "sgc", description: "Kipsigis" },
  { subtag: "sgd", description: "Surigaonon" },
  { subtag: "sge", description: "Segai" },
  { subtag: "sgg", description: "Swiss-German Sign Language" },
  { subtag: "sgh", description: "Shughni" },
  { subtag: "sgi", description: "Suga" },
  { subtag: "sgj", description: "Surgujia" },
  { subtag: "sgk", description: "Sangkong" },
  { subtag: "sgm", description: "Singa" },
  { subtag: "sgn", description: "Sign languages" },
  { subtag: "sgp", description: "Singpho" },
  { subtag: "sgr", description: "Sangisari" },
  { subtag: "sgs", description: "Samogitian" },
  { subtag: "sgt", description: "Brokpake" },
  { subtag: "sgu", description: "Salas" },
  { subtag: "sgw", description: "Sebat Bet Gurage" },
  { subtag: "sgx", description: "Sierra Leone Sign Language" },
  { subtag: "sgy", description: "Sanglechi" },
  { subtag: "sgz", description: "Sursurunga" },
  { subtag: "sha", description: "Shall-Zwall" },
  { subtag: "shb", description: "Ninam" },
  { subtag: "shc", description: "Sonde" },
  { subtag: "shd", description: "Kundal Shahi" },
  { subtag: "she", description: "Sheko" },
  { subtag: "shg", description: "Shua" },
  { subtag: "shh", description: "Shoshoni" },
  { subtag: "shi", description: "Tachelhit" },
  { subtag: "shj", description: "Shatt" },
  { subtag: "shk", description: "Shilluk" },
  { subtag: "shl", description: "Shendu" },
  { subtag: "shm", description: "Shahrudi" },
  { subtag: "shn", description: "Shan" },
  { subtag: "sho", description: "Shanga" },
  { subtag: "shp", description: "Shipibo-Conibo" },
  { subtag: "shq", description: "Sala" },
  { subtag: "shr", description: "Shi" },
  { subtag: "shs", description: "Shuswap" },
  { subtag: "sht", description: "Shasta" },
  { subtag: "shu", description: "Chadian Arabic" },
  { subtag: "shv", description: "Shehri" },
  { subtag: "shw", description: "Shwai" },
  { subtag: "shx", description: "She" },
  { subtag: "shy", description: "Tachawit" },
  { subtag: "shz", description: "Syenara Senoufo" },
  { subtag: "sia", description: "Akkala Sami" },
  { subtag: "sib", description: "Sebop" },
  { subtag: "sid", description: "Sidamo" },
  { subtag: "sie", description: "Simaa" },
  { subtag: "sif", description: "Siamou" },
  { subtag: "sig", description: "Paasaal" },
  { subtag: "sih", description: "Zire, Sîshëë" },
  { subtag: "sii", description: "Shom Peng" },
  { subtag: "sij", description: "Numbami" },
  { subtag: "sik", description: "Sikiana" },
  { subtag: "sil", description: "Tumulung Sisaala" },
  { subtag: "sim", description: "Mende (Papua New Guinea)" },
  { subtag: "sio", description: "Siouan languages" },
  { subtag: "sip", description: "Sikkimese" },
  { subtag: "siq", description: "Sonia" },
  { subtag: "sir", description: "Siri" },
  { subtag: "sis", description: "Siuslaw" },
  { subtag: "sit", description: "Sino-Tibetan languages" },
  { subtag: "siu", description: "Sinagen" },
  { subtag: "siv", description: "Sumariup" },
  { subtag: "siw", description: "Siwai" },
  { subtag: "six", description: "Sumau" },
  { subtag: "siy", description: "Sivandi" },
  { subtag: "siz", description: "Siwi" },
  { subtag: "sja", description: "Epena" },
  { subtag: "sjb", description: "Sajau Basap" },
  { subtag: "sjd", description: "Kildin Sami" },
  { subtag: "sje", description: "Pite Sami" },
  { subtag: "sjg", description: "Assangori" },
  { subtag: "sjk", description: "Kemi Sami" },
  { subtag: "sjl", description: "Sajalong, Miji" },
  { subtag: "sjm", description: "Mapun" },
  { subtag: "sjn", description: "Sindarin" },
  { subtag: "sjo", description: "Xibe" },
  { subtag: "sjp", description: "Surjapuri" },
  { subtag: "sjr", description: "Siar-Lak" },
  { subtag: "sjs", description: "Senhaja De Srair" },
  { subtag: "sjt", description: "Ter Sami" },
  { subtag: "sju", description: "Ume Sami" },
  { subtag: "sjw", description: "Shawnee" },
  { subtag: "ska", description: "Skagit" },
  { subtag: "skb", description: "Saek" },
  { subtag: "skc", description: "Ma Manda" },
  { subtag: "skd", description: "Southern Sierra Miwok" },
  { subtag: "ske", description: "Seke (Vanuatu)" },
  { subtag: "skf", description: "Sakirabiá" },
  { subtag: "skg", description: "Sakalava Malagasy" },
  { subtag: "skh", description: "Sikule" },
  { subtag: "ski", description: "Sika" },
  { subtag: "skj", description: "Seke (Nepal)" },
  { subtag: "skm", description: "Kutong" },
  { subtag: "skn", description: "Kolibugan Subanon" },
  { subtag: "sko", description: "Seko Tengah" },
  { subtag: "skp", description: "Sekapan" },
  { subtag: "skq", description: "Sininkere" },
  { subtag: "skr", description: "Saraiki, Seraiki" },
  { subtag: "sks", description: "Maia" },
  { subtag: "skt", description: "Sakata" },
  { subtag: "sku", description: "Sakao" },
  { subtag: "skv", description: "Skou" },
  { subtag: "skw", description: "Skepi Creole Dutch" },
  { subtag: "skx", description: "Seko Padang" },
  { subtag: "sky", description: "Sikaiana" },
  { subtag: "skz", description: "Sekar" },
  { subtag: "sla", description: "Slavic languages" },
  { subtag: "slc", description: "Sáliba" },
  { subtag: "sld", description: "Sissala" },
  { subtag: "sle", description: "Sholaga" },
  { subtag: "slf", description: "Swiss-Italian Sign Language" },
  { subtag: "slg", description: "Selungai Murut" },
  { subtag: "slh", description: "Southern Puget Sound Salish" },
  { subtag: "sli", description: "Lower Silesian" },
  { subtag: "slj", description: "Salumá" },
  { subtag: "sll", description: "Salt-Yui" },
  { subtag: "slm", description: "Pangutaran Sama" },
  { subtag: "sln", description: "Salinan" },
  { subtag: "slp", description: "Lamaholot" },
  { subtag: "slq", description: "Salchuq" },
  { subtag: "slr", description: "Salar" },
  { subtag: "sls", description: "Singapore Sign Language" },
  { subtag: "slt", description: "Sila" },
  { subtag: "slu", description: "Selaru" },
  { subtag: "slw", description: "Sialum" },
  { subtag: "slx", description: "Salampasu" },
  { subtag: "sly", description: "Selayar" },
  { subtag: "slz", description: "Ma'ya" },
  { subtag: "sma", description: "Southern Sami" },
  { subtag: "smb", description: "Simbari" },
  { subtag: "smc", description: "Som" },
  { subtag: "smf", description: "Auwe" },
  { subtag: "smg", description: "Simbali" },
  { subtag: "smh", description: "Samei" },
  { subtag: "smi", description: "Sami languages" },
  { subtag: "smj", description: "Lule Sami" },
  { subtag: "smk", description: "Bolinao" },
  { subtag: "sml", description: "Central Sama" },
  { subtag: "smm", description: "Musasa" },
  { subtag: "smn", description: "Inari Sami" },
  { subtag: "smp", description: "Samaritan" },
  { subtag: "smq", description: "Samo" },
  { subtag: "smr", description: "Simeulue" },
  { subtag: "sms", description: "Skolt Sami" },
  { subtag: "smt", description: "Simte" },
  { subtag: "smu", description: "Somray" },
  { subtag: "smv", description: "Samvedi" },
  { subtag: "smw", description: "Sumbawa" },
  { subtag: "smx", description: "Samba" },
  { subtag: "smy", description: "Semnani" },
  { subtag: "smz", description: "Simeku" },
  { subtag: "snc", description: "Sinaugoro" },
  { subtag: "sne", description: "Bau Bidayuh" },
  { subtag: "snf", description: "Noon" },
  { subtag: "sng", description: "Sanga (Democratic Republic of Congo)" },
  { subtag: "sni", description: "Sensi" },
  { subtag: "snj", description: "Riverain Sango" },
  { subtag: "snk", description: "Soninke" },
  { subtag: "snl", description: "Sangil" },
  { subtag: "snm", description: "Southern Ma'di" },
  { subtag: "snn", description: "Siona" },
  { subtag: "sno", description: "Snohomish" },
  { subtag: "snp", description: "Siane" },
  { subtag: "snq", description: "Sangu (Gabon)" },
  { subtag: "snr", description: "Sihan" },
  { subtag: "sns", description: "South West Bay, Nahavaq" },
  { subtag: "snu", description: "Senggi, Viid" },
  { subtag: "snv", description: "Sa'ban" },
  { subtag: "snw", description: "Selee" },
  { subtag: "snx", description: "Sam" },
  { subtag: "sny", description: "Saniyo-Hiyewe" },
  { subtag: "snz", description: "Kou" },
  { subtag: "soa", description: "Thai Song" },
  { subtag: "sob", description: "Sobei" },
  { subtag: "soc", description: "So (Democratic Republic of Congo)" },
  { subtag: "sod", description: "Songoora" },
  { subtag: "soe", description: "Songomeno" },
  { subtag: "sog", description: "Sogdian" },
  { subtag: "soh", description: "Aka" },
  { subtag: "soi", description: "Sonha" },
  { subtag: "soj", description: "Soi" },
  { subtag: "sok", description: "Sokoro" },
  { subtag: "sol", description: "Solos" },
  { subtag: "son", description: "Songhai languages" },
  { subtag: "soo", description: "Songo" },
  { subtag: "sop", description: "Songe" },
  { subtag: "soq", description: "Kanasi" },
  { subtag: "sor", description: "Somrai" },
  { subtag: "sos", description: "Seeku" },
  { subtag: "sou", description: "Southern Thai" },
  { subtag: "sov", description: "Sonsorol" },
  { subtag: "sow", description: "Sowanda" },
  { subtag: "sox", description: "Swo" },
  { subtag: "soy", description: "Miyobe" },
  { subtag: "soz", description: "Temi" },
  { subtag: "spb", description: "Sepa (Indonesia)" },
  { subtag: "spc", description: "Sapé" },
  { subtag: "spd", description: "Saep" },
  { subtag: "spe", description: "Sepa (Papua New Guinea)" },
  { subtag: "spg", description: "Sian" },
  { subtag: "spi", description: "Saponi" },
  { subtag: "spk", description: "Sengo" },
  { subtag: "spl", description: "Selepet" },
  { subtag: "spm", description: "Akukem" },
  { subtag: "spn", description: "Sanapaná" },
  { subtag: "spo", description: "Spokane" },
  { subtag: "spp", description: "Supyire Senoufo" },
  { subtag: "spq", description: "Loreto-Ucayali Spanish" },
  { subtag: "spr", description: "Saparua" },
  { subtag: "sps", description: "Saposa" },
  { subtag: "spt", description: "Spiti Bhoti" },
  { subtag: "spu", description: "Sapuan" },
  { subtag: "spv", description: "Sambalpuri, Kosli" },
  { subtag: "spx", description: "South Picene" },
  { subtag: "spy", description: "Sabaot" },
  { subtag: "sqa", description: "Shama-Sambuga" },
  { subtag: "sqh", description: "Shau" },
  { subtag: "sqj", description: "Albanian languages" },
  { subtag: "sqk", description: "Albanian Sign Language" },
  { subtag: "sqm", description: "Suma" },
  { subtag: "sqn", description: "Susquehannock" },
  { subtag: "sqo", description: "Sorkhei" },
  { subtag: "sqq", description: "Sou" },
  { subtag: "sqr", description: "Siculo Arabic" },
  { subtag: "sqs", description: "Sri Lankan Sign Language" },
  { subtag: "sqt", description: "Soqotri" },
  { subtag: "squ", description: "Squamish" },
  { subtag: "sqx", description: "Kufr Qassem Sign Language (KQSL)" },
  { subtag: "sra", description: "Saruga" },
  { subtag: "srb", description: "Sora" },
  { subtag: "src", description: "Logudorese Sardinian" },
  { subtag: "sre", description: "Sara" },
  { subtag: "srf", description: "Nafi" },
  { subtag: "srg", description: "Sulod" },
  { subtag: "srh", description: "Sarikoli" },
  { subtag: "sri", description: "Siriano" },
  { subtag: "srk", description: "Serudung Murut" },
  { subtag: "srl", description: "Isirawa" },
  { subtag: "srm", description: "Saramaccan" },
  { subtag: "srn", description: "Sranan Tongo" },
  { subtag: "sro", description: "Campidanese Sardinian" },
  { subtag: "srq", description: "Sirionó" },
  { subtag: "srr", description: "Serer" },
  { subtag: "srs", description: "Sarsi" },
  { subtag: "srt", description: "Sauri" },
  { subtag: "sru", description: "Suruí" },
  { subtag: "srv", description: "Southern Sorsoganon" },
  { subtag: "srw", description: "Serua" },
  { subtag: "srx", description: "Sirmauri" },
  { subtag: "sry", description: "Sera" },
  { subtag: "srz", description: "Shahmirzadi" },
  { subtag: "ssa", description: "Nilo-Saharan languages" },
  { subtag: "ssb", description: "Southern Sama" },
  { subtag: "ssc", description: "Suba-Simbiti" },
  { subtag: "ssd", description: "Siroi" },
  { subtag: "sse", description: "Balangingi, Bangingih Sama" },
  { subtag: "ssf", description: "Thao" },
  { subtag: "ssg", description: "Seimat" },
  { subtag: "ssh", description: "Shihhi Arabic" },
  { subtag: "ssi", description: "Sansi" },
  { subtag: "ssj", description: "Sausi" },
  { subtag: "ssk", description: "Sunam" },
  { subtag: "ssl", description: "Western Sisaala" },
  { subtag: "ssm", description: "Semnam" },
  { subtag: "ssn", description: "Waata" },
  { subtag: "sso", description: "Sissano" },
  { subtag: "ssp", description: "Spanish Sign Language" },
  { subtag: "ssq", description: "So'a" },
  { subtag: "ssr", description: "Swiss-French Sign Language" },
  { subtag: "sss", description: "Sô" },
  { subtag: "sst", description: "Sinasina" },
  { subtag: "ssu", description: "Susuami" },
  { subtag: "ssv", description: "Shark Bay" },
  { subtag: "ssx", description: "Samberigi" },
  { subtag: "ssy", description: "Saho" },
  { subtag: "ssz", description: "Sengseng" },
  { subtag: "sta", description: "Settla" },
  { subtag: "stb", description: "Northern Subanen" },
  { subtag: "std", description: "Sentinel" },
  { subtag: "ste", description: "Liana-Seti" },
  { subtag: "stf", description: "Seta" },
  { subtag: "stg", description: "Trieng" },
  { subtag: "sth", description: "Shelta" },
  { subtag: "sti", description: "Bulo Stieng" },
  { subtag: "stj", description: "Matya Samo" },
  { subtag: "stk", description: "Arammba" },
  { subtag: "stl", description: "Stellingwerfs" },
  { subtag: "stm", description: "Setaman" },
  { subtag: "stn", description: "Owa" },
  { subtag: "sto", description: "Stoney" },
  { subtag: "stp", description: "Southeastern Tepehuan" },
  { subtag: "stq", description: "Saterfriesisch" },
  { subtag: "str", description: "Straits Salish" },
  { subtag: "sts", description: "Shumashti" },
  { subtag: "stt", description: "Budeh Stieng" },
  { subtag: "stu", description: "Samtao" },
  { subtag: "stv", description: "Silt'e" },
  { subtag: "stw", description: "Satawalese" },
  { subtag: "sty", description: "Siberian Tatar" },
  { subtag: "sua", description: "Sulka" },
  { subtag: "sub", description: "Suku" },
  { subtag: "suc", description: "Western Subanon" },
  { subtag: "sue", description: "Suena" },
  { subtag: "sug", description: "Suganga" },
  { subtag: "sui", description: "Suki" },
  { subtag: "suj", description: "Shubi" },
  { subtag: "suk", description: "Sukuma" },
  { subtag: "suo", description: "Bouni" },
  { subtag: "suq", description: "Tirmaga-Chai Suri, Suri" },
  { subtag: "sur", description: "Mwaghavul" },
  { subtag: "sus", description: "Susu" },
  { subtag: "sut", description: "Subtiaba" },
  { subtag: "suv", description: "Puroik" },
  { subtag: "suw", description: "Sumbwa" },
  { subtag: "sux", description: "Sumerian" },
  { subtag: "suy", description: "Suyá" },
  { subtag: "suz", description: "Sunwar" },
  { subtag: "sva", description: "Svan" },
  { subtag: "svb", description: "Ulau-Suain" },
  { subtag: "svc", description: "Vincentian Creole English" },
  { subtag: "sve", description: "Serili" },
  { subtag: "svk", description: "Slovakian Sign Language" },
  { subtag: "svm", description: "Slavomolisano" },
  { subtag: "svs", description: "Savosavo" },
  { subtag: "svx", description: "Skalvian" },
  { subtag: "swb", description: "Maore Comorian" },
  { subtag: "swc", description: "Congo Swahili" },
  { subtag: "swf", description: "Sere" },
  { subtag: "swg", description: "Swabian" },
  { subtag: "swh", description: "Swahili (individual language), Kiswahili" },
  { subtag: "swi", description: "Sui" },
  { subtag: "swj", description: "Sira" },
  { subtag: "swk", description: "Malawi Sena" },
  { subtag: "swl", description: "Swedish Sign Language" },
  { subtag: "swm", description: "Samosa" },
  { subtag: "swn", description: "Sawknah" },
  { subtag: "swo", description: "Shanenawa" },
  { subtag: "swp", description: "Suau" },
  { subtag: "swq", description: "Sharwa" },
  { subtag: "swr", description: "Saweru" },
  { subtag: "sws", description: "Seluwasan" },
  { subtag: "swt", description: "Sawila" },
  { subtag: "swu", description: "Suwawa" },
  { subtag: "swv", description: "Shekhawati" },
  { subtag: "sww", description: "Sowa" },
  { subtag: "swx", description: "Suruahá" },
  { subtag: "swy", description: "Sarua" },
  { subtag: "sxb", description: "Suba" },
  { subtag: "sxc", description: "Sicanian" },
  { subtag: "sxe", description: "Sighu" },
  { subtag: "sxg", description: "Shuhi, Shixing" },
  { subtag: "sxk", description: "Southern Kalapuya" },
  { subtag: "sxl", description: "Selian" },
  { subtag: "sxm", description: "Samre" },
  { subtag: "sxn", description: "Sangir" },
  { subtag: "sxo", description: "Sorothaptic" },
  { subtag: "sxr", description: "Saaroa" },
  { subtag: "sxs", description: "Sasaru" },
  { subtag: "sxu", description: "Upper Saxon" },
  { subtag: "sxw", description: "Saxwe Gbe" },
  { subtag: "sya", description: "Siang" },
  { subtag: "syb", description: "Central Subanen" },
  { subtag: "syc", description: "Classical Syriac" },
  { subtag: "syd", description: "Samoyedic languages" },
  { subtag: "syi", description: "Seki" },
  { subtag: "syk", description: "Sukur" },
  { subtag: "syl", description: "Sylheti" },
  { subtag: "sym", description: "Maya Samo" },
  { subtag: "syn", description: "Senaya" },
  { subtag: "syo", description: "Suoy" },
  { subtag: "syr", description: "Syriac" },
  { subtag: "sys", description: "Sinyar" },
  { subtag: "syw", description: "Kagate" },
  { subtag: "syx", description: "Samay" },
  { subtag: "syy", description: "Al-Sayyid Bedouin Sign Language" },
  { subtag: "sza", description: "Semelai" },
  { subtag: "szb", description: "Ngalum" },
  { subtag: "szc", description: "Semaq Beri" },
  { subtag: "szd", description: "Seru" },
  { subtag: "sze", description: "Seze" },
  { subtag: "szg", description: "Sengele" },
  { subtag: "szl", description: "Silesian" },
  { subtag: "szn", description: "Sula" },
  { subtag: "szp", description: "Suabo" },
  { subtag: "szs", description: "Solomon Islands Sign Language" },
  { subtag: "szv", description: "Isu (Fako Division)" },
  { subtag: "szw", description: "Sawai" },
  { subtag: "szy", description: "Sakizaya" },
  { subtag: "taa", description: "Lower Tanana" },
  { subtag: "tab", description: "Tabassaran" },
  { subtag: "tac", description: "Lowland Tarahumara" },
  { subtag: "tad", description: "Tause" },
  { subtag: "tae", description: "Tariana" },
  { subtag: "taf", description: "Tapirapé" },
  { subtag: "tag", description: "Tagoi" },
  { subtag: "tai", description: "Tai languages" },
  { subtag: "taj", description: "Eastern Tamang" },
  { subtag: "tak", description: "Tala" },
  { subtag: "tal", description: "Tal" },
  { subtag: "tan", description: "Tangale" },
  { subtag: "tao", description: "Yami" },
  { subtag: "tap", description: "Taabwa" },
  { subtag: "taq", description: "Tamasheq" },
  { subtag: "tar", description: "Central Tarahumara" },
  { subtag: "tas", description: "Tay Boi" },
  { subtag: "tau", description: "Upper Tanana" },
  { subtag: "tav", description: "Tatuyo" },
  { subtag: "taw", description: "Tai" },
  { subtag: "tax", description: "Tamki" },
  { subtag: "tay", description: "Atayal" },
  { subtag: "taz", description: "Tocho" },
  { subtag: "tba", description: "Aikanã" },
  { subtag: "tbc", description: "Takia" },
  { subtag: "tbd", description: "Kaki Ae" },
  { subtag: "tbe", description: "Tanimbili" },
  { subtag: "tbf", description: "Mandara" },
  { subtag: "tbg", description: "North Tairora" },
  { subtag: "tbh", description: "Dharawal, Thurawal" },
  { subtag: "tbi", description: "Gaam" },
  { subtag: "tbj", description: "Tiang" },
  { subtag: "tbk", description: "Calamian Tagbanwa" },
  { subtag: "tbl", description: "Tboli" },
  { subtag: "tbm", description: "Tagbu" },
  { subtag: "tbn", description: "Barro Negro Tunebo" },
  { subtag: "tbo", description: "Tawala" },
  { subtag: "tbp", description: "Taworta, Diebroud" },
  { subtag: "tbq", description: "Tibeto-Burman languages" },
  { subtag: "tbr", description: "Tumtum" },
  { subtag: "tbs", description: "Tanguat" },
  { subtag: "tbt", description: "Tembo (Kitembo)" },
  { subtag: "tbu", description: "Tubar" },
  { subtag: "tbv", description: "Tobo" },
  { subtag: "tbw", description: "Tagbanwa" },
  { subtag: "tbx", description: "Kapin" },
  { subtag: "tby", description: "Tabaru" },
  { subtag: "tbz", description: "Ditammari" },
  { subtag: "tca", description: "Ticuna" },
  { subtag: "tcb", description: "Tanacross" },
  { subtag: "tcc", description: "Datooga" },
  { subtag: "tcd", description: "Tafi" },
  { subtag: "tce", description: "Southern Tutchone" },
  { subtag: "tcf", description: "Malinaltepec Me'phaa, Malinaltepec Tlapanec" },
  { subtag: "tcg", description: "Tamagario" },
  { subtag: "tch", description: "Turks And Caicos Creole English" },
  { subtag: "tci", description: "Wára" },
  { subtag: "tck", description: "Tchitchege" },
  { subtag: "tcl", description: "Taman (Myanmar)" },
  { subtag: "tcm", description: "Tanahmerah" },
  { subtag: "tcn", description: "Tichurong" },
  { subtag: "tco", description: "Taungyo" },
  { subtag: "tcp", description: "Tawr Chin" },
  { subtag: "tcq", description: "Kaiy" },
  { subtag: "tcs", description: "Torres Strait Creole, Yumplatok" },
  { subtag: "tct", description: "T'en" },
  { subtag: "tcu", description: "Southeastern Tarahumara" },
  { subtag: "tcw", description: "Tecpatlán Totonac" },
  { subtag: "tcx", description: "Toda" },
  { subtag: "tcy", description: "Tulu" },
  { subtag: "tcz", description: "Thado Chin" },
  { subtag: "tda", description: "Tagdal" },
  { subtag: "tdb", description: "Panchpargania" },
  { subtag: "tdc", description: "Emberá-Tadó" },
  { subtag: "tdd", description: "Tai Nüa" },
  { subtag: "tde", description: "Tiranige Diga Dogon" },
  { subtag: "tdf", description: "Talieng" },
  { subtag: "tdg", description: "Western Tamang" },
  { subtag: "tdh", description: "Thulung" },
  { subtag: "tdi", description: "Tomadino" },
  { subtag: "tdj", description: "Tajio" },
  { subtag: "tdk", description: "Tambas" },
  { subtag: "tdl", description: "Sur" },
  { subtag: "tdm", description: "Taruma" },
  { subtag: "tdn", description: "Tondano" },
  { subtag: "tdo", description: "Teme" },
  { subtag: "tdq", description: "Tita" },
  { subtag: "tdr", description: "Todrah" },
  { subtag: "tds", description: "Doutai" },
  { subtag: "tdt", description: "Tetun Dili" },
  { subtag: "tdv", description: "Toro" },
  { subtag: "tdx", description: "Tandroy-Mahafaly Malagasy" },
  { subtag: "tdy", description: "Tadyawan" },
  { subtag: "tea", description: "Temiar" },
  { subtag: "teb", description: "Tetete" },
  { subtag: "tec", description: "Terik" },
  { subtag: "ted", description: "Tepo Krumen" },
  { subtag: "tee", description: "Huehuetla Tepehua" },
  { subtag: "tef", description: "Teressa" },
  { subtag: "teg", description: "Teke-Tege" },
  { subtag: "teh", description: "Tehuelche" },
  { subtag: "tei", description: "Torricelli" },
  { subtag: "tek", description: "Ibali Teke" },
  { subtag: "tem", description: "Timne" },
  { subtag: "ten", description: "Tama (Colombia)" },
  { subtag: "teo", description: "Teso" },
  { subtag: "tep", description: "Tepecano" },
  { subtag: "teq", description: "Temein" },
  { subtag: "ter", description: "Tereno" },
  { subtag: "tes", description: "Tengger" },
  { subtag: "tet", description: "Tetum" },
  { subtag: "teu", description: "Soo" },
  { subtag: "tev", description: "Teor" },
  { subtag: "tew", description: "Tewa (USA)" },
  { subtag: "tex", description: "Tennet" },
  { subtag: "tey", description: "Tulishi" },
  { subtag: "tez", description: "Tetserret" },
  { subtag: "tfi", description: "Tofin Gbe" },
  { subtag: "tfn", description: "Tanaina" },
  { subtag: "tfo", description: "Tefaro" },
  { subtag: "tfr", description: "Teribe" },
  { subtag: "tft", description: "Ternate" },
  { subtag: "tga", description: "Sagalla" },
  { subtag: "tgb", description: "Tobilung" },
  { subtag: "tgc", description: "Tigak" },
  { subtag: "tgd", description: "Ciwogai" },
  { subtag: "tge", description: "Eastern Gorkha Tamang" },
  { subtag: "tgf", description: "Chalikha" },
  { subtag: "tgh", description: "Tobagonian Creole English" },
  { subtag: "tgi", description: "Lawunuia" },
  { subtag: "tgj", description: "Tagin" },
  { subtag: "tgn", description: "Tandaganon" },
  { subtag: "tgo", description: "Sudest" },
  { subtag: "tgp", description: "Tangoa" },
  { subtag: "tgq", description: "Tring" },
  { subtag: "tgr", description: "Tareng" },
  { subtag: "tgs", description: "Nume" },
  { subtag: "tgt", description: "Central Tagbanwa" },
  { subtag: "tgu", description: "Tanggu" },
  { subtag: "tgv", description: "Tingui-Boto" },
  { subtag: "tgw", description: "Tagwana Senoufo" },
  { subtag: "tgx", description: "Tagish" },
  { subtag: "tgy", description: "Togoyo" },
  { subtag: "tgz", description: "Tagalaka" },
  { subtag: "thd", description: "Kuuk Thaayorre, Thayore" },
  { subtag: "the", description: "Chitwania Tharu" },
  { subtag: "thf", description: "Thangmi" },
  { subtag: "thh", description: "Northern Tarahumara" },
  { subtag: "thi", description: "Tai Long" },
  { subtag: "thk", description: "Tharaka, Kitharaka" },
  { subtag: "thl", description: "Dangaura Tharu" },
  { subtag: "thm", description: "Aheu" },
  { subtag: "thn", description: "Thachanadan" },
  { subtag: "thp", description: "Thompson" },
  { subtag: "thq", description: "Kochila Tharu" },
  { subtag: "thr", description: "Rana Tharu" },
  { subtag: "ths", description: "Thakali" },
  { subtag: "tht", description: "Tahltan" },
  { subtag: "thu", description: "Thuri" },
  { subtag: "thv", description: "Tahaggart Tamahaq" },
  { subtag: "thy", description: "Tha" },
  { subtag: "thz", description: "Tayart Tamajeq" },
  { subtag: "tia", description: "Tidikelt Tamazight" },
  { subtag: "tic", description: "Tira" },
  { subtag: "tif", description: "Tifal" },
  { subtag: "tig", description: "Tigre" },
  { subtag: "tih", description: "Timugon Murut" },
  { subtag: "tii", description: "Tiene" },
  { subtag: "tij", description: "Tilung" },
  { subtag: "tik", description: "Tikar" },
  { subtag: "til", description: "Tillamook" },
  { subtag: "tim", description: "Timbe" },
  { subtag: "tin", description: "Tindi" },
  { subtag: "tio", description: "Teop" },
  { subtag: "tip", description: "Trimuris" },
  { subtag: "tiq", description: "Tiéfo" },
  { subtag: "tis", description: "Masadiit Itneg" },
  { subtag: "tit", description: "Tinigua" },
  { subtag: "tiu", description: "Adasen" },
  { subtag: "tiv", description: "Tiv" },
  { subtag: "tiw", description: "Tiwi" },
  { subtag: "tix", description: "Southern Tiwa" },
  { subtag: "tiy", description: "Tiruray" },
  { subtag: "tiz", description: "Tai Hongjin" },
  { subtag: "tja", description: "Tajuasohn" },
  { subtag: "tjg", description: "Tunjung" },
  { subtag: "tji", description: "Northern Tujia" },
  { subtag: "tjj", description: "Tjungundji" },
  { subtag: "tjl", description: "Tai Laing" },
  { subtag: "tjm", description: "Timucua" },
  { subtag: "tjn", description: "Tonjon" },
  { subtag: "tjo", description: "Temacine Tamazight" },
  { subtag: "tjp", description: "Tjupany" },
  { subtag: "tjs", description: "Southern Tujia" },
  { subtag: "tju", description: "Tjurruru" },
  { subtag: "tjw", description: "Djabwurrung" },
  { subtag: "tka", description: "Truká" },
  { subtag: "tkb", description: "Buksa" },
  { subtag: "tkd", description: "Tukudede" },
  { subtag: "tke", description: "Takwane" },
  { subtag: "tkf", description: "Tukumanféd" },
  { subtag: "tkg", description: "Tesaka Malagasy" },
  { subtag: "tkl", description: "Tokelau" },
  { subtag: "tkm", description: "Takelma" },
  { subtag: "tkn", description: "Toku-No-Shima" },
  { subtag: "tkp", description: "Tikopia" },
  { subtag: "tkq", description: "Tee" },
  { subtag: "tkr", description: "Tsakhur" },
  { subtag: "tks", description: "Takestani" },
  { subtag: "tkt", description: "Kathoriya Tharu" },
  { subtag: "tku", description: "Upper Necaxa Totonac" },
  { subtag: "tkv", description: "Mur Pano" },
  { subtag: "tkw", description: "Teanu" },
  { subtag: "tkx", description: "Tangko" },
  { subtag: "tkz", description: "Takua" },
  { subtag: "tla", description: "Southwestern Tepehuan" },
  { subtag: "tlb", description: "Tobelo" },
  { subtag: "tlc", description: "Yecuatla Totonac" },
  { subtag: "tld", description: "Talaud" },
  { subtag: "tlf", description: "Telefol" },
  { subtag: "tlg", description: "Tofanma" },
  { subtag: "tlh", description: "Klingon, tlhIngan Hol" },
  { subtag: "tli", description: "Tlingit" },
  { subtag: "tlj", description: "Talinga-Bwisi" },
  { subtag: "tlk", description: "Taloki" },
  { subtag: "tll", description: "Tetela" },
  { subtag: "tlm", description: "Tolomako" },
  { subtag: "tln", description: "Talondo'" },
  { subtag: "tlo", description: "Talodi" },
  { subtag: "tlp", description: "Filomena Mata-Coahuitlán Totonac" },
  { subtag: "tlq", description: "Tai Loi" },
  { subtag: "tlr", description: "Talise" },
  { subtag: "tls", description: "Tambotalo" },
  { subtag: "tlt", description: "Sou Nama, Teluti" },
  { subtag: "tlu", description: "Tulehu" },
  { subtag: "tlv", description: "Taliabu" },
  { subtag: "tlx", description: "Khehek" },
  { subtag: "tly", description: "Talysh" },
  { subtag: "tma", description: "Tama (Chad)" },
  { subtag: "tmb", description: "Katbol, Avava" },
  { subtag: "tmc", description: "Tumak" },
  { subtag: "tmd", description: "Haruai" },
  { subtag: "tme", description: "Tremembé" },
  { subtag: "tmf", description: "Toba-Maskoy" },
  { subtag: "tmg", description: "Ternateño" },
  { subtag: "tmh", description: "Tamashek" },
  { subtag: "tmi", description: "Tutuba" },
  { subtag: "tmj", description: "Samarokena" },
  { subtag: "tmk", description: "Northwestern Tamang" },
  { subtag: "tml", description: "Tamnim Citak" },
  { subtag: "tmm", description: "Tai Thanh" },
  { subtag: "tmn", description: "Taman (Indonesia)" },
  { subtag: "tmo", description: "Temoq" },
  { subtag: "tmq", description: "Tumleo" },
  { subtag: "tmr", description: "Jewish Babylonian Aramaic (ca. 200-1200 CE)" },
  { subtag: "tms", description: "Tima" },
  { subtag: "tmt", description: "Tasmate" },
  { subtag: "tmu", description: "Iau" },
  { subtag: "tmv", description: "Tembo (Motembo)" },
  { subtag: "tmw", description: "Temuan" },
  { subtag: "tmy", description: "Tami" },
  { subtag: "tmz", description: "Tamanaku" },
  { subtag: "tna", description: "Tacana" },
  { subtag: "tnb", description: "Western Tunebo" },
  { subtag: "tnc", description: "Tanimuca-Retuarã" },
  { subtag: "tnd", description: "Angosturas Tunebo" },
  { subtag: "tng", description: "Tobanga" },
  { subtag: "tnh", description: "Maiani" },
  { subtag: "tni", description: "Tandia" },
  { subtag: "tnk", description: "Kwamera" },
  { subtag: "tnl", description: "Lenakel" },
  { subtag: "tnm", description: "Tabla" },
  { subtag: "tnn", description: "North Tanna" },
  { subtag: "tno", description: "Toromono" },
  { subtag: "tnp", description: "Whitesands" },
  { subtag: "tnq", description: "Taino" },
  { subtag: "tnr", description: "Ménik" },
  { subtag: "tns", description: "Tenis" },
  { subtag: "tnt", description: "Tontemboan" },
  { subtag: "tnu", description: "Tay Khang" },
  { subtag: "tnv", description: "Tangchangya" },
  { subtag: "tnw", description: "Tonsawang" },
  { subtag: "tnx", description: "Tanema" },
  { subtag: "tny", description: "Tongwe" },
  { subtag: "tnz", description: "Ten'edn" },
  { subtag: "tob", description: "Toba" },
  { subtag: "toc", description: "Coyutla Totonac" },
  { subtag: "tod", description: "Toma" },
  { subtag: "tof", description: "Gizrra" },
  { subtag: "tog", description: "Tonga (Nyasa)" },
  { subtag: "toh", description: "Gitonga" },
  { subtag: "toi", description: "Tonga (Zambia)" },
  { subtag: "toj", description: "Tojolabal" },
  { subtag: "tok", description: "Toki Pona" },
  { subtag: "tol", description: "Tolowa" },
  { subtag: "tom", description: "Tombulu" },
  { subtag: "too", description: "Xicotepec De Juárez Totonac" },
  { subtag: "top", description: "Papantla Totonac" },
  { subtag: "toq", description: "Toposa" },
  { subtag: "tor", description: "Togbo-Vara Banda" },
  { subtag: "tos", description: "Highland Totonac" },
  { subtag: "tou", description: "Tho" },
  { subtag: "tov", description: "Upper Taromi" },
  { subtag: "tow", description: "Jemez" },
  { subtag: "tox", description: "Tobian" },
  { subtag: "toy", description: "Topoiyo" },
  { subtag: "toz", description: "To" },
  { subtag: "tpa", description: "Taupota" },
  { subtag: "tpc", description: "Azoyú Me'phaa, Azoyú Tlapanec" },
  { subtag: "tpe", description: "Tippera" },
  { subtag: "tpf", description: "Tarpia" },
  { subtag: "tpg", description: "Kula" },
  { subtag: "tpi", description: "Tok Pisin" },
  { subtag: "tpj", description: "Tapieté" },
  { subtag: "tpk", description: "Tupinikin" },
  { subtag: "tpl", description: "Tlacoapa Me'phaa, Tlacoapa Tlapanec" },
  { subtag: "tpm", description: "Tampulma" },
  { subtag: "tpn", description: "Tupinambá" },
  { subtag: "tpo", description: "Tai Pao" },
  { subtag: "tpp", description: "Pisaflores Tepehua" },
  { subtag: "tpq", description: "Tukpa" },
  { subtag: "tpr", description: "Tuparí" },
  { subtag: "tpt", description: "Tlachichilco Tepehua" },
  { subtag: "tpu", description: "Tampuan" },
  { subtag: "tpv", description: "Tanapag" },
  { subtag: "tpw", description: "Tupí" },
  { subtag: "tpx", description: "Acatepec Me'phaa, Acatepec Tlapanec" },
  { subtag: "tpy", description: "Trumai" },
  { subtag: "tpz", description: "Tinputz" },
  { subtag: "tqb", description: "Tembé" },
  { subtag: "tql", description: "Lehali" },
  { subtag: "tqm", description: "Turumsa" },
  { subtag: "tqn", description: "Tenino" },
  { subtag: "tqo", description: "Toaripi" },
  { subtag: "tqp", description: "Tomoip" },
  { subtag: "tqq", description: "Tunni" },
  { subtag: "tqr", description: "Torona" },
  { subtag: "tqt", description: "Western Totonac" },
  { subtag: "tqu", description: "Touo" },
  { subtag: "tqw", description: "Tonkawa" },
  { subtag: "tra", description: "Tirahi" },
  { subtag: "trb", description: "Terebu" },
  { subtag: "trc", description: "Copala Triqui" },
  { subtag: "trd", description: "Turi" },
  { subtag: "tre", description: "East Tarangan" },
  { subtag: "trf", description: "Trinidadian Creole English" },
  { subtag: "trg", description: "Lishán Didán" },
  { subtag: "trh", description: "Turaka" },
  { subtag: "tri", description: "Trió" },
  { subtag: "trj", description: "Toram" },
  { subtag: "trk", description: "Turkic languages" },
  { subtag: "trl", description: "Traveller Scottish" },
  { subtag: "trm", description: "Tregami" },
  { subtag: "trn", description: "Trinitario" },
  { subtag: "tro", description: "Tarao Naga" },
  { subtag: "trp", description: "Kok Borok" },
  { subtag: "trq", description: "San Martín Itunyoso Triqui" },
  { subtag: "trr", description: "Taushiro" },
  { subtag: "trs", description: "Chicahuaxtla Triqui" },
  { subtag: "trt", description: "Tunggare" },
  { subtag: "tru", description: "Turoyo, Surayt" },
  { subtag: "trv", description: "Sediq, Seediq, Taroko" },
  { subtag: "trw", description: "Torwali" },
  { subtag: "trx", description: "Tringgus-Sembaan Bidayuh" },
  { subtag: "try", description: "Turung" },
  { subtag: "trz", description: "Torá" },
  { subtag: "tsa", description: "Tsaangi" },
  { subtag: "tsb", description: "Tsamai" },
  { subtag: "tsc", description: "Tswa" },
  { subtag: "tsd", description: "Tsakonian" },
  { subtag: "tse", description: "Tunisian Sign Language" },
  { subtag: "tsg", description: "Tausug" },
  { subtag: "tsh", description: "Tsuvan" },
  { subtag: "tsi", description: "Tsimshian" },
  { subtag: "tsj", description: "Tshangla" },
  { subtag: "tsk", description: "Tseku" },
  { subtag: "tsl", description: "Ts'ün-Lao" },
  { subtag: "tsm", description: "Turkish Sign Language, Türk İşaret Dili" },
  { subtag: "tsp", description: "Northern Toussian" },
  { subtag: "tsq", description: "Thai Sign Language" },
  { subtag: "tsr", description: "Akei" },
  { subtag: "tss", description: "Taiwan Sign Language" },
  { subtag: "tst", description: "Tondi Songway Kiini" },
  { subtag: "tsu", description: "Tsou" },
  { subtag: "tsv", description: "Tsogo" },
  { subtag: "tsw", description: "Tsishingini" },
  { subtag: "tsx", description: "Mubami" },
  { subtag: "tsy", description: "Tebul Sign Language" },
  { subtag: "tsz", description: "Purepecha" },
  { subtag: "tta", description: "Tutelo" },
  { subtag: "ttb", description: "Gaa" },
  { subtag: "ttc", description: "Tektiteko" },
  { subtag: "ttd", description: "Tauade" },
  { subtag: "tte", description: "Bwanabwana" },
  { subtag: "ttf", description: "Tuotomb" },
  { subtag: "ttg", description: "Tutong" },
  { subtag: "tth", description: "Upper Ta'oih" },
  { subtag: "tti", description: "Tobati" },
  { subtag: "ttj", description: "Tooro" },
  { subtag: "ttk", description: "Totoro" },
  { subtag: "ttl", description: "Totela" },
  { subtag: "ttm", description: "Northern Tutchone" },
  { subtag: "ttn", description: "Towei" },
  { subtag: "tto", description: "Lower Ta'oih" },
  { subtag: "ttp", description: "Tombelala" },
  { subtag: "ttq", description: "Tawallammat Tamajaq" },
  { subtag: "ttr", description: "Tera" },
  { subtag: "tts", description: "Northeastern Thai" },
  { subtag: "ttt", description: "Muslim Tat" },
  { subtag: "ttu", description: "Torau" },
  { subtag: "ttv", description: "Titan" },
  { subtag: "ttw", description: "Long Wat" },
  { subtag: "tty", description: "Sikaritai" },
  { subtag: "ttz", description: "Tsum" },
  { subtag: "tua", description: "Wiarumus" },
  { subtag: "tub", description: "Tübatulabal" },
  { subtag: "tuc", description: "Mutu" },
  { subtag: "tud", description: "Tuxá" },
  { subtag: "tue", description: "Tuyuca" },
  { subtag: "tuf", description: "Central Tunebo" },
  { subtag: "tug", description: "Tunia" },
  { subtag: "tuh", description: "Taulil" },
  { subtag: "tui", description: "Tupuri" },
  { subtag: "tuj", description: "Tugutil" },
  { subtag: "tul", description: "Tula" },
  { subtag: "tum", description: "Tumbuka" },
  { subtag: "tun", description: "Tunica" },
  { subtag: "tuo", description: "Tucano" },
  { subtag: "tup", description: "Tupi languages" },
  { subtag: "tuq", description: "Tedaga" },
  { subtag: "tus", description: "Tuscarora" },
  { subtag: "tut", description: "Altaic languages" },
  { subtag: "tuu", description: "Tututni" },
  { subtag: "tuv", description: "Turkana" },
  { subtag: "tuw", description: "Tungus languages" },
  { subtag: "tux", description: "Tuxináwa" },
  { subtag: "tuy", description: "Tugen" },
  { subtag: "tuz", description: "Turka" },
  { subtag: "tva", description: "Vaghua" },
  { subtag: "tvd", description: "Tsuvadi" },
  { subtag: "tve", description: "Te'un" },
  { subtag: "tvk", description: "Southeast Ambrym" },
  { subtag: "tvl", description: "Tuvalu" },
  { subtag: "tvm", description: "Tela-Masbuar" },
  { subtag: "tvn", description: "Tavoyan" },
  { subtag: "tvo", description: "Tidore" },
  { subtag: "tvs", description: "Taveta" },
  { subtag: "tvt", description: "Tutsa Naga" },
  { subtag: "tvu", description: "Tunen" },
  { subtag: "tvw", description: "Sedoa" },
  { subtag: "tvx", description: "Taivoan" },
  { subtag: "tvy", description: "Timor Pidgin" },
  { subtag: "twa", description: "Twana" },
  { subtag: "twb", description: "Western Tawbuid" },
  { subtag: "twc", description: "Teshenawa" },
  { subtag: "twd", description: "Twents" },
  { subtag: "twe", description: "Tewa (Indonesia)" },
  { subtag: "twf", description: "Northern Tiwa" },
  { subtag: "twg", description: "Tereweng" },
  { subtag: "twh", description: "Tai Dón" },
  { subtag: "twl", description: "Tawara" },
  { subtag: "twm", description: "Tawang Monpa" },
  { subtag: "twn", description: "Twendi" },
  { subtag: "two", description: "Tswapong" },
  { subtag: "twp", description: "Ere" },
  { subtag: "twq", description: "Tasawaq" },
  { subtag: "twr", description: "Southwestern Tarahumara" },
  { subtag: "twt", description: "Turiwára" },
  { subtag: "twu", description: "Termanu" },
  { subtag: "tww", description: "Tuwari" },
  { subtag: "twx", description: "Tewe" },
  { subtag: "twy", description: "Tawoyan" },
  { subtag: "txa", description: "Tombonuo" },
  { subtag: "txb", description: "Tokharian B" },
  { subtag: "txc", description: "Tsetsaut" },
  { subtag: "txe", description: "Totoli" },
  { subtag: "txg", description: "Tangut" },
  { subtag: "txh", description: "Thracian" },
  { subtag: "txi", description: "Ikpeng" },
  { subtag: "txj", description: "Tarjumo" },
  { subtag: "txm", description: "Tomini" },
  { subtag: "txn", description: "West Tarangan" },
  { subtag: "txo", description: "Toto" },
  { subtag: "txq", description: "Tii" },
  { subtag: "txr", description: "Tartessian" },
  { subtag: "txs", description: "Tonsea" },
  { subtag: "txt", description: "Citak" },
  { subtag: "txu", description: "Kayapó" },
  { subtag: "txx", description: "Tatana" },
  { subtag: "txy", description: "Tanosy Malagasy" },
  { subtag: "tya", description: "Tauya" },
  { subtag: "tye", description: "Kyanga" },
  { subtag: "tyh", description: "O'du" },
  { subtag: "tyi", description: "Teke-Tsaayi" },
  { subtag: "tyj", description: "Tai Do, Tai Yo" },
  { subtag: "tyl", description: "Thu Lao" },
  { subtag: "tyn", description: "Kombai" },
  { subtag: "typ", description: "Thaypan" },
  { subtag: "tyr", description: "Tai Daeng" },
  { subtag: "tys", description: "Tày Sa Pa" },
  { subtag: "tyt", description: "Tày Tac" },
  { subtag: "tyu", description: "Kua" },
  { subtag: "tyv", description: "Tuvinian" },
  { subtag: "tyx", description: "Teke-Tyee" },
  { subtag: "tyy", description: "Tiyaa" },
  { subtag: "tyz", description: "Tày" },
  { subtag: "tza", description: "Tanzanian Sign Language" },
  { subtag: "tzh", description: "Tzeltal" },
  { subtag: "tzj", description: "Tz'utujil" },
  { subtag: "tzl", description: "Talossan" },
  { subtag: "tzm", description: "Central Atlas Tamazight" },
  { subtag: "tzn", description: "Tugun" },
  { subtag: "tzo", description: "Tzotzil" },
  { subtag: "tzx", description: "Tabriak" },
  { subtag: "uam", description: "Uamué" },
  { subtag: "uan", description: "Kuan" },
  { subtag: "uar", description: "Tairuma" },
  { subtag: "uba", description: "Ubang" },
  { subtag: "ubi", description: "Ubi" },
  { subtag: "ubl", description: "Buhi'non Bikol" },
  { subtag: "ubr", description: "Ubir" },
  { subtag: "ubu", description: "Umbu-Ungu" },
  { subtag: "uby", description: "Ubykh" },
  { subtag: "uda", description: "Uda" },
  { subtag: "ude", description: "Udihe" },
  { subtag: "udg", description: "Muduga" },
  { subtag: "udi", description: "Udi" },
  { subtag: "udj", description: "Ujir" },
  { subtag: "udl", description: "Wuzlam" },
  { subtag: "udm", description: "Udmurt" },
  { subtag: "udu", description: "Uduk" },
  { subtag: "ues", description: "Kioko" },
  { subtag: "ufi", description: "Ufim" },
  { subtag: "uga", description: "Ugaritic" },
  { subtag: "ugb", description: "Kuku-Ugbanh" },
  { subtag: "uge", description: "Ughele" },
  { subtag: "ugh", description: "Kubachi" },
  { subtag: "ugn", description: "Ugandan Sign Language" },
  { subtag: "ugo", description: "Ugong" },
  { subtag: "ugy", description: "Uruguayan Sign Language" },
  { subtag: "uha", description: "Uhami" },
  { subtag: "uhn", description: "Damal" },
  { subtag: "uis", description: "Uisai" },
  { subtag: "uiv", description: "Iyive" },
  { subtag: "uji", description: "Tanjijili" },
  { subtag: "uka", description: "Kaburi" },
  { subtag: "ukg", description: "Ukuriguma" },
  { subtag: "ukh", description: "Ukhwejo" },
  { subtag: "uki", description: "Kui (India)" },
  { subtag: "ukk", description: "Muak Sa-aak" },
  { subtag: "ukl", description: "Ukrainian Sign Language" },
  { subtag: "ukp", description: "Ukpe-Bayobiri" },
  { subtag: "ukq", description: "Ukwa" },
  { subtag: "uks", description: "Urubú-Kaapor Sign Language, Kaapor Sign Language" },
  { subtag: "uku", description: "Ukue" },
  { subtag: "ukv", description: "Kuku" },
  { subtag: "ukw", description: "Ukwuani-Aboh-Ndoni" },
  { subtag: "uky", description: "Kuuk-Yak" },
  { subtag: "ula", description: "Fungwa" },
  { subtag: "ulb", description: "Ulukwumi" },
  { subtag: "ulc", description: "Ulch" },
  { subtag: "ule", description: "Lule" },
  { subtag: "ulf", description: "Usku, Afra" },
  { subtag: "uli", description: "Ulithian" },
  { subtag: "ulk", description: "Meriam Mir" },
  { subtag: "ull", description: "Ullatan" },
  { subtag: "ulm", description: "Ulumanda'" },
  { subtag: "uln", description: "Unserdeutsch" },
  { subtag: "ulu", description: "Uma' Lung" },
  { subtag: "ulw", description: "Ulwa" },
  { subtag: "uma", description: "Umatilla" },
  { subtag: "umb", description: "Umbundu" },
  { subtag: "umc", description: "Marrucinian" },
  { subtag: "umd", description: "Umbindhamu" },
  { subtag: "umg", description: "Morrobalama, Umbuygamu" },
  { subtag: "umi", description: "Ukit" },
  { subtag: "umm", description: "Umon" },
  { subtag: "umn", description: "Makyan Naga" },
  { subtag: "umo", description: "Umotína" },
  { subtag: "ump", description: "Umpila" },
  { subtag: "umr", description: "Umbugarla" },
  { subtag: "ums", description: "Pendau" },
  { subtag: "umu", description: "Munsee" },
  { subtag: "una", description: "North Watut" },
  { subtag: "und", description: "Undetermined" },
  { subtag: "une", description: "Uneme" },
  { subtag: "ung", description: "Ngarinyin" },
  { subtag: "uni", description: "Uni" },
  { subtag: "unk", description: "Enawené-Nawé" },
  { subtag: "unm", description: "Unami" },
  { subtag: "unn", description: "Kurnai" },
  { subtag: "unr", description: "Mundari" },
  { subtag: "unu", description: "Unubahe" },
  { subtag: "unx", description: "Munda" },
  { subtag: "unz", description: "Unde Kaili" },
  { subtag: "uon", description: "Kulon" },
  { subtag: "upi", description: "Umeda" },
  { subtag: "upv", description: "Uripiv-Wala-Rano-Atchin" },
  { subtag: "ura", description: "Urarina" },
  { subtag: "urb", description: "Urubú-Kaapor, Kaapor" },
  { subtag: "urc", description: "Urningangg" },
  { subtag: "ure", description: "Uru" },
  { subtag: "urf", description: "Uradhi" },
  { subtag: "urg", description: "Urigina" },
  { subtag: "urh", description: "Urhobo" },
  { subtag: "uri", description: "Urim" },
  { subtag: "urj", description: "Uralic languages" },
  { subtag: "urk", description: "Urak Lawoi'" },
  { subtag: "url", description: "Urali" },
  { subtag: "urm", description: "Urapmin" },
  { subtag: "urn", description: "Uruangnirin" },
  { subtag: "uro", description: "Ura (Papua New Guinea)" },
  { subtag: "urp", description: "Uru-Pa-In" },
  { subtag: "urr", description: "Lehalurup, Löyöp" },
  { subtag: "urt", description: "Urat" },
  { subtag: "uru", description: "Urumi" },
  { subtag: "urv", description: "Uruava" },
  { subtag: "urw", description: "Sop" },
  { subtag: "urx", description: "Urimo" },
  { subtag: "ury", description: "Orya" },
  { subtag: "urz", description: "Uru-Eu-Wau-Wau" },
  { subtag: "usa", description: "Usarufa" },
  { subtag: "ush", description: "Ushojo" },
  { subtag: "usi", description: "Usui" },
  { subtag: "usk", description: "Usaghade" },
  { subtag: "usp", description: "Uspanteco" },
  { subtag: "uss", description: "us-Saare" },
  { subtag: "usu", description: "Uya" },
  { subtag: "uta", description: "Otank" },
  { subtag: "ute", description: "Ute-Southern Paiute" },
  { subtag: "uth", description: "ut-Hun" },
  { subtag: "utp", description: "Amba (Solomon Islands)" },
  { subtag: "utr", description: "Etulo" },
  { subtag: "utu", description: "Utu" },
  { subtag: "uum", description: "Urum" },
  { subtag: "uur", description: "Ura (Vanuatu)" },
  { subtag: "uuu", description: "U" },
  { subtag: "uve", description: "West Uvean, Fagauvea" },
  { subtag: "uvh", description: "Uri" },
  { subtag: "uvl", description: "Lote" },
  { subtag: "uwa", description: "Kuku-Uwanh" },
  { subtag: "uya", description: "Doko-Uyanga" },
  { subtag: "uzn", description: "Northern Uzbek" },
  { subtag: "uzs", description: "Southern Uzbek" },
  { subtag: "vaa", description: "Vaagri Booli" },
  { subtag: "vae", description: "Vale" },
  { subtag: "vaf", description: "Vafsi" },
  { subtag: "vag", description: "Vagla" },
  { subtag: "vah", description: "Varhadi-Nagpuri" },
  { subtag: "vai", description: "Vai" },
  { subtag: "vaj", description: "Sekele, Northwestern ǃKung, Vasekele" },
  { subtag: "val", description: "Vehes" },
  { subtag: "vam", description: "Vanimo" },
  { subtag: "van", description: "Valman" },
  { subtag: "vao", description: "Vao" },
  { subtag: "vap", description: "Vaiphei" },
  { subtag: "var", description: "Huarijio" },
  { subtag: "vas", description: "Vasavi" },
  { subtag: "vau", description: "Vanuma" },
  { subtag: "vav", description: "Varli" },
  { subtag: "vay", description: "Wayu" },
  { subtag: "vbb", description: "Southeast Babar" },
  { subtag: "vbk", description: "Southwestern Bontok" },
  { subtag: "vec", description: "Venetian" },
  { subtag: "ved", description: "Veddah" },
  { subtag: "vel", description: "Veluws" },
  { subtag: "vem", description: "Vemgo-Mabas" },
  { subtag: "veo", description: "Ventureño" },
  { subtag: "vep", description: "Veps" },
  { subtag: "ver", description: "Mom Jango" },
  { subtag: "vgr", description: "Vaghri" },
  { subtag: "vgt", description: "Vlaamse Gebarentaal, Flemish Sign Language" },
  { subtag: "vic", description: "Virgin Islands Creole English" },
  { subtag: "vid", description: "Vidunda" },
  { subtag: "vif", description: "Vili" },
  { subtag: "vig", description: "Viemo" },
  { subtag: "vil", description: "Vilela" },
  { subtag: "vin", description: "Vinza" },
  { subtag: "vis", description: "Vishavan" },
  { subtag: "vit", description: "Viti" },
  { subtag: "viv", description: "Iduna" },
  { subtag: "vka", description: "Kariyarra" },
  { subtag: "vkj", description: "Kujarge" },
  { subtag: "vkk", description: "Kaur" },
  { subtag: "vkl", description: "Kulisusu" },
  { subtag: "vkm", description: "Kamakan" },
  { subtag: "vkn", description: "Koro Nulu" },
  { subtag: "vko", description: "Kodeoha" },
  { subtag: "vkp", description: "Korlai Creole Portuguese" },
  { subtag: "vkt", description: "Tenggarong Kutai Malay" },
  { subtag: "vku", description: "Kurrama" },
  { subtag: "vkz", description: "Koro Zuba" },
  { subtag: "vlp", description: "Valpei" },
  { subtag: "vls", description: "Vlaams" },
  { subtag: "vma", description: "Martuyhunira" },
  { subtag: "vmb", description: "Barbaram" },
  { subtag: "vmc", description: "Juxtlahuaca Mixtec" },
  { subtag: "vmd", description: "Mudu Koraga" },
  { subtag: "vme", description: "East Masela" },
  { subtag: "vmf", description: "Mainfränkisch" },
  { subtag: "vmg", description: "Lungalunga" },
  { subtag: "vmh", description: "Maraghei" },
  { subtag: "vmi", description: "Miwa" },
  { subtag: "vmj", description: "Ixtayutla Mixtec" },
  { subtag: "vmk", description: "Makhuwa-Shirima" },
  { subtag: "vml", description: "Malgana" },
  { subtag: "vmm", description: "Mitlatongo Mixtec" },
  { subtag: "vmp", description: "Soyaltepec Mazatec" },
  { subtag: "vmq", description: "Soyaltepec Mixtec" },
  { subtag: "vmr", description: "Marenje" },
  { subtag: "vms", description: "Moksela" },
  { subtag: "vmu", description: "Muluridyi" },
  { subtag: "vmv", description: "Valley Maidu" },
  { subtag: "vmw", description: "Makhuwa" },
  { subtag: "vmx", description: "Tamazola Mixtec" },
  { subtag: "vmy", description: "Ayautla Mazatec" },
  { subtag: "vmz", description: "Mazatlán Mazatec" },
  { subtag: "vnk", description: "Vano, Lovono" },
  { subtag: "vnm", description: "Vinmavis, Neve'ei" },
  { subtag: "vnp", description: "Vunapu" },
  { subtag: "vor", description: "Voro" },
  { subtag: "vot", description: "Votic" },
  { subtag: "vra", description: "Vera'a" },
  { subtag: "vro", description: "Võro" },
  { subtag: "vrs", description: "Varisi" },
  { subtag: "vrt", description: "Burmbar, Banam Bay" },
  { subtag: "vsi", description: "Moldova Sign Language" },
  { subtag: "vsl", description: "Venezuelan Sign Language" },
  { subtag: "vsv", description: "Valencian Sign Language, Llengua de signes valenciana" },
  { subtag: "vto", description: "Vitou" },
  { subtag: "vum", description: "Vumbu" },
  { subtag: "vun", description: "Vunjo" },
  { subtag: "vut", description: "Vute" },
  { subtag: "vwa", description: "Awa (China)" },
  { subtag: "waa", description: "Walla Walla" },
  { subtag: "wab", description: "Wab" },
  { subtag: "wac", description: "Wasco-Wishram" },
  { subtag: "wad", description: "Wamesa, Wondama" },
  { subtag: "wae", description: "Walser" },
  { subtag: "waf", description: "Wakoná" },
  { subtag: "wag", description: "Wa'ema" },
  { subtag: "wah", description: "Watubela" },
  { subtag: "wai", description: "Wares" },
  { subtag: "waj", description: "Waffa" },
  { subtag: "wak", description: "Wakashan languages" },
  { subtag: "wal", description: "Wolaytta, Wolaitta" },
  { subtag: "wam", description: "Wampanoag" },
  { subtag: "wan", description: "Wan" },
  { subtag: "wao", description: "Wappo" },
  { subtag: "wap", description: "Wapishana" },
  { subtag: "waq", description: "Wagiman" },
  { subtag: "war", description: "Waray (Philippines)" },
  { subtag: "was", description: "Washo" },
  { subtag: "wat", description: "Kaninuwa" },
  { subtag: "wau", description: "Waurá" },
  { subtag: "wav", description: "Waka" },
  { subtag: "waw", description: "Waiwai" },
  { subtag: "wax", description: "Watam, Marangis" },
  { subtag: "way", description: "Wayana" },
  { subtag: "waz", description: "Wampur" },
  { subtag: "wba", description: "Warao" },
  { subtag: "wbb", description: "Wabo" },
  { subtag: "wbe", description: "Waritai" },
  { subtag: "wbf", description: "Wara" },
  { subtag: "wbh", description: "Wanda" },
  { subtag: "wbi", description: "Vwanji" },
  { subtag: "wbj", description: "Alagwa" },
  { subtag: "wbk", description: "Waigali" },
  { subtag: "wbl", description: "Wakhi" },
  { subtag: "wbm", description: "Wa" },
  { subtag: "wbp", description: "Warlpiri" },
  { subtag: "wbq", description: "Waddar" },
  { subtag: "wbr", description: "Wagdi" },
  { subtag: "wbs", description: "West Bengal Sign Language" },
  { subtag: "wbt", description: "Warnman" },
  { subtag: "wbv", description: "Wajarri" },
  { subtag: "wbw", description: "Woi" },
  { subtag: "wca", description: "Yanomámi" },
  { subtag: "wci", description: "Waci Gbe" },
  { subtag: "wdd", description: "Wandji" },
  { subtag: "wdg", description: "Wadaginam" },
  { subtag: "wdj", description: "Wadjiginy" },
  { subtag: "wdk", description: "Wadikali" },
  { subtag: "wdt", description: "Wendat" },
  { subtag: "wdu", description: "Wadjigu" },
  { subtag: "wdy", description: "Wadjabangayi" },
  { subtag: "wea", description: "Wewaw" },
  { subtag: "wec", description: "Wè Western" },
  { subtag: "wed", description: "Wedau" },
  { subtag: "weg", description: "Wergaia" },
  { subtag: "weh", description: "Weh" },
  { subtag: "wei", description: "Kiunum" },
  { subtag: "wem", description: "Weme Gbe" },
  { subtag: "wen", description: "Sorbian languages" },
  { subtag: "weo", description: "Wemale" },
  { subtag: "wep", description: "Westphalien" },
  { subtag: "wer", description: "Weri" },
  { subtag: "wes", description: "Cameroon Pidgin" },
  { subtag: "wet", description: "Perai" },
  { subtag: "weu", description: "Rawngtu Chin" },
  { subtag: "wew", description: "Wejewa" },
  { subtag: "wfg", description: "Yafi, Zorop" },
  { subtag: "wga", description: "Wagaya" },
  { subtag: "wgb", description: "Wagawaga" },
  { subtag: "wgg", description: "Wangkangurru, Wangganguru" },
  { subtag: "wgi", description: "Wahgi" },
  { subtag: "wgo", description: "Waigeo" },
  { subtag: "wgu", description: "Wirangu" },
  { subtag: "wgy", description: "Warrgamay" },
  { subtag: "wha", description: "Sou Upaa, Manusela" },
  { subtag: "whg", description: "North Wahgi" },
  { subtag: "whk", description: "Wahau Kenyah" },
  { subtag: "whu", description: "Wahau Kayan" },
  { subtag: "wib", description: "Southern Toussian" },
  { subtag: "wic", description: "Wichita" },
  { subtag: "wie", description: "Wik-Epa" },
  { subtag: "wif", description: "Wik-Keyangan" },
  { subtag: "wig", description: "Wik Ngathan" },
  { subtag: "wih", description: "Wik-Me'anha" },
  { subtag: "wii", description: "Minidien" },
  { subtag: "wij", description: "Wik-Iiyanh" },
  { subtag: "wik", description: "Wikalkan" },
  { subtag: "wil", description: "Wilawila" },
  { subtag: "wim", description: "Wik-Mungkan" },
  { subtag: "win", description: "Ho-Chunk" },
  { subtag: "wir", description: "Wiraféd" },
  { subtag: "wiu", description: "Wiru" },
  { subtag: "wiv", description: "Vitu" },
  { subtag: "wiy", description: "Wiyot" },
  { subtag: "wja", description: "Waja" },
  { subtag: "wji", description: "Warji" },
  { subtag: "wka", description: "Kw'adza" },
  { subtag: "wkb", description: "Kumbaran" },
  { subtag: "wkd", description: "Wakde, Mo" },
  { subtag: "wkl", description: "Kalanadi" },
  { subtag: "wkr", description: "Keerray-Woorroong" },
  { subtag: "wku", description: "Kunduvadi" },
  { subtag: "wkw", description: "Wakawaka" },
  { subtag: "wky", description: "Wangkayutyuru" },
  { subtag: "wla", description: "Walio" },
  { subtag: "wlc", description: "Mwali Comorian" },
  { subtag: "wle", description: "Wolane" },
  { subtag: "wlg", description: "Kunbarlang" },
  { subtag: "wlh", description: "Welaun" },
  { subtag: "wli", description: "Waioli" },
  { subtag: "wlk", description: "Wailaki" },
  { subtag: "wll", description: "Wali (Sudan)" },
  { subtag: "wlm", description: "Middle Welsh" },
  { subtag: "wlo", description: "Wolio" },
  { subtag: "wlr", description: "Wailapa" },
  { subtag: "wls", description: "Wallisian" },
  { subtag: "wlu", description: "Wuliwuli" },
  { subtag: "wlv", description: "Wichí Lhamtés Vejoz" },
  { subtag: "wlw", description: "Walak" },
  { subtag: "wlx", description: "Wali (Ghana)" },
  { subtag: "wly", description: "Waling" },
  { subtag: "wma", description: "Mawa (Nigeria)" },
  { subtag: "wmb", description: "Wambaya" },
  { subtag: "wmc", description: "Wamas" },
  { subtag: "wmd", description: "Mamaindé" },
  { subtag: "wme", description: "Wambule" },
  { subtag: "wmg", description: "Western Minyag" },
  { subtag: "wmh", description: "Waima'a" },
  { subtag: "wmi", description: "Wamin" },
  { subtag: "wmm", description: "Maiwa (Indonesia)" },
  { subtag: "wmn", description: "Waamwang" },
  { subtag: "wmo", description: "Wom (Papua New Guinea)" },
  { subtag: "wms", description: "Wambon" },
  { subtag: "wmt", description: "Walmajarri" },
  { subtag: "wmw", description: "Mwani" },
  { subtag: "wmx", description: "Womo" },
  { subtag: "wnb", description: "Wanambre" },
  { subtag: "wnc", description: "Wantoat" },
  { subtag: "wnd", description: "Wandarang" },
  { subtag: "wne", description: "Waneci" },
  { subtag: "wng", description: "Wanggom" },
  { subtag: "wni", description: "Ndzwani Comorian" },
  { subtag: "wnk", description: "Wanukaka" },
  { subtag: "wnm", description: "Wanggamala" },
  { subtag: "wnn", description: "Wunumara" },
  { subtag: "wno", description: "Wano" },
  { subtag: "wnp", description: "Wanap" },
  { subtag: "wnu", description: "Usan" },
  { subtag: "wnw", description: "Wintu" },
  { subtag: "wny", description: "Wanyi, Waanyi" },
  { subtag: "woa", description: "Kuwema, Tyaraity" },
  { subtag: "wob", description: "Wè Northern" },
  { subtag: "woc", description: "Wogeo" },
  { subtag: "wod", description: "Wolani" },
  { subtag: "woe", description: "Woleaian" },
  { subtag: "wof", description: "Gambian Wolof" },
  { subtag: "wog", description: "Wogamusin" },
  { subtag: "woi", description: "Kamang" },
  { subtag: "wok", description: "Longto" },
  { subtag: "wom", description: "Wom (Nigeria)" },
  { subtag: "won", description: "Wongo" },
  { subtag: "woo", description: "Manombai" },
  { subtag: "wor", description: "Woria" },
  { subtag: "wos", description: "Hanga Hundi" },
  { subtag: "wow", description: "Wawonii" },
  { subtag: "woy", description: "Weyto" },
  { subtag: "wpc", description: "Maco" },
  { subtag: "wrb", description: "Waluwarra, Warluwara" },
  { subtag: "wrg", description: "Warungu, Gudjal" },
  { subtag: "wrh", description: "Wiradjuri" },
  { subtag: "wri", description: "Wariyangga" },
  { subtag: "wrk", description: "Garrwa" },
  { subtag: "wrl", description: "Warlmanpa" },
  { subtag: "wrm", description: "Warumungu" },
  { subtag: "wrn", description: "Warnang" },
  { subtag: "wro", description: "Worrorra" },
  { subtag: "wrp", description: "Waropen" },
  { subtag: "wrr", description: "Wardaman" },
  { subtag: "wrs", description: "Waris" },
  { subtag: "wru", description: "Waru" },
  { subtag: "wrv", description: "Waruna" },
  { subtag: "wrw", description: "Gugu Warra" },
  { subtag: "wrx", description: "Wae Rana" },
  { subtag: "wry", description: "Merwari" },
  { subtag: "wrz", description: "Waray (Australia)" },
  { subtag: "wsa", description: "Warembori" },
  { subtag: "wsg", description: "Adilabad Gondi" },
  { subtag: "wsi", description: "Wusi" },
  { subtag: "wsk", description: "Waskia" },
  { subtag: "wsr", description: "Owenia" },
  { subtag: "wss", description: "Wasa" },
  { subtag: "wsu", description: "Wasu" },
  { subtag: "wsv", description: "Wotapuri-Katarqalai" },
  { subtag: "wtf", description: "Watiwa" },
  { subtag: "wth", description: "Wathawurrung" },
  { subtag: "wti", description: "Berta" },
  { subtag: "wtk", description: "Watakataui" },
  { subtag: "wtm", description: "Mewati" },
  { subtag: "wtw", description: "Wotu" },
  { subtag: "wua", description: "Wikngenchera" },
  { subtag: "wub", description: "Wunambal" },
  { subtag: "wud", description: "Wudu" },
  { subtag: "wuh", description: "Wutunhua" },
  { subtag: "wul", description: "Silimo" },
  { subtag: "wum", description: "Wumbvu" },
  { subtag: "wun", description: "Bungu" },
  { subtag: "wur", description: "Wurrugu" },
  { subtag: "wut", description: "Wutung" },
  { subtag: "wuu", description: "Wu Chinese" },
  { subtag: "wuv", description: "Wuvulu-Aua" },
  { subtag: "wux", description: "Wulna" },
  { subtag: "wuy", description: "Wauyai" },
  { subtag: "wwa", description: "Waama" },
  { subtag: "wwb", description: "Wakabunga" },
  { subtag: "wwo", description: "Wetamut, Dorig" },
  { subtag: "wwr", description: "Warrwa" },
  { subtag: "www", description: "Wawa" },
  { subtag: "wxa", description: "Waxianghua" },
  { subtag: "wxw", description: "Wardandi" },
  { subtag: "wyb", description: "Wangaaybuwan-Ngiyambaa" },
  { subtag: "wyi", description: "Woiwurrung" },
  { subtag: "wym", description: "Wymysorys" },
  { subtag: "wyn", description: "Wyandot" },
  { subtag: "wyr", description: "Wayoró" },
  { subtag: "wyy", description: "Western Fijian" },
  { subtag: "xaa", description: "Andalusian Arabic" },
  { subtag: "xab", description: "Sambe" },
  { subtag: "xac", description: "Kachari" },
  { subtag: "xad", description: "Adai" },
  { subtag: "xae", description: "Aequian" },
  { subtag: "xag", description: "Aghwan" },
  { subtag: "xai", description: "Kaimbé" },
  { subtag: "xaj", description: "Ararandewára" },
  { subtag: "xak", description: "Máku" },
  { subtag: "xal", description: "Kalmyk, Oirat" },
  { subtag: "xam", description: "ǀXam" },
  { subtag: "xan", description: "Xamtanga" },
  { subtag: "xao", description: "Khao" },
  { subtag: "xap", description: "Apalachee" },
  { subtag: "xaq", description: "Aquitanian" },
  { subtag: "xar", description: "Karami" },
  { subtag: "xas", description: "Kamas" },
  { subtag: "xat", description: "Katawixi" },
  { subtag: "xau", description: "Kauwera" },
  { subtag: "xav", description: "Xavánte" },
  { subtag: "xaw", description: "Kawaiisu" },
  { subtag: "xay", description: "Kayan Mahakam" },
  { subtag: "xbb", description: "Lower Burdekin" },
  { subtag: "xbc", description: "Bactrian" },
  { subtag: "xbd", description: "Bindal" },
  { subtag: "xbe", description: "Bigambal" },
  { subtag: "xbg", description: "Bunganditj" },
  { subtag: "xbi", description: "Kombio" },
  { subtag: "xbj", description: "Birrpayi" },
  { subtag: "xbm", description: "Middle Breton" },
  { subtag: "xbn", description: "Kenaboi" },
  { subtag: "xbo", description: "Bolgarian" },
  { subtag: "xbp", description: "Bibbulman" },
  { subtag: "xbr", description: "Kambera" },
  { subtag: "xbw", description: "Kambiwá" },
  { subtag: "xby", description: "Batjala, Batyala" },
  { subtag: "xcb", description: "Cumbric" },
  { subtag: "xcc", description: "Camunic" },
  { subtag: "xce", description: "Celtiberian" },
  { subtag: "xcg", description: "Cisalpine Gaulish" },
  { subtag: "xch", description: "Chemakum, Chimakum" },
  { subtag: "xcl", description: "Classical Armenian" },
  { subtag: "xcm", description: "Comecrudo" },
  { subtag: "xcn", description: "Cotoname" },
  { subtag: "xco", description: "Chorasmian" },
  { subtag: "xcr", description: "Carian" },
  { subtag: "xct", description: "Classical Tibetan" },
  { subtag: "xcu", description: "Curonian" },
  { subtag: "xcv", description: "Chuvantsy" },
  { subtag: "xcw", description: "Coahuilteco" },
  { subtag: "xcy", description: "Cayuse" },
  { subtag: "xda", description: "Darkinyung" },
  { subtag: "xdc", description: "Dacian" },
  { subtag: "xdk", description: "Dharuk" },
  { subtag: "xdm", description: "Edomite" },
  { subtag: "xdo", description: "Kwandu" },
  { subtag: "xdq", description: "Kaitag" },
  { subtag: "xdy", description: "Malayic Dayak" },
  { subtag: "xeb", description: "Eblan" },
  { subtag: "xed", description: "Hdi" },
  { subtag: "xeg", description: "ǁXegwi" },
  { subtag: "xel", description: "Kelo" },
  { subtag: "xem", description: "Kembayan" },
  { subtag: "xep", description: "Epi-Olmec" },
  { subtag: "xer", description: "Xerénte" },
  { subtag: "xes", description: "Kesawai" },
  { subtag: "xet", description: "Xetá" },
  { subtag: "xeu", description: "Keoru-Ahia" },
  { subtag: "xfa", description: "Faliscan" },
  { subtag: "xga", description: "Galatian" },
  { subtag: "xgb", description: "Gbin" },
  { subtag: "xgd", description: "Gudang" },
  { subtag: "xgf", description: "Gabrielino-Fernandeño" },
  { subtag: "xgg", description: "Goreng" },
  { subtag: "xgi", description: "Garingbal" },
  { subtag: "xgl", description: "Galindan" },
  { subtag: "xgm", description: "Dharumbal, Guwinmal" },
  { subtag: "xgn", description: "Mongolian languages" },
  { subtag: "xgr", description: "Garza" },
  { subtag: "xgu", description: "Unggumi" },
  { subtag: "xgw", description: "Guwa" },
  { subtag: "xha", description: "Harami" },
  { subtag: "xhc", description: "Hunnic" },
  { subtag: "xhd", description: "Hadrami" },
  { subtag: "xhe", description: "Khetrani" },
  { subtag: "xhm", description: "Middle Khmer (1400 to 1850 CE)" },
  { subtag: "xhr", description: "Hernican" },
  { subtag: "xht", description: "Hattic" },
  { subtag: "xhu", description: "Hurrian" },
  { subtag: "xhv", description: "Khua" },
  { subtag: "xib", description: "Iberian" },
  { subtag: "xii", description: "Xiri" },
  { subtag: "xil", description: "Illyrian" },
  { subtag: "xin", description: "Xinca" },
  { subtag: "xir", description: "Xiriâna" },
  { subtag: "xis", description: "Kisan" },
  { subtag: "xiv", description: "Indus Valley Language" },
  { subtag: "xiy", description: "Xipaya" },
  { subtag: "xjb", description: "Minjungbal" },
  { subtag: "xjt", description: "Jaitmatang" },
  { subtag: "xka", description: "Kalkoti" },
  { subtag: "xkb", description: "Northern Nago" },
  { subtag: "xkc", description: "Kho'ini" },
  { subtag: "xkd", description: "Mendalam Kayan" },
  { subtag: "xke", description: "Kereho" },
  { subtag: "xkf", description: "Khengkha" },
  { subtag: "xkg", description: "Kagoro" },
  { subtag: "xki", description: "Kenyan Sign Language" },
  { subtag: "xkj", description: "Kajali" },
  { subtag: "xkk", description: "Kachok, Kaco'" },
  { subtag: "xkl", description: "Mainstream Kenyah" },
  { subtag: "xkn", description: "Kayan River Kayan" },
  { subtag: "xko", description: "Kiorr" },
  { subtag: "xkp", description: "Kabatei" },
  { subtag: "xkq", description: "Koroni" },
  { subtag: "xkr", description: "Xakriabá" },
  { subtag: "xks", description: "Kumbewaha" },
  { subtag: "xkt", description: "Kantosi" },
  { subtag: "xku", description: "Kaamba" },
  { subtag: "xkv", description: "Kgalagadi" },
  { subtag: "xkw", description: "Kembra" },
  { subtag: "xkx", description: "Karore" },
  { subtag: "xky", description: "Uma' Lasan" },
  { subtag: "xkz", description: "Kurtokha" },
  { subtag: "xla", description: "Kamula" },
  { subtag: "xlb", description: "Loup B" },
  { subtag: "xlc", description: "Lycian" },
  { subtag: "xld", description: "Lydian" },
  { subtag: "xle", description: "Lemnian" },
  { subtag: "xlg", description: "Ligurian (Ancient)" },
  { subtag: "xli", description: "Liburnian" },
  { subtag: "xln", description: "Alanic" },
  { subtag: "xlo", description: "Loup A" },
  { subtag: "xlp", description: "Lepontic" },
  { subtag: "xls", description: "Lusitanian" },
  { subtag: "xlu", description: "Cuneiform Luwian" },
  { subtag: "xly", description: "Elymian" },
  { subtag: "xma", description: "Mushungulu" },
  { subtag: "xmb", description: "Mbonga" },
  { subtag: "xmc", description: "Makhuwa-Marrevone" },
  { subtag: "xmd", description: "Mbudum" },
  { subtag: "xme", description: "Median" },
  { subtag: "xmf", description: "Mingrelian" },
  { subtag: "xmg", description: "Mengaka" },
  { subtag: "xmh", description: "Kugu-Muminh" },
  { subtag: "xmj", description: "Majera" },
  { subtag: "xmk", description: "Ancient Macedonian" },
  { subtag: "xml", description: "Malaysian Sign Language" },
  { subtag: "xmm", description: "Manado Malay" },
  { subtag: "xmn", description: "Manichaean Middle Persian" },
  { subtag: "xmo", description: "Morerebi" },
  { subtag: "xmp", description: "Kuku-Mu'inh" },
  { subtag: "xmq", description: "Kuku-Mangk" },
  { subtag: "xmr", description: "Meroitic" },
  { subtag: "xms", description: "Moroccan Sign Language" },
  { subtag: "xmt", description: "Matbat" },
  { subtag: "xmu", description: "Kamu" },
  { subtag: "xmv", description: "Antankarana Malagasy, Tankarana Malagasy" },
  { subtag: "xmw", description: "Tsimihety Malagasy" },
  { subtag: "xmx", description: "Salawati, Maden" },
  { subtag: "xmy", description: "Mayaguduna" },
  { subtag: "xmz", description: "Mori Bawah" },
  { subtag: "xna", description: "Ancient North Arabian" },
  { subtag: "xnb", description: "Kanakanabu" },
  { subtag: "xnd", description: "Na-Dene languages" },
  { subtag: "xng", description: "Middle Mongolian" },
  { subtag: "xnh", description: "Kuanhua" },
  { subtag: "xni", description: "Ngarigu" },
  { subtag: "xnj", description: "Ngoni (Tanzania)" },
  { subtag: "xnk", description: "Nganakarti" },
  { subtag: "xnm", description: "Ngumbarl" },
  { subtag: "xnn", description: "Northern Kankanay" },
  { subtag: "xno", description: "Anglo-Norman" },
  { subtag: "xnq", description: "Ngoni (Mozambique)" },
  { subtag: "xnr", description: "Kangri" },
  { subtag: "xns", description: "Kanashi" },
  { subtag: "xnt", description: "Narragansett" },
  { subtag: "xnu", description: "Nukunul" },
  { subtag: "xny", description: "Nyiyaparli" },
  { subtag: "xnz", description: "Kenzi, Mattoki" },
  { subtag: "xoc", description: "O'chi'chi'" },
  { subtag: "xod", description: "Kokoda" },
  { subtag: "xog", description: "Soga" },
  { subtag: "xoi", description: "Kominimung" },
  { subtag: "xok", description: "Xokleng" },
  { subtag: "xom", description: "Komo (Sudan)" },
  { subtag: "xon", description: "Konkomba" },
  { subtag: "xoo", description: "Xukurú" },
  { subtag: "xop", description: "Kopar" },
  { subtag: "xor", description: "Korubo" },
  { subtag: "xow", description: "Kowaki" },
  { subtag: "xpa", description: "Pirriya" },
  { subtag: "xpb", description: "Northeastern Tasmanian, Pyemmairrener" },
  { subtag: "xpc", description: "Pecheneg" },
  { subtag: "xpd", description: "Oyster Bay Tasmanian" },
  { subtag: "xpe", description: "Liberia Kpelle" },
  { subtag: "xpf", description: "Southeast Tasmanian, Nuenonne" },
  { subtag: "xpg", description: "Phrygian" },
  { subtag: "xph", description: "North Midlands Tasmanian, Tyerrenoterpanner" },
  { subtag: "xpi", description: "Pictish" },
  { subtag: "xpj", description: "Mpalitjanh" },
  { subtag: "xpk", description: "Kulina Pano" },
  { subtag: "xpl", description: "Port Sorell Tasmanian" },
  { subtag: "xpm", description: "Pumpokol" },
  { subtag: "xpn", description: "Kapinawá" },
  { subtag: "xpo", description: "Pochutec" },
  { subtag: "xpp", description: "Puyo-Paekche" },
  { subtag: "xpq", description: "Mohegan-Pequot" },
  { subtag: "xpr", description: "Parthian" },
  { subtag: "xps", description: "Pisidian" },
  { subtag: "xpt", description: "Punthamara" },
  { subtag: "xpu", description: "Punic" },
  { subtag: "xpv", description: "Northern Tasmanian, Tommeginne" },
  { subtag: "xpw", description: "Northwestern Tasmanian, Peerapper" },
  { subtag: "xpx", description: "Southwestern Tasmanian, Toogee" },
  { subtag: "xpy", description: "Puyo" },
  { subtag: "xpz", description: "Bruny Island Tasmanian" },
  { subtag: "xqa", description: "Karakhanid" },
  { subtag: "xqt", description: "Qatabanian" },
  { subtag: "xra", description: "Krahô" },
  { subtag: "xrb", description: "Eastern Karaboro" },
  { subtag: "xrd", description: "Gundungurra" },
  { subtag: "xre", description: "Kreye" },
  { subtag: "xrg", description: "Minang" },
  { subtag: "xri", description: "Krikati-Timbira" },
  { subtag: "xrm", description: "Armazic" },
  { subtag: "xrn", description: "Arin" },
  { subtag: "xrr", description: "Raetic" },
  { subtag: "xrt", description: "Aranama-Tamique" },
  { subtag: "xru", description: "Marriammu" },
  { subtag: "xrw", description: "Karawa" },
  { subtag: "xsa", description: "Sabaean" },
  { subtag: "xsb", description: "Sambal" },
  { subtag: "xsc", description: "Scythian" },
  { subtag: "xsd", description: "Sidetic" },
  { subtag: "xse", description: "Sempan" },
  { subtag: "xsh", description: "Shamang" },
  { subtag: "xsi", description: "Sio" },
  { subtag: "xsj", description: "Subi" },
  { subtag: "xsl", description: "South Slavey" },
  { subtag: "xsm", description: "Kasem" },
  { subtag: "xsn", description: "Sanga (Nigeria)" },
  { subtag: "xso", description: "Solano" },
  { subtag: "xsp", description: "Silopi" },
  { subtag: "xsq", description: "Makhuwa-Saka" },
  { subtag: "xsr", description: "Sherpa" },
  { subtag: "xss", description: "Assan" },
  { subtag: "xsu", description: "Sanumá" },
  { subtag: "xsv", description: "Sudovian" },
  { subtag: "xsy", description: "Saisiyat" },
  { subtag: "xta", description: "Alcozauca Mixtec" },
  { subtag: "xtb", description: "Chazumba Mixtec" },
  { subtag: "xtc", description: "Katcha-Kadugli-Miri" },
  { subtag: "xtd", description: "Diuxi-Tilantongo Mixtec" },
  { subtag: "xte", description: "Ketengban" },
  { subtag: "xtg", description: "Transalpine Gaulish" },
  { subtag: "xth", description: "Yitha Yitha" },
  { subtag: "xti", description: "Sinicahua Mixtec" },
  { subtag: "xtj", description: "San Juan Teita Mixtec" },
  { subtag: "xtl", description: "Tijaltepec Mixtec" },
  { subtag: "xtm", description: "Magdalena Peñasco Mixtec" },
  { subtag: "xtn", description: "Northern Tlaxiaco Mixtec" },
  { subtag: "xto", description: "Tokharian A" },
  { subtag: "xtp", description: "San Miguel Piedras Mixtec" },
  { subtag: "xtq", description: "Tumshuqese" },
  { subtag: "xtr", description: "Early Tripuri" },
  { subtag: "xts", description: "Sindihui Mixtec" },
  { subtag: "xtt", description: "Tacahua Mixtec" },
  { subtag: "xtu", description: "Cuyamecalco Mixtec" },
  { subtag: "xtv", description: "Thawa" },
  { subtag: "xtw", description: "Tawandê" },
  { subtag: "xty", description: "Yoloxochitl Mixtec" },
  { subtag: "xua", description: "Alu Kurumba" },
  { subtag: "xub", description: "Betta Kurumba" },
  { subtag: "xud", description: "Umiida" },
  { subtag: "xug", description: "Kunigami" },
  { subtag: "xuj", description: "Jennu Kurumba" },
  { subtag: "xul", description: "Ngunawal, Nunukul" },
  { subtag: "xum", description: "Umbrian" },
  { subtag: "xun", description: "Unggaranggu" },
  { subtag: "xuo", description: "Kuo" },
  { subtag: "xup", description: "Upper Umpqua" },
  { subtag: "xur", description: "Urartian" },
  { subtag: "xut", description: "Kuthant" },
  { subtag: "xuu", description: "Kxoe, Khwedam" },
  { subtag: "xve", description: "Venetic" },
  { subtag: "xvi", description: "Kamviri" },
  { subtag: "xvn", description: "Vandalic" },
  { subtag: "xvo", description: "Volscian" },
  { subtag: "xvs", description: "Vestinian" },
  { subtag: "xwa", description: "Kwaza" },
  { subtag: "xwc", description: "Woccon" },
  { subtag: "xwd", description: "Wadi Wadi" },
  { subtag: "xwe", description: "Xwela Gbe" },
  { subtag: "xwg", description: "Kwegu" },
  { subtag: "xwj", description: "Wajuk" },
  { subtag: "xwk", description: "Wangkumara" },
  { subtag: "xwl", description: "Western Xwla Gbe" },
  { subtag: "xwo", description: "Written Oirat" },
  { subtag: "xwr", description: "Kwerba Mamberamo" },
  { subtag: "xwt", description: "Wotjobaluk" },
  { subtag: "xww", description: "Wemba Wemba" },
  { subtag: "xxb", description: "Boro (Ghana)" },
  { subtag: "xxk", description: "Ke'o" },
  { subtag: "xxm", description: "Minkin" },
  { subtag: "xxr", description: "Koropó" },
  { subtag: "xxt", description: "Tambora" },
  { subtag: "xya", description: "Yaygir" },
  { subtag: "xyb", description: "Yandjibara" },
  { subtag: "xyj", description: "Mayi-Yapi" },
  { subtag: "xyk", description: "Mayi-Kulan" },
  { subtag: "xyl", description: "Yalakalore" },
  { subtag: "xyt", description: "Mayi-Thakurti" },
  { subtag: "xyy", description: "Yorta Yorta" },
  { subtag: "xzh", description: "Zhang-Zhung" },
  { subtag: "xzm", description: "Zemgalian" },
  { subtag: "xzp", description: "Ancient Zapotec" },
  { subtag: "yaa", description: "Yaminahua" },
  { subtag: "yab", description: "Yuhup" },
  { subtag: "yac", description: "Pass Valley Yali" },
  { subtag: "yad", description: "Yagua" },
  { subtag: "yae", description: "Pumé" },
  { subtag: "yaf", description: "Yaka (Democratic Republic of Congo)" },
  { subtag: "yag", description: "Yámana" },
  { subtag: "yah", description: "Yazgulyam" },
  { subtag: "yai", description: "Yagnobi" },
  { subtag: "yaj", description: "Banda-Yangere" },
  { subtag: "yak", description: "Yakama" },
  { subtag: "yal", description: "Yalunka" },
  { subtag: "yam", description: "Yamba" },
  { subtag: "yan", description: "Mayangna" },
  { subtag: "yao", description: "Yao" },
  { subtag: "yap", description: "Yapese" },
  { subtag: "yaq", description: "Yaqui" },
  { subtag: "yar", description: "Yabarana" },
  { subtag: "yas", description: "Nugunu (Cameroon)" },
  { subtag: "yat", description: "Yambeta" },
  { subtag: "yau", description: "Yuwana" },
  { subtag: "yav", description: "Yangben" },
  { subtag: "yaw", description: "Yawalapití" },
  { subtag: "yax", description: "Yauma" },
  { subtag: "yay", description: "Agwagwune" },
  { subtag: "yaz", description: "Lokaa" },
  { subtag: "yba", description: "Yala" },
  { subtag: "ybb", description: "Yemba" },
  { subtag: "ybe", description: "West Yugur" },
  { subtag: "ybh", description: "Yakha" },
  { subtag: "ybi", description: "Yamphu" },
  { subtag: "ybj", description: "Hasha" },
  { subtag: "ybk", description: "Bokha" },
  { subtag: "ybl", description: "Yukuben" },
  { subtag: "ybm", description: "Yaben" },
  { subtag: "ybn", description: "Yabaâna" },
  { subtag: "ybo", description: "Yabong" },
  { subtag: "ybx", description: "Yawiyo" },
  { subtag: "yby", description: "Yaweyuha" },
  { subtag: "ych", description: "Chesu" },
  { subtag: "ycl", description: "Lolopo" },
  { subtag: "ycn", description: "Yucuna" },
  { subtag: "ycp", description: "Chepya" },
  { subtag: "yda", description: "Yanda" },
  { subtag: "ydd", description: "Eastern Yiddish" },
  { subtag: "yde", description: "Yangum Dey" },
  { subtag: "ydg", description: "Yidgha" },
  { subtag: "ydk", description: "Yoidik" },
  { subtag: "yea", description: "Ravula" },
  { subtag: "yec", description: "Yeniche" },
  { subtag: "yee", description: "Yimas" },
  { subtag: "yei", description: "Yeni" },
  { subtag: "yej", description: "Yevanic" },
  { subtag: "yel", description: "Yela" },
  { subtag: "yer", description: "Tarok" },
  { subtag: "yes", description: "Nyankpa" },
  { subtag: "yet", description: "Yetfa" },
  { subtag: "yeu", description: "Yerukula" },
  { subtag: "yev", description: "Yapunda" },
  { subtag: "yey", description: "Yeyi" },
  { subtag: "yga", description: "Malyangapa" },
  { subtag: "ygi", description: "Yiningayi" },
  { subtag: "ygl", description: "Yangum Gel" },
  { subtag: "ygm", description: "Yagomi" },
  { subtag: "ygp", description: "Gepo" },
  { subtag: "ygr", description: "Yagaria" },
  { subtag: "ygs", description: "Yolŋu Sign Language" },
  { subtag: "ygu", description: "Yugul" },
  { subtag: "ygw", description: "Yagwoia" },
  { subtag: "yha", description: "Baha Buyang" },
  { subtag: "yhd", description: "Judeo-Iraqi Arabic" },
  { subtag: "yhl", description: "Hlepho Phowa" },
  { subtag: "yhs", description: "Yan-nhaŋu Sign Language" },
  { subtag: "yia", description: "Yinggarda" },
  { subtag: "yif", description: "Ache" },
  { subtag: "yig", description: "Wusa Nasu" },
  { subtag: "yih", description: "Western Yiddish" },
  { subtag: "yii", description: "Yidiny" },
  { subtag: "yij", description: "Yindjibarndi" },
  { subtag: "yik", description: "Dongshanba Lalo" },
  { subtag: "yil", description: "Yindjilandji" },
  { subtag: "yim", description: "Yimchungru Naga" },
  { subtag: "yin", description: "Riang Lai, Yinchia" },
  { subtag: "yip", description: "Pholo" },
  { subtag: "yiq", description: "Miqie" },
  { subtag: "yir", description: "North Awyu" },
  { subtag: "yis", description: "Yis" },
  { subtag: "yit", description: "Eastern Lalu" },
  { subtag: "yiu", description: "Awu" },
  { subtag: "yiv", description: "Northern Nisu" },
  { subtag: "yix", description: "Axi Yi" },
  { subtag: "yiz", description: "Azhe" },
  { subtag: "yka", description: "Yakan" },
  { subtag: "ykg", description: "Northern Yukaghir" },
  { subtag: "yki", description: "Yoke" },
  { subtag: "ykk", description: "Yakaikeke" },
  { subtag: "ykl", description: "Khlula" },
  { subtag: "ykm", description: "Kap" },
  { subtag: "ykn", description: "Kua-nsi" },
  { subtag: "yko", description: "Yasa" },
  { subtag: "ykr", description: "Yekora" },
  { subtag: "ykt", description: "Kathu" },
  { subtag: "yku", description: "Kuamasi" },
  { subtag: "yky", description: "Yakoma" },
  { subtag: "yla", description: "Yaul" },
  { subtag: "ylb", description: "Yaleba" },
  { subtag: "yle", description: "Yele" },
  { subtag: "ylg", description: "Yelogu" },
  { subtag: "yli", description: "Angguruk Yali" },
  { subtag: "yll", description: "Yil" },
  { subtag: "ylm", description: "Limi" },
  { subtag: "yln", description: "Langnian Buyang" },
  { subtag: "ylo", description: "Naluo Yi" },
  { subtag: "ylr", description: "Yalarnnga" },
  { subtag: "ylu", description: "Aribwaung" },
  { subtag: "yly", description: "Nyâlayu, Nyelâyu" },
  { subtag: "ymb", description: "Yambes" },
  { subtag: "ymc", description: "Southern Muji" },
  { subtag: "ymd", description: "Muda" },
  { subtag: "yme", description: "Yameo" },
  { subtag: "ymg", description: "Yamongeri" },
  { subtag: "ymh", description: "Mili" },
  { subtag: "ymi", description: "Moji" },
  { subtag: "ymk", description: "Makwe" },
  { subtag: "yml", description: "Iamalele" },
  { subtag: "ymm", description: "Maay" },
  { subtag: "ymn", description: "Yamna, Sunum" },
  { subtag: "ymo", description: "Yangum Mon" },
  { subtag: "ymp", description: "Yamap" },
  { subtag: "ymq", description: "Qila Muji" },
  { subtag: "ymr", description: "Malasar" },
  { subtag: "yms", description: "Mysian" },
  { subtag: "ymx", description: "Northern Muji" },
  { subtag: "ymz", description: "Muzi" },
  { subtag: "yna", description: "Aluo" },
  { subtag: "ynd", description: "Yandruwandha" },
  { subtag: "yne", description: "Lang'e" },
  { subtag: "yng", description: "Yango" },
  { subtag: "ynk", description: "Naukan Yupik" },
  { subtag: "ynl", description: "Yangulam" },
  { subtag: "ynn", description: "Yana" },
  { subtag: "yno", description: "Yong" },
  { subtag: "ynq", description: "Yendang" },
  { subtag: "yns", description: "Yansi" },
  { subtag: "ynu", description: "Yahuna" },
  { subtag: "yob", description: "Yoba" },
  { subtag: "yog", description: "Yogad" },
  { subtag: "yoi", description: "Yonaguni" },
  { subtag: "yok", description: "Yokuts" },
  { subtag: "yol", description: "Yola" },
  { subtag: "yom", description: "Yombe" },
  { subtag: "yon", description: "Yongkom" },
  { subtag: "yot", description: "Yotti" },
  { subtag: "yox", description: "Yoron" },
  { subtag: "yoy", description: "Yoy" },
  { subtag: "ypa", description: "Phala" },
  { subtag: "ypb", description: "Labo Phowa" },
  { subtag: "ypg", description: "Phola" },
  { subtag: "yph", description: "Phupha" },
  { subtag: "ypk", description: "Yupik languages" },
  { subtag: "ypm", description: "Phuma" },
  { subtag: "ypn", description: "Ani Phowa" },
  { subtag: "ypo", description: "Alo Phola" },
  { subtag: "ypp", description: "Phupa" },
  { subtag: "ypz", description: "Phuza" },
  { subtag: "yra", description: "Yerakai" },
  { subtag: "yrb", description: "Yareba" },
  { subtag: "yre", description: "Yaouré" },
  { subtag: "yrk", description: "Nenets" },
  { subtag: "yrl", description: "Nhengatu" },
  { subtag: "yrm", description: "Yirrk-Mel" },
  { subtag: "yrn", description: "Yerong" },
  { subtag: "yro", description: "Yaroamë" },
  { subtag: "yrs", description: "Yarsun" },
  { subtag: "yrw", description: "Yarawata" },
  { subtag: "yry", description: "Yarluyandi" },
  { subtag: "ysc", description: "Yassic" },
  { subtag: "ysd", description: "Samatao" },
  { subtag: "ysg", description: "Sonaga" },
  { subtag: "ysl", description: "Yugoslavian Sign Language" },
  { subtag: "ysm", description: "Myanmar Sign Language" },
  { subtag: "ysn", description: "Sani" },
  { subtag: "yso", description: "Nisi (China)" },
  { subtag: "ysp", description: "Southern Lolopo" },
  { subtag: "ysr", description: "Sirenik Yupik" },
  { subtag: "yss", description: "Yessan-Mayo" },
  { subtag: "ysy", description: "Sanie" },
  { subtag: "yta", description: "Talu" },
  { subtag: "ytl", description: "Tanglang" },
  { subtag: "ytp", description: "Thopho" },
  { subtag: "ytw", description: "Yout Wam" },
  { subtag: "yty", description: "Yatay" },
  { subtag: "yua", description: "Yucateco, Yucatec Maya" },
  { subtag: "yub", description: "Yugambal" },
  { subtag: "yuc", description: "Yuchi" },
  { subtag: "yud", description: "Judeo-Tripolitanian Arabic" },
  { subtag: "yue", description: "Yue Chinese, Cantonese" },
  { subtag: "yuf", description: "Havasupai-Walapai-Yavapai" },
  { subtag: "yug", description: "Yug" },
  { subtag: "yui", description: "Yurutí" },
  { subtag: "yuj", description: "Karkar-Yuri" },
  { subtag: "yuk", description: "Yuki" },
  { subtag: "yul", description: "Yulu" },
  { subtag: "yum", description: "Quechan" },
  { subtag: "yun", description: "Bena (Nigeria)" },
  { subtag: "yup", description: "Yukpa" },
  { subtag: "yuq", description: "Yuqui" },
  { subtag: "yur", description: "Yurok" },
  { subtag: "yut", description: "Yopno" },
  { subtag: "yuw", description: "Yau (Morobe Province)" },
  { subtag: "yux", description: "Southern Yukaghir" },
  { subtag: "yuy", description: "East Yugur" },
  { subtag: "yuz", description: "Yuracare" },
  { subtag: "yva", description: "Yawa" },
  { subtag: "yvt", description: "Yavitero" },
  { subtag: "ywa", description: "Kalou" },
  { subtag: "ywg", description: "Yinhawangka" },
  { subtag: "ywl", description: "Western Lalu" },
  { subtag: "ywn", description: "Yawanawa" },
  { subtag: "ywq", description: "Wuding-Luquan Yi" },
  { subtag: "ywr", description: "Yawuru" },
  { subtag: "ywt", description: "Xishanba Lalo, Central Lalo" },
  { subtag: "ywu", description: "Wumeng Nasu" },
  { subtag: "yww", description: "Yawarawarga" },
  { subtag: "yxa", description: "Mayawali" },
  { subtag: "yxg", description: "Yagara" },
  { subtag: "yxl", description: "Yardliyawarra" },
  { subtag: "yxm", description: "Yinwum" },
  { subtag: "yxu", description: "Yuyu" },
  { subtag: "yxy", description: "Yabula Yabula" },
  { subtag: "yyr", description: "Yir Yoront" },
  { subtag: "yyu", description: "Yau (Sandaun Province)" },
  { subtag: "yyz", description: "Ayizi" },
  { subtag: "yzg", description: "E'ma Buyang" },
  { subtag: "yzk", description: "Zokhuo" },
  { subtag: "zaa", description: "Sierra de Juárez Zapotec" },
  {
    subtag: "zab",
    description: "Western Tlacolula Valley Zapotec, San Juan Guelavía Zapotec",
  },
  { subtag: "zac", description: "Ocotlán Zapotec" },
  { subtag: "zad", description: "Cajonos Zapotec" },
  { subtag: "zae", description: "Yareni Zapotec" },
  { subtag: "zaf", description: "Ayoquesco Zapotec" },
  { subtag: "zag", description: "Zaghawa" },
  { subtag: "zah", description: "Zangwal" },
  { subtag: "zai", description: "Isthmus Zapotec" },
  { subtag: "zaj", description: "Zaramo" },
  { subtag: "zak", description: "Zanaki" },
  { subtag: "zal", description: "Zauzou" },
  { subtag: "zam", description: "Miahuatlán Zapotec" },
  { subtag: "zao", description: "Ozolotepec Zapotec" },
  { subtag: "zap", description: "Zapotec" },
  { subtag: "zaq", description: "Aloápam Zapotec" },
  { subtag: "zar", description: "Rincón Zapotec" },
  { subtag: "zas", description: "Santo Domingo Albarradas Zapotec" },
  { subtag: "zat", description: "Tabaa Zapotec" },
  { subtag: "zau", description: "Zangskari" },
  { subtag: "zav", description: "Yatzachi Zapotec" },
  { subtag: "zaw", description: "Mitla Zapotec" },
  { subtag: "zax", description: "Xadani Zapotec" },
  { subtag: "zay", description: "Zayse-Zergulla, Zaysete" },
  { subtag: "zaz", description: "Zari" },
  { subtag: "zba", description: "Balaibalan" },
  { subtag: "zbc", description: "Central Berawan" },
  { subtag: "zbe", description: "East Berawan" },
  { subtag: "zbl", description: "Blissymbols, Bliss, Blissymbolics" },
  { subtag: "zbt", description: "Batui" },
  { subtag: "zbu", description: "Bu (Bauchi State)" },
  { subtag: "zbw", description: "West Berawan" },
  { subtag: "zca", description: "Coatecas Altas Zapotec" },
  { subtag: "zcd", description: "Las Delicias Zapotec" },
  { subtag: "zch", description: "Central Hongshuihe Zhuang" },
  { subtag: "zdj", description: "Ngazidja Comorian" },
  { subtag: "zea", description: "Zeeuws" },
  { subtag: "zeg", description: "Zenag" },
  { subtag: "zeh", description: "Eastern Hongshuihe Zhuang" },
  { subtag: "zen", description: "Zenaga" },
  { subtag: "zga", description: "Kinga" },
  { subtag: "zgb", description: "Guibei Zhuang" },
  { subtag: "zgh", description: "Standard Moroccan Tamazight" },
  { subtag: "zgm", description: "Minz Zhuang" },
  { subtag: "zgn", description: "Guibian Zhuang" },
  { subtag: "zgr", description: "Magori" },
  { subtag: "zhb", description: "Zhaba" },
  { subtag: "zhd", description: "Dai Zhuang" },
  { subtag: "zhi", description: "Zhire" },
  { subtag: "zhn", description: "Nong Zhuang" },
  { subtag: "zhw", description: "Zhoa" },
  { subtag: "zhx", description: "Chinese (family)" },
  { subtag: "zia", description: "Zia" },
  { subtag: "zib", description: "Zimbabwe Sign Language" },
  { subtag: "zik", description: "Zimakani" },
  { subtag: "zil", description: "Zialo" },
  { subtag: "zim", description: "Mesme" },
  { subtag: "zin", description: "Zinza" },
  { subtag: "ziw", description: "Zigula" },
  { subtag: "ziz", description: "Zizilivakan" },
  { subtag: "zka", description: "Kaimbulawa" },
  { subtag: "zkb", description: "Koibal" },
  { subtag: "zkd", description: "Kadu" },
  { subtag: "zkg", description: "Koguryo" },
  { subtag: "zkh", description: "Khorezmian" },
  { subtag: "zkk", description: "Karankawa" },
  { subtag: "zkn", description: "Kanan" },
  { subtag: "zko", description: "Kott" },
  { subtag: "zkp", description: "São Paulo Kaingáng" },
  { subtag: "zkr", description: "Zakhring" },
  { subtag: "zkt", description: "Kitan" },
  { subtag: "zku", description: "Kaurna" },
  { subtag: "zkv", description: "Krevinian" },
  { subtag: "zkz", description: "Khazar" },
  { subtag: "zla", description: "Zula" },
  { subtag: "zle", description: "East Slavic languages" },
  { subtag: "zlj", description: "Liujiang Zhuang" },
  { subtag: "zlm", description: "Malay (individual language)" },
  { subtag: "zln", description: "Lianshan Zhuang" },
  { subtag: "zlq", description: "Liuqian Zhuang" },
  { subtag: "zls", description: "South Slavic languages" },
  { subtag: "zlw", description: "West Slavic languages" },
  { subtag: "zma", description: "Manda (Australia)" },
  { subtag: "zmb", description: "Zimba" },
  { subtag: "zmc", description: "Margany" },
  { subtag: "zmd", description: "Maridan" },
  { subtag: "zme", description: "Mangerr" },
  { subtag: "zmf", description: "Mfinu" },
  { subtag: "zmg", description: "Marti Ke" },
  { subtag: "zmh", description: "Makolkol" },
  { subtag: "zmi", description: "Negeri Sembilan Malay" },
  { subtag: "zmj", description: "Maridjabin" },
  { subtag: "zmk", description: "Mandandanyi" },
  { subtag: "zml", description: "Matngala" },
  { subtag: "zmm", description: "Marimanindji, Marramaninyshi" },
  { subtag: "zmn", description: "Mbangwe" },
  { subtag: "zmo", description: "Molo" },
  { subtag: "zmp", description: "Mpuono" },
  { subtag: "zmq", description: "Mituku" },
  { subtag: "zmr", description: "Maranunggu" },
  { subtag: "zms", description: "Mbesa" },
  { subtag: "zmt", description: "Maringarr" },
  { subtag: "zmu", description: "Muruwari" },
  { subtag: "zmv", description: "Mbariman-Gudhinma" },
  { subtag: "zmw", description: "Mbo (Democratic Republic of Congo)" },
  { subtag: "zmx", description: "Bomitaba" },
  { subtag: "zmy", description: "Mariyedi" },
  { subtag: "zmz", description: "Mbandja" },
  { subtag: "zna", description: "Zan Gula" },
  { subtag: "znd", description: "Zande languages" },
  { subtag: "zne", description: "Zande (individual language)" },
  { subtag: "zng", description: "Mang" },
  { subtag: "znk", description: "Manangkari" },
  { subtag: "zns", description: "Mangas" },
  { subtag: "zoc", description: "Copainalá Zoque" },
  { subtag: "zoh", description: "Chimalapa Zoque" },
  { subtag: "zom", description: "Zou" },
  { subtag: "zoo", description: "Asunción Mixtepec Zapotec" },
  { subtag: "zoq", description: "Tabasco Zoque" },
  { subtag: "zor", description: "Rayón Zoque" },
  { subtag: "zos", description: "Francisco León Zoque" },
  { subtag: "zpa", description: "Lachiguiri Zapotec" },
  { subtag: "zpb", description: "Yautepec Zapotec" },
  { subtag: "zpc", description: "Choapan Zapotec" },
  { subtag: "zpd", description: "Southeastern Ixtlán Zapotec" },
  { subtag: "zpe", description: "Petapa Zapotec" },
  { subtag: "zpf", description: "San Pedro Quiatoni Zapotec" },
  { subtag: "zpg", description: "Guevea De Humboldt Zapotec" },
  { subtag: "zph", description: "Totomachapan Zapotec" },
  { subtag: "zpi", description: "Santa María Quiegolani Zapotec" },
  { subtag: "zpj", description: "Quiavicuzas Zapotec" },
  { subtag: "zpk", description: "Tlacolulita Zapotec" },
  { subtag: "zpl", description: "Lachixío Zapotec" },
  { subtag: "zpm", description: "Mixtepec Zapotec" },
  { subtag: "zpn", description: "Santa Inés Yatzechi Zapotec" },
  { subtag: "zpo", description: "Amatlán Zapotec" },
  { subtag: "zpp", description: "El Alto Zapotec" },
  { subtag: "zpq", description: "Zoogocho Zapotec" },
  { subtag: "zpr", description: "Santiago Xanica Zapotec" },
  { subtag: "zps", description: "Coatlán Zapotec" },
  { subtag: "zpt", description: "San Vicente Coatlán Zapotec" },
  { subtag: "zpu", description: "Yalálag Zapotec" },
  { subtag: "zpv", description: "Chichicapan Zapotec" },
  { subtag: "zpw", description: "Zaniza Zapotec" },
  { subtag: "zpx", description: "San Baltazar Loxicha Zapotec" },
  { subtag: "zpy", description: "Mazaltepec Zapotec" },
  { subtag: "zpz", description: "Texmelucan Zapotec" },
  { subtag: "zqe", description: "Qiubei Zhuang" },
  { subtag: "zra", description: "Kara (Korea)" },
  { subtag: "zrg", description: "Mirgan" },
  { subtag: "zrn", description: "Zerenkel" },
  { subtag: "zro", description: "Záparo" },
  { subtag: "zrp", description: "Zarphatic" },
  { subtag: "zrs", description: "Mairasi" },
  { subtag: "zsa", description: "Sarasira" },
  { subtag: "zsk", description: "Kaskean" },
  { subtag: "zsl", description: "Zambian Sign Language" },
  { subtag: "zsm", description: "Standard Malay" },
  { subtag: "zsr", description: "Southern Rincon Zapotec" },
  { subtag: "zsu", description: "Sukurum" },
  { subtag: "zte", description: "Elotepec Zapotec" },
  { subtag: "ztg", description: "Xanaguía Zapotec" },
  { subtag: "ztl", description: "Lapaguía-Guivini Zapotec" },
  { subtag: "ztm", description: "San Agustín Mixtepec Zapotec" },
  { subtag: "ztn", description: "Santa Catarina Albarradas Zapotec" },
  { subtag: "ztp", description: "Loxicha Zapotec" },
  { subtag: "ztq", description: "Quioquitani-Quierí Zapotec" },
  { subtag: "zts", description: "Tilquiapan Zapotec" },
  { subtag: "ztt", description: "Tejalapan Zapotec" },
  { subtag: "ztu", description: "Güilá Zapotec" },
  { subtag: "ztx", description: "Zaachila Zapotec" },
  { subtag: "zty", description: "Yatee Zapotec" },
  { subtag: "zua", description: "Zeem" },
  { subtag: "zuh", description: "Tokano" },
  { subtag: "zum", description: "Kumzari" },
  { subtag: "zun", description: "Zuni" },
  { subtag: "zuy", description: "Zumaya" },
  { subtag: "zwa", description: "Zay" },
  { subtag: "zxx", description: "No linguistic content, Not applicable" },
  { subtag: "zyb", description: "Yongbei Zhuang" },
  { subtag: "zyg", description: "Yang Zhuang" },
  { subtag: "zyj", description: "Youjiang Zhuang" },
  { subtag: "zyn", description: "Yongnan Zhuang" },
  { subtag: "zyp", description: "Zyphe Chin" },
  {
    subtag: "zza",
    description: "Zaza, Dimili, Dimli",
  },
  { subtag: "zzj", description: "Zuojiang Zhuang" },
];
