export const icons = [
  {
    icon: "500px",
    className: "fa-brands fa-500px",
    label: "500px",
  },
  {
    icon: "a",
    className: "fa-solid fa-a",
    label: "A",
  },
  {
    icon: "accessible-icon",
    className: "fa-brands fa-accessible-icon",
    label: "Accessible Icon",
  },
  {
    icon: "accusoft",
    className: "fa-brands fa-accusoft",
    label: "Accusoft",
  },
  {
    icon: "address-book",
    className: "fa-solid fa-address-book",
    label: "Address Book",
  },
  {
    icon: "address-book",
    className: "fa-regular fa-address-book",
    label: "Address Book",
  },
  {
    icon: "address-card",
    className: "fa-solid fa-address-card",
    label: "Address Card",
  },
  {
    icon: "address-card",
    className: "fa-regular fa-address-card",
    label: "Address Card",
  },
  {
    icon: "adn",
    className: "fa-brands fa-adn",
    label: "App.net",
  },
  {
    icon: "adversal",
    className: "fa-brands fa-adversal",
    label: "Adversal",
  },
  {
    icon: "affiliatetheme",
    className: "fa-brands fa-affiliatetheme",
    label: "affiliatetheme",
  },
  {
    icon: "airbnb",
    className: "fa-brands fa-airbnb",
    label: "Airbnb",
  },
  {
    icon: "algolia",
    className: "fa-brands fa-algolia",
    label: "Algolia",
  },
  {
    icon: "align-center",
    className: "fa-solid fa-align-center",
    label: "align-center",
  },
  {
    icon: "align-justify",
    className: "fa-solid fa-align-justify",
    label: "align-justify",
  },
  {
    icon: "align-left",
    className: "fa-solid fa-align-left",
    label: "align-left",
  },
  {
    icon: "align-right",
    className: "fa-solid fa-align-right",
    label: "align-right",
  },
  {
    icon: "alipay",
    className: "fa-brands fa-alipay",
    label: "Alipay",
  },
  {
    icon: "amazon",
    className: "fa-brands fa-amazon",
    label: "Amazon",
  },
  {
    icon: "amazon-pay",
    className: "fa-brands fa-amazon-pay",
    label: "Amazon Pay",
  },
  {
    icon: "amilia",
    className: "fa-brands fa-amilia",
    label: "Amilia",
  },
  {
    icon: "anchor",
    className: "fa-solid fa-anchor",
    label: "Anchor",
  },
  {
    icon: "anchor-circle-check",
    className: "fa-solid fa-anchor-circle-check",
    label: "Anchor Circle-check",
  },
  {
    icon: "anchor-circle-exclamation",
    className: "fa-solid fa-anchor-circle-exclamation",
    label: "Anchor Circle-exclamation",
  },
  {
    icon: "anchor-circle-xmark",
    className: "fa-solid fa-anchor-circle-xmark",
    label: "Anchor Circle-xmark",
  },
  {
    icon: "anchor-lock",
    className: "fa-solid fa-anchor-lock",
    label: "Anchor Lock",
  },
  {
    icon: "android",
    className: "fa-brands fa-android",
    label: "Android",
  },
  {
    icon: "angellist",
    className: "fa-brands fa-angellist",
    label: "AngelList",
  },
  {
    icon: "angle-down",
    className: "fa-solid fa-angle-down",
    label: "angle-down",
  },
  {
    icon: "angle-left",
    className: "fa-solid fa-angle-left",
    label: "angle-left",
  },
  {
    icon: "angle-right",
    className: "fa-solid fa-angle-right",
    label: "angle-right",
  },
  {
    icon: "angle-up",
    className: "fa-solid fa-angle-up",
    label: "angle-up",
  },
  {
    icon: "angles-down",
    className: "fa-solid fa-angles-down",
    label: "Angles down",
  },
  {
    icon: "angles-left",
    className: "fa-solid fa-angles-left",
    label: "Angles left",
  },
  {
    icon: "angles-right",
    className: "fa-solid fa-angles-right",
    label: "Angles right",
  },
  {
    icon: "angles-up",
    className: "fa-solid fa-angles-up",
    label: "Angles up",
  },
  {
    icon: "angrycreative",
    className: "fa-brands fa-angrycreative",
    label: "Angry Creative",
  },
  {
    icon: "angular",
    className: "fa-brands fa-angular",
    label: "Angular",
  },
  {
    icon: "ankh",
    className: "fa-solid fa-ankh",
    label: "Ankh",
  },
  {
    icon: "app-store",
    className: "fa-brands fa-app-store",
    label: "App Store",
  },
  {
    icon: "app-store-ios",
    className: "fa-brands fa-app-store-ios",
    label: "iOS App Store",
  },
  {
    icon: "apper",
    className: "fa-brands fa-apper",
    label: "Apper Systems AB",
  },
  {
    icon: "apple",
    className: "fa-brands fa-apple",
    label: "Apple",
  },
  {
    icon: "apple-pay",
    className: "fa-brands fa-apple-pay",
    label: "Apple Pay",
  },
  {
    icon: "apple-whole",
    className: "fa-solid fa-apple-whole",
    label: "Apple whole",
  },
  {
    icon: "archway",
    className: "fa-solid fa-archway",
    label: "Archway",
  },
  {
    icon: "arrow-down",
    className: "fa-solid fa-arrow-down",
    label: "Arrow down",
  },
  {
    icon: "arrow-down-1-9",
    className: "fa-solid fa-arrow-down-1-9",
    label: "Arrow down 1 9",
  },
  {
    icon: "arrow-down-9-1",
    className: "fa-solid fa-arrow-down-9-1",
    label: "Arrow down 9 1",
  },
  {
    icon: "arrow-down-a-z",
    className: "fa-solid fa-arrow-down-a-z",
    label: "Arrow down a z",
  },
  {
    icon: "arrow-down-long",
    className: "fa-solid fa-arrow-down-long",
    label: "Arrow down long",
  },
  {
    icon: "arrow-down-short-wide",
    className: "fa-solid fa-arrow-down-short-wide",
    label: "Arrow down short wide",
  },
  {
    icon: "arrow-down-up-across-line",
    className: "fa-solid fa-arrow-down-up-across-line",
    label: "Arrow Down-up-across-line",
  },
  {
    icon: "arrow-down-up-lock",
    className: "fa-solid fa-arrow-down-up-lock",
    label: "Arrow Down-up-lock",
  },
  {
    icon: "arrow-down-wide-short",
    className: "fa-solid fa-arrow-down-wide-short",
    label: "Arrow down wide short",
  },
  {
    icon: "arrow-down-z-a",
    className: "fa-solid fa-arrow-down-z-a",
    label: "Arrow down z a",
  },
  {
    icon: "arrow-left",
    className: "fa-solid fa-arrow-left",
    label: "arrow-left",
  },
  {
    icon: "arrow-left-long",
    className: "fa-solid fa-arrow-left-long",
    label: "Arrow left long",
  },
  {
    icon: "arrow-pointer",
    className: "fa-solid fa-arrow-pointer",
    label: "Arrow pointer",
  },
  {
    icon: "arrow-right",
    className: "fa-solid fa-arrow-right",
    label: "arrow right",
  },
  {
    icon: "arrow-right-arrow-left",
    className: "fa-solid fa-arrow-right-arrow-left",
    label: "Arrow right arrow left",
  },
  {
    icon: "arrow-right-from-bracket",
    className: "fa-solid fa-arrow-right-from-bracket",
    label: "Arrow right from bracket",
  },
  {
    icon: "arrow-right-long",
    className: "fa-solid fa-arrow-right-long",
    label: "Arrow right long",
  },
  {
    icon: "arrow-right-to-bracket",
    className: "fa-solid fa-arrow-right-to-bracket",
    label: "Arrow right to bracket",
  },
  {
    icon: "arrow-right-to-city",
    className: "fa-solid fa-arrow-right-to-city",
    label: "Arrow Right-to-city",
  },
  {
    icon: "arrow-rotate-left",
    className: "fa-solid fa-arrow-rotate-left",
    label: "Arrow Rotate Left",
  },
  {
    icon: "arrow-rotate-right",
    className: "fa-solid fa-arrow-rotate-right",
    label: "Arrow Rotate Right",
  },
  {
    icon: "arrow-trend-down",
    className: "fa-solid fa-arrow-trend-down",
    label: "Arrow trend down",
  },
  {
    icon: "arrow-trend-up",
    className: "fa-solid fa-arrow-trend-up",
    label: "Arrow trend up",
  },
  {
    icon: "arrow-turn-down",
    className: "fa-solid fa-arrow-turn-down",
    label: "Arrow turn down",
  },
  {
    icon: "arrow-turn-up",
    className: "fa-solid fa-arrow-turn-up",
    label: "Arrow turn up",
  },
  {
    icon: "arrow-up",
    className: "fa-solid fa-arrow-up",
    label: "Arrow up",
  },
  {
    icon: "arrow-up-1-9",
    className: "fa-solid fa-arrow-up-1-9",
    label: "Arrow up 1 9",
  },
  {
    icon: "arrow-up-9-1",
    className: "fa-solid fa-arrow-up-9-1",
    label: "Arrow up 9 1",
  },
  {
    icon: "arrow-up-a-z",
    className: "fa-solid fa-arrow-up-a-z",
    label: "Arrow up a z",
  },
  {
    icon: "arrow-up-from-bracket",
    className: "fa-solid fa-arrow-up-from-bracket",
    label: "Arrow up from bracket",
  },
  {
    icon: "arrow-up-from-ground-water",
    className: "fa-solid fa-arrow-up-from-ground-water",
    label: "Arrow Up-from-ground-water",
  },
  {
    icon: "arrow-up-from-water-pump",
    className: "fa-solid fa-arrow-up-from-water-pump",
    label: "Arrow Up-from-water-pump",
  },
  {
    icon: "arrow-up-long",
    className: "fa-solid fa-arrow-up-long",
    label: "Arrow up long",
  },
  {
    icon: "arrow-up-right-dots",
    className: "fa-solid fa-arrow-up-right-dots",
    label: "Arrow Up-right-dots",
  },
  {
    icon: "arrow-up-right-from-square",
    className: "fa-solid fa-arrow-up-right-from-square",
    label: "Arrow up right from square",
  },
  {
    icon: "arrow-up-short-wide",
    className: "fa-solid fa-arrow-up-short-wide",
    label: "Arrow up short wide",
  },
  {
    icon: "arrow-up-wide-short",
    className: "fa-solid fa-arrow-up-wide-short",
    label: "Arrow up wide short",
  },
  {
    icon: "arrow-up-z-a",
    className: "fa-solid fa-arrow-up-z-a",
    label: "Arrow up z a",
  },
  {
    icon: "arrows-down-to-line",
    className: "fa-solid fa-arrows-down-to-line",
    label: "Arrows Down-to-line",
  },
  {
    icon: "arrows-down-to-people",
    className: "fa-solid fa-arrows-down-to-people",
    label: "Arrows Down-to-people",
  },
  {
    icon: "arrows-left-right",
    className: "fa-solid fa-arrows-left-right",
    label: "Arrows left right",
  },
  {
    icon: "arrows-left-right-to-line",
    className: "fa-solid fa-arrows-left-right-to-line",
    label: "Arrows Left-right-to-line",
  },
  {
    icon: "arrows-rotate",
    className: "fa-solid fa-arrows-rotate",
    label: "Arrows rotate",
  },
  {
    icon: "arrows-spin",
    className: "fa-solid fa-arrows-spin",
    label: "Arrows Spin",
  },
  {
    icon: "arrows-split-up-and-left",
    className: "fa-solid fa-arrows-split-up-and-left",
    label: "Arrows Split-up-and-left",
  },
  {
    icon: "arrows-to-circle",
    className: "fa-solid fa-arrows-to-circle",
    label: "Arrows To-circle",
  },
  {
    icon: "arrows-to-dot",
    className: "fa-solid fa-arrows-to-dot",
    label: "Arrows To-dot",
  },
  {
    icon: "arrows-to-eye",
    className: "fa-solid fa-arrows-to-eye",
    label: "Arrows To-eye",
  },
  {
    icon: "arrows-turn-right",
    className: "fa-solid fa-arrows-turn-right",
    label: "Arrows Turn-right",
  },
  {
    icon: "arrows-turn-to-dots",
    className: "fa-solid fa-arrows-turn-to-dots",
    label: "Arrows Turn-to-dots",
  },
  {
    icon: "arrows-up-down",
    className: "fa-solid fa-arrows-up-down",
    label: "Arrows up down",
  },
  {
    icon: "arrows-up-down-left-right",
    className: "fa-solid fa-arrows-up-down-left-right",
    label: "Arrows up down left right",
  },
  {
    icon: "arrows-up-to-line",
    className: "fa-solid fa-arrows-up-to-line",
    label: "Arrows Up-to-line",
  },
  {
    icon: "artstation",
    className: "fa-brands fa-artstation",
    label: "Artstation",
  },
  {
    icon: "asterisk",
    className: "fa-solid fa-asterisk",
    label: "asterisk",
  },
  {
    icon: "asymmetrik",
    className: "fa-brands fa-asymmetrik",
    label: "Asymmetrik, Ltd.",
  },
  {
    icon: "at",
    className: "fa-solid fa-at",
    label: "At",
  },
  {
    icon: "atlassian",
    className: "fa-brands fa-atlassian",
    label: "Atlassian",
  },
  {
    icon: "atom",
    className: "fa-solid fa-atom",
    label: "Atom",
  },
  {
    icon: "audible",
    className: "fa-brands fa-audible",
    label: "Audible",
  },
  {
    icon: "audio-description",
    className: "fa-solid fa-audio-description",
    label: "Rectangle audio description",
  },
  {
    icon: "austral-sign",
    className: "fa-solid fa-austral-sign",
    label: "Austral Sign",
  },
  {
    icon: "autoprefixer",
    className: "fa-brands fa-autoprefixer",
    label: "Autoprefixer",
  },
  {
    icon: "avianex",
    className: "fa-brands fa-avianex",
    label: "avianex",
  },
  {
    icon: "aviato",
    className: "fa-brands fa-aviato",
    label: "Aviato",
  },
  {
    icon: "award",
    className: "fa-solid fa-award",
    label: "Award",
  },
  {
    icon: "aws",
    className: "fa-brands fa-aws",
    label: "Amazon Web Services (AWS)",
  },
  {
    icon: "b",
    className: "fa-solid fa-b",
    label: "B",
  },
  {
    icon: "baby",
    className: "fa-solid fa-baby",
    label: "Baby",
  },
  {
    icon: "baby-carriage",
    className: "fa-solid fa-baby-carriage",
    label: "Baby Carriage",
  },
  {
    icon: "backward",
    className: "fa-solid fa-backward",
    label: "backward",
  },
  {
    icon: "backward-fast",
    className: "fa-solid fa-backward-fast",
    label: "Backward fast",
  },
  {
    icon: "backward-step",
    className: "fa-solid fa-backward-step",
    label: "Backward step",
  },
  {
    icon: "bacon",
    className: "fa-solid fa-bacon",
    label: "Bacon",
  },
  {
    icon: "bacteria",
    className: "fa-solid fa-bacteria",
    label: "Bacteria",
  },
  {
    icon: "bacterium",
    className: "fa-solid fa-bacterium",
    label: "Bacterium",
  },
  {
    icon: "bag-shopping",
    className: "fa-solid fa-bag-shopping",
    label: "Bag shopping",
  },
  {
    icon: "bahai",
    className: "fa-solid fa-bahai",
    label: "Bahá'í",
  },
  {
    icon: "baht-sign",
    className: "fa-solid fa-baht-sign",
    label: "Baht Sign",
  },
  {
    icon: "ban",
    className: "fa-solid fa-ban",
    label: "ban",
  },
  {
    icon: "ban-smoking",
    className: "fa-solid fa-ban-smoking",
    label: "Ban smoking",
  },
  {
    icon: "bandage",
    className: "fa-solid fa-bandage",
    label: "Bandage",
  },
  {
    icon: "bandcamp",
    className: "fa-brands fa-bandcamp",
    label: "Bandcamp",
  },
  {
    icon: "barcode",
    className: "fa-solid fa-barcode",
    label: "barcode",
  },
  {
    icon: "bars",
    className: "fa-solid fa-bars",
    label: "Bars",
  },
  {
    icon: "bars-progress",
    className: "fa-solid fa-bars-progress",
    label: "Bars progress",
  },
  {
    icon: "bars-staggered",
    className: "fa-solid fa-bars-staggered",
    label: "Bars staggered",
  },
  {
    icon: "baseball",
    className: "fa-solid fa-baseball",
    label: "Baseball Ball",
  },
  {
    icon: "baseball-bat-ball",
    className: "fa-solid fa-baseball-bat-ball",
    label: "Baseball bat ball",
  },
  {
    icon: "basket-shopping",
    className: "fa-solid fa-basket-shopping",
    label: "Basket shopping",
  },
  {
    icon: "basketball",
    className: "fa-solid fa-basketball",
    label: "Basketball Ball",
  },
  {
    icon: "bath",
    className: "fa-solid fa-bath",
    label: "Bath",
  },
  {
    icon: "battery-empty",
    className: "fa-solid fa-battery-empty",
    label: "Battery Empty",
  },
  {
    icon: "battery-full",
    className: "fa-solid fa-battery-full",
    label: "Battery Full",
  },
  {
    icon: "battery-half",
    className: "fa-solid fa-battery-half",
    label: "Battery 1/2 Full",
  },
  {
    icon: "battery-quarter",
    className: "fa-solid fa-battery-quarter",
    label: "Battery 1/4 Full",
  },
  {
    icon: "battery-three-quarters",
    className: "fa-solid fa-battery-three-quarters",
    label: "Battery 3/4 Full",
  },
  {
    icon: "battle-net",
    className: "fa-brands fa-battle-net",
    label: "Battle.net",
  },
  {
    icon: "bed",
    className: "fa-solid fa-bed",
    label: "Bed",
  },
  {
    icon: "bed-pulse",
    className: "fa-solid fa-bed-pulse",
    label: "Bed pulse",
  },
  {
    icon: "beer-mug-empty",
    className: "fa-solid fa-beer-mug-empty",
    label: "Beer mug empty",
  },
  {
    icon: "behance",
    className: "fa-brands fa-behance",
    label: "Behance",
  },
  {
    icon: "behance-square",
    className: "fa-brands fa-behance-square",
    label: "Behance Square",
  },
  {
    icon: "bell",
    className: "fa-solid fa-bell",
    label: "bell",
  },
  {
    icon: "bell",
    className: "fa-regular fa-bell",
    label: "bell",
  },
  {
    icon: "bell-concierge",
    className: "fa-solid fa-bell-concierge",
    label: "Bell concierge",
  },
  {
    icon: "bell-slash",
    className: "fa-solid fa-bell-slash",
    label: "Bell Slash",
  },
  {
    icon: "bell-slash",
    className: "fa-regular fa-bell-slash",
    label: "Bell Slash",
  },
  {
    icon: "bezier-curve",
    className: "fa-solid fa-bezier-curve",
    label: "Bezier Curve",
  },
  {
    icon: "bicycle",
    className: "fa-solid fa-bicycle",
    label: "Bicycle",
  },
  {
    icon: "bilibili",
    className: "fa-brands fa-bilibili",
    label: "Bilibili",
  },
  {
    icon: "bimobject",
    className: "fa-brands fa-bimobject",
    label: "BIMobject",
  },
  {
    icon: "binoculars",
    className: "fa-solid fa-binoculars",
    label: "Binoculars",
  },
  {
    icon: "biohazard",
    className: "fa-solid fa-biohazard",
    label: "Biohazard",
  },
  {
    icon: "bitbucket",
    className: "fa-brands fa-bitbucket",
    label: "Bitbucket",
  },
  {
    icon: "bitcoin",
    className: "fa-brands fa-bitcoin",
    label: "Bitcoin",
  },
  {
    icon: "bitcoin-sign",
    className: "fa-solid fa-bitcoin-sign",
    label: "Bitcoin Sign",
  },
  {
    icon: "bity",
    className: "fa-brands fa-bity",
    label: "Bity",
  },
  {
    icon: "black-tie",
    className: "fa-brands fa-black-tie",
    label: "Font Awesome Black Tie",
  },
  {
    icon: "blackberry",
    className: "fa-brands fa-blackberry",
    label: "BlackBerry",
  },
  {
    icon: "blender",
    className: "fa-solid fa-blender",
    label: "Blender",
  },
  {
    icon: "blender-phone",
    className: "fa-solid fa-blender-phone",
    label: "Blender Phone",
  },
  {
    icon: "blog",
    className: "fa-solid fa-blog",
    label: "Blog",
  },
  {
    icon: "blogger",
    className: "fa-brands fa-blogger",
    label: "Blogger",
  },
  {
    icon: "blogger-b",
    className: "fa-brands fa-blogger-b",
    label: "Blogger B",
  },
  {
    icon: "bluetooth",
    className: "fa-brands fa-bluetooth",
    label: "Bluetooth",
  },
  {
    icon: "bluetooth-b",
    className: "fa-brands fa-bluetooth-b",
    label: "Bluetooth",
  },
  {
    icon: "bold",
    className: "fa-solid fa-bold",
    label: "bold",
  },
  {
    icon: "bolt",
    className: "fa-solid fa-bolt",
    label: "Bolt",
  },
  {
    icon: "bolt-lightning",
    className: "fa-solid fa-bolt-lightning",
    label: "Lightning Bolt",
  },
  {
    icon: "bomb",
    className: "fa-solid fa-bomb",
    label: "Bomb",
  },
  {
    icon: "bone",
    className: "fa-solid fa-bone",
    label: "Bone",
  },
  {
    icon: "bong",
    className: "fa-solid fa-bong",
    label: "Bong",
  },
  {
    icon: "book",
    className: "fa-solid fa-book",
    label: "book",
  },
  {
    icon: "book-atlas",
    className: "fa-solid fa-book-atlas",
    label: "Book atlas",
  },
  {
    icon: "book-bible",
    className: "fa-solid fa-book-bible",
    label: "Book bible",
  },
  {
    icon: "book-bookmark",
    className: "fa-solid fa-book-bookmark",
    label: "Book Bookmark",
  },
  {
    icon: "book-journal-whills",
    className: "fa-solid fa-book-journal-whills",
    label: "Book journal whills",
  },
  {
    icon: "book-medical",
    className: "fa-solid fa-book-medical",
    label: "Medical Book",
  },
  {
    icon: "book-open",
    className: "fa-solid fa-book-open",
    label: "Book Open",
  },
  {
    icon: "book-open-reader",
    className: "fa-solid fa-book-open-reader",
    label: "Book open reader",
  },
  {
    icon: "book-quran",
    className: "fa-solid fa-book-quran",
    label: "Book quran",
  },
  {
    icon: "book-skull",
    className: "fa-solid fa-book-skull",
    label: "Book skull",
  },
  {
    icon: "bookmark",
    className: "fa-solid fa-bookmark",
    label: "bookmark",
  },
  {
    icon: "bookmark",
    className: "fa-regular fa-bookmark",
    label: "bookmark",
  },
  {
    icon: "bootstrap",
    className: "fa-brands fa-bootstrap",
    label: "Bootstrap",
  },
  {
    icon: "border-all",
    className: "fa-solid fa-border-all",
    label: "Border All",
  },
  {
    icon: "border-none",
    className: "fa-solid fa-border-none",
    label: "Border None",
  },
  {
    icon: "border-top-left",
    className: "fa-solid fa-border-top-left",
    label: "Border top left",
  },
  {
    icon: "bore-hole",
    className: "fa-solid fa-bore-hole",
    label: "Bore Hole",
  },
  {
    icon: "bots",
    className: "fa-brands fa-bots",
    label: "Bots",
  },
  {
    icon: "bottle-droplet",
    className: "fa-solid fa-bottle-droplet",
    label: "Bottle Droplet",
  },
  {
    icon: "bottle-water",
    className: "fa-solid fa-bottle-water",
    label: "Bottle Water",
  },
  {
    icon: "bowl-food",
    className: "fa-solid fa-bowl-food",
    label: "Bowl Food",
  },
  {
    icon: "bowl-rice",
    className: "fa-solid fa-bowl-rice",
    label: "Bowl Rice",
  },
  {
    icon: "bowling-ball",
    className: "fa-solid fa-bowling-ball",
    label: "Bowling Ball",
  },
  {
    icon: "box",
    className: "fa-solid fa-box",
    label: "Box",
  },
  {
    icon: "box-archive",
    className: "fa-solid fa-box-archive",
    label: "Box archive",
  },
  {
    icon: "box-open",
    className: "fa-solid fa-box-open",
    label: "Box Open",
  },
  {
    icon: "box-tissue",
    className: "fa-solid fa-box-tissue",
    label: "Tissue Box",
  },
  {
    icon: "boxes-packing",
    className: "fa-solid fa-boxes-packing",
    label: "Boxes Packing",
  },
  {
    icon: "boxes-stacked",
    className: "fa-solid fa-boxes-stacked",
    label: "Boxes stacked",
  },
  {
    icon: "braille",
    className: "fa-solid fa-braille",
    label: "Braille",
  },
  {
    icon: "brain",
    className: "fa-solid fa-brain",
    label: "Brain",
  },
  {
    icon: "brazilian-real-sign",
    className: "fa-solid fa-brazilian-real-sign",
    label: "Brazilian Real Sign",
  },
  {
    icon: "bread-slice",
    className: "fa-solid fa-bread-slice",
    label: "Bread Slice",
  },
  {
    icon: "bridge",
    className: "fa-solid fa-bridge",
    label: "Bridge",
  },
  {
    icon: "bridge-circle-check",
    className: "fa-solid fa-bridge-circle-check",
    label: "Bridge Circle-check",
  },
  {
    icon: "bridge-circle-exclamation",
    className: "fa-solid fa-bridge-circle-exclamation",
    label: "Bridge Circle-exclamation",
  },
  {
    icon: "bridge-circle-xmark",
    className: "fa-solid fa-bridge-circle-xmark",
    label: "Bridge Circle-xmark",
  },
  {
    icon: "bridge-lock",
    className: "fa-solid fa-bridge-lock",
    label: "Bridge Lock",
  },
  {
    icon: "bridge-water",
    className: "fa-solid fa-bridge-water",
    label: "Bridge Water",
  },
  {
    icon: "briefcase",
    className: "fa-solid fa-briefcase",
    label: "Briefcase",
  },
  {
    icon: "briefcase-medical",
    className: "fa-solid fa-briefcase-medical",
    label: "Medical Briefcase",
  },
  {
    icon: "broom",
    className: "fa-solid fa-broom",
    label: "Broom",
  },
  {
    icon: "broom-ball",
    className: "fa-solid fa-broom-ball",
    label: "Broom and Ball",
  },
  {
    icon: "brush",
    className: "fa-solid fa-brush",
    label: "Brush",
  },
  {
    icon: "btc",
    className: "fa-brands fa-btc",
    label: "BTC",
  },
  {
    icon: "bucket",
    className: "fa-solid fa-bucket",
    label: "Bucket",
  },
  {
    icon: "buffer",
    className: "fa-brands fa-buffer",
    label: "Buffer",
  },
  {
    icon: "bug",
    className: "fa-solid fa-bug",
    label: "Bug",
  },
  {
    icon: "bug-slash",
    className: "fa-solid fa-bug-slash",
    label: "Bug Slash",
  },
  {
    icon: "bugs",
    className: "fa-solid fa-bugs",
    label: "Bugs",
  },
  {
    icon: "building",
    className: "fa-solid fa-building",
    label: "Building",
  },
  {
    icon: "building",
    className: "fa-regular fa-building",
    label: "Building",
  },
  {
    icon: "building-circle-arrow-right",
    className: "fa-solid fa-building-circle-arrow-right",
    label: "Building Circle-arrow-right",
  },
  {
    icon: "building-circle-check",
    className: "fa-solid fa-building-circle-check",
    label: "Building Circle-check",
  },
  {
    icon: "building-circle-exclamation",
    className: "fa-solid fa-building-circle-exclamation",
    label: "Building Circle-exclamation",
  },
  {
    icon: "building-circle-xmark",
    className: "fa-solid fa-building-circle-xmark",
    label: "Building Circle-xmark",
  },
  {
    icon: "building-columns",
    className: "fa-solid fa-building-columns",
    label: "Building with Columns",
  },
  {
    icon: "building-flag",
    className: "fa-solid fa-building-flag",
    label: "Building Flag",
  },
  {
    icon: "building-lock",
    className: "fa-solid fa-building-lock",
    label: "Building Lock",
  },
  {
    icon: "building-ngo",
    className: "fa-solid fa-building-ngo",
    label: "Building Ngo",
  },
  {
    icon: "building-shield",
    className: "fa-solid fa-building-shield",
    label: "Building Shield",
  },
  {
    icon: "building-un",
    className: "fa-solid fa-building-un",
    label: "Building Un",
  },
  {
    icon: "building-user",
    className: "fa-solid fa-building-user",
    label: "Building User",
  },
  {
    icon: "building-wheat",
    className: "fa-solid fa-building-wheat",
    label: "Building Wheat",
  },
  {
    icon: "bullhorn",
    className: "fa-solid fa-bullhorn",
    label: "bullhorn",
  },
  {
    icon: "bullseye",
    className: "fa-solid fa-bullseye",
    label: "Bullseye",
  },
  {
    icon: "burger",
    className: "fa-solid fa-burger",
    label: "Burger",
  },
  {
    icon: "buromobelexperte",
    className: "fa-brands fa-buromobelexperte",
    label: "Büromöbel-Experte GmbH & Co. KG.",
  },
  {
    icon: "burst",
    className: "fa-solid fa-burst",
    label: "Burst",
  },
  {
    icon: "bus",
    className: "fa-solid fa-bus",
    label: "Bus",
  },
  {
    icon: "bus-simple",
    className: "fa-solid fa-bus-simple",
    label: "Bus simple",
  },
  {
    icon: "business-time",
    className: "fa-solid fa-business-time",
    label: "Briefcase clock",
  },
  {
    icon: "buy-n-large",
    className: "fa-brands fa-buy-n-large",
    label: "Buy n Large",
  },
  {
    icon: "buysellads",
    className: "fa-brands fa-buysellads",
    label: "BuySellAds",
  },
  {
    icon: "c",
    className: "fa-solid fa-c",
    label: "C",
  },
  {
    icon: "cake-candles",
    className: "fa-solid fa-cake-candles",
    label: "Cake candles",
  },
  {
    icon: "calculator",
    className: "fa-solid fa-calculator",
    label: "Calculator",
  },
  {
    icon: "calendar",
    className: "fa-solid fa-calendar",
    label: "Calendar",
  },
  {
    icon: "calendar",
    className: "fa-regular fa-calendar",
    label: "Calendar",
  },
  {
    icon: "calendar-check",
    className: "fa-solid fa-calendar-check",
    label: "Calendar Check",
  },
  {
    icon: "calendar-check",
    className: "fa-regular fa-calendar-check",
    label: "Calendar Check",
  },
  {
    icon: "calendar-day",
    className: "fa-solid fa-calendar-day",
    label: "Calendar with Day Focus",
  },
  {
    icon: "calendar-days",
    className: "fa-solid fa-calendar-days",
    label: "Calendar Days",
  },
  {
    icon: "calendar-days",
    className: "fa-regular fa-calendar-days",
    label: "Calendar Days",
  },
  {
    icon: "calendar-minus",
    className: "fa-solid fa-calendar-minus",
    label: "Calendar Minus",
  },
  {
    icon: "calendar-minus",
    className: "fa-regular fa-calendar-minus",
    label: "Calendar Minus",
  },
  {
    icon: "calendar-plus",
    className: "fa-solid fa-calendar-plus",
    label: "Calendar Plus",
  },
  {
    icon: "calendar-plus",
    className: "fa-regular fa-calendar-plus",
    label: "Calendar Plus",
  },
  {
    icon: "calendar-week",
    className: "fa-solid fa-calendar-week",
    label: "Calendar with Week Focus",
  },
  {
    icon: "calendar-xmark",
    className: "fa-solid fa-calendar-xmark",
    label: "Calendar X Mark",
  },
  {
    icon: "calendar-xmark",
    className: "fa-regular fa-calendar-xmark",
    label: "Calendar X Mark",
  },
  {
    icon: "camera",
    className: "fa-solid fa-camera",
    label: "camera",
  },
  {
    icon: "camera-retro",
    className: "fa-solid fa-camera-retro",
    label: "Retro Camera",
  },
  {
    icon: "camera-rotate",
    className: "fa-solid fa-camera-rotate",
    label: "Camera Rotate",
  },
  {
    icon: "campground",
    className: "fa-solid fa-campground",
    label: "Campground",
  },
  {
    icon: "canadian-maple-leaf",
    className: "fa-brands fa-canadian-maple-leaf",
    label: "Canadian Maple Leaf",
  },
  {
    icon: "candy-cane",
    className: "fa-solid fa-candy-cane",
    label: "Candy Cane",
  },
  {
    icon: "cannabis",
    className: "fa-solid fa-cannabis",
    label: "Cannabis",
  },
  {
    icon: "capsules",
    className: "fa-solid fa-capsules",
    label: "Capsules",
  },
  {
    icon: "car",
    className: "fa-solid fa-car",
    label: "Car",
  },
  {
    icon: "car-battery",
    className: "fa-solid fa-car-battery",
    label: "Car Battery",
  },
  {
    icon: "car-burst",
    className: "fa-solid fa-car-burst",
    label: "Car Crash",
  },
  {
    icon: "car-on",
    className: "fa-solid fa-car-on",
    label: "Car On",
  },
  {
    icon: "car-rear",
    className: "fa-solid fa-car-rear",
    label: "Car rear",
  },
  {
    icon: "car-side",
    className: "fa-solid fa-car-side",
    label: "Car Side",
  },
  {
    icon: "car-tunnel",
    className: "fa-solid fa-car-tunnel",
    label: "Car Tunnel",
  },
  {
    icon: "caravan",
    className: "fa-solid fa-caravan",
    label: "Caravan",
  },
  {
    icon: "caret-down",
    className: "fa-solid fa-caret-down",
    label: "Caret Down",
  },
  {
    icon: "caret-left",
    className: "fa-solid fa-caret-left",
    label: "Caret Left",
  },
  {
    icon: "caret-right",
    className: "fa-solid fa-caret-right",
    label: "Caret Right",
  },
  {
    icon: "caret-up",
    className: "fa-solid fa-caret-up",
    label: "Caret Up",
  },
  {
    icon: "carrot",
    className: "fa-solid fa-carrot",
    label: "Carrot",
  },
  {
    icon: "cart-arrow-down",
    className: "fa-solid fa-cart-arrow-down",
    label: "Shopping Cart Arrow Down",
  },
  {
    icon: "cart-flatbed",
    className: "fa-solid fa-cart-flatbed",
    label: "Cart flatbed",
  },
  {
    icon: "cart-flatbed-suitcase",
    className: "fa-solid fa-cart-flatbed-suitcase",
    label: "Cart flatbed suitcase",
  },
  {
    icon: "cart-plus",
    className: "fa-solid fa-cart-plus",
    label: "Add to Shopping Cart",
  },
  {
    icon: "cart-shopping",
    className: "fa-solid fa-cart-shopping",
    label: "Cart shopping",
  },
  {
    icon: "cash-register",
    className: "fa-solid fa-cash-register",
    label: "Cash Register",
  },
  {
    icon: "cat",
    className: "fa-solid fa-cat",
    label: "Cat",
  },
  {
    icon: "cc-amazon-pay",
    className: "fa-brands fa-cc-amazon-pay",
    label: "Amazon Pay Credit Card",
  },
  {
    icon: "cc-amex",
    className: "fa-brands fa-cc-amex",
    label: "American Express Credit Card",
  },
  {
    icon: "cc-apple-pay",
    className: "fa-brands fa-cc-apple-pay",
    label: "Apple Pay Credit Card",
  },
  {
    icon: "cc-diners-club",
    className: "fa-brands fa-cc-diners-club",
    label: "Diner's Club Credit Card",
  },
  {
    icon: "cc-discover",
    className: "fa-brands fa-cc-discover",
    label: "Discover Credit Card",
  },
  {
    icon: "cc-jcb",
    className: "fa-brands fa-cc-jcb",
    label: "JCB Credit Card",
  },
  {
    icon: "cc-mastercard",
    className: "fa-brands fa-cc-mastercard",
    label: "MasterCard Credit Card",
  },
  {
    icon: "cc-paypal",
    className: "fa-brands fa-cc-paypal",
    label: "Paypal Credit Card",
  },
  {
    icon: "cc-stripe",
    className: "fa-brands fa-cc-stripe",
    label: "Stripe Credit Card",
  },
  {
    icon: "cc-visa",
    className: "fa-brands fa-cc-visa",
    label: "Visa Credit Card",
  },
  {
    icon: "cedi-sign",
    className: "fa-solid fa-cedi-sign",
    label: "Cedi Sign",
  },
  {
    icon: "cent-sign",
    className: "fa-solid fa-cent-sign",
    label: "Cent Sign",
  },
  {
    icon: "centercode",
    className: "fa-brands fa-centercode",
    label: "Centercode",
  },
  {
    icon: "centos",
    className: "fa-brands fa-centos",
    label: "Centos",
  },
  {
    icon: "certificate",
    className: "fa-solid fa-certificate",
    label: "certificate",
  },
  {
    icon: "chair",
    className: "fa-solid fa-chair",
    label: "Chair",
  },
  {
    icon: "chalkboard",
    className: "fa-solid fa-chalkboard",
    label: "Chalkboard",
  },
  {
    icon: "chalkboard-user",
    className: "fa-solid fa-chalkboard-user",
    label: "Chalkboard user",
  },
  {
    icon: "champagne-glasses",
    className: "fa-solid fa-champagne-glasses",
    label: "Champagne glasses",
  },
  {
    icon: "charging-station",
    className: "fa-solid fa-charging-station",
    label: "Charging Station",
  },
  {
    icon: "chart-area",
    className: "fa-solid fa-chart-area",
    label: "Area Chart",
  },
  {
    icon: "chart-bar",
    className: "fa-solid fa-chart-bar",
    label: "Bar Chart",
  },
  {
    icon: "chart-bar",
    className: "fa-regular fa-chart-bar",
    label: "Bar Chart",
  },
  {
    icon: "chart-column",
    className: "fa-solid fa-chart-column",
    label: "Chart Column",
  },
  {
    icon: "chart-gantt",
    className: "fa-solid fa-chart-gantt",
    label: "Chart Gantt",
  },
  {
    icon: "chart-line",
    className: "fa-solid fa-chart-line",
    label: "Line Chart",
  },
  {
    icon: "chart-pie",
    className: "fa-solid fa-chart-pie",
    label: "Pie Chart",
  },
  {
    icon: "chart-simple",
    className: "fa-solid fa-chart-simple",
    label: "Chart Simple",
  },
  {
    icon: "check",
    className: "fa-solid fa-check",
    label: "Check",
  },
  {
    icon: "check-double",
    className: "fa-solid fa-check-double",
    label: "Double Check",
  },
  {
    icon: "check-to-slot",
    className: "fa-solid fa-check-to-slot",
    label: "Check to Slot",
  },
  {
    icon: "cheese",
    className: "fa-solid fa-cheese",
    label: "Cheese",
  },
  {
    icon: "chess",
    className: "fa-solid fa-chess",
    label: "Chess",
  },
  {
    icon: "chess-bishop",
    className: "fa-solid fa-chess-bishop",
    label: "Chess Bishop",
  },
  {
    icon: "chess-bishop",
    className: "fa-regular fa-chess-bishop",
    label: "Chess Bishop",
  },
  {
    icon: "chess-board",
    className: "fa-solid fa-chess-board",
    label: "Chess Board",
  },
  {
    icon: "chess-king",
    className: "fa-solid fa-chess-king",
    label: "Chess King",
  },
  {
    icon: "chess-king",
    className: "fa-regular fa-chess-king",
    label: "Chess King",
  },
  {
    icon: "chess-knight",
    className: "fa-solid fa-chess-knight",
    label: "Chess Knight",
  },
  {
    icon: "chess-knight",
    className: "fa-regular fa-chess-knight",
    label: "Chess Knight",
  },
  {
    icon: "chess-pawn",
    className: "fa-solid fa-chess-pawn",
    label: "Chess Pawn",
  },
  {
    icon: "chess-pawn",
    className: "fa-regular fa-chess-pawn",
    label: "Chess Pawn",
  },
  {
    icon: "chess-queen",
    className: "fa-solid fa-chess-queen",
    label: "Chess Queen",
  },
  {
    icon: "chess-queen",
    className: "fa-regular fa-chess-queen",
    label: "Chess Queen",
  },
  {
    icon: "chess-rook",
    className: "fa-solid fa-chess-rook",
    label: "Chess Rook",
  },
  {
    icon: "chess-rook",
    className: "fa-regular fa-chess-rook",
    label: "Chess Rook",
  },
  {
    icon: "chevron-down",
    className: "fa-solid fa-chevron-down",
    label: "chevron-down",
  },
  {
    icon: "chevron-left",
    className: "fa-solid fa-chevron-left",
    label: "chevron-left",
  },
  {
    icon: "chevron-right",
    className: "fa-solid fa-chevron-right",
    label: "chevron-right",
  },
  {
    icon: "chevron-up",
    className: "fa-solid fa-chevron-up",
    label: "chevron-up",
  },
  {
    icon: "child",
    className: "fa-solid fa-child",
    label: "Child",
  },
  {
    icon: "child-dress",
    className: "fa-solid fa-child-dress",
    label: "Child Dress",
  },
  {
    icon: "child-reaching",
    className: "fa-solid fa-child-reaching",
    label: "Child Reaching",
  },
  {
    icon: "child-rifle",
    className: "fa-solid fa-child-rifle",
    label: "Child Rifle",
  },
  {
    icon: "children",
    className: "fa-solid fa-children",
    label: "Children",
  },
  {
    icon: "chrome",
    className: "fa-brands fa-chrome",
    label: "Chrome",
  },
  {
    icon: "chromecast",
    className: "fa-brands fa-chromecast",
    label: "Chromecast",
  },
  {
    icon: "church",
    className: "fa-solid fa-church",
    label: "Church",
  },
  {
    icon: "circle",
    className: "fa-solid fa-circle",
    label: "Circle",
  },
  {
    icon: "circle",
    className: "fa-regular fa-circle",
    label: "Circle",
  },
  {
    icon: "circle-arrow-down",
    className: "fa-solid fa-circle-arrow-down",
    label: "Circle arrow down",
  },
  {
    icon: "circle-arrow-left",
    className: "fa-solid fa-circle-arrow-left",
    label: "Circle arrow left",
  },
  {
    icon: "circle-arrow-right",
    className: "fa-solid fa-circle-arrow-right",
    label: "Circle arrow right",
  },
  {
    icon: "circle-arrow-up",
    className: "fa-solid fa-circle-arrow-up",
    label: "Circle arrow up",
  },
  {
    icon: "circle-check",
    className: "fa-solid fa-circle-check",
    label: "Circle check",
  },
  {
    icon: "circle-check",
    className: "fa-regular fa-circle-check",
    label: "Circle check",
  },
  {
    icon: "circle-chevron-down",
    className: "fa-solid fa-circle-chevron-down",
    label: "Circle chevron down",
  },
  {
    icon: "circle-chevron-left",
    className: "fa-solid fa-circle-chevron-left",
    label: "Circle chevron left",
  },
  {
    icon: "circle-chevron-right",
    className: "fa-solid fa-circle-chevron-right",
    label: "Circle chevron right",
  },
  {
    icon: "circle-chevron-up",
    className: "fa-solid fa-circle-chevron-up",
    label: "Circle chevron up",
  },
  {
    icon: "circle-dollar-to-slot",
    className: "fa-solid fa-circle-dollar-to-slot",
    label: "Circle dollar to slot",
  },
  {
    icon: "circle-dot",
    className: "fa-solid fa-circle-dot",
    label: "Circle dot",
  },
  {
    icon: "circle-dot",
    className: "fa-regular fa-circle-dot",
    label: "Circle dot",
  },
  {
    icon: "circle-down",
    className: "fa-solid fa-circle-down",
    label: "Circle down",
  },
  {
    icon: "circle-down",
    className: "fa-regular fa-circle-down",
    label: "Circle down",
  },
  {
    icon: "circle-exclamation",
    className: "fa-solid fa-circle-exclamation",
    label: "Circle exclamation",
  },
  {
    icon: "circle-h",
    className: "fa-solid fa-circle-h",
    label: "Circle h",
  },
  {
    icon: "circle-half-stroke",
    className: "fa-solid fa-circle-half-stroke",
    label: "Circle half stroke",
  },
  {
    icon: "circle-info",
    className: "fa-solid fa-circle-info",
    label: "Circle info",
  },
  {
    icon: "circle-left",
    className: "fa-solid fa-circle-left",
    label: "Circle left",
  },
  {
    icon: "circle-left",
    className: "fa-regular fa-circle-left",
    label: "Circle left",
  },
  {
    icon: "circle-minus",
    className: "fa-solid fa-circle-minus",
    label: "Circle minus",
  },
  {
    icon: "circle-nodes",
    className: "fa-solid fa-circle-nodes",
    label: "Circle Nodes",
  },
  {
    icon: "circle-notch",
    className: "fa-solid fa-circle-notch",
    label: "Circle Notched",
  },
  {
    icon: "circle-pause",
    className: "fa-solid fa-circle-pause",
    label: "Circle pause",
  },
  {
    icon: "circle-pause",
    className: "fa-regular fa-circle-pause",
    label: "Circle pause",
  },
  {
    icon: "circle-play",
    className: "fa-solid fa-circle-play",
    label: "Circle play",
  },
  {
    icon: "circle-play",
    className: "fa-regular fa-circle-play",
    label: "Circle play",
  },
  {
    icon: "circle-plus",
    className: "fa-solid fa-circle-plus",
    label: "Circle plus",
  },
  {
    icon: "circle-question",
    className: "fa-solid fa-circle-question",
    label: "Circle question",
  },
  {
    icon: "circle-question",
    className: "fa-regular fa-circle-question",
    label: "Circle question",
  },
  {
    icon: "circle-radiation",
    className: "fa-solid fa-circle-radiation",
    label: "Circle radiation",
  },
  {
    icon: "circle-right",
    className: "fa-solid fa-circle-right",
    label: "Circle right",
  },
  {
    icon: "circle-right",
    className: "fa-regular fa-circle-right",
    label: "Circle right",
  },
  {
    icon: "circle-stop",
    className: "fa-solid fa-circle-stop",
    label: "Circle stop",
  },
  {
    icon: "circle-stop",
    className: "fa-regular fa-circle-stop",
    label: "Circle stop",
  },
  {
    icon: "circle-up",
    className: "fa-solid fa-circle-up",
    label: "Circle up",
  },
  {
    icon: "circle-up",
    className: "fa-regular fa-circle-up",
    label: "Circle up",
  },
  {
    icon: "circle-user",
    className: "fa-solid fa-circle-user",
    label: "Circle user",
  },
  {
    icon: "circle-user",
    className: "fa-regular fa-circle-user",
    label: "Circle user",
  },
  {
    icon: "circle-xmark",
    className: "fa-solid fa-circle-xmark",
    label: "Circle X Mark",
  },
  {
    icon: "circle-xmark",
    className: "fa-regular fa-circle-xmark",
    label: "Circle X Mark",
  },
  {
    icon: "city",
    className: "fa-solid fa-city",
    label: "City",
  },
  {
    icon: "clapperboard",
    className: "fa-solid fa-clapperboard",
    label: "Clapperboard",
  },
  {
    icon: "clipboard",
    className: "fa-solid fa-clipboard",
    label: "Clipboard",
  },
  {
    icon: "clipboard",
    className: "fa-regular fa-clipboard",
    label: "Clipboard",
  },
  {
    icon: "clipboard-check",
    className: "fa-solid fa-clipboard-check",
    label: "Clipboard with Check",
  },
  {
    icon: "clipboard-list",
    className: "fa-solid fa-clipboard-list",
    label: "Clipboard List",
  },
  {
    icon: "clipboard-question",
    className: "fa-solid fa-clipboard-question",
    label: "Clipboard Question",
  },
  {
    icon: "clipboard-user",
    className: "fa-solid fa-clipboard-user",
    label: "Clipboard with User",
  },
  {
    icon: "clock",
    className: "fa-solid fa-clock",
    label: "Clock",
  },
  {
    icon: "clock",
    className: "fa-regular fa-clock",
    label: "Clock",
  },
  {
    icon: "clock-rotate-left",
    className: "fa-solid fa-clock-rotate-left",
    label: "Clock Rotate Left",
  },
  {
    icon: "clone",
    className: "fa-solid fa-clone",
    label: "Clone",
  },
  {
    icon: "clone",
    className: "fa-regular fa-clone",
    label: "Clone",
  },
  {
    icon: "closed-captioning",
    className: "fa-solid fa-closed-captioning",
    label: "Closed Captioning",
  },
  {
    icon: "closed-captioning",
    className: "fa-regular fa-closed-captioning",
    label: "Closed Captioning",
  },
  {
    icon: "cloud",
    className: "fa-solid fa-cloud",
    label: "Cloud",
  },
  {
    icon: "cloud-arrow-down",
    className: "fa-solid fa-cloud-arrow-down",
    label: "Cloud arrow down",
  },
  {
    icon: "cloud-arrow-up",
    className: "fa-solid fa-cloud-arrow-up",
    label: "Cloud arrow up",
  },
  {
    icon: "cloud-bolt",
    className: "fa-solid fa-cloud-bolt",
    label: "Cloud bolt",
  },
  {
    icon: "cloud-meatball",
    className: "fa-solid fa-cloud-meatball",
    label: "Cloud with (a chance of) Meatball",
  },
  {
    icon: "cloud-moon",
    className: "fa-solid fa-cloud-moon",
    label: "Cloud with Moon",
  },
  {
    icon: "cloud-moon-rain",
    className: "fa-solid fa-cloud-moon-rain",
    label: "Cloud with Moon and Rain",
  },
  {
    icon: "cloud-rain",
    className: "fa-solid fa-cloud-rain",
    label: "Cloud with Rain",
  },
  {
    icon: "cloud-showers-heavy",
    className: "fa-solid fa-cloud-showers-heavy",
    label: "Cloud with Heavy Showers",
  },
  {
    icon: "cloud-showers-water",
    className: "fa-solid fa-cloud-showers-water",
    label: "Cloud Showers-water",
  },
  {
    icon: "cloud-sun",
    className: "fa-solid fa-cloud-sun",
    label: "Cloud with Sun",
  },
  {
    icon: "cloud-sun-rain",
    className: "fa-solid fa-cloud-sun-rain",
    label: "Cloud with Sun and Rain",
  },
  {
    icon: "cloudflare",
    className: "fa-brands fa-cloudflare",
    label: "Cloudflare",
  },
  {
    icon: "cloudscale",
    className: "fa-brands fa-cloudscale",
    label: "cloudscale.ch",
  },
  {
    icon: "cloudsmith",
    className: "fa-brands fa-cloudsmith",
    label: "Cloudsmith",
  },
  {
    icon: "cloudversify",
    className: "fa-brands fa-cloudversify",
    label: "cloudversify",
  },
  {
    icon: "clover",
    className: "fa-solid fa-clover",
    label: "Clover",
  },
  {
    icon: "cmplid",
    className: "fa-brands fa-cmplid",
    label: "Cmplid",
  },
  {
    icon: "code",
    className: "fa-solid fa-code",
    label: "Code",
  },
  {
    icon: "code-branch",
    className: "fa-solid fa-code-branch",
    label: "Code Branch",
  },
  {
    icon: "code-commit",
    className: "fa-solid fa-code-commit",
    label: "Code Commit",
  },
  {
    icon: "code-compare",
    className: "fa-solid fa-code-compare",
    label: "Code Compare",
  },
  {
    icon: "code-fork",
    className: "fa-solid fa-code-fork",
    label: "Code Fork",
  },
  {
    icon: "code-merge",
    className: "fa-solid fa-code-merge",
    label: "Code Merge",
  },
  {
    icon: "code-pull-request",
    className: "fa-solid fa-code-pull-request",
    label: "Code Pull Request",
  },
  {
    icon: "codepen",
    className: "fa-brands fa-codepen",
    label: "Codepen",
  },
  {
    icon: "codiepie",
    className: "fa-brands fa-codiepie",
    label: "Codie Pie",
  },
  {
    icon: "coins",
    className: "fa-solid fa-coins",
    label: "Coins",
  },
  {
    icon: "colon-sign",
    className: "fa-solid fa-colon-sign",
    label: "Colon Sign",
  },
  {
    icon: "comment",
    className: "fa-solid fa-comment",
    label: "comment",
  },
  {
    icon: "comment",
    className: "fa-regular fa-comment",
    label: "comment",
  },
  {
    icon: "comment-dollar",
    className: "fa-solid fa-comment-dollar",
    label: "Comment Dollar",
  },
  {
    icon: "comment-dots",
    className: "fa-solid fa-comment-dots",
    label: "Comment Dots",
  },
  {
    icon: "comment-dots",
    className: "fa-regular fa-comment-dots",
    label: "Comment Dots",
  },
  {
    icon: "comment-medical",
    className: "fa-solid fa-comment-medical",
    label: "Alternate Medical Chat",
  },
  {
    icon: "comment-slash",
    className: "fa-solid fa-comment-slash",
    label: "Comment Slash",
  },
  {
    icon: "comment-sms",
    className: "fa-solid fa-comment-sms",
    label: "Comment sms",
  },
  {
    icon: "comments",
    className: "fa-solid fa-comments",
    label: "comments",
  },
  {
    icon: "comments",
    className: "fa-regular fa-comments",
    label: "comments",
  },
  {
    icon: "comments-dollar",
    className: "fa-solid fa-comments-dollar",
    label: "Comments Dollar",
  },
  {
    icon: "compact-disc",
    className: "fa-solid fa-compact-disc",
    label: "Compact Disc",
  },
  {
    icon: "compass",
    className: "fa-solid fa-compass",
    label: "Compass",
  },
  {
    icon: "compass",
    className: "fa-regular fa-compass",
    label: "Compass",
  },
  {
    icon: "compass-drafting",
    className: "fa-solid fa-compass-drafting",
    label: "Compass drafting",
  },
  {
    icon: "compress",
    className: "fa-solid fa-compress",
    label: "Compress",
  },
  {
    icon: "computer",
    className: "fa-solid fa-computer",
    label: "Computer",
  },
  {
    icon: "computer-mouse",
    className: "fa-solid fa-computer-mouse",
    label: "Computer mouse",
  },
  {
    icon: "confluence",
    className: "fa-brands fa-confluence",
    label: "Confluence",
  },
  {
    icon: "connectdevelop",
    className: "fa-brands fa-connectdevelop",
    label: "Connect Develop",
  },
  {
    icon: "contao",
    className: "fa-brands fa-contao",
    label: "Contao",
  },
  {
    icon: "cookie",
    className: "fa-solid fa-cookie",
    label: "Cookie",
  },
  {
    icon: "cookie-bite",
    className: "fa-solid fa-cookie-bite",
    label: "Cookie Bite",
  },
  {
    icon: "copy",
    className: "fa-solid fa-copy",
    label: "Copy",
  },
  {
    icon: "copy",
    className: "fa-regular fa-copy",
    label: "Copy",
  },
  {
    icon: "copyright",
    className: "fa-solid fa-copyright",
    label: "Copyright",
  },
  {
    icon: "copyright",
    className: "fa-regular fa-copyright",
    label: "Copyright",
  },
  {
    icon: "cotton-bureau",
    className: "fa-brands fa-cotton-bureau",
    label: "Cotton Bureau",
  },
  {
    icon: "couch",
    className: "fa-solid fa-couch",
    label: "Couch",
  },
  {
    icon: "cow",
    className: "fa-solid fa-cow",
    label: "Cow",
  },
  {
    icon: "cpanel",
    className: "fa-brands fa-cpanel",
    label: "cPanel",
  },
  {
    icon: "creative-commons",
    className: "fa-brands fa-creative-commons",
    label: "Creative Commons",
  },
  {
    icon: "creative-commons-by",
    className: "fa-brands fa-creative-commons-by",
    label: "Creative Commons Attribution",
  },
  {
    icon: "creative-commons-nc",
    className: "fa-brands fa-creative-commons-nc",
    label: "Creative Commons Noncommercial",
  },
  {
    icon: "creative-commons-nc-eu",
    className: "fa-brands fa-creative-commons-nc-eu",
    label: "Creative Commons Noncommercial (Euro Sign)",
  },
  {
    icon: "creative-commons-nc-jp",
    className: "fa-brands fa-creative-commons-nc-jp",
    label: "Creative Commons Noncommercial (Yen Sign)",
  },
  {
    icon: "creative-commons-nd",
    className: "fa-brands fa-creative-commons-nd",
    label: "Creative Commons No Derivative Works",
  },
  {
    icon: "creative-commons-pd",
    className: "fa-brands fa-creative-commons-pd",
    label: "Creative Commons Public Domain",
  },
  {
    icon: "creative-commons-pd-alt",
    className: "fa-brands fa-creative-commons-pd-alt",
    label: "Alternate Creative Commons Public Domain",
  },
  {
    icon: "creative-commons-remix",
    className: "fa-brands fa-creative-commons-remix",
    label: "Creative Commons Remix",
  },
  {
    icon: "creative-commons-sa",
    className: "fa-brands fa-creative-commons-sa",
    label: "Creative Commons Share Alike",
  },
  {
    icon: "creative-commons-sampling",
    className: "fa-brands fa-creative-commons-sampling",
    label: "Creative Commons Sampling",
  },
  {
    icon: "creative-commons-sampling-plus",
    className: "fa-brands fa-creative-commons-sampling-plus",
    label: "Creative Commons Sampling +",
  },
  {
    icon: "creative-commons-share",
    className: "fa-brands fa-creative-commons-share",
    label: "Creative Commons Share",
  },
  {
    icon: "creative-commons-zero",
    className: "fa-brands fa-creative-commons-zero",
    label: "Creative Commons CC0",
  },
  {
    icon: "credit-card",
    className: "fa-solid fa-credit-card",
    label: "Credit Card",
  },
  {
    icon: "credit-card",
    className: "fa-regular fa-credit-card",
    label: "Credit Card",
  },
  {
    icon: "critical-role",
    className: "fa-brands fa-critical-role",
    label: "Critical Role",
  },
  {
    icon: "crop",
    className: "fa-solid fa-crop",
    label: "crop",
  },
  {
    icon: "crop-simple",
    className: "fa-solid fa-crop-simple",
    label: "Crop simple",
  },
  {
    icon: "cross",
    className: "fa-solid fa-cross",
    label: "Cross",
  },
  {
    icon: "crosshairs",
    className: "fa-solid fa-crosshairs",
    label: "Crosshairs",
  },
  {
    icon: "crow",
    className: "fa-solid fa-crow",
    label: "Crow",
  },
  {
    icon: "crown",
    className: "fa-solid fa-crown",
    label: "Crown",
  },
  {
    icon: "crutch",
    className: "fa-solid fa-crutch",
    label: "Crutch",
  },
  {
    icon: "cruzeiro-sign",
    className: "fa-solid fa-cruzeiro-sign",
    label: "Cruzeiro Sign",
  },
  {
    icon: "css3",
    className: "fa-brands fa-css3",
    label: "CSS 3 Logo",
  },
  {
    icon: "css3-alt",
    className: "fa-brands fa-css3-alt",
    label: "Alternate CSS3 Logo",
  },
  {
    icon: "cube",
    className: "fa-solid fa-cube",
    label: "Cube",
  },
  {
    icon: "cubes",
    className: "fa-solid fa-cubes",
    label: "Cubes",
  },
  {
    icon: "cubes-stacked",
    className: "fa-solid fa-cubes-stacked",
    label: "Cubes Stacked",
  },
  {
    icon: "cuttlefish",
    className: "fa-brands fa-cuttlefish",
    label: "Cuttlefish",
  },
  {
    icon: "d",
    className: "fa-solid fa-d",
    label: "D",
  },
  {
    icon: "d-and-d",
    className: "fa-brands fa-d-and-d",
    label: "Dungeons & Dragons",
  },
  {
    icon: "d-and-d-beyond",
    className: "fa-brands fa-d-and-d-beyond",
    label: "D&D Beyond",
  },
  {
    icon: "dailymotion",
    className: "fa-brands fa-dailymotion",
    label: "dailymotion",
  },
  {
    icon: "dashcube",
    className: "fa-brands fa-dashcube",
    label: "DashCube",
  },
  {
    icon: "database",
    className: "fa-solid fa-database",
    label: "Database",
  },
  {
    icon: "deezer",
    className: "fa-brands fa-deezer",
    label: "Deezer",
  },
  {
    icon: "delete-left",
    className: "fa-solid fa-delete-left",
    label: "Delete left",
  },
  {
    icon: "delicious",
    className: "fa-brands fa-delicious",
    label: "Delicious",
  },
  {
    icon: "democrat",
    className: "fa-solid fa-democrat",
    label: "Democrat",
  },
  {
    icon: "deploydog",
    className: "fa-brands fa-deploydog",
    label: "deploy.dog",
  },
  {
    icon: "deskpro",
    className: "fa-brands fa-deskpro",
    label: "Deskpro",
  },
  {
    icon: "desktop",
    className: "fa-solid fa-desktop",
    label: "Desktop",
  },
  {
    icon: "dev",
    className: "fa-brands fa-dev",
    label: "DEV",
  },
  {
    icon: "deviantart",
    className: "fa-brands fa-deviantart",
    label: "deviantART",
  },
  {
    icon: "dharmachakra",
    className: "fa-solid fa-dharmachakra",
    label: "Dharmachakra",
  },
  {
    icon: "dhl",
    className: "fa-brands fa-dhl",
    label: "DHL",
  },
  {
    icon: "diagram-next",
    className: "fa-solid fa-diagram-next",
    label: "Diagram Next",
  },
  {
    icon: "diagram-predecessor",
    className: "fa-solid fa-diagram-predecessor",
    label: "Diagram Predecessor",
  },
  {
    icon: "diagram-project",
    className: "fa-solid fa-diagram-project",
    label: "Project Diagram",
  },
  {
    icon: "diagram-successor",
    className: "fa-solid fa-diagram-successor",
    label: "Diagram Successor",
  },
  {
    icon: "diamond",
    className: "fa-solid fa-diamond",
    label: "Diamond",
  },
  {
    icon: "diamond-turn-right",
    className: "fa-solid fa-diamond-turn-right",
    label: "Diamond turn right",
  },
  {
    icon: "diaspora",
    className: "fa-brands fa-diaspora",
    label: "Diaspora",
  },
  {
    icon: "dice",
    className: "fa-solid fa-dice",
    label: "Dice",
  },
  {
    icon: "dice-d20",
    className: "fa-solid fa-dice-d20",
    label: "Dice D20",
  },
  {
    icon: "dice-d6",
    className: "fa-solid fa-dice-d6",
    label: "Dice D6",
  },
  {
    icon: "dice-five",
    className: "fa-solid fa-dice-five",
    label: "Dice Five",
  },
  {
    icon: "dice-four",
    className: "fa-solid fa-dice-four",
    label: "Dice Four",
  },
  {
    icon: "dice-one",
    className: "fa-solid fa-dice-one",
    label: "Dice One",
  },
  {
    icon: "dice-six",
    className: "fa-solid fa-dice-six",
    label: "Dice Six",
  },
  {
    icon: "dice-three",
    className: "fa-solid fa-dice-three",
    label: "Dice Three",
  },
  {
    icon: "dice-two",
    className: "fa-solid fa-dice-two",
    label: "Dice Two",
  },
  {
    icon: "digg",
    className: "fa-brands fa-digg",
    label: "Digg Logo",
  },
  {
    icon: "digital-ocean",
    className: "fa-brands fa-digital-ocean",
    label: "Digital Ocean",
  },
  {
    icon: "discord",
    className: "fa-brands fa-discord",
    label: "Discord",
  },
  {
    icon: "discourse",
    className: "fa-brands fa-discourse",
    label: "Discourse",
  },
  {
    icon: "disease",
    className: "fa-solid fa-disease",
    label: "Disease",
  },
  {
    icon: "display",
    className: "fa-solid fa-display",
    label: "Display",
  },
  {
    icon: "divide",
    className: "fa-solid fa-divide",
    label: "Divide",
  },
  {
    icon: "dna",
    className: "fa-solid fa-dna",
    label: "DNA",
  },
  {
    icon: "dochub",
    className: "fa-brands fa-dochub",
    label: "DocHub",
  },
  {
    icon: "docker",
    className: "fa-brands fa-docker",
    label: "Docker",
  },
  {
    icon: "dog",
    className: "fa-solid fa-dog",
    label: "Dog",
  },
  {
    icon: "dollar-sign",
    className: "fa-solid fa-dollar-sign",
    label: "Dollar Sign",
  },
  {
    icon: "dolly",
    className: "fa-solid fa-dolly",
    label: "Dolly",
  },
  {
    icon: "dong-sign",
    className: "fa-solid fa-dong-sign",
    label: "Dong Sign",
  },
  {
    icon: "door-closed",
    className: "fa-solid fa-door-closed",
    label: "Door Closed",
  },
  {
    icon: "door-open",
    className: "fa-solid fa-door-open",
    label: "Door Open",
  },
  {
    icon: "dove",
    className: "fa-solid fa-dove",
    label: "Dove",
  },
  {
    icon: "down-left-and-up-right-to-center",
    className: "fa-solid fa-down-left-and-up-right-to-center",
    label: "Down left and up right to center",
  },
  {
    icon: "down-long",
    className: "fa-solid fa-down-long",
    label: "Down long",
  },
  {
    icon: "download",
    className: "fa-solid fa-download",
    label: "Download",
  },
  {
    icon: "draft2digital",
    className: "fa-brands fa-draft2digital",
    label: "Draft2digital",
  },
  {
    icon: "dragon",
    className: "fa-solid fa-dragon",
    label: "Dragon",
  },
  {
    icon: "draw-polygon",
    className: "fa-solid fa-draw-polygon",
    label: "Draw Polygon",
  },
  {
    icon: "dribbble",
    className: "fa-brands fa-dribbble",
    label: "Dribbble",
  },
  {
    icon: "dribbble-square",
    className: "fa-brands fa-dribbble-square",
    label: "Dribbble Square",
  },
  {
    icon: "dropbox",
    className: "fa-brands fa-dropbox",
    label: "Dropbox",
  },
  {
    icon: "droplet",
    className: "fa-solid fa-droplet",
    label: "Droplet",
  },
  {
    icon: "droplet-slash",
    className: "fa-solid fa-droplet-slash",
    label: "Droplet slash",
  },
  {
    icon: "drum",
    className: "fa-solid fa-drum",
    label: "Drum",
  },
  {
    icon: "drum-steelpan",
    className: "fa-solid fa-drum-steelpan",
    label: "Drum Steelpan",
  },
  {
    icon: "drumstick-bite",
    className: "fa-solid fa-drumstick-bite",
    label: "Drumstick with Bite Taken Out",
  },
  {
    icon: "drupal",
    className: "fa-brands fa-drupal",
    label: "Drupal Logo",
  },
  {
    icon: "dumbbell",
    className: "fa-solid fa-dumbbell",
    label: "Dumbbell",
  },
  {
    icon: "dumpster",
    className: "fa-solid fa-dumpster",
    label: "Dumpster",
  },
  {
    icon: "dumpster-fire",
    className: "fa-solid fa-dumpster-fire",
    label: "Dumpster Fire",
  },
  {
    icon: "dungeon",
    className: "fa-solid fa-dungeon",
    label: "Dungeon",
  },
  {
    icon: "dyalog",
    className: "fa-brands fa-dyalog",
    label: "Dyalog",
  },
  {
    icon: "e",
    className: "fa-solid fa-e",
    label: "E",
  },
  {
    icon: "ear-deaf",
    className: "fa-solid fa-ear-deaf",
    label: "Ear deaf",
  },
  {
    icon: "ear-listen",
    className: "fa-solid fa-ear-listen",
    label: "Ear listen",
  },
  {
    icon: "earlybirds",
    className: "fa-brands fa-earlybirds",
    label: "Earlybirds",
  },
  {
    icon: "earth-africa",
    className: "fa-solid fa-earth-africa",
    label: "Earth Africa",
  },
  {
    icon: "earth-americas",
    className: "fa-solid fa-earth-americas",
    label: "Earth americas",
  },
  {
    icon: "earth-asia",
    className: "fa-solid fa-earth-asia",
    label: "Earth Asia",
  },
  {
    icon: "earth-europe",
    className: "fa-solid fa-earth-europe",
    label: "Earth Europe",
  },
  {
    icon: "earth-oceania",
    className: "fa-solid fa-earth-oceania",
    label: "Earth Oceania",
  },
  {
    icon: "ebay",
    className: "fa-brands fa-ebay",
    label: "eBay",
  },
  {
    icon: "edge",
    className: "fa-brands fa-edge",
    label: "Edge Browser",
  },
  {
    icon: "edge-legacy",
    className: "fa-brands fa-edge-legacy",
    label: "Edge Legacy Browser",
  },
  {
    icon: "egg",
    className: "fa-solid fa-egg",
    label: "Egg",
  },
  {
    icon: "eject",
    className: "fa-solid fa-eject",
    label: "eject",
  },
  {
    icon: "elementor",
    className: "fa-brands fa-elementor",
    label: "Elementor",
  },
  {
    icon: "elevator",
    className: "fa-solid fa-elevator",
    label: "Elevator",
  },
  {
    icon: "ellipsis",
    className: "fa-solid fa-ellipsis",
    label: "Ellipsis",
  },
  {
    icon: "ellipsis-vertical",
    className: "fa-solid fa-ellipsis-vertical",
    label: "Ellipsis vertical",
  },
  {
    icon: "ello",
    className: "fa-brands fa-ello",
    label: "Ello",
  },
  {
    icon: "ember",
    className: "fa-brands fa-ember",
    label: "Ember",
  },
  {
    icon: "empire",
    className: "fa-brands fa-empire",
    label: "Galactic Empire",
  },
  {
    icon: "envelope",
    className: "fa-solid fa-envelope",
    label: "Envelope",
  },
  {
    icon: "envelope",
    className: "fa-regular fa-envelope",
    label: "Envelope",
  },
  {
    icon: "envelope-circle-check",
    className: "fa-solid fa-envelope-circle-check",
    label: "Envelope Circle-check",
  },
  {
    icon: "envelope-open",
    className: "fa-solid fa-envelope-open",
    label: "Envelope Open",
  },
  {
    icon: "envelope-open",
    className: "fa-regular fa-envelope-open",
    label: "Envelope Open",
  },
  {
    icon: "envelope-open-text",
    className: "fa-solid fa-envelope-open-text",
    label: "Envelope Open-text",
  },
  {
    icon: "envelopes-bulk",
    className: "fa-solid fa-envelopes-bulk",
    label: "Envelopes bulk",
  },
  {
    icon: "envira",
    className: "fa-brands fa-envira",
    label: "Envira Gallery",
  },
  {
    icon: "equals",
    className: "fa-solid fa-equals",
    label: "Equals",
  },
  {
    icon: "eraser",
    className: "fa-solid fa-eraser",
    label: "eraser",
  },
  {
    icon: "erlang",
    className: "fa-brands fa-erlang",
    label: "Erlang",
  },
  {
    icon: "ethereum",
    className: "fa-brands fa-ethereum",
    label: "Ethereum",
  },
  {
    icon: "ethernet",
    className: "fa-solid fa-ethernet",
    label: "Ethernet",
  },
  {
    icon: "etsy",
    className: "fa-brands fa-etsy",
    label: "Etsy",
  },
  {
    icon: "euro-sign",
    className: "fa-solid fa-euro-sign",
    label: "Euro Sign",
  },
  {
    icon: "evernote",
    className: "fa-brands fa-evernote",
    label: "Evernote",
  },
  {
    icon: "exclamation",
    className: "fa-solid fa-exclamation",
    label: "exclamation",
  },
  {
    icon: "expand",
    className: "fa-solid fa-expand",
    label: "Expand",
  },
  {
    icon: "expeditedssl",
    className: "fa-brands fa-expeditedssl",
    label: "ExpeditedSSL",
  },
  {
    icon: "explosion",
    className: "fa-solid fa-explosion",
    label: "Explosion",
  },
  {
    icon: "eye",
    className: "fa-solid fa-eye",
    label: "Eye",
  },
  {
    icon: "eye",
    className: "fa-regular fa-eye",
    label: "Eye",
  },
  {
    icon: "eye-dropper",
    className: "fa-solid fa-eye-dropper",
    label: "Eye Dropper",
  },
  {
    icon: "eye-low-vision",
    className: "fa-solid fa-eye-low-vision",
    label: "Eye low vision",
  },
  {
    icon: "eye-slash",
    className: "fa-solid fa-eye-slash",
    label: "Eye Slash",
  },
  {
    icon: "eye-slash",
    className: "fa-regular fa-eye-slash",
    label: "Eye Slash",
  },
  {
    icon: "f",
    className: "fa-solid fa-f",
    label: "F",
  },
  {
    icon: "face-angry",
    className: "fa-solid fa-face-angry",
    label: "Face angry",
  },
  {
    icon: "face-angry",
    className: "fa-regular fa-face-angry",
    label: "Face angry",
  },
  {
    icon: "face-dizzy",
    className: "fa-solid fa-face-dizzy",
    label: "Face dizzy",
  },
  {
    icon: "face-dizzy",
    className: "fa-regular fa-face-dizzy",
    label: "Face dizzy",
  },
  {
    icon: "face-flushed",
    className: "fa-solid fa-face-flushed",
    label: "Face flushed",
  },
  {
    icon: "face-flushed",
    className: "fa-regular fa-face-flushed",
    label: "Face flushed",
  },
  {
    icon: "face-frown",
    className: "fa-solid fa-face-frown",
    label: "Face frown",
  },
  {
    icon: "face-frown",
    className: "fa-regular fa-face-frown",
    label: "Face frown",
  },
  {
    icon: "face-frown-open",
    className: "fa-solid fa-face-frown-open",
    label: "Face frown open",
  },
  {
    icon: "face-frown-open",
    className: "fa-regular fa-face-frown-open",
    label: "Face frown open",
  },
  {
    icon: "face-grimace",
    className: "fa-solid fa-face-grimace",
    label: "Face grimace",
  },
  {
    icon: "face-grimace",
    className: "fa-regular fa-face-grimace",
    label: "Face grimace",
  },
  {
    icon: "face-grin",
    className: "fa-solid fa-face-grin",
    label: "Face grin",
  },
  {
    icon: "face-grin",
    className: "fa-regular fa-face-grin",
    label: "Face grin",
  },
  {
    icon: "face-grin-beam",
    className: "fa-solid fa-face-grin-beam",
    label: "Face grin beam",
  },
  {
    icon: "face-grin-beam",
    className: "fa-regular fa-face-grin-beam",
    label: "Face grin beam",
  },
  {
    icon: "face-grin-beam-sweat",
    className: "fa-solid fa-face-grin-beam-sweat",
    label: "Face grin beam sweat",
  },
  {
    icon: "face-grin-beam-sweat",
    className: "fa-regular fa-face-grin-beam-sweat",
    label: "Face grin beam sweat",
  },
  {
    icon: "face-grin-hearts",
    className: "fa-solid fa-face-grin-hearts",
    label: "Face grin hearts",
  },
  {
    icon: "face-grin-hearts",
    className: "fa-regular fa-face-grin-hearts",
    label: "Face grin hearts",
  },
  {
    icon: "face-grin-squint",
    className: "fa-solid fa-face-grin-squint",
    label: "Face grin squint",
  },
  {
    icon: "face-grin-squint",
    className: "fa-regular fa-face-grin-squint",
    label: "Face grin squint",
  },
  {
    icon: "face-grin-squint-tears",
    className: "fa-solid fa-face-grin-squint-tears",
    label: "Face grin squint tears",
  },
  {
    icon: "face-grin-squint-tears",
    className: "fa-regular fa-face-grin-squint-tears",
    label: "Face grin squint tears",
  },
  {
    icon: "face-grin-stars",
    className: "fa-solid fa-face-grin-stars",
    label: "Face grin stars",
  },
  {
    icon: "face-grin-stars",
    className: "fa-regular fa-face-grin-stars",
    label: "Face grin stars",
  },
  {
    icon: "face-grin-tears",
    className: "fa-solid fa-face-grin-tears",
    label: "Face grin tears",
  },
  {
    icon: "face-grin-tears",
    className: "fa-regular fa-face-grin-tears",
    label: "Face grin tears",
  },
  {
    icon: "face-grin-tongue",
    className: "fa-solid fa-face-grin-tongue",
    label: "Face grin tongue",
  },
  {
    icon: "face-grin-tongue",
    className: "fa-regular fa-face-grin-tongue",
    label: "Face grin tongue",
  },
  {
    icon: "face-grin-tongue-squint",
    className: "fa-solid fa-face-grin-tongue-squint",
    label: "Face grin tongue squint",
  },
  {
    icon: "face-grin-tongue-squint",
    className: "fa-regular fa-face-grin-tongue-squint",
    label: "Face grin tongue squint",
  },
  {
    icon: "face-grin-tongue-wink",
    className: "fa-solid fa-face-grin-tongue-wink",
    label: "Face grin tongue wink",
  },
  {
    icon: "face-grin-tongue-wink",
    className: "fa-regular fa-face-grin-tongue-wink",
    label: "Face grin tongue wink",
  },
  {
    icon: "face-grin-wide",
    className: "fa-solid fa-face-grin-wide",
    label: "Face grin wide",
  },
  {
    icon: "face-grin-wide",
    className: "fa-regular fa-face-grin-wide",
    label: "Face grin wide",
  },
  {
    icon: "face-grin-wink",
    className: "fa-solid fa-face-grin-wink",
    label: "Face grin wink",
  },
  {
    icon: "face-grin-wink",
    className: "fa-regular fa-face-grin-wink",
    label: "Face grin wink",
  },
  {
    icon: "face-kiss",
    className: "fa-solid fa-face-kiss",
    label: "Face kiss",
  },
  {
    icon: "face-kiss",
    className: "fa-regular fa-face-kiss",
    label: "Face kiss",
  },
  {
    icon: "face-kiss-beam",
    className: "fa-solid fa-face-kiss-beam",
    label: "Face Kiss Beam",
  },
  {
    icon: "face-kiss-beam",
    className: "fa-regular fa-face-kiss-beam",
    label: "Face Kiss Beam",
  },
  {
    icon: "face-kiss-wink-heart",
    className: "fa-solid fa-face-kiss-wink-heart",
    label: "Face Kiss Wink Heart",
  },
  {
    icon: "face-kiss-wink-heart",
    className: "fa-regular fa-face-kiss-wink-heart",
    label: "Face Kiss Wink Heart",
  },
  {
    icon: "face-laugh",
    className: "fa-solid fa-face-laugh",
    label: "Face Laugh",
  },
  {
    icon: "face-laugh",
    className: "fa-regular fa-face-laugh",
    label: "Face Laugh",
  },
  {
    icon: "face-laugh-beam",
    className: "fa-solid fa-face-laugh-beam",
    label: "Face Laugh Beam",
  },
  {
    icon: "face-laugh-beam",
    className: "fa-regular fa-face-laugh-beam",
    label: "Face Laugh Beam",
  },
  {
    icon: "face-laugh-squint",
    className: "fa-solid fa-face-laugh-squint",
    label: "Face Laugh Squint",
  },
  {
    icon: "face-laugh-squint",
    className: "fa-regular fa-face-laugh-squint",
    label: "Face Laugh Squint",
  },
  {
    icon: "face-laugh-wink",
    className: "fa-solid fa-face-laugh-wink",
    label: "Face Laugh Wink",
  },
  {
    icon: "face-laugh-wink",
    className: "fa-regular fa-face-laugh-wink",
    label: "Face Laugh Wink",
  },
  {
    icon: "face-meh",
    className: "fa-solid fa-face-meh",
    label: "Face meh",
  },
  {
    icon: "face-meh",
    className: "fa-regular fa-face-meh",
    label: "Face meh",
  },
  {
    icon: "face-meh-blank",
    className: "fa-solid fa-face-meh-blank",
    label: "Face Meh Blank",
  },
  {
    icon: "face-meh-blank",
    className: "fa-regular fa-face-meh-blank",
    label: "Face Meh Blank",
  },
  {
    icon: "face-rolling-eyes",
    className: "fa-solid fa-face-rolling-eyes",
    label: "Face Rolling Eyes",
  },
  {
    icon: "face-rolling-eyes",
    className: "fa-regular fa-face-rolling-eyes",
    label: "Face Rolling Eyes",
  },
  {
    icon: "face-sad-cry",
    className: "fa-solid fa-face-sad-cry",
    label: "Face Sad Cry",
  },
  {
    icon: "face-sad-cry",
    className: "fa-regular fa-face-sad-cry",
    label: "Face Sad Cry",
  },
  {
    icon: "face-sad-tear",
    className: "fa-solid fa-face-sad-tear",
    label: "Face Sad Tear",
  },
  {
    icon: "face-sad-tear",
    className: "fa-regular fa-face-sad-tear",
    label: "Face Sad Tear",
  },
  {
    icon: "face-smile",
    className: "fa-solid fa-face-smile",
    label: "Face Smile",
  },
  {
    icon: "face-smile",
    className: "fa-regular fa-face-smile",
    label: "Face Smile",
  },
  {
    icon: "face-smile-beam",
    className: "fa-solid fa-face-smile-beam",
    label: "Face Smile Beam",
  },
  {
    icon: "face-smile-beam",
    className: "fa-regular fa-face-smile-beam",
    label: "Face Smile Beam",
  },
  {
    icon: "face-smile-wink",
    className: "fa-solid fa-face-smile-wink",
    label: "Face Smile Wink",
  },
  {
    icon: "face-smile-wink",
    className: "fa-regular fa-face-smile-wink",
    label: "Face Smile Wink",
  },
  {
    icon: "face-surprise",
    className: "fa-solid fa-face-surprise",
    label: "Face Surprise",
  },
  {
    icon: "face-surprise",
    className: "fa-regular fa-face-surprise",
    label: "Face Surprise",
  },
  {
    icon: "face-tired",
    className: "fa-solid fa-face-tired",
    label: "Face Tired",
  },
  {
    icon: "face-tired",
    className: "fa-regular fa-face-tired",
    label: "Face Tired",
  },
  {
    icon: "facebook",
    className: "fa-brands fa-facebook",
    label: "Facebook",
  },
  {
    icon: "facebook-f",
    className: "fa-brands fa-facebook-f",
    label: "Facebook F",
  },
  {
    icon: "facebook-messenger",
    className: "fa-brands fa-facebook-messenger",
    label: "Facebook Messenger",
  },
  {
    icon: "facebook-square",
    className: "fa-brands fa-facebook-square",
    label: "Facebook Square",
  },
  {
    icon: "fan",
    className: "fa-solid fa-fan",
    label: "Fan",
  },
  {
    icon: "fantasy-flight-games",
    className: "fa-brands fa-fantasy-flight-games",
    label: "Fantasy Flight-games",
  },
  {
    icon: "faucet",
    className: "fa-solid fa-faucet",
    label: "Faucet",
  },
  {
    icon: "faucet-drip",
    className: "fa-solid fa-faucet-drip",
    label: "Faucet Drip",
  },
  {
    icon: "fax",
    className: "fa-solid fa-fax",
    label: "Fax",
  },
  {
    icon: "feather",
    className: "fa-solid fa-feather",
    label: "Feather",
  },
  {
    icon: "feather-pointed",
    className: "fa-solid fa-feather-pointed",
    label: "Feather pointed",
  },
  {
    icon: "fedex",
    className: "fa-brands fa-fedex",
    label: "FedEx",
  },
  {
    icon: "fedora",
    className: "fa-brands fa-fedora",
    label: "Fedora",
  },
  {
    icon: "ferry",
    className: "fa-solid fa-ferry",
    label: "Ferry",
  },
  {
    icon: "figma",
    className: "fa-brands fa-figma",
    label: "Figma",
  },
  {
    icon: "file",
    className: "fa-solid fa-file",
    label: "File",
  },
  {
    icon: "file",
    className: "fa-regular fa-file",
    label: "File",
  },
  {
    icon: "file-arrow-down",
    className: "fa-solid fa-file-arrow-down",
    label: "File arrow down",
  },
  {
    icon: "file-arrow-up",
    className: "fa-solid fa-file-arrow-up",
    label: "File arrow up",
  },
  {
    icon: "file-audio",
    className: "fa-solid fa-file-audio",
    label: "Audio File",
  },
  {
    icon: "file-audio",
    className: "fa-regular fa-file-audio",
    label: "Audio File",
  },
  {
    icon: "file-circle-check",
    className: "fa-solid fa-file-circle-check",
    label: "File Circle-Check",
  },
  {
    icon: "file-circle-exclamation",
    className: "fa-solid fa-file-circle-exclamation",
    label: "File Circle-exclamation",
  },
  {
    icon: "file-circle-minus",
    className: "fa-solid fa-file-circle-minus",
    label: "File Circle-minus",
  },
  {
    icon: "file-circle-plus",
    className: "fa-solid fa-file-circle-plus",
    label: "File Circle-plus",
  },
  {
    icon: "file-circle-question",
    className: "fa-solid fa-file-circle-question",
    label: "File Circle-question",
  },
  {
    icon: "file-circle-xmark",
    className: "fa-solid fa-file-circle-xmark",
    label: "File Circle-xmark",
  },
  {
    icon: "file-code",
    className: "fa-solid fa-file-code",
    label: "Code File",
  },
  {
    icon: "file-code",
    className: "fa-regular fa-file-code",
    label: "Code File",
  },
  {
    icon: "file-contract",
    className: "fa-solid fa-file-contract",
    label: "File Contract",
  },
  {
    icon: "file-csv",
    className: "fa-solid fa-file-csv",
    label: "File CSV",
  },
  {
    icon: "file-excel",
    className: "fa-solid fa-file-excel",
    label: "Excel File",
  },
  {
    icon: "file-excel",
    className: "fa-regular fa-file-excel",
    label: "Excel File",
  },
  {
    icon: "file-export",
    className: "fa-solid fa-file-export",
    label: "File Export",
  },
  {
    icon: "file-image",
    className: "fa-solid fa-file-image",
    label: "Image File",
  },
  {
    icon: "file-image",
    className: "fa-regular fa-file-image",
    label: "Image File",
  },
  {
    icon: "file-import",
    className: "fa-solid fa-file-import",
    label: "File Import",
  },
  {
    icon: "file-invoice",
    className: "fa-solid fa-file-invoice",
    label: "File Invoice",
  },
  {
    icon: "file-invoice-dollar",
    className: "fa-solid fa-file-invoice-dollar",
    label: "File Invoice with US Dollar",
  },
  {
    icon: "file-lines",
    className: "fa-solid fa-file-lines",
    label: "File lines",
  },
  {
    icon: "file-lines",
    className: "fa-regular fa-file-lines",
    label: "File lines",
  },
  {
    icon: "file-medical",
    className: "fa-solid fa-file-medical",
    label: "Medical File",
  },
  {
    icon: "file-pdf",
    className: "fa-solid fa-file-pdf",
    label: "PDF File",
  },
  {
    icon: "file-pdf",
    className: "fa-regular fa-file-pdf",
    label: "PDF File",
  },
  {
    icon: "file-pen",
    className: "fa-solid fa-file-pen",
    label: "File pen",
  },
  {
    icon: "file-powerpoint",
    className: "fa-solid fa-file-powerpoint",
    label: "Powerpoint File",
  },
  {
    icon: "file-powerpoint",
    className: "fa-regular fa-file-powerpoint",
    label: "Powerpoint File",
  },
  {
    icon: "file-prescription",
    className: "fa-solid fa-file-prescription",
    label: "File Prescription",
  },
  {
    icon: "file-shield",
    className: "fa-solid fa-file-shield",
    label: "File Shield",
  },
  {
    icon: "file-signature",
    className: "fa-solid fa-file-signature",
    label: "File Signature",
  },
  {
    icon: "file-video",
    className: "fa-solid fa-file-video",
    label: "Video File",
  },
  {
    icon: "file-video",
    className: "fa-regular fa-file-video",
    label: "Video File",
  },
  {
    icon: "file-waveform",
    className: "fa-solid fa-file-waveform",
    label: "File waveform",
  },
  {
    icon: "file-word",
    className: "fa-solid fa-file-word",
    label: "Word File",
  },
  {
    icon: "file-word",
    className: "fa-regular fa-file-word",
    label: "Word File",
  },
  {
    icon: "file-zipper",
    className: "fa-solid fa-file-zipper",
    label: "File zipper",
  },
  {
    icon: "file-zipper",
    className: "fa-regular fa-file-zipper",
    label: "File zipper",
  },
  {
    icon: "fill",
    className: "fa-solid fa-fill",
    label: "Fill",
  },
  {
    icon: "fill-drip",
    className: "fa-solid fa-fill-drip",
    label: "Fill Drip",
  },
  {
    icon: "film",
    className: "fa-solid fa-film",
    label: "Film",
  },
  {
    icon: "filter",
    className: "fa-solid fa-filter",
    label: "Filter",
  },
  {
    icon: "filter-circle-dollar",
    className: "fa-solid fa-filter-circle-dollar",
    label: "Filter Circle Dollar",
  },
  {
    icon: "filter-circle-xmark",
    className: "fa-solid fa-filter-circle-xmark",
    label: "Filter Circle X Mark",
  },
  {
    icon: "fingerprint",
    className: "fa-solid fa-fingerprint",
    label: "Fingerprint",
  },
  {
    icon: "fire",
    className: "fa-solid fa-fire",
    label: "fire",
  },
  {
    icon: "fire-burner",
    className: "fa-solid fa-fire-burner",
    label: "Fire Burner",
  },
  {
    icon: "fire-extinguisher",
    className: "fa-solid fa-fire-extinguisher",
    label: "fire-extinguisher",
  },
  {
    icon: "fire-flame-curved",
    className: "fa-solid fa-fire-flame-curved",
    label: "Fire flame curved",
  },
  {
    icon: "fire-flame-simple",
    className: "fa-solid fa-fire-flame-simple",
    label: "Fire flame simple",
  },
  {
    icon: "firefox",
    className: "fa-brands fa-firefox",
    label: "Firefox",
  },
  {
    icon: "firefox-browser",
    className: "fa-brands fa-firefox-browser",
    label: "Firefox Browser",
  },
  {
    icon: "first-order",
    className: "fa-brands fa-first-order",
    label: "First Order",
  },
  {
    icon: "first-order-alt",
    className: "fa-brands fa-first-order-alt",
    label: "Alternate First Order",
  },
  {
    icon: "firstdraft",
    className: "fa-brands fa-firstdraft",
    label: "firstdraft",
  },
  {
    icon: "fish",
    className: "fa-solid fa-fish",
    label: "Fish",
  },
  {
    icon: "fish-fins",
    className: "fa-solid fa-fish-fins",
    label: "Fish Fins",
  },
  {
    icon: "flag",
    className: "fa-solid fa-flag",
    label: "flag",
  },
  {
    icon: "flag",
    className: "fa-regular fa-flag",
    label: "flag",
  },
  {
    icon: "flag-checkered",
    className: "fa-solid fa-flag-checkered",
    label: "flag-checkered",
  },
  {
    icon: "flag-usa",
    className: "fa-solid fa-flag-usa",
    label: "United States of America Flag",
  },
  {
    icon: "flask",
    className: "fa-solid fa-flask",
    label: "Flask",
  },
  {
    icon: "flask-vial",
    className: "fa-solid fa-flask-vial",
    label: "Flask and Vial",
  },
  {
    icon: "flickr",
    className: "fa-brands fa-flickr",
    label: "Flickr",
  },
  {
    icon: "flipboard",
    className: "fa-brands fa-flipboard",
    label: "Flipboard",
  },
  {
    icon: "floppy-disk",
    className: "fa-solid fa-floppy-disk",
    label: "Floppy Disk",
  },
  {
    icon: "floppy-disk",
    className: "fa-regular fa-floppy-disk",
    label: "Floppy Disk",
  },
  {
    icon: "florin-sign",
    className: "fa-solid fa-florin-sign",
    label: "Florin Sign",
  },
  {
    icon: "fly",
    className: "fa-brands fa-fly",
    label: "Fly",
  },
  {
    icon: "folder",
    className: "fa-solid fa-folder",
    label: "Folder",
  },
  {
    icon: "folder",
    className: "fa-regular fa-folder",
    label: "Folder",
  },
  {
    icon: "folder-closed",
    className: "fa-solid fa-folder-closed",
    label: "Folder Closed",
  },
  {
    icon: "folder-closed",
    className: "fa-regular fa-folder-closed",
    label: "Folder Closed",
  },
  {
    icon: "folder-minus",
    className: "fa-solid fa-folder-minus",
    label: "Folder Minus",
  },
  {
    icon: "folder-open",
    className: "fa-solid fa-folder-open",
    label: "Folder Open",
  },
  {
    icon: "folder-open",
    className: "fa-regular fa-folder-open",
    label: "Folder Open",
  },
  {
    icon: "folder-plus",
    className: "fa-solid fa-folder-plus",
    label: "Folder Plus",
  },
  {
    icon: "folder-tree",
    className: "fa-solid fa-folder-tree",
    label: "Folder Tree",
  },
  {
    icon: "font",
    className: "fa-solid fa-font",
    label: "font",
  },
  {
    icon: "font-awesome",
    className: "fa-brands fa-font-awesome",
    label: "Font Awesome",
  },
  {
    icon: "font-awesome",
    className: "fa-solid fa-font-awesome",
    label: "Font Awesome",
  },
  {
    icon: "font-awesome",
    className: "fa-regular fa-font-awesome",
    label: "Font Awesome",
  },
  {
    icon: "fonticons",
    className: "fa-brands fa-fonticons",
    label: "Fonticons",
  },
  {
    icon: "fonticons-fi",
    className: "fa-brands fa-fonticons-fi",
    label: "Fonticons Fi",
  },
  {
    icon: "football",
    className: "fa-solid fa-football",
    label: "Football Ball",
  },
  {
    icon: "fort-awesome",
    className: "fa-brands fa-fort-awesome",
    label: "Fort Awesome",
  },
  {
    icon: "fort-awesome-alt",
    className: "fa-brands fa-fort-awesome-alt",
    label: "Alternate Fort Awesome",
  },
  {
    icon: "forumbee",
    className: "fa-brands fa-forumbee",
    label: "Forumbee",
  },
  {
    icon: "forward",
    className: "fa-solid fa-forward",
    label: "forward",
  },
  {
    icon: "forward-fast",
    className: "fa-solid fa-forward-fast",
    label: "Forward fast",
  },
  {
    icon: "forward-step",
    className: "fa-solid fa-forward-step",
    label: "Forward step",
  },
  {
    icon: "foursquare",
    className: "fa-brands fa-foursquare",
    label: "Foursquare",
  },
  {
    icon: "franc-sign",
    className: "fa-solid fa-franc-sign",
    label: "Franc Sign",
  },
  {
    icon: "free-code-camp",
    className: "fa-brands fa-free-code-camp",
    label: "freeCodeCamp",
  },
  {
    icon: "freebsd",
    className: "fa-brands fa-freebsd",
    label: "FreeBSD",
  },
  {
    icon: "frog",
    className: "fa-solid fa-frog",
    label: "Frog",
  },
  {
    icon: "fulcrum",
    className: "fa-brands fa-fulcrum",
    label: "Fulcrum",
  },
  {
    icon: "futbol",
    className: "fa-solid fa-futbol",
    label: "Futbol ball",
  },
  {
    icon: "futbol",
    className: "fa-regular fa-futbol",
    label: "Futbol ball",
  },
  {
    icon: "g",
    className: "fa-solid fa-g",
    label: "G",
  },
  {
    icon: "galactic-republic",
    className: "fa-brands fa-galactic-republic",
    label: "Galactic Republic",
  },
  {
    icon: "galactic-senate",
    className: "fa-brands fa-galactic-senate",
    label: "Galactic Senate",
  },
  {
    icon: "gamepad",
    className: "fa-solid fa-gamepad",
    label: "Gamepad",
  },
  {
    icon: "gas-pump",
    className: "fa-solid fa-gas-pump",
    label: "Gas Pump",
  },
  {
    icon: "gauge",
    className: "fa-solid fa-gauge",
    label: "Gauge med",
  },
  {
    icon: "gauge-high",
    className: "fa-solid fa-gauge-high",
    label: "Gauge",
  },
  {
    icon: "gauge-simple",
    className: "fa-solid fa-gauge-simple",
    label: "Gauge simple med",
  },
  {
    icon: "gauge-simple-high",
    className: "fa-solid fa-gauge-simple-high",
    label: "Gauge simple",
  },
  {
    icon: "gavel",
    className: "fa-solid fa-gavel",
    label: "Gavel",
  },
  {
    icon: "gear",
    className: "fa-solid fa-gear",
    label: "Gear",
  },
  {
    icon: "gears",
    className: "fa-solid fa-gears",
    label: "Gears",
  },
  {
    icon: "gem",
    className: "fa-solid fa-gem",
    label: "Gem",
  },
  {
    icon: "gem",
    className: "fa-regular fa-gem",
    label: "Gem",
  },
  {
    icon: "genderless",
    className: "fa-solid fa-genderless",
    label: "Genderless",
  },
  {
    icon: "get-pocket",
    className: "fa-brands fa-get-pocket",
    label: "Get Pocket",
  },
  {
    icon: "gg",
    className: "fa-brands fa-gg",
    label: "GG Currency",
  },
  {
    icon: "gg-circle",
    className: "fa-brands fa-gg-circle",
    label: "GG Currency Circle",
  },
  {
    icon: "ghost",
    className: "fa-solid fa-ghost",
    label: "Ghost",
  },
  {
    icon: "gift",
    className: "fa-solid fa-gift",
    label: "gift",
  },
  {
    icon: "gifts",
    className: "fa-solid fa-gifts",
    label: "Gifts",
  },
  {
    icon: "git",
    className: "fa-brands fa-git",
    label: "Git",
  },
  {
    icon: "git-alt",
    className: "fa-brands fa-git-alt",
    label: "Git Alt",
  },
  {
    icon: "git-square",
    className: "fa-brands fa-git-square",
    label: "Git Square",
  },
  {
    icon: "github",
    className: "fa-brands fa-github",
    label: "GitHub",
  },
  {
    icon: "github-alt",
    className: "fa-brands fa-github-alt",
    label: "Alternate GitHub",
  },
  {
    icon: "github-square",
    className: "fa-brands fa-github-square",
    label: "GitHub Square",
  },
  {
    icon: "gitkraken",
    className: "fa-brands fa-gitkraken",
    label: "GitKraken",
  },
  {
    icon: "gitlab",
    className: "fa-brands fa-gitlab",
    label: "GitLab",
  },
  {
    icon: "gitter",
    className: "fa-brands fa-gitter",
    label: "Gitter",
  },
  {
    icon: "glass-water",
    className: "fa-solid fa-glass-water",
    label: "Glass Water",
  },
  {
    icon: "glass-water-droplet",
    className: "fa-solid fa-glass-water-droplet",
    label: "Glass Water-droplet",
  },
  {
    icon: "glasses",
    className: "fa-solid fa-glasses",
    label: "Glasses",
  },
  {
    icon: "glide",
    className: "fa-brands fa-glide",
    label: "Glide",
  },
  {
    icon: "glide-g",
    className: "fa-brands fa-glide-g",
    label: "Glide G",
  },
  {
    icon: "globe",
    className: "fa-solid fa-globe",
    label: "Globe",
  },
  {
    icon: "gofore",
    className: "fa-brands fa-gofore",
    label: "Gofore",
  },
  {
    icon: "golang",
    className: "fa-brands fa-golang",
    label: "Go",
  },
  {
    icon: "golf-ball-tee",
    className: "fa-solid fa-golf-ball-tee",
    label: "Golf ball tee",
  },
  {
    icon: "goodreads",
    className: "fa-brands fa-goodreads",
    label: "Goodreads",
  },
  {
    icon: "goodreads-g",
    className: "fa-brands fa-goodreads-g",
    label: "Goodreads G",
  },
  {
    icon: "google",
    className: "fa-brands fa-google",
    label: "Google Logo",
  },
  {
    icon: "google-drive",
    className: "fa-brands fa-google-drive",
    label: "Google Drive",
  },
  {
    icon: "google-pay",
    className: "fa-brands fa-google-pay",
    label: "Google Pay",
  },
  {
    icon: "google-play",
    className: "fa-brands fa-google-play",
    label: "Google Play",
  },
  {
    icon: "google-plus",
    className: "fa-brands fa-google-plus",
    label: "Google Plus",
  },
  {
    icon: "google-plus-g",
    className: "fa-brands fa-google-plus-g",
    label: "Google Plus G",
  },
  {
    icon: "google-plus-square",
    className: "fa-brands fa-google-plus-square",
    label: "Google Plus Square",
  },
  {
    icon: "google-wallet",
    className: "fa-brands fa-google-wallet",
    label: "Google Wallet",
  },
  {
    icon: "gopuram",
    className: "fa-solid fa-gopuram",
    label: "Gopuram",
  },
  {
    icon: "graduation-cap",
    className: "fa-solid fa-graduation-cap",
    label: "Graduation Cap",
  },
  {
    icon: "gratipay",
    className: "fa-brands fa-gratipay",
    label: "Gratipay (Gittip)",
  },
  {
    icon: "grav",
    className: "fa-brands fa-grav",
    label: "Grav",
  },
  {
    icon: "greater-than",
    className: "fa-solid fa-greater-than",
    label: "Greater Than",
  },
  {
    icon: "greater-than-equal",
    className: "fa-solid fa-greater-than-equal",
    label: "Greater Than Equal To",
  },
  {
    icon: "grip",
    className: "fa-solid fa-grip",
    label: "Grip",
  },
  {
    icon: "grip-lines",
    className: "fa-solid fa-grip-lines",
    label: "Grip Lines",
  },
  {
    icon: "grip-lines-vertical",
    className: "fa-solid fa-grip-lines-vertical",
    label: "Grip Lines Vertical",
  },
  {
    icon: "grip-vertical",
    className: "fa-solid fa-grip-vertical",
    label: "Grip Vertical",
  },
  {
    icon: "gripfire",
    className: "fa-brands fa-gripfire",
    label: "Gripfire, Inc.",
  },
  {
    icon: "group-arrows-rotate",
    className: "fa-solid fa-group-arrows-rotate",
    label: "Group Arrows-rotate",
  },
  {
    icon: "grunt",
    className: "fa-brands fa-grunt",
    label: "Grunt",
  },
  {
    icon: "guarani-sign",
    className: "fa-solid fa-guarani-sign",
    label: "Guarani Sign",
  },
  {
    icon: "guilded",
    className: "fa-brands fa-guilded",
    label: "Guilded",
  },
  {
    icon: "guitar",
    className: "fa-solid fa-guitar",
    label: "Guitar",
  },
  {
    icon: "gulp",
    className: "fa-brands fa-gulp",
    label: "Gulp",
  },
  {
    icon: "gun",
    className: "fa-solid fa-gun",
    label: "Gun",
  },
  {
    icon: "h",
    className: "fa-solid fa-h",
    label: "H",
  },
  {
    icon: "hacker-news",
    className: "fa-brands fa-hacker-news",
    label: "Hacker News",
  },
  {
    icon: "hacker-news-square",
    className: "fa-brands fa-hacker-news-square",
    label: "Hacker News Square",
  },
  {
    icon: "hackerrank",
    className: "fa-brands fa-hackerrank",
    label: "Hackerrank",
  },
  {
    icon: "hammer",
    className: "fa-solid fa-hammer",
    label: "Hammer",
  },
  {
    icon: "hamsa",
    className: "fa-solid fa-hamsa",
    label: "Hamsa",
  },
  {
    icon: "hand",
    className: "fa-solid fa-hand",
    label: "Paper (Hand)",
  },
  {
    icon: "hand",
    className: "fa-regular fa-hand",
    label: "Paper (Hand)",
  },
  {
    icon: "hand-back-fist",
    className: "fa-solid fa-hand-back-fist",
    label: "Rock (Hand)",
  },
  {
    icon: "hand-back-fist",
    className: "fa-regular fa-hand-back-fist",
    label: "Rock (Hand)",
  },
  {
    icon: "hand-dots",
    className: "fa-solid fa-hand-dots",
    label: "Hand dots",
  },
  {
    icon: "hand-fist",
    className: "fa-solid fa-hand-fist",
    label: "Raised Fist",
  },
  {
    icon: "hand-holding",
    className: "fa-solid fa-hand-holding",
    label: "Hand Holding",
  },
  {
    icon: "hand-holding-dollar",
    className: "fa-solid fa-hand-holding-dollar",
    label: "Hand holding dollar",
  },
  {
    icon: "hand-holding-droplet",
    className: "fa-solid fa-hand-holding-droplet",
    label: "Hand holding droplet",
  },
  {
    icon: "hand-holding-hand",
    className: "fa-solid fa-hand-holding-hand",
    label: "Hand Holding-hand",
  },
  {
    icon: "hand-holding-heart",
    className: "fa-solid fa-hand-holding-heart",
    label: "Hand Holding Heart",
  },
  {
    icon: "hand-holding-medical",
    className: "fa-solid fa-hand-holding-medical",
    label: "Hand Holding Medical Cross",
  },
  {
    icon: "hand-lizard",
    className: "fa-solid fa-hand-lizard",
    label: "Lizard (Hand)",
  },
  {
    icon: "hand-lizard",
    className: "fa-regular fa-hand-lizard",
    label: "Lizard (Hand)",
  },
  {
    icon: "hand-middle-finger",
    className: "fa-solid fa-hand-middle-finger",
    label: "Hand with Middle Finger Raised",
  },
  {
    icon: "hand-peace",
    className: "fa-solid fa-hand-peace",
    label: "Peace (Hand)",
  },
  {
    icon: "hand-peace",
    className: "fa-regular fa-hand-peace",
    label: "Peace (Hand)",
  },
  {
    icon: "hand-point-down",
    className: "fa-solid fa-hand-point-down",
    label: "Hand Pointing Down",
  },
  {
    icon: "hand-point-down",
    className: "fa-regular fa-hand-point-down",
    label: "Hand Pointing Down",
  },
  {
    icon: "hand-point-left",
    className: "fa-solid fa-hand-point-left",
    label: "Hand Pointing Left",
  },
  {
    icon: "hand-point-left",
    className: "fa-regular fa-hand-point-left",
    label: "Hand Pointing Left",
  },
  {
    icon: "hand-point-right",
    className: "fa-solid fa-hand-point-right",
    label: "Hand Pointing Right",
  },
  {
    icon: "hand-point-right",
    className: "fa-regular fa-hand-point-right",
    label: "Hand Pointing Right",
  },
  {
    icon: "hand-point-up",
    className: "fa-solid fa-hand-point-up",
    label: "Hand Pointing Up",
  },
  {
    icon: "hand-point-up",
    className: "fa-regular fa-hand-point-up",
    label: "Hand Pointing Up",
  },
  {
    icon: "hand-pointer",
    className: "fa-solid fa-hand-pointer",
    label: "Pointer (Hand)",
  },
  {
    icon: "hand-pointer",
    className: "fa-regular fa-hand-pointer",
    label: "Pointer (Hand)",
  },
  {
    icon: "hand-scissors",
    className: "fa-solid fa-hand-scissors",
    label: "Scissors (Hand)",
  },
  {
    icon: "hand-scissors",
    className: "fa-regular fa-hand-scissors",
    label: "Scissors (Hand)",
  },
  {
    icon: "hand-sparkles",
    className: "fa-solid fa-hand-sparkles",
    label: "Hand Sparkles",
  },
  {
    icon: "hand-spock",
    className: "fa-solid fa-hand-spock",
    label: "Spock (Hand)",
  },
  {
    icon: "hand-spock",
    className: "fa-regular fa-hand-spock",
    label: "Spock (Hand)",
  },
  {
    icon: "handcuffs",
    className: "fa-solid fa-handcuffs",
    label: "Handcuffs",
  },
  {
    icon: "hands",
    className: "fa-solid fa-hands",
    label: "Hands",
  },
  {
    icon: "hands-asl-interpreting",
    className: "fa-solid fa-hands-asl-interpreting",
    label: "Hands american sign language interpreting",
  },
  {
    icon: "hands-bound",
    className: "fa-solid fa-hands-bound",
    label: "Hands Bound",
  },
  {
    icon: "hands-bubbles",
    className: "fa-solid fa-hands-bubbles",
    label: "Hands bubbles",
  },
  {
    icon: "hands-clapping",
    className: "fa-solid fa-hands-clapping",
    label: "Hands Clapping",
  },
  {
    icon: "hands-holding",
    className: "fa-solid fa-hands-holding",
    label: "Hands holding",
  },
  {
    icon: "hands-holding-child",
    className: "fa-solid fa-hands-holding-child",
    label: "Hands Holding-child",
  },
  {
    icon: "hands-holding-circle",
    className: "fa-solid fa-hands-holding-circle",
    label: "Hands Holding-circle",
  },
  {
    icon: "hands-praying",
    className: "fa-solid fa-hands-praying",
    label: "Hands praying",
  },
  {
    icon: "handshake",
    className: "fa-solid fa-handshake",
    label: "Handshake",
  },
  {
    icon: "handshake",
    className: "fa-regular fa-handshake",
    label: "Handshake",
  },
  {
    icon: "handshake-angle",
    className: "fa-solid fa-handshake-angle",
    label: "Handshake angle",
  },
  {
    icon: "handshake-simple",
    className: "fa-solid fa-handshake-simple",
    label: "Handshake simple",
  },
  {
    icon: "handshake-simple-slash",
    className: "fa-solid fa-handshake-simple-slash",
    label: "Handshake simple slash",
  },
  {
    icon: "handshake-slash",
    className: "fa-solid fa-handshake-slash",
    label: "Handshake Slash",
  },
  {
    icon: "hanukiah",
    className: "fa-solid fa-hanukiah",
    label: "Hanukiah",
  },
  {
    icon: "hard-drive",
    className: "fa-solid fa-hard-drive",
    label: "Hard drive",
  },
  {
    icon: "hard-drive",
    className: "fa-regular fa-hard-drive",
    label: "Hard drive",
  },
  {
    icon: "hashnode",
    className: "fa-brands fa-hashnode",
    label: "Hashnode",
  },
  {
    icon: "hashtag",
    className: "fa-solid fa-hashtag",
    label: "Hashtag",
  },
  {
    icon: "hat-cowboy",
    className: "fa-solid fa-hat-cowboy",
    label: "Cowboy Hat",
  },
  {
    icon: "hat-cowboy-side",
    className: "fa-solid fa-hat-cowboy-side",
    label: "Cowboy Hat Side",
  },
  {
    icon: "hat-wizard",
    className: "fa-solid fa-hat-wizard",
    label: "Wizard's Hat",
  },
  {
    icon: "head-side-cough",
    className: "fa-solid fa-head-side-cough",
    label: "Head Side Cough",
  },
  {
    icon: "head-side-cough-slash",
    className: "fa-solid fa-head-side-cough-slash",
    label: "Head Side-cough-slash",
  },
  {
    icon: "head-side-mask",
    className: "fa-solid fa-head-side-mask",
    label: "Head Side Mask",
  },
  {
    icon: "head-side-virus",
    className: "fa-solid fa-head-side-virus",
    label: "Head Side Virus",
  },
  {
    icon: "heading",
    className: "fa-solid fa-heading",
    label: "heading",
  },
  {
    icon: "headphones",
    className: "fa-solid fa-headphones",
    label: "headphones",
  },
  {
    icon: "headphones-simple",
    className: "fa-solid fa-headphones-simple",
    label: "Headphones simple",
  },
  {
    icon: "headset",
    className: "fa-solid fa-headset",
    label: "Headset",
  },
  {
    icon: "heart",
    className: "fa-solid fa-heart",
    label: "Heart",
  },
  {
    icon: "heart",
    className: "fa-regular fa-heart",
    label: "Heart",
  },
  {
    icon: "heart-circle-bolt",
    className: "fa-solid fa-heart-circle-bolt",
    label: "Heart Circle-bolt",
  },
  {
    icon: "heart-circle-check",
    className: "fa-solid fa-heart-circle-check",
    label: "Heart Circle-check",
  },
  {
    icon: "heart-circle-exclamation",
    className: "fa-solid fa-heart-circle-exclamation",
    label: "Heart Circle-exclamation",
  },
  {
    icon: "heart-circle-minus",
    className: "fa-solid fa-heart-circle-minus",
    label: "Heart Circle-minus",
  },
  {
    icon: "heart-circle-plus",
    className: "fa-solid fa-heart-circle-plus",
    label: "Heart Circle-plus",
  },
  {
    icon: "heart-circle-xmark",
    className: "fa-solid fa-heart-circle-xmark",
    label: "Heart Circle-xmark",
  },
  {
    icon: "heart-crack",
    className: "fa-solid fa-heart-crack",
    label: "Heart crack",
  },
  {
    icon: "heart-pulse",
    className: "fa-solid fa-heart-pulse",
    label: "Heart pulse",
  },
  {
    icon: "helicopter",
    className: "fa-solid fa-helicopter",
    label: "Helicopter",
  },
  {
    icon: "helicopter-symbol",
    className: "fa-solid fa-helicopter-symbol",
    label: "Helicopter Symbol",
  },
  {
    icon: "helmet-safety",
    className: "fa-solid fa-helmet-safety",
    label: "Helmet safety",
  },
  {
    icon: "helmet-un",
    className: "fa-solid fa-helmet-un",
    label: "Helmet Un",
  },
  {
    icon: "highlighter",
    className: "fa-solid fa-highlighter",
    label: "Highlighter",
  },
  {
    icon: "hill-avalanche",
    className: "fa-solid fa-hill-avalanche",
    label: "Hill Avalanche",
  },
  {
    icon: "hill-rockslide",
    className: "fa-solid fa-hill-rockslide",
    label: "Hill Rockslide",
  },
  {
    icon: "hippo",
    className: "fa-solid fa-hippo",
    label: "Hippo",
  },
  {
    icon: "hips",
    className: "fa-brands fa-hips",
    label: "Hips",
  },
  {
    icon: "hire-a-helper",
    className: "fa-brands fa-hire-a-helper",
    label: "HireAHelper",
  },
  {
    icon: "hive",
    className: "fa-brands fa-hive",
    label: "Hive Blockchain Network",
  },
  {
    icon: "hockey-puck",
    className: "fa-solid fa-hockey-puck",
    label: "Hockey Puck",
  },
  {
    icon: "holly-berry",
    className: "fa-solid fa-holly-berry",
    label: "Holly Berry",
  },
  {
    icon: "hooli",
    className: "fa-brands fa-hooli",
    label: "Hooli",
  },
  {
    icon: "hornbill",
    className: "fa-brands fa-hornbill",
    label: "Hornbill",
  },
  {
    icon: "horse",
    className: "fa-solid fa-horse",
    label: "Horse",
  },
  {
    icon: "horse-head",
    className: "fa-solid fa-horse-head",
    label: "Horse Head",
  },
  {
    icon: "hospital",
    className: "fa-solid fa-hospital",
    label: "hospital",
  },
  {
    icon: "hospital",
    className: "fa-regular fa-hospital",
    label: "hospital",
  },
  {
    icon: "hospital-user",
    className: "fa-solid fa-hospital-user",
    label: "Hospital with User",
  },
  {
    icon: "hot-tub-person",
    className: "fa-solid fa-hot-tub-person",
    label: "Hot tub person",
  },
  {
    icon: "hotdog",
    className: "fa-solid fa-hotdog",
    label: "Hot Dog",
  },
  {
    icon: "hotel",
    className: "fa-solid fa-hotel",
    label: "Hotel",
  },
  {
    icon: "hotjar",
    className: "fa-brands fa-hotjar",
    label: "Hotjar",
  },
  {
    icon: "hourglass",
    className: "fa-solid fa-hourglass",
    label: "Hourglass",
  },
  {
    icon: "hourglass",
    className: "fa-regular fa-hourglass",
    label: "Hourglass",
  },
  {
    icon: "hourglass-empty",
    className: "fa-solid fa-hourglass-empty",
    label: "Hourglass empty",
  },
  {
    icon: "hourglass-end",
    className: "fa-solid fa-hourglass-end",
    label: "Hourglass End",
  },
  {
    icon: "hourglass-start",
    className: "fa-solid fa-hourglass-start",
    label: "Hourglass Start",
  },
  {
    icon: "house",
    className: "fa-solid fa-house",
    label: "House",
  },
  {
    icon: "house-chimney",
    className: "fa-solid fa-house-chimney",
    label: "House Chimney",
  },
  {
    icon: "house-chimney-crack",
    className: "fa-solid fa-house-chimney-crack",
    label: "House crack",
  },
  {
    icon: "house-chimney-medical",
    className: "fa-solid fa-house-chimney-medical",
    label: "House medical",
  },
  {
    icon: "house-chimney-user",
    className: "fa-solid fa-house-chimney-user",
    label: "House User",
  },
  {
    icon: "house-chimney-window",
    className: "fa-solid fa-house-chimney-window",
    label: "House with Window + Chimney",
  },
  {
    icon: "house-circle-check",
    className: "fa-solid fa-house-circle-check",
    label: "House Circle-check",
  },
  {
    icon: "house-circle-exclamation",
    className: "fa-solid fa-house-circle-exclamation",
    label: "House Circle-exclamation",
  },
  {
    icon: "house-circle-xmark",
    className: "fa-solid fa-house-circle-xmark",
    label: "House Circle-xmark",
  },
  {
    icon: "house-crack",
    className: "fa-solid fa-house-crack",
    label: "House Simple Crack",
  },
  {
    icon: "house-fire",
    className: "fa-solid fa-house-fire",
    label: "House Fire",
  },
  {
    icon: "house-flag",
    className: "fa-solid fa-house-flag",
    label: "House Flag",
  },
  {
    icon: "house-flood-water",
    className: "fa-solid fa-house-flood-water",
    label: "House Flood",
  },
  {
    icon: "house-flood-water-circle-arrow-right",
    className: "fa-solid fa-house-flood-water-circle-arrow-right",
    label: "House Flood-circle-arrow-right",
  },
  {
    icon: "house-laptop",
    className: "fa-solid fa-house-laptop",
    label: "House laptop",
  },
  {
    icon: "house-lock",
    className: "fa-solid fa-house-lock",
    label: "House Lock",
  },
  {
    icon: "house-medical",
    className: "fa-solid fa-house-medical",
    label: "House Simple Medical",
  },
  {
    icon: "house-medical-circle-check",
    className: "fa-solid fa-house-medical-circle-check",
    label: "House Medical-circle-check",
  },
  {
    icon: "house-medical-circle-exclamation",
    className: "fa-solid fa-house-medical-circle-exclamation",
    label: "House Medical-circle-exclamation",
  },
  {
    icon: "house-medical-circle-xmark",
    className: "fa-solid fa-house-medical-circle-xmark",
    label: "House Medical-circle-xmark",
  },
  {
    icon: "house-medical-flag",
    className: "fa-solid fa-house-medical-flag",
    label: "House Medical-flag",
  },
  {
    icon: "house-signal",
    className: "fa-solid fa-house-signal",
    label: "House Signal",
  },
  {
    icon: "house-tsunami",
    className: "fa-solid fa-house-tsunami",
    label: "House Tsunami",
  },
  {
    icon: "house-user",
    className: "fa-solid fa-house-user",
    label: "Home User",
  },
  {
    icon: "houzz",
    className: "fa-brands fa-houzz",
    label: "Houzz",
  },
  {
    icon: "hryvnia-sign",
    className: "fa-solid fa-hryvnia-sign",
    label: "Hryvnia sign",
  },
  {
    icon: "html5",
    className: "fa-brands fa-html5",
    label: "HTML 5 Logo",
  },
  {
    icon: "hubspot",
    className: "fa-brands fa-hubspot",
    label: "HubSpot",
  },
  {
    icon: "hurricane",
    className: "fa-solid fa-hurricane",
    label: "Hurricane",
  },
  {
    icon: "i",
    className: "fa-solid fa-i",
    label: "I",
  },
  {
    icon: "i-cursor",
    className: "fa-solid fa-i-cursor",
    label: "I Beam Cursor",
  },
  {
    icon: "ice-cream",
    className: "fa-solid fa-ice-cream",
    label: "Ice Cream",
  },
  {
    icon: "icicles",
    className: "fa-solid fa-icicles",
    label: "Icicles",
  },
  {
    icon: "icons",
    className: "fa-solid fa-icons",
    label: "Icons",
  },
  {
    icon: "id-badge",
    className: "fa-solid fa-id-badge",
    label: "Identification Badge",
  },
  {
    icon: "id-badge",
    className: "fa-regular fa-id-badge",
    label: "Identification Badge",
  },
  {
    icon: "id-card",
    className: "fa-solid fa-id-card",
    label: "Identification Card",
  },
  {
    icon: "id-card",
    className: "fa-regular fa-id-card",
    label: "Identification Card",
  },
  {
    icon: "id-card-clip",
    className: "fa-solid fa-id-card-clip",
    label: "Id card clip",
  },
  {
    icon: "ideal",
    className: "fa-brands fa-ideal",
    label: "iDeal",
  },
  {
    icon: "igloo",
    className: "fa-solid fa-igloo",
    label: "Igloo",
  },
  {
    icon: "image",
    className: "fa-solid fa-image",
    label: "Image",
  },
  {
    icon: "image",
    className: "fa-regular fa-image",
    label: "Image",
  },
  {
    icon: "image-portrait",
    className: "fa-solid fa-image-portrait",
    label: "Image portrait",
  },
  {
    icon: "images",
    className: "fa-solid fa-images",
    label: "Images",
  },
  {
    icon: "images",
    className: "fa-regular fa-images",
    label: "Images",
  },
  {
    icon: "imdb",
    className: "fa-brands fa-imdb",
    label: "IMDB",
  },
  {
    icon: "inbox",
    className: "fa-solid fa-inbox",
    label: "inbox",
  },
  {
    icon: "indent",
    className: "fa-solid fa-indent",
    label: "Indent",
  },
  {
    icon: "indian-rupee-sign",
    className: "fa-solid fa-indian-rupee-sign",
    label: "Indian Rupee-sign",
  },
  {
    icon: "industry",
    className: "fa-solid fa-industry",
    label: "Industry",
  },
  {
    icon: "infinity",
    className: "fa-solid fa-infinity",
    label: "Infinity",
  },
  {
    icon: "info",
    className: "fa-solid fa-info",
    label: "Info",
  },
  {
    icon: "instagram",
    className: "fa-brands fa-instagram",
    label: "Instagram",
  },
  {
    icon: "instagram-square",
    className: "fa-brands fa-instagram-square",
    label: "Instagram Square",
  },
  {
    icon: "instalod",
    className: "fa-brands fa-instalod",
    label: "InstaLOD",
  },
  {
    icon: "intercom",
    className: "fa-brands fa-intercom",
    label: "Intercom",
  },
  {
    icon: "internet-explorer",
    className: "fa-brands fa-internet-explorer",
    label: "Internet-explorer",
  },
  {
    icon: "invision",
    className: "fa-brands fa-invision",
    label: "InVision",
  },
  {
    icon: "ioxhost",
    className: "fa-brands fa-ioxhost",
    label: "ioxhost",
  },
  {
    icon: "italic",
    className: "fa-solid fa-italic",
    label: "italic",
  },
  {
    icon: "itch-io",
    className: "fa-brands fa-itch-io",
    label: "itch.io",
  },
  {
    icon: "itunes",
    className: "fa-brands fa-itunes",
    label: "iTunes",
  },
  {
    icon: "itunes-note",
    className: "fa-brands fa-itunes-note",
    label: "Itunes Note",
  },
  {
    icon: "j",
    className: "fa-solid fa-j",
    label: "J",
  },
  {
    icon: "jar",
    className: "fa-solid fa-jar",
    label: "Jar",
  },
  {
    icon: "jar-wheat",
    className: "fa-solid fa-jar-wheat",
    label: "Jar Wheat",
  },
  {
    icon: "java",
    className: "fa-brands fa-java",
    label: "Java",
  },
  {
    icon: "jedi",
    className: "fa-solid fa-jedi",
    label: "Jedi",
  },
  {
    icon: "jedi-order",
    className: "fa-brands fa-jedi-order",
    label: "Jedi Order",
  },
  {
    icon: "jenkins",
    className: "fa-brands fa-jenkins",
    label: "Jenkis",
  },
  {
    icon: "jet-fighter",
    className: "fa-solid fa-jet-fighter",
    label: "Jet fighter",
  },
  {
    icon: "jet-fighter-up",
    className: "fa-solid fa-jet-fighter-up",
    label: "Jet Fighter Up",
  },
  {
    icon: "jira",
    className: "fa-brands fa-jira",
    label: "Jira",
  },
  {
    icon: "joget",
    className: "fa-brands fa-joget",
    label: "Joget",
  },
  {
    icon: "joint",
    className: "fa-solid fa-joint",
    label: "Joint",
  },
  {
    icon: "joomla",
    className: "fa-brands fa-joomla",
    label: "Joomla Logo",
  },
  {
    icon: "js",
    className: "fa-brands fa-js",
    label: "JavaScript (JS)",
  },
  {
    icon: "js-square",
    className: "fa-brands fa-js-square",
    label: "JavaScript (JS) Square",
  },
  {
    icon: "jsfiddle",
    className: "fa-brands fa-jsfiddle",
    label: "jsFiddle",
  },
  {
    icon: "jug-detergent",
    className: "fa-solid fa-jug-detergent",
    label: "Jug Detergent",
  },
  {
    icon: "k",
    className: "fa-solid fa-k",
    label: "K",
  },
  {
    icon: "kaaba",
    className: "fa-solid fa-kaaba",
    label: "Kaaba",
  },
  {
    icon: "kaggle",
    className: "fa-brands fa-kaggle",
    label: "Kaggle",
  },
  {
    icon: "key",
    className: "fa-solid fa-key",
    label: "key",
  },
  {
    icon: "keybase",
    className: "fa-brands fa-keybase",
    label: "Keybase",
  },
  {
    icon: "keyboard",
    className: "fa-solid fa-keyboard",
    label: "Keyboard",
  },
  {
    icon: "keyboard",
    className: "fa-regular fa-keyboard",
    label: "Keyboard",
  },
  {
    icon: "keycdn",
    className: "fa-brands fa-keycdn",
    label: "KeyCDN",
  },
  {
    icon: "khanda",
    className: "fa-solid fa-khanda",
    label: "Khanda",
  },
  {
    icon: "kickstarter",
    className: "fa-brands fa-kickstarter",
    label: "Kickstarter",
  },
  {
    icon: "kickstarter-k",
    className: "fa-brands fa-kickstarter-k",
    label: "Kickstarter K",
  },
  {
    icon: "kip-sign",
    className: "fa-solid fa-kip-sign",
    label: "Kip Sign",
  },
  {
    icon: "kit-medical",
    className: "fa-solid fa-kit-medical",
    label: "Kit medical",
  },
  {
    icon: "kitchen-set",
    className: "fa-solid fa-kitchen-set",
    label: "Kitchen Set",
  },
  {
    icon: "kiwi-bird",
    className: "fa-solid fa-kiwi-bird",
    label: "Kiwi Bird",
  },
  {
    icon: "korvue",
    className: "fa-brands fa-korvue",
    label: "KORVUE",
  },
  {
    icon: "l",
    className: "fa-solid fa-l",
    label: "L",
  },
  {
    icon: "land-mine-on",
    className: "fa-solid fa-land-mine-on",
    label: "Land Mine-on",
  },
  {
    icon: "landmark",
    className: "fa-solid fa-landmark",
    label: "Landmark",
  },
  {
    icon: "landmark-dome",
    className: "fa-solid fa-landmark-dome",
    label: "Landmark dome",
  },
  {
    icon: "landmark-flag",
    className: "fa-solid fa-landmark-flag",
    label: "Landmark Flag",
  },
  {
    icon: "language",
    className: "fa-solid fa-language",
    label: "Language",
  },
  {
    icon: "laptop",
    className: "fa-solid fa-laptop",
    label: "Laptop",
  },
  {
    icon: "laptop-code",
    className: "fa-solid fa-laptop-code",
    label: "Laptop Code",
  },
  {
    icon: "laptop-file",
    className: "fa-solid fa-laptop-file",
    label: "Laptop File",
  },
  {
    icon: "laptop-medical",
    className: "fa-solid fa-laptop-medical",
    label: "Laptop Medical",
  },
  {
    icon: "laravel",
    className: "fa-brands fa-laravel",
    label: "Laravel",
  },
  {
    icon: "lari-sign",
    className: "fa-solid fa-lari-sign",
    label: "Lari Sign",
  },
  {
    icon: "lastfm",
    className: "fa-brands fa-lastfm",
    label: "last.fm",
  },
  {
    icon: "lastfm-square",
    className: "fa-brands fa-lastfm-square",
    label: "last.fm Square",
  },
  {
    icon: "layer-group",
    className: "fa-solid fa-layer-group",
    label: "Layer Group",
  },
  {
    icon: "leaf",
    className: "fa-solid fa-leaf",
    label: "leaf",
  },
  {
    icon: "leanpub",
    className: "fa-brands fa-leanpub",
    label: "Leanpub",
  },
  {
    icon: "left-long",
    className: "fa-solid fa-left-long",
    label: "Left long",
  },
  {
    icon: "left-right",
    className: "fa-solid fa-left-right",
    label: "Left right",
  },
  {
    icon: "lemon",
    className: "fa-solid fa-lemon",
    label: "Lemon",
  },
  {
    icon: "lemon",
    className: "fa-regular fa-lemon",
    label: "Lemon",
  },
  {
    icon: "less",
    className: "fa-brands fa-less",
    label: "Less",
  },
  {
    icon: "less-than",
    className: "fa-solid fa-less-than",
    label: "Less Than",
  },
  {
    icon: "less-than-equal",
    className: "fa-solid fa-less-than-equal",
    label: "Less Than Equal To",
  },
  {
    icon: "life-ring",
    className: "fa-solid fa-life-ring",
    label: "Life Ring",
  },
  {
    icon: "life-ring",
    className: "fa-regular fa-life-ring",
    label: "Life Ring",
  },
  {
    icon: "lightbulb",
    className: "fa-solid fa-lightbulb",
    label: "Lightbulb",
  },
  {
    icon: "lightbulb",
    className: "fa-regular fa-lightbulb",
    label: "Lightbulb",
  },
  {
    icon: "line",
    className: "fa-brands fa-line",
    label: "Line",
  },
  {
    icon: "lines-leaning",
    className: "fa-solid fa-lines-leaning",
    label: "Lines Leaning",
  },
  {
    icon: "link",
    className: "fa-solid fa-link",
    label: "Link",
  },
  {
    icon: "link-slash",
    className: "fa-solid fa-link-slash",
    label: "Link Slash",
  },
  {
    icon: "linkedin",
    className: "fa-brands fa-linkedin",
    label: "LinkedIn",
  },
  {
    icon: "linkedin-in",
    className: "fa-brands fa-linkedin-in",
    label: "LinkedIn In",
  },
  {
    icon: "linode",
    className: "fa-brands fa-linode",
    label: "Linode",
  },
  {
    icon: "linux",
    className: "fa-brands fa-linux",
    label: "Linux",
  },
  {
    icon: "lira-sign",
    className: "fa-solid fa-lira-sign",
    label: "Turkish Lira Sign",
  },
  {
    icon: "list",
    className: "fa-solid fa-list",
    label: "List",
  },
  {
    icon: "list-check",
    className: "fa-solid fa-list-check",
    label: "List check",
  },
  {
    icon: "list-ol",
    className: "fa-solid fa-list-ol",
    label: "list-ol",
  },
  {
    icon: "list-ul",
    className: "fa-solid fa-list-ul",
    label: "list-ul",
  },
  {
    icon: "litecoin-sign",
    className: "fa-solid fa-litecoin-sign",
    label: "Litecoin Sign",
  },
  {
    icon: "location-arrow",
    className: "fa-solid fa-location-arrow",
    label: "location-arrow",
  },
  {
    icon: "location-crosshairs",
    className: "fa-solid fa-location-crosshairs",
    label: "Location Crosshairs",
  },
  {
    icon: "location-dot",
    className: "fa-solid fa-location-dot",
    label: "Location dot",
  },
  {
    icon: "location-pin",
    className: "fa-solid fa-location-pin",
    label: "Location",
  },
  {
    icon: "location-pin-lock",
    className: "fa-solid fa-location-pin-lock",
    label: "Location Pin-lock",
  },
  {
    icon: "lock",
    className: "fa-solid fa-lock",
    label: "lock",
  },
  {
    icon: "lock-open",
    className: "fa-solid fa-lock-open",
    label: "Lock Open",
  },
  {
    icon: "locust",
    className: "fa-solid fa-locust",
    label: "Locust",
  },
  {
    icon: "lungs",
    className: "fa-solid fa-lungs",
    label: "Lungs",
  },
  {
    icon: "lungs-virus",
    className: "fa-solid fa-lungs-virus",
    label: "Lungs Virus",
  },
  {
    icon: "lyft",
    className: "fa-brands fa-lyft",
    label: "lyft",
  },
  {
    icon: "m",
    className: "fa-solid fa-m",
    label: "M",
  },
  {
    icon: "magento",
    className: "fa-brands fa-magento",
    label: "Magento",
  },
  {
    icon: "magnet",
    className: "fa-solid fa-magnet",
    label: "magnet",
  },
  {
    icon: "magnifying-glass",
    className: "fa-solid fa-magnifying-glass",
    label: "Magnifying glass",
  },
  {
    icon: "magnifying-glass-arrow-right",
    className: "fa-solid fa-magnifying-glass-arrow-right",
    label: "Magnifying Glass-arrow-right",
  },
  {
    icon: "magnifying-glass-chart",
    className: "fa-solid fa-magnifying-glass-chart",
    label: "Magnifying Glass-chart",
  },
  {
    icon: "magnifying-glass-dollar",
    className: "fa-solid fa-magnifying-glass-dollar",
    label: "Magnifying glass dollar",
  },
  {
    icon: "magnifying-glass-location",
    className: "fa-solid fa-magnifying-glass-location",
    label: "Magnifying glass location",
  },
  {
    icon: "magnifying-glass-minus",
    className: "fa-solid fa-magnifying-glass-minus",
    label: "Magnifying glass minus",
  },
  {
    icon: "magnifying-glass-plus",
    className: "fa-solid fa-magnifying-glass-plus",
    label: "Magnifying glass plus",
  },
  {
    icon: "mailchimp",
    className: "fa-brands fa-mailchimp",
    label: "Mailchimp",
  },
  {
    icon: "manat-sign",
    className: "fa-solid fa-manat-sign",
    label: "Manat Sign",
  },
  {
    icon: "mandalorian",
    className: "fa-brands fa-mandalorian",
    label: "Mandalorian",
  },
  {
    icon: "map",
    className: "fa-solid fa-map",
    label: "Map",
  },
  {
    icon: "map",
    className: "fa-regular fa-map",
    label: "Map",
  },
  {
    icon: "map-location",
    className: "fa-solid fa-map-location",
    label: "Map location",
  },
  {
    icon: "map-location-dot",
    className: "fa-solid fa-map-location-dot",
    label: "Map location dot",
  },
  {
    icon: "map-pin",
    className: "fa-solid fa-map-pin",
    label: "Map Pin",
  },
  {
    icon: "markdown",
    className: "fa-brands fa-markdown",
    label: "Markdown",
  },
  {
    icon: "marker",
    className: "fa-solid fa-marker",
    label: "Marker",
  },
  {
    icon: "mars",
    className: "fa-solid fa-mars",
    label: "Mars",
  },
  {
    icon: "mars-and-venus",
    className: "fa-solid fa-mars-and-venus",
    label: "Mars and Venus",
  },
  {
    icon: "mars-and-venus-burst",
    className: "fa-solid fa-mars-and-venus-burst",
    label: "Mars and Venus Burst",
  },
  {
    icon: "mars-double",
    className: "fa-solid fa-mars-double",
    label: "Mars Double",
  },
  {
    icon: "mars-stroke",
    className: "fa-solid fa-mars-stroke",
    label: "Mars Stroke",
  },
  {
    icon: "mars-stroke-right",
    className: "fa-solid fa-mars-stroke-right",
    label: "Mars stroke right",
  },
  {
    icon: "mars-stroke-up",
    className: "fa-solid fa-mars-stroke-up",
    label: "Mars stroke up",
  },
  {
    icon: "martini-glass",
    className: "fa-solid fa-martini-glass",
    label: "Martini glass",
  },
  {
    icon: "martini-glass-citrus",
    className: "fa-solid fa-martini-glass-citrus",
    label: "Martini glass citrus",
  },
  {
    icon: "martini-glass-empty",
    className: "fa-solid fa-martini-glass-empty",
    label: "Martini glass empty",
  },
  {
    icon: "mask",
    className: "fa-solid fa-mask",
    label: "Mask",
  },
  {
    icon: "mask-face",
    className: "fa-solid fa-mask-face",
    label: "Face Mask",
  },
  {
    icon: "mask-ventilator",
    className: "fa-solid fa-mask-ventilator",
    label: "Mask Ventilator",
  },
  {
    icon: "masks-theater",
    className: "fa-solid fa-masks-theater",
    label: "Masks theater",
  },
  {
    icon: "mastodon",
    className: "fa-brands fa-mastodon",
    label: "Mastodon",
  },
  {
    icon: "mattress-pillow",
    className: "fa-solid fa-mattress-pillow",
    label: "Mattress Pillow",
  },
  {
    icon: "maxcdn",
    className: "fa-brands fa-maxcdn",
    label: "MaxCDN",
  },
  {
    icon: "maximize",
    className: "fa-solid fa-maximize",
    label: "Maximize",
  },
  {
    icon: "mdb",
    className: "fa-brands fa-mdb",
    label: "Material Design for Bootstrap",
  },
  {
    icon: "medal",
    className: "fa-solid fa-medal",
    label: "Medal",
  },
  {
    icon: "medapps",
    className: "fa-brands fa-medapps",
    label: "MedApps",
  },
  {
    icon: "medium",
    className: "fa-brands fa-medium",
    label: "Medium",
  },
  {
    icon: "medrt",
    className: "fa-brands fa-medrt",
    label: "MRT",
  },
  {
    icon: "meetup",
    className: "fa-brands fa-meetup",
    label: "Meetup",
  },
  {
    icon: "megaport",
    className: "fa-brands fa-megaport",
    label: "Megaport",
  },
  {
    icon: "memory",
    className: "fa-solid fa-memory",
    label: "Memory",
  },
  {
    icon: "mendeley",
    className: "fa-brands fa-mendeley",
    label: "Mendeley",
  },
  {
    icon: "menorah",
    className: "fa-solid fa-menorah",
    label: "Menorah",
  },
  {
    icon: "mercury",
    className: "fa-solid fa-mercury",
    label: "Mercury",
  },
  {
    icon: "message",
    className: "fa-solid fa-message",
    label: "Message",
  },
  {
    icon: "message",
    className: "fa-regular fa-message",
    label: "Message",
  },
  {
    icon: "meteor",
    className: "fa-solid fa-meteor",
    label: "Meteor",
  },
  {
    icon: "microblog",
    className: "fa-brands fa-microblog",
    label: "Micro.blog",
  },
  {
    icon: "microchip",
    className: "fa-solid fa-microchip",
    label: "Microchip",
  },
  {
    icon: "microphone",
    className: "fa-solid fa-microphone",
    label: "microphone",
  },
  {
    icon: "microphone-lines",
    className: "fa-solid fa-microphone-lines",
    label: "Microphone lines",
  },
  {
    icon: "microphone-lines-slash",
    className: "fa-solid fa-microphone-lines-slash",
    label: "Microphone lines slash",
  },
  {
    icon: "microphone-slash",
    className: "fa-solid fa-microphone-slash",
    label: "Microphone Slash",
  },
  {
    icon: "microscope",
    className: "fa-solid fa-microscope",
    label: "Microscope",
  },
  {
    icon: "microsoft",
    className: "fa-brands fa-microsoft",
    label: "Microsoft",
  },
  {
    icon: "mill-sign",
    className: "fa-solid fa-mill-sign",
    label: "Mill Sign",
  },
  {
    icon: "minimize",
    className: "fa-solid fa-minimize",
    label: "Minimize",
  },
  {
    icon: "minus",
    className: "fa-solid fa-minus",
    label: "minus",
  },
  {
    icon: "mitten",
    className: "fa-solid fa-mitten",
    label: "Mitten",
  },
  {
    icon: "mix",
    className: "fa-brands fa-mix",
    label: "Mix",
  },
  {
    icon: "mixcloud",
    className: "fa-brands fa-mixcloud",
    label: "Mixcloud",
  },
  {
    icon: "mixer",
    className: "fa-brands fa-mixer",
    label: "Mixer",
  },
  {
    icon: "mizuni",
    className: "fa-brands fa-mizuni",
    label: "Mizuni",
  },
  {
    icon: "mobile",
    className: "fa-solid fa-mobile",
    label: "Mobile",
  },
  {
    icon: "mobile-button",
    className: "fa-solid fa-mobile-button",
    label: "Mobile button",
  },
  {
    icon: "mobile-retro",
    className: "fa-solid fa-mobile-retro",
    label: "Mobile Retro",
  },
  {
    icon: "mobile-screen",
    className: "fa-solid fa-mobile-screen",
    label: "Mobile screen",
  },
  {
    icon: "mobile-screen-button",
    className: "fa-solid fa-mobile-screen-button",
    label: "Mobile screen button",
  },
  {
    icon: "modx",
    className: "fa-brands fa-modx",
    label: "MODX",
  },
  {
    icon: "monero",
    className: "fa-brands fa-monero",
    label: "Monero",
  },
  {
    icon: "money-bill",
    className: "fa-solid fa-money-bill",
    label: "Money Bill",
  },
  {
    icon: "money-bill-1",
    className: "fa-solid fa-money-bill-1",
    label: "Money bill 1",
  },
  {
    icon: "money-bill-1",
    className: "fa-regular fa-money-bill-1",
    label: "Money bill 1",
  },
  {
    icon: "money-bill-1-wave",
    className: "fa-solid fa-money-bill-1-wave",
    label: "Money bill 1 wave",
  },
  {
    icon: "money-bill-transfer",
    className: "fa-solid fa-money-bill-transfer",
    label: "Money Bill-transfer",
  },
  {
    icon: "money-bill-trend-up",
    className: "fa-solid fa-money-bill-trend-up",
    label: "Money Bill-trend-up",
  },
  {
    icon: "money-bill-wave",
    className: "fa-solid fa-money-bill-wave",
    label: "Wavy Money Bill",
  },
  {
    icon: "money-bill-wheat",
    className: "fa-solid fa-money-bill-wheat",
    label: "Money Bill-wheat",
  },
  {
    icon: "money-bills",
    className: "fa-solid fa-money-bills",
    label: "Money Bills",
  },
  {
    icon: "money-check",
    className: "fa-solid fa-money-check",
    label: "Money Check",
  },
  {
    icon: "money-check-dollar",
    className: "fa-solid fa-money-check-dollar",
    label: "Money check dollar",
  },
  {
    icon: "monument",
    className: "fa-solid fa-monument",
    label: "Monument",
  },
  {
    icon: "moon",
    className: "fa-solid fa-moon",
    label: "Moon",
  },
  {
    icon: "moon",
    className: "fa-regular fa-moon",
    label: "Moon",
  },
  {
    icon: "mortar-pestle",
    className: "fa-solid fa-mortar-pestle",
    label: "Mortar Pestle",
  },
  {
    icon: "mosque",
    className: "fa-solid fa-mosque",
    label: "Mosque",
  },
  {
    icon: "mosquito",
    className: "fa-solid fa-mosquito",
    label: "Mosquito",
  },
  {
    icon: "mosquito-net",
    className: "fa-solid fa-mosquito-net",
    label: "Mosquito Net",
  },
  {
    icon: "motorcycle",
    className: "fa-solid fa-motorcycle",
    label: "Motorcycle",
  },
  {
    icon: "mound",
    className: "fa-solid fa-mound",
    label: "Mound",
  },
  {
    icon: "mountain",
    className: "fa-solid fa-mountain",
    label: "Mountain",
  },
  {
    icon: "mountain-city",
    className: "fa-solid fa-mountain-city",
    label: "Mountain City",
  },
  {
    icon: "mountain-sun",
    className: "fa-solid fa-mountain-sun",
    label: "Mountain Sun",
  },
  {
    icon: "mug-hot",
    className: "fa-solid fa-mug-hot",
    label: "Mug Hot",
  },
  {
    icon: "mug-saucer",
    className: "fa-solid fa-mug-saucer",
    label: "Mug saucer",
  },
  {
    icon: "music",
    className: "fa-solid fa-music",
    label: "Music",
  },
  {
    icon: "n",
    className: "fa-solid fa-n",
    label: "N",
  },
  {
    icon: "naira-sign",
    className: "fa-solid fa-naira-sign",
    label: "Naira Sign",
  },
  {
    icon: "napster",
    className: "fa-brands fa-napster",
    label: "Napster",
  },
  {
    icon: "neos",
    className: "fa-brands fa-neos",
    label: "Neos",
  },
  {
    icon: "network-wired",
    className: "fa-solid fa-network-wired",
    label: "Wired Network",
  },
  {
    icon: "neuter",
    className: "fa-solid fa-neuter",
    label: "Neuter",
  },
  {
    icon: "newspaper",
    className: "fa-solid fa-newspaper",
    label: "Newspaper",
  },
  {
    icon: "newspaper",
    className: "fa-regular fa-newspaper",
    label: "Newspaper",
  },
  {
    icon: "nfc-directional",
    className: "fa-brands fa-nfc-directional",
    label: "NFC Directional",
  },
  {
    icon: "nfc-symbol",
    className: "fa-brands fa-nfc-symbol",
    label: "NFC Simplified",
  },
  {
    icon: "nimblr",
    className: "fa-brands fa-nimblr",
    label: "Nimblr",
  },
  {
    icon: "node",
    className: "fa-brands fa-node",
    label: "Node.js",
  },
  {
    icon: "node-js",
    className: "fa-brands fa-node-js",
    label: "Node.js JS",
  },
  {
    icon: "not-equal",
    className: "fa-solid fa-not-equal",
    label: "Not Equal",
  },
  {
    icon: "note-sticky",
    className: "fa-solid fa-note-sticky",
    label: "Note sticky",
  },
  {
    icon: "note-sticky",
    className: "fa-regular fa-note-sticky",
    label: "Note sticky",
  },
  {
    icon: "notes-medical",
    className: "fa-solid fa-notes-medical",
    label: "Medical Notes",
  },
  {
    icon: "npm",
    className: "fa-brands fa-npm",
    label: "npm",
  },
  {
    icon: "ns8",
    className: "fa-brands fa-ns8",
    label: "NS8",
  },
  {
    icon: "nutritionix",
    className: "fa-brands fa-nutritionix",
    label: "Nutritionix",
  },
  {
    icon: "o",
    className: "fa-solid fa-o",
    label: "O",
  },
  {
    icon: "object-group",
    className: "fa-solid fa-object-group",
    label: "Object Group",
  },
  {
    icon: "object-group",
    className: "fa-regular fa-object-group",
    label: "Object Group",
  },
  {
    icon: "object-ungroup",
    className: "fa-solid fa-object-ungroup",
    label: "Object Ungroup",
  },
  {
    icon: "object-ungroup",
    className: "fa-regular fa-object-ungroup",
    label: "Object Ungroup",
  },
  {
    icon: "octopus-deploy",
    className: "fa-brands fa-octopus-deploy",
    label: "Octopus Deploy",
  },
  {
    icon: "odnoklassniki",
    className: "fa-brands fa-odnoklassniki",
    label: "Odnoklassniki",
  },
  {
    icon: "odnoklassniki-square",
    className: "fa-brands fa-odnoklassniki-square",
    label: "Odnoklassniki Square",
  },
  {
    icon: "oil-can",
    className: "fa-solid fa-oil-can",
    label: "Oil Can",
  },
  {
    icon: "oil-well",
    className: "fa-solid fa-oil-well",
    label: "Oil Well",
  },
  {
    icon: "old-republic",
    className: "fa-brands fa-old-republic",
    label: "Old Republic",
  },
  {
    icon: "om",
    className: "fa-solid fa-om",
    label: "Om",
  },
  {
    icon: "opencart",
    className: "fa-brands fa-opencart",
    label: "OpenCart",
  },
  {
    icon: "openid",
    className: "fa-brands fa-openid",
    label: "OpenID",
  },
  {
    icon: "opera",
    className: "fa-brands fa-opera",
    label: "Opera",
  },
  {
    icon: "optin-monster",
    className: "fa-brands fa-optin-monster",
    label: "Optin Monster",
  },
  {
    icon: "orcid",
    className: "fa-brands fa-orcid",
    label: "ORCID",
  },
  {
    icon: "osi",
    className: "fa-brands fa-osi",
    label: "Open Source Initiative",
  },
  {
    icon: "otter",
    className: "fa-solid fa-otter",
    label: "Otter",
  },
  {
    icon: "outdent",
    className: "fa-solid fa-outdent",
    label: "Outdent",
  },
  {
    icon: "p",
    className: "fa-solid fa-p",
    label: "P",
  },
  {
    icon: "padlet",
    className: "fa-brands fa-padlet",
    label: "Padlet",
  },
  {
    icon: "page4",
    className: "fa-brands fa-page4",
    label: "page4 Corporation",
  },
  {
    icon: "pagelines",
    className: "fa-brands fa-pagelines",
    label: "Pagelines",
  },
  {
    icon: "pager",
    className: "fa-solid fa-pager",
    label: "Pager",
  },
  {
    icon: "paint-roller",
    className: "fa-solid fa-paint-roller",
    label: "Paint Roller",
  },
  {
    icon: "paintbrush",
    className: "fa-solid fa-paintbrush",
    label: "Paint Brush",
  },
  {
    icon: "palette",
    className: "fa-solid fa-palette",
    label: "Palette",
  },
  {
    icon: "palfed",
    className: "fa-brands fa-palfed",
    label: "Palfed",
  },
  {
    icon: "pallet",
    className: "fa-solid fa-pallet",
    label: "Pallet",
  },
  {
    icon: "panorama",
    className: "fa-solid fa-panorama",
    label: "Panorama",
  },
  {
    icon: "paper-plane",
    className: "fa-solid fa-paper-plane",
    label: "Paper Plane",
  },
  {
    icon: "paper-plane",
    className: "fa-regular fa-paper-plane",
    label: "Paper Plane",
  },
  {
    icon: "paperclip",
    className: "fa-solid fa-paperclip",
    label: "Paperclip",
  },
  {
    icon: "parachute-box",
    className: "fa-solid fa-parachute-box",
    label: "Parachute Box",
  },
  {
    icon: "paragraph",
    className: "fa-solid fa-paragraph",
    label: "paragraph",
  },
  {
    icon: "passport",
    className: "fa-solid fa-passport",
    label: "Passport",
  },
  {
    icon: "paste",
    className: "fa-solid fa-paste",
    label: "Paste",
  },
  {
    icon: "paste",
    className: "fa-regular fa-paste",
    label: "Paste",
  },
  {
    icon: "patreon",
    className: "fa-brands fa-patreon",
    label: "Patreon",
  },
  {
    icon: "pause",
    className: "fa-solid fa-pause",
    label: "pause",
  },
  {
    icon: "paw",
    className: "fa-solid fa-paw",
    label: "Paw",
  },
  {
    icon: "paypal",
    className: "fa-brands fa-paypal",
    label: "Paypal",
  },
  {
    icon: "peace",
    className: "fa-solid fa-peace",
    label: "Peace",
  },
  {
    icon: "pen",
    className: "fa-solid fa-pen",
    label: "Pen",
  },
  {
    icon: "pen-clip",
    className: "fa-solid fa-pen-clip",
    label: "Pen clip",
  },
  {
    icon: "pen-fancy",
    className: "fa-solid fa-pen-fancy",
    label: "Pen Fancy",
  },
  {
    icon: "pen-nib",
    className: "fa-solid fa-pen-nib",
    label: "Pen Nib",
  },
  {
    icon: "pen-ruler",
    className: "fa-solid fa-pen-ruler",
    label: "Pen ruler",
  },
  {
    icon: "pen-to-square",
    className: "fa-solid fa-pen-to-square",
    label: "Pen to square",
  },
  {
    icon: "pen-to-square",
    className: "fa-regular fa-pen-to-square",
    label: "Pen to square",
  },
  {
    icon: "pencil",
    className: "fa-solid fa-pencil",
    label: "pencil",
  },
  {
    icon: "people-arrows-left-right",
    className: "fa-solid fa-people-arrows-left-right",
    label: "People arrows left right",
  },
  {
    icon: "people-carry-box",
    className: "fa-solid fa-people-carry-box",
    label: "People carry box",
  },
  {
    icon: "people-group",
    className: "fa-solid fa-people-group",
    label: "People Group",
  },
  {
    icon: "people-line",
    className: "fa-solid fa-people-line",
    label: "People Line",
  },
  {
    icon: "people-pulling",
    className: "fa-solid fa-people-pulling",
    label: "People Pulling",
  },
  {
    icon: "people-robbery",
    className: "fa-solid fa-people-robbery",
    label: "People Robbery",
  },
  {
    icon: "people-roof",
    className: "fa-solid fa-people-roof",
    label: "People Roof",
  },
  {
    icon: "pepper-hot",
    className: "fa-solid fa-pepper-hot",
    label: "Hot Pepper",
  },
  {
    icon: "perbyte",
    className: "fa-brands fa-perbyte",
    label: "PerByte",
  },
  {
    icon: "percent",
    className: "fa-solid fa-percent",
    label: "Percent",
  },
  {
    icon: "periscope",
    className: "fa-brands fa-periscope",
    label: "Periscope",
  },
  {
    icon: "person",
    className: "fa-solid fa-person",
    label: "Person",
  },
  {
    icon: "person-arrow-down-to-line",
    className: "fa-solid fa-person-arrow-down-to-line",
    label: "Person Arrow-down-to-line",
  },
  {
    icon: "person-arrow-up-from-line",
    className: "fa-solid fa-person-arrow-up-from-line",
    label: "Person Arrow-up-from-line",
  },
  {
    icon: "person-biking",
    className: "fa-solid fa-person-biking",
    label: "Person biking",
  },
  {
    icon: "person-booth",
    className: "fa-solid fa-person-booth",
    label: "Person Entering Booth",
  },
  {
    icon: "person-breastfeeding",
    className: "fa-solid fa-person-breastfeeding",
    label: "Person Breastfeeding",
  },
  {
    icon: "person-burst",
    className: "fa-solid fa-person-burst",
    label: "Person Burst",
  },
  {
    icon: "person-cane",
    className: "fa-solid fa-person-cane",
    label: "Person Cane",
  },
  {
    icon: "person-chalkboard",
    className: "fa-solid fa-person-chalkboard",
    label: "Person Chalkboard",
  },
  {
    icon: "person-circle-check",
    className: "fa-solid fa-person-circle-check",
    label: "Person Circle-check",
  },
  {
    icon: "person-circle-exclamation",
    className: "fa-solid fa-person-circle-exclamation",
    label: "Person Circle-exclamation",
  },
  {
    icon: "person-circle-minus",
    className: "fa-solid fa-person-circle-minus",
    label: "Person Circle-minus",
  },
  {
    icon: "person-circle-plus",
    className: "fa-solid fa-person-circle-plus",
    label: "Person Circle-plus",
  },
  {
    icon: "person-circle-question",
    className: "fa-solid fa-person-circle-question",
    label: "Person Circle-question",
  },
  {
    icon: "person-circle-xmark",
    className: "fa-solid fa-person-circle-xmark",
    label: "Person Circle-xmark",
  },
  {
    icon: "person-digging",
    className: "fa-solid fa-person-digging",
    label: "Person digging",
  },
  {
    icon: "person-dots-from-line",
    className: "fa-solid fa-person-dots-from-line",
    label: "Person dots from line",
  },
  {
    icon: "person-dress",
    className: "fa-solid fa-person-dress",
    label: "Person dress",
  },
  {
    icon: "person-dress-burst",
    className: "fa-solid fa-person-dress-burst",
    label: "Person Dress BUrst",
  },
  {
    icon: "person-drowning",
    className: "fa-solid fa-person-drowning",
    label: "Person Drowning",
  },
  {
    icon: "person-falling",
    className: "fa-solid fa-person-falling",
    label: "Person Falling",
  },
  {
    icon: "person-falling-burst",
    className: "fa-solid fa-person-falling-burst",
    label: "Person Falling Burst",
  },
  {
    icon: "person-half-dress",
    className: "fa-solid fa-person-half-dress",
    label: "Person Half-dress",
  },
  {
    icon: "person-harassing",
    className: "fa-solid fa-person-harassing",
    label: "Person Harassing",
  },
  {
    icon: "person-hiking",
    className: "fa-solid fa-person-hiking",
    label: "Person hiking",
  },
  {
    icon: "person-military-pointing",
    className: "fa-solid fa-person-military-pointing",
    label: "Person Military-pointing",
  },
  {
    icon: "person-military-rifle",
    className: "fa-solid fa-person-military-rifle",
    label: "Person Military-rifle",
  },
  {
    icon: "person-military-to-person",
    className: "fa-solid fa-person-military-to-person",
    label: "Person Military-to-person",
  },
  {
    icon: "person-praying",
    className: "fa-solid fa-person-praying",
    label: "Person praying",
  },
  {
    icon: "person-pregnant",
    className: "fa-solid fa-person-pregnant",
    label: "Person Pregnant",
  },
  {
    icon: "person-rays",
    className: "fa-solid fa-person-rays",
    label: "Person Rays",
  },
  {
    icon: "person-rifle",
    className: "fa-solid fa-person-rifle",
    label: "Person Rifle",
  },
  {
    icon: "person-running",
    className: "fa-solid fa-person-running",
    label: "Person running",
  },
  {
    icon: "person-shelter",
    className: "fa-solid fa-person-shelter",
    label: "Person Shelter",
  },
  {
    icon: "person-skating",
    className: "fa-solid fa-person-skating",
    label: "Person skating",
  },
  {
    icon: "person-skiing",
    className: "fa-solid fa-person-skiing",
    label: "Person skiing",
  },
  {
    icon: "person-skiing-nordic",
    className: "fa-solid fa-person-skiing-nordic",
    label: "Person skiing nordic",
  },
  {
    icon: "person-snowboarding",
    className: "fa-solid fa-person-snowboarding",
    label: "Person snowboarding",
  },
  {
    icon: "person-swimming",
    className: "fa-solid fa-person-swimming",
    label: "Person swimming",
  },
  {
    icon: "person-through-window",
    className: "fa-solid fa-person-through-window",
    label: "Person Through-window",
  },
  {
    icon: "person-walking",
    className: "fa-solid fa-person-walking",
    label: "Person walking",
  },
  {
    icon: "person-walking-arrow-loop-left",
    className: "fa-solid fa-person-walking-arrow-loop-left",
    label: "Person Walking-arrow-loop-left",
  },
  {
    icon: "person-walking-arrow-right",
    className: "fa-solid fa-person-walking-arrow-right",
    label: "Person Walking-arrow-right",
  },
  {
    icon: "person-walking-dashed-line-arrow-right",
    className: "fa-solid fa-person-walking-dashed-line-arrow-right",
    label: "Person Walking-dashed-line-arrow-right",
  },
  {
    icon: "person-walking-luggage",
    className: "fa-solid fa-person-walking-luggage",
    label: "Person Walking-luggage",
  },
  {
    icon: "person-walking-with-cane",
    className: "fa-solid fa-person-walking-with-cane",
    label: "Person walking with cane",
  },
  {
    icon: "peseta-sign",
    className: "fa-solid fa-peseta-sign",
    label: "Peseta Sign",
  },
  {
    icon: "peso-sign",
    className: "fa-solid fa-peso-sign",
    label: "Peso Sign",
  },
  {
    icon: "phabricator",
    className: "fa-brands fa-phabricator",
    label: "Phabricator",
  },
  {
    icon: "phoenix-framework",
    className: "fa-brands fa-phoenix-framework",
    label: "Phoenix Framework",
  },
  {
    icon: "phoenix-squadron",
    className: "fa-brands fa-phoenix-squadron",
    label: "Phoenix Squadron",
  },
  {
    icon: "phone",
    className: "fa-solid fa-phone",
    label: "Phone",
  },
  {
    icon: "phone-flip",
    className: "fa-solid fa-phone-flip",
    label: "Phone flip",
  },
  {
    icon: "phone-slash",
    className: "fa-solid fa-phone-slash",
    label: "Phone Slash",
  },
  {
    icon: "phone-volume",
    className: "fa-solid fa-phone-volume",
    label: "Phone Volume",
  },
  {
    icon: "photo-film",
    className: "fa-solid fa-photo-film",
    label: "Photo film",
  },
  {
    icon: "php",
    className: "fa-brands fa-php",
    label: "PHP",
  },
  {
    icon: "pied-piper",
    className: "fa-brands fa-pied-piper",
    label: "Pied Piper Logo",
  },
  {
    icon: "pied-piper-alt",
    className: "fa-brands fa-pied-piper-alt",
    label: "Alternate Pied Piper Logo (Old)",
  },
  {
    icon: "pied-piper-hat",
    className: "fa-brands fa-pied-piper-hat",
    label: "Pied Piper Hat (Old)",
  },
  {
    icon: "pied-piper-pp",
    className: "fa-brands fa-pied-piper-pp",
    label: "Pied Piper PP Logo (Old)",
  },
  {
    icon: "pied-piper-square",
    className: "fa-brands fa-pied-piper-square",
    label: "Pied Piper Square Logo (Old)",
  },
  {
    icon: "piggy-bank",
    className: "fa-solid fa-piggy-bank",
    label: "Piggy Bank",
  },
  {
    icon: "pills",
    className: "fa-solid fa-pills",
    label: "Pills",
  },
  {
    icon: "pinterest",
    className: "fa-brands fa-pinterest",
    label: "Pinterest",
  },
  {
    icon: "pinterest-p",
    className: "fa-brands fa-pinterest-p",
    label: "Pinterest P",
  },
  {
    icon: "pinterest-square",
    className: "fa-brands fa-pinterest-square",
    label: "Pinterest Square",
  },
  {
    icon: "pix",
    className: "fa-brands fa-pix",
    label: "Pix",
  },
  {
    icon: "pizza-slice",
    className: "fa-solid fa-pizza-slice",
    label: "Pizza Slice",
  },
  {
    icon: "place-of-worship",
    className: "fa-solid fa-place-of-worship",
    label: "Place of Worship",
  },
  {
    icon: "plane",
    className: "fa-solid fa-plane",
    label: "plane",
  },
  {
    icon: "plane-arrival",
    className: "fa-solid fa-plane-arrival",
    label: "Plane Arrival",
  },
  {
    icon: "plane-circle-check",
    className: "fa-solid fa-plane-circle-check",
    label: "Plane Circle-check",
  },
  {
    icon: "plane-circle-exclamation",
    className: "fa-solid fa-plane-circle-exclamation",
    label: "Plane Circle-exclamation",
  },
  {
    icon: "plane-circle-xmark",
    className: "fa-solid fa-plane-circle-xmark",
    label: "Plane Circle-xmark",
  },
  {
    icon: "plane-departure",
    className: "fa-solid fa-plane-departure",
    label: "Plane Departure",
  },
  {
    icon: "plane-lock",
    className: "fa-solid fa-plane-lock",
    label: "Plane Lock",
  },
  {
    icon: "plane-slash",
    className: "fa-solid fa-plane-slash",
    label: "Plane Slash",
  },
  {
    icon: "plane-up",
    className: "fa-solid fa-plane-up",
    label: "Plane Up",
  },
  {
    icon: "plant-wilt",
    className: "fa-solid fa-plant-wilt",
    label: "Plant Wilt",
  },
  {
    icon: "plate-wheat",
    className: "fa-solid fa-plate-wheat",
    label: "Plate Wheat",
  },
  {
    icon: "play",
    className: "fa-solid fa-play",
    label: "play",
  },
  {
    icon: "playstation",
    className: "fa-brands fa-playstation",
    label: "PlayStation",
  },
  {
    icon: "plug",
    className: "fa-solid fa-plug",
    label: "Plug",
  },
  {
    icon: "plug-circle-bolt",
    className: "fa-solid fa-plug-circle-bolt",
    label: "Plug Circle-bolt",
  },
  {
    icon: "plug-circle-check",
    className: "fa-solid fa-plug-circle-check",
    label: "Plug Circle-check",
  },
  {
    icon: "plug-circle-exclamation",
    className: "fa-solid fa-plug-circle-exclamation",
    label: "Plug Circle-exclamation",
  },
  {
    icon: "plug-circle-minus",
    className: "fa-solid fa-plug-circle-minus",
    label: "Plug Circle-minus",
  },
  {
    icon: "plug-circle-plus",
    className: "fa-solid fa-plug-circle-plus",
    label: "Plug Circle-plus",
  },
  {
    icon: "plug-circle-xmark",
    className: "fa-solid fa-plug-circle-xmark",
    label: "Plug Circle-xmark",
  },
  {
    icon: "plus",
    className: "fa-solid fa-plus",
    label: "plus",
  },
  {
    icon: "plus-minus",
    className: "fa-solid fa-plus-minus",
    label: "Plus Minus",
  },
  {
    icon: "podcast",
    className: "fa-solid fa-podcast",
    label: "Podcast",
  },
  {
    icon: "poo",
    className: "fa-solid fa-poo",
    label: "Poo",
  },
  {
    icon: "poo-storm",
    className: "fa-solid fa-poo-storm",
    label: "Poo bolt",
  },
  {
    icon: "poop",
    className: "fa-solid fa-poop",
    label: "Poop",
  },
  {
    icon: "power-off",
    className: "fa-solid fa-power-off",
    label: "Power Off",
  },
  {
    icon: "prescription",
    className: "fa-solid fa-prescription",
    label: "Prescription",
  },
  {
    icon: "prescription-bottle",
    className: "fa-solid fa-prescription-bottle",
    label: "Prescription Bottle",
  },
  {
    icon: "prescription-bottle-medical",
    className: "fa-solid fa-prescription-bottle-medical",
    label: "Prescription bottle medical",
  },
  {
    icon: "print",
    className: "fa-solid fa-print",
    label: "print",
  },
  {
    icon: "product-hunt",
    className: "fa-brands fa-product-hunt",
    label: "Product Hunt",
  },
  {
    icon: "pump-medical",
    className: "fa-solid fa-pump-medical",
    label: "Pump Medical",
  },
  {
    icon: "pump-soap",
    className: "fa-solid fa-pump-soap",
    label: "Pump Soap",
  },
  {
    icon: "pushed",
    className: "fa-brands fa-pushed",
    label: "Pushed",
  },
  {
    icon: "puzzle-piece",
    className: "fa-solid fa-puzzle-piece",
    label: "Puzzle Piece",
  },
  {
    icon: "python",
    className: "fa-brands fa-python",
    label: "Python",
  },
  {
    icon: "q",
    className: "fa-solid fa-q",
    label: "Q",
  },
  {
    icon: "qq",
    className: "fa-brands fa-qq",
    label: "QQ",
  },
  {
    icon: "qrcode",
    className: "fa-solid fa-qrcode",
    label: "qrcode",
  },
  {
    icon: "question",
    className: "fa-solid fa-question",
    label: "Question",
  },
  {
    icon: "quinscape",
    className: "fa-brands fa-quinscape",
    label: "QuinScape",
  },
  {
    icon: "quora",
    className: "fa-brands fa-quora",
    label: "Quora",
  },
  {
    icon: "quote-left",
    className: "fa-solid fa-quote-left",
    label: "quote-left",
  },
  {
    icon: "quote-right",
    className: "fa-solid fa-quote-right",
    label: "quote-right",
  },
  {
    icon: "r",
    className: "fa-solid fa-r",
    label: "R",
  },
  {
    icon: "r-project",
    className: "fa-brands fa-r-project",
    label: "R Project",
  },
  {
    icon: "radiation",
    className: "fa-solid fa-radiation",
    label: "Radiation",
  },
  {
    icon: "radio",
    className: "fa-solid fa-radio",
    label: "Radio",
  },
  {
    icon: "rainbow",
    className: "fa-solid fa-rainbow",
    label: "Rainbow",
  },
  {
    icon: "ranking-star",
    className: "fa-solid fa-ranking-star",
    label: "Ranking Star",
  },
  {
    icon: "raspberry-pi",
    className: "fa-brands fa-raspberry-pi",
    label: "Raspberry Pi",
  },
  {
    icon: "ravelry",
    className: "fa-brands fa-ravelry",
    label: "Ravelry",
  },
  {
    icon: "react",
    className: "fa-brands fa-react",
    label: "React",
  },
  {
    icon: "reacteurope",
    className: "fa-brands fa-reacteurope",
    label: "ReactEurope",
  },
  {
    icon: "readme",
    className: "fa-brands fa-readme",
    label: "ReadMe",
  },
  {
    icon: "rebel",
    className: "fa-brands fa-rebel",
    label: "Rebel Alliance",
  },
  {
    icon: "receipt",
    className: "fa-solid fa-receipt",
    label: "Receipt",
  },
  {
    icon: "record-vinyl",
    className: "fa-solid fa-record-vinyl",
    label: "Record Vinyl",
  },
  {
    icon: "rectangle-ad",
    className: "fa-solid fa-rectangle-ad",
    label: "Rectangle ad",
  },
  {
    icon: "rectangle-list",
    className: "fa-solid fa-rectangle-list",
    label: "Rectangle list",
  },
  {
    icon: "rectangle-list",
    className: "fa-regular fa-rectangle-list",
    label: "Rectangle list",
  },
  {
    icon: "rectangle-xmark",
    className: "fa-solid fa-rectangle-xmark",
    label: "Rectangle X Mark",
  },
  {
    icon: "rectangle-xmark",
    className: "fa-regular fa-rectangle-xmark",
    label: "Rectangle X Mark",
  },
  {
    icon: "recycle",
    className: "fa-solid fa-recycle",
    label: "Recycle",
  },
  {
    icon: "red-river",
    className: "fa-brands fa-red-river",
    label: "red river",
  },
  {
    icon: "reddit",
    className: "fa-brands fa-reddit",
    label: "reddit Logo",
  },
  {
    icon: "reddit-alien",
    className: "fa-brands fa-reddit-alien",
    label: "reddit Alien",
  },
  {
    icon: "reddit-square",
    className: "fa-brands fa-reddit-square",
    label: "reddit Square",
  },
  {
    icon: "redhat",
    className: "fa-brands fa-redhat",
    label: "Redhat",
  },
  {
    icon: "registered",
    className: "fa-solid fa-registered",
    label: "Registered Trademark",
  },
  {
    icon: "registered",
    className: "fa-regular fa-registered",
    label: "Registered Trademark",
  },
  {
    icon: "renren",
    className: "fa-brands fa-renren",
    label: "Renren",
  },
  {
    icon: "repeat",
    className: "fa-solid fa-repeat",
    label: "Repeat",
  },
  {
    icon: "reply",
    className: "fa-solid fa-reply",
    label: "Reply",
  },
  {
    icon: "reply-all",
    className: "fa-solid fa-reply-all",
    label: "reply-all",
  },
  {
    icon: "replyd",
    className: "fa-brands fa-replyd",
    label: "replyd",
  },
  {
    icon: "republican",
    className: "fa-solid fa-republican",
    label: "Republican",
  },
  {
    icon: "researchgate",
    className: "fa-brands fa-researchgate",
    label: "Researchgate",
  },
  {
    icon: "resolving",
    className: "fa-brands fa-resolving",
    label: "Resolving",
  },
  {
    icon: "restroom",
    className: "fa-solid fa-restroom",
    label: "Restroom",
  },
  {
    icon: "retweet",
    className: "fa-solid fa-retweet",
    label: "Retweet",
  },
  {
    icon: "rev",
    className: "fa-brands fa-rev",
    label: "Rev.io",
  },
  {
    icon: "ribbon",
    className: "fa-solid fa-ribbon",
    label: "Ribbon",
  },
  {
    icon: "right-from-bracket",
    className: "fa-solid fa-right-from-bracket",
    label: "Right from bracket",
  },
  {
    icon: "right-left",
    className: "fa-solid fa-right-left",
    label: "Right left",
  },
  {
    icon: "right-long",
    className: "fa-solid fa-right-long",
    label: "Right long",
  },
  {
    icon: "right-to-bracket",
    className: "fa-solid fa-right-to-bracket",
    label: "Right to bracket",
  },
  {
    icon: "ring",
    className: "fa-solid fa-ring",
    label: "Ring",
  },
  {
    icon: "road",
    className: "fa-solid fa-road",
    label: "road",
  },
  {
    icon: "road-barrier",
    className: "fa-solid fa-road-barrier",
    label: "Road Barrier",
  },
  {
    icon: "road-bridge",
    className: "fa-solid fa-road-bridge",
    label: "Road Bridge",
  },
  {
    icon: "road-circle-check",
    className: "fa-solid fa-road-circle-check",
    label: "Road Circle-check",
  },
  {
    icon: "road-circle-exclamation",
    className: "fa-solid fa-road-circle-exclamation",
    label: "Road Circle-exclamation",
  },
  {
    icon: "road-circle-xmark",
    className: "fa-solid fa-road-circle-xmark",
    label: "Road Circle-xmark",
  },
  {
    icon: "road-lock",
    className: "fa-solid fa-road-lock",
    label: "Road Lock",
  },
  {
    icon: "road-spikes",
    className: "fa-solid fa-road-spikes",
    label: "Road Spikes",
  },
  {
    icon: "robot",
    className: "fa-solid fa-robot",
    label: "Robot",
  },
  {
    icon: "rocket",
    className: "fa-solid fa-rocket",
    label: "rocket",
  },
  {
    icon: "rocketchat",
    className: "fa-brands fa-rocketchat",
    label: "Rocket.Chat",
  },
  {
    icon: "rockrms",
    className: "fa-brands fa-rockrms",
    label: "Rockrms",
  },
  {
    icon: "rotate",
    className: "fa-solid fa-rotate",
    label: "Rotate",
  },
  {
    icon: "rotate-left",
    className: "fa-solid fa-rotate-left",
    label: "Rotate Left",
  },
  {
    icon: "rotate-right",
    className: "fa-solid fa-rotate-right",
    label: "Rotate Right",
  },
  {
    icon: "route",
    className: "fa-solid fa-route",
    label: "Route",
  },
  {
    icon: "rss",
    className: "fa-solid fa-rss",
    label: "rss",
  },
  {
    icon: "ruble-sign",
    className: "fa-solid fa-ruble-sign",
    label: "Ruble Sign",
  },
  {
    icon: "rug",
    className: "fa-solid fa-rug",
    label: "Rug",
  },
  {
    icon: "ruler",
    className: "fa-solid fa-ruler",
    label: "Ruler",
  },
  {
    icon: "ruler-combined",
    className: "fa-solid fa-ruler-combined",
    label: "Ruler Combined",
  },
  {
    icon: "ruler-horizontal",
    className: "fa-solid fa-ruler-horizontal",
    label: "Ruler Horizontal",
  },
  {
    icon: "ruler-vertical",
    className: "fa-solid fa-ruler-vertical",
    label: "Ruler Vertical",
  },
  {
    icon: "rupee-sign",
    className: "fa-solid fa-rupee-sign",
    label: "Indian Rupee Sign",
  },
  {
    icon: "rupiah-sign",
    className: "fa-solid fa-rupiah-sign",
    label: "Rupiah Sign",
  },
  {
    icon: "rust",
    className: "fa-brands fa-rust",
    label: "Rust",
  },
  {
    icon: "s",
    className: "fa-solid fa-s",
    label: "S",
  },
  {
    icon: "sack-dollar",
    className: "fa-solid fa-sack-dollar",
    label: "Sack of Money",
  },
  {
    icon: "sack-xmark",
    className: "fa-solid fa-sack-xmark",
    label: "Sack Xmark",
  },
  {
    icon: "safari",
    className: "fa-brands fa-safari",
    label: "Safari",
  },
  {
    icon: "sailboat",
    className: "fa-solid fa-sailboat",
    label: "Sailboat",
  },
  {
    icon: "salesforce",
    className: "fa-brands fa-salesforce",
    label: "Salesforce",
  },
  {
    icon: "sass",
    className: "fa-brands fa-sass",
    label: "Sass",
  },
  {
    icon: "satellite",
    className: "fa-solid fa-satellite",
    label: "Satellite",
  },
  {
    icon: "satellite-dish",
    className: "fa-solid fa-satellite-dish",
    label: "Satellite Dish",
  },
  {
    icon: "scale-balanced",
    className: "fa-solid fa-scale-balanced",
    label: "Scale balanced",
  },
  {
    icon: "scale-unbalanced",
    className: "fa-solid fa-scale-unbalanced",
    label: "Scale unbalanced",
  },
  {
    icon: "scale-unbalanced-flip",
    className: "fa-solid fa-scale-unbalanced-flip",
    label: "Scale unbalanced flip",
  },
  {
    icon: "schlix",
    className: "fa-brands fa-schlix",
    label: "SCHLIX",
  },
  {
    icon: "school",
    className: "fa-solid fa-school",
    label: "School",
  },
  {
    icon: "school-circle-check",
    className: "fa-solid fa-school-circle-check",
    label: "School Circle-check",
  },
  {
    icon: "school-circle-exclamation",
    className: "fa-solid fa-school-circle-exclamation",
    label: "School Circle-exclamation",
  },
  {
    icon: "school-circle-xmark",
    className: "fa-solid fa-school-circle-xmark",
    label: "School Circle-xmark",
  },
  {
    icon: "school-flag",
    className: "fa-solid fa-school-flag",
    label: "School Flag",
  },
  {
    icon: "school-lock",
    className: "fa-solid fa-school-lock",
    label: "School Lock",
  },
  {
    icon: "scissors",
    className: "fa-solid fa-scissors",
    label: "Scissors",
  },
  {
    icon: "screenpal",
    className: "fa-brands fa-screenpal",
    label: "Screenpal",
  },
  {
    icon: "screwdriver",
    className: "fa-solid fa-screwdriver",
    label: "Screwdriver",
  },
  {
    icon: "screwdriver-wrench",
    className: "fa-solid fa-screwdriver-wrench",
    label: "Screwdriver wrench",
  },
  {
    icon: "scribd",
    className: "fa-brands fa-scribd",
    label: "Scribd",
  },
  {
    icon: "scroll",
    className: "fa-solid fa-scroll",
    label: "Scroll",
  },
  {
    icon: "scroll-torah",
    className: "fa-solid fa-scroll-torah",
    label: "Scroll torah",
  },
  {
    icon: "sd-card",
    className: "fa-solid fa-sd-card",
    label: "Sd Card",
  },
  {
    icon: "searchengin",
    className: "fa-brands fa-searchengin",
    label: "Searchengin",
  },
  {
    icon: "section",
    className: "fa-solid fa-section",
    label: "Section",
  },
  {
    icon: "seedling",
    className: "fa-solid fa-seedling",
    label: "Seedling",
  },
  {
    icon: "sellcast",
    className: "fa-brands fa-sellcast",
    label: "Sellcast",
  },
  {
    icon: "sellsy",
    className: "fa-brands fa-sellsy",
    label: "Sellsy",
  },
  {
    icon: "server",
    className: "fa-solid fa-server",
    label: "Server",
  },
  {
    icon: "servicestack",
    className: "fa-brands fa-servicestack",
    label: "Servicestack",
  },
  {
    icon: "shapes",
    className: "fa-solid fa-shapes",
    label: "Shapes",
  },
  {
    icon: "share",
    className: "fa-solid fa-share",
    label: "Share",
  },
  {
    icon: "share-from-square",
    className: "fa-solid fa-share-from-square",
    label: "Share from square",
  },
  {
    icon: "share-from-square",
    className: "fa-regular fa-share-from-square",
    label: "Share from square",
  },
  {
    icon: "share-nodes",
    className: "fa-solid fa-share-nodes",
    label: "Share nodes",
  },
  {
    icon: "sheet-plastic",
    className: "fa-solid fa-sheet-plastic",
    label: "Sheet Plastic",
  },
  {
    icon: "shekel-sign",
    className: "fa-solid fa-shekel-sign",
    label: "Shekel Sign",
  },
  {
    icon: "shield",
    className: "fa-solid fa-shield",
    label: "shield",
  },
  {
    icon: "shield-cat",
    className: "fa-solid fa-shield-cat",
    label: "Shield Cat",
  },
  {
    icon: "shield-dog",
    className: "fa-solid fa-shield-dog",
    label: "Shield Dog",
  },
  {
    icon: "shield-halved",
    className: "fa-solid fa-shield-halved",
    label: "Shield Halved",
  },
  {
    icon: "shield-heart",
    className: "fa-solid fa-shield-heart",
    label: "Shield Heart",
  },
  {
    icon: "shield-virus",
    className: "fa-solid fa-shield-virus",
    label: "Shield Virus",
  },
  {
    icon: "ship",
    className: "fa-solid fa-ship",
    label: "Ship",
  },
  {
    icon: "shirt",
    className: "fa-solid fa-shirt",
    label: "T-Shirt",
  },
  {
    icon: "shirtsinbulk",
    className: "fa-brands fa-shirtsinbulk",
    label: "Shirts in Bulk",
  },
  {
    icon: "shoe-prints",
    className: "fa-solid fa-shoe-prints",
    label: "Shoe Prints",
  },
  {
    icon: "shop",
    className: "fa-solid fa-shop",
    label: "Shop",
  },
  {
    icon: "shop-lock",
    className: "fa-solid fa-shop-lock",
    label: "Shop Lock",
  },
  {
    icon: "shop-slash",
    className: "fa-solid fa-shop-slash",
    label: "Shop slash",
  },
  {
    icon: "shopify",
    className: "fa-brands fa-shopify",
    label: "Shopify",
  },
  {
    icon: "shopware",
    className: "fa-brands fa-shopware",
    label: "Shopware",
  },
  {
    icon: "shower",
    className: "fa-solid fa-shower",
    label: "Shower",
  },
  {
    icon: "shrimp",
    className: "fa-solid fa-shrimp",
    label: "Shrimp",
  },
  {
    icon: "shuffle",
    className: "fa-solid fa-shuffle",
    label: "Shuffle",
  },
  {
    icon: "shuttle-space",
    className: "fa-solid fa-shuttle-space",
    label: "Shuttle space",
  },
  {
    icon: "sign-hanging",
    className: "fa-solid fa-sign-hanging",
    label: "Sign hanging",
  },
  {
    icon: "signal",
    className: "fa-solid fa-signal",
    label: "signal",
  },
  {
    icon: "signature",
    className: "fa-solid fa-signature",
    label: "Signature",
  },
  {
    icon: "signs-post",
    className: "fa-solid fa-signs-post",
    label: "Signs post",
  },
  {
    icon: "sim-card",
    className: "fa-solid fa-sim-card",
    label: "SIM Card",
  },
  {
    icon: "simplybuilt",
    className: "fa-brands fa-simplybuilt",
    label: "SimplyBuilt",
  },
  {
    icon: "sink",
    className: "fa-solid fa-sink",
    label: "Sink",
  },
  {
    icon: "sistrix",
    className: "fa-brands fa-sistrix",
    label: "SISTRIX",
  },
  {
    icon: "sitemap",
    className: "fa-solid fa-sitemap",
    label: "Sitemap",
  },
  {
    icon: "sith",
    className: "fa-brands fa-sith",
    label: "Sith",
  },
  {
    icon: "sitrox",
    className: "fa-brands fa-sitrox",
    label: "Sitrox",
  },
  {
    icon: "sketch",
    className: "fa-brands fa-sketch",
    label: "Sketch",
  },
  {
    icon: "skull",
    className: "fa-solid fa-skull",
    label: "Skull",
  },
  {
    icon: "skull-crossbones",
    className: "fa-solid fa-skull-crossbones",
    label: "Skull & Crossbones",
  },
  {
    icon: "skyatlas",
    className: "fa-brands fa-skyatlas",
    label: "skyatlas",
  },
  {
    icon: "skype",
    className: "fa-brands fa-skype",
    label: "Skype",
  },
  {
    icon: "slack",
    className: "fa-brands fa-slack",
    label: "Slack Logo",
  },
  {
    icon: "slash",
    className: "fa-solid fa-slash",
    label: "Slash",
  },
  {
    icon: "sleigh",
    className: "fa-solid fa-sleigh",
    label: "Sleigh",
  },
  {
    icon: "sliders",
    className: "fa-solid fa-sliders",
    label: "Sliders",
  },
  {
    icon: "slideshare",
    className: "fa-brands fa-slideshare",
    label: "Slideshare",
  },
  {
    icon: "smog",
    className: "fa-solid fa-smog",
    label: "Smog",
  },
  {
    icon: "smoking",
    className: "fa-solid fa-smoking",
    label: "Smoking",
  },
  {
    icon: "snapchat",
    className: "fa-brands fa-snapchat",
    label: "Snapchat",
  },
  {
    icon: "snapchat-square",
    className: "fa-brands fa-snapchat-square",
    label: "Snapchat Square",
  },
  {
    icon: "snowflake",
    className: "fa-solid fa-snowflake",
    label: "Snowflake",
  },
  {
    icon: "snowflake",
    className: "fa-regular fa-snowflake",
    label: "Snowflake",
  },
  {
    icon: "snowman",
    className: "fa-solid fa-snowman",
    label: "Snowman",
  },
  {
    icon: "snowplow",
    className: "fa-solid fa-snowplow",
    label: "Snowplow",
  },
  {
    icon: "soap",
    className: "fa-solid fa-soap",
    label: "Soap",
  },
  {
    icon: "socks",
    className: "fa-solid fa-socks",
    label: "Socks",
  },
  {
    icon: "solar-panel",
    className: "fa-solid fa-solar-panel",
    label: "Solar Panel",
  },
  {
    icon: "sort",
    className: "fa-solid fa-sort",
    label: "Sort",
  },
  {
    icon: "sort-down",
    className: "fa-solid fa-sort-down",
    label: "Sort Down (Descending)",
  },
  {
    icon: "sort-up",
    className: "fa-solid fa-sort-up",
    label: "Sort Up (Ascending)",
  },
  {
    icon: "soundcloud",
    className: "fa-brands fa-soundcloud",
    label: "SoundCloud",
  },
  {
    icon: "sourcetree",
    className: "fa-brands fa-sourcetree",
    label: "Sourcetree",
  },
  {
    icon: "spa",
    className: "fa-solid fa-spa",
    label: "Spa",
  },
  {
    icon: "spaghetti-monster-flying",
    className: "fa-solid fa-spaghetti-monster-flying",
    label: "Spaghetti monster flying",
  },
  {
    icon: "speakap",
    className: "fa-brands fa-speakap",
    label: "Speakap",
  },
  {
    icon: "speaker-deck",
    className: "fa-brands fa-speaker-deck",
    label: "Speaker Deck",
  },
  {
    icon: "spell-check",
    className: "fa-solid fa-spell-check",
    label: "Spell Check",
  },
  {
    icon: "spider",
    className: "fa-solid fa-spider",
    label: "Spider",
  },
  {
    icon: "spinner",
    className: "fa-solid fa-spinner",
    label: "Spinner",
  },
  {
    icon: "splotch",
    className: "fa-solid fa-splotch",
    label: "Splotch",
  },
  {
    icon: "spoon",
    className: "fa-solid fa-spoon",
    label: "Spoon",
  },
  {
    icon: "spotify",
    className: "fa-brands fa-spotify",
    label: "Spotify",
  },
  {
    icon: "spray-can",
    className: "fa-solid fa-spray-can",
    label: "Spray Can",
  },
  {
    icon: "spray-can-sparkles",
    className: "fa-solid fa-spray-can-sparkles",
    label: "Spray Can Sparkles",
  },
  {
    icon: "square",
    className: "fa-solid fa-square",
    label: "Square",
  },
  {
    icon: "square",
    className: "fa-regular fa-square",
    label: "Square",
  },
  {
    icon: "square-arrow-up-right",
    className: "fa-solid fa-square-arrow-up-right",
    label: "Square arrow up right",
  },
  {
    icon: "square-caret-down",
    className: "fa-solid fa-square-caret-down",
    label: "Square caret down",
  },
  {
    icon: "square-caret-down",
    className: "fa-regular fa-square-caret-down",
    label: "Square caret down",
  },
  {
    icon: "square-caret-left",
    className: "fa-solid fa-square-caret-left",
    label: "Square caret left",
  },
  {
    icon: "square-caret-left",
    className: "fa-regular fa-square-caret-left",
    label: "Square caret left",
  },
  {
    icon: "square-caret-right",
    className: "fa-solid fa-square-caret-right",
    label: "Square caret right",
  },
  {
    icon: "square-caret-right",
    className: "fa-regular fa-square-caret-right",
    label: "Square caret right",
  },
  {
    icon: "square-caret-up",
    className: "fa-solid fa-square-caret-up",
    label: "Square caret up",
  },
  {
    icon: "square-caret-up",
    className: "fa-regular fa-square-caret-up",
    label: "Square caret up",
  },
  {
    icon: "square-check",
    className: "fa-solid fa-square-check",
    label: "Square check",
  },
  {
    icon: "square-check",
    className: "fa-regular fa-square-check",
    label: "Square check",
  },
  {
    icon: "square-envelope",
    className: "fa-solid fa-square-envelope",
    label: "Square envelope",
  },
  {
    icon: "square-font-awesome",
    className: "fa-brands fa-square-font-awesome",
    label: "Font Awesome in Square",
  },
  {
    icon: "square-font-awesome-stroke",
    className: "fa-brands fa-square-font-awesome-stroke",
    label: "Font Awesome in Square with Stroke Outline",
  },
  {
    icon: "square-full",
    className: "fa-solid fa-square-full",
    label: "Square Full",
  },
  {
    icon: "square-full",
    className: "fa-regular fa-square-full",
    label: "Square Full",
  },
  {
    icon: "square-h",
    className: "fa-solid fa-square-h",
    label: "Square h",
  },
  {
    icon: "square-minus",
    className: "fa-solid fa-square-minus",
    label: "Square minus",
  },
  {
    icon: "square-minus",
    className: "fa-regular fa-square-minus",
    label: "Square minus",
  },
  {
    icon: "square-nfi",
    className: "fa-solid fa-square-nfi",
    label: "Square Nfi",
  },
  {
    icon: "square-parking",
    className: "fa-solid fa-square-parking",
    label: "Square parking",
  },
  {
    icon: "square-pen",
    className: "fa-solid fa-square-pen",
    label: "Square pen",
  },
  {
    icon: "square-person-confined",
    className: "fa-solid fa-square-person-confined",
    label: "Square Person-confined",
  },
  {
    icon: "square-phone",
    className: "fa-solid fa-square-phone",
    label: "Square phone",
  },
  {
    icon: "square-phone-flip",
    className: "fa-solid fa-square-phone-flip",
    label: "Square phone flip",
  },
  {
    icon: "square-plus",
    className: "fa-solid fa-square-plus",
    label: "Square plus",
  },
  {
    icon: "square-plus",
    className: "fa-regular fa-square-plus",
    label: "Square plus",
  },
  {
    icon: "square-poll-horizontal",
    className: "fa-solid fa-square-poll-horizontal",
    label: "Square poll horizontal",
  },
  {
    icon: "square-poll-vertical",
    className: "fa-solid fa-square-poll-vertical",
    label: "Square poll vertical",
  },
  {
    icon: "square-root-variable",
    className: "fa-solid fa-square-root-variable",
    label: "Square root variable",
  },
  {
    icon: "square-rss",
    className: "fa-solid fa-square-rss",
    label: "Square rss",
  },
  {
    icon: "square-share-nodes",
    className: "fa-solid fa-square-share-nodes",
    label: "Square share nodes",
  },
  {
    icon: "square-up-right",
    className: "fa-solid fa-square-up-right",
    label: "Square up right",
  },
  {
    icon: "square-virus",
    className: "fa-solid fa-square-virus",
    label: "Square Virus",
  },
  {
    icon: "square-xmark",
    className: "fa-solid fa-square-xmark",
    label: "Square X Mark",
  },
  {
    icon: "squarespace",
    className: "fa-brands fa-squarespace",
    label: "Squarespace",
  },
  {
    icon: "stack-exchange",
    className: "fa-brands fa-stack-exchange",
    label: "Stack Exchange",
  },
  {
    icon: "stack-overflow",
    className: "fa-brands fa-stack-overflow",
    label: "Stack Overflow",
  },
  {
    icon: "stackpath",
    className: "fa-brands fa-stackpath",
    label: "Stackpath",
  },
  {
    icon: "staff-aesculapius",
    className: "fa-solid fa-staff-aesculapius",
    label: "Staff Aesculapius",
  },
  {
    icon: "stairs",
    className: "fa-solid fa-stairs",
    label: "Stairs",
  },
  {
    icon: "stamp",
    className: "fa-solid fa-stamp",
    label: "Stamp",
  },
  {
    icon: "star",
    className: "fa-solid fa-star",
    label: "Star",
  },
  {
    icon: "star",
    className: "fa-regular fa-star",
    label: "Star",
  },
  {
    icon: "star-and-crescent",
    className: "fa-solid fa-star-and-crescent",
    label: "Star and Crescent",
  },
  {
    icon: "star-half",
    className: "fa-solid fa-star-half",
    label: "star-half",
  },
  {
    icon: "star-half",
    className: "fa-regular fa-star-half",
    label: "star-half",
  },
  {
    icon: "star-half-stroke",
    className: "fa-solid fa-star-half-stroke",
    label: "Star half stroke",
  },
  {
    icon: "star-half-stroke",
    className: "fa-regular fa-star-half-stroke",
    label: "Star half stroke",
  },
  {
    icon: "star-of-david",
    className: "fa-solid fa-star-of-david",
    label: "Star of David",
  },
  {
    icon: "star-of-life",
    className: "fa-solid fa-star-of-life",
    label: "Star of Life",
  },
  {
    icon: "staylinked",
    className: "fa-brands fa-staylinked",
    label: "StayLinked",
  },
  {
    icon: "steam",
    className: "fa-brands fa-steam",
    label: "Steam",
  },
  {
    icon: "steam-square",
    className: "fa-brands fa-steam-square",
    label: "Steam Square",
  },
  {
    icon: "steam-symbol",
    className: "fa-brands fa-steam-symbol",
    label: "Steam Symbol",
  },
  {
    icon: "sterling-sign",
    className: "fa-solid fa-sterling-sign",
    label: "Sterling sign",
  },
  {
    icon: "stethoscope",
    className: "fa-solid fa-stethoscope",
    label: "Stethoscope",
  },
  {
    icon: "sticker-mule",
    className: "fa-brands fa-sticker-mule",
    label: "Sticker Mule",
  },
  {
    icon: "stop",
    className: "fa-solid fa-stop",
    label: "stop",
  },
  {
    icon: "stopwatch",
    className: "fa-solid fa-stopwatch",
    label: "Stopwatch",
  },
  {
    icon: "stopwatch-20",
    className: "fa-solid fa-stopwatch-20",
    label: "Stopwatch 20",
  },
  {
    icon: "store",
    className: "fa-solid fa-store",
    label: "Store",
  },
  {
    icon: "store-slash",
    className: "fa-solid fa-store-slash",
    label: "Store Slash",
  },
  {
    icon: "strava",
    className: "fa-brands fa-strava",
    label: "Strava",
  },
  {
    icon: "street-view",
    className: "fa-solid fa-street-view",
    label: "Street View",
  },
  {
    icon: "strikethrough",
    className: "fa-solid fa-strikethrough",
    label: "Strikethrough",
  },
  {
    icon: "stripe",
    className: "fa-brands fa-stripe",
    label: "Stripe",
  },
  {
    icon: "stripe-s",
    className: "fa-brands fa-stripe-s",
    label: "Stripe S",
  },
  {
    icon: "stroopwafel",
    className: "fa-solid fa-stroopwafel",
    label: "Stroopwafel",
  },
  {
    icon: "studiovinari",
    className: "fa-brands fa-studiovinari",
    label: "Studio Vinari",
  },
  {
    icon: "stumbleupon",
    className: "fa-brands fa-stumbleupon",
    label: "StumbleUpon Logo",
  },
  {
    icon: "stumbleupon-circle",
    className: "fa-brands fa-stumbleupon-circle",
    label: "StumbleUpon Circle",
  },
  {
    icon: "subscript",
    className: "fa-solid fa-subscript",
    label: "subscript",
  },
  {
    icon: "suitcase",
    className: "fa-solid fa-suitcase",
    label: "Suitcase",
  },
  {
    icon: "suitcase-medical",
    className: "fa-solid fa-suitcase-medical",
    label: "Suitcase medical",
  },
  {
    icon: "suitcase-rolling",
    className: "fa-solid fa-suitcase-rolling",
    label: "Suitcase Rolling",
  },
  {
    icon: "sun",
    className: "fa-solid fa-sun",
    label: "Sun",
  },
  {
    icon: "sun",
    className: "fa-regular fa-sun",
    label: "Sun",
  },
  {
    icon: "sun-plant-wilt",
    className: "fa-solid fa-sun-plant-wilt",
    label: "Sun Plant-wilt",
  },
  {
    icon: "superpowers",
    className: "fa-brands fa-superpowers",
    label: "Superpowers",
  },
  {
    icon: "superscript",
    className: "fa-solid fa-superscript",
    label: "superscript",
  },
  {
    icon: "supple",
    className: "fa-brands fa-supple",
    label: "Supple",
  },
  {
    icon: "suse",
    className: "fa-brands fa-suse",
    label: "Suse",
  },
  {
    icon: "swatchbook",
    className: "fa-solid fa-swatchbook",
    label: "Swatchbook",
  },
  {
    icon: "swift",
    className: "fa-brands fa-swift",
    label: "Swift",
  },
  {
    icon: "symfony",
    className: "fa-brands fa-symfony",
    label: "Symfony",
  },
  {
    icon: "synagogue",
    className: "fa-solid fa-synagogue",
    label: "Synagogue",
  },
  {
    icon: "syringe",
    className: "fa-solid fa-syringe",
    label: "Syringe",
  },
  {
    icon: "t",
    className: "fa-solid fa-t",
    label: "T",
  },
  {
    icon: "table",
    className: "fa-solid fa-table",
    label: "table",
  },
  {
    icon: "table-cells",
    className: "fa-solid fa-table-cells",
    label: "Table cells",
  },
  {
    icon: "table-cells-large",
    className: "fa-solid fa-table-cells-large",
    label: "Table cells large",
  },
  {
    icon: "table-columns",
    className: "fa-solid fa-table-columns",
    label: "Table columns",
  },
  {
    icon: "table-list",
    className: "fa-solid fa-table-list",
    label: "Table list",
  },
  {
    icon: "table-tennis-paddle-ball",
    className: "fa-solid fa-table-tennis-paddle-ball",
    label: "Table tennis paddle ball",
  },
  {
    icon: "tablet",
    className: "fa-solid fa-tablet",
    label: "Tablet",
  },
  {
    icon: "tablet-button",
    className: "fa-solid fa-tablet-button",
    label: "Tablet button",
  },
  {
    icon: "tablet-screen-button",
    className: "fa-solid fa-tablet-screen-button",
    label: "Tablet screen button",
  },
  {
    icon: "tablets",
    className: "fa-solid fa-tablets",
    label: "Tablets",
  },
  {
    icon: "tachograph-digital",
    className: "fa-solid fa-tachograph-digital",
    label: "Tachograph digital",
  },
  {
    icon: "tag",
    className: "fa-solid fa-tag",
    label: "tag",
  },
  {
    icon: "tags",
    className: "fa-solid fa-tags",
    label: "tags",
  },
  {
    icon: "tape",
    className: "fa-solid fa-tape",
    label: "Tape",
  },
  {
    icon: "tarp",
    className: "fa-solid fa-tarp",
    label: "Tarp",
  },
  {
    icon: "tarp-droplet",
    className: "fa-solid fa-tarp-droplet",
    label: "Tarp Droplet",
  },
  {
    icon: "taxi",
    className: "fa-solid fa-taxi",
    label: "Taxi",
  },
  {
    icon: "teamspeak",
    className: "fa-brands fa-teamspeak",
    label: "TeamSpeak",
  },
  {
    icon: "teeth",
    className: "fa-solid fa-teeth",
    label: "Teeth",
  },
  {
    icon: "teeth-open",
    className: "fa-solid fa-teeth-open",
    label: "Teeth Open",
  },
  {
    icon: "telegram",
    className: "fa-brands fa-telegram",
    label: "Telegram",
  },
  {
    icon: "temperature-arrow-down",
    className: "fa-solid fa-temperature-arrow-down",
    label: "Temperature arrow down",
  },
  {
    icon: "temperature-arrow-up",
    className: "fa-solid fa-temperature-arrow-up",
    label: "Temperature arrow up",
  },
  {
    icon: "temperature-empty",
    className: "fa-solid fa-temperature-empty",
    label: "Temperature empty",
  },
  {
    icon: "temperature-full",
    className: "fa-solid fa-temperature-full",
    label: "Temperature full",
  },
  {
    icon: "temperature-half",
    className: "fa-solid fa-temperature-half",
    label: "Temperature half",
  },
  {
    icon: "temperature-high",
    className: "fa-solid fa-temperature-high",
    label: "High Temperature",
  },
  {
    icon: "temperature-low",
    className: "fa-solid fa-temperature-low",
    label: "Low Temperature",
  },
  {
    icon: "temperature-quarter",
    className: "fa-solid fa-temperature-quarter",
    label: "Temperature quarter",
  },
  {
    icon: "temperature-three-quarters",
    className: "fa-solid fa-temperature-three-quarters",
    label: "Temperature three quarters",
  },
  {
    icon: "tencent-weibo",
    className: "fa-brands fa-tencent-weibo",
    label: "Tencent Weibo",
  },
  {
    icon: "tenge-sign",
    className: "fa-solid fa-tenge-sign",
    label: "Tenge sign",
  },
  {
    icon: "tent",
    className: "fa-solid fa-tent",
    label: "Tent",
  },
  {
    icon: "tent-arrow-down-to-line",
    className: "fa-solid fa-tent-arrow-down-to-line",
    label: "Tent Arrow-down-to-line",
  },
  {
    icon: "tent-arrow-left-right",
    className: "fa-solid fa-tent-arrow-left-right",
    label: "Tent Arrow-left-right",
  },
  {
    icon: "tent-arrow-turn-left",
    className: "fa-solid fa-tent-arrow-turn-left",
    label: "Tent Arrow-turn-left",
  },
  {
    icon: "tent-arrows-down",
    className: "fa-solid fa-tent-arrows-down",
    label: "Tent Arrows-down",
  },
  {
    icon: "tents",
    className: "fa-solid fa-tents",
    label: "Tents",
  },
  {
    icon: "terminal",
    className: "fa-solid fa-terminal",
    label: "Terminal",
  },
  {
    icon: "text-height",
    className: "fa-solid fa-text-height",
    label: "text-height",
  },
  {
    icon: "text-slash",
    className: "fa-solid fa-text-slash",
    label: "Text slash",
  },
  {
    icon: "text-width",
    className: "fa-solid fa-text-width",
    label: "Text Width",
  },
  {
    icon: "the-red-yeti",
    className: "fa-brands fa-the-red-yeti",
    label: "The Red Yeti",
  },
  {
    icon: "themeco",
    className: "fa-brands fa-themeco",
    label: "Themeco",
  },
  {
    icon: "themeisle",
    className: "fa-brands fa-themeisle",
    label: "ThemeIsle",
  },
  {
    icon: "thermometer",
    className: "fa-solid fa-thermometer",
    label: "Thermometer",
  },
  {
    icon: "think-peaks",
    className: "fa-brands fa-think-peaks",
    label: "Think Peaks",
  },
  {
    icon: "thumbs-down",
    className: "fa-solid fa-thumbs-down",
    label: "thumbs-down",
  },
  {
    icon: "thumbs-down",
    className: "fa-regular fa-thumbs-down",
    label: "thumbs-down",
  },
  {
    icon: "thumbs-up",
    className: "fa-solid fa-thumbs-up",
    label: "thumbs-up",
  },
  {
    icon: "thumbs-up",
    className: "fa-regular fa-thumbs-up",
    label: "thumbs-up",
  },
  {
    icon: "thumbtack",
    className: "fa-solid fa-thumbtack",
    label: "Thumbtack",
  },
  {
    icon: "ticket",
    className: "fa-solid fa-ticket",
    label: "Ticket",
  },
  {
    icon: "ticket-simple",
    className: "fa-solid fa-ticket-simple",
    label: "Ticket simple",
  },
  {
    icon: "tiktok",
    className: "fa-brands fa-tiktok",
    label: "TikTok",
  },
  {
    icon: "timeline",
    className: "fa-solid fa-timeline",
    label: "Timeline",
  },
  {
    icon: "toggle-off",
    className: "fa-solid fa-toggle-off",
    label: "Toggle Off",
  },
  {
    icon: "toggle-on",
    className: "fa-solid fa-toggle-on",
    label: "Toggle On",
  },
  {
    icon: "toilet",
    className: "fa-solid fa-toilet",
    label: "Toilet",
  },
  {
    icon: "toilet-paper",
    className: "fa-solid fa-toilet-paper",
    label: "Toilet Paper",
  },
  {
    icon: "toilet-paper-slash",
    className: "fa-solid fa-toilet-paper-slash",
    label: "Toilet Paper Slash",
  },
  {
    icon: "toilet-portable",
    className: "fa-solid fa-toilet-portable",
    label: "Toilet Portable",
  },
  {
    icon: "toilets-portable",
    className: "fa-solid fa-toilets-portable",
    label: "Toilets Portable",
  },
  {
    icon: "toolbox",
    className: "fa-solid fa-toolbox",
    label: "Toolbox",
  },
  {
    icon: "tooth",
    className: "fa-solid fa-tooth",
    label: "Tooth",
  },
  {
    icon: "torii-gate",
    className: "fa-solid fa-torii-gate",
    label: "Torii Gate",
  },
  {
    icon: "tornado",
    className: "fa-solid fa-tornado",
    label: "Tornado",
  },
  {
    icon: "tower-broadcast",
    className: "fa-solid fa-tower-broadcast",
    label: "Tower broadcast",
  },
  {
    icon: "tower-cell",
    className: "fa-solid fa-tower-cell",
    label: "Tower Cell",
  },
  {
    icon: "tower-observation",
    className: "fa-solid fa-tower-observation",
    label: "Tower Observation",
  },
  {
    icon: "tractor",
    className: "fa-solid fa-tractor",
    label: "Tractor",
  },
  {
    icon: "trade-federation",
    className: "fa-brands fa-trade-federation",
    label: "Trade Federation",
  },
  {
    icon: "trademark",
    className: "fa-solid fa-trademark",
    label: "Trademark",
  },
  {
    icon: "traffic-light",
    className: "fa-solid fa-traffic-light",
    label: "Traffic Light",
  },
  {
    icon: "trailer",
    className: "fa-solid fa-trailer",
    label: "Trailer",
  },
  {
    icon: "train",
    className: "fa-solid fa-train",
    label: "Train",
  },
  {
    icon: "train-subway",
    className: "fa-solid fa-train-subway",
    label: "Train subway",
  },
  {
    icon: "train-tram",
    className: "fa-solid fa-train-tram",
    label: "Train tram",
  },
  {
    icon: "transgender",
    className: "fa-solid fa-transgender",
    label: "Transgender",
  },
  {
    icon: "trash",
    className: "fa-solid fa-trash",
    label: "Trash",
  },
  {
    icon: "trash-arrow-up",
    className: "fa-solid fa-trash-arrow-up",
    label: "Trash arrow up",
  },
  {
    icon: "trash-can",
    className: "fa-solid fa-trash-can",
    label: "Trash can",
  },
  {
    icon: "trash-can",
    className: "fa-regular fa-trash-can",
    label: "Trash can",
  },
  {
    icon: "trash-can-arrow-up",
    className: "fa-solid fa-trash-can-arrow-up",
    label: "Trash can arrow up",
  },
  {
    icon: "tree",
    className: "fa-solid fa-tree",
    label: "Tree",
  },
  {
    icon: "tree-city",
    className: "fa-solid fa-tree-city",
    label: "Tree City",
  },
  {
    icon: "trello",
    className: "fa-brands fa-trello",
    label: "Trello",
  },
  {
    icon: "triangle-exclamation",
    className: "fa-solid fa-triangle-exclamation",
    label: "Triangle exclamation",
  },
  {
    icon: "trophy",
    className: "fa-solid fa-trophy",
    label: "trophy",
  },
  {
    icon: "trowel",
    className: "fa-solid fa-trowel",
    label: "Trowel",
  },
  {
    icon: "trowel-bricks",
    className: "fa-solid fa-trowel-bricks",
    label: "Trowel Bricks",
  },
  {
    icon: "truck",
    className: "fa-solid fa-truck",
    label: "truck",
  },
  {
    icon: "truck-arrow-right",
    className: "fa-solid fa-truck-arrow-right",
    label: "Truck Arrow-right",
  },
  {
    icon: "truck-droplet",
    className: "fa-solid fa-truck-droplet",
    label: "Truck Droplet",
  },
  {
    icon: "truck-fast",
    className: "fa-solid fa-truck-fast",
    label: "Truck fast",
  },
  {
    icon: "truck-field",
    className: "fa-solid fa-truck-field",
    label: "Truck Field",
  },
  {
    icon: "truck-field-un",
    className: "fa-solid fa-truck-field-un",
    label: "Truck Field-un",
  },
  {
    icon: "truck-front",
    className: "fa-solid fa-truck-front",
    label: "Truck Front",
  },
  {
    icon: "truck-medical",
    className: "fa-solid fa-truck-medical",
    label: "Truck medical",
  },
  {
    icon: "truck-monster",
    className: "fa-solid fa-truck-monster",
    label: "Truck Monster",
  },
  {
    icon: "truck-moving",
    className: "fa-solid fa-truck-moving",
    label: "Truck Moving",
  },
  {
    icon: "truck-pickup",
    className: "fa-solid fa-truck-pickup",
    label: "Truck Side",
  },
  {
    icon: "truck-plane",
    className: "fa-solid fa-truck-plane",
    label: "Truck Plane",
  },
  {
    icon: "truck-ramp-box",
    className: "fa-solid fa-truck-ramp-box",
    label: "Truck ramp box",
  },
  {
    icon: "tty",
    className: "fa-solid fa-tty",
    label: "TTY",
  },
  {
    icon: "tumblr",
    className: "fa-brands fa-tumblr",
    label: "Tumblr",
  },
  {
    icon: "tumblr-square",
    className: "fa-brands fa-tumblr-square",
    label: "Tumblr Square",
  },
  {
    icon: "turkish-lira-sign",
    className: "fa-solid fa-turkish-lira-sign",
    label: "Turkish Lira-sign",
  },
  {
    icon: "turn-down",
    className: "fa-solid fa-turn-down",
    label: "Turn down",
  },
  {
    icon: "turn-up",
    className: "fa-solid fa-turn-up",
    label: "Turn up",
  },
  {
    icon: "tv",
    className: "fa-solid fa-tv",
    label: "Television",
  },
  {
    icon: "twitch",
    className: "fa-brands fa-twitch",
    label: "Twitch",
  },
  {
    icon: "twitter",
    className: "fa-brands fa-twitter",
    label: "Twitter",
  },
  {
    icon: "twitter-square",
    className: "fa-brands fa-twitter-square",
    label: "Twitter Square",
  },
  {
    icon: "typo3",
    className: "fa-brands fa-typo3",
    label: "Typo3",
  },
  {
    icon: "u",
    className: "fa-solid fa-u",
    label: "U",
  },
  {
    icon: "uber",
    className: "fa-brands fa-uber",
    label: "Uber",
  },
  {
    icon: "ubuntu",
    className: "fa-brands fa-ubuntu",
    label: "Ubuntu",
  },
  {
    icon: "uikit",
    className: "fa-brands fa-uikit",
    label: "UIkit",
  },
  {
    icon: "umbraco",
    className: "fa-brands fa-umbraco",
    label: "Umbraco",
  },
  {
    icon: "umbrella",
    className: "fa-solid fa-umbrella",
    label: "Umbrella",
  },
  {
    icon: "umbrella-beach",
    className: "fa-solid fa-umbrella-beach",
    label: "Umbrella Beach",
  },
  {
    icon: "uncharted",
    className: "fa-brands fa-uncharted",
    label: "Uncharted Software",
  },
  {
    icon: "underline",
    className: "fa-solid fa-underline",
    label: "Underline",
  },
  {
    icon: "uniregistry",
    className: "fa-brands fa-uniregistry",
    label: "Uniregistry",
  },
  {
    icon: "unity",
    className: "fa-brands fa-unity",
    label: "Unity 3D",
  },
  {
    icon: "universal-access",
    className: "fa-solid fa-universal-access",
    label: "Universal Access",
  },
  {
    icon: "unlock",
    className: "fa-solid fa-unlock",
    label: "unlock",
  },
  {
    icon: "unlock-keyhole",
    className: "fa-solid fa-unlock-keyhole",
    label: "Unlock keyhole",
  },
  {
    icon: "unsplash",
    className: "fa-brands fa-unsplash",
    label: "Unsplash",
  },
  {
    icon: "untappd",
    className: "fa-brands fa-untappd",
    label: "Untappd",
  },
  {
    icon: "up-down",
    className: "fa-solid fa-up-down",
    label: "Up down",
  },
  {
    icon: "up-down-left-right",
    className: "fa-solid fa-up-down-left-right",
    label: "Up down left right",
  },
  {
    icon: "up-long",
    className: "fa-solid fa-up-long",
    label: "Up long",
  },
  {
    icon: "up-right-and-down-left-from-center",
    className: "fa-solid fa-up-right-and-down-left-from-center",
    label: "Up right and down left from center",
  },
  {
    icon: "up-right-from-square",
    className: "fa-solid fa-up-right-from-square",
    label: "Up right from square",
  },
  {
    icon: "upload",
    className: "fa-solid fa-upload",
    label: "Upload",
  },
  {
    icon: "ups",
    className: "fa-brands fa-ups",
    label: "UPS",
  },
  {
    icon: "usb",
    className: "fa-brands fa-usb",
    label: "USB",
  },
  {
    icon: "user",
    className: "fa-solid fa-user",
    label: "User",
  },
  {
    icon: "user",
    className: "fa-regular fa-user",
    label: "User",
  },
  {
    icon: "user-astronaut",
    className: "fa-solid fa-user-astronaut",
    label: "User Astronaut",
  },
  {
    icon: "user-check",
    className: "fa-solid fa-user-check",
    label: "User Check",
  },
  {
    icon: "user-clock",
    className: "fa-solid fa-user-clock",
    label: "User Clock",
  },
  {
    icon: "user-doctor",
    className: "fa-solid fa-user-doctor",
    label: "User doctor",
  },
  {
    icon: "user-gear",
    className: "fa-solid fa-user-gear",
    label: "User gear",
  },
  {
    icon: "user-graduate",
    className: "fa-solid fa-user-graduate",
    label: "User Graduate",
  },
  {
    icon: "user-group",
    className: "fa-solid fa-user-group",
    label: "User group",
  },
  {
    icon: "user-injured",
    className: "fa-solid fa-user-injured",
    label: "User Injured",
  },
  {
    icon: "user-large",
    className: "fa-solid fa-user-large",
    label: "User large",
  },
  {
    icon: "user-large-slash",
    className: "fa-solid fa-user-large-slash",
    label: "User large slash",
  },
  {
    icon: "user-lock",
    className: "fa-solid fa-user-lock",
    label: "User Lock",
  },
  {
    icon: "user-minus",
    className: "fa-solid fa-user-minus",
    label: "User Minus",
  },
  {
    icon: "user-ninja",
    className: "fa-solid fa-user-ninja",
    label: "User Ninja",
  },
  {
    icon: "user-nurse",
    className: "fa-solid fa-user-nurse",
    label: "Nurse",
  },
  {
    icon: "user-pen",
    className: "fa-solid fa-user-pen",
    label: "User pen",
  },
  {
    icon: "user-plus",
    className: "fa-solid fa-user-plus",
    label: "User Plus",
  },
  {
    icon: "user-secret",
    className: "fa-solid fa-user-secret",
    label: "User Secret",
  },
  {
    icon: "user-shield",
    className: "fa-solid fa-user-shield",
    label: "User Shield",
  },
  {
    icon: "user-slash",
    className: "fa-solid fa-user-slash",
    label: "User Slash",
  },
  {
    icon: "user-tag",
    className: "fa-solid fa-user-tag",
    label: "User Tag",
  },
  {
    icon: "user-tie",
    className: "fa-solid fa-user-tie",
    label: "User Tie",
  },
  {
    icon: "user-xmark",
    className: "fa-solid fa-user-xmark",
    label: "User X Mark",
  },
  {
    icon: "users",
    className: "fa-solid fa-users",
    label: "Users",
  },
  {
    icon: "users-between-lines",
    className: "fa-solid fa-users-between-lines",
    label: "Users Between-lines",
  },
  {
    icon: "users-gear",
    className: "fa-solid fa-users-gear",
    label: "Users gear",
  },
  {
    icon: "users-line",
    className: "fa-solid fa-users-line",
    label: "Users Line",
  },
  {
    icon: "users-rays",
    className: "fa-solid fa-users-rays",
    label: "Users Rays",
  },
  {
    icon: "users-rectangle",
    className: "fa-solid fa-users-rectangle",
    label: "Users Rectangle",
  },
  {
    icon: "users-slash",
    className: "fa-solid fa-users-slash",
    label: "Users Slash",
  },
  {
    icon: "users-viewfinder",
    className: "fa-solid fa-users-viewfinder",
    label: "Users Viewfinder",
  },
  {
    icon: "usps",
    className: "fa-brands fa-usps",
    label: "United States Postal Service",
  },
  {
    icon: "ussunnah",
    className: "fa-brands fa-ussunnah",
    label: "us-Sunnah Foundation",
  },
  {
    icon: "utensils",
    className: "fa-solid fa-utensils",
    label: "Utensils",
  },
  {
    icon: "v",
    className: "fa-solid fa-v",
    label: "V",
  },
  {
    icon: "vaadin",
    className: "fa-brands fa-vaadin",
    label: "Vaadin",
  },
  {
    icon: "van-shuttle",
    className: "fa-solid fa-van-shuttle",
    label: "Van shuttle",
  },
  {
    icon: "vault",
    className: "fa-solid fa-vault",
    label: "Vault",
  },
  {
    icon: "vector-square",
    className: "fa-solid fa-vector-square",
    label: "Vector Square",
  },
  {
    icon: "venus",
    className: "fa-solid fa-venus",
    label: "Venus",
  },
  {
    icon: "venus-double",
    className: "fa-solid fa-venus-double",
    label: "Venus Double",
  },
  {
    icon: "venus-mars",
    className: "fa-solid fa-venus-mars",
    label: "Venus Mars",
  },
  {
    icon: "vest",
    className: "fa-solid fa-vest",
    label: "vest",
  },
  {
    icon: "vest-patches",
    className: "fa-solid fa-vest-patches",
    label: "vest-patches",
  },
  {
    icon: "viacoin",
    className: "fa-brands fa-viacoin",
    label: "Viacoin",
  },
  {
    icon: "viadeo",
    className: "fa-brands fa-viadeo",
    label: "Viadeo",
  },
  {
    icon: "viadeo-square",
    className: "fa-brands fa-viadeo-square",
    label: "Viadeo Square",
  },
  {
    icon: "vial",
    className: "fa-solid fa-vial",
    label: "Vial",
  },
  {
    icon: "vial-circle-check",
    className: "fa-solid fa-vial-circle-check",
    label: "Vial Circle-check",
  },
  {
    icon: "vial-virus",
    className: "fa-solid fa-vial-virus",
    label: "Vial Virus",
  },
  {
    icon: "vials",
    className: "fa-solid fa-vials",
    label: "Vials",
  },
  {
    icon: "viber",
    className: "fa-brands fa-viber",
    label: "Viber",
  },
  {
    icon: "video",
    className: "fa-solid fa-video",
    label: "Video",
  },
  {
    icon: "video-slash",
    className: "fa-solid fa-video-slash",
    label: "Video Slash",
  },
  {
    icon: "vihara",
    className: "fa-solid fa-vihara",
    label: "Vihara",
  },
  {
    icon: "vimeo",
    className: "fa-brands fa-vimeo",
    label: "Vimeo",
  },
  {
    icon: "vimeo-square",
    className: "fa-brands fa-vimeo-square",
    label: "Vimeo Square",
  },
  {
    icon: "vimeo-v",
    className: "fa-brands fa-vimeo-v",
    label: "Vimeo",
  },
  {
    icon: "vine",
    className: "fa-brands fa-vine",
    label: "Vine",
  },
  {
    icon: "virus",
    className: "fa-solid fa-virus",
    label: "Virus",
  },
  {
    icon: "virus-covid",
    className: "fa-solid fa-virus-covid",
    label: "Virus Covid",
  },
  {
    icon: "virus-covid-slash",
    className: "fa-solid fa-virus-covid-slash",
    label: "Virus Covid-slash",
  },
  {
    icon: "virus-slash",
    className: "fa-solid fa-virus-slash",
    label: "Virus Slash",
  },
  {
    icon: "viruses",
    className: "fa-solid fa-viruses",
    label: "Viruses",
  },
  {
    icon: "vk",
    className: "fa-brands fa-vk",
    label: "VK",
  },
  {
    icon: "vnv",
    className: "fa-brands fa-vnv",
    label: "VNV",
  },
  {
    icon: "voicemail",
    className: "fa-solid fa-voicemail",
    label: "Voicemail",
  },
  {
    icon: "volcano",
    className: "fa-solid fa-volcano",
    label: "Volcano",
  },
  {
    icon: "volleyball",
    className: "fa-solid fa-volleyball",
    label: "Volleyball Ball",
  },
  {
    icon: "volume-high",
    className: "fa-solid fa-volume-high",
    label: "Volume high",
  },
  {
    icon: "volume-low",
    className: "fa-solid fa-volume-low",
    label: "Volume low",
  },
  {
    icon: "volume-off",
    className: "fa-solid fa-volume-off",
    label: "Volume Off",
  },
  {
    icon: "volume-xmark",
    className: "fa-solid fa-volume-xmark",
    label: "Volume X Mark",
  },
  {
    icon: "vr-cardboard",
    className: "fa-solid fa-vr-cardboard",
    label: "Cardboard VR",
  },
  {
    icon: "vuejs",
    className: "fa-brands fa-vuejs",
    label: "Vue.js",
  },
  {
    icon: "w",
    className: "fa-solid fa-w",
    label: "W",
  },
  {
    icon: "walkie-talkie",
    className: "fa-solid fa-walkie-talkie",
    label: "Walkie Talkie",
  },
  {
    icon: "wallet",
    className: "fa-solid fa-wallet",
    label: "Wallet",
  },
  {
    icon: "wand-magic",
    className: "fa-solid fa-wand-magic",
    label: "Wand magic",
  },
  {
    icon: "wand-magic-sparkles",
    className: "fa-solid fa-wand-magic-sparkles",
    label: "Wand magic sparkles",
  },
  {
    icon: "wand-sparkles",
    className: "fa-solid fa-wand-sparkles",
    label: "Wand sparkles",
  },
  {
    icon: "warehouse",
    className: "fa-solid fa-warehouse",
    label: "Warehouse",
  },
  {
    icon: "watchman-monitoring",
    className: "fa-brands fa-watchman-monitoring",
    label: "Watchman Monitoring",
  },
  {
    icon: "water",
    className: "fa-solid fa-water",
    label: "Water",
  },
  {
    icon: "water-ladder",
    className: "fa-solid fa-water-ladder",
    label: "Water ladder",
  },
  {
    icon: "wave-square",
    className: "fa-solid fa-wave-square",
    label: "Square Wave",
  },
  {
    icon: "waze",
    className: "fa-brands fa-waze",
    label: "Waze",
  },
  {
    icon: "weebly",
    className: "fa-brands fa-weebly",
    label: "Weebly",
  },
  {
    icon: "weibo",
    className: "fa-brands fa-weibo",
    label: "Weibo",
  },
  {
    icon: "weight-hanging",
    className: "fa-solid fa-weight-hanging",
    label: "Hanging Weight",
  },
  {
    icon: "weight-scale",
    className: "fa-solid fa-weight-scale",
    label: "Weight scale",
  },
  {
    icon: "weixin",
    className: "fa-brands fa-weixin",
    label: "Weixin (WeChat)",
  },
  {
    icon: "whatsapp",
    className: "fa-brands fa-whatsapp",
    label: "What's App",
  },
  {
    icon: "whatsapp-square",
    className: "fa-brands fa-whatsapp-square",
    label: "What's App Square",
  },
  {
    icon: "wheat-awn",
    className: "fa-solid fa-wheat-awn",
    label: "Wheat awn",
  },
  {
    icon: "wheat-awn-circle-exclamation",
    className: "fa-solid fa-wheat-awn-circle-exclamation",
    label: "Wheat Awn-circle-exclamation",
  },
  {
    icon: "wheelchair",
    className: "fa-solid fa-wheelchair",
    label: "Wheelchair",
  },
  {
    icon: "wheelchair-move",
    className: "fa-solid fa-wheelchair-move",
    label: "Wheelchair Move",
  },
  {
    icon: "whiskey-glass",
    className: "fa-solid fa-whiskey-glass",
    label: "Whiskey glass",
  },
  {
    icon: "whmcs",
    className: "fa-brands fa-whmcs",
    label: "WHMCS",
  },
  {
    icon: "wifi",
    className: "fa-solid fa-wifi",
    label: "WiFi",
  },
  {
    icon: "wikipedia-w",
    className: "fa-brands fa-wikipedia-w",
    label: "Wikipedia W",
  },
  {
    icon: "wind",
    className: "fa-solid fa-wind",
    label: "Wind",
  },
  {
    icon: "window-maximize",
    className: "fa-solid fa-window-maximize",
    label: "Window Maximize",
  },
  {
    icon: "window-maximize",
    className: "fa-regular fa-window-maximize",
    label: "Window Maximize",
  },
  {
    icon: "window-minimize",
    className: "fa-solid fa-window-minimize",
    label: "Window Minimize",
  },
  {
    icon: "window-minimize",
    className: "fa-regular fa-window-minimize",
    label: "Window Minimize",
  },
  {
    icon: "window-restore",
    className: "fa-solid fa-window-restore",
    label: "Window Restore",
  },
  {
    icon: "window-restore",
    className: "fa-regular fa-window-restore",
    label: "Window Restore",
  },
  {
    icon: "windows",
    className: "fa-brands fa-windows",
    label: "Windows",
  },
  {
    icon: "wine-bottle",
    className: "fa-solid fa-wine-bottle",
    label: "Wine Bottle",
  },
  {
    icon: "wine-glass",
    className: "fa-solid fa-wine-glass",
    label: "Wine Glass",
  },
  {
    icon: "wine-glass-empty",
    className: "fa-solid fa-wine-glass-empty",
    label: "Wine glass empty",
  },
  {
    icon: "wirsindhandwerk",
    className: "fa-brands fa-wirsindhandwerk",
    label: "wirsindhandwerk",
  },
  {
    icon: "wix",
    className: "fa-brands fa-wix",
    label: "Wix",
  },
  {
    icon: "wizards-of-the-coast",
    className: "fa-brands fa-wizards-of-the-coast",
    label: "Wizards of the Coast",
  },
  {
    icon: "wodu",
    className: "fa-brands fa-wodu",
    label: "Wodu",
  },
  {
    icon: "wolf-pack-battalion",
    className: "fa-brands fa-wolf-pack-battalion",
    label: "Wolf Pack Battalion",
  },
  {
    icon: "won-sign",
    className: "fa-solid fa-won-sign",
    label: "Won Sign",
  },
  {
    icon: "wordpress",
    className: "fa-brands fa-wordpress",
    label: "WordPress Logo",
  },
  {
    icon: "wordpress-simple",
    className: "fa-brands fa-wordpress-simple",
    label: "Wordpress Simple",
  },
  {
    icon: "worm",
    className: "fa-solid fa-worm",
    label: "Worm",
  },
  {
    icon: "wpbeginner",
    className: "fa-brands fa-wpbeginner",
    label: "WPBeginner",
  },
  {
    icon: "wpexplorer",
    className: "fa-brands fa-wpexplorer",
    label: "WPExplorer",
  },
  {
    icon: "wpforms",
    className: "fa-brands fa-wpforms",
    label: "WPForms",
  },
  {
    icon: "wpressr",
    className: "fa-brands fa-wpressr",
    label: "wpressr",
  },
  {
    icon: "wrench",
    className: "fa-solid fa-wrench",
    label: "Wrench",
  },
  {
    icon: "x",
    className: "fa-solid fa-x",
    label: "X",
  },
  {
    icon: "x-ray",
    className: "fa-solid fa-x-ray",
    label: "X-Ray",
  },
  {
    icon: "xbox",
    className: "fa-brands fa-xbox",
    label: "Xbox",
  },
  {
    icon: "xing",
    className: "fa-brands fa-xing",
    label: "Xing",
  },
  {
    icon: "xing-square",
    className: "fa-brands fa-xing-square",
    label: "Xing Square",
  },
  {
    icon: "xmark",
    className: "fa-solid fa-xmark",
    label: "X Mark",
  },
  {
    icon: "xmarks-lines",
    className: "fa-solid fa-xmarks-lines",
    label: "Xmarks Lines",
  },
  {
    icon: "y",
    className: "fa-solid fa-y",
    label: "Y",
  },
  {
    icon: "y-combinator",
    className: "fa-brands fa-y-combinator",
    label: "Y Combinator",
  },
  {
    icon: "yahoo",
    className: "fa-brands fa-yahoo",
    label: "Yahoo Logo",
  },
  {
    icon: "yammer",
    className: "fa-brands fa-yammer",
    label: "Yammer",
  },
  {
    icon: "yandex",
    className: "fa-brands fa-yandex",
    label: "Yandex",
  },
  {
    icon: "yandex-international",
    className: "fa-brands fa-yandex-international",
    label: "Yandex International",
  },
  {
    icon: "yarn",
    className: "fa-brands fa-yarn",
    label: "Yarn",
  },
  {
    icon: "yelp",
    className: "fa-brands fa-yelp",
    label: "Yelp",
  },
  {
    icon: "yen-sign",
    className: "fa-solid fa-yen-sign",
    label: "Yen Sign",
  },
  {
    icon: "yin-yang",
    className: "fa-solid fa-yin-yang",
    label: "Yin Yang",
  },
  {
    icon: "yoast",
    className: "fa-brands fa-yoast",
    label: "Yoast",
  },
  {
    icon: "youtube",
    className: "fa-brands fa-youtube",
    label: "YouTube",
  },
  {
    icon: "youtube-square",
    className: "fa-brands fa-youtube-square",
    label: "YouTube Square",
  },
  {
    icon: "z",
    className: "fa-solid fa-z",
    label: "Z",
  },
  {
    icon: "zhihu",
    className: "fa-brands fa-zhihu",
    label: "Zhihu",
  },
];
