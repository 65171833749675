import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { openColorPicker } from "../../../actions/eb";
import { MAIL_COLOR_VAR_NAMES } from "../../../lib/generalVars";
import { translate } from "../../../translations/translations";

const MailColorscheme = ({ eb: { ebCssVars }, openColorPicker }) => {
  return (
    <>
      <h4>{translate("cMailStyle.mailColorScheme", false, null)}</h4>
      <div className="row mt-3">
        {MAIL_COLOR_VAR_NAMES.map((colorVarName, i) => (
          <div className="col-6 px-3 py-2 d-flex" key={colorVarName}>
            <p className="m-0" style={{ width: "50%" }}>
              {translate("cMailStyle.color", false, null)} {i + 1}:
            </p>
            <div
              className="cursorPointer"
              style={{ width: "50%", height: "100%", backgroundColor: ebCssVars[colorVarName] }}
              onClick={() => openColorPicker(`${translate("cMailStyle.editColor", false, null)} ${i + 1}`, ebCssVars[colorVarName], colorVarName)}
              data-bs-toggle="modal"
              data-bs-target="#ModalColorPicker"
            ></div>
          </div>
        ))}
      </div>
      <p className="mt-3 mb-0">
        {translate("cMailStyle.needInspiration", false, null)}{" "}
        <span className="text-primary textHover-secondary trans-3 cursorPointer" data-bs-toggle="modal" data-bs-target="#ModalColorSchemeGenerator">
          {translate("cMailStyle.openColorSchemeGenerator", false, null)}
        </span>
      </p>
    </>
  );
};

MailColorscheme.propTypes = {
  eb: PropTypes.object.isRequired,
  openColorPicker: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  eb: state.eb,
});

export default connect(mapStateToProps, { openColorPicker })(MailColorscheme);
