import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { FONT_WEIGHT_CSS, FONT_WEIGHT_NAMES } from "../../../lib/generalVars";

import { selectFont } from "../../../actions/eb";
import { translate } from "../../../translations/translations";

const MailFonts = ({ eb: { ebCssVars }, selectFont }) => {
  const [showFontNameHeaders, setShowFontNameHeaders] = useState(true);
  const [showFontNameBody, setShowFontNameBody] = useState(true);

  const switchFonts = () => {
    let currHeadersName = ebCssVars.fontHeadersName;
    let currBodyName = ebCssVars.fontBodyName;
    let currHeadersWeight = ebCssVars.fontHeadersWeight;
    let currBodyWeight = ebCssVars.fontBodyWeight;
    selectFont(currHeadersName, "fontBodyName");
    selectFont(currBodyName, "fontHeadersName");
    selectFont(currHeadersWeight, "fontBodyWeight");
    selectFont(currBodyWeight, "fontHeadersWeight");
  };

  return (
    <>
      <h4 className="mt-5">{translate("cMailStyle.mailFonts", false, null)}</h4>
      {/* Headers */}
      <div className="posRelative">
        <div className="my-2 d-flex align-items-center">
          <p className="m-0" style={{ width: "100px" }}>
            {translate("cMailStyle.headers", false, null)}
          </p>
          <button
            className="btn btn-light"
            style={{ width: "200px" }}
            data-bs-toggle="modal"
            data-bs-target="#ModalMailFontsHeaders"
            onMouseOver={() => setShowFontNameHeaders((prev) => !prev)}
            onMouseOut={() => setShowFontNameHeaders((prev) => !prev)}
          >
            {showFontNameHeaders ? ebCssVars.fontHeadersName : translate("cMailStyle.changeFont", false, null)}
          </button>
          <select
            className="form-select form-select-sm ms-3"
            style={{ width: "150px" }}
            value={ebCssVars.fontHeadersWeight}
            onChange={(e) => selectFont(e.target.value, "fontHeadersWeight")}
          >
            {FONT_WEIGHT_NAMES.map((weight, i) => (
              <option key={FONT_WEIGHT_CSS[i]} value={FONT_WEIGHT_CSS[i]}>
                {weight}
              </option>
            ))}
          </select>
        </div>
        {/* Body */}
        <div className="my-2 d-flex align-items-center">
          <p className="m-0" style={{ width: "100px" }}>
            {translate("cMailStyle.body", false, null)}
          </p>
          <button
            className="btn btn-light"
            style={{ width: "200px" }}
            data-bs-toggle="modal"
            data-bs-target="#ModalMailFontsBody"
            onMouseOver={() => setShowFontNameBody((prev) => !prev)}
            onMouseOut={() => setShowFontNameBody((prev) => !prev)}
          >
            {showFontNameBody ? ebCssVars.fontBodyName : translate("cMailStyle.changeFont", false, null)}
          </button>
          <select
            className="form-select form-select-sm ms-3"
            style={{ width: "150px" }}
            value={ebCssVars.fontBodyWeight}
            onChange={(e) => selectFont(e.target.value, "fontBodyWeight")}
          >
            {FONT_WEIGHT_NAMES.map((weight, i) => (
              <option key={FONT_WEIGHT_CSS[i]} value={FONT_WEIGHT_CSS[i]}>
                {weight}
              </option>
            ))}
          </select>
        </div>
        <span
          className="overallStyleSwapFonts text-gray textHover-primary cursorPointer trans-3"
          onClick={switchFonts}
          title={translate("cMailStyle.switchHeadersBodyFont", false, null)}
        >
          <i className="fa-solid fa-arrow-right-arrow-left" />
        </span>
      </div>
    </>
  );
};

MailFonts.propTypes = {
  eb: PropTypes.object.isRequired,
  selectFont: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  eb: state.eb,
});

export default connect(mapStateToProps, { selectFont })(MailFonts);
