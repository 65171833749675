import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Tooltip from "./Tooltip";

import { EDIT_FORM_ID_PREFIX } from "../../../../../lib/editFunctions";

const Select = ({ eb: { selectedElement }, label, id, options, startValue, tooltipText }) => {
  const [value, setValue] = useState(0);

  useEffect(() => {
    setValue(startValue);
  }, [startValue, selectedElement]);

  return (
    <div className="w-100 d-flex align-items-center my-3">
      <div className="w-50">
        {label}
        <Tooltip text={tooltipText} />
      </div>
      <div className="w-50 d-flex justify-content-center">
        <select className="form-select form-select-sm" id={EDIT_FORM_ID_PREFIX + id} value={value} onChange={(e) => setValue(e.target.value)}>
          {options.map((option) => (
            <option key={option.val} value={option.val}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

Select.propTypes = {
  eb: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  eb: state.eb,
});

export default connect(mapStateToProps, null)(Select);
