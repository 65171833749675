import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Select from "./formComponents/Select";
import Divider from "./formComponents/Divider";
import Border from "./Border";
import BorderRadius from "./BorderRadius";
import Margin from "./Margin";
import LinkDestTarget from "./formComponents/LinkDestTarget";

import { formGetStartValue } from "../../../../lib/editFunctions";
import { capitalizeFirstLetter } from "../../../../lib/generalFunctions";
import { SOCIAL_NAMES, SOCIAL_COLORS } from "../../../../lib/editStyleVars";
import { translate } from "../../../../translations/translations";

const SocialIconSource = ({ eb: { startStyles } }) => {
  return (
    <>
      <LinkDestTarget />
      <Select
        label={translate("cEditForms.socialMediaSite", false, null)}
        id={"socialIconName"}
        options={SOCIAL_NAMES.map((name) => ({ val: name, label: capitalizeFirstLetter(name.replace(/-/g, " ")) }))}
        startValue={formGetStartValue(startStyles, "socialIconName")}
        tooltipText={translate("cEditForms.ttSocialMediaSite", false, null)}
      />
      <Select
        label={translate("cEditForms.iconColor", false, null)}
        id={"socialIconColor"}
        options={SOCIAL_COLORS}
        startValue={formGetStartValue(startStyles, "socialIconColor")}
        tooltipText={translate("cEditForms.ttIconColor", false, null)}
      />
      <Select
        label={translate("cEditForms.bgColor", false, null)}
        id={"socialIconBgColor"}
        options={SOCIAL_COLORS}
        startValue={formGetStartValue(startStyles, "socialIconBgColor")}
        tooltipText={translate("cEditForms.ttIconBgColor", false, null)}
      />
      <Divider />
      <Border />
      <Divider />
      <BorderRadius />
      <Divider />
      <Margin />
    </>
  );
};

SocialIconSource.propTypes = {
  eb: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  eb: state.eb,
});

export default connect(mapStateToProps, null)(SocialIconSource);
