import React from "react";

import MailColorscheme from "./MailColorscheme";
import MailFonts from "./MailFonts";

const MailStyle = () => {
  return (
    <>
      <MailColorscheme />
      <MailFonts />
    </>
  );
};

export default MailStyle;
