export const cMailStyle = {
  mailColorScheme: {
    en: "Mail color scheme",
    nl: "Mail kleurenpallet",
  },
  color: {
    en: "Color",
    nl: "Kleur",
  },
  editColor: {
    en: "Edit color",
    nl: "Bewerk kleur",
  },
  needInspiration: {
    en: "Need inspiration?",
    nl: "Inspiratie nodig?",
  },
  openColorSchemeGenerator: {
    en: "Open color scheme generator",
    nl: "Open kleurenpallet generator",
  },
  mailFonts: {
    en: "Mail fonts",
    nl: "Mail lettertypes",
  },
  headers: {
    en: "Headers",
    nl: "Tekstkoppen",
  },
  changeFont: {
    en: "Change font",
    nl: "Verander lettertype",
  },
  body: {
    en: "Body",
    nl: "Normale tekst",
  },
  switchHeadersBodyFont: {
    en: "Switch headers and body fonts",
    nl: "Wissel koppen en tekst lettertypes",
  },
};
