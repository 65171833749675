import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Custom from "./formComponents/Custom";

import { formGetStartValue } from "../../../../lib/editFunctions";
import { EDIT_FORM_ID_PREFIX } from "../../../../lib/editFunctions";
import { translate } from "../../../../translations/translations";

const ImgSize = ({ eb: { startStyles } }) => {
  const ImgWidth = () => {
    const [valueSelect, setValueSelect] = useState("");
    const [valuePx, setValuePx] = useState(0);
    useEffect(() => {
      setValueSelect(formGetStartValue(startStyles, "imgResizeWidth"));
      setValuePx(formGetStartValue(startStyles, "imgResizeWidthPx"));
      // eslint-disable-next-line
    }, [formGetStartValue(startStyles, "imgResizeWidth"), formGetStartValue(startStyles, "imgResizeWidthPx")]);

    return (
      <>
        {/* Dropdown */}
        <select
          className="form-select form-select-sm"
          id={`${EDIT_FORM_ID_PREFIX}imgResizeWidth`}
          value={valueSelect}
          onChange={(e) => setValueSelect(e.target.value)}
        >
          <option value="100%">{translate("cEditForms.fullWidth", false, null)}</option>
          <option value="auto">{translate("cEditForms.autoBasedOnHeight", false, null)}</option>
          <option value="">{translate("cEditForms.setWidthManually", false, null)}</option>
        </select>
        {/* Specific width in px */}
        {valueSelect === "" && (
          <div className="input-group input-group-sm mt-3">
            <input
              type="number"
              className="form-control"
              id={`${EDIT_FORM_ID_PREFIX}imgResizeWidthPx`}
              value={valuePx}
              onChange={(e) => setValuePx(e.target.value)}
              min={0}
            />
            <span className="input-group-text">px</span>
          </div>
        )}
      </>
    );
  };

  const ImgHeight = () => {
    const [valueSelect, setValueSelect] = useState("");
    const [valuePx, setValuePx] = useState(0);
    useEffect(() => {
      setValueSelect(formGetStartValue(startStyles, "imgResizeHeight"));
      setValuePx(formGetStartValue(startStyles, "imgResizeHeightPx"));
      // eslint-disable-next-line
    }, [formGetStartValue(startStyles, "imgResizeHeight"), formGetStartValue(startStyles, "imgResizeHeightPx")]);

    return (
      <>
        {/* Dropdown */}
        <select
          className="form-select form-select-sm"
          id={`${EDIT_FORM_ID_PREFIX}imgResizeHeight`}
          value={valueSelect}
          onChange={(e) => setValueSelect(e.target.value)}
        >
          <option value="100%">{translate("cEditForms.fullHeight", false, null)}</option>
          <option value="auto">{translate("cEditForms.autoBasedOnWdith", false, null)}</option>
          <option value="">{translate("cEditForms.setHeightManually", false, null)}</option>
        </select>
        {/* Specific height in px */}
        {valueSelect === "" && (
          <div className="input-group input-group-sm mt-3">
            <input
              type="number"
              className="form-control"
              id={`${EDIT_FORM_ID_PREFIX}imgResizeHeightPx`}
              value={valuePx}
              onChange={(e) => setValuePx(e.target.value)}
              min={0}
            />
            <span className="input-group-text">px</span>
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <Custom
        label={translate("cEditForms.imgWidth", false, null)}
        column={true}
        customComponent={ImgWidth}
        tooltipText={translate("cEditForms.ttImgWidth", false, null)}
      />
      <Custom
        label={translate("cEditForms.imgHeight", false, null)}
        column={true}
        customComponent={ImgHeight}
        tooltipText={translate("cEditForms.ttImgHeight", false, null)}
      />
    </>
  );
};

ImgSize.propTypes = {
  eb: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  eb: state.eb,
});

export default connect(mapStateToProps, null)(ImgSize);
