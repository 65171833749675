import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { FONT_WEIGHT_VALUES, FONT_WEIGHT_CSS, FONT_WEIGHT_NAMES } from "../../../lib/generalVars";
import { selectFont } from "../../../actions/eb";
import { translate } from "../../../translations/translations";

// target = "headers" || "body"
const MailFonts = ({ eb: { ebCssVars }, target, selectFont }) => {
  const closeBtn = useRef();

  const initialPreviewText = translate("mEmailFonts.initialPreviewText", false, null);

  // https://www.w3schools.com/cssref/css_websafe_fonts.php
  const [state, setState] = useState({
    allFonts: ["Arial", "Verdana", "Tahoma", "Trebuchet MS", "Times New Roman", "Georgia", "Garamond", "Courier New", "Brush Script MT"],
    fontWeight: "400",
    previewText: initialPreviewText,
    selectedFont: null,
  });
  const { allFonts, previewText, fontWeight, selectedFont } = state;

  const onChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const clickConfirmFont = () => {
    try {
      selectFont(selectedFont.fontName, target === "headers" ? "fontHeadersName" : "fontBodyName");
      selectFont(selectedFont.fontWeight, target === "headers" ? "fontHeadersWeight" : "fontBodyWeight");
      closeModal();
    } catch (error) {
      console.error(`Error in clickConfirmFont // selectedFont: ${selectedFont.fontName}`);
      console.error(error);
    }
  };

  const closeModal = () => {
    // Reset state
    setState({
      ...state,
      previewText: initialPreviewText,
      selectedFont: null,
      errorText: "",
    });
    // Close fontSelectionPanes
    closeFontSelectionPane();
  };

  const openFontSelectionPane = (e, i) => {
    // Close currently open
    closeFontSelectionPane();
    // Get the div that is being clicked
    let clickedDiv = e.currentTarget;
    // Find the position to insert the selection pane
    let colsPerRow = 3;
    let endOfRow = (i + 1) % colsPerRow;
    endOfRow > 0 && (endOfRow = colsPerRow - endOfRow);
    let insertBefore = clickedDiv.parentElement;
    for (let i = 0; i <= endOfRow; i++) {
      insertBefore !== null && (insertBefore = insertBefore.nextSibling);
    }
    // Set up the content of the selection pane
    let selectionPane = document.createElement("div");
    selectionPane.className = "col-12 p-2 fontSelectionPane";
    let innerDiv = document.createElement("div");
    innerDiv.className = "pt-2 rounded shadow-light trans-3";
    innerDiv.innerHTML = `<h5 class="text-primary m-0 px-2">${allFonts[i]}<i class="fa-solid fa-xmark float-end trans-3 cursorPointer text-gray textHover-primary fontSelectionPaneClose"></i></h5><p class="p-2 m-0">Available styles</p>`;
    // Add the available weights
    FONT_WEIGHT_VALUES.forEach(
      (weight, j) =>
        (innerDiv.innerHTML = `${innerDiv.innerHTML}<div class="p-2 bgHover-light border-top fontSelectionPanePreview"><p class="fontSize09 text-gray mb-1">${FONT_WEIGHT_NAMES[j]}</p><div class="d-flex align-items-center"><p class="m-0 fontSize15" style="font-family: ${allFonts[i]}; font-weight: ${FONT_WEIGHT_CSS[j]}; width: 80%;">${previewText}</p><span class="text-success ms-auto trans-3 cursorPointer fontSelectionPaneSelect" data-font="${allFonts[i]}" data-weight="${FONT_WEIGHT_CSS[j]}"><i class="fa-solid fa-circle-check me-1 fontSize11"></i>Select this font</span></div></div>`)
    );
    // Insert the selection pane
    selectionPane.appendChild(innerDiv);
    clickedDiv.parentElement.parentElement.insertBefore(selectionPane, insertBefore);
  };

  const onClickModal = (e) => {
    // If e.target is the icon to close the fontSelectionPane, close it
    e.target.className.includes("fontSelectionPaneClose") && closeFontSelectionPane();
    // If e.target or e.target.parentElement is fontSelectionPaneSelect, select the font
    if (e.target.className.includes("fontSelectionPaneSelect") || e.target.parentElement.className.includes("fontSelectionPaneSelect")) {
      let span = e.target;
      span.localName === "i" && (span = e.target.parentElement);
      setState({ ...state, selectedFont: { fontName: span.dataset.font, fontWeight: span.dataset.weight } });
      closeFontSelectionPane();
    }
  };

  const closeFontSelectionPane = (indexToOpen = -1) => {
    const openPanes = document.querySelectorAll(".fontSelectionPane");
    openPanes.forEach((pane) => pane.remove());
  };

  return (
    <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-xl" onClick={onClickModal}>
      <div className="modal-content">
        <div className="modal-header">
          <h3 className="modal-title">
            {target === "headers"
              ? translate("mEmailFonts.selectNewFontHeaders", false, null)
              : translate("mEmailFonts.selectNewFontBody", false, null)}
          </h3>
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ref={closeBtn} onClick={closeModal}></button>
        </div>
        <div className="modal-body">
          <div className="row">
            {/* Preview text */}
            <div className="col-3">
              <p className="mt-3 mb-0">{translate("mEmailFonts.previewText", false, null)}</p>
              <input
                className="form-control"
                type="text"
                value={previewText}
                name="previewText"
                onChange={onChange}
                placeholder={translate("mEmailFonts.previewText", false, null)}
              />
            </div>
            {/* Fonts */}
            <div className="col-9">
              <div className="row mr-0">
                {allFonts.map((fontFamily, i) => (
                  <div className="col-4 p-2" key={i}>
                    <div
                      className="previewFontDiv p-2 rounded shadow-light trans-3 cursorPointer flexSameHeight"
                      onClick={(e) => openFontSelectionPane(e, i)}
                    >
                      <p className="fontSize15 mb-2 toGrow" style={{ fontFamily, fontWeight }}>
                        {previewText}
                      </p>
                      <p className="text-muted small mb-0">{fontFamily}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer justify-content-between">
          <p className="m-0 me-auto">
            <span className="text-bold">{translate("mEmailFonts.selectedFont", false, null)}: </span>
            <span
              className="fontSize12"
              style={{
                fontFamily: selectedFont === null ? ebCssVars[target === "headers" ? "fontHeadersName" : "fontBodyName"] : selectedFont.fontName,
                fontWeight: selectedFont === null ? ebCssVars[target === "headers" ? "fontHeadersName" : "fontBodyName"] : selectedFont.fontName,
              }}
            >
              {selectedFont === null ? ebCssVars[target === "headers" ? "fontHeadersName" : "fontBodyName"] : selectedFont.fontName}
            </span>
          </p>
          <button type="button" className="btn btn-gray px-4" data-bs-dismiss="modal" onClick={closeModal}>
            {translate("mEmailFonts.close", false, null)}
          </button>
          <button type="button" className="btn btn-success px-4" data-bs-dismiss="modal" onClick={clickConfirmFont}>
            {translate("mEmailFonts.confirmFont", false, null)}
          </button>
        </div>
      </div>
    </div>
  );
};

MailFonts.propTypes = {
  eb: PropTypes.object.isRequired,
  selectFont: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  eb: state.eb,
});

export default connect(mapStateToProps, { selectFont })(MailFonts);
