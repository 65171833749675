// Css for development
export const DevCss = `
.dragdrop-hover {
  background-color: #a8a8a8 !important;
  transition: all 300ms;
}

.dragdrop-hover {
  margin-bottom: 16px !important;
}

[data-ebtype="component"], [data-ebtype="element"], .column {
  position: relative;
}

.componentActions, .elementActions {
  position: absolute;
  opacity: 0;
  right: 3px;
  top: 0;
  transition: all 300ms;
  z-index: 500;
}

.componentActions {  
  top: 0;
}

.elementActions {  
  top: 24px;
}

[data-ebtype="component"]:hover .componentActions, [data-ebtype="element"]:hover>.elementActions, tr[data-ebtype="element"]:hover .elementActions, .column:hover>.elementActions {
  opacity: 1;
}

.componentActions span:hover, .elementActions span:hover {
  cursor: pointer;
}

.componentActions>.moveup,
.componentActions>.movedown,
.componentActions>.edit,
.elementActions>.edit,
.elementActions>.duplicate,
.elementActions>.reorder {
  color: #28a745;
  margin-right: 0.5rem;
  display: inline;
}

.componentActions>.delete, .elementActions>.delete {
  color: #dc3545;
  display: inline;
}

[data-texteditable="true"] {
  border: 1px solid transparent;
}

[data-texteditable="true"]:hover {
  border-color: #007bff !important;
}`;
