export const mModalBrowseTemplates = {
  previewAllTemplates: {
    en: "Preview templates",
    nl: "Bekijk templates",
  },
  browseTemplates: {
    en: "Browse templates",
    nl: "Bekijk templates",
  },
  allCategories: {
    en: "All categories",
    nl: "Alle categorieen",
  },
  noTemplatesFound: {
    en: "No templates found. Please reload this page.",
    nl: "Geen templates gevonden. Vernieuw aub de pagina.",
  },
  close: {
    en: "Close",
    nl: "Sluiten",
  },
  loadSelectedTemplate: {
    en: "Load selected template",
    nl: "Laad geselecteerde template",
  },
  lastTemplates: {
    en: "These are the last templates",
    nl: "Dit zijn de laatste templates",
  },
  seeAddnlTemplates: {
    en: "See additional templates",
    nl: "Bekijk meer templates",
  },
  showingTemplates: {
    en: "Showing templates",
    nl: "Templates",
  },
  of: {
    en: "of",
    nl: "van",
  },
};
