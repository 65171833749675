// Bring in action types
import {
  // Manage project
  LOAD_PROJECT,
  LOAD_TEMPLATE,
  UPDATE_PROJECT_GALLERY,
  UPDATE_GALLERY_FILE_DESC,
  UPDATE_MAILMETA,
  // Product tour
  OPEN_PRODUCT_TOUR,
  CLOSE_PRODUCT_TOUR,
  // Drag & drop
  START_DRAG_FROM_MENU,
  END_DRAG_FROM_MENU,
  DROP_COMPONENT,
  // Edit component
  REORDER_COMPONENT,
  DELETE_COMPONENT,
  SET_SELECTED_ELEMENT,
  // Manage elements
  DELETE_ELEMENT,
  DUPLICATE_ELEMENT,
  REORDER_COL,
  // Manage pages
  ADD_NEW_VERSION,
  SET_ACTIVE_PAGE,
  DELETE_VERSION,
  // Track changes
  SAVE_TRACK_CHANGES,
  UNDO_REDO,
  // Edit styles
  START_EDITING,
  OPEN_COLOR_PICKER,
  UPDATE_CSS_VAR,
  CONFIRM_COLOR_SCHEME,
  CHANGE_MAIL_COLORS,
  UPDATE_COMPONENT_CSS,
  UPDATE_COMPONENT_CLASSES,
  UPDATE_COMPONENT_ATTRIBUTES,
  UPDATE_COMPONENT_HTMLTAGNAME,
  UPDATE_EXISTING_TEXT_ELEMENT,
  ADD_NEW_TEXT_ELEMENT,
  UPDATE_LIST_MARKER2,
  SELECT_IMG_FROM_GALLERY,
  UPDATE_BTN_LINK_TEXT,
  UPDATE_CUSTOMS_NUM_RATING_STARS,
  // xxx
  // Other
  CHANGE_SCREEN_SIZE,
} from "../actions/types";

import { CssVarsObj } from "../lib/css/CssVars";
import { getRandomId } from "../lib/domFunctions";
import {
  getComponentHtmlCss,
  populateElementIds,
  duplicate_getElementsRequiringUniqueClassnames,
  duplicate_renewClassnamesHtml,
  getRatingStarImg,
} from "../lib/parse";
import { reorderArray, getVersionNameFromIndex } from "../lib/generalFunctions";
import { TEXT_EDITOR_EDITABLE_CSS_PROPS } from "../lib/textEditorFunctions";
import { welcomeToEb } from "../lib/productTour/welcomeToEb";

const INIT_PAGE_ID = "5099803df3f4948bd2f98391";
const INIT_EBCONTENT = [
  {
    pageId: INIT_PAGE_ID,
    version: "A",
    components: [],
  },
];

const INIT_EBCUSTOMCSS = [];
const EMPTY_COLOR_PICKER_OBJ = {
  title: "",
  color: "",
  varName: "",
};

// Set initialState to an empty array
const initialState = {
  // Overall
  project: null,
  ebContent: INIT_EBCONTENT,
  ebCustomCss: INIT_EBCUSTOMCSS,
  ebCssVars: CssVarsObj,
  // Product tour
  productTourIsOpen: false,
  prevData: { ebContent: INIT_EBCONTENT, ebCustomCss: INIT_EBCUSTOMCSS, ebCssVars: CssVarsObj },
  // Drag & drop
  draggedId: -1,
  isDraggingFromMenu: false,
  idSelectedComponentFromMenu: "",
  // Edit component
  selectedElement: "",
  editFormsToShow: [],
  startStyles: null,
  // History / track changes
  trackChanges: [],
  posTrackChanges: 0,
  // Manage pages
  activePageId: INIT_PAGE_ID,
  activeVersion: "A", // change in activeVersion should change the activePageId. You probably don't even need this anymore; just take page.version from the activePage which is unique through its unique activePageId
  // Edit styles
  colorPicker: EMPTY_COLOR_PICKER_OBJ,
  // Other
  screen: "desktop",
};

const arrInsertAt = (arr, pos, itemToInsert) => [...arr.slice(0, pos), itemToInsert, ...arr.slice(pos)];
const arrMove = (arr, from, to) => {
  if (from === to) {
    return arr;
  }
  if (from < to) {
    return [...arr.slice(0, from), ...arr.slice(from + 1, to + 1), arr[from], ...arr.slice(to + 1)];
  }
  if (from > to) {
    return [...arr.slice(0, to), arr[from], ...arr.slice(to, from), ...arr.slice(from + 1)];
  }
};

// Reducer functionality
export default function eb(state = initialState, action) {
  // Destructure action
  const { type, payload } = action;

  // Re-used vars
  let prev;
  let splitSelectedComponentId;
  let splitElementId;
  let selectedElement;
  let customCss;
  let customClasses;
  let targetClassname;

  const getSelectedElement = (splitElementId) => {
    try {
      // 1) Get selected component
      let selectedElement = state.ebContent
        .filter((page) => page.pageId === state.activePageId)[0]
        .components.filter((component) => component.componentId === splitElementId[0])[0];
      // 2) Move down the component tree to the selected element
      for (let i = 1; i < splitElementId.length; i++) {
        selectedElement = selectedElement.children.filter((child) => child.childId === splitElementId[i])[0];
      }
      return selectedElement;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  switch (type) {
    // Manage project
    case LOAD_PROJECT:
      // payload = project object
      return {
        ...state,
        project: {
          _id: payload._id,
          projectName: payload.projectName,
          gallery: payload.gallery,
          wsId: payload.workspace,
          mailMeta: payload.mailMeta,
        },
        ebContent: payload.ebContent,
        ebCustomCss: payload.ebCustomCss,
        ebCssVars: payload.ebCssVars,
        activePageId: payload.ebContent[0].pageId,
        trackChanges: [],
        posTrackChanges: 0,
        selectedElement: "",
        editFormsToShow: [],
        startStyles: null,
      };
    case LOAD_TEMPLATE:
      // payload = { ebContent, ebCustomCss, ebCssVars }
      return {
        ...state,
        ebContent: payload.ebContent,
        ebCustomCss: payload.ebCustomCss,
        ebCssVars: payload.ebCssVars,
        activePageId: payload.ebContent[0].pageId,
        trackChanges: [],
        posTrackChanges: 0,
        selectedElement: "",
        editFormsToShow: [],
        startStyles: null,
      };
    case UPDATE_PROJECT_GALLERY:
      // payload = gallery array
      return {
        ...state,
        project: { ...state.project, gallery: payload },
      };
    case UPDATE_GALLERY_FILE_DESC:
      // payload = { fileId, newDesc }
      return {
        ...state,
        project: {
          ...state.project,
          gallery: state.project.gallery.map((item) => (item._id === payload.fileId ? { ...item, fileDesc: payload.newDesc } : item)),
        },
      };
    case UPDATE_MAILMETA:
      // payload = { metaVariable, metaValue }
      return {
        ...state,
        project: { ...state.project, mailMeta: { ...state.project.mailMeta, [payload.metaVariable]: payload.metaValue } },
      };
    // Product tour
    case OPEN_PRODUCT_TOUR:
      let prevData = {
        ebContent: structuredClone(state.ebContent),
        ebCustomCss: structuredClone(state.ebCustomCss),
        ebCssVars: structuredClone(state.ebCssVars),
      };
      return state.productTourIsOpen
        ? state
        : {
            ...state,
            ebContent: welcomeToEb.ebContent,
            ebCustomCss: welcomeToEb.ebCustomCss,
            ebCssVars: welcomeToEb.ebCssVars,
            prevData,
            productTourIsOpen: true,
          };
    case CLOSE_PRODUCT_TOUR:
      return state.productTourIsOpen
        ? {
            ...state,
            ebContent: state.prevData.ebContent,
            ebCustomCss: state.prevData.ebCustomCss,
            ebCssVars: state.prevData.ebCssVars,
            prevData: { ebContent: INIT_EBCONTENT, ebCustomCss: INIT_EBCUSTOMCSS, ebCssVars: CssVarsObj },
            productTourIsOpen: false,
          }
        : state;
    // Drag & drop
    case START_DRAG_FROM_MENU:
      return {
        ...state,
        // Payload is the componentId
        isDraggingFromMenu: true,
        idSelectedComponentFromMenu: payload,
      };
    case END_DRAG_FROM_MENU:
      return {
        ...state,
        isDraggingFromMenu: false,
        idSelectedComponentFromMenu: "",
      };
    case DROP_COMPONENT:
      prev = {
        // ebContent: structuredClone(state.ebContent.filter((page) => page.pageId === state.activePageId)[0].components),
        ebContent: structuredClone(state.ebContent),
        ebCustomCss: structuredClone(state.ebCustomCss),
      };
      let newComponentId = getRandomId();
      let { html, css } = getComponentHtmlCss(state.idSelectedComponentFromMenu, newComponentId);
      let activePageComponents = state.ebContent.filter((page) => page.pageId === state.activePageId)[0].components;
      activePageComponents = arrInsertAt(activePageComponents, payload, html);
      return {
        ...state,
        // Payload = posToInsert
        ebContent: state.ebContent.map((page) => (page.pageId === state.activePageId ? { ...page, components: activePageComponents } : page)),
        ebCustomCss: [...state.ebCustomCss, css],
        newlyAddedComponent: html,
        draggedId: -1,
        trackChanges: [prev, ...state.trackChanges.slice(state.posTrackChanges, 9)],
        posTrackChanges: 0,
      };
    // Edit component
    case REORDER_COMPONENT:
      // Payload = { componentId, direction }
      // Save current version for track changes history
      prev = {
        // ebContent: structuredClone(state.ebContent.filter((page) => page.pageId === state.activePageId)[0].components),
        ebContent: structuredClone(state.ebContent),
        ebCustomCss: structuredClone(state.ebCustomCss),
      };
      // Prepare reordered array
      let reorderedComponents = structuredClone(state.ebContent.filter((page) => page.pageId === state.activePageId)[0].components);
      let from = reorderedComponents.map((component) => component.componentId).indexOf(payload.componentId);
      let to = from + payload.direction;
      // if (from + to >= 0 && from + to < reorderedComponents.length) {
      if (to >= 0 && to <= reorderedComponents.length) {
        return {
          ...state,
          ebContent: state.ebContent.map((page) =>
            page.pageId === state.activePageId ? { ...page, components: reorderArray(reorderedComponents, from, to) } : page
          ),
          trackChanges: [prev, ...state.trackChanges.slice(state.posTrackChanges, 9)],
          posTrackChanges: 0,
        };
      } else {
        return {
          ...state,
        };
      }
    case DELETE_COMPONENT:
      // Save current version for track changes history
      prev = {
        // ebContent: structuredClone(state.ebContent.filter((page) => page.pageId === state.activePageId)[0].components),
        ebContent: structuredClone(state.ebContent),
        ebCustomCss: structuredClone(state.ebCustomCss),
      };
      return {
        ...state,
        // Payload is componentId
        ebContent: state.ebContent.map((page) =>
          page.pageId === state.activePageId
            ? { ...page, components: page.components.filter((component) => component.componentId !== payload) }
            : page
        ),
        trackChanges: [prev, ...state.trackChanges.slice(state.posTrackChanges, 9)],
        posTrackChanges: 0,
      };
    case SET_SELECTED_ELEMENT:
      return {
        ...state,
        // Payload is the data-id of the selectedElement
        selectedElement: payload,
        editFormsToShow: payload === "" ? [] : state.editFormsToShow,
      };
    // Manage elements
    case DELETE_ELEMENT:
      prev = {
        // ebContent: structuredClone(state.ebContent.filter((page) => page.pageId === state.activePageId)[0].components),
        ebContent: structuredClone(state.ebContent),
        ebCustomCss: structuredClone(state.ebCustomCss),
      };
      // payload is concatenated elementId (i.e., "xx-xx-xx-...")
      splitSelectedComponentId = payload.split("-");
      let deleteElement = (target, deleteElementId, i) => {
        // Remove the specified element from the ebContent stack
        // 1) find the parent of the deleteElementId and 2) remove the deleteElementId from the parent's children array
        if (target.componentId === splitSelectedComponentId[i] || target.childId === splitSelectedComponentId[i]) {
          if (typeof splitSelectedComponentId[i + 2] !== "undefined") {
            // If 2 levels deeper exist, you're not at the parent yet
            return { ...target, children: target.children.map((child) => deleteElement(child, deleteElementId, i + 1)) };
          } else {
            // This is the selectedElement's parent => remove the element that's to be deleted from its children array
            return { ...target, children: target.children.filter((child) => child.childId !== deleteElementId) };
          }
        } else {
          return target;
        }
      };
      return {
        ...state,
        ebContent: state.ebContent.map((page) => ({
          ...page,
          components: page.components.map((component) =>
            component.componentId === splitSelectedComponentId[0]
              ? deleteElement(component, splitSelectedComponentId[splitSelectedComponentId.length - 1], 0)
              : component
          ),
        })),
        trackChanges: [prev, ...state.trackChanges.slice(state.posTrackChanges, 9)],
        posTrackChanges: 0,
      };
    case DUPLICATE_ELEMENT:
      prev = {
        // ebContent: structuredClone(state.ebContent.filter((page) => page.pageId === state.activePageId)[0].components),
        ebContent: structuredClone(state.ebContent),
        ebCustomCss: structuredClone(state.ebCustomCss),
      };
      // payload is concatenated elementId of the ebtype=element (i.e., "xx-xx-xx-...")
      splitSelectedComponentId = payload.split("-");
      // Set up array of classnames that need to be unique
      let duplicateUniqueClassnames = [];
      // Duplicate element in ebContent
      let duplicateElement = (target, elementId, i) => {
        // Copy the specified element and add the copied element next to the specified element
        // 1) find the parent of the elementId, 2) find the pos of elementId,
        // 3) generate copy of elementId and update IDs of it and its children and 4) insert copy of elementId after elementId
        if (target.componentId === splitSelectedComponentId[i] || target.childId === splitSelectedComponentId[i]) {
          if (typeof splitSelectedComponentId[i + 2] !== "undefined") {
            // If 2 levels deeper exist, you're not at the parent yet
            return { ...target, children: target.children.map((child) => duplicateElement(child, elementId, i + 1)) };
          } else {
            // This is the selectedElement's parent
            // Find position of elementId
            let pos = target.children.map((child) => child.childId).indexOf(splitSelectedComponentId[i + 1]);
            // Get copy of element to be inserted and update IDs
            let copiedElementToAdd = populateElementIds(structuredClone(target.children.filter((child) => child.childId === elementId)[0]), true);
            // Get classnames that need to be unique
            duplicateUniqueClassnames = duplicate_getElementsRequiringUniqueClassnames(
              structuredClone(target.children.filter((child) => child.childId === elementId)[0]),
              state.ebCustomCss.filter((component) => component.componentId === splitSelectedComponentId[0])[0].classes
            );
            copiedElementToAdd = duplicate_renewClassnamesHtml(copiedElementToAdd, duplicateUniqueClassnames);
            // Insert copied element
            return { ...target, children: arrInsertAt(target.children, pos + 1, copiedElementToAdd) };
          }
        } else {
          return target;
        }
      };
      // Duplicate css in ebCustomCss
      let duplicateCss = (classes) => {
        // Get all css items whose className need to be updated
        let cssToDuplicate = classes.filter((item) =>
          duplicateUniqueClassnames.map((uniqueClassname) => uniqueClassname.oldClassname).includes(item.className)
        );
        // Update the classNames
        cssToDuplicate = cssToDuplicate.map((item) => ({
          ...item,
          className: duplicateUniqueClassnames.filter((uniqueClassname) => uniqueClassname.oldClassname === item.className)[0].newClassname,
        }));
        return cssToDuplicate;
      };
      return {
        ...state,
        // ebContent: state.ebContent.map((page) =>
        //   page.pageId === state.activePageId
        //     ? {
        //         ...page,
        //         components: page.components.map((component) =>
        //           component.componentId === splitSelectedComponentId[0]
        //             ? duplicateElement(component, splitSelectedComponentId[splitSelectedComponentId.length - 1], 0)
        //             : component
        //         ),
        //       }
        //     : page
        // ),
        ebContent: state.ebContent.map((page) => ({
          ...page,
          components: page.components.map((component) =>
            component.componentId === splitSelectedComponentId[0]
              ? duplicateElement(component, splitSelectedComponentId[splitSelectedComponentId.length - 1], 0)
              : component
          ),
        })),
        ebCustomCss: state.ebCustomCss.map((customCssState) =>
          customCssState.componentId === splitSelectedComponentId[0]
            ? {
                ...customCssState,
                classes: [...customCssState.classes, ...duplicateCss(customCssState.classes)],
              }
            : customCssState
        ),
        trackChanges: [prev, ...state.trackChanges.slice(state.posTrackChanges, 9)],
        posTrackChanges: 0,
      };
    case REORDER_COL:
      prev = {
        ebContent: structuredClone(state.ebContent),
        ebCustomCss: structuredClone(state.ebCustomCss),
      };
      // payload is concatenated elementId (i.e., "xx-xx-xx-...")
      splitSelectedComponentId = payload.split("-");
      let reorderCol = (target, reorderColId, i) => {
        // 1) find the .column-container
        if (target.componentId === splitSelectedComponentId[i] || target.childId === splitSelectedComponentId[i]) {
          if (typeof splitSelectedComponentId[i + 2] !== "undefined") {
            // If 2 levels deeper exist, you're not at the parent yet
            return { ...target, children: target.children.map((child) => reorderCol(child, reorderColId, i + 1)) };
          } else {
            // This is the .column-container
            // Get number of .columns and current position of .column to be reordered
            let from = target.children.map((child) => child.childId).indexOf(splitSelectedComponentId[i + 1]);
            let to = from - 1;
            from === 0 && (to = target.children.length - 1);
            return {
              ...target,
              children: arrMove(target.children, from, to),
            };
          }
        } else {
          return target;
        }
      };
      return {
        ...state,
        ebContent: state.ebContent.map((page) => ({
          ...page,
          components: page.components.map((component) =>
            component.componentId === splitSelectedComponentId[0]
              ? reorderCol(component, splitSelectedComponentId[splitSelectedComponentId.length - 1], 0)
              : component
          ),
        })),
        trackChanges: [prev, ...state.trackChanges.slice(state.posTrackChanges, 9)],
        posTrackChanges: 0,
      };
    // Manage pages
    case ADD_NEW_VERSION:
      // payload = { pageToAdd, customCssToAdd }
      return {
        ...state,
        ebContent: [...state.ebContent, payload.pageToAdd],
        ebCustomCss: [...state.ebCustomCss, ...payload.customCssToAdd],
      };
    case SET_ACTIVE_PAGE:
      // payload = pageId
      return {
        ...state,
        activePageId: payload,
      };
    case DELETE_VERSION:
      return {
        ...state,
        // Payload = pageId
        // reset version names
        ebContent: state.ebContent.filter((page) => page.pageId !== payload).map((page, i) => ({ ...page, version: getVersionNameFromIndex(i + 1) })),
        activePageId: state.activePageId === payload ? state.ebContent.filter((page) => page.pageId !== payload)[0].pageId : state.activePageId,
      };
    // Track changes
    case SAVE_TRACK_CHANGES:
      prev = {
        // ebContent: structuredClone(state.ebContent.filter((page) => page.pageId === state.activePageId)[0].components),
        ebContent: structuredClone(state.ebContent),
        ebCustomCss: structuredClone(state.ebCustomCss),
      };
      return {
        ...state,
        trackChanges: [prev, ...state.trackChanges.slice(state.posTrackChanges, 9)],
        posTrackChanges: 0,
      };
    case UNDO_REDO:
      // Payload is either +1 to undo a change or -1 to redo a change
      let restoreTo =
        payload === 1
          ? structuredClone(state.trackChanges[state.posTrackChanges])
          : structuredClone(state.trackChanges[state.posTrackChanges + payload]);
      // If posTrackChanges === 0 & undo was clicked: Save current version into trackChanges so the undo can be restored through redo
      if (state.posTrackChanges === 0 && payload === 1) {
        let curr = {
          // ebContent: structuredClone(state.ebContent.filter((page) => page.pageId === state.activePageId)[0].components),
          ebContent: structuredClone(state.ebContent),
          ebCustomCss: structuredClone(state.ebCustomCss),
        };
        return {
          ...state,
          // ebContent: state.ebContent.map((page) => (page.pageId === state.activePageId ? { ...page, components: restoreTo.ebContent } : page)),
          ebContent: restoreTo.ebContent,
          ebCustomCss: restoreTo.ebCustomCss,
          posTrackChanges: state.posTrackChanges + payload,
          trackChanges: [curr, ...state.trackChanges],
        };
      }
      // If posTrackChanges === 1 & redo was clicked: remove trackChanges[0] from the stack, as that was only added to allow restoration via redo
      if (state.posTrackChanges === 1 && payload === -1) {
        return {
          ...state,
          // ebContent: state.ebContent.map((page) => (page.pageId === state.activePageId ? { ...page, components: restoreTo.ebContent } : page)),
          ebContent: restoreTo.ebContent,
          ebCustomCss: restoreTo.ebCustomCss,
          posTrackChanges: state.posTrackChanges + payload,
          trackChanges: state.trackChanges.slice(1),
        };
      }
      // Else: Normal behavior
      else {
        return {
          ...state,
          // ebContent: state.ebContent.map((page) => (page.pageId === state.activePageId ? { ...page, components: restoreTo.ebContent } : page)),
          ebContent: restoreTo.ebContent,
          ebCustomCss: restoreTo.ebCustomCss,
          posTrackChanges: state.posTrackChanges + payload,
        };
      }
    // Edit styles
    case START_EDITING:
      return {
        ...state,
        editFormsToShow: payload.editFormsToShow,
        startStyles: payload.startStyles,
      };
    case OPEN_COLOR_PICKER:
      return {
        ...state,
        // Payload = { title, color, varName }
        colorPicker: {
          title: payload.title,
          color: payload.color,
          varName: payload.varName,
        },
      };
    case UPDATE_CSS_VAR:
      return {
        ...state,
        ebCssVars: { ...state.ebCssVars, [payload.varName]: payload.value },
        colorPicker: EMPTY_COLOR_PICKER_OBJ,
      };
    case CONFIRM_COLOR_SCHEME:
      // payload = [ "rgb(x, x, x)", "rgb(x, x, x)", "rgb(x, x, x)", "rgb(x, x, x)", "rgb(x, x, x)" ]
      return {
        ...state,
        ebCssVars: { ...state.ebCssVars, color1: payload[0], color2: payload[1], color3: payload[2], color4: payload[3], color5: payload[4] },
      };
    case CHANGE_MAIL_COLORS:
      // payload is [ { varName: "color1-10", newRgb: "x, x, x" } ]
      // Add oldRgb value to the array
      let changeMailColorRgbs = payload.map((color) => ({
        ...color,
        oldRgb: (state.ebCssVars[color.varName].match(/\((\d+, \d+, \d+).+?\)/) || ["", "0, 0, 0"])[1],
      }));
      return {
        ...state,
        ebCustomCss: state.ebCustomCss.map((componentCss) => ({
          ...componentCss,
          classes: componentCss.classes.map((className) => ({
            ...className,
            rules: className.rules.map((rule) => {
              if (rule.value.toString().includes("rgb(")) {
                let val = rule.value;
                changeMailColorRgbs.forEach((color) => {
                  if (rule.value.includes(color.oldRgb)) {
                    val = rule.value.replace(color.oldRgb, color.newRgb);
                  }
                });
                return { ...rule, value: val };
              } else {
                return rule;
              }
            }),
          })),
        })),
      };
    case UPDATE_COMPONENT_CSS:
      // payload = { splitElementId, updatedCssRules } => updatedCssRules has format: [ { property: "margin", pseudo: "", value: "0px 0px 0.5px 8px" } ]
      // Get the selected component ID
      splitElementId = payload.splitElementId;
      // Get selected element
      selectedElement = getSelectedElement(splitElementId);
      // Get selected element classname that is to be updated
      customCss = state.ebCustomCss.filter((customCss) => customCss.componentId === state.selectedElement.split("-")[0])[0].classes;
      customClasses = customCss.map((custom) => custom.className);
      targetClassname = selectedElement.classes.filter((className) => customClasses.includes(className))[0];
      if (typeof targetClassname === "undefined") {
        // If targetClassname is undefined, it means the classname doesn't have rules set yet => take selectedElement's 1st class as targetClassname
        targetClassname = selectedElement.classes[0];
      }
      // Get existing element class css rules
      customCss = customCss.filter((custom) => custom.className === targetClassname);
      if (customCss.length === 0) {
        // Found targetClassname doesn't have rules yet => add them
        customCss = [
          {
            className: targetClassname,
            pseudo: "",
            rules: payload.updatedCssRules.filter((rule) => rule.pseudo === "").map((rule) => ({ property: rule.property, value: rule.value })),
          },
          {
            className: targetClassname,
            pseudo: "hover",
            rules: payload.updatedCssRules.filter((rule) => rule.pseudo === "hover").map((rule) => ({ property: rule.property, value: rule.value })),
          },
        ];
      } else {
        // Found targetClassname has existing rules =>
        // 1) Remove old rules
        customCss = customCss.map((custom) => {
          // Get updated rules of same pseudo
          let updatedRulesCssProps = payload.updatedCssRules.filter((rule) => rule.pseudo === custom.pseudo).map((rule) => rule.property);
          // Remove updatedRules CSS properties
          return { ...custom, rules: custom.rules.filter((rule) => !updatedRulesCssProps.includes(rule.property)) };
        });
        // 2) Add new rules
        customCss = customCss.map((custom) => ({
          ...custom,
          rules: [
            ...custom.rules,
            ...payload.updatedCssRules
              .filter((rule) => rule.pseudo === custom.pseudo)
              .map((rule) => ({ property: rule.property, value: rule.value })),
          ],
        }));
      }
      // Update in state
      return {
        ...state,
        ebCustomCss: state.ebCustomCss.map((customCssState) =>
          customCssState.componentId === state.selectedElement.split("-")[0]
            ? {
                ...customCssState,
                classes: [...customCssState.classes.filter((customClass) => customClass.className !== targetClassname), ...customCss],
              }
            : customCssState
        ),
      };
    case UPDATE_COMPONENT_CLASSES:
      // payload = { splitElementId, updatedClasses } => updatedClasses = [ { oldClassname: "container", newClassname: "container-fluid" }, ... ]
      // Get the selected component ID
      splitSelectedComponentId = payload.splitElementId;
      let updateComponentClasses = (target, updatedClasses, i) => {
        if (target.componentId === splitSelectedComponentId[i] || target.childId === splitSelectedComponentId[i]) {
          if (typeof splitSelectedComponentId[i + 1] !== "undefined") {
            // A level deeper exists => call recursively
            return { ...target, children: target.children.map((child) => updateComponentClasses(child, updatedClasses, i + 1)) };
          } else {
            let classesToRemove = updatedClasses.map((item) => item.oldClassname);
            let classesToAdd = updatedClasses.map((item) => item.newClassname);
            return { ...target, classes: [...target.classes.filter((className) => !classesToRemove.includes(className)), ...classesToAdd] };
          }
        } else {
          return target;
        }
      };
      return {
        ...state,
        // ebContent: state.ebContent.map((page) =>
        //   page.pageId === state.activePageId
        //     ? {
        //         ...page,
        //         components: page.components.map((component) =>
        //           component.componentId === splitSelectedComponentId[0] ? updateComponentClasses(component, payload.updatedClasses, 0) : component
        //         ),
        //       }
        //     : page
        // ),
        ebContent: state.ebContent.map((page) => ({
          ...page,
          components: page.components.map((component) =>
            component.componentId === splitSelectedComponentId[0] ? updateComponentClasses(component, payload.updatedClasses, 0) : component
          ),
        })),
      };
    case UPDATE_COMPONENT_ATTRIBUTES:
      // payload = { splitElementId, updatedAttributes } => updatedAttributes = [ { attr: "id", oldVal: "xxx", newVal: "yyy" }, ... ]
      // Get the selected component ID
      splitSelectedComponentId = payload.splitElementId;
      let updateComponentAttributes = (target, updatedAttributes, i) => {
        if (target.componentId === splitSelectedComponentId[i] || target.childId === splitSelectedComponentId[i]) {
          if (typeof splitSelectedComponentId[i + 1] !== "undefined") {
            // A level deeper exists => call recursively
            return { ...target, children: target.children.map((child) => updateComponentAttributes(child, updatedAttributes, i + 1)) };
          } else {
            let attrsToRemove = updatedAttributes.map((item) => item.attr);
            let attrsToAdd = updatedAttributes.map((item) => ({ property: item.attr, value: item.newVal }));
            return { ...target, attributes: [...target.attributes.filter((attr) => !attrsToRemove.includes(attr.property)), ...attrsToAdd] };
          }
        } else {
          return target;
        }
      };
      return {
        ...state,
        ebContent: state.ebContent.map((page) => ({
          ...page,
          components: page.components.map((component) =>
            component.componentId === splitSelectedComponentId[0] ? updateComponentAttributes(component, payload.updatedAttributes, 0) : component
          ),
        })),
      };
    case UPDATE_COMPONENT_HTMLTAGNAME:
      // payload = { splitElementId, updatedHtmlTagname } => updatedHtmlTagname = { oldVal: "xxx", newVal: "yyy" }
      // Get the selected component ID
      splitSelectedComponentId = payload.splitElementId;
      let updateComponentHtmlTagname = (target, updatedHtmlTagname, i) => {
        if (target.componentId === splitSelectedComponentId[i] || target.childId === splitSelectedComponentId[i]) {
          if (typeof splitSelectedComponentId[i + 1] !== "undefined") {
            // A level deeper exists => call recursively
            return { ...target, children: target.children.map((child) => updateComponentHtmlTagname(child, updatedHtmlTagname, i + 1)) };
          } else {
            return { ...target, htmlTagName: updatedHtmlTagname.newVal };
          }
        } else {
          return target;
        }
      };
      return {
        ...state,
        ebContent: state.ebContent.map((page) => ({
          ...page,
          components: page.components.map((component) =>
            component.componentId === splitSelectedComponentId[0] ? updateComponentHtmlTagname(component, payload.updatedHtmlTagname, 0) : component
          ),
        })),
      };
    case UPDATE_EXISTING_TEXT_ELEMENT:
      // payload = { elementObj, cssRules }
      // elementObj
      splitSelectedComponentId = state.selectedElement.split("-");
      let updateTextElement = (target, elementObj, i) => {
        if (target.componentId === splitSelectedComponentId[i] || target.childId === splitSelectedComponentId[i]) {
          if (typeof splitSelectedComponentId[i + 1] !== "undefined") {
            // A level deeper exists => call recursively
            return { ...target, children: target.children.map((child) => updateTextElement(child, elementObj, i + 1)) };
          } else {
            return elementObj;
          }
        } else {
          return target;
        }
      };
      // Determine which css style props can be updated
      let canUpdExtTextElStyleProp = (cssProp) => {
        if (payload.elementObj.htmlTagName === "a") {
          return !TEXT_EDITOR_EDITABLE_CSS_PROPS.filter(
            (prop) => !["text-decoration", "color", "background", "background-color"].includes(prop)
          ).includes(cssProp);
        }
        return !TEXT_EDITOR_EDITABLE_CSS_PROPS.includes(cssProp);
      };
      // cssRules
      customCss = state.ebCustomCss.filter((customCss) => customCss.componentId === splitSelectedComponentId[0])[0].classes;
      customClasses = customCss.map((custom) => custom.className);
      targetClassname = payload.elementObj.classes.filter((className) => customClasses.includes(className))[0];
      return {
        ...state,
        ebContent: state.ebContent.map((page) => ({
          ...page,
          components: page.components.map((component) =>
            component.componentId === splitSelectedComponentId[0] ? updateTextElement(component, payload.elementObj, 0) : component
          ),
        })),
        ebCustomCss: state.ebCustomCss.map((customCssState) =>
          customCssState.componentId === splitSelectedComponentId[0]
            ? {
                ...customCssState,
                classes: customCssState.classes.map((item) =>
                  item.className === targetClassname && item.pseudo === ""
                    ? {
                        ...item,
                        // rules: [...item.rules.filter((rule) => !TEXT_EDITOR_EDITABLE_CSS_PROPS.includes(rule.property)), ...payload.cssRules],
                        rules: [...item.rules.filter((rule) => canUpdExtTextElStyleProp(rule.property)), ...payload.cssRules],
                      }
                    : item
                ),
              }
            : customCssState
        ),
      };
    case ADD_NEW_TEXT_ELEMENT:
      // payload = { elementObj, cssRules }
      splitSelectedComponentId = state.selectedElement.split("-");
      let addTextElement = (target, textElementObjToAdd, i) => {
        // Add the dynamically created text element after the text element that was being edited (= selectedElement)
        // So, you need to 1) find the parent of the selectedElement, 2) find selectedElement's position within its parent's children array and
        //     3) insert the new text element after that position
        if (target.componentId === splitSelectedComponentId[i] || target.childId === splitSelectedComponentId[i]) {
          if (typeof splitSelectedComponentId[i + 2] !== "undefined") {
            // If 2 levels deeper exist, you're not at the parent yet
            return { ...target, children: target.children.map((child) => addTextElement(child, textElementObjToAdd, i + 1)) };
          } else {
            // This is the selectedElement's parent => find selectedElement's position within the children array and add new text element after
            let pos = target.children.map((child) => child.childId).indexOf(splitSelectedComponentId[i + 1]);
            // Get the text element that is being split up in multiple pieces (= selectedElement)
            let textElementBeingDuplicated = target.children.filter((child) => child.childId === splitSelectedComponentId[i + 1])[0];
            // Get that text element's non-unique classes (e.g. .w-[x])
            customClasses = state.ebCustomCss
              .filter((customCss) => customCss.componentId === splitSelectedComponentId[0])[0]
              .classes.map((custom) => custom.className);
            let textElementBeingDuplicated_classes = textElementBeingDuplicated.classes.filter((className) => !customClasses.includes(className));
            // Add those non-unique classes to the textElementObjToAdd
            return {
              ...target,
              children: arrInsertAt(target.children, pos + 1, {
                ...textElementObjToAdd,
                classes: [...textElementObjToAdd.classes, ...textElementBeingDuplicated_classes],
              }),
            };
          }
        } else {
          return target;
        }
      };
      // cssRules
      let classesToAdd = [
        { className: payload.elementObj.classes[0], pseudo: "", rules: payload.cssRules },
        { className: payload.elementObj.classes[0], pseudo: "hover", rules: [] },
      ];
      return {
        ...state,
        ebContent: state.ebContent.map((page) => ({
          ...page,
          components: page.components.map((component) =>
            component.componentId === splitSelectedComponentId[0] ? addTextElement(component, payload.elementObj, 0) : component
          ),
        })),
        ebCustomCss: state.ebCustomCss.map((customCssState) =>
          customCssState.componentId === splitSelectedComponentId[0]
            ? {
                ...customCssState,
                classes: [...customCssState.classes, ...classesToAdd],
              }
            : customCssState
        ),
      };
    case UPDATE_LIST_MARKER2:
      // payload = { splitElementId, cssRules }
      splitElementId = payload.splitElementId;
      // Get selected element
      selectedElement = getSelectedElement(splitElementId);
      // Get selected element classname that is to be updated
      customCss = state.ebCustomCss.filter((customCss) => customCss.componentId === splitElementId[0])[0].classes;
      customClasses = customCss.map((custom) => custom.className);
      targetClassname = selectedElement.classes.filter((className) => customClasses.includes(className))[0];
      return {
        ...state,
        ebCustomCss: state.ebCustomCss.map((customCssState) =>
          customCssState.componentId === state.selectedElement.split("-")[0]
            ? {
                ...customCssState,
                classes: customCssState.classes.map((className) =>
                  className.className === targetClassname
                    ? {
                        ...className,
                        rules: [
                          ...className.rules.filter((rule) => !payload.cssRules.map((rule) => rule.property).includes(rule.property)),
                          ...payload.cssRules,
                        ],
                      }
                    : className
                ),
              }
            : customCssState
        ),
      };
    case SELECT_IMG_FROM_GALLERY:
      // payload = { newSrc, targetStartStyleProp, fileDesc }
      // targetStartStyleProp = "imgSource" || "parallaxImgSrc"
      return {
        ...state,
        startStyles: { ...state.startStyles, [payload.targetStartStyleProp]: payload.newSrc, imgDesc: payload.fileDesc },
      };
    case UPDATE_BTN_LINK_TEXT:
      // payload = { splitElementId, custom: { property, oldVal, newVal } }
      splitSelectedComponentId = payload.splitElementId;
      let updateButtonText = (target, custom, i) => {
        if (target.componentId === splitSelectedComponentId[i] || target.childId === splitSelectedComponentId[i]) {
          if (typeof splitSelectedComponentId[i + 1] !== "undefined") {
            // A level deeper exists => call recursively
            return { ...target, children: target.children.map((child) => updateButtonText(child, custom, i + 1)) };
          } else {
            // Found selectedElement = button => find its textNode child and update its content
            return {
              ...target,
              children: target.children.map((child) =>
                child.htmlTagName === "textNode"
                  ? {
                      ...child,
                      content: custom.newVal,
                    }
                  : child
              ),
            };
          }
        } else {
          return target;
        }
      };
      return {
        ...state,
        ebContent: state.ebContent.map((page) => ({
          ...page,
          components: page.components.map((component) =>
            component.componentId === splitSelectedComponentId[0] ? updateButtonText(component, payload.custom, 0) : component
          ),
        })),
      };
    case UPDATE_CUSTOMS_NUM_RATING_STARS:
      // payload = { splitElementId, custom: { property, oldVal, newVal } }
      splitSelectedComponentId = payload.splitElementId;
      let setNumRatingStars = (target, custom, i) => {
        if (target.componentId === splitSelectedComponentId[i] || target.childId === splitSelectedComponentId[i]) {
          if (typeof splitSelectedComponentId[i + 1] !== "undefined") {
            // A level deeper exists => call recursively
            return { ...target, children: target.children.map((child) => setNumRatingStars(child, custom, i + 1)) };
          } else {
            // Found selectedElement = wrapper div => replace children
            let starImgClasses = target.children[0].classes;
            let newChildren = [];
            // Add full stars
            for (let i = 0; i < parseInt(custom.newVal); i++) {
              newChildren.push(getRatingStarImg("full", starImgClasses));
            }
            if (parseFloat(custom.newVal) % 1 > 0) {
              newChildren.push(getRatingStarImg("half", starImgClasses));
            }
            let toAdd = 5 - newChildren.length;
            for (let i = 1; i <= toAdd; i++) {
              newChildren.push(getRatingStarImg("empty", starImgClasses));
            }
            return {
              ...target,
              children: newChildren,
            };
          }
        } else {
          return target;
        }
      };
      return {
        ...state,
        ebContent: state.ebContent.map((page) => ({
          ...page,
          components: page.components.map((component) =>
            component.componentId === splitSelectedComponentId[0] ? setNumRatingStars(component, payload.custom, 0) : component
          ),
        })),
      };
    // Other
    case CHANGE_SCREEN_SIZE:
      return {
        ...state,
        // Payload is screenSize
        screen: payload,
      };
    default:
      return state;
  }
}
