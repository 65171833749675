export const template5 = {
  id: "66033b9b13e86bffe3111da4",
  name: { en: "Invite to a conference", nl: "Uitnodiging voor evenement" },
  desc: { en: "Invite to an email marketing conference", nl: "Uitnodiging voor een conferentie over email marketing" },
  categories: ["event"],
  ebContent: [
    {
      pageId: "PriezJ2qmb",
      version: "A",
      components: [
        {
          componentId: "RyGhrF",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "basics-7-component-RyGhrF"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "KUpgtc",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "basics-7-col-container-RyGhrF"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "zcR44C",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-12"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "YleG9m",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "NZqV9w",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "QPc1Vl",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "jjzMmn",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["basics-7-col-td-wrapper-RyGhrF"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-name", value: "divider" },
                                    { property: "data-editable", value: "true" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "cF3vNg",
                                      type: "",
                                      htmlTagName: "div",
                                      classes: ["basics-7-divider-RyGhrF"],
                                      styles: [],
                                      attributes: [{ property: "data-elementgetter1", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "LWyyFp",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "basics-1-component-LWyyFp"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "kDejxj",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "basics-1-col-container-LWyyFp"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "FN9Phv",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-12"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "XG1YBX",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "gS5f2p",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "Boyd4I",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "DRwSov",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["basics-1-col-td-wrapper-LWyyFp"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "uCQie4",
                                      type: "",
                                      htmlTagName: "h1",
                                      classes: ["basics-1-title-LWyyFp"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "h" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Trends in email marketing",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "p91zNU",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "basics-2-component-p91zNU"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "eg5hQT",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "basics-2-col-container-p91zNU"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "pI8MGX",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-12"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "o6QjHj",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "YyJlxn",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "PR3PRg",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "IAkzf0",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["basics-2-col-td-wrapper-p91zNU"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "hneiFy",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["basics-2-text-p91zNU"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Join the best conference of the year and learn from industry experts.",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "N8SJ4E",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "basics-5-component-N8SJ4E"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "KB63ul",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "basics-5-col-container-N8SJ4E"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "tpyX3H",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-12"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "BiOgBR",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "zzlZ44",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "YL38yA",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "dpX4TU",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["basics-5-col-td-wrapper-N8SJ4E"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "vQe2mk",
                                      type: "",
                                      htmlTagName: "table",
                                      classes: ["column-table"],
                                      styles: [],
                                      attributes: [
                                        { property: "role", value: "presentation" },
                                        { property: "width", value: "100%" },
                                        { property: "cellspacing", value: "0" },
                                        { property: "cellpadding", value: "0" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "JMlyk4",
                                          type: "block",
                                          htmlTagName: "tbody",
                                          classes: [],
                                          styles: [],
                                          attributes: [{ property: "data-name", value: "block" }],
                                          content: "",
                                          children: [
                                            {
                                              childId: "U5Y7rx",
                                              type: "element",
                                              htmlTagName: "tr",
                                              classes: [],
                                              styles: [],
                                              attributes: [],
                                              content: "",
                                              children: [
                                                {
                                                  childId: "DJnCcD",
                                                  type: "",
                                                  htmlTagName: "td",
                                                  classes: ["basics-5-list-tdwrapper-N8SJ4E"],
                                                  styles: [],
                                                  attributes: [],
                                                  content: "",
                                                  children: [
                                                    {
                                                      childId: "HB5ykT",
                                                      type: "",
                                                      htmlTagName: "div",
                                                      classes: ["basics-5-listwrapper-N8SJ4E"],
                                                      styles: [],
                                                      attributes: [
                                                        { property: "data-name", value: "div" },
                                                        { property: "data-editable", value: "false" },
                                                        { property: "data-texteditable", value: "false" },
                                                        { property: "data-listwrapper", value: "true" },
                                                      ],
                                                      content: "",
                                                      children: [
                                                        {
                                                          childId: "bK7Sn6",
                                                          type: "",
                                                          htmlTagName: "span",
                                                          classes: ["basics-5-listbullet-N8SJ4E"],
                                                          styles: [],
                                                          attributes: [
                                                            { property: "data-name", value: "span" },
                                                            { property: "data-editable", value: "false" },
                                                            { property: "data-listbullet", value: "true" },
                                                          ],
                                                          content: "",
                                                          children: [],
                                                        },
                                                        {
                                                          childId: "hCzbRW",
                                                          type: "",
                                                          htmlTagName: "p",
                                                          classes: ["basics-5-list-text-N8SJ4E"],
                                                          styles: [],
                                                          attributes: [
                                                            { property: "data-name", value: "p" },
                                                            { property: "data-editable", value: "true" },
                                                            { property: "data-texteditable", value: "true" },
                                                            { property: "data-listtext", value: "true" },
                                                          ],
                                                          content: "",
                                                          children: [
                                                            {
                                                              childId: "",
                                                              type: "",
                                                              htmlTagName: "textNode",
                                                              classes: [],
                                                              styles: [],
                                                              attributes: [],
                                                              content: "Connect with other marketeers ",
                                                              children: [],
                                                            },
                                                          ],
                                                        },
                                                      ],
                                                    },
                                                  ],
                                                },
                                              ],
                                            },
                                            {
                                              childId: "fJAtSd",
                                              type: "element",
                                              htmlTagName: "tr",
                                              classes: [],
                                              styles: [],
                                              attributes: [],
                                              content: "",
                                              children: [
                                                {
                                                  childId: "i0tqqH",
                                                  type: "",
                                                  htmlTagName: "td",
                                                  classes: ["basics-5-list-tdwrapper-N8SJ4E"],
                                                  styles: [],
                                                  attributes: [],
                                                  content: "",
                                                  children: [
                                                    {
                                                      childId: "pXLvBs",
                                                      type: "",
                                                      htmlTagName: "div",
                                                      classes: ["basics-5-listwrapper-N8SJ4E"],
                                                      styles: [],
                                                      attributes: [
                                                        { property: "data-name", value: "div" },
                                                        { property: "data-editable", value: "false" },
                                                        { property: "data-texteditable", value: "false" },
                                                        { property: "data-listwrapper", value: "true" },
                                                      ],
                                                      content: "",
                                                      children: [
                                                        {
                                                          childId: "X9tXjV",
                                                          type: "",
                                                          htmlTagName: "span",
                                                          classes: ["basics-5-listbullet-N8SJ4E"],
                                                          styles: [],
                                                          attributes: [
                                                            { property: "data-name", value: "span" },
                                                            { property: "data-editable", value: "false" },
                                                            { property: "data-listbullet", value: "true" },
                                                          ],
                                                          content: "",
                                                          children: [],
                                                        },
                                                        {
                                                          childId: "aLMVjq",
                                                          type: "",
                                                          htmlTagName: "p",
                                                          classes: ["basics-5-list-text-N8SJ4E"],
                                                          styles: [],
                                                          attributes: [
                                                            { property: "data-name", value: "p" },
                                                            { property: "data-editable", value: "true" },
                                                            { property: "data-texteditable", value: "true" },
                                                            { property: "data-listtext", value: "true" },
                                                          ],
                                                          content: "",
                                                          children: [
                                                            {
                                                              childId: "",
                                                              type: "",
                                                              htmlTagName: "textNode",
                                                              classes: [],
                                                              styles: [],
                                                              attributes: [],
                                                              content: "Join sessions with experts from leading companies",
                                                              children: [],
                                                            },
                                                          ],
                                                        },
                                                      ],
                                                    },
                                                  ],
                                                },
                                              ],
                                            },
                                            {
                                              childId: "XDYrpI",
                                              type: "element",
                                              htmlTagName: "tr",
                                              classes: [],
                                              styles: [],
                                              attributes: [],
                                              content: "",
                                              children: [
                                                {
                                                  childId: "VAhiMS",
                                                  type: "",
                                                  htmlTagName: "td",
                                                  classes: ["basics-5-list-tdwrapper-N8SJ4E"],
                                                  styles: [],
                                                  attributes: [],
                                                  content: "",
                                                  children: [
                                                    {
                                                      childId: "BXNvH9",
                                                      type: "",
                                                      htmlTagName: "div",
                                                      classes: ["basics-5-listwrapper-N8SJ4E"],
                                                      styles: [],
                                                      attributes: [
                                                        { property: "data-name", value: "div" },
                                                        { property: "data-editable", value: "false" },
                                                        { property: "data-texteditable", value: "false" },
                                                        { property: "data-listwrapper", value: "true" },
                                                      ],
                                                      content: "",
                                                      children: [
                                                        {
                                                          childId: "ntUxZh",
                                                          type: "",
                                                          htmlTagName: "span",
                                                          classes: ["basics-5-listbullet-N8SJ4E"],
                                                          styles: [],
                                                          attributes: [
                                                            { property: "data-name", value: "span" },
                                                            { property: "data-editable", value: "false" },
                                                            { property: "data-listbullet", value: "true" },
                                                          ],
                                                          content: "",
                                                          children: [],
                                                        },
                                                        {
                                                          childId: "txpwnG",
                                                          type: "",
                                                          htmlTagName: "p",
                                                          classes: ["basics-5-list-text-N8SJ4E"],
                                                          styles: [],
                                                          attributes: [
                                                            { property: "data-name", value: "p" },
                                                            { property: "data-editable", value: "true" },
                                                            { property: "data-texteditable", value: "true" },
                                                            { property: "data-listtext", value: "true" },
                                                          ],
                                                          content: "",
                                                          children: [
                                                            {
                                                              childId: "",
                                                              type: "",
                                                              htmlTagName: "textNode",
                                                              classes: [],
                                                              styles: [],
                                                              attributes: [],
                                                              content: "Have fun!",
                                                              children: [],
                                                            },
                                                          ],
                                                        },
                                                      ],
                                                    },
                                                  ],
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "lL9tII",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "cards-1-component-lL9tII"],
          styles: [],
          attributes: [{ property: "data-name", value: "col_component" }],
          content: "",
          children: [
            {
              childId: "VZMXec",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "cards-1-col-container-lL9tII"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "a5WZOq",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-6", "vertical-align-top"],
                  styles: [],
                  attributes: [{ property: "data-colLayout", value: "true" }],
                  content: "",
                  children: [
                    {
                      childId: "nQoNdj",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "toPrbE",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "GI7bHZ",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "RnNRFO",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["cards-1-col-td-wrapper-lL9tII"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "s144lM",
                                      type: "",
                                      htmlTagName: "h3",
                                      classes: ["cards-1-title-lL9tII"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "h" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Location",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "tBREDc",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["cards-1-text-lL9tII"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Amsterdam RAI",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "YCDjIX",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["FjcIyMWgrSe7DI5Xey3VjW4Dc80Iou"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-name", value: "p" },
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Europaplein 54",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "x6fZBt",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["tVOlWhgcG7BBccsN4izBvVLIWRYfM0"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-name", value: "p" },
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "1078 GZ Amsterdam",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "eQn62i",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-6", "vertical-align-top"],
                  styles: [],
                  attributes: [{ property: "data-colLayout", value: "true" }],
                  content: "",
                  children: [
                    {
                      childId: "igYovR",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "mWhy7F",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "fYDvLC",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "Ww5gMk",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["cards-1-col-td-wrapper-lL9tII"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "K3cop7",
                                      type: "",
                                      htmlTagName: "h3",
                                      classes: ["cards-1-title-lL9tII"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "h" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Time",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "tTuWMA",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["cards-1-text-lL9tII"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "April 20th",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "vO8z0I",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["xi4s35gD7Mq5Lq3NJNy3HBMEJutu9A"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-name", value: "p" },
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "12.00 - late",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "xITmnz",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "basics-1-component-xITmnz"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "JnKzaD",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "basics-1-col-container-xITmnz"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "NJy8g4",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-12"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "cDohaq",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "LWa2Wn",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "EWQoIT",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "S6FqbW",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["basics-1-col-td-wrapper-xITmnz"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "UcevuE",
                                      type: "",
                                      htmlTagName: "h1",
                                      classes: ["basics-1-title-xITmnz"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "h" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Keynote speakers",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "tTXEpi",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "cards-10-component-tTXEpi"],
          styles: [],
          attributes: [{ property: "data-name", value: "col_component" }],
          content: "",
          children: [
            {
              childId: "mimizV",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "cards-10-col-container-tTXEpi"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "Qt2R2E",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-4", "vertical-align-top"],
                  styles: [],
                  attributes: [{ property: "data-colLayout", value: "true" }],
                  content: "",
                  children: [
                    {
                      childId: "RON2xr",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "WvDyLV",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "oDjAg4",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "gcyDfv",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["cards-10-col-td-wrapper-tTXEpi"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "P4TtC7",
                                      type: "",
                                      htmlTagName: "img",
                                      classes: ["cards-10-img-tTXEpi"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-name", value: "img" },
                                        { property: "src", value: "https://cdn.satonda.com/eb/assets/avatar-1.jpg" },
                                        { property: "alt", value: "Image description" },
                                      ],
                                      content: "",
                                      children: [],
                                    },
                                    {
                                      childId: "xkwePM",
                                      type: "",
                                      htmlTagName: "h3",
                                      classes: ["cards-10-title-tTXEpi"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "h" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Rosa Johnson",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "ub2y35",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["cards-10-text-tTXEpi"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Founder & CEO",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "SucYW7",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-4", "vertical-align-top"],
                  styles: [],
                  attributes: [{ property: "data-colLayout", value: "true" }],
                  content: "",
                  children: [
                    {
                      childId: "XQREca",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "HNyfZz",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "VFGTtq",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "fNOSpv",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["cards-10-col-td-wrapper-tTXEpi"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "ej3zMR",
                                      type: "",
                                      htmlTagName: "img",
                                      classes: ["cards-10-img-tTXEpi"],
                                      styles: [],
                                      attributes: [
                                        { property: "src", value: "https://cdn.satonda.com/eb/assets/avatar-2.jpg" },
                                        { property: "alt", value: "Image description" },
                                        { property: "data-editable", value: "true" },
                                        { property: "data-name", value: "img" },
                                      ],
                                      content: "",
                                      children: [],
                                    },
                                    {
                                      childId: "JSqjbz",
                                      type: "",
                                      htmlTagName: "h3",
                                      classes: ["cards-10-title-tTXEpi"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "h" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Mike Rogers",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "DxX3Sn",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["cards-10-text-tTXEpi"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "CTO",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "MLPZQs",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-4", "vertical-align-top"],
                  styles: [],
                  attributes: [{ property: "data-colLayout", value: "true" }],
                  content: "",
                  children: [
                    {
                      childId: "ohxpN9",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "XQbMDY",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "J3k2Wu",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "YdqDnZ",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["cards-10-col-td-wrapper-tTXEpi"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "mRdjj6",
                                      type: "",
                                      htmlTagName: "img",
                                      classes: ["cards-10-img-tTXEpi"],
                                      styles: [],
                                      attributes: [
                                        { property: "src", value: "https://cdn.satonda.com/eb/assets/avatar-3.jpg" },
                                        { property: "alt", value: "Image description" },
                                        { property: "data-editable", value: "true" },
                                        { property: "data-name", value: "img" },
                                      ],
                                      content: "",
                                      children: [],
                                    },
                                    {
                                      childId: "PkgI7J",
                                      type: "",
                                      htmlTagName: "h3",
                                      classes: ["cards-10-title-tTXEpi"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "h" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Michelle Laredo",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "fOEF09",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["cards-10-text-tTXEpi"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "SEO specialist",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "BQODEY",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "basics-3-component-BQODEY"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "NyQYRB",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "basics-3-col-container-BQODEY"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "rjCiIn",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-12"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "kgX5N7",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "i8akFB",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "Xuvw4j",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "fzpa4Y",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["basics-3-col-td-wrapper-BQODEY"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "olULZl",
                                      type: "",
                                      htmlTagName: "a",
                                      classes: ["basics-3-btn-BQODEY"],
                                      styles: [],
                                      attributes: [
                                        { property: "href", value: "#!" },
                                        { property: "data-href", value: "#!" },
                                        { property: "data-target", value: "_blank" },
                                        { property: "data-editable", value: "true" },
                                        { property: "data-name", value: "button" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Register now!",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "yxq8I7",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "footer-10-component-yxq8I7"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "xcjZzB",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "footer-10-col-container-yxq8I7"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "RFHVlP",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-12"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "rthNFE",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "NSa5ws",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "gRgA2Q",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "jJdo3h",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["footer-10-col-td-wrapper-yxq8I7"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "XLoWdh",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["footer-10-text1-yxq8I7"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                        { property: "data-uniqueclassname", value: "true" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "© 2023 Company name. All rights reserved.",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "Mix36Z",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["footer-10-text2-yxq8I7"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                        { property: "data-uniqueclassname", value: "true" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "You receive this email because you're registered in our database as a subscriber.",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "gWRIzc",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["footer-10-text3-yxq8I7"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                        { property: "data-uniqueclassname", value: "true" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Company name",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "BviCYH",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["footer-10-text4-yxq8I7"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                        { property: "data-uniqueclassname", value: "true" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Streetname 1",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "zvjxBP",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["footer-10-text5-yxq8I7"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                        { property: "data-uniqueclassname", value: "true" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "1000 LA Amsterdam",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "wtOzEG",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["footer-10-text6-yxq8I7"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                        { property: "data-uniqueclassname", value: "true" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "The Netherlands",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "EFcaKf",
                                      type: "element",
                                      htmlTagName: "a",
                                      classes: ["footer-10-link-yxq8I7"],
                                      styles: [],
                                      attributes: [
                                        { property: "href", value: "#!" },
                                        { property: "data-href", value: "%UNSUBSCRIBE_LINK%" },
                                        { property: "data-target", value: "_blank" },
                                        { property: "data-editable", value: "true" },
                                        { property: "data-name", value: "a" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Unsubscribe",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  ebCustomCss: [
    {
      componentId: "RyGhrF",
      classes: [
        {
          className: "basics-7-component-RyGhrF",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
          ],
        },
        {
          className: "basics-7-col-container-RyGhrF",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "background-color", value: "rgb(255, 255, 255)" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "basics-7-col-td-wrapper-RyGhrF",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0rem 0rem 0rem 0rem" },
            { property: "text-align", value: "start" },
          ],
        },
        {
          className: "basics-7-divider-RyGhrF",
          pseudo: "",
          rules: [
            { property: "display", value: "inline-block" },
            { property: "height", value: "25px" },
            { property: "width", value: "100%" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
            { property: "border-radius", value: "0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(230, 230, 230)" },
          ],
        },
      ],
    },
    {
      componentId: "ZiKpld",
      classes: [
        { className: "basics-7-component-ZiKpld", pseudo: "", rules: [{ property: "padding", value: "0 10px 0 10px" }] },
        {
          className: "basics-7-col-container-ZiKpld",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "background-color", value: "rgb(255, 255, 255)" },
            { property: "border-radius", value: "0 0 0 0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
          ],
        },
        {
          className: "basics-7-col-td-wrapper-ZiKpld",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "text-align", value: "start" },
            { property: "padding", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "basics-7-divider-ZiKpld",
          pseudo: "",
          rules: [
            { property: "width", value: "100%" },
            { property: "display", value: "inline-block" },
            { property: "height", value: "50px" },
            { property: "background", value: "rgb(230, 230, 230)" },
            { property: "background-color", value: "rgb(230, 230, 230)" },
            { property: "border-radius", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(230, 230, 230)" },
          ],
        },
      ],
    },
    {
      componentId: "LWyyFp",
      classes: [
        {
          className: "basics-1-title-LWyyFp",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgb(255, 255, 255)" },
            { property: "font-weight", value: "bold" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "basics-1-component-LWyyFp",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
          ],
        },
        {
          className: "basics-1-col-container-LWyyFp",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "linear-gradient(45deg, rgb(251, 146, 55) 20%, rgb(245, 65, 0) 100%)" },
            { property: "background-color", value: "rgb(251, 146, 55)" },
            { property: "padding-top", value: "1.5rem" },
            { property: "padding-bottom", value: "1.5rem" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "basics-1-col-td-wrapper-LWyyFp",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0.5rem 0.5rem 0.5rem 0.5rem" },
            { property: "text-align", value: "start" },
          ],
        },
      ],
    },
    {
      componentId: "p91zNU",
      classes: [
        {
          className: "basics-2-component-p91zNU",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
          ],
        },
        {
          className: "basics-2-col-container-p91zNU",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "background-color", value: "rgb(255, 255, 255)" },
            { property: "padding-top", value: "1.5rem" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "basics-2-col-td-wrapper-p91zNU",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0.5rem 0.5rem 0.5rem 0.5rem" },
            { property: "text-align", value: "start" },
          ],
        },
        {
          className: "basics-2-text-p91zNU",
          pseudo: "",
          rules: [
            { property: "text-align", value: "start" },
            { property: "color", value: "rgb(33, 37, 41)" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
          ],
        },
      ],
    },
    {
      componentId: "E7PtQu",
      classes: [
        { className: "basics-5-listwrapper-E7PtQu", pseudo: "", rules: [{ property: "margin", value: "0rem 0 0.25rem 0" }] },
        {
          className: "basics-5-listtext-E7PtQu",
          pseudo: "",
          rules: [
            { property: "display", value: "inline-block" },
            { property: "margin", value: "0 0 0 0" },
            { property: "vertical-align", value: "middle" },
            { property: "text-align", value: "left" },
          ],
        },
        {
          className: "basics-5-listbullet-E7PtQu",
          pseudo: "",
          rules: [
            { property: "display", value: "inline-block" },
            { property: "vertical-align", value: "middle" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "height", value: "8px" },
            { property: "width", value: "8px" },
            { property: "border-radius", value: "0rem" },
            { property: "background", value: "rgb(251, 146, 55)" },
            { property: "border-color", value: "rgb(251, 146, 55)" },
            { property: "margin-right", value: "0.5rem" },
          ],
        },
        {
          className: "basics-5-component-E7PtQu",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
          ],
        },
        {
          className: "basics-5-col-container-E7PtQu",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "background-color", value: "rgb(255, 255, 255)" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "basics-5-col-td-wrapper-E7PtQu",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0.5rem 0.75rem 0.5rem 0.75rem" },
            { property: "text-align", value: "start" },
          ],
        },
      ],
    },
    {
      componentId: "lL9tII",
      classes: [
        {
          className: "FjcIyMWgrSe7DI5Xey3VjW4Dc80Iou",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(33, 37, 41)" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        { className: "FjcIyMWgrSe7DI5Xey3VjW4Dc80Iou", pseudo: "hover", rules: [] },
        {
          className: "tVOlWhgcG7BBccsN4izBvVLIWRYfM0",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(33, 37, 41)" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        { className: "tVOlWhgcG7BBccsN4izBvVLIWRYfM0", pseudo: "hover", rules: [] },
        {
          className: "cards-1-text-lL9tII",
          pseudo: "",
          rules: [
            { property: "text-align", value: "left" },
            { property: "color", value: "rgb(33, 37, 41)" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "xi4s35gD7Mq5Lq3NJNy3HBMEJutu9A",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(33, 37, 41)" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        { className: "xi4s35gD7Mq5Lq3NJNy3HBMEJutu9A", pseudo: "hover", rules: [] },
        {
          className: "cards-1-title-lL9tII",
          pseudo: "",
          rules: [
            { property: "text-align", value: "left" },
            { property: "color", value: "rgb(251, 146, 55)" },
            { property: "margin", value: "0rem 0rem 0.25rem 0rem" },
          ],
        },
        {
          className: "cards-1-component-lL9tII",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
          ],
        },
        {
          className: "cards-1-col-container-lL9tII",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "background-color", value: "rgb(255, 255, 255)" },
            { property: "padding-top", value: "0.75rem" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "cards-1-col-td-wrapper-lL9tII",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0.5rem 0.75rem 0.5rem 0.75rem" },
            { property: "text-align", value: "start" },
          ],
        },
      ],
    },
    {
      componentId: "xITmnz",
      classes: [
        {
          className: "basics-1-component-xITmnz",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
          ],
        },
        {
          className: "basics-1-col-container-xITmnz",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "background-color", value: "rgb(255, 255, 255)" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "basics-1-col-td-wrapper-xITmnz",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0.5rem 0.5rem 0.5rem 0.5rem" },
            { property: "text-align", value: "start" },
          ],
        },
        {
          className: "basics-1-title-xITmnz",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgb(251, 146, 55)" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
          ],
        },
      ],
    },
    {
      componentId: "tTXEpi",
      classes: [
        {
          className: "cards-10-title-tTXEpi",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(251, 146, 55)" },
            { property: "margin", value: "0rem 0rem 0.5rem 0rem" },
            { property: "text-align", value: "center" },
            { property: "font-weight", value: "bold" },
          ],
        },
        {
          className: "cards-10-text-tTXEpi",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(33, 37, 41)" },
            { property: "margin", value: "0rem 0rem 0.5rem 0rem" },
            { property: "text-align", value: "center" },
          ],
        },
        {
          className: "cards-10-component-tTXEpi",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
          ],
        },
        {
          className: "cards-10-col-container-tTXEpi",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "background-color", value: "rgb(255, 255, 255)" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "cards-10-col-td-wrapper-tTXEpi",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0.5rem 0.75rem 0.5rem 0.75rem" },
            { property: "text-align", value: "center" },
          ],
        },
        {
          className: "cards-10-img-tTXEpi",
          pseudo: "",
          rules: [
            { property: "outline", value: "none" },
            { property: "-ms-interpolation-mode", value: "bicubic" },
            { property: "max-width", value: "200px" },
            { property: "max-height", value: "200px" },
            { property: "width", value: "100%" },
            { property: "height", value: "auto" },
            { property: "margin", value: "0rem 0rem 0.5rem 0rem" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "2px" },
            { property: "border-color", value: "rgb(251, 146, 55)" },
            { property: "border-radius", value: "0rem" },
          ],
        },
      ],
    },
    {
      componentId: "BQODEY",
      classes: [
        {
          className: "basics-3-component-BQODEY",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
          ],
        },
        {
          className: "basics-3-col-container-BQODEY",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "padding-top", value: "0.75rem" },
            { property: "padding-bottom", value: "0.5rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "background-color", value: "rgb(255, 255, 255)" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "basics-3-btn-BQODEY",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "font-weight", value: "bold" },
            { property: "font-style", value: "normal" },
            { property: "text-decoration", value: "none" },
            { property: "color", value: "rgb(255, 255, 255)" },
            { property: "background", value: "rgb(251, 146, 55)" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(251, 146, 55)" },
            { property: "border-radius", value: "0.2rem" },
            { property: "padding", value: "0.5rem 0rem 0.5rem 0rem" },
            { property: "display", value: "block" },
            { property: "font-size", value: "16px" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "basics-3-col-td-wrapper-BQODEY",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0.5rem 0.75rem 0.5rem 0.75rem" },
            { property: "text-align", value: "start" },
          ],
        },
      ],
    },
    {
      componentId: "yxq8I7",
      classes: [
        {
          className: "footer-10-text1-yxq8I7",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(108, 117, 125)" },
            { property: "margin", value: "0rem 0rem 0.125rem 0rem" },
            { property: "text-align", value: "center" },
            { property: "font-size", value: "12px" },
          ],
        },
        {
          className: "footer-10-text2-yxq8I7",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(108, 117, 125)" },
            { property: "margin", value: "0rem 0rem 0.125rem 0rem" },
            { property: "text-align", value: "center" },
            { property: "font-size", value: "12px" },
          ],
        },
        {
          className: "footer-10-text3-yxq8I7",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(108, 117, 125)" },
            { property: "margin", value: "1rem 0rem 0.125rem 0rem" },
            { property: "text-align", value: "center" },
            { property: "font-size", value: "12px" },
          ],
        },
        {
          className: "footer-10-text4-yxq8I7",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(108, 117, 125)" },
            { property: "margin", value: "0rem 0rem 0.125rem 0rem" },
            { property: "text-align", value: "center" },
            { property: "font-size", value: "12px" },
          ],
        },
        {
          className: "footer-10-text5-yxq8I7",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(108, 117, 125)" },
            { property: "margin", value: "0rem 0rem 0.125rem 0rem" },
            { property: "text-align", value: "center" },
            { property: "font-size", value: "12px" },
          ],
        },
        {
          className: "footer-10-text6-yxq8I7",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(108, 117, 125)" },
            { property: "margin", value: "0rem 0rem 0.25rem 0rem" },
            { property: "text-align", value: "center" },
            { property: "font-size", value: "12px" },
          ],
        },
        {
          className: "footer-10-link-yxq8I7",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(108, 117, 125)" },
            { property: "margin", value: "1rem 0rem 0rem 0rem" },
            { property: "display", value: "block" },
            { property: "text-align", value: "center" },
            { property: "text-decoration", value: "underline" },
            { property: "font-size", value: "12px" },
          ],
        },
        {
          className: "footer-10-component-yxq8I7",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
          ],
        },
        {
          className: "footer-10-col-container-yxq8I7",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(234, 237, 240)" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "padding-top", value: "2rem" },
            { property: "padding-bottom", value: "2rem" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "footer-10-col-td-wrapper-yxq8I7",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0.5rem 0rem 0.5rem 0rem" },
            { property: "text-align", value: "start" },
          ],
        },
      ],
    },
    {
      componentId: "N8SJ4E",
      classes: [
        { className: "basics-5-list-tdwrapper-N8SJ4E", pseudo: "", rules: [{ property: "margin", value: "0rem 0 0.25rem 0" }] },
        {
          className: "basics-5-listwrapper-N8SJ4E",
          pseudo: "",
          rules: [
            { property: "text-align", value: "left" },
            { property: "display", value: "inline-block" },
            { property: "margin", value: "0 0 0.5rem 0" },
            { property: "vertical-align", value: "middle" },
          ],
        },
        {
          className: "basics-5-listbullet-N8SJ4E",
          pseudo: "",
          rules: [
            { property: "display", value: "inline-block" },
            { property: "vertical-align", value: "middle" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "height", value: "8px" },
            { property: "width", value: "8px" },
            { property: "border-radius", value: "0rem" },
            { property: "background", value: "rgb(251, 146, 55)" },
            { property: "border-color", value: "rgb(251, 146, 55)" },
            { property: "margin-right", value: "0.5rem" },
          ],
        },
        {
          className: "basics-5-list-text-N8SJ4E",
          pseudo: "",
          rules: [
            { property: "display", value: "inline" },
            { property: "margin", value: "0 0 0 0" },
          ],
        },
        {
          className: "basics-5-component-N8SJ4E",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
          ],
        },
        {
          className: "basics-5-col-container-N8SJ4E",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "background-color", value: "rgb(255, 255, 255)" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "basics-5-col-td-wrapper-N8SJ4E",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0.5rem 0.75rem 0.5rem 0.75rem" },
            { property: "text-align", value: "start" },
          ],
        },
      ],
    },
  ],
  ebCssVars: {
    fontHeadersName: "Arial",
    fontHeadersWeight: "400",
    fontBodyName: "Arial",
    fontBodyWeight: "400",
    color1: "rgb(251, 146, 55)",
    color2: "rgb(245, 65, 0)",
    color3: "rgba(40, 167, 69, 1)",
    color4: "rgba(13, 202, 240, 1)",
    color5: "rgba(255, 193, 7, 1)",
    color6: "rgba(220, 53, 69, 1)",
    color7: "rgba(233, 236, 239, 1)",
    color8: "rgba(33, 37, 41, 1)",
    color9: "rgba(206, 212, 218, 1)",
    color10: "rgba(108, 117, 125, 1)",
  },
};
