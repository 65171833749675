// Import dependencies
import axios from "axios";

// Have a function to check whether there is a token in localstorage
const setAuthToken = (token) => {
  if (token) {
    // If there is, add it to the axios global headers
    axios.defaults.headers.common["x-auth-token"] = token;
  } else {
    // If there is no token passed in, delete the one that was set as the global header
    delete axios.defaults.headers.common["x-auth-token"];
  }
};

export default setAuthToken;
