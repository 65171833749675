import store from "../store";
import {
  EDIT_COMPONENT_ACTION_ICON_TYPE,
  COMPONENT_MOVEUP_TYPE,
  COMPONENT_MOVEDOWN_TYPE,
  COMPONENT_EDIT_TYPE,
  COMPONENT_DELETE_TYPE,
  ELEMENT_DUPLICATE_TYPE,
  ELEMENT_EDIT_TYPE,
  ELEMENT_DELETE_TYPE,
  COL_REORDER_TYPE,
} from "./generalVars";
import { determineEditFormsToShow } from "./editFunctions";
import { reorderComponent, deleteComponent, setSelectedElement, duplicateElement, deleteElement, reorderColumn } from "../actions/eb";

export const getIframeWindow = () => {
  try {
    return document.getElementById("iframe").contentWindow;
  } catch (error) {
    return null;
  }
};

export const getTargetElement = (elementId) => {
  try {
    // return getIframeWindow().document.getElementById(elementId);
    return getIframeWindow().document.querySelector(`[data-id="${elementId}"]`);
  } catch (error) {
    return null;
  }
};

export const elementIsEditableTextElement = (element) => {
  try {
    return element.dataset.texteditable === "true";
  } catch (error) {
    return false;
  }
};

export const getRandomId = () => {
  const idLength = 6;
  let generatedId = [];
  const allowedChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const allowedCharsLength = allowedChars.length;
  let uniqueId = false;
  while (!uniqueId) {
    for (let i = 0; i < idLength; i++) {
      if (i === 0) {
        // First character of the id needs to be a letter, else CSS id selector doesn't work
        generatedId.push(allowedChars.charAt(Math.floor(Math.random() * (allowedCharsLength - 10))));
      } else {
        generatedId.push(allowedChars.charAt(Math.floor(Math.random() * allowedCharsLength)));
      }
    }
    uniqueId = randomIdIsUnique(generatedId.join(""));
  }
  return generatedId.join("");
};

const randomIdIsUnique = (randomId) => {
  return getTargetElement(randomId) === null;
};

export const getRandomString = (strLength) => {
  let generatedStr = [];
  const allowedChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const allowedCharsLength = allowedChars.length;
  for (let i = 0; i < strLength; i++) {
    if (i === 0) {
      // Ensure first char is a letter
      generatedStr.push(allowedChars.charAt(Math.floor(Math.random() * (allowedCharsLength - 10))));
    } else {
      generatedStr.push(allowedChars.charAt(Math.floor(Math.random() * allowedCharsLength)));
    }
  }
  return generatedStr.join("");
};

export const clickIframeElement = (target) => {
  try {
    // console.log("clickIframeElement", target);
    // Decide what to do when a user clicks somewhere in the iframe
    // If it's an edit-action, perform that action
    if (target.dataset.ebtype === EDIT_COMPONENT_ACTION_ICON_TYPE) {
      // Get the selected action from the target's parent and perform action
      target = target.parentElement;
      // console.log(target);
      // let componentId = target.parentElement.parentElement.dataset.id;
      let componentId = target.dataset.id.split("-")[0];
      // Component actions
      if (target.dataset.ebtype === COMPONENT_MOVEUP_TYPE) {
        store.dispatch(reorderComponent(componentId, -1));
        store.dispatch(setSelectedElement(""));
      }
      if (target.dataset.ebtype === COMPONENT_MOVEDOWN_TYPE) {
        store.dispatch(reorderComponent(componentId, 1));
        store.dispatch(setSelectedElement(""));
      }
      if (target.dataset.ebtype === COMPONENT_EDIT_TYPE) {
        store.dispatch(setSelectedElement(componentId));
        determineEditFormsToShow(getParentByebtype(target, "component"));
      }
      if (target.dataset.ebtype === COMPONENT_DELETE_TYPE) {
        store.dispatch(deleteComponent(componentId));
        store.dispatch(setSelectedElement(""));
      }
      // Element actions
      if (target.dataset.ebtype === ELEMENT_EDIT_TYPE) {
        let element = getParentByebtype(target, "element");
        store.dispatch(setSelectedElement(element.dataset.id));
        determineEditFormsToShow(element);
      }
      if (target.dataset.ebtype === ELEMENT_DUPLICATE_TYPE) {
        let element = getParentByebtype(target, "element");
        store.dispatch(duplicateElement(element.dataset.id));
        if (element.className.includes("splide__slide") && element.className.includes("-main-")) {
          // If the element being duplicated is a carousel slide part of a thumbnail carousel, then duplicate also its thumbnail
          store.dispatch(duplicateElement(carousel_getThumbnailSlideId(element)));
        }
        store.dispatch(setSelectedElement(""));
      }
      if (target.dataset.ebtype === ELEMENT_DELETE_TYPE) {
        let element = getParentByebtype(target, "element");
        store.dispatch(deleteElement(element.dataset.id));
        if (element.className.includes("splide__slide") && element.className.includes("-main-")) {
          // If the element being duplicated is a carousel slide part of a thumbnail carousel, then duplicate also its thumbnail
          store.dispatch(deleteElement(carousel_getThumbnailSlideId(element)));
        }
        store.dispatch(setSelectedElement(""));
      }
      // Reorder column
      if (target.dataset.ebtype === COL_REORDER_TYPE) {
        let element = getParentByClassnameRegex(target, /column/gi);
        store.dispatch(reorderColumn(element.dataset.id));
        store.dispatch(setSelectedElement(""));
      }
      return;
    }
    // If the element that is clicked is a text styling element, go to its parent
    const TEXT_STYLING_ELEMENTS = ["strong", "em", "ins", "del"];
    if (TEXT_STYLING_ELEMENTS.includes(target.localName)) {
      target = target.parentElement;
    }
    // Get parentElement if specified
    if (target.dataset.checkparent === "true" || typeof target.dataset.name === "undefined") {
      target = getParentForCheckParentElements(target);
    }
    // If target is a googlemaps_wrapper, get its next sibling
    if (target.dataset.name === "googlemaps_wrapper") {
      target = target.nextElementSibling;
    }
    // If target is a footer4, get its child element
    if (target.dataset.name === "footer4_wrapper") {
      target = target.firstElementChild;
    }
    // Check whether clicked element is editable. If so, set selectedElement to clicked element's ID. If not, don't do anything
    if (target.dataset.editable === "true") {
      store.dispatch(setSelectedElement(target.dataset.id));
      determineEditFormsToShow(target);
      return;
    }
    // If not editable, reset selectedElement
    store.dispatch(setSelectedElement(""));
    return;
  } catch (error) {
    console.error(error);
    return;
  }
};

const getParentForCheckParentElements = (element) => {
  if (
    element.parentElement !== null &&
    (element.parentElement.dataset.checkparent === "true" || typeof element.parentElement.dataset.name === "undefined")
  ) {
    return getParentForCheckParentElements(element.parentElement);
  }
  return element.parentElement;
};

const getParentByebtype = (target, ebtype) => {
  try {
    if (target.dataset.ebtype === ebtype) {
      return target;
    }
    return getParentByebtype(target.parentElement, ebtype);
  } catch (error) {
    return null;
  }
};

const getParentByClassnameRegex = (target, regex) => {
  try {
    if (target.className.match(regex) !== null) {
      return target;
    }
    return getParentByClassnameRegex(target.parentElement, regex);
  } catch (error) {
    return null;
  }
};

const carousel_getThumbnailSlideId = (elMainSlide) => {
  try {
    // elMainSlide has format like "splide01-slide01"
    // Corresponding thumbnail slide id will be "splide02-slide01"
    let carouselNum = parseInt(elMainSlide.id.split("-")[0].replace("splide", "")) + 1;
    carouselNum = carouselNum < 10 ? `0${carouselNum}` : carouselNum;
    let thumbnailSlideId = `splide${carouselNum}-${elMainSlide.id.split("-")[1]}`;
    let thumbnailSlide = getIframeWindow().document.getElementById(thumbnailSlideId);
    return thumbnailSlide.dataset.id;
  } catch (error) {
    console.error(error);
    return "";
  }
};
