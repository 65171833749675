export const template12 = {
  id: "66034e6b7069caa2f9dc0028",
  name: { en: "Customer testimonial", nl: "Klantreviews" },
  desc: { en: "Share what your customers are saying about your products", nl: "Deel wat uw klanten vertellen over uw producten" },
  categories: ["product"],
  ebContent: [
    {
      pageId: "txnLYlbmSQ",
      version: "A",
      components: [
        {
          componentId: "lceNJz",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "basics-7-component-lceNJz"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "zdePxz",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "basics-7-col-container-lceNJz"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "cOVVg0",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-12"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "sbR1Y4",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "vmUx53",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "fjsV62",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "p56PSt",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["basics-7-col-td-wrapper-lceNJz"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-name", value: "divider" },
                                    { property: "data-editable", value: "true" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "q2vVBj",
                                      type: "",
                                      htmlTagName: "div",
                                      classes: ["basics-7-divider-lceNJz"],
                                      styles: [],
                                      attributes: [{ property: "data-elementgetter1", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "ZQgBqa",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "basics-1-component-ZQgBqa"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "cULET0",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "basics-1-col-container-ZQgBqa"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "hcH0X6",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-12"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "B71nQw",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "KAl4ZL",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "GKCoTj",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "VcPI5K",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["basics-1-col-td-wrapper-ZQgBqa"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "OIQlzc",
                                      type: "",
                                      htmlTagName: "h1",
                                      classes: ["basics-1-title-ZQgBqa"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "h" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "You're missing out",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "njc4Y5",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "basics-2-component-njc4Y5"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "Hbm6rJ",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "basics-2-col-container-njc4Y5"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "Lokq3G",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-12"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "fCWqlP",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "dI10cr",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "WXFo8R",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "rhL33g",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["basics-2-col-td-wrapper-njc4Y5"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "aizmMa",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["basics-2-text-njc4Y5"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "We have a great product that you're not using. Key benefits:",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "tQDj5g",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "basics-5-component-tQDj5g"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "XzaB23",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "basics-5-col-container-tQDj5g"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "uCz1w3",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-12"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "VPKTJR",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "c5BPZG",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "BKrBRu",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "FcYYsH",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["basics-5-col-td-wrapper-tQDj5g"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "saaEYE",
                                      type: "",
                                      htmlTagName: "table",
                                      classes: ["column-table"],
                                      styles: [],
                                      attributes: [
                                        { property: "role", value: "presentation" },
                                        { property: "width", value: "100%" },
                                        { property: "cellspacing", value: "0" },
                                        { property: "cellpadding", value: "0" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "wNfVLl",
                                          type: "block",
                                          htmlTagName: "tbody",
                                          classes: [],
                                          styles: [],
                                          attributes: [{ property: "data-name", value: "block" }],
                                          content: "",
                                          children: [
                                            {
                                              childId: "F1cbxv",
                                              type: "element",
                                              htmlTagName: "tr",
                                              classes: [],
                                              styles: [],
                                              attributes: [],
                                              content: "",
                                              children: [
                                                {
                                                  childId: "nUPc1l",
                                                  type: "",
                                                  htmlTagName: "td",
                                                  classes: ["basics-5-list-tdwrapper-tQDj5g"],
                                                  styles: [],
                                                  attributes: [],
                                                  content: "",
                                                  children: [
                                                    {
                                                      childId: "SEhdmI",
                                                      type: "",
                                                      htmlTagName: "div",
                                                      classes: ["basics-5-listwrapper-tQDj5g"],
                                                      styles: [],
                                                      attributes: [
                                                        { property: "data-name", value: "div" },
                                                        { property: "data-editable", value: "false" },
                                                        { property: "data-texteditable", value: "false" },
                                                        { property: "data-listwrapper", value: "true" },
                                                      ],
                                                      content: "",
                                                      children: [
                                                        {
                                                          childId: "EgpWzz",
                                                          type: "",
                                                          htmlTagName: "span",
                                                          classes: ["basics-5-listbullet-tQDj5g"],
                                                          styles: [],
                                                          attributes: [
                                                            { property: "data-name", value: "span" },
                                                            { property: "data-editable", value: "false" },
                                                            { property: "data-listbullet", value: "true" },
                                                          ],
                                                          content: "",
                                                          children: [],
                                                        },
                                                        {
                                                          childId: "G9w7EV",
                                                          type: "",
                                                          htmlTagName: "p",
                                                          classes: ["basics-5-list-text-tQDj5g"],
                                                          styles: [],
                                                          attributes: [
                                                            { property: "data-name", value: "p" },
                                                            { property: "data-editable", value: "true" },
                                                            { property: "data-texteditable", value: "true" },
                                                            { property: "data-listtext", value: "true" },
                                                          ],
                                                          content: "",
                                                          children: [
                                                            {
                                                              childId: "",
                                                              type: "",
                                                              htmlTagName: "textNode",
                                                              classes: [],
                                                              styles: [],
                                                              attributes: [],
                                                              content: "Lorem ipsum dolor sit amet",
                                                              children: [],
                                                            },
                                                          ],
                                                        },
                                                      ],
                                                    },
                                                  ],
                                                },
                                              ],
                                            },
                                            {
                                              childId: "OOzHER",
                                              type: "element",
                                              htmlTagName: "tr",
                                              classes: [],
                                              styles: [],
                                              attributes: [],
                                              content: "",
                                              children: [
                                                {
                                                  childId: "tFfb93",
                                                  type: "",
                                                  htmlTagName: "td",
                                                  classes: ["basics-5-list-tdwrapper-tQDj5g"],
                                                  styles: [],
                                                  attributes: [],
                                                  content: "",
                                                  children: [
                                                    {
                                                      childId: "hRyB2r",
                                                      type: "",
                                                      htmlTagName: "div",
                                                      classes: ["basics-5-listwrapper-tQDj5g"],
                                                      styles: [],
                                                      attributes: [
                                                        { property: "data-name", value: "div" },
                                                        { property: "data-editable", value: "false" },
                                                        { property: "data-texteditable", value: "false" },
                                                        { property: "data-listwrapper", value: "true" },
                                                      ],
                                                      content: "",
                                                      children: [
                                                        {
                                                          childId: "A81Vfp",
                                                          type: "",
                                                          htmlTagName: "span",
                                                          classes: ["basics-5-listbullet-tQDj5g"],
                                                          styles: [],
                                                          attributes: [
                                                            { property: "data-name", value: "span" },
                                                            { property: "data-editable", value: "false" },
                                                            { property: "data-listbullet", value: "true" },
                                                          ],
                                                          content: "",
                                                          children: [],
                                                        },
                                                        {
                                                          childId: "J5zYkm",
                                                          type: "",
                                                          htmlTagName: "p",
                                                          classes: ["basics-5-list-text-tQDj5g"],
                                                          styles: [],
                                                          attributes: [
                                                            { property: "data-name", value: "p" },
                                                            { property: "data-editable", value: "true" },
                                                            { property: "data-texteditable", value: "true" },
                                                            { property: "data-listtext", value: "true" },
                                                          ],
                                                          content: "",
                                                          children: [
                                                            {
                                                              childId: "",
                                                              type: "",
                                                              htmlTagName: "textNode",
                                                              classes: [],
                                                              styles: [],
                                                              attributes: [],
                                                              content: "Lorem ipsum dolor sit amet",
                                                              children: [],
                                                            },
                                                          ],
                                                        },
                                                      ],
                                                    },
                                                  ],
                                                },
                                              ],
                                            },
                                            {
                                              childId: "FQ5Csj",
                                              type: "element",
                                              htmlTagName: "tr",
                                              classes: [],
                                              styles: [],
                                              attributes: [],
                                              content: "",
                                              children: [
                                                {
                                                  childId: "KS8Zeq",
                                                  type: "",
                                                  htmlTagName: "td",
                                                  classes: ["basics-5-list-tdwrapper-tQDj5g"],
                                                  styles: [],
                                                  attributes: [],
                                                  content: "",
                                                  children: [
                                                    {
                                                      childId: "NDak0y",
                                                      type: "",
                                                      htmlTagName: "div",
                                                      classes: ["basics-5-listwrapper-tQDj5g"],
                                                      styles: [],
                                                      attributes: [
                                                        { property: "data-name", value: "div" },
                                                        { property: "data-editable", value: "false" },
                                                        { property: "data-texteditable", value: "false" },
                                                        { property: "data-listwrapper", value: "true" },
                                                      ],
                                                      content: "",
                                                      children: [
                                                        {
                                                          childId: "eFskAo",
                                                          type: "",
                                                          htmlTagName: "span",
                                                          classes: ["basics-5-listbullet-tQDj5g"],
                                                          styles: [],
                                                          attributes: [
                                                            { property: "data-name", value: "span" },
                                                            { property: "data-editable", value: "false" },
                                                            { property: "data-listbullet", value: "true" },
                                                          ],
                                                          content: "",
                                                          children: [],
                                                        },
                                                        {
                                                          childId: "cDZvnr",
                                                          type: "",
                                                          htmlTagName: "p",
                                                          classes: ["basics-5-list-text-tQDj5g"],
                                                          styles: [],
                                                          attributes: [
                                                            { property: "data-name", value: "p" },
                                                            { property: "data-editable", value: "true" },
                                                            { property: "data-texteditable", value: "true" },
                                                            { property: "data-listtext", value: "true" },
                                                          ],
                                                          content: "",
                                                          children: [
                                                            {
                                                              childId: "",
                                                              type: "",
                                                              htmlTagName: "textNode",
                                                              classes: [],
                                                              styles: [],
                                                              attributes: [],
                                                              content: "Lorem ipsum dolor sit amet",
                                                              children: [],
                                                            },
                                                          ],
                                                        },
                                                      ],
                                                    },
                                                  ],
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "zAhlBz",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "basics-2-component-zAhlBz"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "Cx9ucm",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "basics-2-col-container-zAhlBz"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "c9z3m3",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-12"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "XAS40t",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "n5oQE9",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "Se0Ysk",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "Nh9gkU",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["basics-2-col-td-wrapper-zAhlBz"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "hZVR77",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["basics-2-text-zAhlBz"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "What our other customers have to say:",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "a2BdRO",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "cards-6-component-a2BdRO"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "wLLUIN",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "cards-6-col-container-a2BdRO"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "Gy8JZc",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-12"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "ncj5xR",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "PT0nvu",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "Qq2saf",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "rGYKrb",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["cards-6-col-td-wrapper-a2BdRO"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "HxExiu",
                                      type: "",
                                      htmlTagName: "table",
                                      classes: ["column-table", "cards-6-table-table-a2BdRO"],
                                      styles: [],
                                      attributes: [
                                        { property: "role", value: "presentation" },
                                        { property: "width", value: "100%" },
                                        { property: "cellspacing", value: "0" },
                                        { property: "cellpadding", value: "0" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "JaKdSi",
                                          type: "block",
                                          htmlTagName: "tbody",
                                          classes: [],
                                          styles: [],
                                          attributes: [{ property: "data-name", value: "block" }],
                                          content: "",
                                          children: [
                                            {
                                              childId: "FSt2nV",
                                              type: "element",
                                              htmlTagName: "tr",
                                              classes: ["cards-6-table-row-a2BdRO"],
                                              styles: [],
                                              attributes: [],
                                              content: "",
                                              children: [
                                                {
                                                  childId: "P7f4bC",
                                                  type: "",
                                                  htmlTagName: "td",
                                                  classes: ["cards-6-table-cell-all-a2BdRO", "cards-6-table-cell-img-a2BdRO"],
                                                  styles: [],
                                                  attributes: [{ property: "data-tablecell", value: "true" }],
                                                  content: "",
                                                  children: [
                                                    {
                                                      childId: "VpCHwD",
                                                      type: "",
                                                      htmlTagName: "img",
                                                      classes: ["cards-6-img-a2BdRO"],
                                                      styles: [],
                                                      attributes: [
                                                        { property: "src", value: "https://cdn.satonda.com/eb/assets/client-2.png" },
                                                        { property: "alt", value: "Client" },
                                                        { property: "data-editable", value: "true" },
                                                        { property: "data-name", value: "img" },
                                                      ],
                                                      content: "",
                                                      children: [],
                                                    },
                                                  ],
                                                },
                                                {
                                                  childId: "yldOHD",
                                                  type: "",
                                                  htmlTagName: "td",
                                                  classes: ["cards-6-table-cell-all-a2BdRO"],
                                                  styles: [],
                                                  attributes: [{ property: "data-tablecell", value: "true" }],
                                                  content: "",
                                                  children: [
                                                    {
                                                      childId: "hXhw5V",
                                                      type: "",
                                                      htmlTagName: "p",
                                                      classes: ["cards-6-table-text1-a2BdRO"],
                                                      styles: [],
                                                      attributes: [
                                                        { property: "data-editable", value: "true" },
                                                        { property: "data-texteditable", value: "true" },
                                                        { property: "data-name", value: "p" },
                                                        { property: "data-uniqueclassname", value: "true" },
                                                      ],
                                                      content: "",
                                                      children: [
                                                        {
                                                          childId: "",
                                                          type: "",
                                                          htmlTagName: "textNode",
                                                          classes: [],
                                                          styles: [],
                                                          attributes: [],
                                                          content: "Client A",
                                                          children: [],
                                                        },
                                                      ],
                                                    },
                                                    {
                                                      childId: "oj7eOz",
                                                      type: "",
                                                      htmlTagName: "p",
                                                      classes: ["cards-6-table-text2-a2BdRO"],
                                                      styles: [],
                                                      attributes: [
                                                        { property: "data-editable", value: "true" },
                                                        { property: "data-texteditable", value: "true" },
                                                        { property: "data-name", value: "p" },
                                                        { property: "data-uniqueclassname", value: "true" },
                                                      ],
                                                      content: "",
                                                      children: [
                                                        {
                                                          childId: "",
                                                          type: "",
                                                          htmlTagName: "textNode",
                                                          classes: [],
                                                          styles: [],
                                                          attributes: [],
                                                          content:
                                                            "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Beatae ratione omnis temporibus vero fugit quam necessitatibus dolore voluptatibus libero nesciunt!",
                                                          children: [],
                                                        },
                                                      ],
                                                    },
                                                  ],
                                                },
                                              ],
                                            },
                                            {
                                              childId: "lU9GVq",
                                              type: "element",
                                              htmlTagName: "tr",
                                              classes: ["cards-6-table-row-a2BdRO"],
                                              styles: [],
                                              attributes: [],
                                              content: "",
                                              children: [
                                                {
                                                  childId: "PUYcBT",
                                                  type: "",
                                                  htmlTagName: "td",
                                                  classes: ["cards-6-table-cell-all-a2BdRO", "cards-6-table-cell-img-a2BdRO"],
                                                  styles: [],
                                                  attributes: [{ property: "data-tablecell", value: "true" }],
                                                  content: "",
                                                  children: [
                                                    {
                                                      childId: "MJOnyR",
                                                      type: "",
                                                      htmlTagName: "img",
                                                      classes: ["cards-6-img-a2BdRO"],
                                                      styles: [],
                                                      attributes: [
                                                        { property: "src", value: "https://cdn.satonda.com/eb/assets/client-1.png" },
                                                        { property: "alt", value: "Client" },
                                                        { property: "data-editable", value: "true" },
                                                        { property: "data-name", value: "img" },
                                                      ],
                                                      content: "",
                                                      children: [],
                                                    },
                                                  ],
                                                },
                                                {
                                                  childId: "Z8ZBZd",
                                                  type: "",
                                                  htmlTagName: "td",
                                                  classes: ["cards-6-table-cell-all-a2BdRO"],
                                                  styles: [],
                                                  attributes: [{ property: "data-tablecell", value: "true" }],
                                                  content: "",
                                                  children: [
                                                    {
                                                      childId: "K7fKYT",
                                                      type: "",
                                                      htmlTagName: "p",
                                                      classes: ["cards-6-table-text3-a2BdRO"],
                                                      styles: [],
                                                      attributes: [
                                                        { property: "data-editable", value: "true" },
                                                        { property: "data-texteditable", value: "true" },
                                                        { property: "data-name", value: "p" },
                                                        { property: "data-uniqueclassname", value: "true" },
                                                      ],
                                                      content: "",
                                                      children: [
                                                        {
                                                          childId: "",
                                                          type: "",
                                                          htmlTagName: "textNode",
                                                          classes: [],
                                                          styles: [],
                                                          attributes: [],
                                                          content: "Client B",
                                                          children: [],
                                                        },
                                                      ],
                                                    },
                                                    {
                                                      childId: "t7LeFP",
                                                      type: "",
                                                      htmlTagName: "p",
                                                      classes: ["cards-6-table-text4-a2BdRO"],
                                                      styles: [],
                                                      attributes: [
                                                        { property: "data-editable", value: "true" },
                                                        { property: "data-texteditable", value: "true" },
                                                        { property: "data-name", value: "p" },
                                                        { property: "data-uniqueclassname", value: "true" },
                                                      ],
                                                      content: "",
                                                      children: [
                                                        {
                                                          childId: "",
                                                          type: "",
                                                          htmlTagName: "textNode",
                                                          classes: [],
                                                          styles: [],
                                                          attributes: [],
                                                          content:
                                                            "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Beatae ratione omnis temporibus vero fugit quam necessitatibus dolore voluptatibus libero nesciunt!",
                                                          children: [],
                                                        },
                                                      ],
                                                    },
                                                  ],
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "TJcvym",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "cards-5-component-TJcvym"],
          styles: [],
          attributes: [{ property: "data-name", value: "col_component" }],
          content: "",
          children: [
            {
              childId: "ezjCq2",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "cards-5-col-container-TJcvym"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "sMH8SE",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "vertical-align-middle", "col-6"],
                  styles: [],
                  attributes: [{ property: "data-colLayout", value: "true" }],
                  content: "",
                  children: [
                    {
                      childId: "J9y1au",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "LO9jSD",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "cclNcG",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "cfCTkw",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["cards-5-col-td-wrapper-TJcvym"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "MDesoP",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["cards-5-text-TJcvym"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: " Lorem ipsum dolor sit, amet consectetur adipisicing elit. Beatae ratione omnis temporibus vero!",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "Uj4CoN",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "vertical-align-middle", "col-6"],
                  styles: [],
                  attributes: [{ property: "data-colLayout", value: "true" }],
                  content: "",
                  children: [
                    {
                      childId: "mg4k0N",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "k6qcOD",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "THvz3R",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "ziVpfl",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["cards-5-col-td-wrapper-TJcvym"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "N1cJfI",
                                      type: "",
                                      htmlTagName: "a",
                                      classes: ["cards-5-btn-TJcvym"],
                                      styles: [],
                                      attributes: [
                                        { property: "href", value: "#!" },
                                        { property: "data-href", value: "#!" },
                                        { property: "data-target", value: "_blank" },
                                        { property: "data-editable", value: "true" },
                                        { property: "data-name", value: "button" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Get started today!",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "YgrtCu",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "footer-6-component-YgrtCu"],
          styles: [],
          attributes: [{ property: "data-name", value: "col_component" }],
          content: "",
          children: [
            {
              childId: "sYPNMX",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "footer-6-col-container-YgrtCu"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "ZvSw20",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-6", "vertical-align-middle", "footer-6-bgdiv-YgrtCu"],
                  styles: [],
                  attributes: [
                    { property: "data-colLayout", value: "true" },
                    { property: "data-editable", value: "true" },
                    { property: "data-name", value: "bgdiv" },
                  ],
                  content: "",
                  children: [
                    {
                      childId: "LlwkF7",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "dcWsBb",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "TyImyW",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "WGJlqS",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["footer-6-col-td-wrapper-YgrtCu"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "bTA4Me",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["footer-6-text1-YgrtCu"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                        { property: "data-uniqueclassname", value: "true" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "We would love to hear from you!",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "VmCSu2",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-6", "vertical-align-middle"],
                  styles: [],
                  attributes: [{ property: "data-colLayout", value: "true" }],
                  content: "",
                  children: [
                    {
                      childId: "MwwDHs",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "jfPPtx",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "bX8huh",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "HI0yhg",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["footer-6-col-td-wrapper-YgrtCu"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "SUoWdf",
                                      type: "",
                                      htmlTagName: "img",
                                      classes: ["footer-6-img-YgrtCu"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-name", value: "img" },
                                        { property: "src", value: "https://cdn.satonda.com/eb/assets/avatar-3.jpg" },
                                        { property: "alt", value: "Image description" },
                                      ],
                                      content: "",
                                      children: [],
                                    },
                                    {
                                      childId: "aa40te",
                                      type: "",
                                      htmlTagName: "h4",
                                      classes: ["footer-6-text2-YgrtCu"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "h" },
                                        { property: "data-uniqueclassname", value: "true" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Nora Jenkins",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "acG719",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["footer-6-text3-YgrtCu"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                        { property: "data-uniqueclassname", value: "true" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Customer support",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "O5AxfT",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["footer-6-text4-YgrtCu"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                        { property: "data-uniqueclassname", value: "true" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "123 456 789",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "FMnYhk",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["footer-6-text5-YgrtCu"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                        { property: "data-uniqueclassname", value: "true" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "contact@us.com",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "Ujho1L",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "basics-13-component-Ujho1L"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "DgkZUB",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "basics-13-col-container-Ujho1L"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "Q8WyjF",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-12"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "fC0rWJ",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "ixHkYJ",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "Bwyg5a",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "xpQsol",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["basics-13-col-td-wrapper-Ujho1L"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "Z4QiHi",
                                      type: "",
                                      htmlTagName: "a",
                                      classes: ["basics-13-linkwrapper-Ujho1L"],
                                      styles: [],
                                      attributes: [
                                        { property: "href", value: "#!" },
                                        { property: "data-href", value: "#!" },
                                        { property: "data-target", value: "_blank" },
                                        { property: "data-linkwrapper", value: "true" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "saPVsQ",
                                          type: "",
                                          htmlTagName: "img",
                                          classes: ["basics-13-img-Ujho1L"],
                                          styles: [],
                                          attributes: [
                                            { property: "data-editable", value: "true" },
                                            { property: "data-name", value: "img" },
                                            { property: "src", value: "https://cdn.satonda.com/eb/assets/logo5.png" },
                                            { property: "alt", value: "Logo" },
                                          ],
                                          content: "",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "kwNufx",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "footer-9-component-kwNufx"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "gW5tna",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "footer-9-col-container-kwNufx"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "FTwiyY",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-12"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "U3AC5h",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "NwVDIP",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "jnYdL5",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "HsAZ9M",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["footer-9-col-td-wrapper-kwNufx"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "Nobfr8",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["footer-9-text1-kwNufx"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                        { property: "data-uniqueclassname", value: "true" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "© Company name",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "yXg51O",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["footer-9-text2-kwNufx"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                        { property: "data-uniqueclassname", value: "true" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Streetname 1, 1000 XX Amsterdam, The Netherlands",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "f1vYoZ",
                                      type: "element",
                                      htmlTagName: "a",
                                      classes: ["footer-9-link-kwNufx"],
                                      styles: [],
                                      attributes: [
                                        { property: "href", value: "#!" },
                                        { property: "data-href", value: "%UNSUBSCRIBE_LINK%" },
                                        { property: "data-target", value: "_blank" },
                                        { property: "data-editable", value: "true" },
                                        { property: "data-name", value: "a" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Unsubscribe",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  ebCustomCss: [
    {
      componentId: "lceNJz",
      classes: [
        {
          className: "basics-7-component-lceNJz",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
          ],
        },
        {
          className: "basics-7-col-container-lceNJz",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "background-color", value: "rgb(255, 255, 255)" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "basics-7-col-td-wrapper-lceNJz",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0rem 0rem 0rem 0rem" },
            { property: "text-align", value: "start" },
          ],
        },
        {
          className: "basics-7-divider-lceNJz",
          pseudo: "",
          rules: [
            { property: "display", value: "inline-block" },
            { property: "height", value: "15px" },
            { property: "width", value: "100%" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
            { property: "border-radius", value: "0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(230, 230, 230)" },
          ],
        },
      ],
    },
    {
      componentId: "ZQgBqa",
      classes: [
        {
          className: "basics-1-component-ZQgBqa",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
          ],
        },
        {
          className: "basics-1-col-container-ZQgBqa",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "background-color", value: "rgb(255, 255, 255)" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "basics-1-col-td-wrapper-ZQgBqa",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0.5rem 0.5rem 0.5rem 0.5rem" },
            { property: "text-align", value: "start" },
          ],
        },
        {
          className: "basics-1-title-ZQgBqa",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgb(229, 42, 5)" },
            { property: "font-weight", value: "bold" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
          ],
        },
      ],
    },
    {
      componentId: "njc4Y5",
      classes: [
        {
          className: "basics-2-text-njc4Y5",
          pseudo: "",
          rules: [
            { property: "text-align", value: "start" },
            { property: "color", value: "rgb(33, 37, 41)" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "basics-2-component-njc4Y5",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
          ],
        },
        {
          className: "basics-2-col-container-njc4Y5",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "background-color", value: "rgb(255, 255, 255)" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "basics-2-col-td-wrapper-njc4Y5",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0.5rem 0.75rem 0.5rem 0.75rem" },
            { property: "text-align", value: "start" },
          ],
        },
      ],
    },
    {
      componentId: "gWxLBW",
      classes: [
        { className: "basics-5-listwrapper-gWxLBW", pseudo: "", rules: [{ property: "margin", value: "0rem 0 0.25rem 0" }] },
        {
          className: "basics-5-listtext-gWxLBW",
          pseudo: "",
          rules: [
            { property: "text-align", value: "left" },
            { property: "display", value: "inline-block" },
            { property: "margin", value: "0 0 0 0" },
            { property: "vertical-align", value: "middle" },
          ],
        },
        {
          className: "basics-5-listbullet-gWxLBW",
          pseudo: "",
          rules: [
            { property: "height", value: "8px" },
            { property: "width", value: "8px" },
            { property: "background", value: "rgb(229, 42, 5)" },
            { property: "margin-right", value: "0.5rem" },
            { property: "border-radius", value: "0rem" },
            { property: "display", value: "inline-block" },
            { property: "vertical-align", value: "middle" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(229, 42, 5)" },
          ],
        },
        {
          className: "basics-5-component-gWxLBW",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
          ],
        },
        {
          className: "basics-5-col-container-gWxLBW",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "background-color", value: "rgb(255, 255, 255)" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "basics-5-col-td-wrapper-gWxLBW",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0.5rem 0.75rem 0.5rem 0.75rem" },
            { property: "text-align", value: "start" },
          ],
        },
      ],
    },
    {
      componentId: "a2BdRO",
      classes: [
        { className: "cards-6-table-table-a2BdRO", pseudo: "", rules: [] },
        { className: "cards-6-table-row-a2BdRO", pseudo: "", rules: [] },
        { className: "cards-6-table-cell-img-a2BdRO", pseudo: "", rules: [{ property: "width", value: "60px" }] },
        {
          className: "cards-6-table-cell-all-a2BdRO",
          pseudo: "",
          rules: [
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "padding", value: "0px 10px 0px 10px" },
          ],
        },
        {
          className: "cards-6-table-text1-a2BdRO",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(33, 37, 41)" },
            { property: "margin", value: "0rem 0rem 0.25rem 0rem" },
            { property: "text-align", value: "start" },
            { property: "font-weight", value: "bold" },
          ],
        },
        {
          className: "cards-6-table-text2-a2BdRO",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(33, 37, 41)" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "text-align", value: "start" },
          ],
        },
        {
          className: "cards-6-table-text3-a2BdRO",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(33, 37, 41)" },
            { property: "margin", value: "0rem 0rem 0.25rem 0rem" },
            { property: "text-align", value: "start" },
            { property: "font-weight", value: "bold" },
          ],
        },
        {
          className: "cards-6-table-text4-a2BdRO",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(33, 37, 41)" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "text-align", value: "start" },
          ],
        },
        {
          className: "cards-6-img-a2BdRO",
          pseudo: "",
          rules: [
            { property: "outline", value: "none" },
            { property: "-ms-interpolation-mode", value: "bicubic" },
            { property: "width", value: "100%" },
            { property: "height", value: "auto" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "cards-6-component-a2BdRO",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
          ],
        },
        {
          className: "cards-6-col-container-a2BdRO",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "background-color", value: "rgb(255, 255, 255)" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "cards-6-col-td-wrapper-a2BdRO",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0.5rem 0.75rem 0.5rem 0.75rem" },
            { property: "text-align", value: "start" },
          ],
        },
      ],
    },
    {
      componentId: "TJcvym",
      classes: [
        {
          className: "cards-5-title-TJcvym",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(229, 42, 5)" },
            { property: "margin", value: "0rem 0rem 0.5rem 0rem" },
            { property: "text-align", value: "center" },
          ],
        },
        {
          className: "cards-5-btn-TJcvym",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "font-weight", value: "bold" },
            { property: "font-style", value: "normal" },
            { property: "text-decoration", value: "none" },
            { property: "color", value: "rgb(255, 255, 255)" },
            { property: "background", value: "rgb(229, 42, 5)" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(229, 42, 5)" },
            { property: "border-radius", value: "1000px" },
            { property: "padding", value: "0.5rem 0rem 0.5rem 0rem" },
            { property: "display", value: "block" },
            { property: "font-size", value: "16px" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "cards-5-text-TJcvym",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgb(33, 37, 41)" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "cards-5-component-TJcvym",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
          ],
        },
        {
          className: "cards-5-col-container-TJcvym",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "background-color", value: "rgb(255, 255, 255)" },
            { property: "padding-top", value: "2rem" },
            { property: "padding-bottom", value: "2rem" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "cards-5-col-td-wrapper-TJcvym",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0.5rem 0.75rem 0.5rem 0.75rem" },
            { property: "text-align", value: "start" },
          ],
        },
      ],
    },
    {
      componentId: "YgrtCu",
      classes: [
        {
          className: "footer-6-text3-YgrtCu",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(33, 37, 41)" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-align", value: "center" },
          ],
        },
        {
          className: "footer-6-text4-YgrtCu",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(33, 37, 41)" },
            { property: "margin", value: "0rem 0rem 0.25rem 0rem" },
            { property: "text-align", value: "center" },
          ],
        },
        {
          className: "footer-6-text5-YgrtCu",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(33, 37, 41)" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "text-align", value: "center" },
          ],
        },
        {
          className: "footer-6-component-YgrtCu",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
          ],
        },
        {
          className: "footer-6-col-container-YgrtCu",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "background-color", value: "rgb(255, 255, 255)" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "footer-6-col-td-wrapper-YgrtCu",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0.5rem 0.75rem 0.5rem 0.75rem" },
            { property: "text-align", value: "center" },
          ],
        },
        {
          className: "footer-6-bgdiv-YgrtCu",
          pseudo: "",
          rules: [
            { property: "background-color", value: "rgb(230, 42, 5)" },
            { property: "background", value: "rgb(230, 42, 5)" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(243, 205, 108)" },
            { property: "border-radius", value: "0rem" },
          ],
        },
        {
          className: "footer-6-text1-YgrtCu",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgb(255, 255, 255)" },
            { property: "font-weight", value: "bold" },
            { property: "margin", value: "1rem 0rem 1rem 0rem" },
          ],
        },
        {
          className: "footer-6-img-YgrtCu",
          pseudo: "",
          rules: [
            { property: "outline", value: "none" },
            { property: "-ms-interpolation-mode", value: "bicubic" },
            { property: "max-width", value: "100%" },
            { property: "max-height", value: "200px" },
            { property: "width", value: "150px" },
            { property: "height", value: "150px" },
            { property: "margin", value: "0rem 0rem 0.5rem 0rem" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "2px" },
            { property: "border-color", value: "rgb(230, 42, 5)" },
            { property: "border-radius", value: "50%" },
          ],
        },
        {
          className: "footer-6-text2-YgrtCu",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgb(229, 42, 5)" },
            { property: "font-weight", value: "bold" },
            { property: "margin", value: "0rem 0rem 0.25rem 0rem" },
          ],
        },
      ],
    },
    {
      componentId: "kwNufx",
      classes: [
        {
          className: "footer-9-text1-kwNufx",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(108, 117, 125)" },
            { property: "margin", value: "0rem 0rem 0.25rem 0rem" },
            { property: "text-align", value: "center" },
            { property: "font-size", value: "12px" },
          ],
        },
        {
          className: "footer-9-text2-kwNufx",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(108, 117, 125)" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-align", value: "center" },
            { property: "font-size", value: "12px" },
          ],
        },
        {
          className: "footer-9-link-kwNufx",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(108, 117, 125)" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "display", value: "block" },
            { property: "text-align", value: "center" },
            { property: "text-decoration", value: "underline" },
            { property: "font-size", value: "12px" },
          ],
        },
        {
          className: "footer-9-component-kwNufx",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
          ],
        },
        {
          className: "footer-9-col-container-kwNufx",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(234, 237, 240)" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "padding-top", value: "1.5rem" },
            { property: "padding-bottom", value: "3rem" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "footer-9-col-td-wrapper-kwNufx",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0.5rem 0rem 0.5rem 0rem" },
            { property: "text-align", value: "start" },
          ],
        },
      ],
    },
    {
      componentId: "Ujho1L",
      classes: [
        {
          className: "basics-13-linkwrapper-Ujho1L",
          pseudo: "",
          rules: [
            { property: "display", value: "block" },
            { property: "line-height", value: "0 !important" },
          ],
        },
        {
          className: "basics-13-img-Ujho1L",
          pseudo: "",
          rules: [
            { property: "outline", value: "none" },
            { property: "-ms-interpolation-mode", value: "bicubic" },
            { property: "max-width", value: "100%" },
            { property: "max-height", value: "200px" },
            { property: "width", value: "auto" },
            { property: "height", value: "auto" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "0px" },
            { property: "border-color", value: "rgb(0, 0, 0)" },
            { property: "border-radius", value: "0rem" },
          ],
        },
        {
          className: "basics-13-component-Ujho1L",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
          ],
        },
        {
          className: "basics-13-col-container-Ujho1L",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(234, 237, 240)" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "padding-top", value: "1.5rem" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "basics-13-col-td-wrapper-Ujho1L",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0rem 0rem 0rem 0rem" },
            { property: "text-align", value: "center" },
          ],
        },
      ],
    },
    {
      componentId: "zAhlBz",
      classes: [
        {
          className: "basics-2-text-zAhlBz",
          pseudo: "",
          rules: [
            { property: "text-align", value: "start" },
            { property: "color", value: "rgb(229, 42, 5)" },
            { property: "font-weight", value: "bold" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "basics-2-component-zAhlBz",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
          ],
        },
        {
          className: "basics-2-col-container-zAhlBz",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "padding-top", value: "1rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "background-color", value: "rgb(255, 255, 255)" },
            { property: "padding-bottom", value: "0rem" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "basics-2-col-td-wrapper-zAhlBz",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0.5rem 0.75rem 0.5rem 0.75rem" },
            { property: "text-align", value: "start" },
          ],
        },
      ],
    },
    {
      componentId: "tQDj5g",
      classes: [
        { className: "basics-5-list-tdwrapper-tQDj5g", pseudo: "", rules: [{ property: "margin", value: "0rem 0 0.25rem 0" }] },
        {
          className: "basics-5-listwrapper-tQDj5g",
          pseudo: "",
          rules: [
            { property: "text-align", value: "left" },
            { property: "display", value: "inline-block" },
            { property: "margin", value: "0 0 0.5rem 0" },
            { property: "vertical-align", value: "middle" },
          ],
        },
        {
          className: "basics-5-listbullet-tQDj5g",
          pseudo: "",
          rules: [
            { property: "height", value: "8px" },
            { property: "width", value: "8px" },
            { property: "background", value: "rgb(229, 42, 5)" },
            { property: "margin-right", value: "0.5rem" },
            { property: "border-radius", value: "0rem" },
            { property: "display", value: "inline-block" },
            { property: "vertical-align", value: "middle" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(229, 42, 5)" },
          ],
        },
        {
          className: "basics-5-list-text-tQDj5g",
          pseudo: "",
          rules: [
            { property: "display", value: "inline" },
            { property: "margin", value: "0 0 0 0" },
          ],
        },
        {
          className: "basics-5-component-tQDj5g",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
          ],
        },
        {
          className: "basics-5-col-container-tQDj5g",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "background-color", value: "rgb(255, 255, 255)" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "basics-5-col-td-wrapper-tQDj5g",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0.5rem 0.75rem 0.5rem 0.75rem" },
            { property: "text-align", value: "start" },
          ],
        },
      ],
    },
  ],
  ebCssVars: {
    fontHeadersName: "Arial",
    fontHeadersWeight: "400",
    fontBodyName: "Arial",
    fontBodyWeight: "400",
    color1: "rgba(229, 42, 5, 1)",
    color2: "rgba(246, 129, 0, 1)",
    color3: "rgba(40, 167, 69, 1)",
    color4: "rgba(13, 202, 240, 1)",
    color5: "rgba(255, 193, 7, 1)",
    color6: "rgba(220, 53, 69, 1)",
    color7: "rgba(233, 236, 239, 1)",
    color8: "rgba(33, 37, 41, 1)",
    color9: "rgba(206, 212, 218, 1)",
    color10: "rgba(108, 117, 125, 1)",
  },
};
