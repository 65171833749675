export const template9 = {
  id: "660344d91cd7814b91f1dc57",
  name: { en: "Clothing promotion", nl: "Kledingaanbieding" },
  desc: { en: "Promotion of new collection", nl: "Aanbieding van nieuwe collectie kleren" },
  categories: ["product"],
  ebContent: [
    {
      pageId: "45b44cb6-8852-4120-a1ad-7607d5e4dadb",
      version: "A",
      components: [
        {
          componentId: "ArKxOt",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "basics-7-component-ArKxOt"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "zNFikl",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "basics-7-col-container-ArKxOt"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "TJBPkS",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-12"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "tBcMTS",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "uoh3oF",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "ftKLqv",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "Mn9Iu9",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["basics-7-col-td-wrapper-ArKxOt"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-name", value: "divider" },
                                    { property: "data-editable", value: "true" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "O7uKIx",
                                      type: "",
                                      htmlTagName: "div",
                                      classes: ["basics-7-divider-ArKxOt"],
                                      styles: [],
                                      attributes: [{ property: "data-elementgetter1", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "cJ5aOO",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "basics-13-component-cJ5aOO"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "fFC1CO",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "basics-13-col-container-cJ5aOO"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "J7FmBM",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-12"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "PvDTEC",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "a65GuC",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "qo1ljL",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "EWk2tZ",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["basics-13-col-td-wrapper-cJ5aOO"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "pVVIfR",
                                      type: "",
                                      htmlTagName: "a",
                                      classes: ["basics-13-linkwrapper-cJ5aOO"],
                                      styles: [],
                                      attributes: [
                                        { property: "href", value: "#!" },
                                        { property: "data-href", value: "#!" },
                                        { property: "data-target", value: "_blank" },
                                        { property: "data-linkwrapper", value: "true" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "BhQykm",
                                          type: "",
                                          htmlTagName: "img",
                                          classes: ["basics-13-img-cJ5aOO"],
                                          styles: [],
                                          attributes: [
                                            { property: "data-editable", value: "true" },
                                            { property: "data-name", value: "img" },
                                            { property: "src", value: "https://cdn.satonda.com/eb/assets/logo4.png" },
                                            { property: "alt", value: "Image description" },
                                          ],
                                          content: "",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "BtDiwI",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "basics-1-component-BtDiwI"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "yhgkd1",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "basics-1-col-container-BtDiwI"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "roqipP",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-12"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "LTghKb",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "i7Dtrv",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "Oecydf",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "jUKbPA",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["basics-1-col-td-wrapper-BtDiwI"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "ELjF1d",
                                      type: "",
                                      htmlTagName: "h1",
                                      classes: ["basics-1-title-BtDiwI"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "h" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Get your new look!",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "qk7fO6",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "basics-2-component-qk7fO6"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "cTGqY2",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "basics-2-col-container-qk7fO6"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "QycFEp",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-12"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "okxWvd",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "OnXEX3",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "WriTUx",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "dFY6QY",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["basics-2-col-td-wrapper-qk7fO6"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "fl6hGL",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["basics-2-text-qk7fO6"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content:
                                            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sit amet porttitor eget dolor morbi non arcu.",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "d0glqv",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "basics-3-component-d0glqv"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "MQLOe3",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "basics-3-col-container-d0glqv"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "N7gyAE",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-12"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "fq8538",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "tDAUZd",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "xAeqCQ",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "FcZIEJ",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["basics-3-col-td-wrapper-d0glqv"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "wnbRtw",
                                      type: "",
                                      htmlTagName: "a",
                                      classes: ["basics-3-btn-d0glqv"],
                                      styles: [],
                                      attributes: [
                                        { property: "href", value: "#!" },
                                        { property: "data-href", value: "#!" },
                                        { property: "data-target", value: "_blank" },
                                        { property: "data-editable", value: "true" },
                                        { property: "data-name", value: "button" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "See our summer collection!",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "IrQTBy",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "cards-13-component-IrQTBy"],
          styles: [],
          attributes: [{ property: "data-name", value: "col_component" }],
          content: "",
          children: [
            {
              childId: "P7fJlZ",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "cards-13-col-container-IrQTBy"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "WXEiTd",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-6", "vertical-align-top"],
                  styles: [],
                  attributes: [{ property: "data-colLayout", value: "true" }],
                  content: "",
                  children: [
                    {
                      childId: "s96M5V",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "zNAQAz",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "gaOfWk",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "fRzwiw",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["cards-13-col-td-wrapper-IrQTBy"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "jE27Fx",
                                      type: "",
                                      htmlTagName: "img",
                                      classes: ["cards-13-img-IrQTBy"],
                                      styles: [],
                                      attributes: [
                                        { property: "src", value: "https://cdn.satonda.com/eb/assets/product3.jpg" },
                                        { property: "alt", value: "Image description" },
                                        { property: "data-editable", value: "true" },
                                        { property: "data-name", value: "img" },
                                      ],
                                      content: "",
                                      children: [],
                                    },
                                    {
                                      childId: "QnDXEw",
                                      type: "",
                                      htmlTagName: "h3",
                                      classes: ["cards-13-title-IrQTBy"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "h" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Nice shirt",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "RRhMcz",
                                      type: "",
                                      htmlTagName: "div",
                                      classes: ["cards-13-rating-wrapper-IrQTBy"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-name", value: "ratingStars" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "iq0B7Z",
                                          type: "",
                                          htmlTagName: "img",
                                          classes: ["cards-13-rating-star-IrQTBy"],
                                          styles: [],
                                          attributes: [
                                            { property: "src", value: "https://cdn.satonda.com/eb/assets/star-full.png" },
                                            { property: "data-checkparent", value: "true" },
                                            { property: "data-name", value: "img" },
                                          ],
                                          content: "",
                                          children: [],
                                        },
                                        {
                                          childId: "xzqy5b",
                                          type: "",
                                          htmlTagName: "img",
                                          classes: ["cards-13-rating-star-IrQTBy"],
                                          styles: [],
                                          attributes: [
                                            { property: "src", value: "https://cdn.satonda.com/eb/assets/star-full.png" },
                                            { property: "data-checkparent", value: "true" },
                                            { property: "data-name", value: "img" },
                                          ],
                                          content: "",
                                          children: [],
                                        },
                                        {
                                          childId: "usj3Vj",
                                          type: "",
                                          htmlTagName: "img",
                                          classes: ["cards-13-rating-star-IrQTBy"],
                                          styles: [],
                                          attributes: [
                                            { property: "src", value: "https://cdn.satonda.com/eb/assets/star-full.png" },
                                            { property: "data-checkparent", value: "true" },
                                            { property: "data-name", value: "img" },
                                          ],
                                          content: "",
                                          children: [],
                                        },
                                        {
                                          childId: "cNTVDL",
                                          type: "",
                                          htmlTagName: "img",
                                          classes: ["cards-13-rating-star-IrQTBy"],
                                          styles: [],
                                          attributes: [
                                            { property: "src", value: "https://cdn.satonda.com/eb/assets/star-full.png" },
                                            { property: "data-checkparent", value: "true" },
                                            { property: "data-name", value: "img" },
                                          ],
                                          content: "",
                                          children: [],
                                        },
                                        {
                                          childId: "exXnZ9",
                                          type: "",
                                          htmlTagName: "img",
                                          classes: ["cards-13-rating-star-IrQTBy"],
                                          styles: [],
                                          attributes: [
                                            { property: "src", value: "https://cdn.satonda.com/eb/assets/star-empty.png" },
                                            { property: "data-checkparent", value: "true" },
                                            { property: "data-name", value: "img" },
                                          ],
                                          content: "",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "MDE4lv",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["cards-13-text-IrQTBy"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Lorem ipsum dolor sit amet consectetur.",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "JExR4q",
                                      type: "",
                                      htmlTagName: "a",
                                      classes: ["cards-13-btn-IrQTBy"],
                                      styles: [],
                                      attributes: [
                                        { property: "href", value: "#!" },
                                        { property: "data-href", value: "#!" },
                                        { property: "data-target", value: "_blank" },
                                        { property: "data-editable", value: "true" },
                                        { property: "data-name", value: "button" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Order now",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "NhU0x4",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-6", "vertical-align-top"],
                  styles: [],
                  attributes: [{ property: "data-colLayout", value: "true" }],
                  content: "",
                  children: [
                    {
                      childId: "TTK6IU",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "coC10x",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "uZC1il",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "Xttfn0",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["cards-13-col-td-wrapper-IrQTBy"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "Rjdz3V",
                                      type: "",
                                      htmlTagName: "img",
                                      classes: ["cards-13-img-IrQTBy"],
                                      styles: [],
                                      attributes: [
                                        { property: "src", value: "https://cdn.satonda.com/eb/assets/product5.jpg" },
                                        { property: "alt", value: "Image description" },
                                        { property: "data-editable", value: "true" },
                                        { property: "data-name", value: "img" },
                                      ],
                                      content: "",
                                      children: [],
                                    },
                                    {
                                      childId: "jLZdUh",
                                      type: "",
                                      htmlTagName: "h3",
                                      classes: ["cards-13-title-IrQTBy"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "h" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Great shoes",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "v8iD92",
                                      type: "",
                                      htmlTagName: "div",
                                      classes: ["cards-13-rating-wrapper-IrQTBy"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-name", value: "ratingStars" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "lPAxZJ",
                                          type: "",
                                          htmlTagName: "img",
                                          classes: ["cards-13-rating-star-IrQTBy"],
                                          styles: [],
                                          attributes: [
                                            { property: "src", value: "https://cdn.satonda.com/eb/assets/star-full.png" },
                                            { property: "data-checkparent", value: "true" },
                                            { property: "data-name", value: "img" },
                                          ],
                                          content: "",
                                          children: [],
                                        },
                                        {
                                          childId: "k5EO3a",
                                          type: "",
                                          htmlTagName: "img",
                                          classes: ["cards-13-rating-star-IrQTBy"],
                                          styles: [],
                                          attributes: [
                                            { property: "src", value: "https://cdn.satonda.com/eb/assets/star-full.png" },
                                            { property: "data-checkparent", value: "true" },
                                            { property: "data-name", value: "img" },
                                          ],
                                          content: "",
                                          children: [],
                                        },
                                        {
                                          childId: "rJL49B",
                                          type: "",
                                          htmlTagName: "img",
                                          classes: ["cards-13-rating-star-IrQTBy"],
                                          styles: [],
                                          attributes: [
                                            { property: "src", value: "https://cdn.satonda.com/eb/assets/star-full.png" },
                                            { property: "data-checkparent", value: "true" },
                                            { property: "data-name", value: "img" },
                                          ],
                                          content: "",
                                          children: [],
                                        },
                                        {
                                          childId: "CH29XY",
                                          type: "",
                                          htmlTagName: "img",
                                          classes: ["cards-13-rating-star-IrQTBy"],
                                          styles: [],
                                          attributes: [
                                            { property: "src", value: "https://cdn.satonda.com/eb/assets/star-full.png" },
                                            { property: "data-checkparent", value: "true" },
                                            { property: "data-name", value: "img" },
                                          ],
                                          content: "",
                                          children: [],
                                        },
                                        {
                                          childId: "QSHGpz",
                                          type: "",
                                          htmlTagName: "img",
                                          classes: ["cards-13-rating-star-IrQTBy"],
                                          styles: [],
                                          attributes: [
                                            { property: "src", value: "https://cdn.satonda.com/eb/assets/star-half.png" },
                                            { property: "data-checkparent", value: "true" },
                                            { property: "data-name", value: "img" },
                                          ],
                                          content: "",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "n7yXUb",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["cards-13-text-IrQTBy"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Lorem ipsum dolor sit amet consectetur.",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "clayYg",
                                      type: "",
                                      htmlTagName: "a",
                                      classes: ["cards-13-btn-IrQTBy"],
                                      styles: [],
                                      attributes: [
                                        { property: "href", value: "#!" },
                                        { property: "data-href", value: "#!" },
                                        { property: "data-target", value: "_blank" },
                                        { property: "data-editable", value: "true" },
                                        { property: "data-name", value: "button" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Order now",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "ucFHGu",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "footer-4-component-ucFHGu"],
          styles: [],
          attributes: [{ property: "data-name", value: "col_component" }],
          content: "",
          children: [
            {
              childId: "YlwoI8",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "footer-4-col-container-ucFHGu"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "rx6EBs",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-6", "vertical-align-top"],
                  styles: [],
                  attributes: [{ property: "data-colLayout", value: "true" }],
                  content: "",
                  children: [
                    {
                      childId: "ZK1Wm3",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "UD1qmF",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "H23FiO",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "rc6fGr",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["footer-4-col-td-wrapper-ucFHGu"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "ZZ4pnE",
                                      type: "",
                                      htmlTagName: "h1",
                                      classes: ["footer-4-title-ucFHGu"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "h" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Need any help?",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "nNKzsK",
                                      type: "element",
                                      htmlTagName: "a",
                                      classes: ["footer-4-link-ucFHGu"],
                                      styles: [],
                                      attributes: [
                                        { property: "href", value: "#!" },
                                        { property: "data-href", value: "#!" },
                                        { property: "data-target", value: "_blank" },
                                        { property: "data-editable", value: "true" },
                                        { property: "data-name", value: "a" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Contact us",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "nBstvP",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-6", "vertical-align-top"],
                  styles: [],
                  attributes: [{ property: "data-colLayout", value: "true" }],
                  content: "",
                  children: [
                    {
                      childId: "jaZGHr",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "oHMlJc",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "uMgcbR",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "NgQhB5",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["footer-4-col-td-wrapper-ucFHGu"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "EdU68m",
                                      type: "",
                                      htmlTagName: "h1",
                                      classes: ["footer-4-title-ucFHGu"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "h" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Follow us",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "h8HsSO",
                                      type: "block",
                                      htmlTagName: "div",
                                      classes: ["footer-4-wrapper-icons-ucFHGu"],
                                      styles: [],
                                      attributes: [{ property: "data-name", value: "block" }],
                                      content: "",
                                      children: [
                                        {
                                          childId: "RffBY0",
                                          type: "element",
                                          htmlTagName: "a",
                                          classes: ["footer-4-social-link-ucFHGu"],
                                          styles: [],
                                          attributes: [
                                            { property: "href", value: "#!" },
                                            { property: "data-href", value: "#!" },
                                            { property: "data-target", value: "_blank" },
                                            { property: "data-editable", value: "true" },
                                            { property: "data-name", value: "socialIcon" },
                                          ],
                                          content: "",
                                          children: [
                                            {
                                              childId: "I6BTfD",
                                              type: "",
                                              htmlTagName: "img",
                                              classes: ["footer-4-social-icon-ucFHGu"],
                                              styles: [],
                                              attributes: [
                                                { property: "src", value: "https://cdn.satonda.com/eb/assets/facebook-brand-white-square.png" },
                                                { property: "alt", value: "Facebook" },
                                                { property: "data-editable", value: "false" },
                                              ],
                                              content: "",
                                              children: [],
                                            },
                                          ],
                                        },
                                        {
                                          childId: "xHPpUj",
                                          type: "element",
                                          htmlTagName: "a",
                                          classes: ["footer-4-social-link-ucFHGu"],
                                          styles: [],
                                          attributes: [
                                            { property: "href", value: "#!" },
                                            { property: "data-href", value: "#!" },
                                            { property: "data-target", value: "_blank" },
                                            { property: "data-editable", value: "true" },
                                            { property: "data-name", value: "socialIcon" },
                                          ],
                                          content: "",
                                          children: [
                                            {
                                              childId: "N5ALsx",
                                              type: "",
                                              htmlTagName: "img",
                                              classes: ["footer-4-social-icon-ucFHGu"],
                                              styles: [],
                                              attributes: [
                                                { property: "src", value: "https://cdn.satonda.com/eb/assets/twitter-brand-white-square.png" },
                                                { property: "alt", value: "Twitter" },
                                                { property: "data-editable", value: "false" },
                                              ],
                                              content: "",
                                              children: [],
                                            },
                                          ],
                                        },
                                        {
                                          childId: "a8vSaf",
                                          type: "element",
                                          htmlTagName: "a",
                                          classes: ["footer-4-social-link-ucFHGu"],
                                          styles: [],
                                          attributes: [
                                            { property: "href", value: "#!" },
                                            { property: "data-href", value: "#!" },
                                            { property: "data-target", value: "_blank" },
                                            { property: "data-editable", value: "true" },
                                            { property: "data-name", value: "socialIcon" },
                                          ],
                                          content: "",
                                          children: [
                                            {
                                              childId: "Fqi6fq",
                                              type: "",
                                              htmlTagName: "img",
                                              classes: ["footer-4-social-icon-ucFHGu"],
                                              styles: [],
                                              attributes: [
                                                { property: "src", value: "https://cdn.satonda.com/eb/assets/youtube-brand-white-square.png" },
                                                { property: "alt", value: "Youtube" },
                                                { property: "data-editable", value: "false" },
                                              ],
                                              content: "",
                                              children: [],
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "e4IoUW",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "footer-10-component-e4IoUW"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "VXT2nm",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "footer-10-col-container-e4IoUW"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "qQWSvr",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-12"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "tnisxn",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "iTxa3Z",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "UIXADH",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "cbg5JI",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["footer-10-col-td-wrapper-e4IoUW"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "Ny6ll3",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["footer-10-text1-e4IoUW"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                        { property: "data-uniqueclassname", value: "true" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "© 2023 Company name. All rights reserved.",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "vTCek6",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["footer-10-text2-e4IoUW"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                        { property: "data-uniqueclassname", value: "true" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "You receive this email because you're registered in our database as a subscriber.",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "oPMBHg",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["footer-10-text3-e4IoUW"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                        { property: "data-uniqueclassname", value: "true" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Company name",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "wGHfC6",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["footer-10-text4-e4IoUW"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                        { property: "data-uniqueclassname", value: "true" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Streetname 1",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "eazlb4",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["footer-10-text5-e4IoUW"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                        { property: "data-uniqueclassname", value: "true" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "1000 LA Amsterdam",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "uQDx0z",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["footer-10-text6-e4IoUW"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                        { property: "data-uniqueclassname", value: "true" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "The Netherlands",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "nWqVQV",
                                      type: "element",
                                      htmlTagName: "a",
                                      classes: ["footer-10-link-e4IoUW"],
                                      styles: [],
                                      attributes: [
                                        { property: "href", value: "#!" },
                                        { property: "data-href", value: "%UNSUBSCRIBE_LINK%" },
                                        { property: "data-target", value: "_blank" },
                                        { property: "data-editable", value: "true" },
                                        { property: "data-name", value: "a" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Unsubscribe",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  ebCustomCss: [
    {
      componentId: "ArKxOt",
      classes: [
        {
          className: "basics-7-component-ArKxOt",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
          ],
        },
        {
          className: "basics-7-col-container-ArKxOt",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "background-color", value: "rgb(255, 255, 255)" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "basics-7-col-td-wrapper-ArKxOt",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0rem 0rem 0rem 0rem" },
            { property: "text-align", value: "start" },
          ],
        },
        {
          className: "basics-7-divider-ArKxOt",
          pseudo: "",
          rules: [
            { property: "display", value: "inline-block" },
            { property: "height", value: "25px" },
            { property: "width", value: "100%" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
            { property: "border-radius", value: "0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(230, 230, 230)" },
          ],
        },
      ],
    },
    {
      componentId: "cJ5aOO",
      classes: [
        {
          className: "basics-13-linkwrapper-cJ5aOO",
          pseudo: "",
          rules: [
            { property: "display", value: "block" },
            { property: "line-height", value: "0 !important" },
          ],
        },
        {
          className: "basics-13-img-cJ5aOO",
          pseudo: "",
          rules: [
            { property: "outline", value: "none" },
            { property: "-ms-interpolation-mode", value: "bicubic" },
            { property: "max-width", value: "100%" },
            { property: "max-height", value: "200px" },
            { property: "width", value: "200px" },
            { property: "height", value: "auto" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "0px" },
            { property: "border-color", value: "rgb(0, 0, 0)" },
            { property: "border-radius", value: "0rem" },
          ],
        },
        {
          className: "basics-13-component-cJ5aOO",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
          ],
        },
        {
          className: "basics-13-col-container-cJ5aOO",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-top", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(234, 237, 240)" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "padding-bottom", value: "0.5rem" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "basics-13-col-td-wrapper-cJ5aOO",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0.5rem 0rem 0.5rem 0rem" },
            { property: "text-align", value: "center" },
          ],
        },
      ],
    },
    {
      componentId: "BtDiwI",
      classes: [
        {
          className: "basics-1-component-BtDiwI",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
          ],
        },
        {
          className: "basics-1-col-container-BtDiwI",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "background-color", value: "rgb(255, 255, 255)" },
            { property: "padding-top", value: "0.5rem" },
            { property: "border-radius", value: "0.75rem 0.75rem 0 0" },
          ],
        },
        {
          className: "basics-1-col-td-wrapper-BtDiwI",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0.5rem 0.5rem 0.5rem 0.5rem" },
            { property: "text-align", value: "start" },
          ],
        },
        {
          className: "basics-1-title-BtDiwI",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgb(191, 70, 251000)" },
            { property: "font-weight", value: "bold" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
          ],
        },
      ],
    },
    {
      componentId: "qk7fO6",
      classes: [
        {
          className: "basics-2-component-qk7fO6",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
          ],
        },
        {
          className: "basics-2-col-container-qk7fO6",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "background-color", value: "rgb(255, 255, 255)" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "basics-2-col-td-wrapper-qk7fO6",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0.5rem 0.75rem 0.5rem 0.75rem" },
            { property: "text-align", value: "start" },
          ],
        },
        {
          className: "basics-2-text-qk7fO6",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(33, 37, 41)" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
          ],
        },
      ],
    },
    {
      componentId: "d0glqv",
      classes: [
        {
          className: "basics-3-component-d0glqv",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
          ],
        },
        {
          className: "basics-3-col-container-d0glqv",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "background-color", value: "rgb(255, 255, 255)" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "basics-3-btn-d0glqv",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "font-weight", value: "bold" },
            { property: "font-style", value: "normal" },
            { property: "text-decoration", value: "none" },
            { property: "color", value: "rgb(255, 255, 255)" },
            { property: "background", value: "rgb(191, 70, 251000)" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(191, 70, 251000)" },
            { property: "border-radius", value: "1000px" },
            { property: "padding", value: "0.5rem 0rem 0.5rem 0rem" },
            { property: "display", value: "block" },
            { property: "font-size", value: "16px" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "basics-3-col-td-wrapper-d0glqv",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0.5rem 0.75rem 0.5rem 0.75rem" },
            { property: "text-align", value: "start" },
          ],
        },
      ],
    },
    {
      componentId: "QFGeDc",
      classes: [
        { className: "cards-4-component-QFGeDc", pseudo: "", rules: [{ property: "padding", value: "0 10px 0 10px" }] },
        {
          className: "cards-4-col-container-QFGeDc",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "background-color", value: "rgb(255, 255, 255)" },
            { property: "border-radius", value: "0 0 0 0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
          ],
        },
        {
          className: "cards-4-col-td-wrapper-QFGeDc",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "text-align", value: "start" },
            { property: "padding", value: "0.5rem 0.5rem 0.5rem 0.5rem" },
          ],
        },
        {
          className: "cards-4-title-QFGeDc",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(191, 70, 251000)" },
            { property: "margin", value: "0rem 0rem 0.5rem 0rem" },
            { property: "text-align", value: "center" },
          ],
        },
        {
          className: "cards-4-text-QFGeDc",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(33, 37, 41)" },
            { property: "margin", value: "0rem 0rem 0.5rem 0rem" },
            { property: "text-align", value: "center" },
          ],
        },
        {
          className: "cards-4-btn-QFGeDc",
          pseudo: "",
          rules: [
            { property: "background", value: "rgb(191, 70, 251000)" },
            { property: "color", value: "rgb(255, 255, 255)" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(191, 70, 251000)" },
            { property: "border-radius", value: "0.2rem" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "padding", value: "0.5rem 0rem 0.5rem 0rem" },
            { property: "text-decoration", value: "none" },
            { property: "text-align", value: "center" },
            { property: "font-weight", value: "bold" },
            { property: "display", value: "block" },
          ],
        },
        {
          className: "cards-4-img-QFGeDc",
          pseudo: "",
          rules: [
            { property: "outline", value: "none" },
            { property: "-ms-interpolation-mode", value: "bicubic" },
            { property: "max-width", value: "100%" },
            { property: "width", value: "100%" },
            { property: "max-height", value: "200px" },
            { property: "height", value: "auto" },
            { property: "margin", value: "0rem 0rem 0.5rem 0rem" },
          ],
        },
      ],
    },
    {
      componentId: "IrQTBy",
      classes: [
        {
          className: "cards-13-title-IrQTBy",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(191, 70, 251000)" },
            { property: "margin", value: "0rem 0rem 0.5rem 0rem" },
            { property: "text-align", value: "center" },
            { property: "font-weight", value: "bold" },
          ],
        },
        {
          className: "cards-13-text-IrQTBy",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(33, 37, 41)" },
            { property: "margin", value: "0.5rem 0rem 0.5rem 0rem" },
            { property: "text-align", value: "center" },
          ],
        },
        {
          className: "cards-13-btn-IrQTBy",
          pseudo: "",
          rules: [
            { property: "background", value: "rgb(191, 70, 251000)" },
            { property: "color", value: "rgb(255, 255, 255)" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(191, 70, 251000)" },
            { property: "border-radius", value: "1rem" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "padding", value: "0.5rem 0rem 0.5rem 0rem" },
            { property: "text-decoration", value: "none" },
            { property: "text-align", value: "center" },
            { property: "font-weight", value: "bold" },
            { property: "display", value: "block" },
          ],
        },
        {
          className: "cards-13-img-IrQTBy",
          pseudo: "",
          rules: [
            { property: "outline", value: "none" },
            { property: "-ms-interpolation-mode", value: "bicubic" },
            { property: "max-width", value: "100%" },
            { property: "width", value: "100%" },
            { property: "max-height", value: "200px" },
            { property: "height", value: "auto" },
            { property: "margin", value: "0rem 0rem 0.5rem 0rem" },
            { property: "border-radius", value: "1rem" },
          ],
        },
        { className: "cards-13-rating-wrapper-IrQTBy", pseudo: "", rules: [{ property: "display", value: "block" }] },
        {
          className: "cards-13-rating-star-IrQTBy",
          pseudo: "",
          rules: [
            { property: "outline", value: "none" },
            { property: "-ms-interpolation-mode", value: "bicubic" },
            { property: "width", value: "auto" },
            { property: "height", value: "16px" },
            { property: "margin", value: "0rem 0.125rem 0rem 0rem" },
            { property: "display", value: "inline-block" },
          ],
        },
        {
          className: "cards-13-component-IrQTBy",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
          ],
        },
        {
          className: "cards-13-col-container-IrQTBy",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "padding-bottom", value: "1.5rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "background-color", value: "rgb(255, 255, 255)" },
            { property: "padding-top", value: "1.5rem" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "cards-13-col-td-wrapper-IrQTBy",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0.5rem 0.75rem 0.5rem 0.75rem" },
            { property: "text-align", value: "center" },
          ],
        },
      ],
    },
    {
      componentId: "ucFHGu",
      classes: [
        {
          className: "footer-4-title-ucFHGu",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(255, 255, 255)" },
            { property: "margin", value: "0rem 0rem 1.5rem 0rem" },
            { property: "text-align", value: "start" },
            { property: "font-weight", value: "bold" },
          ],
        },
        {
          className: "footer-4-link-ucFHGu",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(255, 255, 255)" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "display", value: "block" },
            { property: "text-decoration", value: "underline" },
          ],
        },
        {
          className: "footer-4-wrapper-icons-ucFHGu",
          pseudo: "",
          rules: [
            { property: "text-align", value: "start" },
            { property: "display", value: "block" },
          ],
        },
        {
          className: "footer-4-social-link-ucFHGu",
          pseudo: "",
          rules: [
            { property: "display", value: "inline-block" },
            { property: "text-decoration", value: "none" },
            { property: "margin", value: "0rem 1rem 0rem 0rem" },
          ],
        },
        { className: "footer-4-social-link-ucFHGu", pseudo: "last-child", rules: [{ property: "margin-right", value: "0" }] },
        {
          className: "footer-4-social-icon-ucFHGu",
          pseudo: "",
          rules: [
            { property: "outline", value: "none" },
            { property: "-ms-interpolation-mode", value: "bicubic" },
            { property: "width", value: "32px" },
            { property: "height", value: "32px" },
            { property: "border-radius", value: "50%" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(255, 255, 255)" },
          ],
        },
        {
          className: "footer-4-component-ucFHGu",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
          ],
        },
        {
          className: "footer-4-col-container-ucFHGu",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-top", value: "1rem" },
            { property: "padding-bottom", value: "1rem" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(108, 3, 160)" },
            { property: "background-color", value: "rgb(108, 3, 160)" },
            { property: "border-radius", value: "0 0 0.75rem 0.75rem" },
          ],
        },
        {
          className: "footer-4-col-td-wrapper-ucFHGu",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0.5rem 0.75rem 0.5rem 0.75rem" },
            { property: "text-align", value: "start" },
          ],
        },
      ],
    },
    {
      componentId: "e4IoUW",
      classes: [
        {
          className: "footer-10-text1-e4IoUW",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(108, 117, 125)" },
            { property: "margin", value: "0rem 0rem 0.125rem 0rem" },
            { property: "text-align", value: "center" },
            { property: "font-size", value: "12px" },
          ],
        },
        {
          className: "footer-10-text2-e4IoUW",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(108, 117, 125)" },
            { property: "margin", value: "0rem 0rem 0.125rem 0rem" },
            { property: "text-align", value: "center" },
            { property: "font-size", value: "12px" },
          ],
        },
        {
          className: "footer-10-text3-e4IoUW",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(108, 117, 125)" },
            { property: "margin", value: "1rem 0rem 0.125rem 0rem" },
            { property: "text-align", value: "center" },
            { property: "font-size", value: "12px" },
          ],
        },
        {
          className: "footer-10-text4-e4IoUW",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(108, 117, 125)" },
            { property: "margin", value: "0rem 0rem 0.125rem 0rem" },
            { property: "text-align", value: "center" },
            { property: "font-size", value: "12px" },
          ],
        },
        {
          className: "footer-10-text5-e4IoUW",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(108, 117, 125)" },
            { property: "margin", value: "0rem 0rem 0.125rem 0rem" },
            { property: "text-align", value: "center" },
            { property: "font-size", value: "12px" },
          ],
        },
        {
          className: "footer-10-text6-e4IoUW",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(108, 117, 125)" },
            { property: "margin", value: "0rem 0rem 0.25rem 0rem" },
            { property: "text-align", value: "center" },
            { property: "font-size", value: "12px" },
          ],
        },
        {
          className: "footer-10-link-e4IoUW",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(108, 117, 125)" },
            { property: "margin", value: "1rem 0rem 0rem 0rem" },
            { property: "display", value: "block" },
            { property: "text-align", value: "center" },
            { property: "text-decoration", value: "underline" },
            { property: "font-size", value: "12px" },
          ],
        },
        {
          className: "footer-10-component-e4IoUW",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
          ],
        },
        {
          className: "footer-10-col-container-e4IoUW",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(234, 237, 240)" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "padding-top", value: "2rem" },
            { property: "padding-bottom", value: "3rem" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "footer-10-col-td-wrapper-e4IoUW",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0.5rem 0rem 0.5rem 0rem" },
            { property: "text-align", value: "start" },
          ],
        },
      ],
    },
  ],
  ebCssVars: {
    fontHeadersName: "Arial",
    fontHeadersWeight: "400",
    fontBodyName: "Arial",
    fontBodyWeight: "400",
    color1: "rgb(191, 70, 251)",
    color2: "rgba(246, 129, 0, 1)",
    color3: "rgba(40, 167, 69, 1)",
    color4: "rgba(13, 202, 240, 1)",
    color5: "rgba(255, 193, 7, 1)",
    color6: "rgba(220, 53, 69, 1)",
    color7: "rgba(233, 236, 239, 1)",
    color8: "rgba(33, 37, 41, 1)",
    color9: "rgba(206, 212, 218, 1)",
    color10: "rgba(108, 117, 125, 1)",
  },
};
