import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Tooltip from "./Tooltip";

import { EDIT_FORM_ID_PREFIX } from "../../../../../lib/editFunctions";

import { openColorPicker } from "../../../../../actions/eb";

import { translate } from "../../../../../translations/translations";

const ColorPicker = ({ label, id, startValue, openColorPicker, tooltipText }) => {
  return (
    <div className="w-100 d-flex align-items-center my-3">
      <div className="w-50">
        {label}
        <Tooltip text={tooltipText} />
      </div>
      <div
        className="w-50 d-flex justify-content-center cursorPointer border border-light"
        style={{
          height: "24px",
          // background: `url("/assets/img/transparant-square.png")`
        }}
        onClick={() => openColorPicker(translate("cEditForms.selectColor", false, null), startValue, EDIT_FORM_ID_PREFIX + id)}
        data-bs-toggle="modal"
        data-bs-target="#ModalColorPicker"
      >
        <div className="w-100 h-100" data-editform="colorpicker" id={EDIT_FORM_ID_PREFIX + id} style={{ backgroundColor: startValue }}></div>
      </div>
    </div>
  );
};

ColorPicker.propTypes = {
  openColorPicker: PropTypes.func.isRequired,
};

export default connect(null, { openColorPicker })(ColorPicker);
