import store from "../store";

export const getState = (slice, stateVar) => {
  if (stateVar === "") {
    return store.getState()[slice];
  } else {
    return store.getState()[slice][stateVar];
  }
};

export const getInternalLinksToSelectFrom = (ebContent) => {
  let linksToSelectFrom = [];
  ebContent.forEach((page) => {
    if (page.version === "A") {
      // Add page
      linksToSelectFrom.push(`/${page.pageLink}.html`);
      // Add page's sections
      page.components.forEach((component) => {
        if (component.attributes.filter((attr) => attr.property === "id").length > 0) {
          linksToSelectFrom.push(`/${page.pageLink}.html#${component.attributes.filter((attr) => attr.property === "id")[0].value}`);
        }
      });
    }
  });
  return linksToSelectFrom;
};
