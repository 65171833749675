export const mImgGalleryCrop = {
  croppingImg: {
    en: "Cropping image..",
    nl: "Afbeelding bijsnijden..",
  },
  cropSuccess: {
    en: "Image has been cropped. You may need to clear the page's cache before you see the cropped image.",
    nl: "Afbeelding bijgesneden. Mogelijk moet u uw browser's cachegeheuren vernieuwen voor de bijgesneden afbeelding zichtbaar wordt.",
  },
  errorWhileCropping: {
    en: "Error while cropping image on server. Please try again.",
    nl: "Server fout tijdens het bijsnijden van de afbeelding. Probeer het aub opnieuw.",
  },
  cropImage: {
    en: "Crop image",
    nl: "Snij afbeelding bij",
  },
  cropOptions: {
    en: "Crop options",
    nl: "Opties",
  },
  aspectRatio: {
    en: "Aspect ratio",
    nl: "Beeldverhouding",
  },
  free: {
    en: "Free",
    nl: "Vrije vorm",
  },
  square: {
    en: "Square",
    nl: "Vierkant",
  },
  cropForm: {
    en: "Crop form",
    nl: "Vorm",
  },
  circular: {
    en: "Circular",
    nl: "Cirkel",
  },
  showRulers: {
    en: "Show rulers",
    nl: "Lineaal tonen",
  },
  yes: {
    en: "Yes",
    nl: "Ja",
  },
  no: {
    en: "No",
    nl: "Nee",
  },
  lockedSize: {
    en: "Locked size",
    nl: "Vaste grootte",
  },
  cancel: {
    en: "Cancel",
    nl: "Annuleren",
  },
};
