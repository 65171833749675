import { translate } from "../../translations/translations";

export const steps = [
  // Step 1
  {
    selector: `[data-tour="full-page"]`,
    content: () => (
      <>
        <p className="mb-2">{translate("lProductTour.step1_intro", false, null)}</p>
        {/* <p className="mb-2">
          For more specific walkthroughs of how to use Emailbuilder,{" "}
          <a href="https://www.satonda.com" target="_blank" rel="noopener noreferrer" className="trans-3">
            click here
          </a>{" "}
          to go to our knowledge base.
        </p> */}
      </>
    ),
  },
  // Step 2
  {
    selector: `[data-tour="top-menu"]`,
    content: () => (
      <>
        <p className="mb-2">{translate("lProductTour.step2_menu", false, null)}</p>
      </>
    ),
  },
  // Step 3
  {
    selector: `[data-tour="save-and-exit"]`,
    content: () => (
      <>
        <p className="mb-2">{translate("lProductTour.step3_exit", false, null)}</p>
      </>
    ),
  },
  // Step 4
  {
    selector: `[data-tour="active-page-version"]`,
    content: () => (
      <>
        <p className="mb-2">{translate("lProductTour.step4_name", false, null)}</p>
      </>
    ),
  },
  // Step 5
  {
    selector: `[data-tour="site-page-options"]`,
    content: () => (
      <>
        <p className="mb-2">{translate("lProductTour.step5_emailOptions", false, null)}</p>
      </>
    ),
  },
  // Step 6
  {
    selector: `[data-tour="change-screen-size"]`,
    content: () => (
      <>
        <p className="mb-2">{translate("lProductTour.step6_screenSize", false, null)}</p>
      </>
    ),
  },
  // Step 7
  {
    selector: `[data-tour="undo-redo"]`,
    content: () => (
      <>
        <p className="mb-2">{translate("lProductTour.step7_undoRedo", false, null)}</p>
      </>
    ),
  },
  // Step 8
  {
    selector: `[data-tour="save-project"]`,
    content: () => (
      <>
        <p className="mb-2">{translate("lProductTour.step8_save", false, null)}</p>
      </>
    ),
  },
  // Step 9
  {
    selector: `[data-tour="eb-editor-menu"]`,
    content: () => (
      <>
        <p className="mb-2">{translate("lProductTour.step9_editPane", false, null)}</p>
      </>
    ),
  },
  // Step 10
  {
    selector: `[data-tour="browse-templates"]`,
    content: () => (
      <>
        <p className="mb-2">{translate("lProductTour.step10_browseTemplates", false, null)}</p>
      </>
    ),
  },
  // Step 11
  {
    selector: `[data-tour="open-img-gallery"]`,
    content: () => (
      <>
        <p className="mb-2">{translate("lProductTour.step11_imgGallery", false, null)}</p>
      </>
    ),
  },
  // Step 12
  {
    selector: `[data-tour="eb-settings"]`,
    content: () => (
      <>
        <p className="mb-2">{translate("lProductTour.step12_appSettings", false, null)}</p>
      </>
    ),
  },
  // Step 13
  {
    selector: `[data-tour="site-style"]`,
    // TODO: Open accordion. Maybe as a ref and simulate a ref.current.click()
    content: () => (
      <>
        <p className="mb-2">{translate("lProductTour.step13_styles", false, null)}</p>
        <p className="mb-2">{translate("lProductTour.step13_colors", false, null)}</p>
        <p className="mb-2">{translate("lProductTour.step13_fonts", false, null)}</p>
      </>
    ),
  },
  // Step 14
  {
    selector: `[data-tour="add-component"]`,
    // TODO: Open accordion. Maybe as a ref and simulate a ref.current.click()
    content: () => (
      <>
        <p className="mb-2">{translate("lProductTour.step14_component", false, null)}</p>
      </>
    ),
  },
  // Step 15
  {
    selector: `[data-tour="edit-component"]`,
    // TODO: Open accordion. Maybe as a ref and simulate a ref.current.click()
    content: () => (
      <>
        <p className="mb-2">{translate("lProductTour.step15_editComponent", false, null)}</p>
      </>
    ),
  },
  // Step 16
  {
    selector: `[data-tour="editor-canvas"]`,
    content: () => (
      <>
        <p className="mb-2">{translate("lProductTour.step16_editorCanvas", false, null)}</p>
        <p className="mb-2">{translate("lProductTour.step16_selectElement", false, null)}</p>
        <p className="mb-2">{translate("lProductTour.step16_componentButtons", false, null)}</p>
        <ul>
          <li>
            {translate("lProductTour.step16_reorder1", false, null)} (<span className="fontSize08 text-success fa-solid fa-arrow-up me-2"></span>
            <span className="fontSize08 text-success fa-solid fa-arrow-down"></span>): {translate("lProductTour.step16_reorder2", false, null)}
          </li>
          <li>
            {translate("lProductTour.step16_edit1", false, null)} (<span className="fontSize08 text-success fa-solid fa-pencil"></span>):{" "}
            {translate("lProductTour.step16_edit2", false, null)}
          </li>
          <li>
            {translate("lProductTour.step16_delete1", false, null)} (<span className="fontSize08 text-danger fa-regular fa-trash-can"></span>):{" "}
            {translate("lProductTour.step16_delete2", false, null)}
          </li>
        </ul>
        <p className="mb-2">
          {translate("lProductTour.step16_elementButtons", false, null)} (<span className="fontSize08 text-success fa-solid fa-plus"></span>){" "}
          {translate("lProductTour.step16_elementRemove", false, null)} (<span className="fontSize08 text-danger fa-regular fa-trash-can"></span>)
        </p>
      </>
    ),
  },
  // Step 17
  {
    selector: `[data-tour="tour-end"]`,
    content: () => (
      <>
        <p className="mb-2">{translate("lProductTour.step17_experiment", false, null)}</p>
        {/* <p className="mb-2">
          You can click{" "}
          <a href="https://www.satonda.com" target="_blank" rel="noopener noreferrer" className="trans-3">
            here
          </a>{" "}
          to visit your knowledge base with more detailed walkthroughs of specific functionality of sitebuilder.
        </p> */}
      </>
    ),
  },
];
