import { FixedCss } from "./css/FixedCss";
import { parseComponentHtml, parseCss } from "./parse";
import { EDIT_COMPONENT_ACTIONS_CLASS, EDIT_ELEMENT_ACTIONS_CLASS, TAGNAME_COMMENT } from "./generalVars";

// ========================
// == Exported functions ==
// ========================

export const exportMail = (project, ebContent, ebCustomCss, ebCssVars) => {
  return ebContent.map((page) => ({
    version: page.version,
    html: getHtml(page.components, ebCustomCss, ebCssVars, project.mailMeta),
    plainText: getPlainText(page.components),
  }));
};

// =============
// == Helpers ==
// =============

const getPlainText = (arrComponents) => {
  // Pull out all content strings from the components as well as alt descriptions for images
  let plainText = "";
  arrComponents.forEach((component) => {
    // let componentPlainText = getAllPlainText(component).join("\n\n");
    // plainText += componentPlainText;
    // componentPlainText !== "" && (plainText += "\n\n");
    plainText += getAllPlainText(component).join("");
  });
  return plainText;
};

const getAllPlainText = (elementObj) => {
  try {
    if (elementObj.content !== "") {
      // eslint-disable-next-line
      return elementObj.htmlTagName === "span" ? `${elementObj.content}\s` : `${elementObj.content}\n\n`;
    }
    if (elementObj.htmlTagName === "img") {
      return `${elementObj.attributes.filter((attr) => attr.property === "alt")[0].value}\n\n`;
    }
    if (elementObj.children.length > 0) {
      let found = [];
      elementObj.children.forEach((child) => {
        found.push(getAllPlainText(child));
      });
      // Take out null/undefined and flatten array
      return [].concat(...found.filter((el) => el !== null && typeof el !== "undefined"));
    }
    return [];
  } catch (error) {
    return [];
  }
};

const getHtml = (arrComponents, ebCustomCss, ebCssVars, mailMeta) => {
  // https://templates.mailchimp.com/development/css/client-specific-styles/
  // https://www.goodemailcode.com/email-code/template.html
  let html = `<!DOCTYPE html>
  <html
    lang="${mailMeta.langCode}"
    dir="ltr"
    xmlns="http://www.w3.org/1999/xhtml"
    xmlns:o="urn:schemas-microsoft-com:office:office"
    xmlns:v="urn:schemas-microsoft-com:vml"
  >
    <head>${getHead(ebCustomCss, ebCssVars)}</head><body xml:lang="${mailMeta.langCode}">${getBody(arrComponents, mailMeta)}</body></html>`;
  return sanitizeHtml(html);
};

const getHead = (ebCustomCss, ebCssVars) => {
  // Add boilerplate
  let head = `<title>%EMAIL_SUBJECT%</title>
  <meta property="og:title" content="" />
  <meta name="twitter:title" content="" />
  <meta name="format-detection" content="telephone=no, date=no, address=no, email=no, url=no" />
  <meta name="x-apple-disable-message-reformatting" />
  <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
  <meta http-equiv="X-UA-Compatible" content="IE=edge" />
  <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  
  <!--[if gte mso 9]>
      <xml>
        <o:OfficeDocumentSettings>
          <o:AllowPNG />
          <o:PixelsPerInch>96</o:PixelsPerInch>
        </o:OfficeDocumentSettings>
      </xml>

      <style>
        ul > li {
          text-indent: -1em;
        }
      </style>
    <![endif]-->
    <!--[if mso]>
      <style type="text/css">
        body,
        td {
          font-family: Arial, Helvetica, sans-serif;
        }
      </style>
    <![endif]-->
    <meta name="robots" content="noindex,follow" />`;
  // Add dynamic css
  // head += `<style type="text/css">${compileCssVarsObj(ebCssVars, true) + FixedCss + parseCss(ebCustomCss)}</style>`;
  head += `<style type="text/css">${applyCssVars(ebCssVars, FixedCss + parseCss(ebCustomCss))}</style>`;
  // Add other boilerplate
  head += `<![endif]-->
    <style type="text/css">
      body[data-outlook-cycle] img.stretch-on-mobile {
        height: auto !important;
        width: 100% !important;
      }
      body[data-outlook-cycle] {
        padding-left: 10px !important;
        padding-right: 10px !important;
      }
      a[x-apple-data-detectors] {
        color: inherit !important;
        text-decoration: none !important;
        font-size: inherit !important;
        font-family: inherit !important;
        font-weight: inherit !important;
        line-height: inherit !important;
      }
      #outlook a {
        padding: 0;
      }
      .yshortcuts a {
        border-bottom: none !important;
      }
      a {
        text-decoration: underline;
      }
      .ExternalClass {
        width: 100%;
      }
      .ExternalClass,
      .ExternalClass p,
      .ExternalClass td,
      .ExternalClass div,
      .ExternalClass span,
      .ExternalClass font {
        line-height: 100%;
      }
      p {
        margin: 0;
      }
      body {
        -ms-text-size-adjust: 100%;
        -webkit-text-size-adjust: 100%;
        -webkit-font-smoothing: antialiased;
        moz-osx-font-smoothing: grayscale;
      }
    </style>`;
  return head;
};

const getBody = (arrComponents, mailMeta) => {
  return openBodyBoilerplate(mailMeta) + getBodyContent(arrComponents) + closeBodyBoilerplate();
};

const openBodyBoilerplate = (mailMeta) => {
  return `<!--[if gte mso 9]>
  <v:background xmlns:v="urn:schemas-microsoft-com:vml" fill="t">
    <v:fill type="tile" size="100%,100%" color="#ffffff" />
  </v:background>
<![endif]-->

<div lang="${mailMeta.langCode}" dir="ltr">
  <table
    role="presentation"
    style="
      border-spacing: 0 !important;
      border-collapse: collapse;
      mso-table-lspace: 0pt;
      mso-table-rspace: 0pt;
      margin: 0;
      padding: 0;
      width: 100% !important;
      min-width: 320px !important;
      height: 100% !important;
    "
    width="100%"
    height="100%"
    cellspacing="0"
    cellpadding="0"
  >
    <tbody>
      <tr>
        <td
          style="
            border-collapse: collapse;
            mso-line-height-rule: exactly;
            word-break: break-word;
          "
          valign="top"
        >`;
};

const closeBodyBoilerplate = () => {
  return `</td></tr></tbody></table></div>`;
};

const getBodyContent = (arrComponents) => {
  return arrComponents.map((component) => parseComponentHtml(addOutlookComments(component), false)).join("");
};

const addOutlookComments = (component) => {
  // Add the following content before .column-container <div> opening tag
  //    <!--[if !((mso)|(IE))]>
  // Add the following content after .column-container <div> opening tag
  //    <!--<![endif]-->
  //    <!--[if (mso)|(IE)]><div class="column-container" style="min-width:280px;max-width:600px;width:100%;Margin-left:auto;Margin-right:auto;border-collapse:collapse;border-spacing:0;"><table align="center" style="border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt;width:600px;" cellpadding="0" cellspacing="0" role="presentation" width="600"><tr><![endif]-->
  // Add the following content before the .column-container </div> closing tag
  //    <!--[if (mso)|(IE)]></tr></table><![endif]-->

  // Add the following content before .column.col-[x] <div> opening tag (with 600px to be dependent on the .col-[x] class)
  //    <!--[if (mso)|(IE)]><td valign="top" style="width:600px;"><![endif]-->
  //    <!--[if gte mso 9]><table role="presentation" width="600" cellpadding="0" cellspacing="0" style="border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt;width:600px"><![endif]-->
  // Add the following content after .column.col-[x] </div> closing tag
  //    <!--[if gte mso 9]></table><![endif]-->
  //    <!--[if (mso)|(IE)]></td><![endif]-->

  const CLASS_COL_CONTAINER = "column-container";
  const CLASS_COL = "column";
  try {
    if (component.children.length > 0 && component.children[0].classes.includes(CLASS_COL_CONTAINER)) {
      // Before .column-container opening tag => insert comment object before column-container object
      return { ...component, children: [getCommentObj(`[if !((mso)|(IE))]`), ...component.children.map((child) => addOutlookComments(child))] };
    }
    if (component.classes.includes(CLASS_COL_CONTAINER)) {
      let cols = component.children.map((child, i) => ({ pos: i, obj: child })).filter((child) => child.obj.classes.includes(CLASS_COL));
      // After .column-container opening tag => insert comment object as column-container's first children
      let newChildrenArr = [
        getCommentObj(`<![endif]`),
        getCommentObj(
          `[if (mso)|(IE)]><div class="column-container" style="min-width:280px;max-width:600px;width:100%;Margin-left:auto;Margin-right:auto;border-collapse:collapse;border-spacing:0;"><table align="center" style="border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt;width:600px;" cellpadding="0" cellspacing="0" role="presentation" width="600"><tr><![endif]`
        ),
        ...component.children,
      ];
      let posTracker = 2;
      // Add comments related to the .columns
      cols.forEach((col) => {
        let colSize = 600 / (12 / parseInt(col.obj.classes.filter((className) => className.match(/col-\d+/) !== null)[0].match(/col-(\d+)/)[1]));
        newChildrenArr = arrInsertAt(
          newChildrenArr,
          posTracker,
          getCommentObj(`[if (mso)|(IE)]><td valign="top" style="width:${colSize}px;"><![endif]`)
        );
        posTracker++;
        newChildrenArr = arrInsertAt(
          newChildrenArr,
          posTracker,
          getCommentObj(
            `[if gte mso 9]><table role="presentation" width="${colSize}" cellpadding="0" cellspacing="0" style="border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt;width:${colSize}px"><![endif]`
          )
        );
        posTracker += 2;
        newChildrenArr = arrInsertAt(newChildrenArr, posTracker, getCommentObj(`[if gte mso 9]></table><![endif]`));
        posTracker++;
        newChildrenArr = arrInsertAt(newChildrenArr, posTracker, getCommentObj(`[if (mso)|(IE)]></td><![endif]`));
        posTracker++;
      });
      // Before .column-container closing tag => insert comment object as column-container's last children
      newChildrenArr = [...newChildrenArr, getCommentObj(`[if (mso)|(IE)]></tr></table><![endif]`)];
      return {
        ...component,
        children: newChildrenArr,
      };
    }
    return { ...component, children: component.children.map((child) => addOutlookComments(child)) };
  } catch (error) {
    console.error(error);
    return component;
  }
};

const sanitizeHtml = (html) => {
  html = enableLinks(html);
  html = removeEbDataAttrs(html);
  html = removeEditButtons(html);
  // Remove empty closing tags (weird leftover from parse function..)
  html = html.replaceAll(/<\/>/g, "");
  // Remove unnecesary spaces
  html = html.replaceAll(/"\s+>/g, `">`);
  html = html.replaceAll(/="\s+/g, `="`);
  html = html.replaceAll(/\s+"/g, `"`);
  html = html.replaceAll(/\s+>/g, `>`);
  html = html.replaceAll(/\s{2,}/g, ` `);
  return html;
};

const removeEbDataAttrs = (html) => {
  const EB_DATA_ATTRS = [
    // Core attributes
    "data-ebtype",
    "data-componentid",
    "data-id",
    "data-name",
    "data-editable",
    "data-texteditable",
    "data-imgresizable",
    "data-checkparent",
    // Getters/wrappers
    "data-elementgetter1",
    "data-elementgetter2",
    "data-elementgetter3",
    "data-elementgetter4",
    "data-elementgetter5",
    "data-linkwrapper",
    "data-linkelement",
    "data-collayout",
    "data-listwrapper",
    "data-listbullet",
    "data-listtext",
    "data-tablecell",
    // Other
    "data-uniqueclassname",
  ];
  EB_DATA_ATTRS.forEach((attr) => {
    let re = new RegExp(`${attr}=".*?"`, "gi");
    html = html.replace(re, "");
  });
  return html;
};

const removeEditButtons = (html) => {
  // Ensure to 1) account for strange spaces after having deleted data- attributes before and 2) end with 2 closing div tags to capture the full block
  let re = new RegExp(`<div\\s+class="${EDIT_COMPONENT_ACTIONS_CLASS}"\\s+>.+?<\\/div><\\/div>`, "g");
  html = html.replace(re, "");
  re = new RegExp(`<div\\s+class="${EDIT_ELEMENT_ACTIONS_CLASS}"\\s+>.+?<\\/div><\\/div>`, "g");
  html = html.replace(re, "");
  return html;
};

const enableLinks = (html) => {
  let linkTags = [...html.matchAll(/<a .+?>/g)].map((match) => match[0]);
  linkTags.forEach((link) => {
    if (link.includes(`data-linkwrapper="true"`)) {
      // Move up the ID of a linkelement to its actual link wrapper a tag so it can be caught in Google Analytics URL
      let re = new RegExp(`${link}(.+?)<\\/a>`);
      let linkInsideHtml = (html.match(re) || ["", ""])[1];
      let foundId = (linkInsideHtml.match(/\sid="(.+?)"\s/) || ["", ""])[1];
      let replace = link.replace(">", ` id="${foundId}">`);
      html = html.replace(link, replace);
      // Update link string so that the part below, updating its destination, continues to work. Else it won't pick up the string as it was changed
      link = replace;
    }
    // Find destination
    let dest = (link.match(/data-href="(.+?)"/) || ["#!", "#!"])[1];
    // Update link
    let replace = link
      .replace(/data-href=".+?"/, "")
      .replace(/href=".+?"/, `href="${dest}"`)
      .replace(/data-target="/, `target="`);
    html = html.replace(link, replace);
  });
  return html;
};

const getCommentObj = (commentContent) => {
  return {
    childId: "",
    type: "",
    htmlTagName: TAGNAME_COMMENT,
    classes: [],
    styles: [],
    attributes: [],
    content: commentContent,
    children: [],
  };
};

const arrInsertAt = (arr, pos, itemToInsert) => [...arr.slice(0, pos), itemToInsert, ...arr.slice(pos)];

const applyCssVars = (ebCssVars, css) => {
  const { fontHeadersName, fontHeadersWeight, fontBodyName, fontBodyWeight } = ebCssVars;
  return css
    .replaceAll(`var(--font-body)`, `"${fontBodyName}", sans-serif`)
    .replaceAll(`var(--font-body-weight)`, fontBodyWeight)
    .replaceAll(`var(--font-headers)`, `"${fontHeadersName}", sans-serif`)
    .replaceAll(`var(--font-headers-weight)`, fontHeadersWeight)
    .replaceAll(`var(--body-bg)`, "rgb(255, 255, 255)")
    .replaceAll(`var(--body-line-height)`, "1.5");
};
