import { v4 as uuidv4 } from "uuid";
import { templateComponents } from "./components";
import { getRandomId, getRandomString } from "./domFunctions";
import {
  ATTR_EB_TYPE,
  TAGNAME_TEXT_NODE,
  TAGNAME_COMMENT,
  EDIT_COMPONENT_ACTIONS_TYPE,
  EDIT_COMPONENT_ACTIONS_CLASS,
  EDIT_COMPONENT_ACTION_ICON_TYPE,
  COMPONENT_MOVEUP_TYPE,
  COMPONENT_MOVEUP_CLASS,
  COMPONENT_MOVEDOWN_TYPE,
  COMPONENT_MOVEDOWN_CLASS,
  COMPONENT_EDIT_TYPE,
  COMPONENT_EDIT_CLASS,
  COMPONENT_DELETE_TYPE,
  COMPONENT_DELETE_CLASS,
  EDIT_ELEMENT_ACTIONS_TYPE,
  EDIT_ELEMENT_ACTIONS_CLASS,
  ELEMENT_DUPLICATE_TYPE,
  ELEMENT_DUPLICATE_CLASS,
  ELEMENT_EDIT_TYPE,
  ELEMENT_EDIT_CLASS,
  ELEMENT_DELETE_TYPE,
  ELEMENT_DELETE_CLASS,
  COL_REORDER_TYPE,
  COL_REORDER_CLASS,
} from "./generalVars";
import { getAllChildrenUniqueClassnames } from "./componentObjectFunctions";
import { translate } from "../translations/translations";

// const templatePages = [
//   {
//     pageId: "",
//     version: "A || B",
//     pageLink: "",
//     pageTitle: "",
//     pageDesc: "",
//     components: [
//       {
//         componentId: "",
//         type: "component",
//         htmlTagName: "div",
//         classes: ["", ""],
//         styles: [{ property: "", value: "" }],
//         attributes: [{ property: "", value: "" }],
//         content: "text in case of textNode/comment or empty otherwise",
//         children: [
//           {
//             childId: "",
//             type: "block || element",
//             htmlTagName: "div || element's tag name",
//             classes: ["", ""],
//             styles: [{ property: "", value: "" }],
//             attributes: [{ property: "", value: "" }],
//             content: "text in case of textNode/comment or empty otherwise",
//             children: [
//               {
//                 childId: "",
//                 type: "block || element",
//                 htmlTagName: "div || element's tag name",
//                 classes: ["", ""],
//                 styles: [{ property: "", value: "" }],
//                 attributes: [{ property: "", value: "" }],
//                 content: "text in case of textNode/comment or empty otherwise",
//                 children: [],
//               },
//             ],
//           },
//         ],
//       },
//     ],
//   },
// ];

// const templateCustomCss = [
//   {
//     componentId: "",
//     classes: [
//       {
//         className: "",
//         pseudo: "",
//         rules: [{ property: "", value: "" }],
//       },
//     ],
//   },
// ];

const EDIT_BUTTONS_COMPONENT = {
  childId: "",
  type: EDIT_COMPONENT_ACTIONS_TYPE,
  htmlTagName: "div",
  classes: [EDIT_COMPONENT_ACTIONS_CLASS],
  styles: [],
  attributes: [],
  content: "",
  children: [
    {
      childId: "",
      type: COMPONENT_MOVEUP_TYPE,
      htmlTagName: "div",
      classes: [COMPONENT_MOVEUP_CLASS],
      styles: [],
      attributes: [{ property: "title", value: translate("lParse.moveUpComponent", false, null) }],
      content: "",
      children: [
        {
          childId: "",
          type: EDIT_COMPONENT_ACTION_ICON_TYPE,
          htmlTagName: "span",
          classes: ["fa-solid", "fa-arrow-up"],
          styles: [],
          attributes: [],
          content: "",
          children: [],
        },
      ],
    },
    {
      childId: "",
      type: COMPONENT_MOVEDOWN_TYPE,
      htmlTagName: "div",
      classes: [COMPONENT_MOVEDOWN_CLASS],
      styles: [],
      attributes: [{ property: "title", value: translate("lParse.moveDownComponent", false, null) }],
      content: "",
      children: [
        {
          childId: "",
          type: EDIT_COMPONENT_ACTION_ICON_TYPE,
          htmlTagName: "span",
          classes: ["fa-solid", "fa-arrow-down"],
          styles: [],
          attributes: [],
          content: "",
          children: [],
        },
      ],
    },
    {
      childId: "",
      type: COMPONENT_EDIT_TYPE,
      htmlTagName: "div",
      classes: [COMPONENT_EDIT_CLASS],
      styles: [],
      attributes: [{ property: "title", value: translate("lParse.editComponent", false, null) }],
      content: "",
      children: [
        {
          childId: "",
          type: EDIT_COMPONENT_ACTION_ICON_TYPE,
          htmlTagName: "span",
          classes: ["fa-solid", "fa-pencil"],
          styles: [],
          attributes: [],
          content: "",
          children: [],
        },
      ],
    },
    {
      childId: "",
      type: COMPONENT_DELETE_TYPE,
      htmlTagName: "div",
      classes: [COMPONENT_DELETE_CLASS],
      styles: [],
      attributes: [{ property: "title", value: translate("lParse.removeComponent", false, null) }],
      content: "",
      children: [
        {
          childId: "",
          type: EDIT_COMPONENT_ACTION_ICON_TYPE,
          htmlTagName: "span",
          classes: ["fa-regular", "fa-trash-can"],
          styles: [],
          attributes: [],
          content: "",
          children: [],
        },
      ],
    },
  ],
};

const EDIT_BUTTONS_ELEMENT = {
  childId: "",
  type: EDIT_ELEMENT_ACTIONS_TYPE,
  htmlTagName: "div",
  classes: [EDIT_ELEMENT_ACTIONS_CLASS],
  styles: [],
  attributes: [],
  content: "",
  children: [
    {
      childId: "",
      type: ELEMENT_EDIT_TYPE,
      htmlTagName: "div",
      classes: [ELEMENT_EDIT_CLASS],
      styles: [],
      attributes: [{ property: "title", value: translate("lParse.editElement", false, null) }],
      content: "",
      children: [
        {
          childId: "",
          type: EDIT_COMPONENT_ACTION_ICON_TYPE,
          htmlTagName: "span",
          classes: ["fa-solid", "fa-pencil"],
          styles: [],
          attributes: [],
          content: "",
          children: [],
        },
      ],
    },
    {
      childId: "",
      type: ELEMENT_DUPLICATE_TYPE,
      htmlTagName: "div",
      classes: [ELEMENT_DUPLICATE_CLASS],
      styles: [],
      attributes: [{ property: "title", value: translate("lParse.duplicateElement", false, null) }],
      content: "",
      children: [
        {
          childId: "",
          type: EDIT_COMPONENT_ACTION_ICON_TYPE,
          htmlTagName: "span",
          classes: ["fa-solid", "fa-plus"],
          styles: [],
          attributes: [],
          content: "",
          children: [],
        },
      ],
    },
    {
      childId: "",
      type: ELEMENT_DELETE_TYPE,
      htmlTagName: "div",
      classes: [ELEMENT_DELETE_CLASS],
      styles: [],
      attributes: [{ property: "title", value: translate("lParse.removeElement", false, null) }],
      content: "",
      children: [
        {
          childId: "",
          type: EDIT_COMPONENT_ACTION_ICON_TYPE,
          htmlTagName: "span",
          classes: ["fa-regular", "fa-trash-can"],
          styles: [],
          attributes: [],
          content: "",
          children: [],
        },
      ],
    },
  ],
};

const REORDER_BUTTONS_COL = {
  childId: "",
  type: EDIT_ELEMENT_ACTIONS_TYPE,
  htmlTagName: "div",
  classes: [EDIT_ELEMENT_ACTIONS_CLASS],
  styles: [],
  attributes: [],
  content: "",
  children: [
    {
      childId: "",
      type: COL_REORDER_TYPE,
      htmlTagName: "div",
      classes: [COL_REORDER_CLASS],
      styles: [],
      attributes: [{ property: "title", value: translate("lParse.reorderColumns", false, null) }],
      content: "",
      children: [
        {
          childId: "",
          type: EDIT_COMPONENT_ACTION_ICON_TYPE,
          htmlTagName: "span",
          classes: ["fa-solid", "fa-right-left"],
          styles: [],
          attributes: [],
          content: "",
          children: [],
        },
      ],
    },
  ],
};

export const parseComponentHtml = (component, boolEditButtons) => {
  if (boolEditButtons) {
    // Add edit buttons to the component if html is being parsed for editing
    component = addComponentButtons(component);
  }
  // Get the HTML string for a selected component
  let openingTags = [];
  let closingTags = [];
  // Get HTML string for component wrapper
  let { htmlTagName, componentId, classes, styles, attributes, content, children, type } = component;
  openingTags.push(getOpeningTag(htmlTagName, componentId, classes, styles, attributes, content, type));
  closingTags.push(getClosingTag(htmlTagName));
  // Loop through component's children recursively
  let { openingChildTags, closingChildTags } = getChildTags(children, `${componentId}-`);
  openingTags.push(openingChildTags);
  closingTags.push(closingChildTags);
  return getHtmlString(openingTags, closingTags, "", "");
};

export const getSectionClassname = (component) => {
  // For all components (except sectionDividers and navbars), add the [name]-[id]-component class to the section to set the right padding-top/bottom
  // For navbars, add the position to the section ("" || "fixed-top" || "sticky-top")
  // return `section ${component.classes.filter((className) => className.match(/-component-/) !== null)[0] || ""} ${
  //   (component.attributes.filter((attr) => attr.property === "data-navbarposition")[0] || { value: "" }).value
  // }`.replace(/\s+$/, "");
  return "";
};

export const parseCss = (arrCustomCss, boolAddImportant = false) => {
  // Get whole mail's custom css
  let css = "";
  arrCustomCss.forEach((component) => {
    component.classes.forEach((cssClass) => {
      let pseudoClass = cssClass.pseudo === "" ? "" : `:${cssClass.pseudo}`;
      let cssRules = cssClass.rules.map((rule) => `${rule.property}: ${rule.value}${boolAddImportant ? " !important" : ""};`).join(" ");
      css += `.${cssClass.className}${pseudoClass} { ${cssRules} } `;
    });
  });
  return css;
};

export const convertHtml = (componentDiv, isebtypeComponent) => {
  return convert_prepareElementObj(componentDiv, isebtypeComponent);
};

export const convertCss = (cssString) => {
  return {
    componentId: "",
    classes: convert_getCssClasses(cssString),
  };
};

export const getComponentHtmlCss = (idSelectedComponentFromMenu, newComponentId) => {
  let selectedComponent = templateComponents.filter((component) => component.id === idSelectedComponentFromMenu)[0];
  selectedComponent = randomizeComponentClassnames(selectedComponent, newComponentId);
  let html = selectedComponent.html;
  let css = selectedComponent.css;
  html = { ...html, componentId: newComponentId };
  html = populateComponentChildIds(html);
  html = setContactFormId(html);
  css = { ...css, componentId: newComponentId };
  return { html, css };
};

// ================================
// == parseComponentHtml helpers ==
// ================================

const addComponentButtons = (component) => {
  // Component - Move up, move down, select component, delete component
  component = { ...component, children: [...component.children, EDIT_BUTTONS_COMPONENT] };
  // Elements - Select, duplicate and delete element
  component = { ...component, children: component.children.map((child) => addElementButtons(child)) };
  return component;
};

const addElementButtons = (ebElement) => {
  // If ebElement is reorderable       => add reorder buttons
  if (elementIsReorderableCol(ebElement)) {
    if (ebElement.children.length > 0) {
      return { ...ebElement, children: [...ebElement.children.map((child) => addElementButtons(child)), REORDER_BUTTONS_COL] };
    }
    return { ...ebElement, children: [REORDER_BUTTONS_COL] };
  }
  // If ebElement === element          => add buttons
  // If ebElement has children         => call recursively
  // If not an element and no children => return ebElement
  if (ebElement.type === "element") {
    if (ebElement.htmlTagName === "tr") {
      // table rows can't have children => put the buttons in its last td
      // no further recursive call, so there can't be any nested blocks/elements
      return {
        ...ebElement,
        children: ebElement.children.map((child, i) => {
          if (i === ebElement.children.length - 1) {
            return { ...child, children: [...child.children, EDIT_BUTTONS_ELEMENT] };
          }
          return child;
        }),
      };
    }
    if (ebElement.children.length > 0) {
      return { ...ebElement, children: [...ebElement.children.map((child) => addElementButtons(child)), EDIT_BUTTONS_ELEMENT] };
    }
    return { ...ebElement, children: [EDIT_BUTTONS_ELEMENT] };
  }
  if (ebElement.children.length > 0) {
    return { ...ebElement, children: ebElement.children.map((child) => addElementButtons(child)) };
  }
  return ebElement;
};

const getChildTags = (childElements, trackerId, isElementOfLinkWrapper = false) => {
  // trackerId = tracker of cumulative element IDs (e.g. [componentId]-[blockId]-[elementId])
  let openingTags = [];
  let closingTags = [];
  // Loop through the childElements
  childElements.forEach((childElement) => {
    let { childId, type, htmlTagName, classes, styles, attributes, content, children } = childElement;
    if (isElementOfLinkWrapper) {
      // If element is part of a link wrapper, add data-linkelement attribute for tracking
      attributes = [...attributes, { property: "data-linkelement", value: "true" }];
    } else {
      // If not part of a link wrapper, check whether the element itself is a link wrapper
      isElementOfLinkWrapper = elementIsLinkWrapper(childElement);
    }
    // Prepare opening and closing tags
    openingTags.push(getOpeningTag(htmlTagName, `${trackerId}${childId}`, classes, styles, attributes, content, type));
    // closingTags.unshift(getClosingTag(htmlTagName));
    closingTags.push(getClosingTag(htmlTagName));
    // If the child has children itself, get those tags recursively
    if (children.length > 0) {
      let { openingChildTags, closingChildTags } = getChildTags(children, `${trackerId}${childId}-`, isElementOfLinkWrapper);
      openingTags.push(openingChildTags);
      closingTags.push(closingChildTags);
    }
  });
  return { openingChildTags: openingTags, closingChildTags: closingTags };
};

const getHtmlString = (openingTags, closingTags, prevLevelClosingTags, htmlStringTracker) => {
  openingTags.forEach((openingTag, i) => {
    // Get opening tag
    if (Array.isArray(openingTag)) {
      htmlStringTracker = getHtmlString(openingTag, closingTags[i], closingTags[i - 1] || [], htmlStringTracker);
    } else {
      htmlStringTracker += openingTag;
    }
    if (!Array.isArray(openingTag) && (!Array.isArray(openingTags[i + 1]) || typeof openingTags[i + 1] === "undefined")) {
      // Deepest level => self-closing tag of current element (if not a textNode/comment)
      // htmlStringTracker += `</${(openingTag.match(/^<(.+?)[\s|>]/) || ["", ""])[1]}>`;
      // Revise regex to \w to exclude !-- comments
      htmlStringTracker += `</${(openingTag.match(/^<(\w+?)[\s|>]/) || ["", ""])[1]}>`;
    }
    if (typeof openingTags[i + 1] === "undefined") {
      // Close wrapper => closing tag of prior element
      htmlStringTracker += prevLevelClosingTags;
    }
  });
  return htmlStringTracker;
};

const getOpeningTag = (htmlTagName, id, classes, styles, attributes, content, type) => {
  // If a textNode/comment, return its content. Otherwise, prepare an opening HTML tag
  if (htmlTagName === TAGNAME_TEXT_NODE) {
    return content;
  }
  if (htmlTagName === TAGNAME_COMMENT) {
    return `<!--${content}-->`;
  }
  return `<${htmlTagName} ${getId(id)} ${getClasses(classes)} ${getStyles(styles)} ${getAttributes(attributes)} ${ATTR_EB_TYPE}="${type}">`.replace(
    /\s+/g,
    " "
  );
};

const getClosingTag = (htmlTagName) => {
  // If a textNode/comment, return an empty string as there's no closing tag
  if (htmlTagName === TAGNAME_TEXT_NODE || htmlTagName === TAGNAME_COMMENT) {
    return "";
  }
  return `</${htmlTagName}>`;
};

const getId = (id) => {
  // return id === "" ? "" : `id="${id}"`;
  return id === "" ? "" : `data-id="${id}"`;
};

const getClasses = (arrClasses) => {
  return arrClasses.length === 0 ? "" : `class="${arrClasses.join(" ")}"`;
};

const getStyles = (arrStyles) => {
  return arrStyles.length === 0 ? "" : `style="${arrStyles.map((style) => `${style.property}: ${style.value};`).join(" ")}"`;
};

const getAttributes = (arrAttributes) => {
  // return arrAttributes.length === 0 ? "" : arrAttributes.map((attr) => `${attr.property}="${attr.value}"`).join(" ");
  const JSON_ATTRS = ["data-splide", "data-masonry"];
  return arrAttributes.length === 0
    ? ""
    : arrAttributes
        .map((attr) => (JSON_ATTRS.includes(attr.property) ? `${attr.property}='${attr.value}'` : `${attr.property}="${attr.value}"`))
        .join(" ");
};

const elementIsReorderableCol = (ebElement) => {
  const CLASSES_REORDER = ["col-2", "col-3", "col-4", "col-5", "col-6", "col-7", "col-8", "col-9", "col-10"];
  return ebElement.classes.some((className) => CLASSES_REORDER.includes(className));
};

const elementIsLinkWrapper = (ebElement) => {
  return ebElement.attributes.filter((attr) => attr.property === "data-linkwrapper" && attr.value === "true").length > 0;
};

// =========================
// == convertHtml helpers ==
// =========================

const convert_prepareElementObj = (element, isebtypeComponent = false) => {
  let idPropName = isebtypeComponent ? "componentId" : "childId";
  let htmlTagName = convert_getElementTagname(element);
  return {
    // [idPropName]: htmlTagName === TAGNAME_TEXT_NODE ? "" : convert_getId(element.attributes),
    // type: htmlTagName === TAGNAME_TEXT_NODE ? "" : convert_getElementebtype(element.attributes),
    // htmlTagName,
    // classes: htmlTagName === TAGNAME_TEXT_NODE ? [] : convert_getClassList(element.classList),
    // styles: htmlTagName === TAGNAME_TEXT_NODE ? [] : convert_getStyles(element.attributes),
    // attributes: htmlTagName === TAGNAME_TEXT_NODE ? [] : convert_getAttributes(element.attributes),
    // content: htmlTagName === TAGNAME_TEXT_NODE ? element.textContent : "",
    // children: htmlTagName === TAGNAME_TEXT_NODE ? [] : convert_getChildren(element),
    [idPropName]: [TAGNAME_TEXT_NODE, TAGNAME_COMMENT].includes(htmlTagName) ? "" : convert_getId(element.attributes),
    type: [TAGNAME_TEXT_NODE, TAGNAME_COMMENT].includes(htmlTagName) ? "" : convert_getElementebtype(element.attributes),
    htmlTagName,
    classes: [TAGNAME_TEXT_NODE, TAGNAME_COMMENT].includes(htmlTagName) ? [] : convert_getClassList(element.classList),
    styles: [TAGNAME_TEXT_NODE, TAGNAME_COMMENT].includes(htmlTagName) ? [] : convert_getStyles(element.attributes),
    attributes: [TAGNAME_TEXT_NODE, TAGNAME_COMMENT].includes(htmlTagName) ? [] : convert_getAttributes(element.attributes),
    content: [TAGNAME_TEXT_NODE, TAGNAME_COMMENT].includes(htmlTagName) ? element.textContent : "",
    children: [TAGNAME_TEXT_NODE, TAGNAME_COMMENT].includes(htmlTagName) ? [] : convert_getChildren(element),
  };
};

const convert_getId = (elementAttributes) => {
  try {
    // If set, data-id has format: data-id="DEZLxk-UvNAkI-Rn26YH-DojwDD-Q25ber"
    let elementId = [...elementAttributes].filter((attr) => attr.name === "data-id")[0].value.split("-");
    return elementId[elementId.length - 1];
  } catch (error) {
    return "";
  }
};

const convert_getClassList = (domClassList) => {
  try {
    return [...domClassList];
  } catch (error) {
    return [];
  }
};

const convert_getStyles = (elementAttributes) => {
  try {
    // Format: [{ property: "", value: "" }]
    return [...elementAttributes]
      .filter((attr) => attr.name === "style")[0]
      .value.split("; ")
      .map((style) => ({ property: style.split(": ")[0], value: style.split(": ")[1].replace(";", "") }));
  } catch (error) {
    return [];
  }
};

const convert_getAttributes = (elementAttributes) => {
  try {
    const IGNORE_ATTRS = [ATTR_EB_TYPE, "data-id", "style", "class"];
    // Format: [{ property: "", value: "" }]
    return [...elementAttributes].filter((attr) => !IGNORE_ATTRS.includes(attr.name)).map((attr) => ({ property: attr.name, value: attr.value }));
  } catch (error) {
    return [];
  }
};

const convert_getElementebtype = (elementAttributes) => {
  try {
    return [...elementAttributes].filter((attr) => attr.name === ATTR_EB_TYPE)[0].value;
  } catch (error) {
    return "";
  }
};

const convert_getElementTagname = (element) => {
  return element.nodeName === "#text" ? TAGNAME_TEXT_NODE : element.nodeName === "#comment" ? TAGNAME_COMMENT : element.localName;
};

const convert_getChildren = (element) => {
  let children = [];
  [...element.childNodes].forEach((childNode) => {
    children.push(convert_prepareElementObj(childNode));
  });
  return children;
};

// ========================
// == convertCss helpers ==
// ========================

const convert_getCssClasses = (cssString) => {
  cssString = cssString.replace(/\s+/gm, " ");
  try {
    return [...cssString.matchAll(/\.(.+?) { (.+?) }/gm)].map((className) => ({
      className: className[1].split(":")[0],
      // pseudo: className[1].split(":")[1] || "",
      // Cover a case like: .process-2-wrapper:hover > *:first-child {
      pseudo: className[1].replace(className[1].split(":")[0], "").replace(":", ""),
      rules: className[2].split("; ").map((rule) => ({ property: rule.split(": ")[0], value: rule.split(": ")[1].replace(";", "") })),
    }));
  } catch (error) {
    return [];
  }
};

// =================================
// == getComponentHtmlCss helpers ==
// =================================

const populateComponentChildIds = (component) => {
  let componentChildren = [];
  component.children.forEach((child) => {
    componentChildren.push(populateElementIds(child, false));
  });
  return { ...component, children: componentChildren };
};

export const populateElementIds = (element, regenerateIds) => {
  // if (element.htmlTagName !== TAGNAME_TEXT_NODE && (element.childId === "" || regenerateIds)) {
  //   element.childId = getRandomId();
  // }
  if (![TAGNAME_TEXT_NODE, TAGNAME_COMMENT].includes(element.htmlTagName) && (element.childId === "" || regenerateIds)) {
    element.childId = getRandomId();
  }
  let elementChildren = [];
  element.children.forEach((child) => {
    elementChildren.push(populateElementIds(child, regenerateIds));
  });
  return { ...element, children: elementChildren };
};

const randomizeComponentClassnames = (selectedComponent, newComponentId) => {
  // Append all classnames with the newComponentId to allow user to add the same component at different places on the mail with different styles
  try {
    // Get all unique classnames used in CSS
    let allClassnames = [
      ...new Set(
        [...new Set(selectedComponent.css.classes.map((className) => className.className))].map((className) => {
          // Remove className [xxx] such as card-1-wrapper li
          if (className.match(/([\w|-]+)\s/) !== null) {
            className = className.match(/([\w|-]+)\s/)[1];
          }
          // Remove [xxx].className such as section.card-1-component
          if (className.match(/\.([\w|-]+)/) !== null) {
            className = className.match(/\.([\w|-]+)/)[1];
          }
          return className;
        })
      ),
    ];
    // Update all instances of the unique classnames and add the componentId
    let htmlJson = JSON.stringify(selectedComponent.html);
    let cssJson = JSON.stringify(selectedComponent.css);
    allClassnames.forEach((className) => {
      // Regex has to find the full classname, until either:
      //   1) a '"' closing
      //   2) a ' ' for certain css rules when styling element children
      //   3) a '.' for css rules with double classes on an element
      //   4) a '\' to capture classes object in the slide options JSON
      // eslint-disable-next-line
      let re = new RegExp(`${className}("|\\s|\.|\)`, "g");
      htmlJson = htmlJson.replace(re, `${className}-${newComponentId}$1`);
      cssJson = cssJson.replace(re, `${className}-${newComponentId}$1`);
    });
    return { ...selectedComponent, html: JSON.parse(htmlJson), css: JSON.parse(cssJson) };
  } catch (error) {
    console.error(error);
    return selectedComponent;
  }
};

const setContactFormId = (component) => {
  if (component.attributes.filter((attr) => attr.property === "data-ebformid" && attr.value === "").length > 0) {
    return {
      ...component,
      attributes: component.attributes.map((attr) => (attr.property === "data-ebformid" ? { ...attr, value: uuidv4() } : attr)),
    };
  } else {
    return { ...component, children: component.children.map((c) => setContactFormId(c)) };
  }
};

// =====================
// == Reducer helpers ==
// =====================

export const duplicate_getElementsRequiringUniqueClassnames = (duplicatedElement, customCss) => {
  try {
    // Get classnames for each node with data-uniqueclassname=true
    let uniqueClassnames = getAllChildrenUniqueClassnames(duplicatedElement, customCss);
    // Return array of [ { oldClassname: "", newClassname: "" }, ... ]
    return uniqueClassnames.map((foundClassname) => ({ oldClassname: foundClassname, newClassname: getRandomString(16) }));
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const duplicate_renewClassnamesHtml = (duplicatedElement, duplicateUniqueClassnames) => {
  // duplicateUniqueClassnames = [ { oldClassname: "", newClassname: "" }, ... ]
  // replace all instances of each oldClassname with its newClassname
  try {
    let htmlJson = JSON.stringify(duplicatedElement);
    duplicateUniqueClassnames.forEach((className) => {
      // Regex has to find the full classname, until either a '"' closing or a ' ' for certain css rules when styling element children
      let re = new RegExp(`${className.oldClassname}("|\\s)`, "g");
      htmlJson = htmlJson.replace(re, `${className.newClassname}$1`);
    });
    return JSON.parse(htmlJson);
  } catch (error) {
    console.error(error);
    return duplicatedElement;
  }
};

export const renewClassnamesNewVersion = (arrPageComponents, arrOldComponentIds, arrNewComponentIds) => {
  try {
    let htmlJson = JSON.stringify(arrPageComponents);
    arrOldComponentIds.forEach((componentId, i) => {
      let re = new RegExp(`-${componentId}("|\\s)`, "g");
      htmlJson = htmlJson.replace(re, `-${arrNewComponentIds[i]}$1`);
    });
    return JSON.parse(htmlJson);
  } catch (error) {
    console.error(error);
    return arrPageComponents;
  }
};

export const getRatingStarImg = (strType, arrClasses) => {
  // strType = "full" || "half" || "empty"
  return {
    childId: "",
    type: "",
    htmlTagName: "img",
    classes: arrClasses,
    styles: [],
    attributes: [
      { property: "src", value: `https://cdn.satonda.com/eb/assets/star-${strType}.png` },
      { property: "data-checkparent", value: "true" },
      { property: "data-name", value: "img" },
    ],
    content: "",
    children: [],
  };
};
