import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { v4 as uuidv4 } from "uuid";

import { addNewVersion, setActivePage, deleteVersion, setMailMetaValue } from "../../actions/eb";
import { getSubscriptionMaxVersions, getVersionNameFromIndex } from "../../lib/generalFunctions";
import { metaLangCodes } from "../../lib/metaLangCodes";
import { DYNAMIC_CONTENT_VARS } from "../../lib/generalVars";
import { getRandomId } from "../../lib/domFunctions";
import { renewClassnamesNewVersion } from "../../lib/parse";
import { translate } from "../../translations/translations";

const ModalPageOptions = ({
  auth: { workspace },
  eb: { project, ebContent, ebCustomCss, activePageId },
  addNewVersion,
  setActivePage,
  deleteVersion,
  setMailMetaValue,
}) => {
  const [selectedMenuItem, setSelectedMenuItem] = useState("selectVersion");
  const [localAlert, setLocalAlert] = useState(null);

  const closeBtn = useRef();

  const clickClose = () => {
    closeBtn.current.click();
  };

  const mailMetaVal = (metaVar) => {
    return project !== null && typeof project.mailMeta !== "undefined" && typeof project.mailMeta[metaVar] !== "undefined"
      ? project.mailMeta[metaVar]
      : "";
  };

  // Mail lang code meta data
  const MailLangCode = () => {
    useEffect(() => {
      let langCode = mailMetaVal("langCode");
      if (langCode !== "") {
        let currLang = metaLangCodes.filter((lang) => lang.subtag === langCode)[0];
        setLangCodesToShow([currLang]);
        // setSearchTerm(currLang.description);
        setSelectedLangCode(langCode);
      }
      // eslint-disable-next-line
    }, []);

    const [searchTerm, setSearchTerm] = useState("");
    const [langCodesToShow, setLangCodesToShow] = useState([]);
    const [selectedLangCode, setSelectedLangCode] = useState("");

    const onType = (val) => {
      setSearchTerm(val);
      setLangCodesToShow(metaLangCodes.filter((lang) => lang.description.toLowerCase().includes(val.toLowerCase())));
    };

    const clickUpdateLangCode = async () => {
      setMailMetaValue("langCode", selectedLangCode);
      makeAlert(translate("mModalPageOptions.langCodeUpdated", false, null), "success");
    };

    return (
      <>
        <h4>{translate("mModalPageOptions.setEmailLangCode", false, null)}</h4>
        <div className="row mt-4">
          <div className="col-6 d-flex align-items-center">{translate("mModalPageOptions.searchLanguage", false, null)}</div>
          <div className="col-6 d-flex align-items-center">
            <input
              className="form-control"
              type="text"
              value={searchTerm}
              onChange={(e) => onType(e.target.value)}
              placeholder={translate("mModalPageOptions.searchLanguage", false, null)}
            />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-6 d-flex align-items-center">{translate("mModalPageOptions.selectYourLang", false, null)}</div>
          <div className="col-6 d-flex align-items-center">
            <select className="form-select" value={selectedLangCode} onChange={(e) => setSelectedLangCode(e.target.value)}>
              <option value="">{translate("mModalPageOptions.noSpecificLang", false, null)}</option>
              {langCodesToShow.map((lang) => (
                <option key={lang.subtag} value={lang.subtag}>
                  {lang.description}
                </option>
              ))}
            </select>
          </div>
        </div>
        <button className="mt-3 px-4 btn btn-success" onClick={clickUpdateLangCode}>
          {translate("mModalPageOptions.updateLangCode", false, null)}
        </button>
      </>
    );
  };

  // Check for errors
  const MailErrors = () => {
    const [checkDone, setCheckDone] = useState(false);
    const [foundErrors, setFoundErrors] = useState([]);

    const clickCheckErrors = () => {
      let errors = [];
      ebContent.forEach((page) => {
        let v = ebContent.length > 1 ? `${translate("mModalPageOptions.version", false, null)} ${page.version}` : "";
        page.components.forEach((component, i) => {
          let errMsg = v === "" ? "" : `${v} - `;
          let componentName = "";
          component.classes.forEach((className) => {
            if (className.match(/(\w+-\d+)-/) !== null) {
              componentName = className.match(/(\w+-\d+)-/)[1];
            }
          });
          errMsg += `${translate("mModalPageOptions.component", false, null)} #${i + 1} (${componentName}):`;
          let componentJson = JSON.stringify(component);
          // Empty links
          [...componentJson.matchAll(/"property":"data-href","value":"(.+?)"/g)]
            .map((link) => link[1])
            .filter((linkDest) => linkDest === "#!")
            .forEach((noDest) => errors.push(`${errMsg} ${translate("mModalPageOptions.linkWithoutDest", false, null)}`));
          // Images without description
          [...componentJson.matchAll(/"property":"alt","value":"(.+?)"/g)]
            .map((img) => img[1])
            .filter((imgAlt) => imgAlt === "")
            .forEach((noAlt) => errors.push(`${errMsg} ${translate("mModalPageOptions.imgWithoutDesc", false, null)}`));
          // Unrecognized dynamic content   DYNAMIC_CONTENT_VARS
          [...componentJson.matchAll(/%([\w_]+?)%/g)]
            .map((dynamicContent) => dynamicContent[0])
            .filter((dynamicContentVar) => !DYNAMIC_CONTENT_VARS.includes(dynamicContentVar))
            .forEach((wrongVarName) => errors.push(`${errMsg} ${translate("mModalPageOptions.nonExistingDynVar", false, { wrongVarName })}`));
        });
      });
      setCheckDone(true);
      setFoundErrors(errors);
    };

    return (
      <>
        <h4>{translate("mModalPageOptions.mailErrors", false, null)}</h4>
        <div className="mt-4">
          {checkDone && foundErrors.length === 0 ? (
            <p className="m-0 text-success">{translate("mModalPageOptions.noErrorsFound", false, null)}</p>
          ) : checkDone && foundErrors.length > 0 ? (
            <>
              <p className="m-0">{translate("mModalPageOptions.foundErrors", false, null)}:</p>
              {foundErrors.map((foundError, i) => (
                <div key={i} className="alert alert-warning mt-2 mb-2 p-2 fontSize09">
                  <i className="fa-solid fa-triangle-exclamation me-2" />
                  {foundError}
                </div>
              ))}
            </>
          ) : (
            <p className="m-0">{translate("mModalPageOptions.clickCheckForErrors", false, null)}</p>
          )}
        </div>
        <button className="mt-4 px-4 btn btn-success" onClick={clickCheckErrors}>
          {translate("mModalPageOptions.checkForErrors", false, null)}
        </button>
      </>
    );
  };

  // Add new page
  const SelectAddVersion = () => {
    const [versionToDuplicate, setVersionToDuplicate] = useState("");
    const [versionToDelete, setVersionToDelete] = useState("");

    const selectPageId = (pageId) => {
      setActivePage(pageId);
      clickClose();
    };

    const clickAddNewVersion = () => {
      let pageToDuplicate = structuredClone(ebContent.filter((p) => p.pageId === versionToDuplicate)[0]);
      let componentIdsToDuplicate = pageToDuplicate.components.map((c) => c.componentId);
      let newComponentIds = componentIdsToDuplicate.map((old) => getRandomId());

      let customCssToDuplicate = structuredClone(ebCustomCss.filter((css) => componentIdsToDuplicate.includes(css.componentId))).map((component) => ({
        ...component,
        classes: component.classes.map((c) => ({
          ...c,
          className: c.className.replace(component.componentId, newComponentIds[componentIdsToDuplicate.indexOf(component.componentId)]),
        })),
        componentId: newComponentIds[componentIdsToDuplicate.indexOf(component.componentId)],
      }));
      pageToDuplicate = {
        pageId: uuidv4(),
        version: getVersionNameFromIndex(ebContent.length + 1),
        components: renewClassnamesNewVersion(
          pageToDuplicate.components.map((component) => ({
            ...component,
            componentId: newComponentIds[componentIdsToDuplicate.indexOf(component.componentId)],
          })),
          componentIdsToDuplicate,
          newComponentIds
        ),
      };

      if (versionToDuplicate !== "") {
        if (ebContent.length < getSubscriptionMaxVersions(workspace.subscriptions)) {
          addNewVersion(pageToDuplicate, customCssToDuplicate);
          makeAlert(translate("mModalPageOptions.newVersionAdded", false, null), "success");
        } else {
          makeAlert(translate("mModalPageOptions.reachedMaxVersions", false, null), "danger");
        }
      }
    };

    const clickRemoveVersion = () => {
      if (versionToDelete !== "") {
        deleteVersion(versionToDelete);
        makeAlert(translate("mModalPageOptions.versionRemoved", false, null), "success");
      }
    };

    return (
      <>
        <h4>{translate("mModalPageOptions.selectAddRemoveVersions", false, null)}</h4>
        <p className="text-italic fontSize09 my-3">Y{translate("mModalPageOptions.explainerMultipleVersions", false, null)}</p>
        <div className="row mt-3">
          <div className="col-6 d-flex align-items-center">{translate("mModalPageOptions.selectVersionToEdit", false, null)}</div>
          <div className="col-6 d-flex align-items-center">
            <select className="form-select" value={activePageId} onChange={(e) => selectPageId(e.target.value)}>
              {ebContent.map((page) => (
                <option key={page.pageId} value={page.pageId}>
                  {translate("mModalPageOptions.version", false, null)} {page.version}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-6 d-flex align-items-center">{translate("mModalPageOptions.selectVersionToDuplicate", false, null)}</div>
          <div className="col-6 d-flex align-items-center">
            <select className="form-select" value={versionToDuplicate} onChange={(e) => setVersionToDuplicate(e.target.value)}>
              <option value="">{translate("mModalPageOptions.selectVersionAsBase", false, null)}</option>
              {ebContent.map((page) => (
                <option key={page.pageId} value={page.pageId}>
                  {translate("mModalPageOptions.version", false, null)} {page.version}
                </option>
              ))}
            </select>
          </div>
        </div>
        <button className="btn btn-success px-4 mt-1" disabled={versionToDuplicate === ""} onClick={clickAddNewVersion}>
          {translate("mModalPageOptions.addAdditionalVersion", false, null)}
        </button>
        <div className="row mt-4">
          <div className="col-6 d-flex align-items-center">{translate("mModalPageOptions.selectVersionToRemove", false, null)}</div>
          <div className="col-6 d-flex align-items-center">
            <select className="form-select" value={versionToDelete} onChange={(e) => setVersionToDelete(e.target.value)}>
              <option value="">{translate("mModalPageOptions.selectVersionToRemove", false, null)}</option>
              {ebContent.map((page) => (
                <option key={page.pageId} value={page.pageId}>
                  {translate("mModalPageOptions.version", false, null)} {page.version}
                </option>
              ))}
            </select>
          </div>
        </div>
        <button className="btn btn-danger px-4 mt-1" disabled={versionToDelete === ""} onClick={clickRemoveVersion}>
          {translate("mModalPageOptions.removeVersion", false, null)}
        </button>
      </>
    );
  };

  const makeAlert = (text, className) => {
    setLocalAlert({ text, className });
    setTimeout(() => setLocalAlert(null), 3000);
  };

  const LocalAlert = () => {
    return (
      localAlert !== null && (
        <div className={`alert alert-${localAlert.className} mb-3 py-2`} role="alert">
          {localAlert.text}
        </div>
      )
    );
  };

  return (
    <div
      className="modal fade"
      id="ModalPageOptions"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="ModalPageOptionsLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-xl">
        <div className="modal-content" style={{ height: "75vh" }}>
          <div className="modal-header">
            <h3 className="modal-title" id="ModalPageOptionsLabel">
              {translate("mModalPageOptions.mailOptions", false, null)}
            </h3>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ref={closeBtn}></button>
          </div>
          <div className="modal-body p-0">
            <div className="row h-100 mx-0">
              <div className="col-3 ps-0 pe-2">
                <div className="d-flex flex-column m-0 h-100 editResult">
                  <p className="modalMenuItem" onClick={() => setSelectedMenuItem("mailLanguage")}>
                    {translate("mModalPageOptions.setMailLang", false, null)}
                  </p>
                  <p className="modalMenuItem" onClick={() => setSelectedMenuItem("selectVersion")}>
                    {translate("mModalPageOptions.selectOrAddNewVersion", false, null)}
                  </p>
                  <p className="modalMenuItem" onClick={() => setSelectedMenuItem("mailErrors")}>
                    {translate("mModalPageOptions.checkErrors", false, null)}
                  </p>
                </div>
              </div>
              <div className="col-9 ps-3 pe-0">
                <div className="m-0 p-3 h-100 editResult">
                  <>
                    <LocalAlert />
                    {selectedMenuItem === "mailLanguage" && <MailLangCode />}
                    {selectedMenuItem === "selectVersion" && <SelectAddVersion />}
                    {selectedMenuItem === "mailErrors" && <MailErrors />}
                  </>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-gray" data-bs-dismiss="modal">
              {translate("mModalPageOptions.close", false, null)}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

ModalPageOptions.propTypes = {
  auth: PropTypes.object.isRequired,
  eb: PropTypes.object.isRequired,
  addNewVersion: PropTypes.func.isRequired,
  setActivePage: PropTypes.func.isRequired,
  deleteVersion: PropTypes.func.isRequired,
  setMailMetaValue: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  eb: state.eb,
});

export default connect(mapStateToProps, { addNewVersion, setActivePage, deleteVersion, setMailMetaValue })(ModalPageOptions);
