import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Divider from "./formComponents/Divider";
import ColVertAlign from "./ColVertAlign";
import Custom from "./formComponents/Custom";

import { formGetStartValue, EDIT_FORM_ID_PREFIX } from "../../../../lib/editFunctions";
import { COL_LAYOUT_VALUES } from "../../../../lib/editStyleVars";
import { translate } from "../../../../translations/translations";

const ColLayout = ({ eb: { startStyles } }) => {
  const Layout = () => {
    const [value, setValue] = useState("");
    useEffect(() => {
      setValue(formGetStartValue(startStyles, "colLayout"));
      // eslint-disable-next-line
    }, [formGetStartValue(startStyles, "colLayout")]);

    return (
      <>
        <div className="row" id={`${EDIT_FORM_ID_PREFIX}colLayout`} data-selected={value}>
          <div className="col-4 p-1">
            <div className={`colLayoutOption${value === COL_LAYOUT_VALUES[0] ? " selected" : ""}`} onClick={() => setValue(COL_LAYOUT_VALUES[0])}>
              <div className="" style={{ width: "50%" }}>
                <div>1:2</div>
              </div>
              <div className="" style={{ width: "50%" }}>
                <div>1:2</div>
              </div>
            </div>
          </div>
          <div className="col-4 p-1">
            <div className={`colLayoutOption${value === COL_LAYOUT_VALUES[1] ? " selected" : ""}`} onClick={() => setValue(COL_LAYOUT_VALUES[1])}>
              <div className="" style={{ width: "33%" }}>
                <div>1:3</div>
              </div>
              <div className="" style={{ width: "67%" }}>
                <div>2:3</div>
              </div>
            </div>
          </div>
          <div className="col-4 p-1">
            <div className={`colLayoutOption${value === COL_LAYOUT_VALUES[2] ? " selected" : ""}`} onClick={() => setValue(COL_LAYOUT_VALUES[2])}>
              <div className="" style={{ width: "67%" }}>
                <div>2:3</div>
              </div>
              <div className="" style={{ width: "33%" }}>
                <div>1:3</div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {/* Layout - Only for 2-column layouts */}
      {COL_LAYOUT_VALUES.includes(formGetStartValue(startStyles, "colLayout")) && (
        <Custom
          label={translate("cEditForms.layout", false, null)}
          column={true}
          customComponent={Layout}
          tooltipText={translate("cEditForms.ttColLayout", false, null)}
        />
      )}
      <Divider />
      {/* Vertical alignment */}
      <ColVertAlign />
    </>
  );
};

ColLayout.propTypes = {
  eb: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  eb: state.eb,
});

export default connect(mapStateToProps, null)(ColLayout);
