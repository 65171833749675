import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Slider from "./formComponents/Slider";

import { formGetStartValue } from "../../../../lib/editFunctions";
import { translate } from "../../../../translations/translations";

const FontSize = ({ eb: { startStyles } }) => {
  return (
    <>
      <Slider
        label={translate("cEditForms.fontSizePx", false, null)}
        id={"fontSize"}
        min={8}
        max={50}
        step={1}
        startValue={formGetStartValue(startStyles, "fontSize")}
        tooltipText={translate("cEditForms.ttFontSize", false, null)}
      />
    </>
  );
};

FontSize.propTypes = {
  eb: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  eb: state.eb,
});

export default connect(mapStateToProps, null)(FontSize);
