export const template7 = {
  id: "660340c0903bc6296e5c0cd0",
  name: { en: "Newsletter", nl: "Nieuwsbrief" },
  desc: { en: "A newsletter with links to highlighted stories", nl: "Een nieuwsbrief met links naar uitgelichte artikelen" },
  categories: ["newsletter"],
  ebContent: [
    {
      pageId: "2af349a4-a7bc-4ad8-b168-ec0dae451e36",
      version: "A",
      components: [
        {
          componentId: "gymbh0",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "basics-7-component-gymbh0"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "McgWvb",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "basics-7-col-container-gymbh0"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "eAwEch",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-12"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "d76dfQ",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "ZjJm8y",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "HV1gg5",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "ofERPW",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["basics-7-col-td-wrapper-gymbh0"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-name", value: "divider" },
                                    { property: "data-editable", value: "true" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "IbYdsU",
                                      type: "",
                                      htmlTagName: "div",
                                      classes: ["basics-7-divider-gymbh0"],
                                      styles: [],
                                      attributes: [{ property: "data-elementgetter1", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "XHbOO1",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "basics-13-component-XHbOO1"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "ZCuG2i",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "basics-13-col-container-XHbOO1"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "GZF7Iw",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-12"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "xHCWxU",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "TjkZHN",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "sXSqGM",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "O3x66F",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["basics-13-col-td-wrapper-XHbOO1"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "yVniVX",
                                      type: "",
                                      htmlTagName: "a",
                                      classes: ["basics-13-linkwrapper-XHbOO1"],
                                      styles: [],
                                      attributes: [
                                        { property: "href", value: "#!" },
                                        { property: "data-href", value: "#!" },
                                        { property: "data-target", value: "_blank" },
                                        { property: "data-linkwrapper", value: "true" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "tfDKjM",
                                          type: "",
                                          htmlTagName: "img",
                                          classes: ["basics-13-img-XHbOO1"],
                                          styles: [],
                                          attributes: [
                                            { property: "data-editable", value: "true" },
                                            { property: "data-name", value: "img" },
                                            { property: "src", value: "https://cdn.satonda.com/eb/assets/amsterdam1.jpg" },
                                            { property: "alt", value: "Image description" },
                                          ],
                                          content: "",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "JCcpeT",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "basics-1-component-JCcpeT"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "V95H3v",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "basics-1-col-container-JCcpeT"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "zm0dX8",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-12"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "et6PmL",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "OXZ5ar",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "T6yH6A",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "s1cg0O",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["basics-1-col-td-wrapper-JCcpeT"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "dCQf3r",
                                      type: "",
                                      htmlTagName: "h1",
                                      classes: ["basics-1-title-JCcpeT"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "h" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Your weekly digest",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "Wej6ED",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "basics-2-component-Wej6ED"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "Yb9b4V",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "basics-2-col-container-Wej6ED"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "mp64Pk",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-12"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "ZQgb6T",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "cmqaP8",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "mpw721",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "kHJdR8",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["basics-2-col-td-wrapper-Wej6ED"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "Ehp5zk",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["basics-2-text-Wej6ED"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content:
                                            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sit amet porttitor eget dolor morbi non arcu. Diam in arcu cursus euismod quis viverra nibh. Ultrices mi tempus imperdiet nulla malesuada pellentesque.",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "WKEjly",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "cards-9-component-WKEjly"],
          styles: [],
          attributes: [{ property: "data-name", value: "col_component" }],
          content: "",
          children: [
            {
              childId: "ULd42F",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "cards-9-col-container-WKEjly"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "LoPsPb",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-6", "vertical-align-middle"],
                  styles: [],
                  attributes: [{ property: "data-colLayout", value: "true" }],
                  content: "",
                  children: [
                    {
                      childId: "fYTDzH",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "kg5do8",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "wmEFol",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "IW4ABa",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["cards-9-col-td-wrapper-WKEjly"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "FawRfd",
                                      type: "",
                                      htmlTagName: "img",
                                      classes: ["cards-9-img-WKEjly"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-name", value: "img" },
                                        { property: "src", value: "https://cdn.satonda.com/eb/assets/amsterdam2.jpg" },
                                        { property: "alt", value: "Image description" },
                                      ],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "L2puqL",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-6", "vertical-align-middle"],
                  styles: [],
                  attributes: [{ property: "data-colLayout", value: "true" }],
                  content: "",
                  children: [
                    {
                      childId: "OHJvAt",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "vs4ot3",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "FLdqcz",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "Lek8aT",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["cards-9-col-td-wrapper-WKEjly"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "RhWQO6",
                                      type: "",
                                      htmlTagName: "h1",
                                      classes: ["cards-9-title-WKEjly"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "h" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Something cool",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "cH4kQU",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["cards-9-text-WKEjly"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Lorem ipsum dolor sit, amet consectetur adipisicing elit.",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "cuGeFa",
                                      type: "",
                                      htmlTagName: "a",
                                      classes: ["cards-9-btn-WKEjly"],
                                      styles: [],
                                      attributes: [
                                        { property: "href", value: "#!" },
                                        { property: "data-href", value: "#!" },
                                        { property: "data-target", value: "_blank" },
                                        { property: "data-editable", value: "true" },
                                        { property: "data-name", value: "button" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Read more",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "i6f3ZV",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "cards-9-component-i6f3ZV"],
          styles: [],
          attributes: [{ property: "data-name", value: "col_component" }],
          content: "",
          children: [
            {
              childId: "v0MhAk",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "cards-9-col-container-i6f3ZV"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "zzpijW",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-6", "vertical-align-middle"],
                  styles: [],
                  attributes: [{ property: "data-colLayout", value: "true" }],
                  content: "",
                  children: [
                    {
                      childId: "dmo4qQ",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "gNwsdg",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "V5jUHs",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "xAOE56",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["cards-9-col-td-wrapper-i6f3ZV"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "j3MoaO",
                                      type: "",
                                      htmlTagName: "h1",
                                      classes: ["cards-9-title-i6f3ZV"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "h" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Also pretty cool",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "lNTwkn",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["cards-9-text-i6f3ZV"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Lorem ipsum dolor sit, amet consectetur adipisicing elit.",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "KrFPjv",
                                      type: "",
                                      htmlTagName: "a",
                                      classes: ["cards-9-btn-i6f3ZV"],
                                      styles: [],
                                      attributes: [
                                        { property: "href", value: "#!" },
                                        { property: "data-href", value: "#!" },
                                        { property: "data-target", value: "_blank" },
                                        { property: "data-editable", value: "true" },
                                        { property: "data-name", value: "button" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Read more",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "oJ8Xms",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-6", "vertical-align-middle"],
                  styles: [],
                  attributes: [{ property: "data-colLayout", value: "true" }],
                  content: "",
                  children: [
                    {
                      childId: "ptSBWF",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "mBiLkM",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "p4sad3",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "NtwMK4",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["cards-9-col-td-wrapper-i6f3ZV"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "BmAqYC",
                                      type: "",
                                      htmlTagName: "img",
                                      classes: ["cards-9-img-i6f3ZV"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-name", value: "img" },
                                        { property: "src", value: "https://cdn.satonda.com/eb/assets/amsterdam3.jpg" },
                                        { property: "alt", value: "Image description" },
                                      ],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "UjXDAS",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "basics-7-component-UjXDAS"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "aj5gHU",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "basics-7-col-container-UjXDAS"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "qbTMgi",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-12"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "UhXnA0",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "CLbDji",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "u6PXVK",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "h1DP5c",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["basics-7-col-td-wrapper-UjXDAS"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-name", value: "divider" },
                                    { property: "data-editable", value: "true" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "uPt714",
                                      type: "",
                                      htmlTagName: "div",
                                      classes: ["basics-7-divider-UjXDAS"],
                                      styles: [],
                                      attributes: [{ property: "data-elementgetter1", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "Wk38se",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "basics-2-component-Wk38se"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "HfTljk",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "basics-2-col-container-Wk38se"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "P77mDp",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-12"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "IhoAwd",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "w4vdAf",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "fXksLw",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "Nbqmti",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["basics-2-col-td-wrapper-Wk38se"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "OadHXq",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["basics-2-text-Wk38se"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Lorem ipsum dolor sit amet consectetur.",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "WRpuJY",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "footer-2-component-WRpuJY"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "HtkB7T",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "footer-2-col-container-WRpuJY"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "WhmpUP",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-12"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "WyzY2f",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "KzzkMk",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "nfiJEU",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "D8sFcI",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["footer-2-col-td-wrapper-WRpuJY"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "JW6Il8",
                                      type: "",
                                      htmlTagName: "table",
                                      classes: ["column-table", "footer-2-table-table-WRpuJY"],
                                      styles: [],
                                      attributes: [
                                        { property: "role", value: "presentation" },
                                        { property: "width", value: "100%" },
                                        { property: "cellspacing", value: "0" },
                                        { property: "cellpadding", value: "0" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "GDtBRZ",
                                          type: "",
                                          htmlTagName: "tbody",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "",
                                          children: [
                                            {
                                              childId: "mhEhI2",
                                              type: "",
                                              htmlTagName: "tr",
                                              classes: ["footer-2-table-row-WRpuJY"],
                                              styles: [],
                                              attributes: [],
                                              content: "",
                                              children: [
                                                {
                                                  childId: "ue5nOe",
                                                  type: "",
                                                  htmlTagName: "td",
                                                  classes: ["footer-2-table-cell-all-WRpuJY"],
                                                  styles: [],
                                                  attributes: [{ property: "data-tablecell", value: "true" }],
                                                  content: "",
                                                  children: [
                                                    {
                                                      childId: "fDyzKY",
                                                      type: "",
                                                      htmlTagName: "p",
                                                      classes: ["footer-2-text-WRpuJY"],
                                                      styles: [],
                                                      attributes: [
                                                        { property: "data-editable", value: "true" },
                                                        { property: "data-texteditable", value: "true" },
                                                        { property: "data-name", value: "p" },
                                                      ],
                                                      content: "",
                                                      children: [
                                                        {
                                                          childId: "",
                                                          type: "",
                                                          htmlTagName: "textNode",
                                                          classes: [],
                                                          styles: [],
                                                          attributes: [],
                                                          content: "Follow us on social media",
                                                          children: [],
                                                        },
                                                      ],
                                                    },
                                                    {
                                                      childId: "HPt1qM",
                                                      type: "block",
                                                      htmlTagName: "div",
                                                      classes: ["footer-2-wrapper-icons-WRpuJY"],
                                                      styles: [],
                                                      attributes: [{ property: "data-name", value: "block" }],
                                                      content: "",
                                                      children: [
                                                        {
                                                          childId: "mfSARu",
                                                          type: "element",
                                                          htmlTagName: "a",
                                                          classes: ["footer-2-social-link-WRpuJY"],
                                                          styles: [],
                                                          attributes: [
                                                            { property: "href", value: "#!" },
                                                            { property: "data-href", value: "#!" },
                                                            { property: "data-target", value: "_blank" },
                                                            { property: "data-editable", value: "true" },
                                                            { property: "data-name", value: "socialIcon" },
                                                          ],
                                                          content: "",
                                                          children: [
                                                            {
                                                              childId: "JfGV2s",
                                                              type: "",
                                                              htmlTagName: "img",
                                                              classes: ["footer-2-social-icon-WRpuJY"],
                                                              styles: [],
                                                              attributes: [
                                                                {
                                                                  property: "src",
                                                                  value: "https://cdn.satonda.com/eb/assets/facebook-brand-white-square.png",
                                                                },
                                                                { property: "alt", value: "Facebook" },
                                                                { property: "data-editable", value: "false" },
                                                              ],
                                                              content: "",
                                                              children: [],
                                                            },
                                                          ],
                                                        },
                                                        {
                                                          childId: "UJa69w",
                                                          type: "element",
                                                          htmlTagName: "a",
                                                          classes: ["footer-2-social-link-WRpuJY"],
                                                          styles: [],
                                                          attributes: [
                                                            { property: "href", value: "#!" },
                                                            { property: "data-href", value: "#!" },
                                                            { property: "data-target", value: "_blank" },
                                                            { property: "data-editable", value: "true" },
                                                            { property: "data-name", value: "socialIcon" },
                                                          ],
                                                          content: "",
                                                          children: [
                                                            {
                                                              childId: "BrD0RY",
                                                              type: "",
                                                              htmlTagName: "img",
                                                              classes: ["footer-2-social-icon-WRpuJY"],
                                                              styles: [],
                                                              attributes: [
                                                                {
                                                                  property: "src",
                                                                  value: "https://cdn.satonda.com/eb/assets/twitter-brand-white-square.png",
                                                                },
                                                                { property: "alt", value: "Twitter" },
                                                                { property: "data-editable", value: "false" },
                                                              ],
                                                              content: "",
                                                              children: [],
                                                            },
                                                          ],
                                                        },
                                                        {
                                                          childId: "yjSxJG",
                                                          type: "element",
                                                          htmlTagName: "a",
                                                          classes: ["footer-2-social-link-WRpuJY"],
                                                          styles: [],
                                                          attributes: [
                                                            { property: "href", value: "#!" },
                                                            { property: "data-href", value: "#!" },
                                                            { property: "data-target", value: "_blank" },
                                                            { property: "data-editable", value: "true" },
                                                            { property: "data-name", value: "socialIcon" },
                                                          ],
                                                          content: "",
                                                          children: [
                                                            {
                                                              childId: "j94nAa",
                                                              type: "",
                                                              htmlTagName: "img",
                                                              classes: ["footer-2-social-icon-WRpuJY"],
                                                              styles: [],
                                                              attributes: [
                                                                {
                                                                  property: "src",
                                                                  value: "https://cdn.satonda.com/eb/assets/youtube-brand-white-square.png",
                                                                },
                                                                { property: "alt", value: "Youtube" },
                                                                { property: "data-editable", value: "false" },
                                                              ],
                                                              content: "",
                                                              children: [],
                                                            },
                                                          ],
                                                        },
                                                      ],
                                                    },
                                                  ],
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "FbrX64",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "footer-9-component-FbrX64"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "EMbGEF",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "footer-9-col-container-FbrX64"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "wj5BWn",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-12"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "E8lchg",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "kuB6bk",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "qnScaJ",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "L0gcGH",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["footer-9-col-td-wrapper-FbrX64"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "cE4m6L",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["footer-9-text1-FbrX64"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                        { property: "data-uniqueclassname", value: "true" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "© Company name",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "XrrF3q",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["footer-9-text2-FbrX64"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                        { property: "data-uniqueclassname", value: "true" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Streetname 1, 1000 XX Amsterdam, The Netherlands",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "XXI5Xo",
                                      type: "element",
                                      htmlTagName: "a",
                                      classes: ["footer-9-link-FbrX64"],
                                      styles: [],
                                      attributes: [
                                        { property: "href", value: "#!" },
                                        { property: "data-href", value: "%UNSUBSCRIBE_LINK%" },
                                        { property: "data-target", value: "_blank" },
                                        { property: "data-editable", value: "true" },
                                        { property: "data-name", value: "a" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Unsubscribe",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  ebCustomCss: [
    {
      componentId: "gymbh0",
      classes: [
        {
          className: "basics-7-component-gymbh0",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
          ],
        },
        {
          className: "basics-7-col-container-gymbh0",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "background-color", value: "rgb(255, 255, 255)" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "basics-7-col-td-wrapper-gymbh0",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0rem 0rem 0rem 0rem" },
            { property: "text-align", value: "start" },
          ],
        },
        {
          className: "basics-7-divider-gymbh0",
          pseudo: "",
          rules: [
            { property: "display", value: "inline-block" },
            { property: "height", value: "20px" },
            { property: "width", value: "100%" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
            { property: "border-radius", value: "0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(230, 230, 230)" },
          ],
        },
      ],
    },
    {
      componentId: "XHbOO1",
      classes: [
        {
          className: "basics-13-linkwrapper-XHbOO1",
          pseudo: "",
          rules: [
            { property: "display", value: "block" },
            { property: "line-height", value: "0 !important" },
          ],
        },
        {
          className: "basics-13-img-XHbOO1",
          pseudo: "",
          rules: [
            { property: "outline", value: "none" },
            { property: "-ms-interpolation-mode", value: "bicubic" },
            { property: "max-width", value: "100%" },
            { property: "max-height", value: "200px" },
            { property: "width", value: "100%" },
            { property: "height", value: "auto" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "0px" },
            { property: "border-color", value: "rgb(0, 0, 0)" },
            { property: "border-radius", value: "0rem" },
          ],
        },
        {
          className: "basics-13-component-XHbOO1",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
          ],
        },
        {
          className: "basics-13-col-container-XHbOO1",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(234, 237, 240)" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "basics-13-col-td-wrapper-XHbOO1",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0rem 0rem 0rem 0rem" },
            { property: "text-align", value: "start" },
          ],
        },
      ],
    },
    {
      componentId: "JCcpeT",
      classes: [
        {
          className: "basics-1-component-JCcpeT",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
          ],
        },
        {
          className: "basics-1-col-container-JCcpeT",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "background-color", value: "rgb(255, 255, 255)" },
            { property: "padding-top", value: "1rem" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "basics-1-col-td-wrapper-JCcpeT",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0.5rem 0.75rem 0.5rem 0.75rem" },
            { property: "text-align", value: "start" },
          ],
        },
        {
          className: "basics-1-title-JCcpeT",
          pseudo: "",
          rules: [
            { property: "text-align", value: "left" },
            { property: "color", value: "rgb(42, 147, 126)" },
            { property: "font-weight", value: "bold" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
          ],
        },
      ],
    },
    {
      componentId: "Wej6ED",
      classes: [
        {
          className: "basics-2-component-Wej6ED",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
          ],
        },
        {
          className: "basics-2-col-container-Wej6ED",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "background-color", value: "rgb(255, 255, 255)" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "basics-2-col-td-wrapper-Wej6ED",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0.5rem 0.75rem 0.5rem 0.75rem" },
            { property: "text-align", value: "start" },
          ],
        },
        {
          className: "basics-2-text-Wej6ED",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(33, 37, 41)" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
          ],
        },
      ],
    },
    {
      componentId: "WKEjly",
      classes: [
        {
          className: "cards-9-component-WKEjly",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
          ],
        },
        {
          className: "cards-9-col-container-WKEjly",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "background-color", value: "rgb(255, 255, 255)" },
            { property: "padding-top", value: "0.75rem" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "cards-9-title-WKEjly",
          pseudo: "",
          rules: [
            { property: "text-align", value: "left" },
            { property: "color", value: "rgb(42, 147, 126)" },
            { property: "margin", value: "0rem 0rem 0.5rem 0rem" },
          ],
        },
        {
          className: "cards-9-text-WKEjly",
          pseudo: "",
          rules: [
            { property: "text-align", value: "left" },
            { property: "color", value: "rgb(33, 37, 41)" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
          ],
        },
        {
          className: "cards-9-img-WKEjly",
          pseudo: "",
          rules: [
            { property: "outline", value: "none" },
            { property: "-ms-interpolation-mode", value: "bicubic" },
            { property: "max-width", value: "100%" },
            { property: "max-height", value: "200px" },
            { property: "width", value: "100%" },
            { property: "height", value: "auto" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "0px" },
            { property: "border-color", value: "rgb(0, 0, 0)" },
            { property: "border-radius", value: "0rem" },
          ],
        },
        {
          className: "cards-9-btn-WKEjly",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "font-weight", value: "normal" },
            { property: "font-style", value: "normal" },
            { property: "text-decoration", value: "underline" },
            { property: "color", value: "rgb(42, 147, 126)" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(42, 147, 126)" },
            { property: "border-radius", value: "0rem" },
            { property: "padding", value: "0.5rem 0rem 0.5rem 0rem" },
            { property: "display", value: "inline-block" },
            { property: "font-size", value: "16px" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "cards-9-col-td-wrapper-WKEjly",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0.5rem 0.75rem 0.5rem 0.75rem" },
            { property: "text-align", value: "start" },
          ],
        },
      ],
    },
    {
      componentId: "i6f3ZV",
      classes: [
        {
          className: "cards-9-component-i6f3ZV",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
          ],
        },
        {
          className: "cards-9-col-container-i6f3ZV",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "background-color", value: "rgb(255, 255, 255)" },
            { property: "padding-top", value: "0.75rem" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "cards-9-title-i6f3ZV",
          pseudo: "",
          rules: [
            { property: "text-align", value: "left" },
            { property: "color", value: "rgb(42, 147, 126)" },
            { property: "margin", value: "0rem 0rem 0.5rem 0rem" },
          ],
        },
        {
          className: "cards-9-text-i6f3ZV",
          pseudo: "",
          rules: [
            { property: "text-align", value: "left" },
            { property: "color", value: "rgb(33, 37, 41)" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
          ],
        },
        {
          className: "cards-9-img-i6f3ZV",
          pseudo: "",
          rules: [
            { property: "outline", value: "none" },
            { property: "-ms-interpolation-mode", value: "bicubic" },
            { property: "max-width", value: "100%" },
            { property: "max-height", value: "200px" },
            { property: "width", value: "100%" },
            { property: "height", value: "auto" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "0px" },
            { property: "border-color", value: "rgb(0, 0, 0)" },
            { property: "border-radius", value: "0rem" },
          ],
        },
        {
          className: "cards-9-btn-i6f3ZV",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "font-weight", value: "normal" },
            { property: "font-style", value: "normal" },
            { property: "text-decoration", value: "underline" },
            { property: "color", value: "rgb(42, 147, 126)" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(42, 147, 126)" },
            { property: "border-radius", value: "0rem" },
            { property: "padding", value: "0.5rem 0rem 0.5rem 0rem" },
            { property: "display", value: "inline-block" },
            { property: "font-size", value: "16px" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "cards-9-col-td-wrapper-i6f3ZV",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0.5rem 0.75rem 0.5rem 0.75rem" },
            { property: "text-align", value: "start" },
          ],
        },
      ],
    },
    {
      componentId: "UjXDAS",
      classes: [
        {
          className: "basics-7-component-UjXDAS",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
          ],
        },
        {
          className: "basics-7-col-container-UjXDAS",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "background-color", value: "rgb(255, 255, 255)" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "basics-7-col-td-wrapper-UjXDAS",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0rem 0rem 0rem 0rem" },
            { property: "text-align", value: "start" },
          ],
        },
        {
          className: "basics-7-divider-UjXDAS",
          pseudo: "",
          rules: [
            { property: "display", value: "inline-block" },
            { property: "height", value: "8px" },
            { property: "width", value: "100%" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
            { property: "border-radius", value: "0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(230, 230, 230)" },
          ],
        },
      ],
    },
    {
      componentId: "Wk38se",
      classes: [
        {
          className: "basics-2-text-Wk38se",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(33, 37, 41)" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "text-align", value: "start" },
          ],
        },
        {
          className: "basics-2-component-Wk38se",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
          ],
        },
        {
          className: "basics-2-col-container-Wk38se",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "background-color", value: "rgb(255, 255, 255)" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "basics-2-col-td-wrapper-Wk38se",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0.5rem 0.5rem 0.5rem 0.5rem" },
            { property: "text-align", value: "start" },
          ],
        },
      ],
    },
    {
      componentId: "WRpuJY",
      classes: [
        { className: "footer-2-table-table-WRpuJY", pseudo: "", rules: [] },
        { className: "footer-2-table-row-WRpuJY", pseudo: "", rules: [] },
        {
          className: "footer-2-table-cell-all-WRpuJY",
          pseudo: "",
          rules: [
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "padding", value: "0px 10px 0px 10px" },
          ],
        },
        {
          className: "footer-2-wrapper-icons-WRpuJY",
          pseudo: "",
          rules: [
            { property: "text-align", value: "start" },
            { property: "display", value: "inline-block" },
            { property: "vertical-align", value: "middle" },
          ],
        },
        {
          className: "footer-2-social-link-WRpuJY",
          pseudo: "",
          rules: [
            { property: "display", value: "inline-block" },
            { property: "text-decoration", value: "none" },
            { property: "margin", value: "0rem 1rem 0rem 0rem" },
          ],
        },
        { className: "footer-2-social-link-WRpuJY", pseudo: "last-child", rules: [{ property: "margin-right", value: "0" }] },
        {
          className: "footer-2-social-icon-WRpuJY",
          pseudo: "",
          rules: [
            { property: "outline", value: "none" },
            { property: "-ms-interpolation-mode", value: "bicubic" },
            { property: "width", value: "32px" },
            { property: "height", value: "32px" },
            { property: "border-radius", value: "50%" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(255, 255, 255)" },
          ],
        },
        {
          className: "footer-2-text-WRpuJY",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(233, 236, 239)" },
            { property: "font-weight", value: "bold" },
            { property: "margin", value: "1rem 2rem 1rem 0rem" },
            { property: "text-align", value: "start" },
            { property: "display", value: "inline-block" },
            { property: "vertical-align", value: "middle" },
          ],
        },
        {
          className: "footer-2-component-WRpuJY",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
          ],
        },
        {
          className: "footer-2-col-container-WRpuJY",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-top", value: "1.5rem" },
            { property: "padding-bottom", value: "1.5rem" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(80, 80, 80)" },
            { property: "background-color", value: "rgb(80, 80, 80)" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "footer-2-col-td-wrapper-WRpuJY",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0.5rem 0rem 0.5rem 0rem" },
            { property: "text-align", value: "start" },
          ],
        },
      ],
    },
    {
      componentId: "FbrX64",
      classes: [
        {
          className: "footer-9-text1-FbrX64",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(108, 117, 125)" },
            { property: "margin", value: "0rem 0rem 0.25rem 0rem" },
            { property: "text-align", value: "center" },
            { property: "font-size", value: "12px" },
          ],
        },
        {
          className: "footer-9-text2-FbrX64",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(108, 117, 125)" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-align", value: "center" },
            { property: "font-size", value: "12px" },
          ],
        },
        {
          className: "footer-9-link-FbrX64",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(108, 117, 125)" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "display", value: "block" },
            { property: "text-align", value: "center" },
            { property: "text-decoration", value: "underline" },
            { property: "font-size", value: "12px" },
          ],
        },
        {
          className: "footer-9-component-FbrX64",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
          ],
        },
        {
          className: "footer-9-col-container-FbrX64",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "padding-top", value: "1.5rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(234, 237, 240)" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "padding-bottom", value: "3rem" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "footer-9-col-td-wrapper-FbrX64",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0.5rem 0rem 0.5rem 0rem" },
            { property: "text-align", value: "start" },
          ],
        },
      ],
    },
  ],
  ebCssVars: {
    fontHeadersName: "Arial",
    fontHeadersWeight: "400",
    fontBodyName: "Arial",
    fontBodyWeight: "400",
    color1: "rgb(42, 147, 126)",
    color2: "rgba(246, 129, 0, 1)",
    color3: "rgba(40, 167, 69, 1)",
    color4: "rgba(13, 202, 240, 1)",
    color5: "rgba(255, 193, 7, 1)",
    color6: "rgba(220, 53, 69, 1)",
    color7: "rgba(233, 236, 239, 1)",
    color8: "rgba(33, 37, 41, 1)",
    color9: "rgba(206, 212, 218, 1)",
    color10: "rgba(108, 117, 125, 1)",
  },
};
