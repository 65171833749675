// Auth
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const SET_LANG_CODE = "SET_LANG_CODE";

// Global loading
export const SET_LOADING = "SET_LOADING";
export const REMOVE_LOADING = "REMOVE_LOADING";

// Manage project
export const LOAD_PROJECT = "LOAD_PROJECT";
export const LOAD_TEMPLATE = "LOAD_TEMPLATE";
export const UPDATE_PROJECT_GALLERY = "UPDATE_PROJECT_GALLERY";
export const UPDATE_GALLERY_FILE_DESC = "UPDATE_GALLERY_FILE_DESC";
export const UPDATE_MAILMETA = "UPDATE_MAILMETA";

// Product tour
export const OPEN_PRODUCT_TOUR = "OPEN_PRODUCT_TOUR";
export const CLOSE_PRODUCT_TOUR = "CLOSE_PRODUCT_TOUR";

// Drag & drop
export const START_DRAG_FROM_MENU = "START_DRAG_FROM_MENU";
export const END_DRAG_FROM_MENU = "END_DRAG_FROM_MENU";
export const DROP_COMPONENT = "DROP_COMPONENT";

// Edit component
export const REORDER_COMPONENT = "REORDER_COMPONENT";
export const DELETE_COMPONENT = "DELETE_COMPONENT";
export const SET_SELECTED_ELEMENT = "SET_SELECTED_ELEMENT";

// Manage elements
export const DELETE_ELEMENT = "DELETE_ELEMENT";
export const DUPLICATE_ELEMENT = "DUPLICATE_ELEMENT";
export const REORDER_COL = "REORDER_COL";

// Manage pages
export const ADD_NEW_VERSION = "ADD_NEW_VERSION";
export const SET_ACTIVE_PAGE = "SET_ACTIVE_PAGE";
export const DELETE_VERSION = "DELETE_VERSION";

// Track changes
export const SAVE_TRACK_CHANGES = "SAVE_TRACK_CHANGES";
export const UNDO_REDO = "UNDO_REDO";

// Edit styles
export const START_EDITING = "START_EDITING";
export const OPEN_COLOR_PICKER = "OPEN_COLOR_PICKER";
export const UPDATE_CSS_VAR = "UPDATE_CSS_VAR";
export const CONFIRM_COLOR_SCHEME = "CONFIRM_COLOR_SCHEME";
export const CHANGE_MAIL_COLORS = "CHANGE_MAIL_COLORS";
export const UPDATE_COMPONENT_CSS = "UPDATE_COMPONENT_CSS";
export const UPDATE_COMPONENT_CLASSES = "UPDATE_COMPONENT_CLASSES";
export const UPDATE_COMPONENT_ATTRIBUTES = "UPDATE_COMPONENT_ATTRIBUTES";
export const UPDATE_COMPONENT_HTMLTAGNAME = "UPDATE_COMPONENT_HTMLTAGNAME";
export const UPDATE_EXISTING_TEXT_ELEMENT = "UPDATE_EXISTING_TEXT_ELEMENT";
export const ADD_NEW_TEXT_ELEMENT = "ADD_NEW_TEXT_ELEMENT";
export const UPDATE_LIST_MARKER2 = "UPDATE_LIST_MARKER2";
export const SELECT_IMG_FROM_GALLERY = "SELECT_IMG_FROM_GALLERY";
// Apply custom updates
export const UPDATE_BTN_LINK_TEXT = "UPDATE_BTN_LINK_TEXT";
export const UPDATE_CUSTOMS_NUM_RATING_STARS = "UPDATE_CUSTOMS_NUM_RATING_STARS";
// export const XXXXX = "XXXXX";
// export const XXXXX = "XXXXX";
// export const XXXXX = "XXXXX";

// Other
export const CHANGE_SCREEN_SIZE = "CHANGE_SCREEN_SIZE";
