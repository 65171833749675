import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Slider from "./formComponents/Slider";

import { formGetStartValue } from "../../../../lib/editFunctions";
import { PADDING_VALUES } from "../../../../lib/editStyleVars";
import { translate } from "../../../../translations/translations";

const Padding = ({ eb: { startStyles } }) => {
  return (
    <>
      <Slider
        label={translate("cEditForms.paddingLeft", false, null)}
        id={"paddingLeft"}
        min={0}
        max={10}
        step={1}
        startValue={PADDING_VALUES.indexOf(formGetStartValue(startStyles, "paddingLeft"))}
        tooltipText={translate("cEditForms.ttPaddingLeft", false, null)}
      />
      <Slider
        label={translate("cEditForms.paddingRight", false, null)}
        id={"paddingRight"}
        min={0}
        max={10}
        step={1}
        startValue={PADDING_VALUES.indexOf(formGetStartValue(startStyles, "paddingRight"))}
        tooltipText={translate("cEditForms.ttPaddingRight", false, null)}
      />
      <Slider
        label={translate("cEditForms.paddingTop", false, null)}
        id={"paddingTop"}
        min={0}
        max={10}
        step={1}
        startValue={PADDING_VALUES.indexOf(formGetStartValue(startStyles, "paddingTop"))}
        tooltipText={translate("cEditForms.ttPaddingTop", false, null)}
      />
      <Slider
        label={translate("cEditForms.paddingBottom", false, null)}
        id={"paddingBottom"}
        min={0}
        max={10}
        step={1}
        startValue={PADDING_VALUES.indexOf(formGetStartValue(startStyles, "paddingBottom"))}
        tooltipText={translate("cEditForms.ttPaddingBottom", false, null)}
      />
    </>
  );
};

Padding.propTypes = {
  eb: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  eb: state.eb,
});

export default connect(mapStateToProps, null)(Padding);
