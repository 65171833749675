// Import dependencies
import axios from "axios";

const setWorkspace = (workspaceId) => {
  if (workspaceId) {
    // If there is, add it to the axios global headers
    axios.defaults.headers.common["x-auth-workspace"] = workspaceId;
  } else {
    // If there is no id passed in, delete the one that was set as the global header
    delete axios.defaults.headers.common["x-auth-workspace"];
  }
};

export default setWorkspace;
