import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import LinkDestTarget from "./formComponents/LinkDestTarget";

const DownloadAppSource = ({ eb: { startStyles } }) => {
  return (
    <>
      <LinkDestTarget />
    </>
  );
};

DownloadAppSource.propTypes = {
  eb: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  eb: state.eb,
});

export default connect(mapStateToProps, null)(DownloadAppSource);
