import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";

import ImgGalleryListFiles from "./components/ImgGalleryListFiles";
import ImgGalleryUpload from "./components/ImgGalleryUpload";
import ImgGalleryCrop from "./components/ImgGalleryCrop";
import Spinner from "../../components/layout/Spinner";

import { updateProjectGallery } from "../../actions/eb";
import { translate } from "../../translations/translations";
import { API_URL } from "../../lib/generalVars";

const ModalImageGallery = ({ eb: { project }, updateProjectGallery }) => {
  const [localLoading, setLocalLoading] = useState({ isLoading: false, msg: "" });
  const [localAlert, setLocalAlert] = useState(null);
  const [imageCropping, setImageCropping] = useState(null);

  const makeAlert = (text, className) => {
    setLocalAlert({ text, className });
    setTimeout(() => setLocalAlert(null), 3000);
  };

  const LocalAlert = () => {
    return (
      localAlert !== null && (
        <div className={`alert alert-${localAlert.className} mb-3 py-2`} role="alert">
          {localAlert.text}
        </div>
      )
    );
  };

  const resetStateVars = () => {
    setLocalLoading({ isLoading: false, msg: "" });
    setLocalAlert(null);
    setImageCropping(null);
  };

  const uploadToBackend = async (files) => {
    // files = array of files to be uploaded
    const formData = getFormData(files);
    try {
      const res = await axios.post(`${API_URL}/emailbuilder/gallery/upload`, formData);
      updateProjectGallery(res.data);
    } catch (error) {
      console.error(error);
      return { success: false, msg: translate("mModalImageGallery.serverErrorUploading", false, null) };
    }
  };

  const getFormData = (files) => {
    // formData.append(name, value, filename); => "name" field needs to equal the multer.array("name") in the API
    const formData = new FormData();
    // Text fields need to come before file uploads; else multer can't access req.body when its needed
    formData.append("targetDest", "eb");
    formData.append("projectId", project._id);
    formData.append("filesUploaded", files.map((file) => file.name.replace(/\s/g, "-")).join(","));
    files.forEach((file) => {
      // Add each file to formData object and replace any spaces in filename with -
      formData.append("files", file, file.name.replace(/\s/g, "-"));
    });
    return formData;
  };

  return (
    <div
      className="modal fade"
      id="ModalImageGallery"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="ModalImageGalleryLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-xl" style={{ maxWidth: "80vw" }}>
        <div className="modal-content" style={{ height: "90vh" }}>
          <div className="modal-header">
            <h3 className="modal-title" id="ModalImageGalleryLabel">
              {translate("mModalImageGallery.imgGallery", false, null)}
            </h3>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={resetStateVars}></button>
          </div>
          <div className="modal-body">
            {localLoading.isLoading ? (
              <div className="mt-5">
                <Spinner msg={localLoading.msg} />
              </div>
            ) : imageCropping !== null ? (
              <ImgGalleryCrop
                imgToCrop={imageCropping}
                setImageCropping={setImageCropping}
                setLocalLoading={setLocalLoading}
                makeAlert={makeAlert}
                uploadToBackend={uploadToBackend}
              />
            ) : (
              <>
                <LocalAlert />
                <ImgGalleryListFiles setLocalLoading={setLocalLoading} makeAlert={makeAlert} setImageCropping={setImageCropping} />
                <ImgGalleryUpload setLocalLoading={setLocalLoading} makeAlert={makeAlert} uploadToBackend={uploadToBackend} />
              </>
            )}
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-gray" data-bs-dismiss="modal" onClick={resetStateVars}>
              {translate("mModalImageGallery.close", false, null)}
            </button>
            {/* <button
              type="button"
              className="btn btn-success"
              data-bs-dismiss="modal"
              disabled={selectedTemplateId === ""}
              onClick={clickLoadTemplate}
            >
              Load selected template
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
};

ModalImageGallery.propTypes = {
  eb: PropTypes.object.isRequired,
  updateProjectGallery: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  eb: state.eb,
});

export default connect(mapStateToProps, { updateProjectGallery })(ModalImageGallery);
