export const mModalSelectIcon = {
  selectNewIcon: {
    en: "Select a new icon",
    nl: "Selecteer een nieuw icoon",
  },
  searchIconName: {
    en: "Search icon name",
    nl: "Zoek icoonnaam",
  },
  search: {
    en: "Search..",
    nl: "Zoeken..",
  },
  selectCategory: {
    en: "Select category",
    nl: "Selecteer categorie",
  },
  all: {
    en: "All",
    nl: "Alle",
  },
  xIconsMatchCriteria: {
    en: "icons match your criteria",
    nl: "iconen voldoen aan uw criteria",
  },
  noIconsMatchSelection: {
    en: "No icons match your selection",
    nl: "Geen iconen voldoen aan uw selectie",
  },
  selectedIcon: {
    en: "Selected icon",
    nl: "Geselecteerde icoon",
  },
  noIconSelected: {
    en: "No icon selected",
    nl: "Geen icoon geselecteerd",
  },
  close: {
    en: "Close",
    nl: "Sluiten",
  },
  confirmIcon: {
    en: "Confirm icon",
    nl: "Bevestig icoon",
  },
};
