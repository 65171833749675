import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import LinkDestForm from "./formComponents/LinkDestForm";

import { formGetStartValue } from "../../../../lib/editFunctions";
import { isValidURL } from "../../../../lib/generalFunctions";
import { translate } from "../../../../translations/translations";

const LinkElement = ({ eb: { startStyles } }) => {
  return (
    <>
      <LinkDestForm
        label={translate("cEditForms.linkDest", false, null)}
        id={"linkElementDest"}
        startValue={formGetStartValue(startStyles, "linkElementDest")}
        tooltipText={translate("cEditForms.ttLinkElement", false, null)}
        onTypeCheck={isValidURL}
        errMsgText={translate("cEditForms.notValidUrl", false, null)}
      />
    </>
  );
};

LinkElement.propTypes = {
  eb: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  eb: state.eb,
});

export default connect(mapStateToProps, null)(LinkElement);
