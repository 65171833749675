// Basics
import { basics1 } from "./basics/basics-1";
import { basics2 } from "./basics/basics-2";
import { basics3 } from "./basics/basics-3";
import { basics4 } from "./basics/basics-4";
import { basics5 } from "./basics/basics-5";
import { basics6 } from "./basics/basics-6";
import { basics7 } from "./basics/basics-7";
import { basics8 } from "./basics/basics-8";
import { basics9 } from "./basics/basics-9";
import { basics10 } from "./basics/basics-10";
import { basics11 } from "./basics/basics-11";
import { basics12 } from "./basics/basics-12";
import { basics13 } from "./basics/basics-13";
// Hero
import { hero1 } from "./hero/hero-1";
import { hero2 } from "./hero/hero-2";
import { hero3 } from "./hero/hero-3";
import { hero4 } from "./hero/hero-4";
// import { hero5 } from "./hero/hero-5";
// Cards
import { cards1 } from "./cards/cards-1";
import { cards2 } from "./cards/cards-2";
import { cards3 } from "./cards/cards-3";
import { cards4 } from "./cards/cards-4";
import { cards5 } from "./cards/cards-5";
import { cards6 } from "./cards/cards-6";
import { cards7 } from "./cards/cards-7";
import { cards8 } from "./cards/cards-8";
import { cards9 } from "./cards/cards-9";
import { cards10 } from "./cards/cards-10";
import { cards11 } from "./cards/cards-11";
import { cards12 } from "./cards/cards-12";
import { cards13 } from "./cards/cards-13";
// Footer
import { footer1 } from "./footer/footer-1";
import { footer2 } from "./footer/footer-2";
import { footer3 } from "./footer/footer-3";
import { footer4 } from "./footer/footer-4";
import { footer5 } from "./footer/footer-5";
import { footer6 } from "./footer/footer-6";
import { footer7 } from "./footer/footer-7";
import { footer8 } from "./footer/footer-8";
import { footer9 } from "./footer/footer-9";
import { footer10 } from "./footer/footer-10";

export const templateComponents = [
  basics1,
  basics2,
  basics3,
  basics4,
  basics5,
  basics6,
  basics7,
  basics8,
  basics9,
  basics10,
  basics11,
  basics12,
  basics13,
  hero1,
  hero2,
  hero3,
  hero4,
  // hero5,
  cards1,
  cards2,
  cards3,
  cards4,
  cards5,
  cards6,
  cards7,
  cards8,
  cards9,
  cards10,
  cards11,
  cards12,
  cards13,
  footer1,
  footer2,
  footer3,
  footer4,
  footer5,
  footer6,
  footer7,
  footer8,
  footer9,
  footer10,
];
