export const template10 = {
  id: "660349a2827696e432a2404c",
  name: { en: "Daily deal", nl: "Dagelijkse aanbieding" },
  desc: { en: "Email showing the daily meal deal", nl: "Email met de dagelijkse maaltijdpromo" },
  categories: ["product"],
  ebContent: [
    {
      pageId: "fc6240d7-c03b-41a3-a2e5-c989c28372e9",
      version: "A",
      components: [
        {
          componentId: "sAMGOR",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "basics-7-component-sAMGOR"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "QaTmlt",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "basics-7-col-container-sAMGOR"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "IzfesK",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-12"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "IbJHNA",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "B8292Q",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "pxtMuR",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "eM9oan",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["basics-7-col-td-wrapper-sAMGOR"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-name", value: "divider" },
                                    { property: "data-editable", value: "true" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "Fb4Q5S",
                                      type: "",
                                      htmlTagName: "div",
                                      classes: ["basics-7-divider-sAMGOR"],
                                      styles: [],
                                      attributes: [{ property: "data-elementgetter1", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "kLzzQv",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "basics-13-component-kLzzQv"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "pht9U7",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "basics-13-col-container-kLzzQv"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "DkhF5l",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-12"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "Av5p6i",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "sslVyw",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "cFvud8",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "dhU7XT",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["basics-13-col-td-wrapper-kLzzQv"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "z5vD9U",
                                      type: "",
                                      htmlTagName: "a",
                                      classes: ["basics-13-linkwrapper-kLzzQv"],
                                      styles: [],
                                      attributes: [
                                        { property: "href", value: "#!" },
                                        { property: "data-href", value: "#!" },
                                        { property: "data-target", value: "_blank" },
                                        { property: "data-linkwrapper", value: "true" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "XalLky",
                                          type: "",
                                          htmlTagName: "img",
                                          classes: ["basics-13-img-kLzzQv"],
                                          styles: [],
                                          attributes: [
                                            { property: "data-editable", value: "true" },
                                            { property: "data-name", value: "img" },
                                            { property: "src", value: "https://cdn.satonda.com/eb/assets/logo3.png" },
                                            { property: "alt", value: "Logo" },
                                          ],
                                          content: "",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "tm1jOH",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "basics-1-component-tm1jOH"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "k7bjFg",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "basics-1-col-container-tm1jOH"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "z5SR0j",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-12"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "gjXA1x",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "CuJ3Tz",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "W39r2T",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "FQ66iE",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["basics-1-col-td-wrapper-tm1jOH"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "TI6Lct",
                                      type: "",
                                      htmlTagName: "h1",
                                      classes: ["basics-1-title-tm1jOH"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "h" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Come enjoy a healthy meal",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "e6LnHW",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "basics-8-component-e6LnHW"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "E4MQdD",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "basics-8-col-container-e6LnHW"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "mZLQGI",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-12"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "k6Yjhg",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "Pimq8t",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "FMfPqG",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "rNh5zB",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["basics-8-col-td-wrapper-e6LnHW"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-name", value: "divider" },
                                    { property: "data-editable", value: "true" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "zhTitU",
                                      type: "",
                                      htmlTagName: "div",
                                      classes: ["basics-8-divider-e6LnHW"],
                                      styles: [],
                                      attributes: [{ property: "data-elementgetter1", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "skP5sP",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "basics-2-component-skP5sP"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "pIaiT1",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "basics-2-col-container-skP5sP"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "bnQq3S",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-12"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "JdWR2I",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "reU2VE",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "SgUCq5",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "Aq0ETh",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["basics-2-col-td-wrapper-skP5sP"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "lqR0jH",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["basics-2-text-skP5sP"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Lorem ipsum dolor sit amet consectetur.",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "duNIc9",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "basics-3-component-duNIc9"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "Cn7cUp",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "basics-3-col-container-duNIc9"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "PsbvjF",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-12"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "aAp2FP",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "ahVKEZ",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "Yf60eY",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "y7nlth",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["basics-3-col-td-wrapper-duNIc9"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "eUHrs2",
                                      type: "",
                                      htmlTagName: "a",
                                      classes: ["basics-3-btn-duNIc9"],
                                      styles: [],
                                      attributes: [
                                        { property: "href", value: "#!" },
                                        { property: "data-href", value: "#!" },
                                        { property: "data-target", value: "_blank" },
                                        { property: "data-editable", value: "true" },
                                        { property: "data-name", value: "button" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Visit our website",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "aXCsjH",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "basics-1-component-aXCsjH"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "uQMCxs",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "basics-1-col-container-aXCsjH"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "hCnmsd",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-12"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "NEvoOk",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "TVZ67O",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "pC1UJQ",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "Jl1rsB",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["basics-1-col-td-wrapper-aXCsjH"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "HHfwce",
                                      type: "",
                                      htmlTagName: "h3",
                                      classes: ["basics-1-title-aXCsjH"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "h" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Deals of the day",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "jBMbjb",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "cards-4-component-jBMbjb"],
          styles: [],
          attributes: [{ property: "data-name", value: "col_component" }],
          content: "",
          children: [
            {
              childId: "Jx83uk",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "cards-4-col-container-jBMbjb"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "oc0P0P",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-6", "vertical-align-top"],
                  styles: [],
                  attributes: [{ property: "data-colLayout", value: "true" }],
                  content: "",
                  children: [
                    {
                      childId: "xQxJVw",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "s3OKrv",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "l6KP8s",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "OFywhR",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["cards-4-col-td-wrapper-jBMbjb"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "fNORjf",
                                      type: "",
                                      htmlTagName: "img",
                                      classes: ["cards-4-img-jBMbjb"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-name", value: "img" },
                                        { property: "src", value: "https://cdn.satonda.com/eb/assets/meal2.jpg" },
                                        { property: "alt", value: "Product" },
                                      ],
                                      content: "",
                                      children: [],
                                    },
                                    {
                                      childId: "a76PHk",
                                      type: "",
                                      htmlTagName: "h3",
                                      classes: ["cards-4-title-jBMbjb"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "h" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "This is a great deal",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "PMIsd2",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["cards-4-text-jBMbjb"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Lorem ipsum dolor sit amet consectetur.",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "xtgbF2",
                                      type: "",
                                      htmlTagName: "a",
                                      classes: ["cards-4-btn-jBMbjb"],
                                      styles: [],
                                      attributes: [
                                        { property: "href", value: "#!" },
                                        { property: "data-href", value: "#!" },
                                        { property: "data-target", value: "_blank" },
                                        { property: "data-editable", value: "true" },
                                        { property: "data-name", value: "button" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Order now!",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "P14qwd",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-6", "vertical-align-top"],
                  styles: [],
                  attributes: [{ property: "data-colLayout", value: "true" }],
                  content: "",
                  children: [
                    {
                      childId: "RVkYcA",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "zNgj2n",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "f7IE1b",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "hIHe4p",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["cards-4-col-td-wrapper-jBMbjb"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "UjVHV2",
                                      type: "",
                                      htmlTagName: "img",
                                      classes: ["cards-4-img-jBMbjb"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-name", value: "img" },
                                        { property: "src", value: "https://cdn.satonda.com/eb/assets/meal1.jpg" },
                                        { property: "alt", value: "Product" },
                                      ],
                                      content: "",
                                      children: [],
                                    },
                                    {
                                      childId: "RyPNay",
                                      type: "",
                                      htmlTagName: "h3",
                                      classes: ["cards-4-title-jBMbjb"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "h" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "This as well",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "SFiTTc",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["cards-4-text-jBMbjb"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Lorem ipsum dolor sit amet consectetur.",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "BDsCpe",
                                      type: "",
                                      htmlTagName: "a",
                                      classes: ["cards-4-btn-jBMbjb"],
                                      styles: [],
                                      attributes: [
                                        { property: "href", value: "#!" },
                                        { property: "data-href", value: "#!" },
                                        { property: "data-target", value: "_blank" },
                                        { property: "data-editable", value: "true" },
                                        { property: "data-name", value: "button" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Order now!",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "n7wkD7",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "footer-7-component-n7wkD7"],
          styles: [],
          attributes: [{ property: "data-name", value: "col_component" }],
          content: "",
          children: [
            {
              childId: "iHi8np",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "footer-7-col-container-n7wkD7"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "VzvYr2",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-6", "vertical-align-top"],
                  styles: [],
                  attributes: [{ property: "data-colLayout", value: "true" }],
                  content: "",
                  children: [
                    {
                      childId: "gXj8w6",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "sYXr4z",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "d5FOEm",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "QKUkP8",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["footer-7-col-td-wrapper-n7wkD7"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "WMGwCq",
                                      type: "",
                                      htmlTagName: "h3",
                                      classes: ["footer-7-title-n7wkD7"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "h" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Any questions?",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "hJRIfY",
                                      type: "block",
                                      htmlTagName: "div",
                                      classes: ["footer-7-wrapper-links"],
                                      styles: [],
                                      attributes: [{ property: "data-name", value: "block" }],
                                      content: "",
                                      children: [
                                        {
                                          childId: "lUEJFS",
                                          type: "element",
                                          htmlTagName: "a",
                                          classes: ["footer-7-link-n7wkD7"],
                                          styles: [],
                                          attributes: [
                                            { property: "href", value: "#!" },
                                            { property: "data-href", value: "#!" },
                                            { property: "data-target", value: "_blank" },
                                            { property: "data-editable", value: "true" },
                                            { property: "data-name", value: "a" },
                                          ],
                                          content: "",
                                          children: [
                                            {
                                              childId: "",
                                              type: "",
                                              htmlTagName: "textNode",
                                              classes: [],
                                              styles: [],
                                              attributes: [],
                                              content: "Customer support",
                                              children: [],
                                            },
                                          ],
                                        },
                                        {
                                          childId: "Q6rWSy",
                                          type: "element",
                                          htmlTagName: "a",
                                          classes: ["footer-7-link-n7wkD7"],
                                          styles: [],
                                          attributes: [
                                            { property: "href", value: "#!" },
                                            { property: "data-href", value: "#!" },
                                            { property: "data-target", value: "_blank" },
                                            { property: "data-editable", value: "true" },
                                            { property: "data-name", value: "a" },
                                          ],
                                          content: "",
                                          children: [
                                            {
                                              childId: "",
                                              type: "",
                                              htmlTagName: "textNode",
                                              classes: [],
                                              styles: [],
                                              attributes: [],
                                              content: "Frequently asked questions",
                                              children: [],
                                            },
                                          ],
                                        },
                                        {
                                          childId: "iOcdRW",
                                          type: "element",
                                          htmlTagName: "a",
                                          classes: ["footer-7-link-n7wkD7"],
                                          styles: [],
                                          attributes: [
                                            { property: "href", value: "#!" },
                                            { property: "data-href", value: "#!" },
                                            { property: "data-target", value: "_blank" },
                                            { property: "data-editable", value: "true" },
                                            { property: "data-name", value: "a" },
                                          ],
                                          content: "",
                                          children: [
                                            {
                                              childId: "",
                                              type: "",
                                              htmlTagName: "textNode",
                                              classes: [],
                                              styles: [],
                                              attributes: [],
                                              content: "Jobs",
                                              children: [],
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "PJC4wd",
                                      type: "block",
                                      htmlTagName: "div",
                                      classes: ["footer-7-wrapper-icons-n7wkD7"],
                                      styles: [],
                                      attributes: [{ property: "data-name", value: "block" }],
                                      content: "",
                                      children: [
                                        {
                                          childId: "badz9s",
                                          type: "element",
                                          htmlTagName: "a",
                                          classes: ["footer-7-social-link-n7wkD7"],
                                          styles: [],
                                          attributes: [
                                            { property: "href", value: "#!" },
                                            { property: "data-href", value: "#!" },
                                            { property: "data-target", value: "_blank" },
                                            { property: "data-editable", value: "true" },
                                            { property: "data-name", value: "socialIcon" },
                                          ],
                                          content: "",
                                          children: [
                                            {
                                              childId: "N990Jy",
                                              type: "",
                                              htmlTagName: "img",
                                              classes: ["footer-7-social-icon-n7wkD7"],
                                              styles: [],
                                              attributes: [
                                                { property: "src", value: "https://cdn.satonda.com/eb/assets/facebook-brand-white-square.png" },
                                                { property: "alt", value: "Facebook" },
                                                { property: "data-editable", value: "false" },
                                              ],
                                              content: "",
                                              children: [],
                                            },
                                          ],
                                        },
                                        {
                                          childId: "avT1dL",
                                          type: "element",
                                          htmlTagName: "a",
                                          classes: ["footer-7-social-link-n7wkD7"],
                                          styles: [],
                                          attributes: [
                                            { property: "href", value: "#!" },
                                            { property: "data-href", value: "#!" },
                                            { property: "data-target", value: "_blank" },
                                            { property: "data-editable", value: "true" },
                                            { property: "data-name", value: "socialIcon" },
                                          ],
                                          content: "",
                                          children: [
                                            {
                                              childId: "jjYwMF",
                                              type: "",
                                              htmlTagName: "img",
                                              classes: ["footer-7-social-icon-n7wkD7"],
                                              styles: [],
                                              attributes: [
                                                { property: "src", value: "https://cdn.satonda.com/eb/assets/twitter-brand-white-square.png" },
                                                { property: "alt", value: "Twitter" },
                                                { property: "data-editable", value: "false" },
                                              ],
                                              content: "",
                                              children: [],
                                            },
                                          ],
                                        },
                                        {
                                          childId: "Sz3RWO",
                                          type: "element",
                                          htmlTagName: "a",
                                          classes: ["footer-7-social-link-n7wkD7"],
                                          styles: [],
                                          attributes: [
                                            { property: "href", value: "#!" },
                                            { property: "data-href", value: "#!" },
                                            { property: "data-target", value: "_blank" },
                                            { property: "data-editable", value: "true" },
                                            { property: "data-name", value: "socialIcon" },
                                          ],
                                          content: "",
                                          children: [
                                            {
                                              childId: "jCkB4j",
                                              type: "",
                                              htmlTagName: "img",
                                              classes: ["footer-7-social-icon-n7wkD7"],
                                              styles: [],
                                              attributes: [
                                                { property: "src", value: "https://cdn.satonda.com/eb/assets/youtube-brand-white-square.png" },
                                                { property: "alt", value: "Youtube" },
                                                { property: "data-editable", value: "false" },
                                              ],
                                              content: "",
                                              children: [],
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  childId: "XYyiUi",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-6", "vertical-align-top"],
                  styles: [],
                  attributes: [{ property: "data-colLayout", value: "true" }],
                  content: "",
                  children: [
                    {
                      childId: "ZnEQDt",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "uJIUFo",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "yMXx2s",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "G3DaLl",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["footer-7-col-td-wrapper-n7wkD7"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "XHe4YW",
                                      type: "block",
                                      htmlTagName: "div",
                                      classes: ["footer-7-wrapper-list"],
                                      styles: [],
                                      attributes: [{ property: "data-name", value: "block" }],
                                      content: "",
                                      children: [
                                        {
                                          childId: "oY7d4X",
                                          type: "element",
                                          htmlTagName: "div",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "",
                                          children: [
                                            {
                                              childId: "cCNOyT",
                                              type: "",
                                              htmlTagName: "p",
                                              classes: ["footer-7-listitem-n7wkD7"],
                                              styles: [],
                                              attributes: [
                                                { property: "data-editable", value: "true" },
                                                { property: "data-texteditable", value: "true" },
                                                { property: "data-name", value: "p" },
                                              ],
                                              content: "",
                                              children: [
                                                {
                                                  childId: "eL888V",
                                                  type: "",
                                                  htmlTagName: "span",
                                                  classes: [""],
                                                  styles: [{ property: "color", value: "rgb(40, 167, 69)" }],
                                                  attributes: [
                                                    { property: "data-name", value: "span" },
                                                    { property: "data-checkparent", value: "true" },
                                                  ],
                                                  content: "",
                                                  children: [
                                                    {
                                                      childId: "",
                                                      type: "",
                                                      htmlTagName: "textNode",
                                                      classes: [],
                                                      styles: [],
                                                      attributes: [],
                                                      content: "✔",
                                                      children: [],
                                                    },
                                                  ],
                                                },
                                                {
                                                  childId: "tzWZWM",
                                                  type: "",
                                                  htmlTagName: "span",
                                                  classes: [""],
                                                  styles: [{ property: "color", value: "rgb(255, 255, 255)" }],
                                                  attributes: [
                                                    { property: "data-name", value: "span" },
                                                    { property: "data-checkparent", value: "true" },
                                                  ],
                                                  content: "",
                                                  children: [
                                                    {
                                                      childId: "",
                                                      type: "",
                                                      htmlTagName: "textNode",
                                                      classes: [],
                                                      styles: [],
                                                      attributes: [],
                                                      content: "  10+ years experience in being great",
                                                      children: [],
                                                    },
                                                  ],
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                        {
                                          childId: "GP0gJz",
                                          type: "element",
                                          htmlTagName: "div",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "",
                                          children: [
                                            {
                                              childId: "sj0EL1",
                                              type: "",
                                              htmlTagName: "p",
                                              classes: ["footer-7-listitem-n7wkD7"],
                                              styles: [],
                                              attributes: [
                                                { property: "data-editable", value: "true" },
                                                { property: "data-texteditable", value: "true" },
                                                { property: "data-name", value: "p" },
                                              ],
                                              content: "",
                                              children: [
                                                {
                                                  childId: "U9RV3v",
                                                  type: "",
                                                  htmlTagName: "span",
                                                  classes: [""],
                                                  styles: [{ property: "color", value: "rgb(40, 167, 69)" }],
                                                  attributes: [
                                                    { property: "data-name", value: "span" },
                                                    { property: "data-checkparent", value: "true" },
                                                  ],
                                                  content: "",
                                                  children: [
                                                    {
                                                      childId: "",
                                                      type: "",
                                                      htmlTagName: "textNode",
                                                      classes: [],
                                                      styles: [],
                                                      attributes: [],
                                                      content: "✔",
                                                      children: [],
                                                    },
                                                  ],
                                                },
                                                {
                                                  childId: "Wn67LG",
                                                  type: "",
                                                  htmlTagName: "span",
                                                  classes: [""],
                                                  styles: [{ property: "color", value: "rgb(255, 255, 255)" }],
                                                  attributes: [
                                                    { property: "data-name", value: "span" },
                                                    { property: "data-checkparent", value: "true" },
                                                  ],
                                                  content: "",
                                                  children: [
                                                    {
                                                      childId: "",
                                                      type: "",
                                                      htmlTagName: "textNode",
                                                      classes: [],
                                                      styles: [],
                                                      attributes: [],
                                                      content: "  14 days return guarantee",
                                                      children: [],
                                                    },
                                                  ],
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                        {
                                          childId: "GOS9aD",
                                          type: "element",
                                          htmlTagName: "div",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "",
                                          children: [
                                            {
                                              childId: "Z1LX4p",
                                              type: "",
                                              htmlTagName: "p",
                                              classes: ["footer-7-listitem-n7wkD7"],
                                              styles: [],
                                              attributes: [
                                                { property: "data-editable", value: "true" },
                                                { property: "data-texteditable", value: "true" },
                                                { property: "data-name", value: "p" },
                                              ],
                                              content: "",
                                              children: [
                                                {
                                                  childId: "T273Lu",
                                                  type: "",
                                                  htmlTagName: "span",
                                                  classes: [""],
                                                  styles: [{ property: "color", value: "rgb(40, 167, 69)" }],
                                                  attributes: [
                                                    { property: "data-name", value: "span" },
                                                    { property: "data-checkparent", value: "true" },
                                                  ],
                                                  content: "",
                                                  children: [
                                                    {
                                                      childId: "",
                                                      type: "",
                                                      htmlTagName: "textNode",
                                                      classes: [],
                                                      styles: [],
                                                      attributes: [],
                                                      content: "✔",
                                                      children: [],
                                                    },
                                                  ],
                                                },
                                                {
                                                  childId: "NoyZbT",
                                                  type: "",
                                                  htmlTagName: "span",
                                                  classes: [""],
                                                  styles: [{ property: "color", value: "rgb(255, 255, 255)" }],
                                                  attributes: [
                                                    { property: "data-name", value: "span" },
                                                    { property: "data-checkparent", value: "true" },
                                                  ],
                                                  content: "",
                                                  children: [
                                                    {
                                                      childId: "",
                                                      type: "",
                                                      htmlTagName: "textNode",
                                                      classes: [],
                                                      styles: [],
                                                      attributes: [],
                                                      content: "  99% customer satisfaction",
                                                      children: [],
                                                    },
                                                  ],
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "nuuF6j",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "footer-9-component-nuuF6j"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "Mg6LXs",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "footer-9-col-container-nuuF6j"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "oYr9cf",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-12"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "ZhI9Qy",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "ewzna8",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "lGvns9",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "x1WxZa",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["footer-9-col-td-wrapper-nuuF6j"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "PAgr1q",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["footer-9-text1-nuuF6j"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                        { property: "data-uniqueclassname", value: "true" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "© Company name",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "Prj4Oc",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["footer-9-text2-nuuF6j"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                        { property: "data-uniqueclassname", value: "true" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Streetname 1, 1000 XX Amsterdam, The Netherlands",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "jWKRgJ",
                                      type: "element",
                                      htmlTagName: "a",
                                      classes: ["footer-9-link-nuuF6j"],
                                      styles: [],
                                      attributes: [
                                        { property: "href", value: "#!" },
                                        { property: "data-href", value: "%UNSUBSCRIBE_LINK%" },
                                        { property: "data-target", value: "_blank" },
                                        { property: "data-editable", value: "true" },
                                        { property: "data-name", value: "a" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Unsubscribe",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  ebCustomCss: [
    {
      componentId: "sAMGOR",
      classes: [
        {
          className: "basics-7-component-sAMGOR",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(224, 250, 227)" },
            { property: "background", value: "rgb(224, 250, 227)" },
          ],
        },
        {
          className: "basics-7-col-container-sAMGOR",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "background-color", value: "rgb(255, 255, 255)" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "basics-7-col-td-wrapper-sAMGOR",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0rem 0rem 0rem 0rem" },
            { property: "text-align", value: "start" },
          ],
        },
        {
          className: "basics-7-divider-sAMGOR",
          pseudo: "",
          rules: [
            { property: "display", value: "inline-block" },
            { property: "height", value: "50px" },
            { property: "width", value: "100%" },
            { property: "background-color", value: "rgb(224, 250, 227)" },
            { property: "background", value: "rgb(224, 250, 227)" },
            { property: "border-radius", value: "0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(230, 230, 230)" },
          ],
        },
      ],
    },
    {
      componentId: "kLzzQv",
      classes: [
        {
          className: "basics-13-linkwrapper-kLzzQv",
          pseudo: "",
          rules: [
            { property: "display", value: "block" },
            { property: "line-height", value: "0 !important" },
          ],
        },
        {
          className: "basics-13-img-kLzzQv",
          pseudo: "",
          rules: [
            { property: "outline", value: "none" },
            { property: "-ms-interpolation-mode", value: "bicubic" },
            { property: "max-width", value: "100%" },
            { property: "max-height", value: "200px" },
            { property: "width", value: "150px" },
            { property: "height", value: "auto" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "0px" },
            { property: "border-color", value: "rgb(0, 0, 0)" },
            { property: "border-radius", value: "0rem" },
          ],
        },
        {
          className: "basics-13-component-kLzzQv",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(224, 250, 227)" },
            { property: "background", value: "rgb(224, 250, 227)" },
          ],
        },
        {
          className: "basics-13-col-container-kLzzQv",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "background-color", value: "rgb(255, 255, 255)" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "basics-13-col-td-wrapper-kLzzQv",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0.5rem 0rem 0.5rem 0rem" },
            { property: "text-align", value: "center" },
          ],
        },
      ],
    },
    {
      componentId: "tm1jOH",
      classes: [
        {
          className: "basics-1-title-tm1jOH",
          pseudo: "",
          rules: [
            { property: "text-align", value: "left" },
            { property: "color", value: "rgb(29, 165, 43)" },
            { property: "font-weight", value: "bold" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "basics-1-component-tm1jOH",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(224, 250, 227)" },
            { property: "background", value: "rgb(224, 250, 227)" },
          ],
        },
        {
          className: "basics-1-col-container-tm1jOH",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "background-color", value: "rgb(255, 255, 255)" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "basics-1-col-td-wrapper-tm1jOH",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0.5rem 0.75rem 0.5rem 0.75rem" },
            { property: "text-align", value: "start" },
          ],
        },
      ],
    },
    {
      componentId: "e6LnHW",
      classes: [
        {
          className: "basics-8-divider-e6LnHW",
          pseudo: "",
          rules: [
            { property: "width", value: "50px" },
            { property: "display", value: "inline-block" },
            { property: "height", value: "3px" },
            { property: "background", value: "rgb(29, 165, 43)" },
            { property: "background-color", value: "rgb(29, 165, 43)" },
            { property: "border-radius", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(29, 165, 43)" },
          ],
        },
        {
          className: "basics-8-component-e6LnHW",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(224, 250, 227)" },
            { property: "background", value: "rgb(224, 250, 227)" },
          ],
        },
        {
          className: "basics-8-col-container-e6LnHW",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "background-color", value: "rgb(255, 255, 255)" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "basics-8-col-td-wrapper-e6LnHW",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0rem 0.75rem 0rem 0.75rem" },
            { property: "text-align", value: "start" },
          ],
        },
      ],
    },
    {
      componentId: "skP5sP",
      classes: [
        {
          className: "basics-2-text-skP5sP",
          pseudo: "",
          rules: [
            { property: "text-align", value: "start" },
            { property: "color", value: "rgb(33, 37, 41)" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "basics-2-component-skP5sP",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(224, 250, 227)" },
            { property: "background", value: "rgb(224, 250, 227)" },
          ],
        },
        {
          className: "basics-2-col-container-skP5sP",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "background-color", value: "rgb(255, 255, 255)" },
            { property: "padding-top", value: "1rem" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "basics-2-col-td-wrapper-skP5sP",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0.5rem 0.75rem 0.5rem 0.75rem" },
            { property: "text-align", value: "start" },
          ],
        },
      ],
    },
    {
      componentId: "duNIc9",
      classes: [
        {
          className: "basics-3-btn-duNIc9",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "font-weight", value: "bold" },
            { property: "font-style", value: "normal" },
            { property: "text-decoration", value: "none" },
            { property: "color", value: "rgb(255, 255, 255)" },
            { property: "background", value: "rgb(29, 165, 43)" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(29, 165, 43)" },
            { property: "border-radius", value: "0rem" },
            { property: "padding", value: "0.5rem 1rem 0.5rem 1rem" },
            { property: "display", value: "inline-block" },
            { property: "font-size", value: "16px" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "basics-3-component-duNIc9",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(224, 250, 227)" },
            { property: "background", value: "rgb(224, 250, 227)" },
          ],
        },
        {
          className: "basics-3-col-container-duNIc9",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "background-color", value: "rgb(255, 255, 255)" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "basics-3-col-td-wrapper-duNIc9",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0.5rem 0.75rem 0.5rem 0.75rem" },
            { property: "text-align", value: "start" },
          ],
        },
      ],
    },
    {
      componentId: "jBMbjb",
      classes: [
        {
          className: "cards-4-title-jBMbjb",
          pseudo: "",
          rules: [
            { property: "text-align", value: "left" },
            { property: "color", value: "rgb(29, 165, 43)" },
            { property: "font-weight", value: "bold" },
            { property: "margin", value: "0rem 0rem 0.5rem 0rem" },
          ],
        },
        {
          className: "cards-4-text-jBMbjb",
          pseudo: "",
          rules: [
            { property: "text-align", value: "left" },
            { property: "color", value: "rgb(33, 37, 41)" },
            { property: "font-size", value: "14px" },
            { property: "margin", value: "0rem 0rem 0.25rem 0rem" },
          ],
        },
        {
          className: "cards-4-btn-jBMbjb",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "font-weight", value: "normal" },
            { property: "font-style", value: "normal" },
            { property: "text-decoration", value: "underline" },
            { property: "color", value: "rgb(20, 113, 29)" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(29, 165, 43)" },
            { property: "border-radius", value: "0rem" },
            { property: "padding", value: "0.5rem 0rem 0.5rem 0rem" },
            { property: "display", value: "inline-block" },
            { property: "font-size", value: "16px" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "cards-4-img-jBMbjb",
          pseudo: "",
          rules: [
            { property: "outline", value: "none" },
            { property: "-ms-interpolation-mode", value: "bicubic" },
            { property: "max-width", value: "100%" },
            { property: "max-height", value: "200px" },
            { property: "width", value: "100%" },
            { property: "height", value: "180px" },
            { property: "margin", value: "0rem 0rem 0.5rem 0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "0px" },
            { property: "border-color", value: "rgb(0, 0, 0)" },
            { property: "border-radius", value: "0rem" },
          ],
        },
        {
          className: "cards-4-component-jBMbjb",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(224, 250, 227)" },
            { property: "background", value: "rgb(224, 250, 227)" },
          ],
        },
        {
          className: "cards-4-col-container-jBMbjb",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "padding-bottom", value: "1.5rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "background-color", value: "rgb(255, 255, 255)" },
            { property: "padding-top", value: "0rem" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "cards-4-col-td-wrapper-jBMbjb",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0.5rem 0.75rem 0.5rem 0.75rem" },
            { property: "text-align", value: "start" },
          ],
        },
      ],
    },
    {
      componentId: "n7wkD7",
      classes: [
        {
          className: "footer-7-title-n7wkD7",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(255, 255, 255)" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-align", value: "start" },
            { property: "font-weight", value: "bold" },
          ],
        },
        {
          className: "footer-7-link-n7wkD7",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(255, 255, 255)" },
            { property: "margin", value: "0rem 0rem 0.125rem 0rem" },
            { property: "display", value: "block" },
            { property: "text-decoration", value: "underline" },
          ],
        },
        {
          className: "footer-7-wrapper-icons-n7wkD7",
          pseudo: "",
          rules: [
            { property: "text-align", value: "start" },
            { property: "display", value: "block" },
            { property: "margin", value: "1rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "footer-7-social-link-n7wkD7",
          pseudo: "",
          rules: [
            { property: "display", value: "inline-block" },
            { property: "text-decoration", value: "none" },
            { property: "margin", value: "0rem 1rem 0rem 0rem" },
          ],
        },
        { className: "footer-7-social-link-n7wkD7", pseudo: "last-child", rules: [{ property: "margin-right", value: "0" }] },
        {
          className: "footer-7-social-icon-n7wkD7",
          pseudo: "",
          rules: [
            { property: "outline", value: "none" },
            { property: "-ms-interpolation-mode", value: "bicubic" },
            { property: "width", value: "32px" },
            { property: "height", value: "32px" },
            { property: "border-radius", value: "50%" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(255, 255, 255)" },
          ],
        },
        {
          className: "footer-7-listitem-n7wkD7",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(255, 255, 255)" },
            { property: "margin", value: "0rem 0rem 0.25rem 0rem" },
          ],
        },
        {
          className: "footer-7-component-n7wkD7",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(224, 250, 227)" },
            { property: "background", value: "rgb(224, 250, 227)" },
          ],
        },
        {
          className: "footer-7-col-container-n7wkD7",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-top", value: "1rem" },
            { property: "padding-bottom", value: "1rem" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(20, 113, 29)" },
            { property: "background-color", value: "rgb(20, 113, 29)" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "footer-7-col-td-wrapper-n7wkD7",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0.5rem 0.75rem 0.5rem 0.75rem" },
            { property: "text-align", value: "start" },
          ],
        },
      ],
    },
    {
      componentId: "nuuF6j",
      classes: [
        {
          className: "footer-9-text1-nuuF6j",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(108, 117, 125)" },
            { property: "margin", value: "0rem 0rem 0.25rem 0rem" },
            { property: "text-align", value: "center" },
            { property: "font-size", value: "12px" },
          ],
        },
        {
          className: "footer-9-text2-nuuF6j",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(108, 117, 125)" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-align", value: "center" },
            { property: "font-size", value: "12px" },
          ],
        },
        {
          className: "footer-9-link-nuuF6j",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(108, 117, 125)" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "display", value: "block" },
            { property: "text-align", value: "center" },
            { property: "text-decoration", value: "underline" },
            { property: "font-size", value: "12px" },
          ],
        },
        {
          className: "footer-9-component-nuuF6j",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(224, 250, 227)" },
            { property: "background", value: "rgb(224, 250, 227)" },
          ],
        },
        {
          className: "footer-9-col-container-nuuF6j",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "padding-bottom", value: "3rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(224, 250, 227)" },
            { property: "background-color", value: "rgb(224, 250, 227)" },
            { property: "padding-top", value: "2rem" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "footer-9-col-td-wrapper-nuuF6j",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0.5rem 0rem 0.5rem 0rem" },
            { property: "text-align", value: "start" },
          ],
        },
      ],
    },
    {
      componentId: "aXCsjH",
      classes: [
        {
          className: "basics-1-title-aXCsjH",
          pseudo: "",
          rules: [
            { property: "text-align", value: "left" },
            { property: "color", value: "rgb(29, 165, 43)" },
            { property: "font-weight", value: "bold" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "basics-1-component-aXCsjH",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(224, 250, 227)" },
            { property: "background", value: "rgb(224, 250, 227)" },
          ],
        },
        {
          className: "basics-1-col-container-aXCsjH",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "padding-top", value: "1.5rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "background-color", value: "rgb(255, 255, 255)" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "basics-1-col-td-wrapper-aXCsjH",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0.5rem 0.75rem 0.5rem 0.75rem" },
            { property: "text-align", value: "start" },
          ],
        },
      ],
    },
  ],
  ebCssVars: {
    fontHeadersName: "Arial",
    fontHeadersWeight: "400",
    fontBodyName: "Arial",
    fontBodyWeight: "400",
    color1: "rgb(29, 165, 43)",
    color2: "rgb(225, 250, 227)",
    color3: "rgb(20, 113, 29)",
    color4: "rgba(13, 202, 240, 1)",
    color5: "rgba(255, 193, 7, 1)",
    color6: "rgba(220, 53, 69, 1)",
    color7: "rgba(233, 236, 239, 1)",
    color8: "rgba(33, 37, 41, 1)",
    color9: "rgba(206, 212, 218, 1)",
    color10: "rgba(108, 117, 125, 1)",
  },
};
