export const template6 = {
  id: "66033dc16df08542b71d3503",
  name: { en: "Invite to a coffee tasting event", nl: "Uitnodiging voor een koffie evenement" },
  desc: { en: "Invite to a coffee tasting event", nl: "Uitnodiging voor een koffie evenement" },
  categories: ["event"],
  ebContent: [
    {
      pageId: "7a990a7a-6ae3-4578-92e8-36c0ac2fea0b",
      version: "A",
      components: [
        {
          componentId: "uLVJcc",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "basics-7-component-uLVJcc"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "TyNwoS",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "basics-7-col-container-uLVJcc"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "GUSIzJ",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-12"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "x5mA8t",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "ki8XQc",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "TsPV5K",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "FhpgVg",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["basics-7-col-td-wrapper-uLVJcc"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-name", value: "divider" },
                                    { property: "data-editable", value: "true" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "IxKWq7",
                                      type: "",
                                      htmlTagName: "div",
                                      classes: ["basics-7-divider-uLVJcc"],
                                      styles: [],
                                      attributes: [{ property: "data-elementgetter1", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "nWRMlY",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "basics-1-component-nWRMlY"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "ze2u3E",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "basics-1-col-container-nWRMlY"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "CoBFZw",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-12"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "SNc9jy",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "CQOLyb",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "XL6xzA",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "sdq39z",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["basics-1-col-td-wrapper-nWRMlY"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "p7TROe",
                                      type: "",
                                      htmlTagName: "h1",
                                      classes: ["basics-1-title-nWRMlY"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "h" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "You're invited!",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "W8E0HG",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "basics-4-component-W8E0HG"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "i1Sovk",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "basics-4-col-container-W8E0HG"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "YBmWVN",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-12"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "CiYuRX",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "cId06i",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "FL3ZH4",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "A9SssA",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["basics-4-col-td-wrapper-W8E0HG"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "YzykCM",
                                      type: "",
                                      htmlTagName: "img",
                                      classes: ["basics-4-img-W8E0HG"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-name", value: "img" },
                                        { property: "src", value: "https://cdn.satonda.com/eb/assets/coffee3.jpg" },
                                        { property: "alt", value: "Coffee bar" },
                                      ],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "c10pzb",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "basics-2-component-c10pzb"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "pyMWfW",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "basics-2-col-container-c10pzb"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "PMhn6W",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-12"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "CLFVAs",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "ZppVti",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "uvjpCW",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "iryuw7",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["basics-2-col-td-wrapper-c10pzb"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "shnlFB",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["basics-2-text-c10pzb"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content:
                                            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sit amet porttitor eget dolor morbi non arcu. Diam in arcu cursus euismod quis viverra nibh. Ultrices mi tempus imperdiet nulla malesuada pellentesque.",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "nVjekd",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "basics-3-component-nVjekd"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "a9rTf8",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "basics-3-col-container-nVjekd"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "KYXuTd",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-12"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "JyOKNI",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "dNPeik",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "cRlg6k",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "FPkMfq",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["basics-3-col-td-wrapper-nVjekd"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "O9M2bv",
                                      type: "",
                                      htmlTagName: "a",
                                      classes: ["basics-3-btn-nVjekd"],
                                      styles: [],
                                      attributes: [
                                        { property: "href", value: "#!" },
                                        { property: "data-href", value: "#!" },
                                        { property: "data-target", value: "_blank" },
                                        { property: "data-editable", value: "true" },
                                        { property: "data-name", value: "button" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Save your spot!",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "t8HvwA",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "footer-3-component-t8HvwA"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "Hps5nq",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "footer-3-col-container-t8HvwA"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "eCu8dZ",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-12"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "L2I7eY",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "YCOk6d",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "qN733c",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "zOtehb",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["footer-3-col-td-wrapper-t8HvwA"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "ceLJOW",
                                      type: "",
                                      htmlTagName: "table",
                                      classes: ["column-table", "footer-3-table-table-t8HvwA"],
                                      styles: [],
                                      attributes: [
                                        { property: "role", value: "presentation" },
                                        { property: "width", value: "100%" },
                                        { property: "cellspacing", value: "0" },
                                        { property: "cellpadding", value: "0" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "jTrYAs",
                                          type: "",
                                          htmlTagName: "tbody",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "",
                                          children: [
                                            {
                                              childId: "IABQmU",
                                              type: "",
                                              htmlTagName: "tr",
                                              classes: ["footer-3-table-row-t8HvwA"],
                                              styles: [],
                                              attributes: [],
                                              content: "",
                                              children: [
                                                {
                                                  childId: "HpqPpr",
                                                  type: "",
                                                  htmlTagName: "td",
                                                  classes: ["footer-3-table-cell-all-t8HvwA"],
                                                  styles: [],
                                                  attributes: [{ property: "data-tablecell", value: "true" }],
                                                  content: "",
                                                  children: [
                                                    {
                                                      childId: "HCVFcN",
                                                      type: "block",
                                                      htmlTagName: "div",
                                                      classes: ["footer-3-wrapper-icons-t8HvwA"],
                                                      styles: [],
                                                      attributes: [{ property: "data-name", value: "block" }],
                                                      content: "",
                                                      children: [
                                                        {
                                                          childId: "SVFQ8u",
                                                          type: "element",
                                                          htmlTagName: "a",
                                                          classes: ["footer-3-social-link-t8HvwA"],
                                                          styles: [],
                                                          attributes: [
                                                            { property: "href", value: "#!" },
                                                            { property: "data-href", value: "#!" },
                                                            { property: "data-target", value: "_blank" },
                                                            { property: "data-editable", value: "true" },
                                                            { property: "data-name", value: "socialIcon" },
                                                          ],
                                                          content: "",
                                                          children: [
                                                            {
                                                              childId: "I2aDVd",
                                                              type: "",
                                                              htmlTagName: "img",
                                                              classes: ["footer-3-social-icon-t8HvwA"],
                                                              styles: [],
                                                              attributes: [
                                                                {
                                                                  property: "src",
                                                                  value: "https://cdn.satonda.com/eb/assets/facebook-white-brand-square.png",
                                                                },
                                                                { property: "alt", value: "Facebook" },
                                                                { property: "data-editable", value: "false" },
                                                              ],
                                                              content: "",
                                                              children: [],
                                                            },
                                                          ],
                                                        },
                                                        {
                                                          childId: "xVYiZh",
                                                          type: "element",
                                                          htmlTagName: "a",
                                                          classes: ["footer-3-social-link-t8HvwA"],
                                                          styles: [],
                                                          attributes: [
                                                            { property: "href", value: "#!" },
                                                            { property: "data-href", value: "#!" },
                                                            { property: "data-target", value: "_blank" },
                                                            { property: "data-editable", value: "true" },
                                                            { property: "data-name", value: "socialIcon" },
                                                          ],
                                                          content: "",
                                                          children: [
                                                            {
                                                              childId: "lM151q",
                                                              type: "",
                                                              htmlTagName: "img",
                                                              classes: ["footer-3-social-icon-t8HvwA"],
                                                              styles: [],
                                                              attributes: [
                                                                {
                                                                  property: "src",
                                                                  value: "https://cdn.satonda.com/eb/assets/twitter-white-brand-square.png",
                                                                },
                                                                { property: "alt", value: "Twitter" },
                                                                { property: "data-editable", value: "false" },
                                                              ],
                                                              content: "",
                                                              children: [],
                                                            },
                                                          ],
                                                        },
                                                        {
                                                          childId: "PHkNYB",
                                                          type: "element",
                                                          htmlTagName: "a",
                                                          classes: ["footer-3-social-link-t8HvwA"],
                                                          styles: [],
                                                          attributes: [
                                                            { property: "href", value: "#!" },
                                                            { property: "data-href", value: "#!" },
                                                            { property: "data-target", value: "_blank" },
                                                            { property: "data-editable", value: "true" },
                                                            { property: "data-name", value: "socialIcon" },
                                                          ],
                                                          content: "",
                                                          children: [
                                                            {
                                                              childId: "hWKkns",
                                                              type: "",
                                                              htmlTagName: "img",
                                                              classes: ["footer-3-social-icon-t8HvwA"],
                                                              styles: [],
                                                              attributes: [
                                                                {
                                                                  property: "src",
                                                                  value: "https://cdn.satonda.com/eb/assets/youtube-white-brand-square.png",
                                                                },
                                                                { property: "alt", value: "Youtube" },
                                                                { property: "data-editable", value: "false" },
                                                              ],
                                                              content: "",
                                                              children: [],
                                                            },
                                                          ],
                                                        },
                                                      ],
                                                    },
                                                  ],
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "wWKQov",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "footer-9-component-wWKQov"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "PsNz5E",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "footer-9-col-container-wWKQov"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "R8YZQ3",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-12"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "yk7IaZ",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "qh6cBo",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "Oq6xTR",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "cN76oV",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["footer-9-col-td-wrapper-wWKQov"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "PbEN6l",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["footer-9-text1-wWKQov"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                        { property: "data-uniqueclassname", value: "true" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "© Company name",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "qgkFF2",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["footer-9-text2-wWKQov"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                        { property: "data-uniqueclassname", value: "true" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Streetname 1, 1000 XX Amsterdam, The Netherlands",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "EQIbpb",
                                      type: "element",
                                      htmlTagName: "a",
                                      classes: ["footer-9-link-wWKQov"],
                                      styles: [],
                                      attributes: [
                                        { property: "href", value: "#!" },
                                        { property: "data-href", value: "%UNSUBSCRIBE_LINK%" },
                                        { property: "data-target", value: "_blank" },
                                        { property: "data-editable", value: "true" },
                                        { property: "data-name", value: "a" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Unsubscribe",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  ebCustomCss: [
    {
      componentId: "uLVJcc",
      classes: [
        {
          className: "basics-7-component-uLVJcc",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(238, 211, 191)" },
            { property: "background", value: "rgb(238, 211, 191)" },
          ],
        },
        {
          className: "basics-7-col-container-uLVJcc",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "background-color", value: "rgb(255, 255, 255)" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "basics-7-col-td-wrapper-uLVJcc",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0rem 0rem 0rem 0rem" },
            { property: "text-align", value: "start" },
          ],
        },
        {
          className: "basics-7-divider-uLVJcc",
          pseudo: "",
          rules: [
            { property: "display", value: "inline-block" },
            { property: "height", value: "50px" },
            { property: "width", value: "100%" },
            { property: "background-color", value: "rgb(238, 211, 191)" },
            { property: "background", value: "rgb(238, 211, 191)" },
            { property: "border-radius", value: "0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(230, 230, 230)" },
          ],
        },
      ],
    },
    {
      componentId: "nWRMlY",
      classes: [
        {
          className: "basics-1-component-nWRMlY",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(238, 211, 191)" },
            { property: "background", value: "rgb(238, 211, 191)" },
          ],
        },
        {
          className: "basics-1-col-container-nWRMlY",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "background-color", value: "rgb(255, 255, 255)" },
            { property: "padding-top", value: "1rem" },
            { property: "border-radius", value: "0.75rem 0.75rem 0 0" },
          ],
        },
        {
          className: "basics-1-col-td-wrapper-nWRMlY",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0.5rem 0.5rem 0.5rem 0.5rem" },
            { property: "text-align", value: "start" },
          ],
        },
        {
          className: "basics-1-title-nWRMlY",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgb(133, 78, 35)" },
            { property: "font-weight", value: "bold" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
          ],
        },
      ],
    },
    {
      componentId: "W8E0HG",
      classes: [
        {
          className: "basics-4-component-W8E0HG",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(238, 211, 191)" },
            { property: "background", value: "rgb(238, 211, 191)" },
          ],
        },
        {
          className: "basics-4-col-container-W8E0HG",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "background-color", value: "rgb(255, 255, 255)" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "basics-4-col-td-wrapper-W8E0HG",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0.5rem 0rem 0.5rem 0rem" },
            { property: "text-align", value: "start" },
          ],
        },
        {
          className: "basics-4-img-W8E0HG",
          pseudo: "",
          rules: [
            { property: "outline", value: "none" },
            { property: "-ms-interpolation-mode", value: "bicubic" },
            { property: "max-width", value: "100%" },
            { property: "max-height", value: "200px" },
            { property: "width", value: "100%" },
            { property: "height", value: "auto" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "0px" },
            { property: "border-color", value: "rgb(0, 0, 0)" },
            { property: "border-radius", value: "0rem" },
          ],
        },
      ],
    },
    {
      componentId: "c10pzb",
      classes: [
        {
          className: "basics-2-text-c10pzb",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(133, 78, 35)" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "basics-2-component-c10pzb",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(238, 211, 191)" },
            { property: "background", value: "rgb(238, 211, 191)" },
          ],
        },
        {
          className: "basics-2-col-container-c10pzb",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "background-color", value: "rgb(255, 255, 255)" },
            { property: "padding-top", value: "0.75rem" },
            { property: "padding-bottom", value: "0.75rem" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "basics-2-col-td-wrapper-c10pzb",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0.5rem 0.75rem 0.5rem 0.75rem" },
            { property: "text-align", value: "start" },
          ],
        },
      ],
    },
    {
      componentId: "nVjekd",
      classes: [
        {
          className: "basics-3-btn-nVjekd",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "font-weight", value: "bold" },
            { property: "font-style", value: "normal" },
            { property: "text-decoration", value: "none" },
            { property: "color", value: "rgb(255, 255, 255)" },
            { property: "background", value: "rgb(133, 78, 35)" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(133, 78, 35)" },
            { property: "border-radius", value: "1000px" },
            { property: "padding", value: "0.5rem 0rem 0.5rem 0rem" },
            { property: "display", value: "block" },
            { property: "font-size", value: "16px" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "basics-3-component-nVjekd",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(238, 211, 191)" },
            { property: "background", value: "rgb(238, 211, 191)" },
          ],
        },
        {
          className: "basics-3-col-container-nVjekd",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-top", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "background-color", value: "rgb(255, 255, 255)" },
            { property: "padding-bottom", value: "1rem" },
            { property: "border-radius", value: "0 0 0.75rem 0.75rem" },
          ],
        },
        {
          className: "basics-3-col-td-wrapper-nVjekd",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0.5rem 0.75rem 0.5rem 0.75rem" },
            { property: "text-align", value: "start" },
          ],
        },
      ],
    },
    {
      componentId: "t8HvwA",
      classes: [
        { className: "footer-3-table-table-t8HvwA", pseudo: "", rules: [] },
        { className: "footer-3-table-row-t8HvwA", pseudo: "", rules: [] },
        {
          className: "footer-3-table-cell-all-t8HvwA",
          pseudo: "",
          rules: [
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "padding", value: "0px 10px 0px 10px" },
          ],
        },
        {
          className: "footer-3-wrapper-icons-t8HvwA",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "display", value: "block" },
          ],
        },
        {
          className: "footer-3-social-link-t8HvwA",
          pseudo: "",
          rules: [
            { property: "display", value: "inline-block" },
            { property: "text-decoration", value: "none" },
            { property: "margin", value: "0rem 1rem 0rem 0rem" },
          ],
        },
        { className: "footer-3-social-link-t8HvwA", pseudo: "last-child", rules: [{ property: "margin-right", value: "0" }] },
        {
          className: "footer-3-social-icon-t8HvwA",
          pseudo: "",
          rules: [
            { property: "outline", value: "none" },
            { property: "-ms-interpolation-mode", value: "bicubic" },
            { property: "width", value: "32px" },
            { property: "height", value: "32px" },
            { property: "border-radius", value: "50%" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(255, 255, 255)" },
          ],
        },
        {
          className: "footer-3-component-t8HvwA",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(238, 211, 191)" },
            { property: "background", value: "rgb(238, 211, 191)" },
          ],
        },
        {
          className: "footer-3-col-container-t8HvwA",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-top", value: "1.5rem" },
            { property: "padding-bottom", value: "1.5rem" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(238, 211, 191)" },
            { property: "background-color", value: "rgb(238, 211, 191)" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "footer-3-col-td-wrapper-t8HvwA",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0.5rem 0rem 0.5rem 0rem" },
            { property: "text-align", value: "start" },
          ],
        },
      ],
    },
    {
      componentId: "wWKQov",
      classes: [
        {
          className: "footer-9-text1-wWKQov",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(108, 117, 125)" },
            { property: "margin", value: "0rem 0rem 0.25rem 0rem" },
            { property: "text-align", value: "center" },
            { property: "font-size", value: "12px" },
          ],
        },
        {
          className: "footer-9-text2-wWKQov",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(108, 117, 125)" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-align", value: "center" },
            { property: "font-size", value: "12px" },
          ],
        },
        {
          className: "footer-9-link-wWKQov",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(108, 117, 125)" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "display", value: "block" },
            { property: "text-align", value: "center" },
            { property: "text-decoration", value: "underline" },
            { property: "font-size", value: "12px" },
          ],
        },
        {
          className: "footer-9-component-wWKQov",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(238, 211, 191)" },
            { property: "background", value: "rgb(238, 211, 191)" },
          ],
        },
        {
          className: "footer-9-col-container-wWKQov",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(238, 211, 191)" },
            { property: "background-color", value: "rgb(238, 211, 191)" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "footer-9-col-td-wrapper-wWKQov",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0.5rem 0rem 0.5rem 0rem" },
            { property: "text-align", value: "start" },
          ],
        },
      ],
    },
  ],
  ebCssVars: {
    fontHeadersName: "Arial",
    fontHeadersWeight: "400",
    fontBodyName: "Arial",
    fontBodyWeight: "400",
    color1: "rgb(133, 78, 35)",
    color2: "rgb(238, 211, 191)",
    color3: "rgba(40, 167, 69, 1)",
    color4: "rgba(13, 202, 240, 1)",
    color5: "rgba(255, 193, 7, 1)",
    color6: "rgba(220, 53, 69, 1)",
    color7: "rgba(233, 236, 239, 1)",
    color8: "rgba(33, 37, 41, 1)",
    color9: "rgba(206, 212, 218, 1)",
    color10: "rgba(108, 117, 125, 1)",
  },
};
