import { getState } from "./stateFunctions";
import {
  getTargetObj,
  getTargetParent,
  getCustomCss,
  getFirstChildWithAttr,
  getFirstChildByTagname,
  getFirstChildByClassname,
  getTargetCustomClassname,
  getTargetCustomCssRules,
  getAllChildrenWithAttr,
  getParentWithAttr,
  getFirstChildByClassnameMatchRegex,
} from "./componentObjectFunctions";

export const getStartStyles = (editForms) => {
  // Each style to be in format { editFormField, value }
  try {
    // Get the target in the ebContent object
    let selectedElement = getState("eb", "selectedElement");
    let splitSelectedElementId = selectedElement.split("-");
    let customCss = getCustomCss(splitSelectedElementId[0]);
    let target = getTargetObj(splitSelectedElementId);
    // Loop through all editForms to get starting values
    let startStyles = {};
    startStyles = { ...startStyles, ...elementId(target, customCss) };
    editForms.forEach((editForm) => {
      editForm === "alignHori" && (startStyles = { ...startStyles, ...alignHori(target, customCss) });
      editForm === "width" && (startStyles = { ...startStyles, ...width(target, customCss) });
      editForm === "bgColor" && (startStyles = { ...startStyles, ...bgColor(target, customCss) });
      editForm === "textColor" && (startStyles = { ...startStyles, ...textColor(target, customCss) });
      editForm === "border" && (startStyles = { ...startStyles, ...border(target, customCss) });
      editForm === "borderRadius" && (startStyles = { ...startStyles, ...borderRadius(target, customCss) });
      editForm === "linkEditor" && (startStyles = { ...startStyles, ...linkEditor(target, customCss) });
      editForm === "padding" && (startStyles = { ...startStyles, ...padding(target, customCss) });
      editForm === "margin" && (startStyles = { ...startStyles, ...margin(target, customCss) });
      editForm === "imgSource" && (startStyles = { ...startStyles, ...imgSource(target, customCss) });
      editForm === "imgResize" && (startStyles = { ...startStyles, ...imgResize(target, customCss) });
      editForm === "buttonEditor" && (startStyles = { ...startStyles, ...buttonEditor(target, customCss) });
      editForm === "listMarker2" && (startStyles = { ...startStyles, ...listMarker2(target, customCss) });
      editForm === "component" && (startStyles = { ...startStyles, ...component(target, customCss) });
      editForm === "colLayout" && (startStyles = { ...startStyles, ...colLayout(target, customCss) });
      editForm === "divider" && (startStyles = { ...startStyles, ...divider(target, customCss) });
      editForm === "socialIconSource" && (startStyles = { ...startStyles, ...socialIconSource(target, customCss) });
      editForm === "downloadAppSource" && (startStyles = { ...startStyles, ...downloadAppSource(target, customCss) });
      editForm === "numRatingStars" && (startStyles = { ...startStyles, ...numRatingStars(target, customCss) });
      editForm === "linkElement" && (startStyles = { ...startStyles, ...linkElement(target, customCss) });
    });
    return startStyles;
  } catch (error) {
    console.error(error);
    return [];
  }
};

const ELEMENTS_TO_GET_ALIGNMENTWRAPPER = ["element_col", "statistics3a"];

const elementId = (target, customCss) => {
  try {
    // target is the element that was clicked. Get its wrapper if needed
    // let linkWrapper = getParentWithAttr("data-linkwrapper", "true");
    // linkWrapper !== null && (target = linkWrapper);
    return {
      elementId: (target.attributes.filter((attr) => attr.property === "id")[0] || { value: "" }).value,
    };
  } catch (error) {
    console.error(error);
    return null;
  }
};

const alignHori = (target, customCss) => {
  try {
    let targetCustomClassname = getTargetCustomClassname(target.classes, customCss);
    let { customClassRulesNormal } = getTargetCustomCssRules(customCss, targetCustomClassname);
    return {
      alignHori: (customClassRulesNormal.filter((rule) => rule.property === "text-align")[0] || { value: "start" }).value,
    };
  } catch (error) {
    console.error(error);
    return null;
  }
};

const width = (target, customCss) => {
  try {
    return {
      widthMobile: parseInt((target.classes.join(" ").match(/w-(\d+)/) || ["", 100])[1]),
      widthTablet: parseInt((target.classes.join(" ").match(/w-md-(\d+)/) || ["", 100])[1]),
      widthDesktop: parseInt((target.classes.join(" ").match(/w-lg-(\d+)/) || ["", 100])[1]),
    };
  } catch (error) {
    console.error(error);
    return null;
  }
};

const bgColor = (target, customCss) => {
  try {
    if (
      target.attributes.filter((attr) => attr.property === "data-name" && ELEMENTS_TO_GET_ALIGNMENTWRAPPER.includes(attr.value)).length > 0 &&
      typeof getFirstChildWithAttr(target, "data-elementalignmentwrapper", "true") !== "undefined"
    ) {
      target = getFirstChildWithAttr(target, "data-elementalignmentwrapper", "true");
    }
    // Get target's custom css rules
    let targetCustomClassname = getTargetCustomClassname(target.classes, customCss);
    let { customClassRulesNormal } = getTargetCustomCssRules(customCss, targetCustomClassname);
    let { backgroundColor, boolBgIsGradient, bgGradientDirection, bgGradientColor1, bgGradientColor2, bgGradientStop1, bgGradientStop2 } =
      getBgVars(customClassRulesNormal);
    return {
      // bgColor: (customClassRulesNormal.filter((rule) => rule.property === "background-color")[0] || { value: "rgb(255, 255, 255)" }).value,
      // bgColorHover: (customClassRulesHover.filter((rule) => rule.property === "background-color")[0] || { value: "rgb(255, 255, 255)" }).value,
      bgColor: backgroundColor,
      boolBgIsGradient,
      bgGradientDirection,
      bgGradientColor1,
      bgGradientColor2,
      bgGradientStop1,
      bgGradientStop2,
    };
  } catch (error) {
    console.error(error);
    return null;
  }
};

const border = (target, customCss) => {
  try {
    if (
      target.attributes.filter((attr) => attr.property === "data-name" && ELEMENTS_TO_GET_ALIGNMENTWRAPPER.includes(attr.value)).length > 0 &&
      typeof getFirstChildWithAttr(target, "data-elementalignmentwrapper", "true") !== "undefined"
    ) {
      target = getFirstChildWithAttr(target, "data-elementalignmentwrapper", "true");
    }
    // Get target's custom css rules
    let targetCustomClassname = getTargetCustomClassname(target.classes, customCss);
    let { customClassRulesNormal, customClassRulesHover } = getTargetCustomCssRules(customCss, targetCustomClassname);
    return getBorderCssRules(customClassRulesNormal, customClassRulesHover);
    // // Get class content
    // // format: border-style: [topStyle] [rightStyle] [bottomStyle] [leftStyle]
    // let borderStyleClass = (
    //   customClassRulesNormal.filter((rule) => rule.property === "border-style")[0] || { value: "none none none none" }
    // ).value.split(" ");
    // // borderSide = none, all, left, right, top, bottom, topbottom, leftright
    // let borders = {
    //   top: borderStyleClass[0],
    //   right: borderStyleClass[1],
    //   bottom: borderStyleClass[2],
    //   left: borderStyleClass[3],
    // };
    // let borderSide = "none";
    // if (borders.top !== "none" && borders.right !== "none" && borders.bottom !== "none" && borders.left !== "none") {
    //   borderSide = "all";
    // }
    // if (borders.top === "none" && borders.right === "none" && borders.bottom === "none" && borders.left !== "none") {
    //   borderSide = "left";
    // }
    // if (borders.top === "none" && borders.right !== "none" && borders.bottom === "none" && borders.left === "none") {
    //   borderSide = "right";
    // }
    // if (borders.top !== "none" && borders.right === "none" && borders.bottom === "none" && borders.left === "none") {
    //   borderSide = "top";
    // }
    // if (borders.top === "none" && borders.right === "none" && borders.bottom !== "none" && borders.left === "none") {
    //   borderSide = "bottom";
    // }
    // if (borders.top !== "none" && borders.right === "none" && borders.bottom !== "none" && borders.left === "none") {
    //   borderSide = "topbottom";
    // }
    // if (borders.top === "none" && borders.right !== "none" && borders.bottom === "none" && borders.left !== "none") {
    //   borderSide = "leftright";
    // }
    // return {
    //   borderSide: borderSide,
    //   borderType: borderStyleClass.filter((style) => style !== "none")[0] || "solid",
    //   borderWidth: removeUnit((customClassRulesNormal.filter((rule) => rule.property === "border-width")[0] || { value: 0 }).value),
    //   borderColor: (customClassRulesNormal.filter((rule) => rule.property === "border-color")[0] || { value: "rgb(0, 0, 0)" }).value,
    //   borderColorHover: (customClassRulesHover.filter((rule) => rule.property === "border-color")[0] || { value: "rgb(0, 0, 0)" }).value,
    // };
  } catch (error) {
    console.error(error);
    return null;
  }
};

const borderRadius = (target, customCss) => {
  try {
    if (
      target.attributes.filter((attr) => attr.property === "data-name" && ELEMENTS_TO_GET_ALIGNMENTWRAPPER.includes(attr.value)).length > 0 &&
      typeof getFirstChildWithAttr(target, "data-elementalignmentwrapper", "true") !== "undefined"
    ) {
      target = getFirstChildWithAttr(target, "data-elementalignmentwrapper", "true");
    }
    // Get target's custom css rules
    let targetCustomClassname = getTargetCustomClassname(target.classes, customCss);
    let { customClassRulesNormal } = getTargetCustomCssRules(customCss, targetCustomClassname);
    // Get class content
    return {
      borderRadius: removeUnit((customClassRulesNormal.filter((rule) => rule.property === "border-radius")[0] || { value: 0 }).value),
    };
  } catch (error) {
    console.error(error);
    return null;
  }
};

const textColor = (target, customCss) => {
  try {
    // Get target's custom css rules
    let targetCustomClassname = getTargetCustomClassname(target.classes, customCss);
    let { customClassRulesNormal, customClassRulesHover } = getTargetCustomCssRules(customCss, targetCustomClassname);
    return {
      textColor: (customClassRulesNormal.filter((rule) => rule.property === "color")[0] || { value: "rgb(0, 0, 0)" }).value,
      textColorHover: (customClassRulesHover.filter((rule) => rule.property === "color")[0] || { value: "rgb(0, 0, 0)" }).value,
    };
  } catch (error) {
    console.error(error);
    return null;
  }
};

const linkEditor = (target, customCss) => {
  try {
    // Get target's custom css rules
    let targetCustomClassname = getTargetCustomClassname(target.classes, customCss);
    let { customClassRulesNormal } = getTargetCustomCssRules(customCss, targetCustomClassname);

    let linkContent = getFirstChildByTagname(target, "textNode");
    return {
      linkDest: (target.attributes.filter((attr) => attr.property === "data-href")[0] || { value: "#!" }).value,
      linkTarget: (target.attributes.filter((attr) => attr.property === "data-target")[0] || { value: "_blank" }).value,
      ...textColor(target, customCss),
      linkDeco: (customClassRulesNormal.filter((rule) => rule.property === "text-decoration")[0] || { value: "none" }).value,
      linkContent: linkContent === null || typeof linkContent === "undefined" ? null : linkContent.content,
      fontSize: removeUnit((customClassRulesNormal.filter((rule) => rule.property === "font-size")[0] || { value: "16" }).value),
    };
  } catch (error) {
    console.error(error);
    return null;
  }
};

const padding = (target, customCss) => {
  try {
    // Get target's custom css rules
    let targetCustomClassname = getTargetCustomClassname(target.classes, customCss);
    let { customClassRulesNormal } = getTargetCustomCssRules(customCss, targetCustomClassname);
    // Get class content (format: top right bottom left)
    let padding = (customClassRulesNormal.filter((rule) => rule.property === "padding")[0] || { value: "0 0 0 0" }).value.split(" ");
    return {
      paddingTop: removeUnit(padding[0]),
      paddingRight: removeUnit(padding[1]),
      paddingBottom: removeUnit(padding[2]),
      paddingLeft: removeUnit(padding[3]),
    };
  } catch (error) {
    console.error(error);
    return null;
  }
};

const margin = (target, customCss) => {
  try {
    // Get target's custom css rules
    let targetCustomClassname = getTargetCustomClassname(target.classes, customCss);
    let { customClassRulesNormal } = getTargetCustomCssRules(customCss, targetCustomClassname);
    // Get class content (format: top right bottom left)
    let margin = (customClassRulesNormal.filter((rule) => rule.property === "margin")[0] || { value: "0 0 0 0" }).value.split(" ");
    return {
      marginTop: removeUnit(margin[0]),
      marginRight: removeUnit(margin[1]),
      marginBottom: removeUnit(margin[2]),
      marginLeft: removeUnit(margin[3]),
    };
  } catch (error) {
    console.error(error);
    return null;
  }
};

const imgSource = (target, customCss) => {
  try {
    return {
      imgSource: (target.attributes.filter((attr) => attr.property === "src")[0] || { value: "" }).value,
      imgDesc: (target.attributes.filter((attr) => attr.property === "alt")[0] || { value: "" }).value,
    };
  } catch (error) {
    console.error(error);
    return null;
  }
};

const imgResize = (target, customCss) => {
  try {
    // Get target's custom css rules
    let targetCustomClassname = getTargetCustomClassname(target.classes, customCss);
    let { customClassRulesNormal } = getTargetCustomCssRules(customCss, targetCustomClassname);

    let imgResizeWidth = (customClassRulesNormal.filter((rule) => rule.property === "width")[0] || { value: "auto" }).value;
    let imgResizeWidthPx = 0;
    if (!["auto", "100%"].includes(imgResizeWidth)) {
      imgResizeWidthPx = removeUnit(imgResizeWidth);
      imgResizeWidth = "";
    }

    let imgResizeHeight = (customClassRulesNormal.filter((rule) => rule.property === "height")[0] || { value: "auto" }).value;
    let imgResizeHeightPx = 0;
    if (!["auto", "100%"].includes(imgResizeHeight)) {
      imgResizeHeightPx = removeUnit(imgResizeHeight);
      imgResizeHeight = "";
    }

    return {
      imgResizeWidth,
      imgResizeWidthPx,
      imgResizeHeight,
      imgResizeHeightPx,
    };
  } catch (error) {
    console.error(error);
    return null;
  }
};

const listMarker2 = (target, customCss) => {
  try {
    let listWrapper = getParentWithAttr("data-listwrapper", "true");
    let markerSpan = getFirstChildWithAttr(listWrapper, "data-listbullet", "true");
    // Get target's custom css rules
    let targetCustomClassname = getTargetCustomClassname(markerSpan.classes, customCss);
    let { customClassRulesNormal } = getTargetCustomCssRules(customCss, targetCustomClassname);
    // Get class content
    let markerBorderRadius = removeUnit((customClassRulesNormal.filter((rule) => rule.property === "border-radius")[0] || { value: "0" }).value);
    let markerHeight = removeUnit((customClassRulesNormal.filter((rule) => rule.property === "height")[0] || { value: "0" }).value);
    let markerWidth = removeUnit((customClassRulesNormal.filter((rule) => rule.property === "width")[0] || { value: "0" }).value);
    let markerBgColor = (customClassRulesNormal.filter((rule) => rule.property === "background")[0] || { value: "rgba(0, 0, 0, 0)" }).value;
    let markerBorderColor = (customClassRulesNormal.filter((rule) => rule.property === "border-color")[0] || { value: "rgba(0, 0, 0, 0)" }).value;
    let markerMarginRight = removeUnit((customClassRulesNormal.filter((rule) => rule.property === "margin-right")[0] || { value: "0" }).value);
    // Form           height === width  borderRadius
    // none           true                 0
    // square         true                 0
    // circle         true                 50
    // line           false                0
    let markerForm = "none";
    if (markerHeight > 0 && markerWidth > 0) {
      if (markerHeight === markerWidth) {
        if (markerBorderRadius === 0) {
          markerForm = "square";
        } else {
          markerForm = "circle";
        }
      } else {
        markerForm = "line";
      }
    }
    return {
      markerBgColor,
      markerBorderColor,
      markerMarginRight,
      markerForm,
    };
  } catch (error) {
    console.error(error);
    return null;
  }
};

const buttonEditor = (target, customCss) => {
  try {
    // Get button's parent
    let btnParent = getTargetParent(target.childId);
    // Get target & parent's custom css rules
    let { customClassRulesNormal: classRulesBtn } = getTargetCustomCssRules(customCss, getTargetCustomClassname(target.classes, customCss));
    let { customClassRulesNormal: classRulesParent } = getTargetCustomCssRules(customCss, getTargetCustomClassname(btnParent.classes, customCss));
    // Get button text
    let btnText = getFirstChildByTagname(target, "textNode");
    return {
      btnIsLink: target.htmlTagName === "a",
      linkDest: (target.attributes.filter((attr) => attr.property === "data-href")[0] || { value: "#!" }).value,
      linkTarget: (target.attributes.filter((attr) => attr.property === "data-target")[0] || { value: "_blank" }).value,
      btnIsBold: classRulesBtn.filter((rule) => rule.property === "font-weight" && rule.value === "bold").length > 0,
      btnIsItalic: classRulesBtn.filter((rule) => rule.property === "font-style" && rule.value === "italic").length > 0,
      btnIsUnderlined: classRulesBtn.filter((rule) => rule.property === "text-decoration" && rule.value === "underline").length > 0,
      ...textColor(target, customCss),
      // ...bgColor(target, customCss),
      btnBgColor: (classRulesBtn.filter((rule) => rule.property === "background")[0] || { value: "rgb(255, 255, 255)" }).value,
      ...border(target, customCss),
      ...borderRadius(target, customCss),
      ...padding(target, customCss),
      btnText: btnText === null || typeof btnText === "undefined" ? null : btnText.content,
      btnFullWidth: (classRulesBtn.filter((rule) => rule.property === "display")[0] || { value: "inline-block" }).value === "block",
      alignHori: (classRulesParent.filter((rule) => rule.property === "text-align")[0] || { value: "center" }).value,
      fontSize: removeUnit((classRulesBtn.filter((rule) => rule.property === "font-size")[0] || { value: "16" }).value),
    };
  } catch (error) {
    console.error(error);
    return null;
  }
};

const component = (target, customCss) => {
  try {
    // target = ebtype=component
    let componentColContainer = getFirstChildByClassname(target, "column-container");
    let tdWrapper = getFirstChildByClassnameMatchRegex(target, /col-td-wrapper/);

    let { customClassRulesNormal: cssRulesColContainer } = getTargetCustomCssRules(
      customCss,
      getTargetCustomClassname(componentColContainer.classes, customCss)
    );

    let { componentRadiusSize, componentRadiusSide } = getComponentBorderRadius(cssRulesColContainer);
    let { backgroundColor, boolBgIsGradient, bgGradientDirection, bgGradientColor1, bgGradientColor2, bgGradientStop1, bgGradientStop2 } =
      getBgVars(cssRulesColContainer);
    return {
      ...padding(tdWrapper, customCss),
      ...alignHori(tdWrapper, customCss),
      ...bgColor(target, customCss),
      ...border(componentColContainer, customCss),
      componentBgColor: backgroundColor,
      componentBoolBgIsGradient: boolBgIsGradient,
      componentBgGradientDirection: bgGradientDirection,
      componentBgGradientColor1: bgGradientColor1,
      componentBgGradientColor2: bgGradientColor2,
      componentBgGradientStop1: bgGradientStop1,
      componentBgGradientStop2: bgGradientStop2,
      componentPaddingTop: removeUnit((cssRulesColContainer.filter((rule) => rule.property === "padding-top")[0] || { value: "0" }).value),
      componentPaddingBottom: removeUnit((cssRulesColContainer.filter((rule) => rule.property === "padding-bottom")[0] || { value: "0" }).value),
      componentRadiusSize,
      componentRadiusSide,
    };
  } catch (error) {
    console.error(error);
    return "";
  }
};

const colLayout = (target, customCss) => {
  try {
    // target = ebtype=component
    // Get all columns and their .col-[x] layout/vertical alignment
    let columns = getAllChildrenWithAttr(target, "data-colLayout", "true");
    let layout = columns.map((col) => parseInt(col.classes.filter((className) => className.match(/col-\d+/) !== null)[0].match(/col-(\d+)/)[1]));
    let colVerticalAlign = columns[0].classes.filter((className) => className.includes("vertical-align-"))[0];
    return {
      colLayout: layout.join("-"),
      colVerticalAlign,
    };
  } catch (error) {
    console.error(error);
    return "";
  }
};

const divider = (target, customCss) => {
  try {
    // target = td.col-td-wrapper => get divider
    let divDivider = getFirstChildWithAttr(target, "data-elementgetter1", "true");
    let { customClassRulesNormal: rulesTarget } = getTargetCustomCssRules(customCss, getTargetCustomClassname(target.classes, customCss));
    let { customClassRulesNormal: rulesDivider } = getTargetCustomCssRules(customCss, getTargetCustomClassname(divDivider.classes, customCss));

    let dividerWidth = (rulesDivider.filter((rule) => rule.property === "width")[0] || { value: "auto" }).value;
    let dividerWidthPx = 0;
    if (dividerWidth !== "100%") {
      dividerWidthPx = removeUnit(dividerWidth);
      dividerWidth = "";
    }

    return {
      alignHori: (rulesTarget.filter((rule) => rule.property === "text-align")[0] || { value: "center" }).value,
      height: removeUnit((rulesDivider.filter((rule) => rule.property === "height")[0] || { value: "0" }).value),
      dividerWidthPx,
      dividerWidth,
      ...bgColor(divDivider, customCss),
      ...borderRadius(divDivider, customCss),
      ...border(divDivider, customCss),
    };
  } catch (error) {
    console.error(error);
    return null;
  }
};

const socialIconSource = (target, customCss) => {
  try {
    // target = a. Get its img child
    let socialIconImg = target.children[0];
    let imgSource = (socialIconImg.attributes.filter((attr) => attr.property === "src")[0] || { value: "" }).value;
    // filename: [brand]-[iconColor]-[bgColor]-[shape]
    // shape is irrelevant: can be done via border-radius
    let match = imgSource.match(/\/([\w-]+)-(brand|white|black)-(brand|white|black)/) || ["", "", "", ""];

    return {
      linkDest: (target.attributes.filter((attr) => attr.property === "data-href")[0] || { value: "#!" }).value,
      linkTarget: (target.attributes.filter((attr) => attr.property === "data-target")[0] || { value: "_blank" }).value,
      socialIconName: match[1],
      socialIconColor: match[2],
      socialIconBgColor: match[3],
      ...border(socialIconImg, customCss),
      ...borderRadius(socialIconImg, customCss),
      ...margin(target, customCss),
    };
  } catch (error) {
    console.error(error);
    return null;
  }
};

const downloadAppSource = (target, customCss) => {
  try {
    // target = a. Get its img child
    return {
      linkDest: (target.attributes.filter((attr) => attr.property === "data-href")[0] || { value: "#!" }).value,
      linkTarget: (target.attributes.filter((attr) => attr.property === "data-target")[0] || { value: "_blank" }).value,
    };
  } catch (error) {
    console.error(error);
    return null;
  }
};

const numRatingStars = (target, customCss) => {
  try {
    // target = div wrapper => sum the number of stars shown
    // File names:
    // Full:  https://cdn.satonda.com/eb/assets/star-full.png
    // Half:  https://cdn.satonda.com/eb/assets/star-half.png
    // Empty: https://cdn.satonda.com/eb/assets/star-empty.png
    let numStars = target.children
      .map((img) => img.attributes.filter((attr) => attr.property === "src")[0].value)
      .map((src) => {
        if (src.match(/star-full/) !== null) {
          return 1;
        }
        if (src.match(/star-half/) !== null) {
          return 0.5;
        }
        return 0;
      })
      .reduce((a, b) => a + b, 0);
    return {
      numStars,
    };
  } catch (error) {
    console.error(error);
    return null;
  }
};

const linkElement = (target, customCss) => {
  try {
    // target = child of the link wrapper => get the link wrapper
    let linkWrapper = getParentWithAttr("data-linkwrapper", "true");
    return {
      linkElementDest: (linkWrapper.attributes.filter((attr) => attr.property === "data-href")[0] || { value: "#!" }).value,
      // linkTarget: (target.attributes.filter((attr) => attr.property === "data-target")[0] || { value: "_blank" }).value,
    };
  } catch (error) {
    console.error(error);
    return null;
  }
};

// =============
// == Helpers ==
// =============

const removeUnit = (str) => {
  try {
    // str = e.g. 2px or 0.125rem
    return parseFloat(str);
  } catch (error) {
    console.error(error);
    return str;
  }
};

const getBorderCssRules = (customClassRulesNormal, customClassRulesHover) => {
  try {
    // Get class content
    // format: border-style: [topStyle] [rightStyle] [bottomStyle] [leftStyle]
    let borderStyleClass = (
      customClassRulesNormal.filter((rule) => rule.property === "border-style")[0] || { value: "none none none none" }
    ).value.split(" ");
    // borderSide = none, all, left, right, top, bottom, topbottom, leftright
    let borders = {
      top: borderStyleClass[0],
      right: borderStyleClass[1],
      bottom: borderStyleClass[2],
      left: borderStyleClass[3],
    };
    let borderSide = "none";
    if (borders.top !== "none" && borders.right !== "none" && borders.bottom !== "none" && borders.left !== "none") {
      borderSide = "all";
    }
    if (borders.top === "none" && borders.right === "none" && borders.bottom === "none" && borders.left !== "none") {
      borderSide = "left";
    }
    if (borders.top === "none" && borders.right !== "none" && borders.bottom === "none" && borders.left === "none") {
      borderSide = "right";
    }
    if (borders.top !== "none" && borders.right === "none" && borders.bottom === "none" && borders.left === "none") {
      borderSide = "top";
    }
    if (borders.top === "none" && borders.right === "none" && borders.bottom !== "none" && borders.left === "none") {
      borderSide = "bottom";
    }
    if (borders.top !== "none" && borders.right === "none" && borders.bottom !== "none" && borders.left === "none") {
      borderSide = "topbottom";
    }
    if (borders.top === "none" && borders.right !== "none" && borders.bottom === "none" && borders.left !== "none") {
      borderSide = "leftright";
    }
    if (borders.top !== "none" && borders.right !== "none" && borders.bottom !== "none" && borders.left === "none") {
      borderSide = "exceptleft";
    }
    if (borders.top !== "none" && borders.right === "none" && borders.bottom !== "none" && borders.left !== "none") {
      borderSide = "exceptright";
    }
    if (borders.top === "none" && borders.right !== "none" && borders.bottom !== "none" && borders.left !== "none") {
      borderSide = "excepttop";
    }
    if (borders.top !== "none" && borders.right !== "none" && borders.bottom === "none" && borders.left !== "none") {
      borderSide = "exceptbottom";
    }
    return {
      borderSide: borderSide,
      borderType: borderStyleClass.filter((style) => style !== "none")[0] || "solid",
      borderWidth: removeUnit((customClassRulesNormal.filter((rule) => rule.property === "border-width")[0] || { value: 0 }).value),
      borderColor: (customClassRulesNormal.filter((rule) => rule.property === "border-color")[0] || { value: "rgb(0, 0, 0)" }).value,
      borderColorHover: (customClassRulesHover.filter((rule) => rule.property === "border-color")[0] || { value: "rgb(0, 0, 0)" }).value,
    };
  } catch (error) {
    console.error(error);
    return null;
  }
};

const getComponentBorderRadius = (arrCssRules) => {
  let componentRadiusSize = 0;
  let componentRadiusSide = "all";
  try {
    let borderRadiusCss = (arrCssRules.filter((rule) => rule.property === "border-radius")[0] || { value: "0 0 0 0" }).value.split(" ");
    componentRadiusSize = Math.max(...borderRadiusCss.map((val) => removeUnit(val))) || 0;
    //  top-left | top-right | bottom-right | bottom-left
    let sides = {
      topleft: borderRadiusCss[0],
      topright: borderRadiusCss[1],
      bottomright: borderRadiusCss[2],
      bottomleft: borderRadiusCss[3],
    };
    if (sides.topleft !== "0" && sides.topright !== "0" && sides.bottomright === "0" && sides.bottomleft === "0") {
      componentRadiusSide = "top";
    }
    if (sides.topleft === "0" && sides.topright === "0" && sides.bottomright !== "0" && sides.bottomleft !== "0") {
      componentRadiusSide = "bottom";
    }
    return { componentRadiusSize, componentRadiusSide };
  } catch (error) {
    return { componentRadiusSize, componentRadiusSide };
  }
};

const getBgVars = (arrCssRules) => {
  let backgroundColor = "rgb(255, 255, 255)";
  let boolBgIsGradient = false;
  let bgGradientColor1 = backgroundColor;
  let bgGradientColor2 = backgroundColor;
  let bgGradientStop1 = 0;
  let bgGradientStop2 = 0;
  let bgGradientDirection = 0;
  try {
    // get background-color value
    backgroundColor = (arrCssRules.filter((rule) => rule.property === "background-color")[0] || { value: "rgb(255, 255, 255)" }).value;
    // check whether background is gradient and if so, get values
    let bgCssString = (arrCssRules.filter((rule) => rule.property === "background")[0] || { value: "" }).value;
    bgGradientColor1 = backgroundColor;
    bgGradientColor2 = backgroundColor;
    let values = bgCssString.match(/(\d+)deg.+?rgb\((.+?)\).+?(\d+)%.+?rgb\((.+?)\).+?(\d+)%/);
    if (values !== null) {
      // If background is gradient, set values
      boolBgIsGradient = true;
      bgGradientDirection = parseInt(values[1]);
      bgGradientColor1 = `rgb(${values[2]})`;
      bgGradientStop1 = parseInt(values[3]);
      bgGradientColor2 = `rgb(${values[4]})`;
      bgGradientStop2 = parseInt(values[5]);
    }
    return { backgroundColor, boolBgIsGradient, bgGradientDirection, bgGradientColor1, bgGradientColor2, bgGradientStop1, bgGradientStop2 };
  } catch (error) {
    console.error(error);
    return { backgroundColor, boolBgIsGradient, bgGradientDirection, bgGradientColor1, bgGradientColor2, bgGradientStop1, bgGradientStop2 };
  }
};
