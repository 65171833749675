import { USER_LOADED, AUTH_ERROR, SET_LANG_CODE } from "../actions/types";

const initialState = {
  token: localStorage.getItem("token"),
  wsId: localStorage.getItem("workspace"),
  isAuthenticated: null,
  user: null,
  workspace: null,
  loading: true,
  langCode: "en",
};

export default function auth(state = initialState, action) {
  // Destructure action
  const { type, payload } = action;

  switch (type) {
    case USER_LOADED:
      // payload = { user, workspaces }
      const getWorkspace = () => {
        try {
          return payload.workspaces.filter((ws) => ws._id === localStorage.getItem("workspace"))[0];
        } catch (error) {
          return null;
        }
      };
      return {
        ...state,
        isAuthenticated: true,
        user: payload.user,
        workspace: getWorkspace(),
        loading: false,
        langCode: payload.user.language,
      };
    case AUTH_ERROR:
      localStorage.removeItem("token");
      localStorage.removeItem("workspace");
      return {
        ...state,
        token: null,
        wsId: null,
        isAuthenticated: false,
        user: null,
        workspace: null,
        loading: false,
      };
    case SET_LANG_CODE:
      return {
        ...state,
        langCode: payload,
      };
    default:
      return state;
  }
}
