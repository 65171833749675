// Bring in action types
import { SET_LOADING, REMOVE_LOADING } from "../actions/types";

// Set initialState to an empty array
const initialState = {
  globalLoading: [],
};

// Reducer functionality
export default function globalLoading(state = initialState, action) {
  // Destructure action
  const { type, payload } = action;
  switch (type) {
    case SET_LOADING:
      // Payload is { id, msg } (newest first)
      return { ...state, globalLoading: [payload, ...state.globalLoading] };
    case REMOVE_LOADING:
      // Remove a specific loadingInstance by its ID
      return {
        ...state,
        // Payload is the id of the loadingInstance to be deleted
        globalLoading: state.globalLoading.filter((loadingInstance) => loadingInstance.id !== payload),
      };
    default:
      return state;
  }
}
