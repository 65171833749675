export const template3 = {
  id: "660336377f133cb84f4e045c",
  name: { en: "Email with text and a link to your website", nl: "Email met tekst en een link naar uw website" },
  desc: { en: "Email with text and a link to your website", nl: "Email met tekst en een link naar uw website" },
  categories: ["text"],
  ebContent: [
    {
      pageId: "JxT7qWz55R",
      version: "A",
      components: [
        {
          componentId: "WiD7vf",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "basics-7-component-WiD7vf"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "pRu6Tm",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "basics-7-col-container-WiD7vf"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "RUSso9",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-12"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "iHTOEm",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "DUkNTM",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "l16rMr",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "w0czkh",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["basics-7-col-td-wrapper-WiD7vf"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-name", value: "divider" },
                                    { property: "data-editable", value: "true" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "E6a3Ly",
                                      type: "",
                                      htmlTagName: "div",
                                      classes: ["basics-7-divider-WiD7vf"],
                                      styles: [],
                                      attributes: [{ property: "data-elementgetter1", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "yf6QzD",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "basics-13-component-yf6QzD"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "flDHYG",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "basics-13-col-container-yf6QzD"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "q2p6Ca",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-12"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "hkTnMf",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "Cw26GQ",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "Uw7qDD",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "QcQzlD",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["basics-13-col-td-wrapper-yf6QzD"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "DsjVc7",
                                      type: "",
                                      htmlTagName: "a",
                                      classes: ["basics-13-linkwrapper-yf6QzD"],
                                      styles: [],
                                      attributes: [
                                        { property: "href", value: "#!" },
                                        { property: "data-href", value: "#!" },
                                        { property: "data-target", value: "_blank" },
                                        { property: "data-linkwrapper", value: "true" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "Y5ML3Z",
                                          type: "",
                                          htmlTagName: "img",
                                          classes: ["basics-13-img-yf6QzD"],
                                          styles: [],
                                          attributes: [
                                            { property: "data-editable", value: "true" },
                                            { property: "data-name", value: "img" },
                                            { property: "src", value: "https://cdn.satonda.com/eb/assets/logo-kjp.png" },
                                            { property: "alt", value: "Company Logo" },
                                          ],
                                          content: "",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "sm9ZfG",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "basics-7-component-sm9ZfG"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "S1mw6K",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "basics-7-col-container-sm9ZfG"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "TvRw6A",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-12"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "JGGJXF",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "akfpAu",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "AHllcY",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "JkGfZd",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["basics-7-col-td-wrapper-sm9ZfG"],
                                  styles: [],
                                  attributes: [
                                    { property: "data-name", value: "divider" },
                                    { property: "data-editable", value: "true" },
                                  ],
                                  content: "",
                                  children: [
                                    {
                                      childId: "J1N9oo",
                                      type: "",
                                      htmlTagName: "div",
                                      classes: ["basics-7-divider-sm9ZfG"],
                                      styles: [],
                                      attributes: [{ property: "data-elementgetter1", value: "true" }],
                                      content: "",
                                      children: [],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "JNJxEh",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "basics-1-component-JNJxEh"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "wk18Oe",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "basics-1-col-container-JNJxEh"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "jauHRq",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-12"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "PYvaFU",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "YE0OXy",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "JKCT6k",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "VBq0dx",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["basics-1-col-td-wrapper-JNJxEh"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "MLMdHY",
                                      type: "",
                                      htmlTagName: "h1",
                                      classes: ["basics-1-title-JNJxEh"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "h" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "An eye-catching title that makes the receiver continue reading!",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "T6q6Rr",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "basics-2-component-T6q6Rr"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "Nue9ee",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "basics-2-col-container-T6q6Rr"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "ieTWE5",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-12"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "RCgIbu",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "lhoOc0",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "gE6VEZ",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "OxPBQi",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["basics-2-col-td-wrapper-T6q6Rr"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "Q05y31",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["basics-2-text-T6q6Rr"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content:
                                            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sit amet porttitor eget dolor morbi non arcu.",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "YuY52Y",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "basics-2-component-YuY52Y"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "feJr78",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "basics-2-col-container-YuY52Y"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "p5lTHz",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-12"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "RxTL4C",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "FvmQiu",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "zqFctJ",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "L5voqy",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["basics-2-col-td-wrapper-YuY52Y"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "EoPzwJ",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["basics-2-text-YuY52Y"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content:
                                            "Diam in arcu cursus euismod quis viverra nibh. Ultrices mi tempus imperdiet nulla malesuada pellentesque. Pharetra diam sit amet nisl suscipit adipiscing bibendum est.",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "gMcm6m",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "basics-5-component-gMcm6m"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "mktyQs",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "basics-5-col-container-gMcm6m"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "LXZePT",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-12"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "islZWm",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "Usa4nV",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "TZjXXc",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "SLK8Yz",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["basics-5-col-td-wrapper-gMcm6m"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "Q5yomF",
                                      type: "",
                                      htmlTagName: "table",
                                      classes: ["column-table"],
                                      styles: [],
                                      attributes: [
                                        { property: "role", value: "presentation" },
                                        { property: "width", value: "100%" },
                                        { property: "cellspacing", value: "0" },
                                        { property: "cellpadding", value: "0" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "SNUO92",
                                          type: "block",
                                          htmlTagName: "tbody",
                                          classes: [],
                                          styles: [],
                                          attributes: [{ property: "data-name", value: "block" }],
                                          content: "",
                                          children: [
                                            {
                                              childId: "JfdIdF",
                                              type: "element",
                                              htmlTagName: "tr",
                                              classes: [],
                                              styles: [],
                                              attributes: [],
                                              content: "",
                                              children: [
                                                {
                                                  childId: "Qauh8z",
                                                  type: "",
                                                  htmlTagName: "td",
                                                  classes: ["basics-5-list-tdwrapper-gMcm6m"],
                                                  styles: [],
                                                  attributes: [],
                                                  content: "",
                                                  children: [
                                                    {
                                                      childId: "w41VZJ",
                                                      type: "",
                                                      htmlTagName: "div",
                                                      classes: ["basics-5-listwrapper-gMcm6m"],
                                                      styles: [],
                                                      attributes: [
                                                        { property: "data-name", value: "div" },
                                                        { property: "data-editable", value: "false" },
                                                        { property: "data-texteditable", value: "false" },
                                                        { property: "data-listwrapper", value: "true" },
                                                      ],
                                                      content: "",
                                                      children: [
                                                        {
                                                          childId: "bje5XR",
                                                          type: "",
                                                          htmlTagName: "span",
                                                          classes: ["basics-5-listbullet-gMcm6m"],
                                                          styles: [],
                                                          attributes: [
                                                            { property: "data-name", value: "span" },
                                                            { property: "data-editable", value: "false" },
                                                            { property: "data-listbullet", value: "true" },
                                                          ],
                                                          content: "",
                                                          children: [],
                                                        },
                                                        {
                                                          childId: "iFA0wG",
                                                          type: "",
                                                          htmlTagName: "p",
                                                          classes: ["basics-5-list-text-gMcm6m"],
                                                          styles: [],
                                                          attributes: [
                                                            { property: "data-name", value: "p" },
                                                            { property: "data-editable", value: "true" },
                                                            { property: "data-texteditable", value: "true" },
                                                            { property: "data-listtext", value: "true" },
                                                          ],
                                                          content: "",
                                                          children: [
                                                            {
                                                              childId: "",
                                                              type: "",
                                                              htmlTagName: "textNode",
                                                              classes: [],
                                                              styles: [],
                                                              attributes: [],
                                                              content: "Vestibulum morbi blandit cursus risus at ultrices ",
                                                              children: [],
                                                            },
                                                          ],
                                                        },
                                                      ],
                                                    },
                                                  ],
                                                },
                                              ],
                                            },
                                            {
                                              childId: "xpFpkw",
                                              type: "element",
                                              htmlTagName: "tr",
                                              classes: [],
                                              styles: [],
                                              attributes: [],
                                              content: "",
                                              children: [
                                                {
                                                  childId: "FdQzd0",
                                                  type: "",
                                                  htmlTagName: "td",
                                                  classes: ["basics-5-list-tdwrapper-gMcm6m"],
                                                  styles: [],
                                                  attributes: [],
                                                  content: "",
                                                  children: [
                                                    {
                                                      childId: "bBZhHw",
                                                      type: "",
                                                      htmlTagName: "div",
                                                      classes: ["basics-5-listwrapper-gMcm6m"],
                                                      styles: [],
                                                      attributes: [
                                                        { property: "data-name", value: "div" },
                                                        { property: "data-editable", value: "false" },
                                                        { property: "data-texteditable", value: "false" },
                                                        { property: "data-listwrapper", value: "true" },
                                                      ],
                                                      content: "",
                                                      children: [
                                                        {
                                                          childId: "BQ9gSC",
                                                          type: "",
                                                          htmlTagName: "span",
                                                          classes: ["basics-5-listbullet-gMcm6m"],
                                                          styles: [],
                                                          attributes: [
                                                            { property: "data-name", value: "span" },
                                                            { property: "data-editable", value: "false" },
                                                            { property: "data-listbullet", value: "true" },
                                                          ],
                                                          content: "",
                                                          children: [],
                                                        },
                                                        {
                                                          childId: "GJREuO",
                                                          type: "",
                                                          htmlTagName: "p",
                                                          classes: ["basics-5-list-text-gMcm6m"],
                                                          styles: [],
                                                          attributes: [
                                                            { property: "data-name", value: "p" },
                                                            { property: "data-editable", value: "true" },
                                                            { property: "data-texteditable", value: "true" },
                                                            { property: "data-listtext", value: "true" },
                                                          ],
                                                          content: "",
                                                          children: [
                                                            {
                                                              childId: "",
                                                              type: "",
                                                              htmlTagName: "textNode",
                                                              classes: [],
                                                              styles: [],
                                                              attributes: [],
                                                              content: "Arcu non odio euismod lacinia ",
                                                              children: [],
                                                            },
                                                          ],
                                                        },
                                                      ],
                                                    },
                                                  ],
                                                },
                                              ],
                                            },
                                            {
                                              childId: "oDoW7q",
                                              type: "element",
                                              htmlTagName: "tr",
                                              classes: [],
                                              styles: [],
                                              attributes: [],
                                              content: "",
                                              children: [
                                                {
                                                  childId: "EuCGvA",
                                                  type: "",
                                                  htmlTagName: "td",
                                                  classes: ["basics-5-list-tdwrapper-gMcm6m"],
                                                  styles: [],
                                                  attributes: [],
                                                  content: "",
                                                  children: [
                                                    {
                                                      childId: "qGqBnO",
                                                      type: "",
                                                      htmlTagName: "div",
                                                      classes: ["basics-5-listwrapper-gMcm6m"],
                                                      styles: [],
                                                      attributes: [
                                                        { property: "data-name", value: "div" },
                                                        { property: "data-editable", value: "false" },
                                                        { property: "data-texteditable", value: "false" },
                                                        { property: "data-listwrapper", value: "true" },
                                                      ],
                                                      content: "",
                                                      children: [
                                                        {
                                                          childId: "StaMq6",
                                                          type: "",
                                                          htmlTagName: "span",
                                                          classes: ["basics-5-listbullet-gMcm6m"],
                                                          styles: [],
                                                          attributes: [
                                                            { property: "data-name", value: "span" },
                                                            { property: "data-editable", value: "false" },
                                                            { property: "data-listbullet", value: "true" },
                                                          ],
                                                          content: "",
                                                          children: [],
                                                        },
                                                        {
                                                          childId: "QVMebm",
                                                          type: "",
                                                          htmlTagName: "p",
                                                          classes: ["basics-5-list-text-gMcm6m"],
                                                          styles: [],
                                                          attributes: [
                                                            { property: "data-name", value: "p" },
                                                            { property: "data-editable", value: "true" },
                                                            { property: "data-texteditable", value: "true" },
                                                            { property: "data-listtext", value: "true" },
                                                          ],
                                                          content: "",
                                                          children: [
                                                            {
                                                              childId: "",
                                                              type: "",
                                                              htmlTagName: "textNode",
                                                              classes: [],
                                                              styles: [],
                                                              attributes: [],
                                                              content: "Volutpat est velit egestas dui id ",
                                                              children: [],
                                                            },
                                                          ],
                                                        },
                                                      ],
                                                    },
                                                  ],
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "DH3Apz",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "basics-2-component-DH3Apz"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "tdzV3X",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "basics-2-col-container-DH3Apz"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "AWo4cp",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-12"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "ibS6J4",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "sXVagM",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "mzCCk7",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "VhTtnK",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["basics-2-col-td-wrapper-DH3Apz"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "tI1IAb",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["basics-2-text-DH3Apz"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Lectus magna fringilla urna porttitor rhoncus dolor purus non.",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "FFb1SA",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "basics-3-component-FFb1SA"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "AfNjUL",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "basics-3-col-container-FFb1SA"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "lCRRqR",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-12"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "KXJ1ZH",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "FfZH0y",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "tGvECO",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "Q7yWWu",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["basics-3-col-td-wrapper-FFb1SA"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "LRVZWM",
                                      type: "",
                                      htmlTagName: "a",
                                      classes: ["basics-3-btn-FFb1SA"],
                                      styles: [],
                                      attributes: [
                                        { property: "href", value: "#!" },
                                        { property: "data-href", value: "#!" },
                                        { property: "data-target", value: "_blank" },
                                        { property: "data-editable", value: "true" },
                                        { property: "data-name", value: "button" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Read more on our website!",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "lsjnhw",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "footer-3-component-lsjnhw"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "nfrAYa",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "footer-3-col-container-lsjnhw"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "hnvRAu",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-12"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "Y01608",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "v0MMjO",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "P17Fxf",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "IYFUcf",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["footer-3-col-td-wrapper-lsjnhw"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "jTDsKO",
                                      type: "",
                                      htmlTagName: "table",
                                      classes: ["column-table", "footer-3-table-table-lsjnhw"],
                                      styles: [],
                                      attributes: [
                                        { property: "role", value: "presentation" },
                                        { property: "width", value: "100%" },
                                        { property: "cellspacing", value: "0" },
                                        { property: "cellpadding", value: "0" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "BfQu8O",
                                          type: "",
                                          htmlTagName: "tbody",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "",
                                          children: [
                                            {
                                              childId: "pDEyY4",
                                              type: "",
                                              htmlTagName: "tr",
                                              classes: ["footer-3-table-row-lsjnhw"],
                                              styles: [],
                                              attributes: [],
                                              content: "",
                                              children: [
                                                {
                                                  childId: "zZBmnF",
                                                  type: "",
                                                  htmlTagName: "td",
                                                  classes: ["footer-3-table-cell-all-lsjnhw"],
                                                  styles: [],
                                                  attributes: [{ property: "data-tablecell", value: "true" }],
                                                  content: "",
                                                  children: [
                                                    {
                                                      childId: "MZkhR7",
                                                      type: "block",
                                                      htmlTagName: "div",
                                                      classes: ["footer-3-wrapper-icons-lsjnhw"],
                                                      styles: [],
                                                      attributes: [{ property: "data-name", value: "block" }],
                                                      content: "",
                                                      children: [
                                                        {
                                                          childId: "mfK7rv",
                                                          type: "element",
                                                          htmlTagName: "a",
                                                          classes: ["footer-3-social-link-lsjnhw"],
                                                          styles: [],
                                                          attributes: [
                                                            { property: "href", value: "#!" },
                                                            { property: "data-href", value: "#!" },
                                                            { property: "data-target", value: "_blank" },
                                                            { property: "data-editable", value: "true" },
                                                            { property: "data-name", value: "socialIcon" },
                                                          ],
                                                          content: "",
                                                          children: [
                                                            {
                                                              childId: "fRAawv",
                                                              type: "",
                                                              htmlTagName: "img",
                                                              classes: ["footer-3-social-icon-lsjnhw"],
                                                              styles: [],
                                                              attributes: [
                                                                {
                                                                  property: "src",
                                                                  value: "https://cdn.satonda.com/eb/assets/facebook-white-brand-square.png",
                                                                },
                                                                { property: "alt", value: "Facebook" },
                                                                { property: "data-editable", value: "false" },
                                                              ],
                                                              content: "",
                                                              children: [],
                                                            },
                                                          ],
                                                        },
                                                        {
                                                          childId: "GvOjB4",
                                                          type: "element",
                                                          htmlTagName: "a",
                                                          classes: ["footer-3-social-link-lsjnhw"],
                                                          styles: [],
                                                          attributes: [
                                                            { property: "href", value: "#!" },
                                                            { property: "data-href", value: "#!" },
                                                            { property: "data-target", value: "_blank" },
                                                            { property: "data-editable", value: "true" },
                                                            { property: "data-name", value: "socialIcon" },
                                                          ],
                                                          content: "",
                                                          children: [
                                                            {
                                                              childId: "F72uWo",
                                                              type: "",
                                                              htmlTagName: "img",
                                                              classes: ["footer-3-social-icon-lsjnhw"],
                                                              styles: [],
                                                              attributes: [
                                                                {
                                                                  property: "src",
                                                                  value: "https://cdn.satonda.com/eb/assets/twitter-white-brand-square.png",
                                                                },
                                                                { property: "alt", value: "Twitter" },
                                                                { property: "data-editable", value: "false" },
                                                              ],
                                                              content: "",
                                                              children: [],
                                                            },
                                                          ],
                                                        },
                                                        {
                                                          childId: "skB6pf",
                                                          type: "element",
                                                          htmlTagName: "a",
                                                          classes: ["footer-3-social-link-lsjnhw"],
                                                          styles: [],
                                                          attributes: [
                                                            { property: "href", value: "#!" },
                                                            { property: "data-href", value: "#!" },
                                                            { property: "data-target", value: "_blank" },
                                                            { property: "data-editable", value: "true" },
                                                            { property: "data-name", value: "socialIcon" },
                                                          ],
                                                          content: "",
                                                          children: [
                                                            {
                                                              childId: "LKoCIB",
                                                              type: "",
                                                              htmlTagName: "img",
                                                              classes: ["footer-3-social-icon-lsjnhw"],
                                                              styles: [],
                                                              attributes: [
                                                                {
                                                                  property: "src",
                                                                  value: "https://cdn.satonda.com/eb/assets/youtube-white-brand-square.png",
                                                                },
                                                                { property: "alt", value: "Youtube" },
                                                                { property: "data-editable", value: "false" },
                                                              ],
                                                              content: "",
                                                              children: [],
                                                            },
                                                          ],
                                                        },
                                                      ],
                                                    },
                                                  ],
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          componentId: "eaT0er",
          type: "component",
          htmlTagName: "div",
          classes: ["eb-component", "footer-9-component-eaT0er"],
          styles: [],
          attributes: [{ property: "data-name", value: "component" }],
          content: "",
          children: [
            {
              childId: "rmqZlg",
              type: "",
              htmlTagName: "div",
              classes: ["column-container", "footer-9-col-container-eaT0er"],
              styles: [],
              attributes: [],
              content: "",
              children: [
                {
                  childId: "g90eYH",
                  type: "",
                  htmlTagName: "div",
                  classes: ["column", "col-12"],
                  styles: [],
                  attributes: [],
                  content: "",
                  children: [
                    {
                      childId: "psZe0W",
                      type: "",
                      htmlTagName: "table",
                      classes: ["column-table"],
                      styles: [],
                      attributes: [
                        { property: "role", value: "presentation" },
                        { property: "width", value: "100%" },
                        { property: "cellspacing", value: "0" },
                        { property: "cellpadding", value: "0" },
                      ],
                      content: "",
                      children: [
                        {
                          childId: "c1yug4",
                          type: "",
                          htmlTagName: "tbody",
                          classes: [],
                          styles: [],
                          attributes: [],
                          content: "",
                          children: [
                            {
                              childId: "zdzfEy",
                              type: "",
                              htmlTagName: "tr",
                              classes: [],
                              styles: [],
                              attributes: [],
                              content: "",
                              children: [
                                {
                                  childId: "Tdwgvz",
                                  type: "",
                                  htmlTagName: "td",
                                  classes: ["footer-9-col-td-wrapper-eaT0er"],
                                  styles: [],
                                  attributes: [],
                                  content: "",
                                  children: [
                                    {
                                      childId: "T0Vmx8",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["footer-9-text1-eaT0er"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                        { property: "data-uniqueclassname", value: "true" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "© Company name",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "GoHZoY",
                                      type: "",
                                      htmlTagName: "p",
                                      classes: ["footer-9-text2-eaT0er"],
                                      styles: [],
                                      attributes: [
                                        { property: "data-editable", value: "true" },
                                        { property: "data-texteditable", value: "true" },
                                        { property: "data-name", value: "p" },
                                        { property: "data-uniqueclassname", value: "true" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Streetname 1, 1000 XX Amsterdam, The Netherlands",
                                          children: [],
                                        },
                                      ],
                                    },
                                    {
                                      childId: "xpw8lc",
                                      type: "element",
                                      htmlTagName: "a",
                                      classes: ["footer-9-link-eaT0er"],
                                      styles: [],
                                      attributes: [
                                        { property: "href", value: "#!" },
                                        { property: "data-href", value: "%UNSUBSCRIBE_LINK%" },
                                        { property: "data-target", value: "_blank" },
                                        { property: "data-editable", value: "true" },
                                        { property: "data-name", value: "a" },
                                      ],
                                      content: "",
                                      children: [
                                        {
                                          childId: "",
                                          type: "",
                                          htmlTagName: "textNode",
                                          classes: [],
                                          styles: [],
                                          attributes: [],
                                          content: "Unsubscribe",
                                          children: [],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  ebCustomCss: [
    {
      componentId: "WiD7vf",
      classes: [
        {
          className: "basics-7-component-WiD7vf",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
          ],
        },
        {
          className: "basics-7-col-container-WiD7vf",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "background-color", value: "rgb(255, 255, 255)" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "basics-7-col-td-wrapper-WiD7vf",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0rem 0rem 0rem 0rem" },
            { property: "text-align", value: "start" },
          ],
        },
        {
          className: "basics-7-divider-WiD7vf",
          pseudo: "",
          rules: [
            { property: "display", value: "inline-block" },
            { property: "height", value: "25px" },
            { property: "width", value: "100%" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
            { property: "border-radius", value: "0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(230, 230, 230)" },
          ],
        },
      ],
    },
    {
      componentId: "yf6QzD",
      classes: [
        {
          className: "basics-13-linkwrapper-yf6QzD",
          pseudo: "",
          rules: [
            { property: "display", value: "block" },
            { property: "line-height", value: "0 !important" },
          ],
        },
        {
          className: "basics-13-component-yf6QzD",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
          ],
        },
        {
          className: "basics-13-col-container-yf6QzD",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(234, 237, 240)" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "basics-13-col-td-wrapper-yf6QzD",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0.5rem 0rem 0.5rem 0rem" },
            { property: "text-align", value: "center" },
          ],
        },
        {
          className: "basics-13-img-yf6QzD",
          pseudo: "",
          rules: [
            { property: "outline", value: "none" },
            { property: "-ms-interpolation-mode", value: "bicubic" },
            { property: "max-width", value: "100%" },
            { property: "max-height", value: "200px" },
            { property: "width", value: "150px" },
            { property: "height", value: "auto" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "0px" },
            { property: "border-color", value: "rgb(0, 0, 0)" },
            { property: "border-radius", value: "0rem" },
          ],
        },
      ],
    },
    {
      componentId: "sm9ZfG",
      classes: [
        {
          className: "basics-7-component-sm9ZfG",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
          ],
        },
        {
          className: "basics-7-col-container-sm9ZfG",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "background-color", value: "rgb(255, 255, 255)" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "basics-7-col-td-wrapper-sm9ZfG",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0rem 0rem 0rem 0rem" },
            { property: "text-align", value: "start" },
          ],
        },
        {
          className: "basics-7-divider-sm9ZfG",
          pseudo: "",
          rules: [
            { property: "display", value: "inline-block" },
            { property: "height", value: "20px" },
            { property: "width", value: "100%" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
            { property: "border-radius", value: "0rem" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(230, 230, 230)" },
          ],
        },
      ],
    },
    {
      componentId: "JNJxEh",
      classes: [
        {
          className: "basics-1-component-JNJxEh",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
          ],
        },
        {
          className: "basics-1-col-container-JNJxEh",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(213, 160, 26)" },
            { property: "background-color", value: "rgb(213, 160, 26)" },
            { property: "border-radius", value: "0.75rem 0.75rem 0 0" },
          ],
        },
        {
          className: "basics-1-col-td-wrapper-JNJxEh",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "1.5rem 0.5rem 1.5rem 0.5rem" },
            { property: "text-align", value: "start" },
          ],
        },
        {
          className: "basics-1-title-JNJxEh",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "color", value: "rgb(255, 255, 255)" },
            { property: "font-weight", value: "bold" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
          ],
        },
      ],
    },
    {
      componentId: "T6q6Rr",
      classes: [
        {
          className: "basics-2-component-T6q6Rr",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
          ],
        },
        {
          className: "basics-2-col-container-T6q6Rr",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "background-color", value: "rgb(255, 255, 255)" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "basics-2-col-td-wrapper-T6q6Rr",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0.5rem 0.75rem 0.5rem 0.75rem" },
            { property: "text-align", value: "start" },
          ],
        },
        {
          className: "basics-2-text-T6q6Rr",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(33, 37, 41)" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
          ],
        },
      ],
    },
    {
      componentId: "YuY52Y",
      classes: [
        {
          className: "basics-2-component-YuY52Y",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
          ],
        },
        {
          className: "basics-2-col-container-YuY52Y",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "background-color", value: "rgb(255, 255, 255)" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "basics-2-col-td-wrapper-YuY52Y",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0.5rem 0.75rem 0.5rem 0.75rem" },
            { property: "text-align", value: "start" },
          ],
        },
        {
          className: "basics-2-text-YuY52Y",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(33, 37, 41)" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
          ],
        },
      ],
    },
    {
      componentId: "S0gPe4",
      classes: [
        { className: "basics-5-listwrapper-S0gPe4", pseudo: "", rules: [{ property: "margin", value: "0rem 0 0.25rem 0" }] },
        {
          className: "basics-5-listtext-S0gPe4",
          pseudo: "",
          rules: [
            { property: "display", value: "inline-block" },
            { property: "margin", value: "0 0 0 0" },
            { property: "vertical-align", value: "middle" },
          ],
        },
        {
          className: "basics-5-listbullet-S0gPe4",
          pseudo: "",
          rules: [
            { property: "display", value: "inline-block" },
            { property: "vertical-align", value: "middle" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "height", value: "8px" },
            { property: "width", value: "8px" },
            { property: "border-radius", value: "0rem" },
            { property: "background", value: "rgb(213, 160, 26)" },
            { property: "border-color", value: "rgb(213, 160, 26)" },
            { property: "margin-right", value: "0.5rem" },
          ],
        },
        {
          className: "basics-5-component-S0gPe4",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
          ],
        },
        {
          className: "basics-5-col-container-S0gPe4",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "background-color", value: "rgb(255, 255, 255)" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "basics-5-col-td-wrapper-S0gPe4",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0.5rem 0.75rem 0.5rem 0.75rem" },
            { property: "text-align", value: "start" },
          ],
        },
      ],
    },
    {
      componentId: "DH3Apz",
      classes: [
        {
          className: "basics-2-component-DH3Apz",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
          ],
        },
        {
          className: "basics-2-col-container-DH3Apz",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "background-color", value: "rgb(255, 255, 255)" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "basics-2-col-td-wrapper-DH3Apz",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0.5rem 0.75rem 0.5rem 0.75rem" },
            { property: "text-align", value: "start" },
          ],
        },
        {
          className: "basics-2-text-DH3Apz",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(33, 37, 41)" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
          ],
        },
      ],
    },
    {
      componentId: "FFb1SA",
      classes: [
        {
          className: "basics-3-btn-FFb1SA",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "font-weight", value: "bold" },
            { property: "font-style", value: "normal" },
            { property: "text-decoration", value: "none" },
            { property: "color", value: "rgb(255, 255, 255)" },
            { property: "background", value: "rgb(213, 160, 26)" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(213, 160, 26)" },
            { property: "border-radius", value: "1000px" },
            { property: "padding", value: "0.5rem 0rem 0.5rem 0rem" },
            { property: "display", value: "block" },
            { property: "font-size", value: "16px" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "basics-3-component-FFb1SA",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
          ],
        },
        {
          className: "basics-3-col-container-FFb1SA",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "background-color", value: "rgb(255, 255, 255)" },
            { property: "border-radius", value: "0 0 0.75rem 0.75rem" },
          ],
        },
        {
          className: "basics-3-col-td-wrapper-FFb1SA",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "1.5rem 0.75rem 1.5rem 0.75rem" },
            { property: "text-align", value: "start" },
          ],
        },
      ],
    },
    {
      componentId: "lsjnhw",
      classes: [
        { className: "footer-3-table-table-lsjnhw", pseudo: "", rules: [] },
        { className: "footer-3-table-row-lsjnhw", pseudo: "", rules: [] },
        {
          className: "footer-3-table-cell-all-lsjnhw",
          pseudo: "",
          rules: [
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "padding", value: "0px 10px 0px 10px" },
          ],
        },
        {
          className: "footer-3-wrapper-icons-lsjnhw",
          pseudo: "",
          rules: [
            { property: "text-align", value: "center" },
            { property: "display", value: "block" },
          ],
        },
        {
          className: "footer-3-social-link-lsjnhw",
          pseudo: "",
          rules: [
            { property: "display", value: "inline-block" },
            { property: "text-decoration", value: "none" },
            { property: "margin", value: "0rem 1rem 0rem 0rem" },
          ],
        },
        { className: "footer-3-social-link-lsjnhw", pseudo: "last-child", rules: [{ property: "margin-right", value: "0" }] },
        {
          className: "footer-3-social-icon-lsjnhw",
          pseudo: "",
          rules: [
            { property: "outline", value: "none" },
            { property: "-ms-interpolation-mode", value: "bicubic" },
            { property: "width", value: "32px" },
            { property: "height", value: "32px" },
            { property: "border-radius", value: "50%" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(255, 255, 255)" },
          ],
        },
        {
          className: "footer-3-component-lsjnhw",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
          ],
        },
        {
          className: "footer-3-col-container-lsjnhw",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-top", value: "1.5rem" },
            { property: "padding-bottom", value: "1.5rem" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(234, 237, 240)" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "footer-3-col-td-wrapper-lsjnhw",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "1.5rem 0rem 0.5rem 0rem" },
            { property: "text-align", value: "start" },
          ],
        },
      ],
    },
    {
      componentId: "eaT0er",
      classes: [
        {
          className: "footer-9-text1-eaT0er",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(108, 117, 125)" },
            { property: "margin", value: "0rem 0rem 0.25rem 0rem" },
            { property: "text-align", value: "center" },
            { property: "font-size", value: "12px" },
          ],
        },
        {
          className: "footer-9-text2-eaT0er",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(108, 117, 125)" },
            { property: "margin", value: "0rem 0rem 1rem 0rem" },
            { property: "text-align", value: "center" },
            { property: "font-size", value: "12px" },
          ],
        },
        {
          className: "footer-9-link-eaT0er",
          pseudo: "",
          rules: [
            { property: "color", value: "rgb(108, 117, 125)" },
            { property: "margin", value: "0rem 0rem 0rem 0rem" },
            { property: "display", value: "block" },
            { property: "text-align", value: "center" },
            { property: "text-decoration", value: "underline" },
            { property: "font-size", value: "12px" },
          ],
        },
        {
          className: "footer-9-component-eaT0er",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
          ],
        },
        {
          className: "footer-9-col-container-eaT0er",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(234, 237, 240)" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "footer-9-col-td-wrapper-eaT0er",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "1rem 0rem 3rem 0rem" },
            { property: "text-align", value: "start" },
          ],
        },
      ],
    },
    {
      componentId: "gMcm6m",
      classes: [
        { className: "basics-5-list-tdwrapper-gMcm6m", pseudo: "", rules: [{ property: "margin", value: "0rem 0 0.25rem 0" }] },
        {
          className: "basics-5-listwrapper-gMcm6m",
          pseudo: "",
          rules: [
            { property: "text-align", value: "left" },
            { property: "display", value: "inline-block" },
            { property: "margin", value: "0 0 0.5rem 0" },
            { property: "vertical-align", value: "middle" },
          ],
        },
        {
          className: "basics-5-listbullet-gMcm6m",
          pseudo: "",
          rules: [
            { property: "display", value: "inline-block" },
            { property: "vertical-align", value: "middle" },
            { property: "border-style", value: "solid solid solid solid" },
            { property: "border-width", value: "1px" },
            { property: "height", value: "8px" },
            { property: "width", value: "8px" },
            { property: "border-radius", value: "0rem" },
            { property: "background", value: "rgb(213, 160, 26)" },
            { property: "border-color", value: "rgb(213, 160, 26)" },
            { property: "margin-right", value: "0.5rem" },
          ],
        },
        {
          className: "basics-5-list-text-gMcm6m",
          pseudo: "",
          rules: [
            { property: "display", value: "inline" },
            { property: "margin", value: "0 0 0 0" },
          ],
        },
        {
          className: "basics-5-component-gMcm6m",
          pseudo: "",
          rules: [
            { property: "padding", value: "0 10px 0 10px" },
            { property: "background-color", value: "rgb(234, 237, 240)" },
            { property: "background", value: "rgb(234, 237, 240)" },
          ],
        },
        {
          className: "basics-5-col-container-gMcm6m",
          pseudo: "",
          rules: [
            { property: "min-width", value: "280px" },
            { property: "max-width", value: "600px" },
            { property: "width", value: "100%" },
            { property: "margin", value: "0 auto 0 auto" },
            { property: "border-collapse", value: "collapse" },
            { property: "border-spacing", value: "0" },
            { property: "padding-top", value: "0" },
            { property: "padding-bottom", value: "0" },
            { property: "padding-left", value: "0" },
            { property: "padding-right", value: "0" },
            { property: "border-style", value: "none none none none" },
            { property: "border-width", value: "1px" },
            { property: "border-color", value: "rgb(210, 210, 210)" },
            { property: "background", value: "rgb(255, 255, 255)" },
            { property: "background-color", value: "rgb(255, 255, 255)" },
            { property: "border-radius", value: "0rem 0rem 0rem 0rem" },
          ],
        },
        {
          className: "basics-5-col-td-wrapper-gMcm6m",
          pseudo: "",
          rules: [
            { property: "border-collapse", value: "collapse" },
            { property: "word-break", value: "break-word" },
            { property: "padding", value: "0.5rem 0.75rem 0.5rem 0.75rem" },
            { property: "text-align", value: "start" },
          ],
        },
      ],
    },
  ],
  ebCssVars: {
    fontHeadersName: "Arial",
    fontHeadersWeight: "400",
    fontBodyName: "Arial",
    fontBodyWeight: "400",
    color1: "rgb(213, 160, 26)",
    color2: "rgba(246, 129, 0, 1)",
    color3: "rgba(40, 167, 69, 1)",
    color4: "rgba(13, 202, 240, 1)",
    color5: "rgba(255, 193, 7, 1)",
    color6: "rgba(220, 53, 69, 1)",
    color7: "rgba(233, 236, 239, 1)",
    color8: "rgba(33, 37, 41, 1)",
    color9: "rgba(206, 212, 218, 1)",
    color10: "rgba(108, 117, 125, 1)",
  },
};
