import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import useDebounce from "../../lib/useDebounce";
import { icons } from "../../lib/faIcons/Icons";
import { iconCategories } from "../../lib/faIcons/Categories";
import { EDIT_FORM_ID_PREFIX } from "../../lib/editFunctions";
import { translate } from "../../translations/translations";

const ModalSelectIcon = ({ eb: { ebCssVars } }) => {
  const closeBtn = useRef();

  // Key vars
  const NUMBER_ICONS_LOAD = 30;

  const [state, setState] = useState({
    scrollPos: 0,
    scrollPosMax: 0,
  });
  const { scrollPos, scrollPosMax } = state;
  const [selectedCategory, setSelectedCategory] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredIcons, setFilteredIcons] = useState(icons);
  const [showUntilNumber, setShowUntilNumber] = useState(NUMBER_ICONS_LOAD);
  const [selectedIcon, setSelectedIcon] = useState("");

  useEffect(() => {
    if (selectedCategory === "") {
      setFilteredIcons(icons);
    } else {
      let iconsToShow = iconCategories[selectedCategory].icons;
      setFilteredIcons(icons.filter((icon) => iconsToShow.includes(icon.icon)));
    }
    setSearchTerm("");
    // eslint-disable-next-line
  }, [selectedCategory]);

  const onScroll = (e) => {
    setState({ ...state, scrollPos: e.target.scrollTop, scrollPosMax: e.target.scrollTopMax });
  };

  let debouncedSearchTerm = useDebounce(searchTerm, 200);
  useEffect(() => {
    if (searchTerm === "") {
      setFilteredIcons(icons);
    } else {
      setFilteredIcons(icons.filter((icon) => icon.label.toLowerCase().includes(searchTerm.toLowerCase())));
    }
    setSelectedCategory("");
    // eslint-disable-next-line
  }, [debouncedSearchTerm]);

  let debouncedScrollPos = useDebounce(scrollPos, 200);
  useEffect(() => {
    if (debouncedScrollPos > 0 && scrollPosMax - debouncedScrollPos < 200) {
      loadIcons(false);
    }
    // eslint-disable-next-line
  }, [debouncedScrollPos]);

  const loadIcons = (reset) => {
    setShowUntilNumber((prev) => prev * (reset ? 0 : 1) + NUMBER_ICONS_LOAD);
  };

  const clickConfirmIcon = () => {
    document.getElementById(`${EDIT_FORM_ID_PREFIX}selectedIcon`).className = selectedIcon;
    closeModal();
  };

  const closeModal = () => {
    setSelectedIcon("");
    setSelectedCategory("");
    setSearchTerm("");
  };

  const selectIcon = (iconClassname) => {
    setSelectedIcon(iconClassname);
  };

  return (
    <div className="modal fade" id="ModalSelectIcon" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-hidden="true">
      <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            <h3 className="modal-title">{translate("mModalSelectIcon.selectNewIcon", false, null)}</h3>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ref={closeBtn} onClick={closeModal}></button>
          </div>
          <div className="modal-body" onScroll={onScroll}>
            <div className="row">
              {/* Menu */}
              <div className="col-3">
                <p className="m-0">{translate("mModalSelectIcon.searchIconName", false, null)}</p>
                <input
                  className="form-control form-control-sm"
                  type="text"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  placeholder={translate("mModalSelectIcon.search", false, null)}
                />
                <p className="mt-3 mb-0">{translate("mModalSelectIcon.selectCategory", false, null)}</p>
                <div className="mx-n1">
                  <select className="form-select form-select-sm" value={selectedCategory} onChange={(e) => setSelectedCategory(e.target.value)}>
                    <option value="">{translate("mModalSelectIcon.all", false, null)}</option>
                    {Object.keys(iconCategories).map((cat) => (
                      <option key={cat} value={cat}>
                        {iconCategories[cat].label}
                      </option>
                    ))}
                  </select>
                </div>
                <p className="mt-2 mb-0 fontSize08">
                  <span className="text-bold">{filteredIcons.length}</span> {translate("mModalSelectIcon.xIconsMatchCriteria", false, null)}
                </p>
              </div>
              {/* Icons */}
              <div className="col-9">
                <div className="row mr-0">
                  {filteredIcons.length > 0 ? (
                    filteredIcons.slice(0, showUntilNumber).map((icon, i) => (
                      <div className="col-2 p-2" key={i}>
                        <div
                          className="previewFontDiv p-2 rounded shadow-light trans-3 cursorPointer flexSameHeight text-center"
                          onClick={() => selectIcon(icon.className)}
                        >
                          <p className="mb-2 fontSize15">
                            <span className={icon.className}></span>
                          </p>
                          <p className="m-0 fontSize08 text-gray toGrow">{icon.label}</p>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p className="text-italic">{translate("mModalSelectIcon.noIconsMatchSelection", false, null)}</p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer justify-content-between">
            <p className="m-0 me-auto d-flex align-items-center">
              <span className="text-bold me-2">{translate("mModalSelectIcon.selectedIcon", false, null)}: </span>
              {selectedIcon === "" ? (
                <span className="text-italic">{translate("mModalSelectIcon.noIconSelected", false, null)}</span>
              ) : (
                <span className={`fontSize15 ${selectedIcon}`} />
              )}
            </p>
            <button type="button" className="btn btn-gray px-4" data-bs-dismiss="modal" onClick={closeModal}>
              {translate("mModalSelectIcon.close", false, null)}
            </button>
            <button type="button" className="btn btn-success px-4" data-bs-dismiss="modal" onClick={clickConfirmIcon} disabled={selectedIcon === ""}>
              {translate("mModalSelectIcon.confirmIcon", false, null)}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

ModalSelectIcon.propTypes = {
  eb: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  eb: state.eb,
});

export default connect(mapStateToProps, null)(ModalSelectIcon);
