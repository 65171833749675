import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";

import FormatDate from "../../layout/FormatDate";

import { updateProjectGallery, updateGalleryFileDesc } from "../../../actions/eb";
import { POST_CONFIG } from "../../../lib/reducerFunctions";
import { getGalleryAssetLink } from "../../../lib/generalFunctions";
import { translate } from "../../../translations/translations";
import { API_URL } from "../../../lib/generalVars";

const ImgGalleryListFiles = ({
  auth: { langCode },
  eb: { project },
  updateProjectGallery,
  updateGalleryFileDesc,
  setLocalLoading,
  makeAlert,
  setImageCropping,
}) => {
  const PER_PAGE = 12;
  const [imagesToRender, setImagesToRender] = useState([]);
  const [currPage, setCurrPage] = useState(0);
  let allGalleryImages = project !== null && typeof project.gallery !== "undefined" ? project.gallery : [];
  let maxPages = Math.ceil(allGalleryImages.length / PER_PAGE);

  useEffect(() => {
    renderImages();
    // eslint-disable-next-line
  }, [allGalleryImages, currPage]);

  const renderImages = () => {
    let start = currPage * PER_PAGE;
    let end = start + PER_PAGE;
    setImagesToRender(allGalleryImages.slice(start, end));
  };

  const clickPageBtn = (inc) => {
    setCurrPage((prev) => prev + inc);
  };

  const getFileExt = (file) => {
    return file.fileName.split(".")[1];
  };

  const GalleryFile = ({ file }) => {
    const [toggleEditName, setToggleEditName] = useState(false);
    const [fileName, setFileName] = useState(file.fileName.split(".")[0]);
    const [toggleEditDesc, setToggleEditDesc] = useState(false);
    const [fileDesc, setFileDesc] = useState(file.fileDesc);

    const clickEditFileName = async () => {
      setLocalLoading({ isLoading: true, msg: translate("mImgGalleryListFiles.updatingFileName", false, null) });
      const newName = `${fileName}.${getFileExt(file)}`;
      if (fileNameAlreadyExists(newName)) {
        makeAlert(translate("mImgGalleryListFiles.fileNameAlreadyExists", false, null), "danger");
      } else {
        try {
          const body = JSON.stringify({
            projectId: project._id,
            fileId: file._id,
            oldName: file.fileName,
            newName,
          });
          const res = await axios.put(`${API_URL}/emailbuilder/gallery/changeFilename`, body, POST_CONFIG);
          updateProjectGallery(res.data);
          makeAlert(translate("mImgGalleryListFiles.fileNameUpdated", false, null), "success");
        } catch (error) {
          console.error(error);
          makeAlert(translate("mImgGalleryListFiles.serverErrorUpdatingName", false, null), "danger");
        }
      }
      setLocalLoading({ isLoading: false, msg: "" });
      setToggleEditName(false);
    };

    const clickEditFileDesc = async () => {
      updateGalleryFileDesc(file._id, fileDesc);
      makeAlert(translate("mImgGalleryListFiles.fileDescUpdated", false, null), "success");
      setToggleEditDesc(false);
    };

    const clickDeleteFile = async () => {
      setLocalLoading({ isLoading: true, msg: translate("mImgGalleryListFiles.deletingFile", false, null) });
      try {
        const body = JSON.stringify({ projectId: project._id, fileId: file._id, fileName: file.fileName });
        const res = await axios.put(`${API_URL}/emailbuilder/gallery/deleteFile`, body, POST_CONFIG);
        updateProjectGallery(res.data);
        makeAlert(translate("mImgGalleryListFiles.fileDeleted", false, null), "success");
      } catch (error) {
        console.error(error);
        makeAlert(translate("mImgGalleryListFiles.serverErrorDeleting", false, null), "danger");
      }
      setLocalLoading({ isLoading: false, msg: "" });
    };

    const fileNameAlreadyExists = (nameToCheck) => {
      try {
        return project.gallery.filter((file) => file.fileName === nameToCheck).length > 0;
      } catch (error) {
        console.error(error);
        return false;
      }
    };

    return (
      <div className={`col-12 col-md-6 col-lg-3 p-3 card-browse-gallery`}>
        <div className="flexSameHeight">
          <img src={getGalleryAssetLink(project._id, file.fileName)} alt={file.fileName} />
          <div className="toGrow d-flex flex-column justify-content-end px-2 py-2">
            {toggleEditName ? (
              <div className="input-group input-group-sm m-0">
                <input type="text" className={`form-control`} value={fileName} onChange={(e) => setFileName(e.target.value.replace(/\s/g, "-"))} />
                <span className="input-group-text">.{getFileExt(file)}</span>
                <button
                  className="btn btn-outline-success"
                  disabled={fileName === ""}
                  onClick={clickEditFileName}
                  title={translate("mImgGalleryListFiles.updateFileName", false, null)}
                >
                  {translate("mImgGalleryListFiles.edit", false, null)}
                </button>
                <button
                  className="btn btn-outline-danger"
                  onClick={() => setToggleEditName(false)}
                  title={translate("mImgGalleryListFiles.cancel", false, null)}
                >
                  <i className="fa-solid fa-times" />
                </button>
              </div>
            ) : (
              <div className="d-flex justify-content-between text-dark">
                <p className="fontSize09 m-0">
                  <i className="fa-regular fa-file-image me-1" />
                  {file.fileName}
                  <i
                    className="fa-solid fa-pencil ms-2 text-success cursorPointer"
                    title={translate("mImgGalleryListFiles.editFileName", false, null)}
                    onClick={() => setToggleEditName(true)}
                  />
                </p>
                <p className="fontSize09 m-0">
                  <i className="fa-regular fa-clock me-1" />
                  <FormatDate dateString={file.updatedAt} lang={langCode} formatStr={"d/m/yyyy"} />
                </p>
              </div>
            )}
          </div>
          <div className="d-flex align-items-center text-dark fontSize09 w-100 px-2 pb-2">
            {toggleEditDesc ? (
              <div className="input-group input-group-sm m-0">
                <input
                  type="text"
                  className={`form-control`}
                  placeholder={translate("mImgGalleryListFiles.fileDesc", false, null)}
                  value={fileDesc}
                  onChange={(e) => setFileDesc(e.target.value)}
                />
                <button
                  className="btn btn-outline-success"
                  onClick={clickEditFileDesc}
                  title={translate("mImgGalleryListFiles.updateFileDesc", false, null)}
                >
                  {translate("mImgGalleryListFiles.edit", false, null)}
                </button>
                <button
                  className="btn btn-outline-danger"
                  onClick={() => setToggleEditDesc(false)}
                  title={translate("mImgGalleryListFiles.cancel", false, null)}
                >
                  <i className="fa-solid fa-times" />
                </button>
              </div>
            ) : (
              <>
                <i className="fa-regular fa-comments me-1" />
                {file.fileDesc === "" ? (
                  <span className="text-italic">{translate("mImgGalleryListFiles.noDesc", false, null)}</span>
                ) : (
                  <span className="d-inline-block text-truncate" style={{ maxWidth: "80%" }} title={file.fileDesc}>
                    {file.fileDesc}
                  </span>
                )}
                <i
                  className="fa-solid fa-pencil ms-2 text-success cursorPointer"
                  title={translate("mImgGalleryListFiles.editFileDesc", false, null)}
                  onClick={() => setToggleEditDesc(true)}
                />
              </>
            )}
          </div>
        </div>
        <div className="card-browse-gallery-actions">
          <a
            className="text-gray cursorPointer me-2"
            title={translate("mImgGalleryListFiles.openInNewTab", false, null)}
            href={getGalleryAssetLink(project._id, file.fileName)}
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fa-solid fa-arrow-up-right-from-square"></i>
          </a>
          <span
            className="text-gray cursorPointer me-2"
            title={translate("mImgGalleryListFiles.cropImg", false, null)}
            onClick={() => setImageCropping(file)}
          >
            <i className="fa-solid fa-crop"></i>
          </span>
          <span className="text-danger cursorPointer" title={translate("mImgGalleryListFiles.deleteFile", false, null)} onClick={clickDeleteFile}>
            <i className="fa-regular fa-trash-can"></i>
          </span>
        </div>
      </div>
    );
  };

  return (
    <>
      <h4 className="text-primary">{translate("mImgGalleryListFiles.yourImgs", false, null)}</h4>
      {/* Browse images */}
      <div className="row">
        {allGalleryImages.length === 0 ? (
          <p className="text-italic">{translate("mImgGalleryListFiles.noImgsYet", false, null)}</p>
        ) : (
          imagesToRender.map((file) => <GalleryFile key={file._id} file={file} />)
        )}
      </div>
      {/* Pagination */}
      <div className="d-flex justify-content-between align-items-center mt-3">
        <button
          className="btn btn-outline-primary trans-3"
          onClick={() => clickPageBtn(-1)}
          title={
            currPage === 0
              ? translate("mImgGalleryListFiles.theseAreFirstImgs", false, null)
              : translate("mImgGalleryListFiles.seeMoreImgs", false, null)
          }
          disabled={currPage === 0}
        >
          <i className="fa-solid fa-chevron-left"></i>
        </button>
        <span className="fontSize08 text-dark">
          {translate("mImgGalleryListFiles.showingImgs", false, null)}{" "}
          <span className="text-bold">
            {currPage * PER_PAGE + 1} - {Math.min((currPage + 1) * PER_PAGE, allGalleryImages.length)}
          </span>{" "}
          {translate("mImgGalleryListFiles.of", false, null)} <span className="text-bold">{allGalleryImages.length}</span>
        </span>
        <button
          className="btn btn-outline-primary trans-3"
          onClick={() => clickPageBtn(1)}
          title={
            currPage + 1 === maxPages
              ? translate("mImgGalleryListFiles.theseAreLatestImgs", false, null)
              : translate("mImgGalleryListFiles.seeMoreImgs", false, null)
          }
          disabled={currPage + 1 === maxPages}
        >
          <i className="fa-solid fa-chevron-right"></i>
        </button>
      </div>
    </>
  );
};

ImgGalleryListFiles.propTypes = {
  auth: PropTypes.object.isRequired,
  eb: PropTypes.object.isRequired,
  updateProjectGallery: PropTypes.func.isRequired,
  updateGalleryFileDesc: PropTypes.func.isRequired,
  setLocalLoading: PropTypes.func.isRequired,
  makeAlert: PropTypes.func.isRequired,
  setImageCropping: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  eb: state.eb,
});

export default connect(mapStateToProps, { updateProjectGallery, updateGalleryFileDesc })(ImgGalleryListFiles);
