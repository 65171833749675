export const lGeneralVars = {
  thin: {
    en: "Thin",
    nl: "Slank",
  },
  light: {
    en: "Light",
    nl: "Dun",
  },
  normal: {
    en: "Normal",
    nl: "Normaal",
  },
  bold: {
    en: "Bold",
    nl: "Dik",
  },
  white: {
    en: "White",
    nl: "Wit",
  },
  black: {
    en: "Black",
    nl: "Zwart",
  },
  brand: {
    en: "Brand",
    nl: "Merk",
  },
};
