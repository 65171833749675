export const mImgGalleryListFiles = {
  updatingFileName: {
    en: "Updating file name..",
    nl: "Bestandsnaam bijwerken..",
  },
  fileNameAlreadyExists: {
    en: "Another file with this name already exists. Please choose a different one.",
    nl: "Een ander bestand met dezelfde naam bestaat al. Kies aub een andere naam.",
  },
  fileNameUpdated: {
    en: "File name updated!",
    nl: "Bestandsnaam bijgewerkt!",
  },
  serverErrorUpdatingName: {
    en: "Server error while updating the file name. Please try again.",
    nl: "Server fout tijdens het bijwerken van de bestandsnaam. Probeer het aub opnieuw.",
  },
  fileDescUpdated: {
    en: "File description updated!",
    nl: "Bestandsomschrijving bijgewerkt!",
  },
  deletingFile: {
    en: "Deleting file..",
    nl: "Bestand verwijderen..",
  },
  fileDeleted: {
    en: "File deleted!",
    nl: "Bestand verwijderd!",
  },
  serverErrorDeleting: {
    en: "Server error while deleting the file. Please try again.",
    nl: "Server fout tijdens het verwijderen van het bestand. Probeer het aub opnieuw.",
  },
  updateFileName: {
    en: "Update file name",
    nl: "Verander bestandsnaam",
  },
  edit: {
    en: "Edit",
    nl: "Verander",
  },
  cancel: {
    en: "Cancel",
    nl: "Annuleren",
  },
  editFileName: {
    en: "Edit file name",
    nl: "Verander bestandsnaam",
  },
  fileDesc: {
    en: "File description",
    nl: "Bestandsomschrijving",
  },
  updateFileDesc: {
    en: "Update file description",
    nl: "Verander bestandsomschrijving",
  },
  noDesc: {
    en: "No description",
    nl: "Geen beschrijving",
  },
  editFileDesc: {
    en: "Edit file description",
    nl: "Verander bestandsomschrijving",
  },
  openInNewTab: {
    en: "Open in new tab",
    nl: "Open in nieuwe tab",
  },
  cropImg: {
    en: "Crop image",
    nl: "Afbeelding bijsnijden",
  },
  deleteFile: {
    en: "Delete file",
    nl: "Verwijder bestand",
  },
  yourImgs: {
    en: "Your images",
    nl: "Uw afbeeldingen",
  },
  noImgsYet: {
    en: "You don't have any images in your gallery yet.",
    nl: "U heeft nog geen afbeelding in uw gallerij.",
  },
  theseAreFirstImgs: {
    en: "These are your most recent images",
    nl: "Dit zijn uw meest recente afbeeldingen",
  },
  seeMoreImgs: {
    en: "See more images",
    nl: "Bekijk meer afbeeldingen",
  },
  showingImgs: {
    en: "Showing images",
    nl: "Afbeeldingen",
  },
  of: {
    en: "of",
    nl: "van",
  },
  theseAreLatestImgs: {
    en: "These are your last images",
    nl: "Dit zijn uw oudste afbeeldingen",
  },
};
