import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Slider from "./formComponents/Slider";

import { formGetStartValue } from "../../../../lib/editFunctions";
import { translate } from "../../../../translations/translations";

const ImgAdjust = ({ eb: { startStyles } }) => {
  return (
    <>
      <Slider
        label={translate("cEditForms.opacity", false, null)}
        id={"imgOpacity"}
        min={0}
        max={1}
        step={0.01}
        startValue={formGetStartValue(startStyles, "imgOpacity")}
        tooltipText={translate("cEditForms.ttOpacity", false, null)}
      />
      <Slider
        label={translate("cEditForms.blur", false, null)}
        id={"imgBlur"}
        min={0}
        max={100}
        step={1}
        startValue={formGetStartValue(startStyles, "imgBlur")}
        tooltipText={translate("cEditForms.ttBlur", false, null)}
      />
      <Slider
        label={translate("cEditForms.brightNess", false, null)}
        id={"imgBrightness"}
        min={0}
        max={500}
        step={1}
        startValue={formGetStartValue(startStyles, "imgBrightness")}
        tooltipText={translate("cEditForms.ttBrightness", false, null)}
      />
      <Slider
        label={translate("cEditForms.contrast", false, null)}
        id={"imgContrast"}
        min={0}
        max={500}
        step={1}
        startValue={formGetStartValue(startStyles, "imgContrast")}
        tooltipText={translate("cEditForms.ttContrast", false, null)}
      />
      <Slider
        label={translate("cEditForms.grayscale", false, null)}
        id={"imgGrayscale"}
        min={0}
        max={100}
        step={1}
        startValue={formGetStartValue(startStyles, "imgGrayscale")}
        tooltipText={translate("cEditForms.ttGrayscale", false, null)}
      />
      <Slider
        label={translate("cEditForms.hue", false, null)}
        id={"imgHueRotate"}
        min={0}
        max={360}
        step={1}
        startValue={formGetStartValue(startStyles, "imgHueRotate")}
        tooltipText={translate("cEditForms.ttHue", false, null)}
      />
      <Slider
        label={translate("cEditForms.invert", false, null)}
        id={"imgInvert"}
        min={0}
        max={100}
        step={1}
        startValue={formGetStartValue(startStyles, "imgInvert")}
        tooltipText={translate("cEditForms.ttInvert", false, null)}
      />
      <Slider
        label={translate("cEditForms.saturate", false, null)}
        id={"imgSaturate"}
        min={0}
        max={500}
        step={1}
        startValue={formGetStartValue(startStyles, "imgSaturate")}
        tooltipText={translate("cEditForms.ttSaturate", false, null)}
      />
      <Slider
        label={translate("cEditForms.sepia", false, null)}
        id={"imgSepia"}
        min={0}
        max={100}
        step={1}
        startValue={formGetStartValue(startStyles, "imgSepia")}
        tooltipText={translate("cEditForms.ttSepia", false, null)}
      />
    </>
  );
};

ImgAdjust.propTypes = {
  eb: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  eb: state.eb,
});

export default connect(mapStateToProps, null)(ImgAdjust);
