import React from "react";

import { setTextEditorBackground } from "../../../../../lib/textEditorFunctions";

const EditComponentAccordionItem = ({ children, title }) => {
  const clickAccordionBtn = (title) => {
    if (title === "Text editor") {
      // Opening text editor => set background color of editor
      setTextEditorBackground();
    }
  };

  return (
    <div className="accordion-item">
      <h2 className="accordion-header" id={`editComponent-${title.toLowerCase().replace(/\s+/g, "")}`}>
        <button
          className="accordion-button editComponent-accordion collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={`#collapse-editComponent-${title.toLowerCase().replace(/\s+/g, "")}`}
          aria-expanded="false"
          aria-controls={`collapse-editComponent-${title.toLowerCase().replace(/\s+/g, "")}`}
          onClick={() => clickAccordionBtn(title)}
        >
          {title}
        </button>
      </h2>
      <div
        id={`collapse-editComponent-${title.toLowerCase().replace(/\s+/g, "")}`}
        className="accordion-collapse collapse"
        aria-labelledby={`editComponent-${title.toLowerCase().replace(/\s+/g, "")}`}
        data-bs-parent="#accordion-EditComponent"
      >
        <div className="accordion-body py-0">{children}</div>
      </div>
    </div>
  );
};

export default EditComponentAccordionItem;
