import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Frame from "react-frame-component";

import { templates } from "../lib/templates";
import { FixedCss } from "../lib/css/FixedCss";
import { compileCssVarsObj } from "../lib/css/CssVars";
import { parseComponentHtml, getSectionClassname, parseCss } from "../lib/parse";
import { TEMPLATE_CATEGORIES } from "../lib/templateVars";

const PreviewTemplate = () => {
  let { langCode, templateId } = useParams();
  const navigate = useNavigate();
  const iframeRef = useRef();

  const SUPPORTED_LANGUAGES = ["en", "nl"];
  const LANGUAGES = { en: "English", nl: "Nederlands" };
  const DICTIONARY = {
    previewTemplate: {
      en: "Preview template",
      nl: "Bekijk template",
    },
    selectLanguage: {
      en: "Select language",
      nl: "Selecteer taal",
    },
    templateNotFound: {
      en: "Template was not found.",
      nl: "Template was niet gevonden.",
    },
    desktopScreen: {
      en: "See how the template looks like on a desktop screen",
      nl: "Bekijk de template op een computerscherm",
    },
    tabletScreen: {
      en: "See how the template looks like on a tablet screen",
      nl: "Bekijk de template op een tabletscherm",
    },
    mobileScreen: {
      en: "See how the template looks like on a mobile screen",
      nl: "Bekijk de template op een telefoonscherm",
    },
    yourScreenIsTooSmall: {
      en: "Your screen is too small to show this size",
      nl: "Uw scherm is te klein om deze grootte te laten zien",
    },
  };

  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [screen, setScreen] = useState("mobile");

  useEffect(() => {
    // Redirect to english page if unsupported language is selected
    if (!SUPPORTED_LANGUAGES.includes(langCode)) {
      navigateTo("en", templateId);
    }
    // eslint-disable-next-line
  }, [langCode]);

  useEffect(() => {
    setTemplate();
    // eslint-disable-next-line
  }, [templateId]);

  const setTemplate = () => {
    try {
      const template = templates.filter((t) => t.id === templateId)[0];
      if (template === null || typeof template === "undefined") {
        setSelectedTemplate(null);
      } else {
        setSelectedTemplate(template);
      }
    } catch (error) {
      setSelectedTemplate(null);
    }
  };

  const navigateTo = (lang, templId) => {
    navigate(`/templates/preview/${lang}/${templId}`, { replace: true });
  };

  const setLangCode = (lang) => {
    navigateTo(lang, templateId);
  };

  const translate = (dict) => {
    try {
      return dict[langCode];
    } catch (error) {
      return "";
    }
  };

  const SelectLanguage = () => {
    return (
      <div className="dropdown">
        <div
          id="dropdown_selectLang"
          className="dropdown-toggle cursorPointer"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          title={translate(DICTIONARY.selectLanguage)}
        >
          <img src={`/assets/img/flags/${langCode}.png`} alt={LANGUAGES[langCode]} />
        </div>
        <ul className="dropdown-menu p-0" aria-labelledby="dropdown_selectLang">
          <p className="p-2 m-0">{translate(DICTIONARY.selectLanguage)}:</p>
          {SUPPORTED_LANGUAGES.map((lang) => (
            <li key={`lang_${lang}`}>
              <div className="dropdown-item p-2 cursorPointer" onClick={() => setLangCode(lang)}>
                <img src={`/assets/img/flags/${lang}.png`} alt={LANGUAGES[lang]} />
                <span className="ms-2">{LANGUAGES[lang]}</span>
              </div>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const TemplateDescription = () => {
    return (
      <>
        <h4 className="text-secondary mt-5 text-bold">{selectedTemplate.name[langCode]}</h4>
        <p className="mt-3 mb-0">{selectedTemplate.desc[langCode]}</p>
        <div className="d-flex mt-3 fontSize07 text-bold">
          {selectedTemplate.categories.map((cat, i) => {
            const category = TEMPLATE_CATEGORIES.filter((tempCat) => tempCat.val === cat)[0];
            if (typeof category === "undefined") {
              return null;
            } else {
              return (
                <span key={`template_cat_${i}`} className={`bg-light py-1 px-3 ${i > 0 ? "mx-2 " : ""}rounded-pill`}>
                  {category.label[langCode]}
                </span>
              );
            }
          })}
        </div>
      </>
    );
  };

  const getSelectedPageComponents = () => {
    try {
      return selectedTemplate.ebContent.filter((v) => v.version === "A")[0].components;
    } catch (error) {
      return [];
    }
  };

  const onIframeClick = (e) => {
    e.preventDefault();
  };

  return (
    <div className="mt-5">
      <div className="container">
        <div className="d-flex justify-content-between align-items-center mt-5">
          <h1 className="h3 text-bold text-primary m-0">{translate(DICTIONARY.previewTemplate)}</h1>
          <SelectLanguage />
        </div>

        {selectedTemplate === null ? (
          <p className="mt-5 text-italic">{translate(DICTIONARY.templateNotFound)}</p>
        ) : (
          <>
            <TemplateDescription />

            {/* Select screen size */}
            {/* Mobile */}
            <div className="d-flex d-md-none mt-5 justify-content-center align-items-center">
              <i
                className={`fa-solid fa-display me-3 changeScreen trans-3 text-midgray`}
                data-toggle="tooltip"
                data-placement="bottom"
                title={translate(DICTIONARY.yourScreenIsTooSmall)}
              />
              <i
                className={`fa-solid fa-tablet-screen-button me-3 changeScreen trans-3 text-midgray`}
                data-toggle="tooltip"
                data-placement="bottom"
                title={translate(DICTIONARY.yourScreenIsTooSmall)}
              />
              <i
                className={`fa-solid fa-mobile-screen-button changeScreen trans-3 cursorPointer ${
                  screen === "mobile" ? "text-primary" : "text-gray"
                } textHover-primary`}
                data-toggle="tooltip"
                data-placement="bottom"
                title={translate(DICTIONARY.mobileScreen)}
                onClick={() => setScreen("mobile")}
              />
            </div>
            {/* Tablet */}
            <div className="d-none d-md-flex d-lg-none mt-5 justify-content-center align-items-center">
              <i
                className={`fa-solid fa-display me-3 changeScreen trans-3 text-midgray`}
                data-toggle="tooltip"
                data-placement="bottom"
                title={translate(DICTIONARY.yourScreenIsTooSmall)}
              />
              <i
                className={`fa-solid fa-tablet-screen-button me-3 changeScreen trans-3 cursorPointer ${
                  screen === "tablet" ? "text-primary" : "text-gray"
                } textHover-primary`}
                data-toggle="tooltip"
                data-placement="bottom"
                title={translate(DICTIONARY.tabletScreen)}
                onClick={() => setScreen("tablet")}
              />
              <i
                className={`fa-solid fa-mobile-screen-button changeScreen trans-3 cursorPointer ${
                  screen === "mobile" ? "text-primary" : "text-gray"
                } textHover-primary`}
                data-toggle="tooltip"
                data-placement="bottom"
                title={translate(DICTIONARY.mobileScreen)}
                onClick={() => setScreen("mobile")}
              />
            </div>
            {/* Desktop */}
            <div className="d-none d-lg-flex mt-5 justify-content-center align-items-center">
              <i
                className={`fa-solid fa-display me-3 changeScreen trans-3 cursorPointer ${
                  screen === "desktop" ? "text-primary" : "text-gray"
                } textHover-primary`}
                data-toggle="tooltip"
                data-placement="bottom"
                title={translate(DICTIONARY.desktopScreen)}
                onClick={() => setScreen("desktop")}
              />
              <i
                className={`fa-solid fa-tablet-screen-button me-3 changeScreen trans-3 cursorPointer ${
                  screen === "tablet" ? "text-primary" : "text-gray"
                } textHover-primary`}
                data-toggle="tooltip"
                data-placement="bottom"
                title={translate(DICTIONARY.tabletScreen)}
                onClick={() => setScreen("tablet")}
              />
              <i
                className={`fa-solid fa-mobile-screen-button changeScreen trans-3 cursorPointer ${
                  screen === "mobile" ? "text-primary" : "text-gray"
                } textHover-primary`}
                data-toggle="tooltip"
                data-placement="bottom"
                title={translate(DICTIONARY.mobileScreen)}
                onClick={() => setScreen("mobile")}
              />
            </div>

            {/* Template viewer */}
            <div className="mt-4 mb-6 d-flex flex-column align-items-center" style={{ height: "80vh" }}>
              <div className={`editResult trans-3 ${screen} toGrow rounded-4 overflow-hidden`}>
                <Frame
                  id="iframe"
                  head={<style>{compileCssVarsObj(selectedTemplate.ebCssVars) + FixedCss + parseCss(selectedTemplate.ebCustomCss)}</style>}
                  onClick={(e) => onIframeClick(e)}
                  ref={iframeRef}
                >
                  {getSelectedPageComponents().length > 0 ? (
                    getSelectedPageComponents().map((component, i) => (
                      <section
                        key={component.componentId}
                        data-componentid={component.componentId}
                        dangerouslySetInnerHTML={{
                          __html: parseComponentHtml(component, false),
                        }}
                        className={getSectionClassname(component)}
                        draggable="false"
                      />
                    ))
                  ) : (
                    <div
                      key="0"
                      data-componentid="0"
                      draggable="false"
                      className="d-flex h-100vh bg-light"
                      style={{ height: "100vh", backgroundColor: "rgb(233, 236, 239)", display: "flex" }}
                    >
                      <p style={{ margin: "auto" }}>Content not found</p>
                    </div>
                  )}
                </Frame>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PreviewTemplate;
