export const convertRgbaStringToHex = (rgbaString) => {
  let rgb = ensureRgba_values(rgbaString);
  return convertRgbToHex(rgb[1], rgb[2], rgb[3]);
};

export const convertRgbToHex = (r, g, b) => {
  return "#" + ((1 << 24) + (parseInt(r) << 16) + (parseInt(g) << 8) + parseInt(b)).toString(16).slice(1);
};

export const convertHexToRgb = (hexString) => {
  if (hexString.length === 4) {
    hexString = [hexString[0], hexString[1], hexString[1], hexString[2], hexString[2], hexString[3], hexString[3]].join("");
  }
  if (hexString.length === 9) {
    hexString = hexString.slice(0, 7);
  }
  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexString);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

export const rgbStringToHsl = (rgbString) => {
  let rgb = ensureRgba_values(rgbString);
  return RGBtoHSL(rgb[1], rgb[2], rgb[3], 1);
};

export const RGBtoHSL = (red, green, blue, alpha = 1) => {
  let min,
    max,
    i,
    l,
    s,
    maxcolor,
    h,
    rgb = [];
  rgb[0] = red / 255;
  rgb[1] = green / 255;
  rgb[2] = blue / 255;
  min = rgb[0];
  max = rgb[0];
  maxcolor = 0;
  for (i = 0; i < rgb.length - 1; i++) {
    if (rgb[i + 1] <= min) {
      min = rgb[i + 1];
    }
    if (rgb[i + 1] >= max) {
      max = rgb[i + 1];
      maxcolor = i + 1;
    }
  }
  if (maxcolor === 0) {
    h = (rgb[1] - rgb[2]) / (max - min);
  }
  if (maxcolor === 1) {
    h = 2 + (rgb[2] - rgb[0]) / (max - min);
  }
  if (maxcolor === 2) {
    h = 4 + (rgb[0] - rgb[1]) / (max - min);
  }
  if (isNaN(h)) {
    h = 0;
  }
  h = h * 60;
  if (h < 0) {
    h = h + 360;
  }
  l = (min + max) / 2;
  if (min === max) {
    s = 0;
  } else {
    if (l < 0.5) {
      s = (max - min) / (max + min);
    } else {
      s = (max - min) / (2 - max - min);
    }
  }
  return [Math.round(h), Math.round(s * 100), Math.round(l * 100), parseFloat(alpha)];
};

export const hslToRgbString = (h, s, l, a = 1) => {
  let rgba = hslToRGB(h, s, l, a);
  return `rgb(${rgba[0]}, ${rgba[1]}, ${rgba[2]})`;
};

export const hslToRGB = (hue, sat, light, a = 1) => {
  // divide sat/light by 100
  sat /= 100;
  light /= 100;
  // from w3color:
  let t1, t2, r, g, b;
  hue = hue / 60;
  if (light <= 0.5) {
    t2 = light * (sat + 1);
  } else {
    t2 = light + sat - light * sat;
  }
  t1 = light * 2 - t2;
  r = hueToRGB(t1, t2, hue + 2) * 255;
  g = hueToRGB(t1, t2, hue) * 255;
  b = hueToRGB(t1, t2, hue - 2) * 255;
  return [Math.round(r), Math.round(g), Math.round(b), parseFloat(a)];
};

export const hueToRGB = (t1, t2, hue) => {
  if (hue < 0) hue += 6;
  if (hue >= 6) hue -= 6;
  if (hue < 1) return (t2 - t1) * hue + t1;
  else if (hue < 3) return t2;
  else if (hue < 4) return (t2 - t1) * (4 - hue) + t1;
  else return t1;
};

export const colorDarken = (rgbaString) => {
  return colorAdjustLum(ensureRgba(rgbaString), -8);
};

export const colorLighten = (rgbaString) => {
  return colorAdjustLum(ensureRgba(rgbaString), 8);
};

export const colorAdjustLum = (rgbaString, lumAdjustment) => {
  let rgb = [];
  if (rgbaString.includes("rgba")) {
    rgb = rgbaString.match(/rgba\((\d+), (\d+), (\d+), (\d|\d\.\d+)/);
  } else {
    rgb = rgbaString.match(/rgb\((\d+), (\d+), (\d+)\)/);
    rgb.push(1);
  }
  let hsl = RGBtoHSL(rgb[1], rgb[2], rgb[3]);
  hsl[2] = hsl[2] + lumAdjustment;
  let newRgb = hslToRGB(hsl[0], hsl[1], hsl[2]);
  return `rgb(${newRgb[0]}, ${newRgb[1]}, ${newRgb[2]})`;
};

export const ensureRgba = (colorString) => {
  // NOTE: Rewritten to ensure rgb() rather than rgba()
  let rgb = ensureRgba_values(colorString);
  return `rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]})`;
};

export const ensureRgba_values = (colorString) => {
  // NOTE: Rewritten to ensure rgb() rather than rgba()

  let rgb = [];
  // Case: rgb(a)
  if (colorString.includes("rgb")) {
    rgb = colorString.replace(/\s+/g, "").match(/\((-?\d+),?(-?\d+),?(-?\d+),?(-?\d|-?\d\.\d+)?\)/);
    rgb.splice(0, 1);
  }
  // Case: hex
  if (colorString.includes("#")) {
    let rgbObj = convertHexToRgb(colorString);
    if (rgbObj !== null) {
      rgb = [rgbObj.r, rgbObj.g, rgbObj.b];
    } else {
      rgb = [0, 0, 0, 1];
    }
  }
  // Case: hsl
  if (colorString.includes("hsl")) {
    let hsl = colorString.replace(/\s+/g, "").match(/\((-?\d+),?(-?\d+),?(-?\d+),?(-?\d|-?\d\.\d+)?\)/);
    rgb = hslToRGB(hsl[1], hsl[2], hsl[3]);
    typeof hsl[4] !== "undefined" && rgb.push(hsl[4]);
  }
  // Check rgba values
  rgb.forEach((digit, i) => {
    let upperbound = 255;
    i === 3 && (upperbound = 1);
    if (digit < 0 || digit > upperbound) {
      //   rgb[i] = presetColorPrimary[i + 1];
      rgb[i] = upperbound;
    }
  });
  return rgb;
};

export const updateAlphaInRgbaString = (colorString, alphaValue) => {
  let rgbaString = ensureRgba(colorString);
  if (parseFloat(alphaValue) >= 0 && parseFloat(alphaValue) <= 1) {
    rgbaString = rgbaString.replace(/, (-?\d|-?\d\.\d+)\)/, `, ${parseFloat(alphaValue)})`);
  }
  return rgbaString;
};

export const getRgbValuesFromString = (colorString) => {
  colorString = ensureRgba(colorString);
  let rgb = colorString.replace(/\s+/g, "").match(/\((-?\d+),?(-?\d+),?(-?\d+),?(-?\d|-?\d\.\d+)?\)/);
  rgb.splice(0, 1);
  return rgb;
};

export const colorContrast_getLightOrDark = (rgbaString, threshold = 130) => {
  let rgb = getRgbValuesFromString(rgbaString);
  let brightness = (rgb[0] * 299 + rgb[1] * 587 + rgb[2] * 114) / 1000;
  return brightness > threshold ? "dark" : "light";
};

export const isColorString = (str) => {
  str = str.replace(/\s+/g, "");
  if (str.match(/^rgba?\(.+,.+,.+(,.+)?\)$/) !== null) {
    return true;
  }
  if (str.match(/^#/) !== null && convertHexToRgb(str) !== null) {
    return true;
  }
  if (str.match(/^hsla?\(.+,.+,.+(,.+)?\)$/) !== null) {
    return true;
  }
  return false;
};
