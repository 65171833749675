export const cEditComponent = {
  elementSettings: {
    en: "Element settings",
    nl: "Element instellingen",
  },
  textEditor: {
    en: "Text editor",
    nl: "Tekstopmaak",
  },
  linkDest: {
    en: "Link destination",
    nl: "Link bestemming",
  },
  colLayout: {
    en: "Column layout",
    nl: "Kolom layout",
  },
  componentDividerHeadingLine: {
    en: "Component divider or heading line",
    nl: "Component afscheider of koplijn",
  },
  rating: {
    en: "Rating",
    nl: "Beoordeling",
  },
  bgColor: {
    en: "Background color",
    nl: "Achtergrondskleur",
  },
  border: {
    en: "Border",
    nl: "Kant",
  },
  borderRadius: {
    en: "Border radius",
    nl: "Hoekradius",
  },
  socialIcon: {
    en: "Social icon",
    nl: "Social icoon",
  },
  appStoreLinks: {
    en: "App store links",
    nl: "App store links",
  },
  btnStyles: {
    en: "Button styles",
    nl: "Knop stijl",
  },
  imgSrc: {
    en: "Image source",
    nl: "Afbeelding bron",
  },
  imgFilters: {
    en: "Image filters",
    nl: "Afbeelding filters",
  },
  imgSize: {
    en: "Image size",
    nl: "Afbeelding grootte",
  },
  linkSettingsStyles: {
    en: "Link settings and styles",
    nl: "Link instellingen en stijl",
  },
  listMarker: {
    en: "List marker style",
    nl: "Opsommingsteken stijl",
  },
  margin: {
    en: "Margin",
    nl: "Marge",
  },
  padding: {
    en: "Padding",
    nl: "Opvulling",
  },
  component: {
    en: "Component",
    nl: "Component",
  },
  applyChanges: {
    en: "Apply changes",
    nl: "Veranderingen toepassen",
  },
  noEditableElementSelected: {
    en: "No editable element selected.",
    nl: "Geen bewerkbaar element geselecteerd.",
  },
};
