import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import GradientColor from "./formComponents/GradientColor";

import { translate } from "../../../../translations/translations";

const BgColor = ({ eb: { startStyles } }) => {
  return (
    <>
      <GradientColor
        label={translate("cEditForms.bgColor", false, null)}
        arrBgVarNames={[
          "bgColor",
          "boolBgIsGradient",
          "bgGradientDirection",
          "bgGradientColor1",
          "bgGradientColor2",
          "bgGradientStop1",
          "bgGradientStop2",
        ]}
        tooltipText={translate("cEditForms.setElementBgColor", false, null)}
      />
    </>
  );
};

BgColor.propTypes = {
  eb: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  eb: state.eb,
});

export default connect(mapStateToProps, null)(BgColor);
