export const mModalColorSchemeGenerator = {
  generateScheme: {
    en: "Generate color scheme",
    nl: "Genereer kleurpallet",
  },
  chooseBaseColor: {
    en: "Choose base color",
    nl: "Kies basiskleur",
  },
  selectColorScheme: {
    en: "Select color scheme",
    nl: "Selecteer kleurpallet",
  },
  close: {
    en: "Close",
    nl: "Sluiten",
  },
  confirmColorScheme: {
    en: "Confirm color scheme",
    nl: "Bevestig kleurpallet",
  },
};
